import { combineReducers } from 'redux';
import {version} from "../config/release"

import categories from "./categories"
import search from "./search"
import products from "./products"
import productStock from "./productStock"
import sellers from "./sellers"
import cart from "./cart"
import cardAuthorizationRequests from "./cardAuthorizationRequests"
import system from "./system"
import user from "./user"
import users from "./users"
import paymentProviders from "./paymentProviders"
import deliveryProviders from "./deliveryProviders"
import orders from "./orders"
import orderEdits from "./orderEdits"
import payouts from "./payouts"
import disbursements from "./disbursements"
import countries from "./countries"
import fees from "./fees"
import creditTransactions from "./creditTransactions"
import creditTransactionRequests from "./creditTransactionRequests"
import creditWallets from "./creditWallets"
import device from "./device"
import session from "./session"
import tags from "./tags"
import platformSettings from "./platformSettings"
import events from "./events"
import promotions from "./promotions"
import videos from "./videos"
import reactions from "./reactions"
import messages from "./messages"
import topups from "./topups"
import paymentClaims from "./paymentClaims"
import refunds from "./refunds"
import deliveryAgents from "./deliveryAgents"
import deliveryTrips from "./deliveryTrips"
import deliverySlots from "./deliverySlots"
import payments from "./payments"
import settlements from "./settlements"
import usage from "./usage"
import restockRequests from "./restockRequests"
import partners from "./partners"
import payees from "./payees"

import {
    INVENTORY_SETTINGS_IDENTIFIER, 
    CREDIT_SETTINGS_IDENTIFIER,
    DELIVERY_SLOTS_IDENTIFIER
} from "../constants/platformSettings"

export const initialState = {
    products: {
        productsById: {},
        productIds: [],
        productIdsByCategoryId: {},
        productIdsBySellerId: {},
        loaded: {
            system: {},
            sellerIds: {},
            categoryIds: {},
            orderIds: {}
        },
        stats: {}
    },
    productStock: {
        productStockById: {},
        productStockIdsByProductId:{},
        productStockIdsBySellerSKU:{},
        productStockIds: [],
        stockLoadedByProductId: {}
    },
    categories: {
        categoriesById:{},
        categoryIds:[],
        settings: {},
        loadedProductIdsByCategoryPage: {}
    },
    search:{
        queryString: "",
        queryResultsByQueryString: {},
    },
    sellers: {
        sellersById: {},
        sellerIdsByProductId: {},
        sellerIds: [],
        inactiveSellerIds: {},
        roleNotificationsBySellerId: {},
        accountingDocsBySellerId: {},
        activityBySellerId: {},
        loadedProductIdsBySellerPage: {}
    },
    cart: {
        id: null,
        currentOrderId: null,
        itemsByProductStockId: {},
        itemIdsBySellerId: {},
        selectedDeliveryProviderIdsBySellerId: {},
        selectedPickupAddressIdBySellerId: {},
        selectedPaymentProviderId: null,
        payWithShopdmCredit: false,
        itemIds: [],
        lastAddedItemId: null,
        giftParameters: null,
        orderNotes: "",
        cartsToMergeByCartId: {},
        deliverySlotsById: {},
        deliverySlotIdsBySellerId: {},
        deliverySlotIdsByDeliveryProviderId:{},
        sellerReadinessEstimationsBySellerId: {},
        weeklyAvailableDeliverySlotsByDeliverySelectionId: {}
    },
    orders: {
        ordersById: {},
        orderIdsBySellerId: {},
        orderIdsByDeliveryProviderId: {},
        orderIdsByOrderNumber: {},
        orderIds: [],
        stats: {}
    },
    orderEdits: {
        orderEditsById: {},
        deletedItemIdsToOrderIds: {}
    },
    paymentProviders: {
        paymentProvidersById: {},
        paymentProviderIds: [],
    },    
    deliveryProviders: {
        deliveryProvidersById: {},
        deliveryProviderIds: []
    },
    payouts:{
        payoutsById:{},
        payoutIds: [],
        stats: {}
    },
    disbursements:{
        disbursementsById:{},
        disbursementsBySellerId: {},
        disbursementIds: [],
        disbursementIdsByOrderId: {},
        stats: {},
        lastDisbursementReference: 0
    },
    system: {
        maintenance: false,
        limitedAccess: false,
        loading: false,
        loadingText: "",
        notification: null,
        environment: null,
        feedbackRequested: false,
        version
    },
    users: {
        usersById: {},
        userIdsByEmail: {},
        userIds: [],
        stats: {},
        lastLoadedAt: 0
    },
    user:{
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        carts: {},
        authenticated:false,
        lastLogin: 0,
        activeSellerAccountId: null,
        activeDeliveryProviderAccountId: null,
        defaultAddressId: null,
        addressesById: {},
        follows: {},
        events: {}
    },
    countries: {
        countriesById: {},
        countryIds: [],
        phoneFormats: {},
        lastPhoneFormatsLoaded: 0

    },
    fees: {
        feesById: {},
        feeIds: [],
        stats: {}
    },
    cardAuthorizationRequests: {
        authRequestsById: {},
        authRequestIds: [],
    },
    creditTransactions: {
        creditTransactionsById: {},
        creditTransactionIds: []
    },
    creditTransactionRequests: {
        creditTransactionRequestsById: {},
        creditTransactionRequestIds: []
    },
    creditWallets:{
        creditWalletsById:{},
        creditWalletsByEntityId: {},
        creditWalletIds: []
    },
    device: {
        cityName: "",
        deviceType: "",
        countryCode: "",
        lastSignedInUserId: "",
        latLon: "",
        timezone: "",
        mobileOSType: "",
        firstVisitedAt: 0,
        firstVisitDate: "",
        lastVisitedAt: 0,
        lastVisitDate: "",
        totalVisits: 0,
        messagingToken: "",
        lastUpdatedMessagingToken: 0
    }, 
    session: {
        id: "",
        actions: []
    },
    tags: {
        tagsById: {},
        tagIds: [],
        settings: {}
    },
    platformSettings: {
        [INVENTORY_SETTINGS_IDENTIFIER]: {},
        [CREDIT_SETTINGS_IDENTIFIER]: {},
        [DELIVERY_SLOTS_IDENTIFIER]: {},
        loaded: {}
    },
    events: {
        eventsById: {},
        eventIds: [],
        live: null
    },
    promotions: {
        promotionsById: {},
        promotionIds: [],
        promotionIdsByProductId: {},
        appliesToAllPromotionIdsBySellerId: {}
    },
    videos: {
        videosById: {},
        videoIds: []
    },
    reactions: {
        reactionsByObjectId: {},
        reactionObjectIds: []
    },
    messages: {
        messagesById: {},
        messageIds: []
    },
    topups: {
        currentTopupId: "",
        topupsById: {},
        topupIds: [],
        stats: {}
    },
    paymentClaims: {
        paymentClaimsById: {},
        paymentClaimIdsByOrderId: {},
        paymentClaimIdsByTopupId: {},
        paymentClaimIds: []
    },
    refunds: {
        refundsById: {},
        refundIds: []
    },
    deliveryAgents: {
        deliveryAgentsById: {},
        deliveryAgentIdsByAreaId: {},
        deliveryAgentIds: []
    },
    deliveryTrips: {
        deliveryTripsById: {},
        deliveryTripIds: [],
        deliveryTripIdsByAreaId: {}
    },
    deliverySlots: {
        supportedDeliverySlotsById: {},
        supportedDeliverySlotIdsInOrder: []
    },
    payments: {
        paymentsById: {},
        paymentIds: []
    },
    settlements: {
        settlementsById: {},
        settlementIds: [],
        loaded: {
            countryIds: {},
        }
    },
    usage: {
        dailySummariesById: {}
    },
    restockRequests: {
        restockRequestsById: {},
        productStockIdsByUserId: {}, //used to show/hide request restock button
        restockRequestIds: [],
    },
    partners: {
        partnersById: {},
        partnerIdsByHandleId: {},
        partnerIds: [],
        lastLoadedAt: 0
    },
    payees: {
        payeesById: {},
        payeeIdByHandleId: {},
        feeSettingsByPayeeId: {},
        rolesByPayeeId: {},
        lastLoadedAt: 0
    }
}

const rootReducer = combineReducers({
    categories,
    search,
    products,
    paymentProviders,
    deliveryProviders,
    productStock,
    sellers,
    cart,
    system,
    user,
    users,
    orders,
    orderEdits,
    payouts,
    disbursements,
    countries,
    fees,
    cardAuthorizationRequests,
    creditTransactions,
    creditTransactionRequests,
    creditWallets,
    device,
    session,
    tags,
    platformSettings,
    events,
    promotions,
    videos,
    reactions,
    messages,
    topups,
    paymentClaims,
    refunds,
    deliveryAgents,
    deliveryTrips,
    deliverySlots,
    payments,
    settlements,
    usage,
    restockRequests,
    partners,
    payees
});

export default rootReducer