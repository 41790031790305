import React from "react"
import styles from "./FeaturedBanner.module.css"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {selectedEnvironment} from "../../config/index"
import {PRODUCTION} from "../../constants/environment"
import {capitalizeAllWords} from "../../utils/stringUtils"

const FeaturedBanner = ({history, sellers}) => {
    const featuredSellerId = selectedEnvironment === PRODUCTION ?
                    "2a5e5adf-1d22-40ed-b9d1-a8daa500ee3e"
                    : "c30c06e7-c557-4a30-8dbb-4126a6852a10"
    
    const seller = sellers.sellersById[featuredSellerId]
    if (!seller) return ""
    const sellerName = capitalizeAllWords(seller.name)
    const onVisitStore = () => history.push(`/sellers/${seller.id}`)
    const style = {
        // backgroundImage: `url(${seller.headerImageUrlMed})`
    }
    return (
        <div className={styles.container}>
            <div className={styles.left} style={style}>
            </div>
            <div className={styles.right}>
                <div className={`title ${styles.heading}`}>New Store Alert!</div>
                <div className={styles.explanation}>
                    <div className={styles.subTitle}>{sellerName} has just launched their new Shopdm store!</div> 
                    <div>This means you can get your {sellerName} groceries delivered islandwide. And yes, we mean you, whether you're in Capuchin, Marigot, Grandfond, Roseau or Scottshead!</div>
                    <div className={styles.subText}>Sidenote: It takes a while to upload 10,000 products, so email us if you want us to fast track your favorite item and check back for updates!</div>
                </div>
                <div>
                    <button onClick={onVisitStore} className="button white">Visit {sellerName}'s Store</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    sellers: state.sellers,
})

export default connect(mapStateToProps)(withRouter(FeaturedBanner))