import React from 'react'

import {DAY_IN_MILLISECONDS, MINUTE_IN_MILLISECONDS} from "../../constants/datetime"

import styles from "./CountDown.module.css"

import {COUNTDOWN_SIZE_BIG, COUNTDOWN_SIZE_SMALL} from "../../constants/interface"

const HOUR_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 60



class CountDown extends React.PureComponent{

    constructor(props){
        super(props)
        let {startTime} = props
        this.state = {
            currentTime: startTime,
            timer: () => {}
        }

    }

    static defaultProps = {
        size: COUNTDOWN_SIZE_BIG,
        endTime: 1,
        startTime: 0
    }

    componentDidMount= async () => {
        const {endTime} = this.props
        const timerId = setInterval(() => {
            const currentTime = Date.now()
            if (currentTime >= endTime) this.state.timer()
            this.setState({currentTime})
        }, 1000)
        this.setState({timer: ()=> clearInterval(timerId)})
    }

    componentWillUnmount(){
        this.state.timer()
    }

    
    render(){
        const {endTime, size} = this.props
        const {currentTime} = this.state 
        if (currentTime=== 0) return ""
        let timeLeft = endTime - currentTime
        timeLeft = timeLeft > 0 ? timeLeft : 0
        const days = Math.floor(timeLeft/DAY_IN_MILLISECONDS)
        timeLeft -= (days * DAY_IN_MILLISECONDS)
        const hours = Math.floor(timeLeft/HOUR_IN_MILLISECONDS)
        timeLeft -= (hours * HOUR_IN_MILLISECONDS)
        const minutes = Math.floor(timeLeft/MINUTE_IN_MILLISECONDS)
        timeLeft -= (minutes * MINUTE_IN_MILLISECONDS)
        const seconds = Math.floor(timeLeft/1000)

        const sizeStyle = size === COUNTDOWN_SIZE_SMALL ? styles.small : ""
        return (
            <div className={`${styles.container} ${sizeStyle}`}>
                
                <div className={styles.valueContainer}>
           
                    <span>{days < 10 ? `0${days}` : days}<span className={styles.units}>d</span></span>
                </div>
                <div className={styles.valueContainer}>
               
                    <span>{hours < 10 ? `0${hours}` : hours}<span className={styles.units}>h</span></span>
                </div>
                <div className={styles.valueContainer}>
           
                    <span>{minutes < 10 ? `0${minutes}` : minutes}<span className={styles.units}>m</span></span>
                </div>
                <div className={styles.valueContainer}>
                  
                    <span>{seconds < 10 ? `0${seconds}` : seconds}<span className={styles.units}>s</span></span>
                </div>
            </div>
        )
    }
}

export default CountDown