import {firebaseApp} from "../config/firebase"
import {getFirestore, doc, setDoc} from "firebase/firestore"
import { logError } from "../utils/errorHandlingUtils"

export const CREATE_ORDER_EDIT = 'CREATE_ORDER_EDIT'

export const createOrderEdit = orderEdit => {
    return {
        type: CREATE_ORDER_EDIT,
        payload: {
            orderEdit
        }
    }
}

export const fetchCreateOrderEdit = (
    id,
    editorId,
    editorType,
    orderId,
    customerId,
    currentStatus,
    itemAdditions=[],
    itemDeletions=[],
    itemEdits=[],
    onSuccess=() =>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const orderEditRef = doc(firestore, "orderEdits", id)
    const orderEdit = {
        id,
        editorId,
        editorType,
        orderId,
        customerId,
        currentStatus,
        itemAdditions,
        itemDeletions,
        itemEdits
    }
    return async (dispatch) =>{
        try {
            await setDoc(orderEditRef, orderEdit)
            dispatch(createOrderEdit(orderEdit))
            onSuccess(orderEdit)
            return true
        } catch (e) {
            const message = `action > orderEdits > fetchCreateOrderEdit: Failed to create order edit ${JSON.stringify(orderEdit)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(orderEdit)
            return false
        }
    }
}