import {DEVELOPMENT, PRODUCTION} from "./environment"
import {selectedEnvironment} from "../config/index"

export const SHOP_DM_PERCENTAGE = 0.03
export const PAYPAL_IDENTIFIER = "paypal"
export const MOBANKING_IDENTIFIER = 'mobanking'
export const IN_STORE_IDENTIFIER = 'pickup'
export const WIRE_TRANSFER_ID = '--wire-transfer--'
export const NBD_MOBANKING_ID = selectedEnvironment === PRODUCTION ? "edy7TQtErKnu20jU9Kpx" : "7JEI6qDgK7O75PRGrNCU"
export const PAYPAL_ID = selectedEnvironment === PRODUCTION ? "U8LCfWvdeUncv7Ki3NJX" : "yjeSCPFoDbZT6lrSjwZw"
export const JAD_CARD_PAYMENTS_ID = selectedEnvironment === PRODUCTION ? "N8XLJVQIAT1XNeITur4q" : "siY5ksE1UKYfTlwnKdzM"
export const DEBIT_OR_CREDIT_CARD_ID = "--debit-or-credit-card--"

export const PAYMENT_OBJECT_TYPE_ORDER = "order"
export const PAYMENT_OBJECT_TYPE_TOPUP = "topup"