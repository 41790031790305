import {DEVELOPMENT, PRODUCTION} from "../constants/environment"
import {selectedEnvironment} from "./index"

const environments = {
  [DEVELOPMENT]:{ //sandbox paypal 
    clientId: 'AYakSzmx6FYjoz_MCbPDQRngobQvMcSMl86XMJaqsH8adYnkTOVOfhX97UJBvZIW76fpixbu60NpFqPG',
    intent: 'capture'
  },
  [PRODUCTION]: { //sandbox live production config
    clientId: 'AcZEAWhX1Aeo4txkwGRviLm-rMj_loJcIrMrskirpZLAKu8ehW80vH4LJo05VywbQqI1b5_SxmSpM56I',
    intent: 'capture'
  }
}

export const config = environments[selectedEnvironment]


export default config;