import React, { PureComponent } from "react"
import ProductTile from "../../containers/ProductTile"
import styles from "./ProductTileList.module.css"
import PropTypes from "prop-types"
import Pagination from "../Pagination"
import queryString from "query-string"

class ProductTileList extends PureComponent{

    static defaultProps = {
        productList: [],
        maxItemsPerPage: 30
    }

    handleChangePageIndex = currentPageIndex => {
        this.forceUpdate()
        const hashParameters = queryString.parse(window.location.hash)
        hashParameters.page = currentPageIndex
        window.location.hash = queryString.stringify(hashParameters)
        setTimeout(() =>{ if(this.containerTop) window.scrollTo(0,this.containerTop.offsetTop-200)}, 50)
    }

    render(){    
        const {productList, onClick, maxItemsPerPage} = this.props
        if (productList.length < 1) return ""
        let currentPageIndex = queryString.parse(window.location.hash).page
        //if there is a product length, it is a number and it is a valid index  
        currentPageIndex = currentPageIndex && 
                            !isNaN(Number(currentPageIndex)) && 
                            ((currentPageIndex * maxItemsPerPage) < productList.length) ? Number(currentPageIndex) : 0
        const startProductIndex = currentPageIndex * maxItemsPerPage
        const endProductIndex = (currentPageIndex + 1) * maxItemsPerPage
        const paginatedProductList = productList.slice(startProductIndex, endProductIndex)
        return (
            <div className={styles.container} ref={el => this.containerTop = el}>
                {
                    productList.length > maxItemsPerPage ?
                        <div className={styles.showing}>Showing {startProductIndex + 1}-{endProductIndex < productList.length ? endProductIndex : productList.length} of {productList.length}</div>
                    :
                        null
                }
                <div className={styles.innerContainer}>
                {
                    paginatedProductList.map(
                        (product) => <ProductTile 
                                    key={product.id}
                                    onClick={onClick}
                                    productId={product.id}
                                    productPrice={product.price}
                                    productTitle={product.title}
                                    productBrand={product.brand}
                                    productUnits={product.units}
                                    starRating={product.starRating}
                                    imageUrl={product.imageUrl}
                                    createdBySellerId={product.createdBySellerId}
                                    characteristics={product.characteristics}
                                    stock={product.stock}
                                />
                    )
                }
                </div>
                {
                    productList.length > maxItemsPerPage ?
                    <div className={styles.paginationContainer}>
                        <Pagination 
                            itemCount={productList.length}
                            maxItemsPerPage={maxItemsPerPage}
                            currentPageIndex={currentPageIndex}
                            onSelectIndex={this.handleChangePageIndex}
                        />
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

ProductTileList.propTypes = {
    productList: PropTypes.array
}
export default ProductTileList;