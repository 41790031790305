import React from "react"
import styles from "./DeliveryOptionsBanner.module.css"
import Pickup from "../../images/shop.png"
import ShopdmLogo from "../../images/logo.png"

const DeliveryOptionsBanner = () => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.title}>Delivery Options For Every Budget</div>
            </div>
            <div className={styles.elements}>
                <Element icon={Pickup} text="Free Pickup"/>
                <Element icon={ShopdmLogo} text="Next day Delivery"/>
                <Element icon={'https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2FdeliveryProviderLogos%2FqwickDelivery%2FqwickLogo.png?alt=media&token=1f25520f-d987-46e6-ac68-ccc12ca789f4'} text="Same-day Express"/>
                <Element icon={'https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2FdeliveryProviderLogos%2FDHL%2Fdhl-1-logo-svg-vector.svg?alt=media&token=e80b5c78-4d4d-42a7-b2ab-221686c69c15'} 
                            text="Overseas Deliveries (USA)"
                />
            </div>
        </div>
    )
}

const Element = ({text, icon}) => {
    return (
        <div className={styles.element}>
            <div className={styles.elementCircle}>
                <img src={icon} height="100%"/>
            </div>
            <div className={styles.elementText}>{text}</div>
        </div>
    )
}
export default DeliveryOptionsBanner