/**
 *  Purpose: the modifications that are carried on the Tag objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {SETTINGS_IDENTIFIER} from "../constants/analysis"

// STATE
// tags:{
//   tagsById:{},
//   tagIds: [],
//   settings: {}
// }

const tagsReducer = (state = initialState.tags, action) => {
    const {type, payload} = action;
    let tagsById = {...state.tagsById}
    let settings = {...state.settings}
    switch (type){
        case types.SAVE_TAGS: {
            if(typeof payload.tags !== "object") {
                logError(`tagsReducer > SAVE_TAGS: tags payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.tags.forEach(tagCollection => {
                if (tagCollection.id !== SETTINGS_IDENTIFIER){
                    Object.keys(tagCollection.tags).forEach(tagId => {
                        tagsById[tagId] = tagCollection.tags[tagId]    
                    })
                    
                } else settings = tagCollection
            });
            return {
                ...state,
                settings,
                tagsById,
                tagIds: Object.keys(tagsById)
            }
        }

        case types.CREATE_PRODUCT: {
            return state
        }

        case types.EDIT_PRODUCT: {
            if(typeof payload.edits !== "object") {
                return state
            }
            if(typeof payload.previousValues !== "object") {
                return state
            }
            if(typeof payload.productId !== "string") {
                return state
            }
            let {edits, previousValues} = payload
            //loop through all its previous tags and remove them if they exist
            if (previousValues.tags){
                Object.keys(previousValues.tags).forEach(tagId => {
                    if (tagsById[tagId]) delete tagsById[tagId].productIds[payload.productId] 
                })
            }
            //if there are new tags
            if (edits.tags) {
                Object.keys(edits.tags).forEach(tagId => {
                    if (!tagsById[tagId]) tagsById[tagId] = {id: tagId, text:tagId, altIds:{}, productIds:{}}
                    tagsById[tagId].productIds[payload.productId] = true
                })
            }
            return {
                ...state,
                tagsById,
                tagIds: Object.keys(tagsById)
            }
        }

        default: return state
    }
}

export default tagsReducer