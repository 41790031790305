import React from "react"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import DisabledButton from "../../components/DisabledButton"
import IncrementInput from "../../components/IncrementInput"

import {logAnalyticsEvent} from "../../config/firebase"
import {ecommerceEvents} from "../../constants/firebase"

class AddToCartButton extends React.Component {
    
    static defaultProps = {
        productId: "",
        productStockId: "",
        method: "from add to cart button",
        addToCartText: "Add To Cart",
        outOfStockText: "Out Of Stock",
        onSuccess: () => {}
    }

    handleAddToCart = (quantity) => {
        const {actions, productStock, products, productId, productStockId, method} = this.props
        const product = products.productsById[productId] 
        if (product){
            const selectedStockOption = productStock.productStockById[productStockId]
            //google analytics
            logAnalyticsEvent(ecommerceEvents.ADD_TO_CART, product.analyticsItem)
            actions.fetchLogDeviceSessionAction({
                action: "addToCart", 
                name: product.titleAndBrand,
                productId: product.id,
                productStockId: selectedStockOption.id,
                price: selectedStockOption.price,
                quantity,
                method
            })
            actions.fetchAddToCart(selectedStockOption, quantity)
        }
    }

    handleRemoveFromCart = () => {
        const {cart, products, productStockId, actions} = this.props
        const item = cart.itemsByProductStockId[productStockId]
        if (!item) return
        const product = products.productsById[item.productId] 
        if (product){
            logAnalyticsEvent(ecommerceEvents.REMOVE_FROM_CART, product.analyticsItem)
            actions.fetchLogDeviceSessionAction({
                action: "removeFromCart",
                productStockId, 
            })
        }
        actions.fetchRemoveFromCart(productStockId)
    }

    handleIncrementQuantity = quantity => {
        if (quantity === 0) this.handleRemoveFromCart()
        else this.handleAddToCart(quantity)
    }

    render () {
        const {productId, productStockId, products, productStock, addToCartText, outOfStockText, cart} = this.props
        const product = products.productsById[productId]
        if (!product) return ""
        const stock = productStock.productStockById[productStockId]
        if (!stock) return ""
        const {quantityInStock} = stock
        const minimumOrderQty = product.minimumOrderQty ? product.minimumOrderQty : 1
        return (
            <React.Fragment>
                {
                    // if the product has enough quantity in stock let to order (more than min qty)
                    quantityInStock >= minimumOrderQty?
                    <React.Fragment>
                        {
                            //if the item is already in the cart, show increment counter
                            cart.itemsByProductStockId[stock.id] ?
                            <IncrementInput 
                                value={cart.itemsByProductStockId[stock.id].quantity}
                                onChange={this.handleIncrementQuantity}
                                min={minimumOrderQty}
                                max={quantityInStock}
                                onBelowMin={this.handleRemoveFromCart}
                            />
                            :
                            //else, show regular button
                            <button 
                                className={["button", "primary"].join(" ")}
                                onClick={() => this.handleAddToCart(minimumOrderQty)}
                            >
                                {addToCartText}
                            </button>
                        }
                    </React.Fragment>

                    :
                    <DisabledButton
                        alert="Sorry, this item is out of stock"
                    >
                        {outOfStockText}
                    </DisabledButton>
                }
            </React.Fragment>
           
        )
    }
}

const mapStateToProps = state => ({
    products: state.products,
    productStock: state.productStock,
    cart: state.cart
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartButton)