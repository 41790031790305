/**
 *  Purpose: the modifications that are carried on the Country objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
// STATE
// countries:{
//   countriesById:{},
//   countryIds: [],
//   phoneFormats: {},
//   lastPhoneFormatsLoaded: 0
// }


const countriesReducer = (state = initialState.countries, action) => {
    const {type, payload} = action;
    let countriesById = {...state.countriesById}

    switch (type){
        case types.SAVE_COUNTRIES: {
            if(typeof payload.countries !== "object") {
                logError(`countriesReducer > SAVE_COUNTRIES: countries payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.countries.forEach(country => {
                countriesById[country.id] = country
            });
            return {
                ...state,
                countriesById,
                countryIds: Object.keys(countriesById)
            }
        }

        case types.SAVE_COUNTRY_PHONE_FORMATS: {
            if(typeof payload.phoneFormats !== "object") {
                logError(`countriesReducer > SAVE_COUNTRY_PHONE_FORMATS: country phone formats payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                phoneFormats: payload.phoneFormats,
                lastPhoneFormatsLoaded: Date.now()
            }
        }
        default: return state
    }
}

export default countriesReducer