import React from "react"

import DataSelect from "../../components/DataSelect"

import styles from "./DeliveryPriceCalculator.module.css"
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {alphabeticalSort, capitalizeAllWords} from "../../utils/stringUtils"
import {DOMINICA_COUNTRY_ID} from "../../constants/country"
import {DELIVERY_FEE_TYPE_LOCATION_BASED, SHOPDM_DELIVERY_ID,MERO_SETTLEMENT_ID} from "../../constants/delivery"
import DeliveryMan from "../../images/delivery-man.png"
import {logAnalyticsEvent} from "../../config/firebase"
class DeliveryPriceCalculator extends React.Component {
    constructor(props){
        super(props)
        const {countries, user} = props
        const dominica = countries.countriesById[DOMINICA_COUNTRY_ID]
        const settlementList = Object.keys(dominica.settlements)
                                        .sort((sIdA, sIdB) => {
                                            return alphabeticalSort(dominica.settlements[sIdA], dominica.settlements[sIdB])
                                        })
                                        .map(id => ({id, name: capitalizeAllWords(dominica.settlements[id])}))
        //if there is a logged in user with an address and 
        //that address is in Dominica, use his default settlement, otherwise,
        //choose the first settlement in the list
        const initialSettlementId = user.defaultAddressId && 
                                    user.addressesById[user.defaultAddressId] && 
                                    user.addressesById[user.defaultAddressId].countryId === DOMINICA_COUNTRY_ID
                                    ?
                                    user.addressesById[user.defaultAddressId].settlementId
                                    :
                                    MERO_SETTLEMENT_ID
                                        
        this.state = {
            settlementList,
            selected: initialSettlementId,
            deliveryProviderId: SHOPDM_DELIVERY_ID
        }
    }

    handleSelect = selected => {
        const {countries} = this.props
        const dominica = countries.countriesById[DOMINICA_COUNTRY_ID]
        logAnalyticsEvent("checked-delivery-price", {settlement: dominica.settlements[selected], countryId: DOMINICA_COUNTRY_ID})
        this.setState({selected})
    }
    
    render(){
        const {settlementList, selected, deliveryProviderId} = this.state
        const {deliveryProviders, countries, cart} = this.props
        const deliveryProvider = deliveryProviders.deliveryProvidersById[deliveryProviderId]
        const dominica = countries.countriesById[DOMINICA_COUNTRY_ID]
        const sellerCount = Object.keys(cart.itemIdsBySellerId).length
        const pickupPrice = deliveryProvider.consolidation && sellerCount > 1? deliveryProvider.consolidation.fixedFee * (sellerCount - 1) : 0
        const totalPrice = (deliveryProvider.deliverySettlementIds[selected] + pickupPrice).toFixed(2)
        return (
            <div className={styles.container}>
                <div className={styles.header}><img className={styles.imgIcon} src={DeliveryMan}/>Price To Deliver All Your Shopping To</div>
                <div className={styles.settlement}>
                    <DataSelect 
                        selected={selected} 
                        dataArray={settlementList}
                        onSelect={this.handleSelect}
                        allowBlank={false}
                    />
                </div>
                <div className={styles.total}>
                {
                    deliveryProvider &&
                    deliveryProvider.feeType === DELIVERY_FEE_TYPE_LOCATION_BASED &&
                    selected in deliveryProvider.deliverySettlementIds ?
                    <div>
                    <div className="amountHighlight">
                        <div>EC ${totalPrice}</div>
                    </div>
                    {
                        sellerCount > 1 && 
                        deliveryProvider.consolidation && 
                        deliveryProvider.consolidation.fixedFee > 0 ?
                        <div className={`${styles.info} ${styles.deliveryInfo}`}>
                            <div>EC ${deliveryProvider.deliverySettlementIds[selected].toFixed(2)} Delivery from 1st seller </div>
                            <div>EC ${pickupPrice.toFixed(2)} from additional {sellerCount - 1} sellers </div>
                        </div>
                        :
                        null
                    }
                    
                    </div>
                    : 
                    null

                }
                </div>
                <div className={styles.info}>Delivered to <span className={styles.name}>{capitalizeAllWords(dominica.settlements[selected])}</span> by <span className={styles.name}>{capitalizeAllWords(deliveryProvider.name)}</span></div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    countries: state.countries,
    deliveryProviders: state.deliveryProviders,
    cart: state.cart,
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryPriceCalculator)