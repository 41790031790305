import {firebaseApp} from "../config/firebase"
import { getFirestore, collection, query, getDocs, where, onSnapshot, writeBatch, doc} from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"
import {MINUTE_IN_MILLISECONDS, DAY_IN_MILLISECONDS} from "../constants/datetime"
import { toggleLoading } from "./system";

export const SAVE_USERS = 'SAVE_USERS'

const saveUsers = users => {
    return {
        type: SAVE_USERS,
        payload: {
            users
        }
    }
}


export const fetchSaveUsers = () => {
    const firestore = getFirestore(firebaseApp);
    const usersRef = query(collection(firestore, "users"))
    return async (dispatch, getState) =>{
        try {
            const {users} = getState()
            //only load all users every 30 mins, users are not serialized so refresh for latest

            if ((Date.now() - users.lastLoadedAt) <= MINUTE_IN_MILLISECONDS * 30) return true
            const querySnapshot = await getDocs(usersRef)
            //get an array of users from the snapshot
            const userArray = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(saveUsers(userArray))
            return true
        } catch (e){
            const message = `action > users > fetchSaveUsers: Failed to save users`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveAdminUsersCreatedInDateRange = (
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all users created in the last 30 days
    toDate=Date.now()
) => {
    /**
      * Purpose: retrieve the users from the firestore database created between the specified date range
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const usersRef = query(collection(firestore, "users"), 
                            where("createdAt", ">=",  fromDate),
                            where("createdAt", "<=", toDate))                          
    return async dispatch => {
        try {
            const usersListener = await onSnapshot(usersRef,
                querySnapshot => {
                    //get an array of users from the snapshot
                    const users = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveUsers(users))
                } 
            )
            return usersListener
        } catch (e){
            const message = `action > users > fetchSaveAdminUsersCreatedInDateRange: Failed to save users from ${fromDate} to ${toDate}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveAdminUsersLastVisitedInDateRange = (
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all users who've visited in the last 30 days
    toDate=Date.now()
) => {
    /**
      * Purpose: retrieve the users from the firestore database who've visited between the specified date range
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const usersRef = query(collection(firestore, "users"), 
                            where("lastVisitedAt", ">=",  fromDate),
                            where("lastVisitedAt", "<=", toDate))                          
    return async dispatch => {
        try {
            const usersListener = await onSnapshot(usersRef,
                querySnapshot => {
                    //get an array of users from the snapshot
                    const users = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveUsers(users))
                } 
            )
            return usersListener
        } catch (e){
            const message = `action > users > fetchSaveAdminUsersLastVisitedInDateRange: Failed to save users who've visted between ${fromDate} and ${toDate}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}


export const fetchAdminUpdateBulkUsers = (userUpdatesById={}) => {
    /**
     * Purpose: allow admins to do a bulk write on all users
     *          note: all users to be written to must already be loaded to the site
     */
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) => {
        try {
            const {users} = getState()
            //loop through batches to avoid hitting the 500 write firebase limit
            const batchArray = [writeBatch(firestore)]
            const batchLimit = 400 //after this many operations on the batch, a new batch will be added and used
            let batchIndex = 0
            let operationCount = 0
            const userIdList = Object.keys(userUpdatesById)
            for(let i = 0; i < userIdList.length; i++){
                const userId = userIdList[i]
                const batch = batchArray[batchIndex]
                //if the user exists
                if (users.usersById[userId]){
                    const userRef = doc(firestore, 'users', userId)
                    const userUpdate = userUpdatesById[userId]
                    batch.set(userRef, userUpdate, {merge: true})
                    operationCount += 1
                    if (operationCount >= batchLimit) {
                        batchArray.push(writeBatch(firestore));
                        batchIndex++;
                        operationCount = 0;
                    }
                }
            }
            //update all users in the different batches
            let updatedUserCount = 0
            for (let i in batchArray) {
                const batch = batchArray[i]
                updatedUserCount += batch._mutations ? batch._mutations.length : batchLimit
                await batch.commit()
                dispatch(toggleLoading(true, `Updated ${updatedUserCount} out of ${Object.keys(userUpdatesById).length} users`))
            }
            dispatch(toggleLoading(false)) 
            return true
        } catch (e) {
            const message = `action > users > fetchAdminUpdateBulkUsers: Failed to update bulk users ${JSON.stringify(Object.keys(userUpdatesById))}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}