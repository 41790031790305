/**
 *  Purpose: the modifications that are carried on the Payment Claims objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 import {
    PAYMENT_OBJECT_TYPE_ORDER,
    PAYMENT_OBJECT_TYPE_TOPUP
} from "../constants/payment"
 // STATE
 // paymentClaims: {
 //     paymentClaimsById: {},
 //     paymentClaimIds: [],
 //     paymentClaimIdsByOrderId: {},
 //     paymentClaimIdsByTopupId: {},
 // }
 
 
 const paymentClaimsReducer = (state = initialState.paymentClaims, action) => {
     const {type, payload} = action;
     let paymentClaimsById = {...state.paymentClaimsById}
     let paymentClaimIdsByOrderId = {...state.paymentClaimIdsByOrderId}
     let paymentClaimIdsByTopupId = {...state.paymentClaimIdsByTopupId}
 
     switch (type){
         case types.SAVE_PAYMENT_CLAIMS: {
             if(typeof payload.paymentClaims !== "object") {
                 logError(`paymentClaimsReducer > SAVE_PAYMENT_ClAIMS: paymentClaims payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.paymentClaims.forEach(paymentClaim => {
                 paymentClaimsById[paymentClaim.id] = paymentClaim
                 if (paymentClaim.objectType === PAYMENT_OBJECT_TYPE_ORDER){
                    paymentClaimIdsByOrderId[paymentClaim.objectId] = paymentClaim.id
                 } else if (paymentClaim.objectType === PAYMENT_OBJECT_TYPE_TOPUP){
                    paymentClaimIdsByTopupId[paymentClaim.objectId] = paymentClaim.id
                 }
             })
             return {
                 ...state,
                 paymentClaimsById,
                 paymentClaimIdsByOrderId,
                 paymentClaimIdsByTopupId,
                 paymentClaimIds: Object.keys(paymentClaimsById)
             }
         }
 
         default: return state
     }
 }
 
 export default paymentClaimsReducer