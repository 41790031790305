import {firebaseApp} from "../config/firebase"
import { getFirestore, getDoc, doc, setDoc} from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"
import { DEFAULT_LOADING_PERIOD } from "../constants/loading";

export const SAVE_PLATFORM_SETTING = 'SAVE_PLATFORM_SETTING'

export const savePlatformSetting = (settingId, platformSetting={}) => {
    return {
        type: SAVE_PLATFORM_SETTING,
        payload: {
            settingId,
            platformSetting
        }
    }
}

export const fetchSavePlatformSetting = (settingId) => {
    const firestore = getFirestore(firebaseApp);
    const settingsRef = doc(firestore, "platformSettings", settingId)
                                
    return async (dispatch, getState) => {
        try{
            const {platformSettings} = getState()
            //if it has been loaded recently, do not reload
            if (
                platformSettings[settingId] &&
                platformSettings.loaded[settingId] &&
                ((Date.now() - platformSettings.loaded[settingId]) <= DEFAULT_LOADING_PERIOD)
            ) return platformSettings[settingId]
            //otherwise load the settings
            const docRef = await getDoc(settingsRef)
            const platformSetting = docRef.data()
            dispatch(savePlatformSetting(settingId, platformSetting))
            return platformSetting
        } catch (e){
            const message = `action > platformSettings > fetchSavePlatformSetting: Failed to save platform setting ${settingId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSetPlatformSetting = (settingId, platformSetting) => {
    const firestore = getFirestore(firebaseApp);
    const settingsRef = doc(firestore, "platformSettings", settingId)
                                
    return async (dispatch, getState) => {
        try{
            //set the new the settings
            await setDoc(settingsRef, platformSetting)
            dispatch(savePlatformSetting(settingId, platformSetting))
            return platformSetting
        } catch (e){
            const message = `action > platformSettings > fetchSetPlatformSetting: Failed to set platform setting ${settingId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}