import React from "react"
import Icon from "../Icon"

const VideoEmbed = ({onClick=()=>{}, buttonText="Embed A Video"}) => {
    return (
        <div className="upload" onClick={onClick}>
            <div className="symbol">
                <Icon icon="video-call"/>
                <button className="button white">{buttonText}</button>
            </div>
        </div>
    )
}

export default VideoEmbed