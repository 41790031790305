import React from "react"
import AvatarIcon from "../AvatarIcon"
import styles from "./SellerBadge.module.css"

const SellerBadge = ({seller={}, onClick=()=>{}}) => {
    return (
        <div className={styles.container} onClick={onClick}>
            <AvatarIcon name={seller.name} imageUrl={seller.logoImageUrlSmall}/>
            <span>{seller.name}</span>
        </div>
    )
}

export default SellerBadge