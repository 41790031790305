import React from "react"
import styles from "./GiftInfoBanner.module.css"
import Bouquet from "../../images/bouquet.png"
import Gift from "../../images/tony-gift.png"
import {withRouter} from "react-router-dom"

const GiftInfoBanner = ({history}) => {
    const goToInfo = () => history.push("/explanations/how-gifting-works")
    return (
        <div className={styles.container}>
            <div className={styles.visual}>
                <img src={Gift} />
            </div>
            <div className={styles.content}>
                <div className={styles.title}><span>Surprise Her/Him At Work</span><span>Send Mom Love From Abroad</span></div>
                <a 
                    className={styles.button}
                    onClick={goToInfo}
                >
                    How Gifting Works
                </a>
            </div>
            <div className={styles.visual}>
                <img src={Bouquet}/>
            </div>
        </div>
    )
}

export default withRouter(GiftInfoBanner)