import React from "react"
import ProductTileList from "../../components/ProductTileList"
import ProductTile from "../ProductTile"
import ReviewSlider from "../ReviewSlider"
import CategorySlider from "../CategorySlider"
import styles from "./HomeTileList.module.css"
import {capitalizeAllWords} from "../../utils/stringUtils"
import BackLink from "../../components/BackLink"

import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import PropTypes from "prop-types"

import ProcessBanner from "../../banners/ProcessBanner"
import DeliveryOptionsBanner from "../../banners/DeliveryOptionsBanner"
import DeliveryRangeBanner from "../../banners/DeliveryRangeBanner"
import ActionBanner from "../../banners/ActionBanner"
import AboutBanner from "../../banners/AboutBanner"
import TestimonialBanner from "../../banners/TestimonialBanner"
import FeaturedBanner from "../../banners/FeaturedBanner"

import Slider from "../../components/Slider"
import {NEWEST_PRODUCTS_COUNT} from "../../constants/product"

import {logAnalyticsEvent} from "../../config/firebase"
function HomeTileList ({products, categories, inactiveSellerIds, handleOpenCategoryModal=() => {},actions, history}){
    const {productIds, productsById} = products
    const productList = productIds.map(productId => productsById[productId])
                                //filter out products from inactive sellers or products that have been set to inactive
                                  .filter(product => !inactiveSellerIds[product.createdBySellerId] && !product.isInactive)
                                
    const featuredProductList = Object.values(productList).filter(product => product.isFeatured)
    
    const latestProductList = Object.values(productList).sort((productA, productB)=> productB.createdAt - productA.createdAt).slice(0, NEWEST_PRODUCTS_COUNT)
    const selectedCategoryIds = categories.settings && categories.settings.featuredCategoryIds?
                                categories.settings.featuredCategoryIds
                                :
                                []
    
    //ensure every product shows in only one category
    const productCategoryMap = selectedCategoryIds.reduce((map, categoryId) => {
        const category = categories.categoriesById[categoryId]
        if (!category) return map
        const categoryProductList = featuredProductList.filter(product => product.categoryIds.includes(categoryId))
        categoryProductList.forEach(product => {
            if (map[product.id]) return
            map[product.id] = categoryId
        })
        return map
    }, {})
    return (

        <div className={styles.container}>
            <p className={"title"}>Top Categories</p>
            <CategorySlider 
                onClick={
                    categoryId => {
                        logAnalyticsEvent("press_category_circle_on_home", {categoryId})
                        actions.fetchLogDeviceSessionAction({
                            action: "pressCategoryCircleOnHome",
                            categoryId
                        })
                        history.push(`/categories/${categoryId}`)
                    }
                }
                onSeeAllCategories={handleOpenCategoryModal}
            />
            <p className={"title"}>Our Customers Love Us!</p>
            <ReviewSlider 
                handleOpenReviewModal={
                    reviewId => {
                        logAnalyticsEvent("view_full_review", {reviewId})
                        actions.fetchLogDeviceSessionAction({
                            action: "viewFullReview",
                            reviewId
                        })
                    }
                }
            />
            <p className={"title"}>Newest Products</p>
            <Slider>
                {
                    latestProductList.map(
                        (product) => <ProductTile 
                                    key={product.id}
                                    productId={product.id}
                                    productPrice={product.price}
                                    productTitle={product.title}
                                    productBrand={product.brand}
                                    productUnits={product.units}
                                    starRating={product.starRating}
                                    imageUrl={product.imageUrl}
                                    createdBySellerId={product.createdBySellerId}
                                    mobileOrientation="portrait"
                                    characteristics={product.characteristics}
                                    stock={product.stock}
                                />
                    )
                }
            </Slider>
            {
                selectedCategoryIds.map((categoryId, i )=> {
                    const category = categories.categoriesById[categoryId]
                    if (!category) return
                    const categoryProductList = featuredProductList.filter(product => productCategoryMap[product.id] === categoryId)
                    const categoryLabel = capitalizeAllWords(category.label)
                    return (
                        <div className={styles.category} key={categoryId}>
                                {
                                    i === 0 ?
                                    <FeaturedBanner />
                                    :
                                    i === 1 ?
                                    <ProcessBanner />
                                    :
                                    i === 2 ?
                                    <TestimonialBanner />
                                    :
                                    i === 3 ? 
                                    <AboutBanner />
                                    
                                    :
                                    i === 4 ?
                                    <DeliveryRangeBanner />
                                    :
                                    i === 5 ?
                                    <DeliveryOptionsBanner />
                                    :
                                    ""
                                }
                            <p className={"title"}>{categoryLabel}</p>
                            <Slider>
                                {
                                    categoryProductList.map(
                                        (product) => <ProductTile 
                                                    key={product.id}
                                                    productId={product.id}
                                                    productPrice={product.price}
                                                    productTitle={product.title}
                                                    productBrand={product.brand}
                                                    productUnits={product.units}
                                                    starRating={product.starRating}
                                                    imageUrl={product.imageUrl}
                                                    createdBySellerId={product.createdBySellerId}
                                                    mobileOrientation="portrait"
                                                    characteristics={product.characteristics}
                                                    stock={product.stock}
                                                />
                                    )
                                }
                            </Slider>
                            <div>
                                <BackLink 
                                    text={`More ${categoryLabel}`}
                                    path={`/categories/${categoryId}`}
                                    direction="forward"
                                /> 
                            </div>
                            {
                                i === (selectedCategoryIds.length - 1) ?
                                <ActionBanner />
                                :
                                null
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    categories: state.categories,
    inactiveSellerIds : state.sellers.inactiveSellerIds
})

HomeTileList.propTypes = {
    products: PropTypes.shape({
        productsById: PropTypes.object.isRequired,
        productIds: PropTypes.array.isRequired
    }).isRequired
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeTileList));