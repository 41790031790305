import React from "react"

import SuccessIcon from "../../components/SuccessIcon"
import {Helmet} from "react-helmet";

import {connect} from "react-redux"

import {Redirect} from "react-router-dom"
import {
    PAYMENT_OBJECT_TYPE_ORDER,
    PAYMENT_OBJECT_TYPE_TOPUP,
} from "../../constants/payment"

class CardTransactionSuccessful extends React.Component {

    constructor(props){
        super(props)
        const {history, user} = props
        const query = new URLSearchParams(history.location.search)
        const custom = query.get("custom")
        const objectType = typeof custom === "string" ? custom.split(" ")[0] : PAYMENT_OBJECT_TYPE_ORDER
        const redirectTo = objectType === PAYMENT_OBJECT_TYPE_TOPUP ?
                            `/shopdm-credit/${user.id}`
                            :
                            `/customers/${user.id}`
        this.state = {
            redirectCount: 4,
            redirectTo,
            redirectInterval: () => {},
            lastIntervalUpdate: 0
        }
    }

    componentDidMount = async () => {
        this.triggerRedirect()
    }

    triggerRedirect = () => {
        this.setState({
            redirectInterval: setInterval(() => {
                if ((Date.now() - this.state.lastIntervalUpdate) >= 1000) this.setState({
                    redirectCount: this.state.redirectCount - 1,
                    lastIntervalUpdate: Date.now()
                })
            }, 300)
        })
    }

    componentWillUnmount(){
        if (typeof this.state.redirectInterval === 'function') this.state.redirectInterval()
    }

    render(){
        const {redirectCount, redirectTo} = this.state
        if (redirectCount <= 0) return <Redirect to={redirectTo}/>
        return (
            <div className="cardResultContainer screen">
                <Helmet>
                    <title>Card Transaction Successful</title>
                </Helmet>
                <img height="100" width="auto" src="https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2Flogo500x500.png?alt=media&token=9be6790b-1251-4331-945d-bc96d96ae65f" />
                <div className="cardResultInnerContainer">
                    <SuccessIcon />
                    <div className="cardResultTitleContainer">  
                        <div className="cardResultTitle">Success!</div>
                    </div>
                    <p className="cardResultNext">Payment received. Thank you for shopping on Shopdm, we've emailed your receipt.</p>
                    <div>Redirecting you in {redirectCount}</div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    user: state.user,
})

export default connect(mapStateToProps)(CardTransactionSuccessful);