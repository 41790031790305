import React from "react"
import styles from "./ActionBanner.module.css"
import FormField from "../../components/FormField"
import DisabledButton from "../../components/DisabledButton"
import SuccessIcon from "../../components/SuccessIcon"

import {withRouter} from "react-router-dom"
import {isValidEmail} from "../../utils/formValidationUtils"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {logAnalyticsEvent} from "../../config/firebase"

class ActionBanner extends React.Component {
    state = {
        email: ""
    }

    handleChange = e => this.setState({email: String(e.target.value)})
    
    handleSubscribe = () =>{
        const {email} = this.state
        const {actions} = this.props
        actions.fetchSubscribe(email)
        logAnalyticsEvent("visitor_subscribed")
        actions.fetchLogDeviceSessionAction({
            action: "visitorSubscribed"
        })
    }

    render(){
        const {history, user} = this.props
        const {email} = this.state
        const goToCreateAccount = () => history.push("/create-account")
        const emailValid = isValidEmail(email)

        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.title}>Get Updates!</div>
                    {
                        user.authenticated?
                        <div>You're already subscribed and ready to get updates!</div>
                        :
                        <div>Create an account or join our update list to stay informed on Shopdm's progress</div>
                    }
                </div>
                <div className={styles.elements}>
                    {
                        !user.authenticated?
                        <React.Fragment>
                            <div className={styles.element}>
                                <button onClick={goToCreateAccount} className={["button", "primary"].join(" ")}>Create Account</button>
                            </div>
                            {
                                !user.subscribed ?
                                <div className={styles.element}>
                                    <FormField 
                                        value={email}
                                        onChange={this.handleChange}
                                        placeholder="email address"
                                    />
                                    {
                                        emailValid ?                       
                                        <button className={["button", styles.light].join(" ")} onClick={this.handleSubscribe}>Subscribe</button>
                                        :
                                        <DisabledButton alert="Enter your email above">Subscribe</DisabledButton>
                                    }
                                </div>
                                :
                                <div className={[styles.element, styles.subscribed].join(" ")}>
                                    You're Subscribed!
                                    <div><SuccessIcon/></div>
                                </div>
                            }
                        </React.Fragment>
                        :
                        <div>
                        <SuccessIcon/>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ActionBanner));