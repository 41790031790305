import React from "react"
import styles from "./PhoneInput.module.css"
import {dominicaCountryCode, usCountryCode, ukCountryCode} from "../../constants/country"
import {alphabeticalSort} from "../../utils/stringUtils"
import {isValidPhoneNumber} from "../../utils/formValidationUtils"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

const defaultPhoneData = {
    "dm": {
        "areaCode": "+1767",
        "basePhoneNumberDigits": 7,
        "id": "dm",
        "name": "dominica"
    },
    "us": {
        "areaCode": "+1",
        "basePhoneNumberDigits": 10,
        "id": "us",
        "name": "united states"
    }
}

class PhoneInput extends React.Component{
    
    constructor(props){
        super(props)
        const areaCodeOptions = this.getAreaCodeOptions()
        const {country, selectedIndex} = this.getCountryFromPhoneNumber(areaCodeOptions)
        this.state = {
            areaCode: country ? country.areaCode: "",
            countryCode: country ? country.id: "",
            basePhoneNumberDigits: country ? country.basePhoneNumberDigits: 7,
            selectedIndex,
            areaCodeOptions
        }
    }

    static defaultProps = {
        readonly: false,
        value: "",
        placeholder: "",
        onChange: ()=>{},
        onChangeAreaCode: ()=>{},
        onKeyDown: ()=>{},
        className: ""
    }

    componentDidMount = async () => {
        const {value, onChange, actions} = this.props
        const {areaCode} = this.state
        await actions.fetchSaveCountryPhoneFormats()
        if (!value) {
            //if this is a new phone number, set it to only the area code
            onChange(areaCode, false)
        }
    }

    componentDidUpdate(prevProps){
        const {countries, value} = this.props
        const {areaCode} = this.state
        //if the phone number formats were just loaded
        if (
            (!prevProps.countries.lastPhoneFormatsLoaded && countries.lastPhoneFormatsLoaded ) ||
            (prevProps.countries.lastPhoneFormatsLoaded < countries.lastPhoneFormatsLoaded)
        ){
            const areaCodeOptions = this.getAreaCodeOptions()
            const {country, selectedIndex} = this.getCountryFromPhoneNumber(areaCodeOptions)
            this.setState({
                areaCode: country ? country.areaCode: "",
                countryCode: country ? country.id: "",
                basePhoneNumberDigits: country ? country.basePhoneNumberDigits: 7,
                selectedIndex,
                areaCodeOptions
            })
        } 
        //if the parent changes the area code
        else if (countries.lastPhoneFormatsLoaded && value && !value.startsWith(areaCode)){
            const areaCodeOptions = this.getAreaCodeOptions()
            const {country, selectedIndex} = this.getCountryFromPhoneNumber(areaCodeOptions)
            this.setState({
                areaCode: country ? country.areaCode: "",
                countryCode: country ? country.id: "",
                basePhoneNumberDigits: country ? country.basePhoneNumberDigits: 7,
                selectedIndex,
                areaCodeOptions
            })
        }
    }

    getAreaCodeOptions = () => {
        //load all the phone number formats into a sorted list
        const {countries} = this.props
        const phoneData = countries.lastPhoneFormatsLoaded ?
                          countries.phoneFormats
                          : defaultPhoneData
        const areaCodeOptions = Object.keys(phoneData).reduce((options, countryCode) => {
            const country = phoneData[countryCode]
            if (country.areaCodes){
                //if this country has multiple area codes (it is an array)
                country.areaCodes.forEach(areaCode => {
                    options.push({
                        ...country,
                        areaCode
                    })
                }) 
            } else options.push(country)
            return options
        }, []).sort((countryA, countryB) => alphabeticalSort(countryA.name, countryB.name))
        return areaCodeOptions
    } 

    getCountryFromPhoneNumber = areaCodeOptions => {
        const {value} = this.props
        const dominicaIndex = areaCodeOptions.findIndex(country => country.id === dominicaCountryCode)
        let selectedIndex = dominicaIndex !== -1 ? dominicaIndex : 0
        if (value) {
            /**
             * Match the phone number provided against the country area codes to reverse lookup the country
             */
            //find all countries where the value starts with their area code
            let maxLength = 0
            let matchList = areaCodeOptions.reduce(
                (matchList, country, i) => {
                    if (value.startsWith(country.areaCode)){
                        //if there is an area code match, save the country and its index in the list
                        matchList.push({...country, index: i})
                        //find the length of the longest area code match
                        maxLength = maxLength < country.areaCode.length ?
                                    country.areaCode.length
                                    :
                                    maxLength
                    }
                    return matchList
                }, [])
            //if there are multiple matches take those with the longest area code
            matchList = matchList.filter(country => country.areaCode.length === maxLength)
            if (matchList.length === 0) selectedIndex = selectedIndex
            else if (matchList.length === 1) selectedIndex = matchList[0].index
            else {
                //if there are still multiple matches choose the match with the ssame country 
                //as the users device, if there is one
                const deviceMatch = matchList.find(country => country.id === this.props.device.countryCode)
                const usMatch = matchList.find(country => country.id === usCountryCode)
                const ukMatch = matchList.find(country => country.id === ukCountryCode)
                if (Boolean(deviceMatch)) selectedIndex = deviceMatch.index
                //if the us is one of the countries remaining, select them. Got to give the americans their special treatment
                else if (Boolean(usMatch)) selectedIndex = usMatch.index
                //if the uk is one of the countries remaining, select them. Got to give the brits their special treatment
                else if (Boolean(ukMatch)) selectedIndex = ukMatch.index
                //otherwise just take the first match in the list, the user will fix any errors
                else selectedIndex = matchList[0].index
            }
        } 
        const country = areaCodeOptions[selectedIndex]
        return ({
            country,
            selectedIndex
        })
    }

    handleChangeAreaCode = selectedIndex => {
        const country = this.state.areaCodeOptions[selectedIndex]
        const {onChange, value} = this.props
        //remove the previous area code to get the phone number
        const phoneNumber = value.slice(this.state.areaCode.length)
        const contactNumber = `${country.areaCode}${phoneNumber}`
        this.setState({
            selectedIndex,
            countryCode: country.id,
            areaCode: country.areaCode,
            basePhoneNumberDigits: country.basePhoneNumberDigits
        }, () => {
            onChange(
                contactNumber,
                isValidPhoneNumber(contactNumber,country.areaCode,country.basePhoneNumberDigits)
            )
        })
    }

    handleChangePhoneNumber = e => {
        const {onChange} = this.props
        const {areaCode, basePhoneNumberDigits} = this.state
        const phoneNumber = e.target.value
        //if a non numerical character is provided, ignore
        if (phoneNumber.length >= 1 && isNaN(phoneNumber[phoneNumber.length - 1])) return
        const contactNumber = `${areaCode}${phoneNumber}`
        onChange(
            contactNumber,
            isValidPhoneNumber(contactNumber,areaCode,basePhoneNumberDigits)
        )
    }
    
    render (){
        const {
            readonly,
            value,
            onKeyDown,
            className
        } = this.props
        const {selectedIndex, areaCode, basePhoneNumberDigits} = this.state
        const phoneNumber = value.slice(areaCode.length)
        const contactNumberValid = isValidPhoneNumber(value, areaCode, basePhoneNumberDigits)
        const validStyles = contactNumberValid ? styles.valid : ""
        return (
            <div className={[styles.container, className, validStyles].join(" ")}>
                <div>
                    <select
                        className={styles.areaCode}
                        value={selectedIndex}
                        onChange={e => this.handleChangeAreaCode(e.target.value)}
                    >
                    {
                        this.state.areaCodeOptions.map((option, i) => <option key={i} value={i}>{option.name} {option.areaCode}</option>)
                    }
                    </select>
                </div>
                <input
                    className={className}
                    readOnly={readonly}
                    value={phoneNumber}
                    type="tel"
                    onChange={readonly ? ()=>{} : this.handleChangePhoneNumber}
                    onKeyDown={onKeyDown}
                    placeholder={
                        basePhoneNumberDigits === 10? 'e.g 5551112222' :
                        basePhoneNumberDigits === 7 ? 'e.g 6120011' : ""
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    device: state.device,
    countries: state.countries
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneInput)