import {DateTime, Duration} from "luxon"
import {DOMINICA_TIMEZONE, DAYS_OF_THE_WEEK_ORDER_MAP, DAYS_OF_THE_WEEK, HOLIDAYS, DAY_IN_MILLISECONDS, DAY_TIME_HOUR_LIST} from "../constants/datetime"
import {SORT_DIRECTION_ASC, SORT_DIRECTION_DESC} from '../constants/analysis'
import {baseUrl} from "../config/firebase"
import {logError} from "./errorHandlingUtils"

export const timestampToDateTimeString = (timestamp=Date.now(), showWeekday=false, showTimezone=false, useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    timestamp = Number(timestamp)
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    return DateTime.fromMillis(timestamp, options).toFormat(`${showWeekday ? 'EEEE, ': ""}MMM-dd-yy 'at' t ${showTimezone?  'ZZZZ' : ''}`)
}
export const timestampToDisbursementFilename = timestamp =>  DateTime.fromMillis(timestamp, {locale: "en-US"}).toFormat('MMMdd,yy_h_mmaZZ')
export const dateStringToTimestamp = (dateString, format='yyyy-MM-dd', useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    return DateTime.fromFormat(dateString, format, options).toMillis()
}
export const timestampToDateString = (timestamp, format='yyyy-MM-dd', useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    return DateTime.fromMillis(timestamp, options).toFormat(format)
}

export const excelDateToTimestamp = serial => {
    var utc_days  = Math.floor(serial - 25568);
    var utc_value = utc_days * 86400;                                        
    var date_info = new Date(utc_value * 1000);
 
    var fractional_day = serial - Math.floor(serial) + 0.0000001;
 
    var total_seconds = Math.floor(86400 * fractional_day);
 
    var seconds = total_seconds % 60;
 
    total_seconds -= seconds;
 
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;
 
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds).valueOf();
 }

 export const convert24HrTimeFormatTo12HrTime = time => {
     if (typeof(time) !== "string") return time
     const fragments = time.split(":")
     if (fragments.length !== 2) return time
     let newTime = ""
     if (Number(fragments[0]) > 12){
        newTime+= `${Number(fragments[0]) - 12}`
        newTime +=`:${fragments[1]} PM`
     } else if (Number(fragments[0]) === 12) {
        newTime +=`12:${fragments[1]} PM`
    } else if (Number(fragments[0]) === 0) {
        newTime +=`12:${fragments[1]} AM`
     }
     else {
        newTime +=`${Number(fragments[0])}`
        newTime +=`:${fragments[1]} AM`
     }
     return newTime                  
}

export const getTimestampFrom24HrTimestring = (time, timestampOfDay=Date.now(), timezone=DOMINICA_TIMEZONE) => {
    if (!time || (typeof time !== "string") || !time.includes(":")) return
    const [hour, minute] = time.split(":")
    return getTimestampForDM24HrTime(hour, minute, timestampOfDay, timezone)
}
export const getTimestampForDM24HrTime = (hour, minute, timestampOfDay=Date.now(), timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    options.zone = timezone
    const dt = DateTime.fromMillis(timestampOfDay, options)
                        .set({hour, minute})
    return dt.ts
}  

export const getTimestampForStartOfDay = (timestamp=Date.now(), useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    const dt = DateTime.fromMillis(timestamp, options)
    return dt.startOf('day').ts
}

export const timestampToWeekNumber = (timestamp=Date.now(), useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    const dt = DateTime.fromMillis(timestamp, options) 
    return dt.weekNumber
}

export const weekToWeekStartTimestamp = (weekNumber, weekYear, useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    const dt = DateTime.fromObject({
        weekYear: weekYear,
        weekNumber: weekNumber
      }, options);
    return dt.startOf('week').ts
}

export const timestampToWeekStartTimestamp = (timestamp=Date.now(), useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    const dt = DateTime.fromMillis(timestamp, options);
    //luxon starts weeks on Monday by default, minusing one day to Sun
    //unless today is actually sunday
    if (dt.weekday === 7) return dt.startOf('day').ts
    else return dt.startOf('week').minus({days: 1}).ts 
}


export const getDateString = () => {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    const day = now.getDate()
    return `${year}-${month}-${day}`
}

export const getIntuitiveDateFromTimestamp = ({
    timestamp, 
    now=Date.now(),
    useLocalTimezone=true, 
    timezone=DOMINICA_TIMEZONE,
    format="EEEE dd MMM"
}) => {
    //target date 
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone 
    let dt = DateTime.fromMillis(timestamp, options)
    let nowDt = DateTime.fromMillis(now, options)
    
    let intuitiveDate = dt.toRelativeCalendar({
        base: nowDt,
        unit: "days"
    })
    //for the most common cases (today, tomorrow, yesterday) use the intuitive date returned
    const commonDates = {
        today: true,
        tomorrow: true,
        yesterday: true
    }
    if (commonDates[intuitiveDate]) return intuitiveDate
    //for all other cases, just return the formatted date
    else return dt.toFormat(format)
}

export const syncLocalTimeWithServer = async () => {
    try {
            const response = await fetch(`${baseUrl}v1/utils/get-server-timestamp`);
            const json = await response.json()
            if (!json.success) return false
            const delta = (json.timestamp + 3000) - Date.now()
            Date.localNow = Date.now
            Date.now = () => Date.localNow() + delta
            return true
    } catch (e) {
        e.message = `${e.message}`
        logError(e)
        return false
    }
}
export const getTimeString = () => DateTime.local().toFormat("TT")

export const getDominicaDatestring = () => {
    const d = new Date();
    const values = d.toLocaleString('en-US', { timeZone: 'America/Dominica' }).split(",")[0].split("/")
    const date = values.reduce((date, value, i) => { 
        if (i === 0) {date.month = Number(value) - 1} 
        else if (i === 1) {date.day = Number(value)} 
        else {date.year = Number(value)} 
        return date
    }, {})
    return `${date.year}-${date.month}-${date.day}`
 }

export const timestampsAreOnSameDay = (t1, t2) => {
    if (typeof t1 !== "number" || typeof t2 !== "number") return false  
    const dt1 = DateTime.fromMillis(t1)
    const dt2 = DateTime.fromMillis(t2)
    return dt1.year === dt2.year &&
           dt1.month === dt2.month &&
           dt1.day === dt2.day
  }

export const getTimeObjectFromMilliseconds = milliseconds => {
    if (isNaN(milliseconds)) return ""
    return Duration.fromMillis(milliseconds).shiftTo("hours","minutes","seconds").toObject()
}

export const getTimeDescriptionFromMilliseconds = milliseconds => {
    const timeObject = getTimeObjectFromMilliseconds(milliseconds)
    if (!timeObject) return ""
    else {
        let description = ``
        if (timeObject.hours) description = `${timeObject.hours} hrs`
        if (timeObject.seconds) description = description ? `${description} ${timeObject.minutes} mins` : `${timeObject.minutes} mins`
        if (timeObject.seconds) description = description ? `${description} ${timeObject.seconds} secs` : `${timeObject.seconds} mins`
        return description
    }
}

export const timestampToWeekDay = (
    timestamp=Date.now(),
    useLocalTimezone=true, 
    timezone=DOMINICA_TIMEZONE,
) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    let dt = DateTime.fromMillis(timestamp, options)
    return dt.weekdayLong.toLowerCase()
}

export const getNumberOfDaysBetweenWeekDays = (startWeekDay, endWeekDay) => {
    if (startWeekDay === endWeekDay) return 0
    const startDayNumber = DAYS_OF_THE_WEEK_ORDER_MAP[startWeekDay]
    if (typeof startDayNumber !== "number") throw new Error(`startWeekDay: ${startWeekDay} is not properly formatted`)
    const endDayNumber = DAYS_OF_THE_WEEK_ORDER_MAP[endWeekDay]
    if (typeof endDayNumber !== "number") throw new Error(`endWeekDay: ${endWeekDay} is not properly formatted`)
    if (endDayNumber > startDayNumber){
        //if the end is later in the week than the start e.g start is monday and end is wednesday
        return endDayNumber - startDayNumber
    } else {
        //if the end is earlier in the week than the start e.g start is wednesday and end is monday
        return (7 - startDayNumber) + endDayNumber
    }
}

export const getHolidayFromTimestamp = (timestamp=Date.now(), timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US", zone: timezone} 
    const dateString = DateTime.fromMillis(timestamp, options).toFormat("yyyy-MM-dd")
    return HOLIDAYS[dateString]
}

export const getClosedDayFromTimestamp = (timestamp=Date.now(), closedDays={}, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US", zone: timezone} 
    const dt = DateTime.fromMillis(timestamp, options)
    const weekDayString = dt.toFormat("cccc").toLowerCase() //wednesday
    return closedDays[weekDayString]
}

export const timestampIsOnWorkingDay = (
    timestamp=Date.now(),
    closedDays={},
    timezone=DOMINICA_TIMEZONE
) => {
    /**
     * Purpose: determine whether the day on which a timestamp falls is a working day for that business
     * 
     * Algorithm: a day is a working day if it is 
     *      a) not a closed day for that business (seller/deliveryProvider/deliveryAgent)
     *      b) not a holiday
     * **/
    return !getHolidayFromTimestamp(timestamp, timezone) && 
           !getClosedDayFromTimestamp(timestamp, closedDays, timezone)
}

export const getTimestampOfNextWorkingDay = (
    timestamp=Date.now(),
    closedDays={},
    timezone=DOMINICA_TIMEZONE
) => {
    /**
     * Purpose: find the next working day after the day associated with the timestamp provided
     * 
     * Algorithm: 
     *      a) find the start of the day of the timestamp provided
     *      b) add a day to this day, until we have found the next working day 
     */
    //
    if (Object.keys(closedDays).length >= 7){
        console.warn(`Cannot be closed every day `, closedDays)
        return Date.now()
    }
    let startOfPreviousDay = getTimestampForStartOfDay(timestamp, false, timezone)
    let nextWorkingDay
    while(!nextWorkingDay){
        let startOfNextDay = startOfPreviousDay + DAY_IN_MILLISECONDS
        if (timestampIsOnWorkingDay(startOfNextDay, closedDays, timezone)){
            //if the next day is a working day, then set is as the next working day
            nextWorkingDay = startOfNextDay
        } 
        //otherwise use this day as the previous day
        else startOfPreviousDay = startOfNextDay
    }
    return nextWorkingDay
}

export const getClosedDaysFromOpeningHours = (
    openingHours={}
) => {
    /**
     * Purpose: convert from openingHours schema to simpler, closedDays schema
     * 
     * openingHours schema:
     *      {
     *          monday: {openingTime: "08:00", closingTime: "16:00"},
     *          tuesday: {openingTime: "08:00", closingTime: "16:00"},
     *          wednesday: {openingTime: "08:00", closingTime: "16:00"},
     *          thursday: {openingTime: "08:00", closingTime: "16:00"},
     *          saturday: {openingTime: "08:00", closingTime: "16:00"}
     *          //the absense of a day indicates closure on that day
     *      }
     * 
     * closedDays schema:
     *      {
     *          sunday: true,
     *          friday: true,
     *      }
     */

    if (!openingHours || Object.keys(openingHours).length === 0) return {sunday: true}
    const closedDays = {}
    DAYS_OF_THE_WEEK.forEach(day => {
        //if not in openingHours, add to closedDays
        //TODO in the future, may account for times, based on timezone
        //for now, everything is in the caribbean
        if (!openingHours[day]) closedDays[day] = true
    })
    return closedDays
}

export const timeStringSort = (timeA, timeB, sortDirection=SORT_DIRECTION_ASC) => {
    if (! (typeof timeA === 'string')) {
        console.warn(`alphabeticalSort: timeA ${JSON.stringify(timeA)} is not a string. Time B is ${JSON.stringify(timeB)}`)
        return 0
    }
    if (! (typeof timeB === 'string')) {
        console.warn(`alphabeticalSort: timeB ${JSON.stringify(timeB)} is not a string. Time A is ${JSON.stringify(timeA)}`)
        return 0
    }
    if (timeA === timeB) return 0
    timeA = timeA.replace(/:/g, '')
    timeB = timeB.replace(/:/g, '')
    if (sortDirection === SORT_DIRECTION_ASC) return timeA - timeB
    else if (sortDirection === SORT_DIRECTION_DESC) return timeB - timeA
    else return 0
}

/**
 * Propose: used to keep a time interval valid by ensuring the fromTime is before the toTime and viceversa
 */
export const intervalTimeChangeIsValid = (newTime, isFromTime, otherTime ) => {
    //find the new time's position in the time list
    const newTimeIndex = DAY_TIME_HOUR_LIST.findIndex(time => time.id === newTime)
    const isToTime = !isFromTime
    if (isFromTime){
        const toTime = otherTime //if we are working with the from time, then the other time is the to time

        //toTime must be after fromTime so if the new fromTime is the last entry in the list, then abort
        if (newTimeIndex === (DAY_TIME_HOUR_LIST.length - 1)) {
            return false
        }
        //otherwise, ensure the toTime is after the new fromTime
        const toTimeIndex = DAY_TIME_HOUR_LIST.findIndex(time => time.id === toTime)
        //if the existing toTime is before, or at the same time as the new fromTime
        //then abort
        if (toTimeIndex <= newTimeIndex) {
            return false
        }
        //if everything checks out, approve updating the fromTime to the new time
        return true
    } else if (isToTime){
        const fromTime = otherTime //if we are working with the to time, then the other time is the from time
        //fromTime must be before toTime so if the new toTime is the first entry in the list, then abort
        if (newTimeIndex === 0) {
            return false
        }
        //otherwise, ensure the fromTime is before the new fromTime  
        const fromTimeIndex = DAY_TIME_HOUR_LIST.findIndex(time => time.id === fromTime)
        //if the existing fromTime is after, or at the same time as the new toTime
        //then abort
        if (fromTimeIndex >= newTimeIndex) {
            return false
        }
        //if everything checks out, approve updating the toTime to the new time
        return true
    }
}