import React, {lazy, Suspense} from "react"
import styles from "./App.module.css"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop"
import Loading from "../../components/Loading"
import AppLoader from "../../components/AppLoader"
import Toast from "../Toast"
import CartMergeModal from "../CartMergeModal"
import EventTimer from "../EventTimer"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {selectedEnvironment} from "../../config"
import {version} from "../../config/release"
import { getMessaging, onMessage, isSupported } from "firebase/messaging";

import {getAnalytics, setUserId, setUserProperties} from 'firebase/analytics'
import {initScope, unsubscribeScope} from '../../config/sentry'
import { firebaseApp } from "../../config/firebase";
import { objectsAreEqual } from "../../utils/generalUtils"
import { syncLocalTimeWithServer} from "../../utils/datetimeUtils"
import {STATISTICS_IDENTIFIER} from "../../constants/analysis"

//load the main app
import Home from "../../screens/Home"
import Results from "../../screens/Results"
import ProductDetails from "../../screens/ProductDetails"
import CartDetails from "../../screens/CartDetails"
import Login from "../../screens/Login"
import ResetPassword from "../../screens/ResetPassword"
import CreateAccount from "../../screens/CreateAccount";
import Checkout from "../../screens/Checkout"
import SellerProfile from "../../screens/SellerProfile"
import CategoryProfile from "../../screens/CategoryProfile"
import CustomerProfile from "../../screens/CustomerProfile"
import CustomerRestockRequestsDashboard from "../../screens/CustomerRestockRequestsDashboard";
import The404Page from "../../screens/404"
import Maintenance from "../../screens/Maintenance"
import NotAuthorized from "../../screens/NotAuthorized"
import LimitedAccess from "../../screens/LimitedAccess"
import Unsubscribe from "../../screens/Unsubscribe"
import Blocked from "../../screens/Blocked"
import CardTransactionSuccessful from "../../screens/CardTransactionSuccessful"
import CardTransactionFailed from "../../screens/CardTransactionFailed"
import ShopdmCredit from "../../screens/ShopdmCredit"
import Event from "../../screens/Event"
import PartnerProfile from "../../screens/PartnerProfile"
//LAZY load delivery dashboard

const DeliveryProviderDashboard = lazy(() => import("../../screens/DeliveryProviderDashboard"))

//LAZY load the admin dashboard
const AdminDashboard = lazy(() => import("../../screens/AdminDashboard"))

//LAZY load the seller dashboard
const SellerDashboard = lazy(() => import("../../screens/SellerDashboard"))

//LAZY load all the info pages
const TermsAndConditions = lazy(() => import("../../screens/TermsAndConditions")) 
const SellerTermsOfUse = lazy(() => import("../../screens/SellerTermsOfUse"))
const PrivacyPolicy = lazy(() => import("../../screens/PrivacyPolicy"))
const RefundPolicy = lazy(() => import("../../screens/RefundPolicy"))
const DeliveryPolicy = lazy(() => import("../../screens/DeliveryPolicy"))
const SecurityPolicy = lazy(() => import("../../screens/SecurityPolicy"))
const GiftingInformation = lazy(() => import("../../screens/GiftingInformation"))
const About = lazy(() => import("../../screens/About"))
const HowSellingWorks = lazy(() => import("../../screens/HowSellingWorks"))
const HowDeliveryWorks = lazy(() => import("../../screens/HowDeliveryWorks")) 

initScope()

class App extends React.Component {

  constructor(props){
    super(props)
    //track whether a user is logged in or out and sync actions across tabs
    const sessionListener = props.actions.trackUserSession()
    //check server time to normalize local time via a delta
    syncLocalTimeWithServer()
    this.state = {
      initialized: false,
      sessionListener : sessionListener ? sessionListener : () => {},
      userListener: () => {},
      cartListeners : {},
      promotionsListener: () => {},
      recentlyClosedPromotionsListener: () => {},
      eventsListener: () => {},
      walletListener: () => {}
    }
  }

  componentWillMount(){
    const {actions, system} = this.props
    if (selectedEnvironment !== system.environment) actions.setEnvironment()
    if (version !== system.version) actions.setVersion()
  }
  
  componentDidMount = async () => {
    const {actions, user, device, cart} = this.props  
    if (user.id) {
      const analytics = getAnalytics(firebaseApp) 
      setUserId(analytics, user.id)
      setUserProperties(analytics, {userId: user.id})
      //update the last time the user visited
      actions.fetchSetLastVisitedAt(user.id)
    }
    const recordDevice = async () => {
      if (device.id) await actions.fetchUpdateDevice(device.id)
      else await actions.fetchCreateDevice()
    }
    actions.fetchLoadSearchProducts()
    const responses = await Promise.all([
      (user.id) ? actions.fetchSaveUser(user.id) : () => {},
      actions.fetchSaveActivePromotions(),
      actions.fetchSaveActiveEvents(),
      (user.defaultWalletId) ? actions.fetchSubscribeToMyCreditWallet(user.defaultWalletId) : () => {},
      actions.fetchSaveRecentlyClosedPromotions(),
      (user.id) ? actions.fetchSaveMyOrders(user.id) : true,
      (user.id) ? actions.fetchSaveUserFollows(user.id) : true,
      actions.fetchSaveCategories(),
      actions.fetchSaveSellers(),
      
    ])

    //if the user has cart items stored, but no server cart, create a cart
    //this deals with the case where a user makes a cart offline and then logs in or creates an account
    if (user.authenticated && !cart.id && cart.itemIds.length > 0){
      //if there is no cart id (it is not from the server) and it contains items, save the cart to the server
      await actions.fetchCreateCart()
    }

    //the code below uses the latest version of the user object, loaded above 
    const carts = this.props.user.carts ? this.props.user.carts : {}
    //we force-add the local cart (if any) to the list of loaded cars
    //this ensures the local cart is always loaded from the server
    //and so, avoids the local cart getting out of sync with the server
    if (cart.id && !carts[cart.id]) carts[cart.id] = true
    //listen to user carts 
    const cartListeners = {}
    for (const cartId in carts) {
      //if there are new carts that have not been subscribed to, subscribe to them
      cartListeners[cartId] = await actions.fetchSubscribeToCart(cartId)
     }
    await Promise.all([
      actions.fetchSaveNewestProducts(),
      actions.fetchSaveProductsInCart(),
      actions.fetchSaveProduct(STATISTICS_IDENTIFIER),
      actions.fetchSavePaymentProviders(),
      actions.fetchSaveDeliveryProviders(),
      actions.fetchSaveCountries(),
      recordDevice()
    ])
    actions.toggleLoading(false)
    this.setState({
      initialized: true,
      userListener: responses[0],
      promotionsListener: responses[1],
      eventsListener: responses[2],
      walletListener: responses[3],
      recentlyClosedPromotionsListener: responses[4],
      cartListeners
    })
    
    

    if (user.authenticated && device.messagingToken){
      //if the user is logged in and has a messaging token, then activate device-wide notifications
      this.activateNotifications()
    } 

    //use for non-blocking loading for logged in users
    if (user.authenticated){
      actions.fetchSaveMyLiveRestockRequests(user.id)
    }
  }

  cancelUserListeners = () => {
    //unsubscribe from the user
    if (typeof this.state.userListener === 'function') this.state.userListener()
    //unsubscribe from the user's wallet
    if (typeof this.state.walletListener === 'function') this.state.walletListener()
    //unsubscribe from all of the user's carts
    Object.values(this.state.cartListeners).forEach(listener => {
      if (typeof listener === 'function') listener()
    })
    //clear state for the next user
    this.setState({
      cartListeners: {},
      userListener: () => {},
      walletListener: () => {}
    })
  }

  componentDidUpdate = async prevProps => {
    const {actions, user, cart, device} = this.props
    let {userListener, walletListener} = this.state
    //if the user changes, or a non-authenticated user logs in
    if (
        user.id !== prevProps.user.id && //if the user id has changed
        user.authenticated //and we are currently logged in
      ) {
        //load the new user's orders
        actions.fetchSaveMyOrders(user.id)
        actions.fetchSaveMyLiveRestockRequests(user.id)
        //subscribe to the new user
        userListener = await actions.fetchSaveUser(user.id)
        walletListener = (user.defaultWalletId) ? await actions.fetchSubscribeToMyCreditWallet(user.defaultWalletId) : () => {}
        //if the newly logged in user has an unsaved cart, save it
        if (!cart.id && cart.itemIds.length > 0){
          //if there is no cart id (it is not from the server) and it contains items, save the cart to the server
          await actions.fetchCreateCart()
        }
        this.setState({
          userListener,
          walletListener
        })
    } else if (prevProps.user.authenticated && !user.authenticated){
      //if the user logs out, clear all user specific listeners
      this.cancelUserListeners()
      return
    } else if (
      user.authenticated &&
      prevProps.user.defaultWalletId !== user.defaultWalletId)
    {
      //if there is a change in the user's default wallet, but the user is still logged in
      //for example, the user did not have a default wallet and now they have one
      //or the user changed their default wallet
      
      //1. cancal any existing wallet listeners
      if (typeof walletListener === 'function') walletListener()
      //2. get a new wallet listener, if the user still has a default wallet
      walletListener = (user.defaultWalletId) ? await actions.fetchSubscribeToMyCreditWallet(user.defaultWalletId) : () => {}
      //3. save it in state
      this.setState({walletListener})
    }else if (device.messagingToken && (device.messagingToken !== prevProps.device.messagingToken)){
      //if the device's messaging token has been updated or acquired for the first time
      this.activateNotifications()
    }

    /** The code below checks for changes in the user's cart list and subscribes to new carts/unsubscribes from carts that are removed */
    const cartListeners = {...this.state.cartListeners}
    // deal with persisted user obects from previous app versions
    const carts = user.carts ? user.carts : {}
    const prevCarts = prevProps.user.carts ? prevProps.user.carts : {}
    if (!objectsAreEqual(carts, prevCarts)){
        //if there has been a change to the user's carts
        for (const cartId in carts) {
          //if there are new carts that have not been subscribed to, subscribe to them
          if (!prevCarts[cartId]) cartListeners[cartId] = await actions.fetchSubscribeToCart(cartId)
        }

        for (const cartId in prevCarts) {
          //if there are cartIds in the last list that don't appear in the new one, unsubscribe from them
          if (!carts[cartId] && cartListeners[cartId]) {
            cartListeners[cartId]()
            delete cartListeners[cartId]
            
            //deals with race condition where device unsubscribes before clearing cart
            if (cartId === cart.id) actions.clearCart(cartId)
          }
        }
        this.setState({
          cartListeners,
        })
    } 
  }
  
  componentWillUnmount(){
    if (typeof this.state.sessionListener === 'function') this.state.sessionListener()
    if (typeof this.state.promotionsListener === 'function') this.state.promotionsListener()
    if (typeof this.state.recentlyClosedPromotionsListener === 'function') this.state.recentlyClosedPromotionsListener()
    if (typeof this.state.eventsListener === 'function') this.state.eventsListener()
    this.cancelUserListeners()
    unsubscribeScope()
  }

  activateNotifications = async () => {
    const {actions} = this.props
    const supported = await isSupported()
    if (!supported) return
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      const {title, body} = payload.notification
      const {data} = payload
      actions.showNotification(
        title, 
        body, 
        data            
      )
    });
  }

  render(){
    const {system, user, cart} = this.props
    if (!this.state.initialized) return <AppLoader/>
    return (
    <div className={styles.container}>
      <Router>
        <Suspense fallback={<Loading />}>
          <ScrollToTop>
          <Switch>
            {
              system.maintenance ?
              <Route path="*" component={Maintenance}/>
              :
              null
            }
            {
              system.limitedAccess &&  !user.authenticated ?
              <Route path="*" component={LimitedAccess}/>
              :
              null
            }
            <Route exact path="/" component={Home} />
            <Route path="/results" component={Results} />
            <Route path="/product-details" component={ProductDetails} />
            <Route path="/cart-details" component={CartDetails} />
            <Route path="/login" component={Login} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/create-account" component={CreateAccount} />
            <Route path="/checkout" component={Checkout} />
            <Route exact path="/sellers/:sellerId" component={SellerProfile} />
            <Route exact path="/categories/:categoryId" component={CategoryProfile} />
            <Route exact path="/customers/:userId" component={CustomerProfile} />
            <Route exact path="/customers/:userId/restock-requests" component={CustomerRestockRequestsDashboard} />
            <Route exact path="/p/:partnerHandleId" component={PartnerProfile} />
            <Route path="/sellers/:sellerId/dashboard" component={SellerDashboard}/>
            <Route path="/delivery-providers/:deliveryProviderId/dashboard" component={DeliveryProviderDashboard}/>
            <Route path="/admin/dashboard" component={AdminDashboard}/>
            <Route path="/not-authorized" component={NotAuthorized}/>
            <Route path="/terms-and-conditions" component={TermsAndConditions}/>
            <Route path="/seller-terms-of-use" component={SellerTermsOfUse}/>
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/refunds" component={RefundPolicy} />
            <Route path="/delivery-policy" component={DeliveryPolicy} />
            <Route path="/security-policy" component={SecurityPolicy} />
            <Route path="/unsubscribe" component={Unsubscribe} />
            <Route path="/blocked" component={Blocked} />
            <Route path="/card-transaction-successful" component={CardTransactionSuccessful} />
            <Route path="/card-transaction-failed" component={CardTransactionFailed} />
            <Route path="/shopdm-credit/:userId" component={ShopdmCredit} />
            <Route path="/explanations/how-gifting-works" component={GiftingInformation} />
            <Route path="/about" component={About} />
            <Route path="/how-selling-works" component={HowSellingWorks} />
            <Route path="/how-delivery-works" component={HowDeliveryWorks} />
            <Route exact path="/events/:eventId" component={Event} />
            <Route path="*" component={The404Page}/>
          </Switch>
          </ScrollToTop>
          {system.notification ? <Toast/> : null}
          <EventTimer />
        </Suspense>
      </Router>
      {system.loading ? <Loading text={system.loadingText}/> : null}
      
      {cart.cartsToMergeByCartId && Object.keys(cart.cartsToMergeByCartId).length > 0 ? <CartMergeModal /> : null}
    </div>
    )
  }
}

const mapStateToProps = state => ({
  system: state.system,
  user: state.user,
  device: state.device,
  cart: state.cart
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);