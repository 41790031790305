import React from "react"

import Form from "../../components/Form"
import FormField from "../../components/FormField"

import {isValidUSAZipCode} from "../../utils/formValidationUtils"
import styles from "./BuyerAddressForm.module.css"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {capitalizeAllWords} from "../../utils/stringUtils"
import {DOMINICA_COUNTRY_ID, usaCountryId} from "../../constants/country"

class AddressForm extends Form{

    constructor(props){
        super(props)
        let contactNumber = ""
        let contactNumberValid = false
        let countryId = this.fields.countryId
        if (props.address){
            if (props.address.contactNumber && typeof props.address.contactNumber === 'string') {
                contactNumber = props.address.contactNumber
                contactNumberValid = true
            }     
            if (props.address.countryId) countryId = props.address.countryId 
        } 
        this.state = {
            values: {...this.fields, ...props.address, contactNumber, contactNumberValid, countryId},
            errors: {},
            settlements: this.getSettlements(countryId)
        }
    }

    fields = {
        line1: "",
        line2: "",
        city: "",
        settlementId:"",
        countryId: DOMINICA_COUNTRY_ID,
        contactNumber: "",
        contactNumberValid: false,
        directions: "",
        zipCode:"",
        usaCity: ""
    }

    identifier = 'line1'

    getSettlements = countryId => {
        const {countries} = this.props 
        const country = countries.countriesById[countryId]
        if (!country) {
            console.warn(`country ${country} does not exist`)
            return []
        } 
        return Object.keys(country.settlements)
                    .map(settlementId => ({
                            id: settlementId,
                            name: capitalizeAllWords(country.settlements[settlementId])
                        }))
                        .sort((a,b) =>{
                            if ( a.name < b.name ){
                                return -1;
                                }
                                if ( a.name > b.name ){
                                return 1;
                                }
                                return 0;
                        })
    }

    executeSubmit = async () => {
        let {submit, countries} = this.props
        const {line1, line2,city, settlementId, countryId, contactNumber,directions, usaCity, zipCode}= this.state.values
        const country = countries.countriesById[countryId]
        return await submit(
            line1.toLowerCase().trim(),
            line2.toLowerCase().trim(),
            city.toLowerCase(),
            settlementId,
            country.name,
            countryId,
            contactNumber,
            directions,
            usaCity.toLowerCase().trim(),
            zipCode.trim(),
            this.handleSubmitSuccess, 
            this.handleSubmitError
        )
    }

    validate = () => {
        const errors = {}
        const {values} = this.state
        const {countries} = this.props
        const {line1, line2,city, countryId, contactNumber, contactNumberValid, directions, zipCode} = values
        const country = countryId && countries.countriesById[countryId] ? countries.countriesById[countryId] : {}
        Object.keys(values).forEach(key => {
            if (!Boolean(values[key]) &&
                (key !== "line2") &&
                (key !== "directions") &&
                (key !== "line1") &&
                //usa specific address params
                (!((countryId !== usaCountryId) && (key === "zipCode"))) &&
                (!((countryId !== usaCountryId) && (key === "usaCity")))
            ){
                const label = key === 'countryId'? "country" 
                            : key === "contactNumber" ? "recipent's phone number" 
                            : key === "settlementId" ? `${countryId === usaCountryId ? 'state' : 'town or village'}` 
                            : key === "city" ? "town/village/capital" : key
                errors[key] = `${label} is required`
            }
        })
        if (!errors["contactNumber"] && !contactNumberValid) errors["contactNumber"] = `phone number is invalid`
        if ((countryId === usaCountryId) && !errors["zipCode"] && !isValidUSAZipCode(zipCode)) errors["zipCode"] = "zip code must be in the format 12345 or 12345-5555"
        if (!line1.trim() && !directions.trim()){
            errors["line1"] =`must provide a street address${countryId === DOMINICA_COUNTRY_ID? ' or directions' : ""}`
            errors["directions"] = `must provide a street address${countryId === DOMINICA_COUNTRY_ID? ' or directions' : ""}`
        }
        if (Object.values(errors).every(error => !error)) return true
        else {
            this.setState({errors}, () => {
                if (!errors["contactNumber"]) this.scrollToTop()
            })
            return false
        }
    }

    handleSubmitSuccess = () => {
        const {postSubmit} = this.props
        if (postSubmit) postSubmit()
    }
    handleSubmitError = (object) => {
        const errors = {}
        errors["createAddress"] = `Failed to create address`
        this.setState({errors})
    }

    clearFields = () => {
        this.setState({
            values: {...this.fields},
            settlements: this.getSettlements(this.fields.countryId)
        })
    }

    handleChangeCountry = countryId => {
        this.setState({
            values: {
                ...this.state.values,
                countryId,
                settlementId: "",
                city: "",
                contactNumber: "",
                usaCity:"",
                zipCode: "",
                directions: ""
            },
            errors: {},
            settlements: this.getSettlements(countryId)
        })
    }

    handleChangeSettlement = settlementId => {
        const {values} = this.state
        const {countries} = this.props
        if (!values.countryId) return
        const city = countries.countriesById[values.countryId].settlements[settlementId]
        this.setState({
            values: {
                ...values,
                settlementId,
                city,
            },
            errors: {}
        })
    }

    handleChangeContactNumber = (contactNumber, contactNumberValid) => {
        const {values} = this.state
        this.setState({
            values: {
                ...values,
                contactNumber,
                contactNumberValid
            },
            errors: {}
        })
    }

    render(){
        const {values, errors, successfullyCreatedAddress, settlements} = this.state
        const {line1, line2, city, directions, countryId, contactNumber, settlementId, zipCode, usaCity} = values
        const {formTitle, buttonText, countries} = this.props
        const countryData = countries.countryIds.map(countryId => {
            const country = countries.countriesById[countryId]
            return {id: country.id, name: capitalizeAllWords(country.name)}
        })
        const country = countryId && countries.countriesById[countryId] ? countries.countriesById[countryId] : {}
        return (
            <div className={['form', styles.container].join(" ")}>
                <div className={"formTitle"}>{formTitle}</div>
                {successfullyCreatedAddress ? <div className={'successBar'}>{successfullyCreatedAddress}</div> : null}
                {
                    errors.createAddress ?
                    <span className={'errorText'}>
                        {errors.createAddress}
                    </span>
                    :
                    null
                }
                <FormField 
                    label="country" 
                    isDropDown={true}
                    allowBlank={false}
                    dataArray={countryData}
                    value={countryId} 
                    onChange={this.handleChangeCountry}
                    errorText={errors.countryId}
                    note="Only delivering to Dominica and the USA"
                />
                <FormField 
                    label={countryId === usaCountryId ? "state *" : "town or village *"} 
                    value={settlementId} 
                    isDropDown={true}
                    dataArray={settlements}
                    onChange={this.handleChangeSettlement}
                    errorText={errors.settlementId}
                    note={city? `selected: ${capitalizeAllWords(city)}` : null}
                />
                <FormField 
                    label="street address" 
                    value={line1} 
                    onChange={e => this.handleChange(e, 'line1')}
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.line1}
                    placeholder="e.g. 61 Hillsborough St, skip if none"
                />
                <FormField 
                    label="apt no./office no./name of establishment" 
                    value={line2} 
                    onChange={e => this.handleChange(e, 'line2')}
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.line2}
                    placeholder="e.g. Flat 5 or 4th Floor or Fire Station, skip if none"
                />
                { 
                    countryId === DOMINICA_COUNTRY_ID ?
                    <FormField 
                        label="directions" 
                        value={directions} 
                        isTextArea={true}
                        onChange={e => this.handleChange(e, 'directions')}
                        errorText={errors.directions}
                        placeholder="e.g. take the first left after the fire station, third house on your right"
                    />
                    :
                    null
                }
                
                { 
                    countryId === usaCountryId ?
                    <React.Fragment>
                        <FormField 
                            label="city *" 
                            value={usaCity} 
                            onChange={e => this.handleChange(e, 'usaCity')}
                            errorText={errors.usaCity}
                        />
                        <FormField 
                            label="zip code *" 
                            value={zipCode} 
                            onChange={e => this.handleChange(e, 'zipCode')}
                            errorText={errors.zipCode}
                        />
                    </React.Fragment>
                    :
                    null
                }
                
                <FormField 
                    label="recipient's phone number *"
                    type="tel" 
                    value={contactNumber} 
                    onChange={this.handleChangeContactNumber}
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.contactNumber}
                />
                <button className="button primary" onClick={this.handleSubmit}>{buttonText}</button>
            </div>
        )
    }
    
}

const mapStateToProps = state => ({
    countries: state.countries
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);