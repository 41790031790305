import React from "react"

import LineItem from "../../components/LineItem"
import styles from "./OrderInvoice.module.css"
import {alphabeticalSort} from "../../utils/stringUtils"

import {connect} from "react-redux"

function OrderInvoice({
        cart, 
        products,
        subTotal,
        deliveryFee,
        giftFee,
        processingFee,
        total,
        paymentProcessorTotal,
        discount,
        promotionCount,
        shopdmCreditUsed,
        shopdmCreditOwed
}){
    const items = cart.itemIds.map(itemId => {
        const item = cart.itemsByProductStockId[itemId]
        return item
    }).sort((itemA, itemB)=>{
        const productA = products.productsById[itemA.productId]
        const productB = products.productsById[itemB.productId]
        if (!productA || !productB) return 0
        return alphabeticalSort(productA.titleAndBrand, productB.titleAndBrand)
    })
    return (
        <div className={styles.container}>
            <div className={styles.section}>
                {
                    items.map(item => {
                        const product = products.productsById[item.productId]
                        if (!product) return ""
                        return <LineItem 
                                    key={item.id}
                                    quantity={item.quantity}
                                    label={product.titleAndBrand}
                                    value={item.price * item.quantity}
                                />
                    })
                }
            </div>
            <div className={styles.section}>
                <LineItem 
                    label="Subtotal"
                    value={subTotal}
                />
                {
                    discount > 0 ?
                    <LineItem 
                        label={`${promotionCount} Discount(s)`}
                        value={discount}
                        operation="-"
                        bold={true}
                    />
                    :
                    null
                }
                <LineItem 
                    label="Delivery Fee"
                    value={deliveryFee}
                />
                {
                    giftFee ?
                    <LineItem 
                        label="Gift Wrapping"
                        value={giftFee}
                    />
                    :
                    null
                }
                <LineItem 
                    label="Processing Fee"
                    value={processingFee}
                />
                {
                    shopdmCreditOwed > 0 ?
                    <LineItem 
                        label="Shopdm Credit Owed"
                        value={shopdmCreditOwed}
                        operation="+"
                        bold={true}
                    />
                    :
                    null
                }   
            </div>
            
            {
                shopdmCreditUsed > 0 ?
                <React.Fragment>
                    <div className={styles.section}>
                        <LineItem 
                            label="Total"
                            value={total}
                            bold={true}
                        />
                        <LineItem 
                            label="Shopdm Credit Used"
                            value={shopdmCreditUsed}
                            operation="-"
                            bold={true}
                        />
                    </div> 
                    <LineItem 
                        label="Total To Pay"
                        value={paymentProcessorTotal}
                        bold={true}
                    />
                </React.Fragment>
            
                :
                <LineItem 
                    label="Total"
                    value={total}
                    bold={true}
                />
            }  
        </div>
    )
}

const mapStateToProps = state => ({
    cart: state.cart,
    products: state.products
})

export default connect(mapStateToProps)(OrderInvoice);