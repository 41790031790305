/**
 *  Purpose: the modifications that are carried on the Disbursement objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {STATISTICS_IDENTIFIER} from "../constants/analysis"
import {SELLER} from "../constants/payouts"

// STATE
// disbursements:{
//  disbursementsById:{},
//  disbursementsBySellerId: {},
//  disbursementIdsByOrderId: {},
//  disbursementIds: [],
//  lastDisbursementReference: 0
// }


const disbursementsReducer = (state = initialState.disbursements, action) => {
    const {type, payload} = action;
    let disbursementsById = {...state.disbursementsById}
    let disbursementsBySellerId = {...state.disbursementsBySellerId}
    let disbursementIds = {...state.disbursementIds}
    let disbursementIdsByOrderId = {...state.disbursementIdsByOrderId}
    let stats = {...state.stats}
    let lastDisbursementReference = state.lastDisbursementReference
    switch (type){
        case types.SAVE_DISBURSEMENTS: {
            if(typeof payload.disbursements !== "object") {
                logError(`disbursementsReducer > SAVE_DISBURSEMENTS: disbursements payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.disbursements.forEach(disbursement => {
                if (disbursement.id !== STATISTICS_IDENTIFIER){
                    disbursementsById[disbursement.id] = disbursement
                    if (disbursement.payeeType === SELLER) {
                        //if the disbursement was sent to a seller, add it to disbursementsBySellerId
                        if (!disbursementsBySellerId[disbursement.payeeId]) disbursementsBySellerId[disbursement.payeeId] = {}
                        disbursementsBySellerId[disbursement.payeeId][disbursement.id] = true
                    }
                    if (disbursement.orderIds){
                        disbursement.orderIds.forEach(orderId => {
                            disbursementIdsByOrderId[orderId] = disbursement.id
                        })
                    }   
                    if (disbursement.reference > lastDisbursementReference) lastDisbursementReference = disbursement.reference
                } else stats = disbursement
            })
            
            return {
                ...state,
                disbursementsById,
                disbursementsBySellerId,
                disbursementIds: Object.keys(disbursementsById),
                disbursementIdsByOrderId,
                stats,
                lastDisbursementReference
            }
        }

        case types.LOGOUT: {
            return initialState.disbursements
        }
        
        default: return state
    }
}

export default disbursementsReducer