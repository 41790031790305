import React from 'react'
import Icon from '../Icon'
import styles from './CloseButton.module.css'

import PropTypes from 'prop-types'
import {CLOSE_BUTTON_THEME_DARK, CLOSE_BUTTON_THEME_LIGHT} from "../../constants/interaction"
function CloseButton ({onClick=()=>{}, theme=CLOSE_BUTTON_THEME_DARK}) {
    const themeStyle = theme === CLOSE_BUTTON_THEME_DARK ? 
                        styles.darkTheme : 
                    theme === CLOSE_BUTTON_THEME_LIGHT ?
                        styles.lightTheme :
                        null
    return <span 
                className={[styles.container, themeStyle].join(" ")} 
                onClick={onClick}>
                <Icon icon="close"/>
            </span>
}   

CloseButton.propTypes = {
    onClick: PropTypes.func,
    theme: PropTypes.oneOf([CLOSE_BUTTON_THEME_DARK, CLOSE_BUTTON_THEME_LIGHT])
}
export default CloseButton;