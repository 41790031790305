import React from "react"
import AvatarIcon from "../AvatarIcon"
import styles from "./UserRow.module.css"

const UserRow = ({name, imageUrl, userId,}) => {
    return (
        <div className={styles.container}>
            <div className={styles.userContainer}>
                <AvatarIcon 
                    name={name}
                    imageUrl={imageUrl}
                />
                <div className={styles.userName}>{name}</div>
            </div>
        </div>
    )
}

export default UserRow