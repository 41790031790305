import React from 'react'
import styles from "./Toast.module.css"
import Icon from "../../images/logo.png"
import CloseButton from "../../components/CloseButton"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

class Toast extends React.PureComponent{
    state = {
        popup: false
    }
    componentDidMount(){
        this.setState({popup: true})
        setTimeout(this.handeClose, 30000)
    }

    handleClick = () => {
        const { history, system} = this.props
        const {link} = system.notification
        if (link){
            history.push(link)
        }
        this.handeClose()
    }

    handeClose = () => {
        const {actions} = this.props
        actions.hideNotification()
    }
    render () {
        const {notification} = this.props.system
        if (!notification) return ""
        const {icon, title, body} = notification
        const popupStyles = this.state.popup ? `${styles.container} ${styles.popup}` : styles.container
        return (
            <div className={popupStyles}>
                <div className={styles.iconContainer} onClick={this.handleClick}>
                    <img src={icon ? icon : Icon}/>
                </div>
                <div className={styles.contentContainer} onClick={this.handleClick}>
                    <div className={`strong ${styles.title}`}>
                        {title}
                    </div>
                    <div className={styles.body}>{body}</div>
                </div>
                <div>
                    <CloseButton onClick={this.handeClose}/>
                </div>
            </div>
        )
    }
}

const mapStatetoProps = state => ({
    system: state.system
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(Toast))