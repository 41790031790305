import React from 'react'
import PaymentModal from "../PaymentModal"

function PayInstoreModal({logo, reference, amount2DecimalPlaces, isOpen, closeModal, onPressConfirm, email}){
    return (
        <PaymentModal
            paymentTitle='Cash On Pickup'
            logo={logo}
            reference={reference}
            amount2DecimalPlaces={amount2DecimalPlaces}
            isOpen={isOpen}
            closeModal={closeModal}
            onPressConfirm={onPressConfirm}
        >
            <div>
                <div className="info-bubble">1. <span>Press 'Confirm and Place Order'</span> to let us know we should start processing your order</div>
                <div className="info-bubble">2. We will email you at <span>{email}</span> when your items are ready for pickup</div>
                <div className="info-bubble">3. <span>Bring EC${amount2DecimalPlaces} and your order number</span> when you come to pick up your items. You'll get more information in the emails</div>
            </div>
        </PaymentModal>
    )
}
export default PayInstoreModal