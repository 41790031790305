import React from "react"
import RadioButton from "../RadioButton"
import DataSelect from "../../components/DataSelect"
import AvatarIcon from "../AvatarIcon"
import DateHighlight from "../DateHighlight"

import styles from "./DeliveryMethodOption.module.css"

import {
    DELIVERY_PROVIDER_TYPE_AGENT_BASED,
    PICK_UP_INSTORE_ID, 
    SELF_DELIVERY_ID, 
    SHOPDM_DELIVERY_ID,
} from "../../constants/delivery"
import {FIXED_FEE, LOCATION_BASED_FEE} from "../../constants/fees"
import {AVATAR_SIZE_MEDIUM} from "../../constants/interface"

import {addressObjectToString, capitalizeAllWords} from "../../utils/stringUtils"
import Pickup from "../../images/pickup.png"
import ShopdmLogo from "../../images/logo200x200.png"

const DeliveryMethodOption = ({
    deliveryProvider,
    isActive=true, //is the option available to be selected?
    selected=false, //is the option selected?
    selectedIndex=1, //the order the seller appears on the page, if it has selected this delivery provider
    userAddress={},
    giftHandlingRequired=false,
    numOfSellersSelected=0,
    numOfSellersAvailable=0,
    onSelect=()=>{},
    estimatedFulfilledAt=0,
    //only used for self delivery
    sellerName,
    sellerLogo="",
    sellerDeliverySettings={},
    //only used for pickup instore
    pickupAddresses=[],
    selectedPickupAddressId="",
    onSelectPickupAddress=()=>{},
}) => {
    //make tiles dark grey if unavailable
    const selectedStyle = !isActive?
                        'unavailable'
                        :
                        selected ?
                        'selected'
                        :
                        ""
    //show the name of the delivery provider, unless it is merchant delivery or pick up instore
    const deliveryName = deliveryProvider.id === PICK_UP_INSTORE_ID?
                         "Pick Up Instore"
                         :
                         deliveryProvider.id === SELF_DELIVERY_ID ?
                         `Delivered By ${sellerName}`
                         :
                         capitalizeAllWords(deliveryProvider.name)
    //show the logo of the delivery provider, unless it is Fulfilled by Shopdm, merchant delivery or pickup instore
    const logo = deliveryProvider.id === SHOPDM_DELIVERY_ID ?
                ShopdmLogo 
                :
                deliveryProvider.id === PICK_UP_INSTORE_ID ?
                Pickup
                :
                deliveryProvider.logo
    const deliveryLogo = deliveryProvider.id === SELF_DELIVERY_ID ?
                        <AvatarIcon name={sellerName} imageUrl={sellerLogo} size={AVATAR_SIZE_MEDIUM}/>
                        :
                        <img className='providerLogo' src={logo} alt={deliveryName}/>
    /**
     * if inactive, it is because: 
     * 1. gift handling is required, but not available
     * 2. this provider does not deliver to the user's country
     * 3. this provider does not delivery to the user's settlement/delivery area
    **/
    const inActiveMessage = giftHandlingRequired && !deliveryProvider.giftHandling ?
                                `Sorry, we can't prepare your gift if you
                                    ${deliveryProvider.id === SELF_DELIVERY_ID ? 
                                        " ask the merchant to deliver"
                                        :
                                        deliveryProvider.id === PICK_UP_INSTORE_ID ?
                                        " choose to pick it up"
                                        :
                                        ` use ${deliveryName}`
                                }`
                            :
                            !deliveryProvider.deliveryCountryIds[userAddress.countryId] ?
                                `Sorry, not available in ${capitalizeAllWords(userAddress.countryName)}`
                            :
                            deliveryProvider.deliverySettlementIds &&
                            deliveryProvider.deliverySettlementIds[userAddress.settlementId] === undefined ?
                            `Does not deliver to ${capitalizeAllWords(userAddress.city)}`
                            :
                            deliveryProvider.type === DELIVERY_PROVIDER_TYPE_AGENT_BASED &&
                            deliveryProvider.deliverySettlementIds &&
                            deliveryProvider.deliverySettlementIds[userAddress.settlementId] &&
                             !estimatedFulfilledAt ?
                            `No agents available to deliver to ${capitalizeAllWords(userAddress.city)}`
                            :
                            ""
    return (
        <div key={deliveryProvider.id} className={['checkoutOption', selectedStyle, styles.container].join(" ")} onClick={isActive ? onSelect : () => {}}>
            <div className={styles.deliveryLogo}>{deliveryLogo}</div>
            <RadioButton 
                checked={selected}  
                isActive={isActive}
            >
                <div className={styles.innerContainer}>
                
                    <div className={styles.deliveryName}>{deliveryName}</div>
                    {
                        !isActive ?
                        // if not active, display the inactive message
                        <div className={[styles.additionalInfo, styles.error].join(" ")}>
                            {inActiveMessage}
                        </div>
                        :
                        <React.Fragment>
                            <div className={styles.fee}>
                                {
                                    getDeliveryFee(
                                        deliveryProvider, 
                                        userAddress, 
                                        sellerDeliverySettings,
                                        numOfSellersSelected,
                                        numOfSellersAvailable,
                                        selectedIndex
                                    )
                                }
                            </div>
                            {
                                deliveryProvider.id === PICK_UP_INSTORE_ID ?
                                    <PickupAddressDropdown 
                                        addressArray={pickupAddresses}
                                        selectedPickupAddressId={selectedPickupAddressId}
                                        onSelectPickupAddress={onSelectPickupAddress}
                                        isSelected={selected}
                                    />
                                :
                                null
                            }
                            <div className={styles.getIt}>
                                <div>Next {deliveryProvider.id === PICK_UP_INSTORE_ID ? "Pick Up" : "Delivery"} Slot</div>
                                <div><DateHighlight timestamp={estimatedFulfilledAt}/></div>
                            </div>
                        </React.Fragment>

                    }
                    
                </div>
            </RadioButton>
        </div>
    )
}

const getDeliveryFee = (deliveryProvider, userAddress, sellerDeliverySettings, numOfSellersSelected, numOfSellersAvailable, selectedIndex) => {
    //pick up instore is always free
    if (deliveryProvider.id === PICK_UP_INSTORE_ID) return "Free" 
    //for merchant delivery, look up their delivery settings
    else if (deliveryProvider.id === SELF_DELIVERY_ID){
        const {fixedFee} = sellerDeliverySettings
        if (fixedFee === 0) return "Free"
        else return `EC $${Number(fixedFee).toFixed(2)}`
    } else {
        //handle all other delivery providers here
        //delivery providers can have different pricing strategies e.g. fixed vs location based
        const {feeType} = deliveryProvider
        //for fixed free providers (same fee regardless of location)
        if (feeType === FIXED_FEE){
            const {fixedFee} = deliveryProvider
            if (fixedFee === 0) return "Free"
            else return `EC $${Number(fixedFee).toFixed(2)}`
        } 
        //for location-based providers
        else if (feeType === LOCATION_BASED_FEE) {
            //look up the fee in their fee table
            const basicFee = deliveryProvider.deliverySettlementIds[userAddress.settlementId]
            const basicFeeString = `EC $${basicFee.toFixed(2)}`
            let feeDescription = basicFeeString

            if (deliveryProvider.consolidation && numOfSellersAvailable > 1){
                /**
                 * For Delivery providers that offer consolidation:
                 * 
                 * if the delivery provider is only available for one seller, we show the basic price
                 * if he is available for more than one seller, but none has selected him, the basic price is shown on all
                 * 
                 * if there are more than one seller that offer this delivery provider, and one has selected him
                 * then the first on the page that has selected him shows the basic price, 
                 * the others show the added consolidation price. This is the case whether or not they are selected 
                 */
                 const consolidationFee = deliveryProvider.consolidation.fixedFee
                 const consolidationFeeString = consolidationFee === 0?
                                                "Free"
                                                :
                                                `+ EC $${Number(consolidationFee).toFixed(2)}`
                if(
                    numOfSellersSelected > 0 && //if this delivery provider is selected for a previous seller on the order
                    (selectedIndex !== 0) // and they are not the first in the list of sellers who have selected this delivery provider  
                ){
                    feeDescription = consolidationFeeString
                } else if (numOfSellersSelected === 0){
                    return (
                    <div className={styles.explanation}>
                      <div><span>{basicFeeString}</span> (1st store)</div>
                      <div><span>{consolidationFeeString}</span> (2nd stores) </div>                      
                    </div>
                    )
                }
            }
            return feeDescription
        } 
        else return "0"
    }
}

const PickupAddressDropdown = ({addressArray, selectedPickupAddressId="", onSelectPickupAddress=()=>{}, isSelected=false}) => {
    const dataArray = addressArray.map(address => ({...address, name: addressObjectToString(address)}))
    // display the opening time for the selected location when a selection is available. Otherwise use the default address
    const sellerAddress = selectedPickupAddressId ?
                            addressArray.find(address => address.id === selectedPickupAddressId)
                            :
                            null
    return (
        <div className={`${styles.pickupAddressContainer} ${sellerAddress ? "" : styles.noPickupChoice}`}>
            {
                isSelected ?
                <DataSelect 
                    dataArray={dataArray}
                    selected={selectedPickupAddressId}
                    onSelect={onSelectPickupAddress}
                    defaultText="choose a location"
                />
                :
                null
            }
        </div>
    )
}
 

export default DeliveryMethodOption