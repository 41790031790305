import React from "react";
import styles from "./AuthProviderButton.module.css";
import PropTypes from "prop-types";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {fetchCreateOrLoginUserWithGoogle} from "../../actions";

 import {isWebView} from "../../utils/deviceUtils"

AuthProviderButton.propTypes = {
    providerName: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
};

function AuthProviderButton({actions, onSuccess, onError}) {
    if (isWebView()) {
        return ""
    }
    return (
        <div className={styles.container} onClick={() => actions.fetchCreateOrLoginUserWithGoogle(onSuccess, onError)}>
            <div className={styles.google}>
                <img src={"https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2Finterface%2Fbtn_google_signin_light_normal_web%402x.png?alt=media&token=6c8d9107-4745-4bde-bff3-4dcbdb46db54"}
                     alt={"Google sign in button"}
                     draggable={"false"}
                />
            </div>
        </div>
    );
}
const mapStateToProps = state => ({
    user: state.user,
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(AuthProviderButton);
