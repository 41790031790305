import React from "react"
import FormField from "../FormField"
import Icon from "../Icon"
import styles from "./PasswordFormField.module.css"

class PasswordFormField extends React.Component {
    state = {
        showPassword: false
    }

    toggleShowPassword = () => this.setState({showPassword: !this.state.showPassword})

    render(){
        const {
            label,
            value, 
            onChange,
            onKeyDown,
            errorText,
            note
        } = this.props
        const {showPassword} = this.state
        return (
            <div className={styles.container}>
                {
                    showPassword ?
                    <FormField 
                        label={label}
                        type="text"
                        value={value}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        errorText={errorText}
                        note={note}
                        inputIconButton="visibility"
                        onClickInputIconButton={this.toggleShowPassword}
                    /> :
                    <FormField 
                        label={label}
                        type="password"
                        value={value}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        errorText={errorText}
                        note={note}
                        inputIconButton="visibility-off"
                        onClickInputIconButton={this.toggleShowPassword}
                    />
                }
            </div>
        )
    }
}

export default PasswordFormField