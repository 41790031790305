import React from "react"
import Icon from "../Icon"
import Cropper from "react-easy-crop"
import pica from 'pica'

import styles from "./PhotoUpload.module.css"
import {IMAGE_TYPE_JPG, IMAGE_TYPE_PNG} from "../../constants/image"
import {compressImageFile} from "../../utils/fileUtils"
import {logError} from "../../utils/errorHandlingUtils"

class PhotoUpload extends React.PureComponent {
    
    state = {
        crop: {x: 0, y: 0},
        zoom: 1
    }

    static defaultProps = {
        imageUrl: "", 
        onChange: ()=>{}, 
        accept: "image/png, image/jpeg", 
        buttonText: "Upload Image", 
        maxImageDimensions: 500,
        customStyle: {},
        cropWidth: null,
        cropLength: null,
        cropShape: 'round',
        compressImage: true,
    }

    fileInput = null

    handleSelectImage = async e => {
        const {onChange, maxImageDimensions, compressImage} = this.props
        let localImageUrl, imageFile
        if(e.target.files.length > 0){
            if (e.target.files[0].type !== IMAGE_TYPE_JPG && e.target.files[0].type !== IMAGE_TYPE_PNG){
                return
            }
            if (compressImage){
                //if set to auto compress images
                let compressedFile = await compressImageFile(e.target.files[0], maxImageDimensions)
                if (!compressedFile) return
                imageFile = compressedFile
            } else {
                imageFile = e.target.files[0]
            }
            localImageUrl = URL.createObjectURL(imageFile)
            onChange(localImageUrl, imageFile)
        } else  logError("PhotoUpload failed to import photo")
    }

    handleClick = () => {
        if (this.fileInput) this.fileInput.click()
    } 

    handleCropComplete = async (croppedArea, croppedAreaPixels) => {
        if (isNaN(croppedAreaPixels.x)) return
        const {imageUrl, imageFile, onCrop, cropWidth, cropHeight} = this.props
        console.log(imageFile)
        const croppedImageFile = await this.getCroppedImage(imageUrl, croppedAreaPixels, cropWidth, cropHeight, imageFile.type)
        onCrop(croppedImageFile)
    }

    createImage = (url) => new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.src = url
    })

    getCroppedImage = async (imageUrl, pixelCrop, cropWidth, cropHeight, fileType) => {
        const image = await this.createImage(imageUrl)
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = cropWidth;
        canvas.height = cropHeight;
        
      
        // ctx.imageSmoothingQuality = "high";
      
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          canvas.width,
          canvas.height
        );

        const resultCanvas = document.createElement("canvas");
        resultCanvas.width = canvas.width
        resultCanvas.height = canvas.height

        const picaTool = pica()
        const picaCanvas = await picaTool.resize(canvas, resultCanvas, {
            unsharpAmount: 25,
            unsharpRadius: 1,
            unsharpThreshold: 1,
            quality: 3,
        });
        
        // As a blob
        return new Promise((resolve, reject) => {
          picaCanvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            fileType
          );
        });
      }

    render(){
        const {
            imageUrl,
            imageFile, 
            accept, 
            buttonText, 
            customStyle,
            cropWidth,
            cropHeight,
            cropShape,
            onCrop
        } = this.props
        const {
            crop,
            zoom
        } = this.state
        const imageStyle = imageUrl ? styles.image : ""
        return (
            <div className={`${styles.container} upload ${imageStyle}`} style={customStyle}  onClick={!imageUrl || !onCrop ? this.handleClick : ()=>{}}>
                <input type="file" ref={ref => this.fileInput = ref} onChange={this.handleSelectImage} accept={accept}></input>
                {
                    imageUrl && imageFile && cropWidth && cropHeight?
                    <div className="productImage">
                        <Cropper 
                            image={imageUrl}
                            crop={crop}
                            zoom={zoom}
                            aspect={cropWidth/cropHeight}
                            cropShape={cropShape}
                            onCropChange={crop => this.setState({crop})}
                            onZoomChange={zoom => this.setState({zoom})}
                            onCropComplete={this.handleCropComplete}
                        />
                    </div>
                    :
                    (imageUrl && (!cropWidth || !cropHeight)) || (imageUrl && !imageFile)?
                    <img className="productImage" src={imageUrl} />
                    :
                    <div className="symbol">
                        <Icon icon="photo-camera" />
                        <button className="button white">{buttonText}</button>
                    </div>
                }
            </div>
        )
    }
}

export default PhotoUpload