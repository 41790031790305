import React from "react"
import SearchBar from "../../containers/SearchBar"
import Footer from "../../containers/Footer"
import CartItemList from "../../containers/CartItemList"
import BackLink from "../../components/BackLink"
import {connect} from "react-redux"
import styles from "./CartDetails.module.css"
import {BEGIN_CHECKOUT} from "../../constants/navigation"
import {Helmet} from "react-helmet";
import CartItemsChangedModal from "../../containers/CartItemsChangedModal";

function CartDetails ({cart, user, history}) {
    const query = new URLSearchParams(history.location.search)
    const lastAction = query.get("last-action")
    const goBack = () => {
        if (history.length > 1 && (lastAction !== BEGIN_CHECKOUT) && !(history.length === 2 && !document.referrer)) history.goBack()
        else {
            history.push(`/`)
        }
    }

    return (
        <div className={[styles.container, "screen"].join(" ")}>
            <Helmet>
                <title>Your Shopping Cart</title>
            </Helmet>
            <SearchBar/>
            <div className={["mainContent", "searchBarPresent", styles.mainContent, "showcase"].join(" ")}>
                <div className={styles.linkContainer}>
                    <BackLink text="Continue Shopping" onClick={goBack}/>
                </div>
                <div className={styles.innerContainer}>
                    <CartItemList />
                </div>
            </div>
            <CartItemsChangedModal />
            <Footer />
        </div>
    )
}

const mapStateToProps = state => ({
    cart: state.cart,
    user: state.user
})

export default connect(mapStateToProps)(CartDetails);