import React from "react"
import Modal from "../Modal"
import Loading from "../Loading"

import {CLOSE_BUTTON_THEME_LIGHT} from "../../constants/interaction"
import {DEFAULT_VIDEO_FULLSCREEN_HEIGHT, FACEBOOK_HOST_ID} from "../../constants/videos"
import styles from "./MediaPostModal.module.css"
import {setUrlParams} from "../../utils/stringUtils"

class MediaPostModal extends React.Component {

    state = {
        loading: true
    }
    static defaultProps = {
        handleCloseModal: ()=>{},
    }

    handleClose = () => {
        const {handleCloseModal} = this.props
        handleCloseModal()
        this.setState({loading:true})
    }
    render(){
        const {modalOpen, video} = this.props
        const {loading} = this.state
        const videoUrlParams = {autoplay: "1", mute: "0"}
        if (video.url && video.url.includes(FACEBOOK_HOST_ID)){
            //if this is a Facebook video
            delete videoUrlParams["autoplay"]
        }
        return (
            <Modal
                isOpen={modalOpen}
                closeModal={this.handleClose}
                className={styles.modal}
                overlayClassName={styles.modalOverlay}
                showCloseButton={true}
                closeOnOverlayClick={true}
                closeButtonTheme={CLOSE_BUTTON_THEME_LIGHT}
            >
                <div className={styles.innerContainer}>
                    <div className={styles.media}>
                        <iframe 
                            className={styles.iframe} 
                            src={setUrlParams(video.url, videoUrlParams)}
                            width={DEFAULT_VIDEO_FULLSCREEN_HEIGHT * video.aspectRatio}
                            height={DEFAULT_VIDEO_FULLSCREEN_HEIGHT}
                            onLoad={() => this.setState({loading: false})}
                            allow="autoplay"
                        />
                        {
                            loading ?
                            <Loading 
                                isContained={true}
                            />
                            :
                            null
                        }
                    </div>
                    {
                        false ? 
                        <div className={styles.comments}></div>
                        :
                        null
                    }
                    
                </div>
            </Modal>
        )
    }
}

export default MediaPostModal