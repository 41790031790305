import {firebaseApp} from "../config/firebase"
import { getFirestore, setDoc, doc, onSnapshot} from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"

export const SAVE_CREDIT_TRANSACTION_REQUESTS = 'SAVE_CREDIT_TRANSACTION_REQUESTS'

export const saveCreditTransactionRequests = creditTransactionRequests => {
    return {
        type: SAVE_CREDIT_TRANSACTION_REQUESTS,
        payload: {
            creditTransactionRequests
        }
    }
}

export const fetchSubscribeToCreditTransactionRequest = creditTransactionRequestId => {
    /**
      * Purpose: listen to a credit transaction request from the firestore database
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp);
    const creditTransactionRequestRef = doc(firestore, "creditTransactionRequests", creditTransactionRequestId)
                      
    return async dispatch => {
        try {
            const creditTransactionRequestListener = await onSnapshot(creditTransactionRequestRef,
                docRef => {
                    //get a credit transaction request from the snapshot
                    const creditTransactionRequest = docRef.data();
                    dispatch(saveCreditTransactionRequests([creditTransactionRequest]))
            })
            return creditTransactionRequestListener
        } catch (e){
            const message = `action > creditTransactionRequests > fetchSubscribeToCreditTransactionRequest: Failed to save creditTransactionRequest`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchCreateCreditTransactionRequest = (
    id,
    walletId,
    totalXcd,
    transactionType,
    transactionEffect,
    currentStatus,
    params={},
    onSuccess=()=>{}, 
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp);
    const creditTransactionRequestsRef = doc(firestore, "creditTransactionRequests", id)
    const creditTransactionRequest = {
        id,
        walletId,
        totalXcd,
        transactionType,
        transactionEffect,
        currentStatus,
        params,
        createdAt: Date.now()
    }
    return async (dispatch, getState) => {
        try {
            const {user} = getState()
            creditTransactionRequest.createdByUserId = user.id
            await setDoc(creditTransactionRequestsRef, creditTransactionRequest)
            onSuccess(creditTransactionRequest)
            return true
        } catch (e){
            const msg = `action > messages > fetchCreateCreditTransactionRequest: Failed to create credit transaction request ${JSON.stringify(creditTransactionRequest)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${msg}`))
            } else {
                e.message = `${e.message} ${msg}`
                logError(e)
            }
            return false
        }
    }
}
