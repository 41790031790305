/**
 *  Purpose: the modifications that are carried on the settlement objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"

 // STATE
 // settlements:{
 //   settlementsById:{},
 //   settlementIds: [].
 //   loaded: {
 //      countryIds: {},
//    }
 // }
 
 
 const settlementsReducer = (state = initialState.settlements, action) => {
     const {type, payload} = action;
     let settlementsById = {...state.settlementsById}
     let loaded = {...state.loaded}
     switch (type){
         case types.SAVE_SETTLEMENTS: {
             if(typeof payload.settlements !== "object") {
                 logError(`settlementsReducer > SAVE_SETTLEMENTS: settlements payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.settlements.forEach(settlement => {
                settlementsById[settlement.id] = settlement
             });

             if (payload.loaded){
                //record country loads
                if (payload.loaded.countryIds){
                    loaded.countryIds = {
                        ...loaded.countryIds,
                        ...payload.loaded.countryIds
                    }
                }
            }
             return {
                 ...state,
                 settlementsById,
                 settlementIds: Object.keys(settlementsById),
                 loaded
             }
        }
 
        default: return state
     }
 }
 
 export default settlementsReducer