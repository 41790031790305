import React from "react"
import {connect} from "react-redux"
import {
    PICK_UP_INSTORE_ID, 
    SELF_DELIVERY_ID, 
    deliverySlotTypeToHumanReadable, 
} from "../../constants/delivery"
import { capitalizeAllWords, addressObjectToString, alphabeticalSort } from "../../utils/stringUtils"
import { timestampToDateString, getIntuitiveDateFromTimestamp} from "../../utils/datetimeUtils"
import { AVATAR_SIZE_MEDIUM, AVATAR_SIZE_SMALL} from "../../constants/interface"

import AvatarIconStack from "../../components/AvatarIconStack"
import DateHighlight from "../../components/DateHighlight"

import styles from "./SelectedDeliverySlot.module.css"
import ShopdmLogo from "../../images/logo.png"
import { DOMINICA_TIMEZONE } from "../../constants/datetime"

/**
 * Purpose:
 * 
 * Visual element to communicate the particulars of the selected slot
 **/

const SelectedDeliverySlot = ({
    deliverySlot=null,
    deliveryProviderId, 
    deliveryProviders,
    sellerId,
    sellerIds, //all the sellers to be delivered by the delivery
    sellers,
    pickupAddressId="",
    Right=null,
    timezone=DOMINICA_TIMEZONE
}) => {
    const deliveryProvider = deliveryProviderId ? deliveryProviders.deliveryProvidersById[deliveryProviderId] : null
    const seller = sellerId ? sellers.sellersById[sellerId] : null
    //only used with pick up instore orders
    const pickupAddress = seller && pickupAddressId ? 
                          seller.addressesById[pickupAddressId]
                          :
                          null
    let deliveryDay = ""
    let startTimeString = ""
    let endTimeString = ""
    let deliverySlotDescription = ""
    let deliverFromSellerNames = ""
    if (deliverySlot && deliverySlot.startTime){ 
        deliveryDay = getIntuitiveDateFromTimestamp({
            timestamp: deliverySlot.endTime,
            useLocalTimezone: !timezone,
            timezone
        })
        deliveryDay = deliveryDay === "today" || deliveryDay === "tomorrow" ?
                      deliveryDay
                      :
                      `on ${deliveryDay}`
        startTimeString = timestampToDateString(deliverySlot.startTime, "h:mm a", !timezone, timezone)
        endTimeString = timestampToDateString(deliverySlot.endTime, "h:mm a", !timezone, timezone)
        deliverySlotDescription = deliverySlotTypeToHumanReadable[deliverySlot.type]
        deliverFromSellerNames = Object.keys(sellerIds)
                                        .map(sellerId => sellers.sellersById[sellerId])
                                        .sort((sA, sB) => alphabeticalSort(sA.name, sB.name))
                                .reduce((text, seller, i, arr) => {
            const sellerName = capitalizeAllWords(seller.name)
            text = !text ? sellerName : `${text}${i === arr.length - 1 ? " and " : ", "}${sellerName}`
            return text
        }, "")
    }
    const isPickup = Boolean(deliveryProviderId === PICK_UP_INSTORE_ID)
    const deliveryEntityName = deliveryProviderId === PICK_UP_INSTORE_ID || deliveryProviderId === SELF_DELIVERY_ID 
                                ? 
                                capitalizeAllWords(seller.name)
                                :
                                capitalizeAllWords(deliveryProvider.name)
    const sellerAvatarList = Object.keys(sellerIds)
                                    .map(sellerId =>{
                                        const seller = sellers.sellersById[sellerId]
                                        return {
                                            name: seller.name,
                                            imageUrl: seller.logoImageUrlMed
                                        }
                                    })
                                    .sort((aA, aB) => alphabeticalSort(aA.name, aB.name))
                                
    return (
    <div className={styles.container}>
        {
            deliveryProvider ?
            <div className={styles.innerContainer}>
                <div className={styles.sellerLogos}>
                        <AvatarIconStack 
                            avatarList={sellerAvatarList}
                            avatarSize={sellerAvatarList.length > 1 ?AVATAR_SIZE_SMALL : AVATAR_SIZE_MEDIUM}
                            coverPercentage={0.3}
                            borderColor={sellerAvatarList.length > 1 ? "var(--grey)" : "var(--light-grey)"}
                            stackLimit={3}
                        />
                </div>
                {
                    isPickup && !pickupAddressId ?
                    "First, tell us where you want to pickup your items"
                    :
                    <div className={styles.deliveryDetails}>
                    {
                        deliverySlot ?
                            <div className={styles.description}>
                                <div className={styles.deliveryTime}>{startTimeString} to {endTimeString}, <DateHighlight timestamp={deliverySlot.endTime}/></div>
                                <div className={styles.deliverAt}>
                                    {
                                    isPickup ? 
                                    "Pick up from" : 
                                    deliveryProvider.id === SELF_DELIVERY_ID ?
                                    "Delivered by"
                                    :
                                    <span>Delivered by <img src={deliveryProvider.logo ? deliveryProvider.logo : ShopdmLogo} width={36} height="auto"/></span>} {deliveryEntityName} {deliveryDay} in the {deliverySlotDescription}
                                </div>
                                <div className={styles.deliveredBy}>
                                {
                                    deliveryProviderId === PICK_UP_INSTORE_ID && pickupAddress?
                                    `Pick up from ${capitalizeAllWords(seller.name)} at ${addressObjectToString(pickupAddress)}`
                                    :
                                    deliveryProviderId === PICK_UP_INSTORE_ID && !pickupAddress?
                                    `Choose a pick up location`
                                    :
                                    deliveryProviderId === SELF_DELIVERY_ID && seller?
                                    ``
                                    :
                                    `Your items from ${deliverFromSellerNames}${sellerAvatarList.length > 1 ? " delivered together, when all stores are ready" : ""}`
                                    
                                }
                                </div>
                            </div>
                          :
                          `Please choose a ${isPickup ? "pick up" : "delivery"} slot`
                      }
                    <div className={styles.right}>
                        {
                            Right? 
                            <Right />
                            :
                            null
                        }
                    </div>
                </div>

                }
                
            </div>
            :
            "No delivery method chosen"
        }
    </div>
    )
}

const mapStateToProps = state => ({
    deliveryProviders: state.deliveryProviders,
    sellers: state.sellers,
})

export default connect(mapStateToProps)(SelectedDeliverySlot)