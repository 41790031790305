import React from "react"
import {Redirect} from "react-router-dom"

import TopBar from "../../components/TopBar"

import Footer from "../../containers/Footer"
import BackLink from "../../components/BackLink"
import CheckoutDetails from "../../containers/CheckoutDetails"
import OrderInvoice from "../../containers/OrderInvoice"
import CartItemsChangedModal from "../../containers/CartItemsChangedModal";

import {BEGIN_CHECKOUT, ORDER_PLACED} from "../../constants/navigation"
import { CUSTOMER_CONFIRMED_PAYMENT, SHOPDM_CONFIRMED_PAYMENT, CUSTOMER_SELECTED_PAY_INSTORE} from "../../constants/order";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import styles from "./Checkout.module.css"
import {Helmet} from "react-helmet";

import support from "../../config/support"

import {calculateOrderTotals} from "../../utils/orderUtils"
class Checkout extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            orderListener : null,
            deliveryAgentsForAddressListener: null,
            currentOrderId: null,
            orderPaid: false
        }
        this.awaitingResponse = false
    }
    
    static getDerivedStateFromProps(props, state){
        /**
         * Purpose:
         * This function ensures that if the order status changes on the server
         * then the frontend will leave the checkout page and clear its local cart
         * 
         * Note: cart listening is handled globally in containers/App so this screen will receive the 
         * cart update even though it has no explicit cart listener
         */
        
        //if we have just cleared the current order id
        const {cart, orders} = props
        if (
            state.currentOrderId && 
            !cart.currentOrderId
        ){
            const order = orders.ordersById[state.currentOrderId]
            //if the cleared order has a status of paid or customer says they paid
            if (
                order && 
                (
                    order.currentStatus === SHOPDM_CONFIRMED_PAYMENT ||
                    order.currentStatus === CUSTOMER_CONFIRMED_PAYMENT ||
                    order.currentStatus === CUSTOMER_SELECTED_PAY_INSTORE
                )
            ){
                //once orderPaid is set to true, it will trigger a transition to the order tracking page 
                return {
                    orderPaid: true
                } 
            }
        }
        return null
    }
    componentDidMount = async () => {
        this.listenToCurrentOrder()
    }

    componentDidUpdate = (prevProps) => {
        const {user} = prevProps
        this.listenToCurrentOrder()
        this.listenToDeliveryAgentsForAddress(user)
    }

    listenToCurrentOrder =  async () => {
        const {actions, cart} = this.props
        if (cart.currentOrderId && !this.state.orderListener && !this.awaitingResponse){
            this.awaitingResponse = true
            const orderListener = await actions.fetchSubscribeToMyOrder(cart.currentOrderId)
            this.setState({orderListener, currentOrderId: cart.currentOrderId}, () => this.awaitingResponse = false)
        }
    }

    listenToDeliveryAgentsForAddress = async (prevUser) => {
        let deliveryAgentsForAddressListener = this.state.deliveryAgentsForAddressListener
        const {user, actions} = this.props
        const address = user.addressesById[user.defaultAddressId]
        const prevAddress = prevUser.addressesById[prevUser.defaultAddressId]
        //if there is as listener and the address has changed or 
        //the settlement in the address has changed
        if (
            deliveryAgentsForAddressListener && 
            (address.settlementId !== prevAddress.settlementId)
        ){
            //cancel the listener to the last address
            this.state.deliveryAgentsForAddressListener()
            deliveryAgentsForAddressListener = null
        }   
        //if there is no listener, either because it has not yet been set (this is the first update)
        //or because the previous listener has been cleared due to an address change
        //then set a new listener
        if (!deliveryAgentsForAddressListener){
            deliveryAgentsForAddressListener = await actions.fetchSubscribeToAreaDeliveryAgents(address.settlementId)
            this.setState({deliveryAgentsForAddressListener})
        }
    }

    componentWillUnmount(){
        const {actions, cart} = this.props
        if (this.state.orderListener) this.state.orderListener()
        if (this.state.deliveryAgentsForAddressListener) this.state.deliveryAgentsForAddressListener()
        if (cart.itemIds.length === 0) actions.clearCart()
        //if the loading screen is up, toggle it off
        actions.toggleLoading(false)
    }


    render (){
        const {user, cart, sellers, paymentProviders, deliveryProviders, promotions, creditWallets} = this.props
        const {orderPaid} = this.state
        //the order is set to paid via credit card or Shopdm importing a mobanking payment sheet
        if (orderPaid) return <Redirect to={`/customers/${user.id}?last-action=${ORDER_PLACED}`} />
        if (cart.itemIds.length === 0) return <Redirect to={`/cart-details?last-action=${BEGIN_CHECKOUT}`} />
        if (!user.authenticated) return <Redirect to="/create-account?redirect-from=/checkout" />
        if (user.unsubscribed) return <Redirect to="/unsubscribe?redirect-from=/checkout" />
        if (!user.isActive) return <Redirect to="/blocked"/>
        if (Object.keys(user.addressesById).length === 0) return <Redirect to="/create-account?redirect-from=/checkout" />
        const wallet = creditWallets.creditWalletsById[user.defaultWalletId]
        
        const totals = calculateOrderTotals(
            cart, 
            user,
            sellers,
            deliveryProviders,
            paymentProviders,
            promotions,
            wallet
        )
        const promotionCount = Object.keys(totals.appliedPromotions).length
        return (
            <div className={[styles.container, "screen"].join(" ")}>
                <Helmet>
                    <title>Checkout</title>
                </Helmet>
                <TopBar title="Checkout" />
                <div className={["topBarPresent", "mainContent", styles.mainContent, "showcase"].join(" ")}>
                    <BackLink text="Back to Shopping Cart" path={`/cart-details?last-action=${BEGIN_CHECKOUT}`}/>
                    <div className={styles.innerContainer}>
                        <div className={styles.invoiceTop}>
                            <OrderInvoice 
                                total={totals.total} 
                                paymentProcessorTotal={totals.paymentProcessorTotal}
                                processingFee={totals.processingFee}
                                deliveryFee={totals.deliveryFee}
                                giftFee={totals.giftFee}
                                subTotal={totals.subTotal}
                                discount={totals.discount}
                                promotionCount={promotionCount}
                                shopdmCreditUsed={totals.shopdmCreditUsed}
                                shopdmCreditOwed={totals.shopdmCreditOwed}
                            />
                        </div>
                        <CheckoutDetails 
                            total={totals.total}
                            totalIfCreditUsed={totals.totalIfCreditUsed}
                            paymentProcessorTotal={totals.paymentProcessorTotal}
                            processingFee={totals.processingFee}
                            paymentProcessorFee={totals.paymentProcessorFee}
                            deliveryFee={totals.deliveryFee}
                            giftFee={totals.giftFee}
                            subTotal={totals.discountedSubTotal}
                            appliedPromotions={totals.appliedPromotions}
                            shopdmCreditUsed={totals.shopdmCreditUsed}
                            shopdmCreditOwed={totals.shopdmCreditOwed}
                            providerFees={totals.providerFees}
                            shopdmCommissionEarningsXcd={totals.shopdmCommissionEarningsXcd}
                            shopdmDeliveryEarningsXcd={totals.shopdmDeliveryEarningsXcd}
                            shopdmGiftingEarningsXcd={totals.shopdmGiftingEarningsXcd}
                            shopdmTotalEarningsXcd={totals.shopdmTotalEarningsXcd}
                        />
                        <OrderInvoice 
                            total={totals.total} 
                            paymentProcessorTotal={totals.paymentProcessorTotal}
                            processingFee={totals.processingFee}
                            deliveryFee={totals.deliveryFee}
                            giftFee={totals.giftFee}
                            subTotal={totals.subTotal}
                            discount={totals.discount}
                            promotionCount={promotionCount}
                            shopdmCreditUsed={totals.shopdmCreditUsed}
                            shopdmCreditOwed={totals.shopdmCreditOwed}
                        />
                    </div>
                    <div className={styles.contactInfo}>Questions? Call us at <a title="call" href={`tel:${support.maintenancePhone}`}>{support.maintenancePhone}</a> or <a href={`mailto:${support.maintenanceEmail}?subject=Questions about the checkout process`} target="_blank" rel="noopener noreferrer">email us at {support.maintenanceEmail}</a></div>
                </div>
                <CartItemsChangedModal />
                <Footer />
            </div>
        )
    }
}


const mapStateToProps = state => ({
    user: state.user,
    cart: state.cart,
    sellers: state.sellers,
    orders: state.orders,
    paymentProviders: state.paymentProviders,
    deliveryProviders: state.deliveryProviders,
    promotions: state.promotions,
    creditWallets: state.creditWallets
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

  
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);