/**
 *  Purpose: the modifications that are carried on the Payment Provider objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
// STATE
// paymentProviders: {
//     paymentProvidersById: {},
//     paymentProviderIds: [],
//     selected: null
// }


const paymentProvidersReducer = (state = initialState.paymentProviders, action) => {
    const {type, payload} = action;
    let paymentProvidersById = {...state.paymentProvidersById}

    switch (type){
        case types.SAVE_PAYMENT_PROVIDERS: {
            if(typeof payload.paymentProviders !== "object") {
                logError(`paymentProvidersReducer > SAVE_PAYMENT_PROVIDERS: paymentProviders payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.paymentProviders.forEach(paymentProvider => {
                paymentProvidersById[paymentProvider.id] = paymentProvider
            })
            return {
                ...state,
                paymentProvidersById,
                paymentProviderIds: Object.keys(paymentProvidersById)
            }
        }

        default: return state
    }
}

export default paymentProvidersReducer