import React from "react"
import styles from "./CartItem.module.css"
import QuantityDropdown from "../QuantityDropdown"
import CharacteristicsList from "../CharacteristicsList"

function CartItem ({
    id,
    imageUrl, 
    productTitle, 
    productTotal, 
    previousTotal,
    quantity, 
    maxQuantity, 
    minimumOrderQty=1,
    units="",
    promotion=null,
    event=null,
    promotionOfferString=null,
    sellerName,
    characteristics={},
    onRemove=null, 
    onChangeQuantity=null,
    onViewSellerProfile=()=>{},
    onViewProductDetails=()=>{}
}){
    return (
        <div className={`${styles.container} ${promotion ? styles.sale : ""}`}>
            <div className={styles.frame}>
                <img onClick={onViewProductDetails} className={['productImage', styles.productImage].join(" ")} src={imageUrl} alt={productTitle}/>
            </div>
            <div>
                <div className={`strong ${styles.productTitle}`}>{productTitle} <CharacteristicsList 
                    id={id}
                    characteristics={characteristics}
                /></div>
                
                <p>from <span 
                            className={'anchorLink'} 
                            onClick={onViewSellerProfile}>{sellerName}</span>
                </p>
                {
                    onChangeQuantity ?
                    <div>
                        <QuantityDropdown 
                            min={minimumOrderQty}
                            units={units}
                            selected={quantity} 
                            max={maxQuantity}
                            onSelect={onChangeQuantity ? onChangeQuantity : ()=>{}}
                        />
                    </div>
                    :
                    null
                }
                {
                    event
                    ? 
                    <div className="saleEventFlag">{event.name} Deal ({promotionOfferString} off)</div> 
                    : 
                    null
                }
                <p className={styles.price}>
                    EC ${productTotal.toFixed(2)} 
                    {promotion ? <span className="salePreviousPrice">${previousTotal.toFixed(2)}</span> : null}
                </p>
                {
                    onRemove ?
                    <button
                        className={['button', 'outline'].join(" ")}
                        onClick={onRemove ? onRemove : ()=>{}}
                    >
                        Remove from Cart
                    </button>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default CartItem