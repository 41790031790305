import React from "react"

import CloseButton from "../../components/CloseButton"

import MenuItem from "../../components/MenuItem"
import Modal from "../../components/Modal"
import AvatarIcon from "../../components/AvatarIcon"

import UpdateProfileImageModal from "../UpdateProfileImageModal"

import {withRouter} from 'react-router-dom'

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import styles from "./UserMenu.module.css"
import logo from "../../images/logo.png"
import {logAnalyticsEvent} from "../../config/firebase"
import {alphabeticalSort, capitalizeAllWords} from "../../utils/stringUtils"

class UserMenu extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            modalOpen: false,
            modalLastOpenedAt: 0,
            slideIn: false
        }
    }

    handleOpenModal = () => this.setState({modalOpen: true, modalLastOpenedAt: Date.now(), slideIn: false}, this.slide)
    handleCloseModal = () => {
        if ((Date.now() - this.state.modalLastOpenedAt) < 500) return
        this.setState({slideIn: false}, this.slideOut)
    }
    slide = () => {
        setTimeout( () => this.setState({slideIn: true}), 50)
    }

    slideOut = () => {
        setTimeout( () => this.setState({modalOpen: false}), 500)
    }

    handleLogout = () => {
        const {actions, user} = this.props
        if (window.confirm("Are you sure you want to log out?")){
            this.handleExit()
            logAnalyticsEvent("logout")
            actions.fetchLogDeviceSessionAction({
                action: "logout",
                userId: user.id
            })
            actions.fetchLogout()
        }
    }

    handleExit = () => this.setState({modalOpen: false, slideIn: false})
    handleClickLogin = () => {
        const {history} = this.props
        this.handleExit()
        history.push("/login")
    }
    handleClickRegister = () => {
        const {history} = this.props
        this.handleExit()
        history.push("/create-account")
    }
    handleClickMyOrders = () => {
        const {history, user} = this.props
        this.handleExit()
        history.push(`/customers/${user.id}`)
    }
    handleClickMyRestockRequests = () => {
        const {history, user} = this.props
        this.handleExit()
        history.push(`/customers/${user.id}/restock-requests`)
    }

    handleClickHome = () => {
        const {history} = this.props
        this.handleExit()
        history.push("/")
    }

    handleClickCart = () => {
        const {history} = this.props
        this.handleExit()
        history.push("/cart-details", {from: history.location.pathname})
    }

    handleClickAboutUs = () => {
        const {history} = this.props
        this.handleExit()
        history.push("/about")
    }

    handleGoToDashboard = authorizedSellerAccountId => {
        const {history} = this.props
        this.handleExit()
        history.push(`/sellers/${authorizedSellerAccountId}/dashboard`)
    }

    handleManageDeliveries = authorizedDeliveryProviderAccountId => {
        const {history} = this.props
        this.handleExit()
        history.push(`/delivery-providers/${authorizedDeliveryProviderAccountId}/dashboard`)
    }
    
    handleGoToAdminPanel = () => {
        const {history} = this.props
        this.handleExit()
        history.push(`/admin/dashboard`)
    }

    handleClickShopdmWallet = () => {
        const {history, actions, user} = this.props
        this.handleExit()
        logAnalyticsEvent('go_to_shopdm_credit_by_user_menu_press')
        actions.fetchLogDeviceSessionAction({
            action: "goToShopdmCreditByUserMenuPress", 
            walletId: user.defaultWalletId
        })
        history.push(`/shopdm-credit/${user.id}`)
    }

    render(){
        const {modalOpen, slideIn} = this.state
        const {user, sellers, deliveryProviders, creditWallets} = this.props
        const modalStyle = slideIn ? `${styles.modal} ${styles.slide}` : styles.modal
        const authorizedSellerAccountIds = sellers.sellerIds.filter(sellerId => {
            const seller = sellers.sellersById[sellerId]
            return seller && seller.roles ? seller.roles[user.id] : false
        })
        const authorizedDeliveryProviderAccountId = deliveryProviders.deliveryProviderIds.find(deliveryProviderId => {
            const deliveryProvider = deliveryProviders.deliveryProvidersById[deliveryProviderId]
            return deliveryProvider && deliveryProvider.roles ? deliveryProvider.roles && deliveryProvider.roles[user.id] : false
        })
        const wallet = user.defaultWalletId ? creditWallets.creditWalletsById[user.defaultWalletId] : null
        return (
        <React.Fragment>
            <div 
                className={styles.greeting} 
                onClick={this.handleOpenModal}
            >
                <AvatarIcon name={`${user.firstName} ${user.lastName}`} showDefaultIcon={!user.authenticated} imageUrl={user.profileImageUrlSmall}/>
                <span>{`Hi, ${user.authenticated ? user.firstName : "Log in"}`}</span>
            </div>
            {
                modalOpen ?
                <Modal
                    isOpen={modalOpen}
                    closeModal={this.handleCloseModal}
                    showCloseButton={false}
                    className={modalStyle}
                    overlayClassName={styles.modalOverlay}
                    closeOnOverlayClick={true}
                >   
                    <div className={styles.header}>
                        <img className={['logo', styles.logo].join(" ")} src={logo} onClick={this.handleClickHome} draggable="false" alt="Shopdm Logo"/>
                        <CloseButton  onClick={this.handleCloseModal}/>
                    </div>
                    {
                        !user.authenticated ? 
                        <LoginOrCreateAccount 
                            onLogin={this.handleClickLogin}
                            onCreateAccount={this.handleClickRegister}
                        />
                        :
                        <div className={styles.userDetails}>
                            <UpdateProfileImageModal 
                                userId={user.id}
                                name={`${user.firstName} ${user.lastName}`} 
                                imageUrl={user.profileImageUrl}
                                imageUrlMed={user.profileImageUrlMed}
                            />
                            <div className={styles.name}>{capitalizeAllWords(`${user.firstName} ${user.lastName}`)}</div>
                            <div>{user.email}</div>
                        </div>
                    }
                    <MenuItem label="Home" icon="home" onClick={this.handleClickHome}/>
                    <MenuItem label="Your Shopping Cart" icon="shopping-cart" onClick={this.handleClickCart}/>
                    {
                        user.authenticated ? 
                        <React.Fragment>
                            {
                                wallet ?
                                <MenuItem label={<span>Your Shopdm Credit: <span className={wallet.balance < 0 ? "negative-balance": ""}>${wallet.balance.toFixed(2)}</span></span>} icon="account-balance-wallet" onClick={this.handleClickShopdmWallet}/>
                                :
                                null
                            }
                            {
                                user.isAdmin || user.isEditor?
                                <MenuItem label="Admin Dashboard" icon="flare" onClick={this.handleGoToAdminPanel}/>
                                :
                                null
                            }
                            <MenuItem label="Track Your Orders" icon="local-shipping" onClick={this.handleClickMyOrders}/>
                            <MenuItem label="Your Restock Requests" icon="contact-support" onClick={this.handleClickMyRestockRequests}/>
                            {
                                authorizedSellerAccountIds.length > 1 ?
                                authorizedSellerAccountIds.map(sellerId => sellers.sellersById[sellerId])
                                                         .sort((sellerA, sellerB) => alphabeticalSort(sellerA.name, sellerB.name))
                                                         .map(seller => {
                                                                return <MenuItem key={seller.id} label={`${capitalizeAllWords(seller.name)} Dashboard`} icon="local-atm" onClick={() => this.handleGoToDashboard(seller.id)}/>
                                                            }) 
                                :
                                authorizedSellerAccountIds.length === 1 ?
                                <MenuItem label="Merchant Dashboard" icon="local-atm" onClick={() => this.handleGoToDashboard(authorizedSellerAccountIds[0])}/>
                                :
                                null
                            }
                            {
                                authorizedDeliveryProviderAccountId ?
                                <MenuItem label="Manage Deliveries" icon="local-shipping" onClick={() => this.handleManageDeliveries(authorizedDeliveryProviderAccountId)}/>
                                :
                                null
                            }
                            
                        </React.Fragment>
                        :
                        null
                    }
                    <MenuItem label="About Shopdm" icon="info" onClick={this.handleClickAboutUs}/>
                    {
                        user.authenticated ? 
                        <MenuItem label="Log out" icon="arrow-back" onClick={this.handleLogout}/>
                        :
                        null
                    }
                </Modal>
                :
                null
            }
        </React.Fragment>
        )
    }
}

const LoginOrCreateAccount = ({onLogin=()=>{}, onCreateAccount=()=>{}}) => {
    return(
    <div className={styles.loginOrCreate}>
        <button 
            className={['button', 'primary'].join(" ")}
            onClick={onCreateAccount}
        >
            Create Account
        </button>
        <div>or</div>
        <button 
            className={['button', 'dark'].join(" ")} 
            onClick={onLogin}
        >
            Login
        </button>
    </div>
    )
}
const mapStateToProps = state => ({
    user: state.user,
    sellers: state.sellers,
    deliveryProviders: state.deliveryProviders,
    creditWallets: state.creditWallets
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserMenu));