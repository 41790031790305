import React from "react"
import styles from "./SearchField.module.css"
import Icon from "../Icon"
import PropTypes from "prop-types"

class SearchField extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            text: props.text
        }
    }

    static defaultProps = {
        onSearch: ()=>{},
        placeholder: 'Search'
    }

    componentDidUpdate = prevProps => {
        if (prevProps.text !== this.props.text) {
          this.setState({text: this.props.text})
        }
      }

    handleChangeText = text => {
        this.setState({text})
    } 

    handleSearch = () => {
        const {text} = this.state
        const {onSearch} = this.props
        onSearch(text)
        if (this.input) this.input.blur()
    }

    handleKeyDown = e => {
        if (e.keyCode === 13){
            this.handleSearch()
        }
    }
    render(){
        const {text} = this.state
        const {placeholder} = this.props
        return (
            <div className={styles.container}>
                <input 
                    ref={el => this.input = el}
                    placeholder={placeholder} 
                    className={styles.field} 
                    value={text}
                    onChange={event => this.handleChangeText(String(event.target.value))}
                    onKeyDown={this.handleKeyDown}
                />
                <button
                    onClick={this.handleSearch}
                    className={styles.searchButton}
                >
                    <Icon className={styles.icon} icon="search"/>
                </button>
            </div>
        )
    }
}

SearchField.propTypes  = {
    onSearch: PropTypes.func
}
export default SearchField;