/**
 *  Purpose: the modifications that are carried on the Product stock objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {STATISTICS_IDENTIFIER} from "../constants/analysis"
// STATE
// productStock:{
//  productStockById:{},
//  productStockIdsByProductId: {}
//  productStockIdsBySellerSKU:{},
//  productStockIds: [],
//  stockLoadedByProductId: {}
// }


const productStockReducer = (state = initialState.productStock, action) => {
    const {type, payload} = action;
    let productStockById = {...state.productStockById}
    let productStockIdsByProductId = {...state.productStockIdsByProductId}
    let productStockIdsBySellerSKU = {...state.productStockIdsBySellerSKU} 
    let stockLoadedByProductId =  {...state.stockLoadedByProductId}
    let productStock

    switch (type){
        //this is where product stock is loaded
        case types.SAVE_PRODUCTS: {
            if(typeof payload.products !== "object") {
                return state
            }
            payload.products.forEach(product => {
                if (product.id === STATISTICS_IDENTIFIER) return
                const stock = product.stock? product.stock : {}
                Object.values(stock).forEach(productStock => {
                    //construct imageUrls for previous product stock that do not have multiple images set up
                    if (!productStock.imageUrl) productStock.imageUrl = product.imageUrl
                    productStock.imageUrls = productStock.imageUrls ? productStock.imageUrls : [productStock.imageUrl]
                    const merged = mergeProductStock(productStock, productStockById, productStockIdsByProductId, productStockIdsBySellerSKU)
                    productStockById = merged.productStockById
                    productStockIdsByProductId = merged.productStockIdsByProductId
                    productStockIdsBySellerSKU = merged.productStockIdsBySellerSKU
                })
                stockLoadedByProductId[product.id] = Date.now()
            })
            
            return {
                ...state,
                productStockById,
                productStockIdsByProductId,
                productStockIdsBySellerSKU,
                productStockIds: Object.keys(productStockById),
                stockLoadedByProductId
            }
        }

        case types.CREATE_PRODUCT: {
            if(typeof payload.product !== "object") {
                return state
            }
            if(typeof payload.sellerId !== "string") {
                return state
            }
            const {product} = payload
            const stock = product.stock? product.stock : {}
            Object.values(stock).forEach(productStock => {
                //construct imageUrls for previous product stock that do not have multiple images set up
                if (!productStock.imageUrl) productStock.imageUrl = product.imageUrl
                productStock.imageUrls = productStock.imageUrls ? productStock.imageUrls : [productStock.imageUrl]
                const merged = mergeProductStock(productStock, productStockById, productStockIdsByProductId, productStockIdsBySellerSKU)
                productStockById = merged.productStockById
                productStockIdsByProductId = merged.productStockIdsByProductId
                productStockIdsBySellerSKU = merged.productStockIdsBySellerSKU
            })
            return {
                ...state,
                productStockById,
                productStockIdsByProductId,
                productStockIdsBySellerSKU,
                productStockIds: Object.keys(productStockById),
            }
        }

        case types.EDIT_PRODUCT: {
            if(typeof payload.productId !== "string") {
                return state
            }
            if(typeof payload.edits !== "object") {
                return state
            }
            if(typeof payload.previousValues !== "object") {
                return state
            }
            const stock = payload.edits.stock? payload.edits.stock : {}
            const previousStock = payload.previousValues.stock ? payload.previousValues.stock : {}
            Object.values(stock).forEach(productStock => {
                if (
                    previousStock[productStock.id] && 
                    previousStock[productStock.id].skuNumber && 
                    (productStock.skuNumber !== previousStock[productStock.id].skuNumber)
                ){
                    //remove the index by the previous sku number before merging
                    delete productStockIdsBySellerSKU[productStock.sellerId][previousStock[productStock.id].skuNumber]
                }
                productStock.imageUrls = productStock.imageUrls ? productStock.imageUrls : [productStock.imageUrl]
                const merged = mergeProductStock(productStock, productStockById, productStockIdsByProductId, productStockIdsBySellerSKU)
                productStockById = merged.productStockById
                productStockIdsByProductId = merged.productStockIdsByProductId
                productStockIdsBySellerSKU = merged.productStockIdsBySellerSKU
            })
            return {
                ...state,
                productStockById,
                productStockIdsByProductId,
                productStockIdsBySellerSKU,
                productStockIds: Object.keys(productStockById),
            }
        }
        
        case types.CREATE_PRODUCT_STOCK: {
            if(typeof payload.productStock !== "object") {
                return state
            }
            productStockById[payload.productStock.id] = {...payload.productStock, ...productStockById[payload.productStock.id]}
            //index product storck ids by product ids
            productStockIdsByProductId[payload.productStock.productId] = {
                                                                    ...productStockIdsByProductId[payload.productStock.productId],
                                                                    [payload.productStock.id]: true
                                                                }
            if (payload.productStock.skuNumber){
                if (!productStockIdsBySellerSKU[payload.productStock.sellerId]) productStockIdsBySellerSKU[payload.productStock.sellerId] = {}
                else if (productStockIdsBySellerSKU[payload.productStock.sellerId][payload.productStock.skuNumber] && (productStockIdsBySellerSKU[payload.productStock.sellerId][payload.productStock.skuNumber] !== payload.productStock.id)) {
                    logError(`Duplicate SKU detected, ${payload.productStock.skuNumber} for seller ${payload.productStock.sellerId} product ${payload.productStock.productId} stock was indexed ${productStockIdsBySellerSKU[payload.productStock.sellerId][payload.productStock.skuNumber]} but is being changed to ${payload.productStock.id}`)
                }
                const sellerSkus = {...productStockIdsBySellerSKU[payload.productStock.sellerId]}
                sellerSkus[payload.productStock.skuNumber] = payload.productStock.id
                productStockIdsBySellerSKU[payload.productStock.sellerId] = sellerSkus
            }
            return {
                ...state,
                productStockById,
                productStockIdsByProductId,
                productStockIdsBySellerSKU,
                productStockIds: Object.keys(productStockById),
            }
        }

        case types.EDIT_PRODUCT_STOCK: {
            if(typeof payload.productStockId !== "string"){
                logError(`productStockReducer > EDIT_PRODUCT_STOCK: productStockId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.edits !== "object") {
                logError(`productStockReducer > EDIT_PRODUCT_STOCK: edits payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.previousValues !== "object") {
                logError(`productStockReducer > EDIT_PRODUCT_STOCK: edits payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if (payload.previousValues.skuNumber && "skuNumber" in payload.edits && (payload.previousValues.skuNumber !== payload.edits.skuNumber)){
                //remove the previous SKU number if it has changed
                const sellerSkus = {...productStockIdsBySellerSKU[payload.previousValues.sellerId]}
                delete sellerSkus[payload.previousValues.skuNumber]
                productStockIdsBySellerSKU[payload.previousValues.sellerId] = sellerSkus
            }
            if (payload.edits.skuNumber) productStockIdsBySellerSKU[payload.previousValues.sellerId][payload.edits.skuNumber] = payload.productStockId
            productStockById[payload.productStockId] = {...productStockById[payload.productStockId], ...payload.edits}
            return {
                ...state,
                productStockById,
                productStockIdsBySellerSKU
            }
        }

        case types.DELETE_PRODUCT_STOCK: {
            if(typeof payload.productStockId !== "string"){
                logError(`productStockReducer > DELETE_PRODUCT_STOCK: productStockId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            productStock = productStockById[payload.productStockId]
            delete productStockIdsByProductId[productStock.productId][payload.productStockId]
            if (productStock.skuNumber) delete productStockIdsBySellerSKU[productStock.sellerId][productStock.skuNumber]
            if (Object.keys(productStockIdsByProductId[productStock.productId]).length === 0) delete productStockIdsByProductId[productStock.productId]
            delete productStockById[payload.productStockId]
            return {
                ...state,
                productStockById,
                productStockIdsByProductId,
                productStockIdsBySellerSKU,
                productStockIds: Object.keys(productStockById),
            }
        }

        case types.CREATE_RESTOCK_REQUEST: {
            //locally increment the restock request count on the product stock when a 
            //restock request is made by the current user
            if(typeof payload.restockRequest !== "object") {
                return state
            }
            const {productStockId, quantity} = payload.restockRequest
            if (!productStockById[productStockId]) return state
            const stock = {...productStockById[productStockId]}
           stock.restockRequestCount = stock.restockRequestCount ? stock.restockRequestCount + 1 : 1
           stock.totalQuantityRequested = stock.totalQuantityRequested ? stock.totalQuantityRequested + quantity : quantity
            return {
                ...state,
                productStockById: {
                    ...productStockById,
                    [productStockId]: stock
                }
            }
        }

        //TODO ensure DELETE_PRODUCT works with stock across machines, i.e. it doesn't cause a crash if edward has his seller profile open on his machine and I delete the stock on my machine
        default: return state
    }
}

const mergeProductStock = (productStock, productStockById, productStockIdsByProductId, productStockIdsBySellerSKU) => {
    //merge the stock from the server with the stock from local state
    //overwrite local state with server state
    productStockById[productStock.id] = {...productStockById[productStock.id], ...productStock,  }
    //index product storck ids by product ids
    productStockIdsByProductId[productStock.productId] = {
                                                            ...productStockIdsByProductId[productStock.productId],
                                                            [productStock.id]: true
                                                        }
    //if th seller is not in the sku map, add them
    if (!productStockIdsBySellerSKU[productStock.sellerId]) productStockIdsBySellerSKU[productStock.sellerId] = {}
    //if the sstok has an sku number, index the id by this number
    if (productStock.skuNumber) {
        if ( productStockIdsBySellerSKU[productStock.sellerId][productStock.skuNumber] && 
            ( productStockIdsBySellerSKU[productStock.sellerId][productStock.skuNumber] !== productStock.id)
        ){
            logError(`Duplicate SKU detected, ${productStock.skuNumber} for seller ${productStock.sellerId} product ${productStock.productId} stock was indexed ${productStockIdsBySellerSKU[productStock.sellerId][productStock.skuNumber]} but is being changed to ${productStock.id}`)
        }
        const sellerSkus = {...productStockIdsBySellerSKU[productStock.sellerId]}
        sellerSkus[productStock.skuNumber] = productStock.id 
        productStockIdsBySellerSKU[productStock.sellerId] = sellerSkus
    }
    return {productStockById, productStockIdsByProductId, productStockIdsBySellerSKU}
}
export default productStockReducer