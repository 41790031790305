import React from "react"
import styles from "./DeliveryRangeBanner.module.css"
import PhoneText from "../../images/phonetext.jpg"
import DeliveryCloud from "../../images/deliverycloud2.JPG"
import DeliveryPriceCalculator from "../../containers/DeliveryPriceCalculator"

const DeliveryRangeBanner = () => {
    return (
        <div className={styles.container}>
            <div className={`${styles.visual}`}>
                <img src={DeliveryCloud}/> 
                {/* <img src={PhoneText} /> */}
            </div>
            <div className={`${styles.content} ${styles.big}`}>
                <div className={styles.title}>Island-Wide Delivery<br/><span className={styles.cost}>From EC $6</span></div>
            </div>
            <div className={styles.visual}>
                <DeliveryPriceCalculator />
            </div>
            <div className={`${styles.content} ${styles.small}`}>
            <div className={styles.title}>Island-Wide Delivery<br/><span className={styles.cost}>From EC $6</span></div>
            </div>
        </div>
    )
}

export default DeliveryRangeBanner