import React from "react"
import styles from "./ProductCloseup.module.css"
import StarRatingComponent from 'react-star-rating-component';
import QuantityDropdown from "../../components/QuantityDropdown"
import ShareButton from "../../components/ShareButton"
import DisabledButton from "../../components/DisabledButton"
import MultiImageFrame from "../../components/MultiImageFrame"
import SocialMetric from "../../components/SocialMetric"
import SellerBadge from "../../components/SellerBadge"
import ProductVariantPicker from "../../components/ProductVariantPicker"

import DeliveryPriceCalculator from "../../containers/DeliveryPriceCalculator"
import RequestRestockButton from "../RequestRestockButton";

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {withRouter, Redirect} from "react-router-dom"

import {logAnalyticsEvent} from "../../config/firebase"
import {ecommerceEvents} from "../../constants/firebase"
import {getProductPromotion, getProductStockPromotion, calculatePromotionalPrice, getPromotionOfferString} from "../../utils/promotions"
import {TIMING_TYPE_EVENT} from "../../constants/promotions"
import { getDisplayVariantId } from "../../utils/productUtils";
import {capitalizeAllWords} from "../../utils/stringUtils"

class ProductCloseup extends React.Component{
    
    constructor(props){
        super(props)
        const {products, productId, productStock} = props
        const product = products.productsById[productId]
        //minimum quantity that a cusstomer can order
        const minimumOrderQty = product && product.minimumOrderQty ? product.minimumOrderQty : 1
        this.state = {
            quantity: minimumOrderQty,
            minimumOrderQty,
            displayVariantId: this.getDefaultDisplayVariant()
        }
    }

    componentDidMount(){
        this.logAnalytics()
    }

    logAnalytics = () => {
        //google analytics
        //log that a user viewed the product details page
        const {products, productId, actions} = this.props
        const product = products.productsById[productId]
        if (product) {
            logAnalyticsEvent(ecommerceEvents.SELECT_ITEM, product.analyticsItem)
            actions.fetchLogDeviceSessionAction({
                action: "viewProductDetails", 
                productId: product.id,
                productTitle: product.titleAndBrand,
                price: product.price
            })
        }
    }
    componentDidUpdate(prevProps){
        const {productId, products, productStock, loaded} = this.props
        const {displayVariantId} = this.state
        if (
            !prevProps.products.productsById[productId] &&
            products.productsById[productId]
        ){
            //if we have just loaded the product, log analytics
            this.logAnalytics()
        }
        if (
            //the value of productStock.productStockIdsByProductId[productId] is undefined before the stock is loaded
            (
                !prevProps.productStock.productStockIdsByProductId[productId] && 
                productStock.productStockIdsByProductId[productId]
            ) ||
            (
                prevProps.productStock.productStockById[displayVariantId] &&
                productStock.productStockById[displayVariantId] &&
                prevProps.productStock.productStockById[displayVariantId].isDiscontinued !== 
                productStock.productStockById[displayVariantId].isDiscontinued
            )
        ){
            this.setState({
                displayVariantId: this.getDefaultDisplayVariant()
            })
        }
    }

    getDefaultDisplayVariant = () => {
        const {defaultProductStockId, productStock, products, productId} = this.props
        //if there is a default stock id and it is valid
        if (
            defaultProductStockId && 
            productStock.productStockIdsByProductId[productId] &&
            productStock.productStockIdsByProductId[productId][defaultProductStockId] &&
            //check whether the product stock is discontinued
            productStock.productStockById[defaultProductStockId] &&
            !productStock.productStockById[defaultProductStockId].isDiscontinued
        ) {
            return defaultProductStockId
        }
        const product = products.productsById[productId]
        if (!product) return ""
        return getDisplayVariantId(product, productStock)
    }
    
    //change the quantity selected of the current stock
    handleChangeQuantity = quantity => this.setState({quantity})

    //add the selected stock option to cart
    handleAddToCart = selectedStockOption => {
        const {actions, sellers, products, productId, handleGoBack} = this.props
        const {quantity} = this.state 
        handleGoBack()
        const seller = sellers.sellersById[selectedStockOption.sellerId]
        const product = products.productsById[productId]
        if (product){
            //google analytics
            logAnalyticsEvent(ecommerceEvents.ADD_TO_CART, product.analyticsItem)
            actions.fetchLogDeviceSessionAction({
                action: "addToCart", 
                name: product.titleAndBrand,
                productId: product.id,
                productStockId: selectedStockOption.id,
                price: selectedStockOption.price,
                quantity,
                method: "from details"
            })
        }
        actions.fetchAddToCart(selectedStockOption, quantity)
    }

    handleVisitSellerProfile = sellerId => {
        const {history} = this.props
        history.push(`/sellers/${sellerId}`)
    }

    handleChangeDisplayVariant = (displayVariantId) => {
        const {minimumOrderQty} = this.state
        this.setState({
            displayVariantId,
            quantity: minimumOrderQty
        })
    } 

    render(){
        const  {
            productId,
            products,
            productStock,
            sellers,
            events,
            user, 
            promotions,
            loaded //since aall products are no longer loaded at startup, this product may not be on the frontend
        } = this.props
        //if there is no product or the product is inactive then redirect
        const product = products.productsById[productId] 
        if (!product && !loaded) return ""
        if ((!product && loaded) || (product && product.isInactive)){
            return <Redirect to="/"/>
        }
        const {quantity, minimumOrderQty, displayVariantId} = this.state
        const units = product.units ? product.units : ""
        const sellerIds = sellers.sellerIdsByProductId[productId] ? 
                            Object.keys(sellers.sellerIdsByProductId[productId])
                            :
                            []
        const sellerCount = sellerIds.length
        const seller = sellerCount === 1 ? sellers.sellersById[sellerIds[0]] : null
        //if there is only one seller and that seller is inactive
        if (seller && !seller.isActive){
            return <Redirect to="/"/>
        }
        const selectedStockOption = productStock.productStockById[displayVariantId]
        const instructions = ""
        //get the price from the stock if one is selected and the product otherwise
        const price = selectedStockOption ?
                      selectedStockOption.price.toFixed(2)
                      :
                      product.price.toFixed(2)
        //get images for multiimageframe
        const imageUrls = selectedStockOption && selectedStockOption.imageUrls ?
                         selectedStockOption.imageUrls
                         :
                         [product.imageUrl]
        const images = imageUrls.map(url => ({url}))
        //determine whether it should be possible to add the current stock option to cart
        const showAddToCart = sellerCount === 1 && selectedStockOption && (selectedStockOption.quantityInStock >= minimumOrderQty)

        //if stock is selected, get the promo for the stock,
        //otherwise get the promo for the product
        const promotion = selectedStockOption ? 
                        getProductStockPromotion(selectedStockOption.id, productId, product.createdBySellerId, user.activeSellerAccountId, promotions)
                        :     
                        getProductPromotion(productId, product.createdBySellerId, user.activeSellerAccountId, promotions)
        let currentPrice = price
        if (promotion){
            currentPrice = calculatePromotionalPrice(price, promotion).toFixed(2)
        }
        let event = promotion && promotion.timingType === TIMING_TYPE_EVENT ?
                    events.eventsById[promotion.terms.eventId]
                    :
                    null
        const saleStyle = promotion ? styles.sale : ""
        const sellerName = seller ? capitalizeAllWords(seller.name) : ""
        return (
        <div className={`${styles.container} ${saleStyle}`}>
            
            <div className={styles.frame}>
                <p className={["productTitle", styles.productTitle].join(" ")}>{product.titleAndBrand}{units ? ` EC$${currentPrice}/${units}`: ""}</p>
                {
                    seller ?
                    <div className={styles.fromSeller}> 
                        <span>from</span>
                        <SellerBadge 
                            seller={seller} 
                            onClick={()=>this.handleVisitSellerProfile(seller.id)}
                        />
                    </div>
                    :
                    null
                }
                {/* <StarRatingComponent 
                    name="product-details-star-rating"
                    value={product.starRating}
                    editing={false}
                /> */}
                <div className={styles.characteristics}>
                    <ProductVariantPicker 
                        variants={product.stock} 
                        characteristics={product.characteristics} 
                        selected={displayVariantId}
                        onChange={this.handleChangeDisplayVariant}
                        filterCharOptionsWithNoMatch={true}
                    />
                </div>
                <div className={styles.image}>
                    <MultiImageFrame images={images}/>
                </div>
            </div>
            <div className={styles.details}>
                 {/* if the promotion is from an event, get the event text */}
                {
                    event
                    ? 
                    <div className="saleEventFlag">{event.name} Deal ({getPromotionOfferString(promotion)} off)</div> 
                    : 
                    null
                }
                {
                    !showAddToCart ?
                    <p className={styles.price}>
                        <span>EC ${currentPrice}</span>
                        {promotion ? <span className="salePreviousPrice">${price}</span> : null}
                    </p>
                    :
                    null
                }
                
                {
                    showAddToCart ?
                    <React.Fragment>
                        <p className={styles.price}>
                            <span>EC ${(currentPrice * quantity).toFixed(2)}</span> 
                            {promotion ? <span className="salePreviousPrice">${(price * quantity).toFixed(2)}</span> : null}
                            {quantity > 1 ? ` for ${quantity}${units ? ` ${units}s` : ""}`:null}
                        </p>
                        <button 
                            className={["button", "primary", styles.cartButton].join(" ")}
                            onClick={() => this.handleAddToCart(selectedStockOption)}
                        >
                            Add To Cart
                        </button>
                        <div className={styles.metrics}>
                            <SocialMetric text={`${product.views} views`} icon="visibility"/>
                            <ShareButton />
                        </div>
                        { product.description && product.description.trim()? <p className={styles.description}>{product.description}</p> : null}
                        <div className={styles.quantity}>
                            <QuantityDropdown 
                                min={minimumOrderQty}
                                max={selectedStockOption ? selectedStockOption.quantityInStock : 0}
                                onSelect={this.handleChangeQuantity} 
                                units={units}
                                selected={quantity}
                            />
                        </div>
                        <p ><span className={styles.price}>EC ${currentPrice}</span> each</p>

                    </React.Fragment>
                    :

                    sellerCount === 1 && Object.keys(product.stock).length > 0 && !selectedStockOption ?
                    <div className={styles.stockMessage}>
                        <DisabledButton
                            alert={instructions}
                        >
                            No Product Matches
                        </DisabledButton>
                    </div>
                    :
                    (selectedStockOption && (selectedStockOption.quantityInStock < 1)) || !product.stock || Object.keys(product.stock).length === 0 ?
                    <div className={styles.stockMessage}>
                        <DisabledButton
                            alert={instructions}
                        >
                            Out of Stock
                        </DisabledButton>
                        {
                            selectedStockOption ?
                            <RequestRestockButton 
                                productId={product.id} 
                                productStockId={selectedStockOption.id}
                                productTitle={product.titleAndBrand} 
                                productImageUrl={selectedStockOption.imageUrl ? selectedStockOption.imageUrl : product.imageUrl}
                                minimumOrderQty={product.minimumOrderQty}
                                price={selectedStockOption.price}
                                skuNumber={selectedStockOption.skuNumber}
                                variantCharacteristics={selectedStockOption.characteristics}
                                restockRequestCount={selectedStockOption.restockRequestCount}
                                sellerId={product.createdBySellerId}
                                sellerName={sellerName}
                                sellerAlertEmail={seller ? seller.alertEmail : ""}
                                sellerLogoImageUrl={seller && seller.logoImageUrlMed ? seller.logoImageUrlMed : ""}
                            />
                            :
                            null
                        }
                    </div>
                    :
                    <div className={styles.stockMessage}>
                        <DisabledButton
                            alert={instructions}
                            className={styles.cartButton}
                        >
                            Add To Cart
                        </DisabledButton>
                    </div>
                }
                
                {
                    !showAddToCart ? 
                    <React.Fragment>
                        <div className={styles.metrics}>
                            <SocialMetric text={`${product.views} views`} icon="visibility"/>
                            <ShareButton />
                        </div>
                        <p className={styles.description}>{product.description}</p> 
                    </React.Fragment>
                    : null
                }
                <div className={styles.characteristics}>
                    <ProductVariantPicker 
                        variants={product.stock} 
                        characteristics={product.characteristics} 
                        selected={displayVariantId}
                        onChange={this.handleChangeDisplayVariant}
                        filterCharOptionsWithNoMatch={true}
                    />
                </div>
                {/* Do not show the delivery price calculator for digital products */}
                {
                    product.isDigital || (seller && seller.hideDeliveryCalculator)? 
                    ""
                    :
                    <div className={styles.calculator}>
                        <DeliveryPriceCalculator />
                    </div>
                }
            </div>
        </div>
        )
    }
}

const mapStateToProps = state => ({
    sellers: state.sellers,
    categories: state.categories,
    products: state.products,
    productStock: state.productStock,
    promotions: state.promotions,
    events: state.events,
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductCloseup))