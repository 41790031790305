import {DEVELOPMENT, PRODUCTION} from "../constants/environment"
import {selectedEnvironment} from "../config"
import {DELIVERY_SLOTS_IDENTIFIER} from "./platformSettings"

export const PICK_UP_INSTORE = 'PICK_UP_INSTORE'
export const SELF_DELIVERY = 'SELF_DELIVERY'
export const THIRD_PARTY_DELIVERY = 'THIRD_PARTY_DELIVERY'
export const VIRTUAL_DELIVERY_ID = '--virtual-delivery--'
export const DHL_DELIVERY_ID = selectedEnvironment === PRODUCTION ? 'H5FT0Kzyktbr3KO7Zvra' : 'WrHTmazB6imRoMbo9ZFE'
export const SELF_DELIVERY_ID = selectedEnvironment === PRODUCTION ? "3T92f2WVneLXEfN4II8Q" : "JG7cBvGgoOQvJFw8Q2Bo"
export const SHOPDM_DELIVERY_ID = selectedEnvironment === PRODUCTION ? "FUmBijiPTuGVKNLOM7Wl" : "GlarWVNG3jFW2lEMczWH"
export const PICK_UP_INSTORE_ID = selectedEnvironment === PRODUCTION ? "WvvRXY1oCZ2fX68gJZ6W" : "miKuR88A4zOQYvu4uLVu"
export const MERO_SETTLEMENT_ID = selectedEnvironment === PRODUCTION ? "dc1e0acb-7150-424b-abbd-67b95d4efa4c" : "992ab42b-a88e-4402-8a3f-0bb53729ac0a"
export const ROSEAU_SETTLEMENT_ID = selectedEnvironment === PRODUCTION ? "02b0c14f-49ec-49b4-86b1-7dc479be8bc0" : "1531a679-a2d8-4bc7-876e-fd99e5523908"

export const DELIVERY_PROVIDER_TYPE_AGENT_BASED = "DELIVERY_PROVIDER_TYPE_AGENT_BASED"
export const DELIVERY_PROVIDER_TYPE_OPENING_HOURS = "DELIVERY_PROVIDER_TYPE_OPENING_HOURS"
export const DELIVERY_PROVIDER_TYPE_PICK_UP = "DELIVERY_PROVIDER_TYPE_PICK_UP"
export const DELIVERY_PROVIDER_TYPE_MERCHANT_DELIVERY = "DELIVERY_PROVIDER_TYPE_MERCHANT_DELIVERY"
export const DELIVERY_PROVIDER_TYPE_VIRTUAL = "DELIVERY_PROVIDER_TYPE_VIRTUAL"
export const DELIVERY_PROVIDER_TYPE_EXPORT = "DELIVERY_PROVIDER_TYPE_EXPORT"

export const DELIVERY_ENTITY_TYPE_AGENT = "DELIVERY_AGENT"
export const DELIVERY_ENTITY_TYPE_DELIVERY_PROVIDER = "DELIVERY_PROVIDER"
export const DELIVERY_ENTITY_TYPE_SHOPDM = "SHOPDM"
export const DELIVERY_ENTITY_ID_SHOPDM = "SHOPDM"

export const DELIVERY_FEE_TYPE_LOCATION_BASED = "locationBased"
export const DELIVERY_FEE_TYPE_FIXED = "fixed"

export const DELIVERY_SLOT_TYPE_EARLY_MORNING = 'EARLY_MORNING'
export const DELIVERY_SLOT_TYPE_LATE_MORNING = 'LATE_MORNING'
export const DELIVERY_SLOT_TYPE_EARLY_AFTERNOON = 'EARLY_AFTERNOON'
export const DELIVERY_SLOT_TYPE_LATE_AFTERNOON = 'LATE_AFTERNOON'
export const DELIVERY_SLOT_TYPE_EVENING = 'EVENING'
export const DELIVERY_SLOT_TYPE_LATE_NIGHT = 'LATE_NIGHT'
export const deliverySlotTypeToHumanReadable = {
    [DELIVERY_SLOT_TYPE_EARLY_MORNING] : 'early morning',
    [DELIVERY_SLOT_TYPE_LATE_MORNING] : 'late morning',
    [DELIVERY_SLOT_TYPE_EARLY_AFTERNOON] : 'early afternoon',
    [DELIVERY_SLOT_TYPE_LATE_AFTERNOON] : 'late afternoon',
    [DELIVERY_SLOT_TYPE_EVENING] : 'evening',
    [DELIVERY_SLOT_TYPE_LATE_NIGHT] : 'late night',
}

export const SUPPORTED_DELIVERY_SLOT_MAP = {
    [DELIVERY_SLOT_TYPE_EARLY_MORNING] : true,
    [DELIVERY_SLOT_TYPE_LATE_MORNING] : true,
    [DELIVERY_SLOT_TYPE_EARLY_AFTERNOON] : true,
    [DELIVERY_SLOT_TYPE_LATE_AFTERNOON] : true,
    [DELIVERY_SLOT_TYPE_EVENING] : true
}

export const DEFAULT_DELIVERY_SLOTS = {
    id: DELIVERY_SLOTS_IDENTIFIER,
    types: {
        [DELIVERY_SLOT_TYPE_EARLY_MORNING]: {
            id: DELIVERY_SLOT_TYPE_EARLY_MORNING,
            type: DELIVERY_SLOT_TYPE_EARLY_MORNING,
            startTime: "07:00",
            endTime: "10:00"
        },
        [DELIVERY_SLOT_TYPE_LATE_MORNING]: {
            id: DELIVERY_SLOT_TYPE_LATE_MORNING,
            type: DELIVERY_SLOT_TYPE_LATE_MORNING,
            startTime: "10:00",
            endTime: "12:00"
        },
        [DELIVERY_SLOT_TYPE_EARLY_AFTERNOON]: {
        id: DELIVERY_SLOT_TYPE_EARLY_AFTERNOON,
            type: DELIVERY_SLOT_TYPE_EARLY_AFTERNOON,
            startTime: "12:00",
            endTime: "15:00"
        },
        [DELIVERY_SLOT_TYPE_LATE_AFTERNOON]: {
            id: DELIVERY_SLOT_TYPE_LATE_AFTERNOON,
            type: DELIVERY_SLOT_TYPE_LATE_AFTERNOON,
            startTime: "15:00",
            endTime: "18:00"
        },
        [DELIVERY_SLOT_TYPE_EVENING]: {
            id: DELIVERY_SLOT_TYPE_EVENING,
            type: DELIVERY_SLOT_TYPE_EVENING,
            startTime: "18:00",
            endTime: "21:00"
        },
        [DELIVERY_SLOT_TYPE_LATE_NIGHT]: {
            id: DELIVERY_SLOT_TYPE_LATE_NIGHT,
            type: DELIVERY_SLOT_TYPE_LATE_NIGHT,
            startTime: "21:00",
            endTime: "23:00"
        },
    }
}

export const DELIVERY_AGENT_TYPE_BUS_DRIVER = "BUS_DRIVER"
export const DELIVERY_AGENT_TYPE_INDIVIDUAL = 'INDIVIDUAL'

export const DELIVERY_VEHICLE_TYPE_BUS = 'BUS'
export const DELIVERY_VEHICLE_TYPE_CAR = 'CAR'
export const DELIVERY_VEHICLE_TYPE_SUV = 'SUV'
export const DELIVERY_VEHICLE_TYPE_TRUCK = 'TRUCK'
export const DELIVERY_VEHICLE_TYPE_SCOOTER = 'SCOOTER'
export const DELIVERY_VEHICLE_TYPE_VAN = 'VAN'

export const DELIVERY_VEHICLE_CAPACITY = {
    [DELIVERY_VEHICLE_TYPE_BUS]: {avg: 14, max: 20},
    [DELIVERY_VEHICLE_TYPE_CAR]: {avg: 6, max: 10},
    [DELIVERY_VEHICLE_TYPE_SUV]: {avg: 8, max: 10},
    [DELIVERY_VEHICLE_TYPE_TRUCK]: {avg: 14, max: 26},
    [DELIVERY_VEHICLE_TYPE_SCOOTER]: {avg: 2, max: 2},
    [DELIVERY_VEHICLE_TYPE_VAN]: {avg: 10, max: 14},
}

export const DELIVERY_VEHICLE_COLOR_RED = 'RED'
export const DELIVERY_VEHICLE_COLOR_BLUE = 'BLUE'
export const DELIVERY_VEHICLE_COLOR_BLACK = 'BLACK'
export const DELIVERY_VEHICLE_COLOR_WHITE = 'WHITE'
export const DELIVERY_VEHICLE_COLOR_GRAY = 'GRAY'
export const DELIVERY_VEHICLE_COLOR_GREEN = 'GREEN'
export const DELIVERY_VEHICLE_COLOR_YELLOW = 'YELLOW'
export const DELIVERY_VEHICLE_COLOR_GOLD = 'GOLD'
export const DELIVERY_VEHICLE_COLOR_SILVER = 'SILVER'
export const DELIVERY_VEHICLE_COLOR_PINK = 'PINK'
export const DELIVERY_VEHICLE_COLOR_PURPLE = 'PURPLE'
export const DELIVERY_VEHICLE_COLOR_OTHER = 'OTHER'

export const DELIVERY_VEHICLE_COLORS = [
    DELIVERY_VEHICLE_COLOR_RED,
    DELIVERY_VEHICLE_COLOR_BLUE,
    DELIVERY_VEHICLE_COLOR_BLACK,
    DELIVERY_VEHICLE_COLOR_WHITE,
    DELIVERY_VEHICLE_COLOR_GRAY,
    DELIVERY_VEHICLE_COLOR_GREEN,
    DELIVERY_VEHICLE_COLOR_YELLOW,
    DELIVERY_VEHICLE_COLOR_GOLD,
    DELIVERY_VEHICLE_COLOR_SILVER, 
    DELIVERY_VEHICLE_COLOR_PINK, 
    DELIVERY_VEHICLE_COLOR_PURPLE,
    DELIVERY_VEHICLE_COLOR_OTHER,
]

export const DELIVERY_VEHICLE_DRIVETRAIN_2WD = '2WD'
export const DELIVERY_VEHICLE_DRIVETRAIN_4WD = '4WD'
export const DELIVERY_VEHICLE_DRIVETRAINS = [
    DELIVERY_VEHICLE_DRIVETRAIN_2WD,
    DELIVERY_VEHICLE_DRIVETRAIN_4WD
]
export const DELIVERY_VEHICLE_DRIVETRAIN_DEFAULTS = {
    [DELIVERY_VEHICLE_TYPE_BUS]: DELIVERY_VEHICLE_DRIVETRAIN_2WD,
    [DELIVERY_VEHICLE_TYPE_CAR]: DELIVERY_VEHICLE_DRIVETRAIN_2WD,
    [DELIVERY_VEHICLE_TYPE_SUV]: DELIVERY_VEHICLE_DRIVETRAIN_4WD,
    [DELIVERY_VEHICLE_TYPE_TRUCK]: DELIVERY_VEHICLE_DRIVETRAIN_4WD,
    [DELIVERY_VEHICLE_TYPE_SCOOTER]: DELIVERY_VEHICLE_DRIVETRAIN_2WD,
    [DELIVERY_VEHICLE_TYPE_VAN]: DELIVERY_VEHICLE_DRIVETRAIN_4WD, 
}

export const DEFAULT_DELIVERY_PROVIDER_DAILY_OPENING_HOURS = {openingTime: "08:00", closingTime: "20:00"}
export const DEFAULT_DELIVERY_PROVIDER_OPENING_HOURS = {
  monday: DEFAULT_DELIVERY_PROVIDER_DAILY_OPENING_HOURS,
  tuesday: DEFAULT_DELIVERY_PROVIDER_DAILY_OPENING_HOURS,
  wednesday: DEFAULT_DELIVERY_PROVIDER_DAILY_OPENING_HOURS,
  thursday: DEFAULT_DELIVERY_PROVIDER_DAILY_OPENING_HOURS,
  friday: DEFAULT_DELIVERY_PROVIDER_DAILY_OPENING_HOURS,
  saturday: DEFAULT_DELIVERY_PROVIDER_DAILY_OPENING_HOURS,
}