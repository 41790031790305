import {DEVELOPMENT, PRODUCTION} from "../constants/environment"
import {selectedEnvironment} from "./index"

const environments = {
  [DEVELOPMENT]:{ //sandbox card processing 
    formAction: 'https://dev.jad.cash/handlers/webpay.aspx',
    paymentId: '5B14A2A2-C112-47BA-898D-885F811917E7',
    responseUrlOrigin: 'https://shop-dm-dev.web.app'
  },
  [PRODUCTION]: { //live production card processing config
    formAction: 'https://jad.cash/handlers/webpay.aspx',
    paymentId: '28daf066-6f46-4c51-a6f3-07fece295835',
    responseUrlOrigin: 'https://shopdm.store'
  }
}

export const config = environments[selectedEnvironment]


export default config;