import React from 'react'
import Icon from "../Icon"

import styles from './SortButton.module.css'

export default ({
        onClickUp=()=>{},
        onClickDown=()=>{},
        hide=null
    }) => {
        const topStyle = hide === "top"?  styles.hidden : hide ? styles.center : ""
        const bottomStyle = hide === "bottom" ? styles.hidden : hide ? styles.center : ""
    return (
        <span className={styles.container}>   
            <span onClick={onClickUp} className={[styles.top, topStyle].join(" ")}><Icon icon="arrow-drop-up"/></span>
            <span onClick={onClickDown} className={[styles.bottom, bottomStyle].join(" ")}><Icon icon="arrow-drop-down"/></span>
        </span>
    )
}