/**
 *  Purpose: the modifications that are carried on the User object for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
// STATE
// user:{
//  firstName: "",
//  lastName: "",
//  email: "",
//  authenticated:false,
//  activeSellerAccountId: null,
//  lastLogin: null
// }


const userReducer = (state = initialState.user, action) => {
    const {type, payload} = action;
    const addressesById = {...state.addressesById}
    switch (type){
        case types.LOGIN: {
            return {
                ...state,
                ...payload.user
            }
        }
        case types.SAVE_USER: {
            let {user} = payload
            if (!user) user = {} //stops crash during no internet
            if (!user.follows && !state.follows) user.follows = {} 
            if (!user.events && !state.events) user.events = {} 
            return {
                ...state,
                ...user
            }
        }
        case types.LOGOUT: {
            return initialState.user
        }

        case types.CHANGE_ENVIRONMENT: {
            return initialState.user
        }
        case types.ADD_ADDRESS: {
            if(typeof payload.address !== "object") {
                logError(`userReducer > ADD_ADDRESS: address payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            addressesById[payload.address.id] = payload.address
            return  {
                ...state,
                addressesById,
                defaultAddressId: payload.address.id
            }
        }

        case types.SET_DEFAULT_USER_ADDRESS: {
            if(typeof payload.addressId !== "string") {
                logError(`userReducer > SET_DEFAULT_USER_ADDRESS: addressId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                defaultAddressId: payload.addressId
            }
        }

        case types.EDIT_USER_ADDRESS: {
            if(typeof payload.address !== "object") {
                logError(`userReducer > EDIT_USER_ADDRESS: address payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            addressesById[payload.address.id] = payload.address
            return  {
                ...state,
                addressesById
            }
        }
         
        case types.DELETE_USER_ADDRESS: {
            if(typeof payload.addressId !== "string") {
                logError(`userReducer > DELETE_USER_ADDRESS: addressId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.defaultAddressId !== "string") {
                logError(`userReducer > DELETE_USER_ADDRESS: defaultAddressId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            delete addressesById[payload.addressId]
            return {
                ...state,
                addressesById,
                defaultAddressId: payload.defaultAddressId
            }
        }

        case types.SET_ACTIVE_SELLER_ACCOUNT:{
            if(typeof payload.activeSellerAccountId !== "string") {
                logError(`userReducer > SET_ACTIVE_SELLER_ACCOUNT: activeSellerAccountId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                activeSellerAccountId: payload.activeSellerAccountId
            }
        }

        case types.SET_ACTIVE_DELIVERY_PROVIDER_ACCOUNT:{
            if(typeof payload.activeDeliveryProviderAccountId !== "string") {
                logError(`userReducer > SET_ACTIVE_DELIVERY_PROVIDER_ACCOUNT: activeDeliveryProviderAccountId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                activeDeliveryProviderAccountId: payload.activeDeliveryProviderAccountId
            }
        }

        case types.UPDATE_UNSUBSCRIBED: {
            return {
                ...state,
                unsubscribed: payload.unsubscribed
            }
        }

        case types.SUBSCRIBE: {
            return {
                ...state,
                subscribed: true,
                subscribedAt: Date.now(),
                subscriptionEmail: payload.subscriptionEmail,
                unsubscribed: false
            }
        }

        case types.SET_USER_PROFILE_IMAGE: {
            if(typeof payload.profileImageUrl !== "string") {
                logError(`userReducer > SET_USER_PROFILE_IMAGE: profileImageUrl in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.profileImageUrlMed !== "string") {
                logError(`userReducer > SET_USER_PROFILE_IMAGE: profileImageUrlMed in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.profileImageUrlSmall !== "string") {
                logError(`userReducer > SET_USER_PROFILE_IMAGE: profileImageUrlSmall in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            const {profileImageUrl, profileImageUrlMed, profileImageUrlSmall} = payload
            return {
                ...state,
                profileImageUrl,
                profileImageUrlMed,
                profileImageUrlSmall
            }
        }

        case types.FOLLOW_SELLER: {
            if(typeof payload.sellerId !== "string") {
                logError(`userReducer > FOLLOW_SELLER: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                follows: {
                    ...state.follows,
                    [payload.sellerId]: Date.now()
                }
            }
        }

        case types.UNFOLLOW_SELLER: {
            if(typeof payload.sellerId !== "string") {
                logError(`userReducer > UNFOLLOW_SELLER: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            const follows = {...state.follows}
            delete follows[payload.sellerId] 
            return {
                ...state,
                follows
            }
        }

        case types.SAVE_USER_FOLLOWS: {
            if(typeof payload.follows !== "object") {
                logError(`userReducer > SAVE_USER_FOLLOWS: follows in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                follows: payload.follows
            }
        }

        case types.SUBSCRIBE_USER_TO_EVENT: {
            if(typeof payload.eventId !== "string") {
                logError(`userReducer > SUBSCRIBE_USER_TO_EVENT: eventId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                events: {
                    ...state.events,
                    [payload.eventId]: Date.now()
                }
            }
        }
        default: return state
    }
}

export default userReducer