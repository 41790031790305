import React from "react"
import Modal from "../Modal"
import MultiImage from "../MultiImage"
import Icon from "../Icon"
import Thumbnail from "../Thumbnail"

import styles from "./MultiImageModal.module.css"

class MultiImageModal extends React.Component{

    state = {
        modalOpen: false
    }

    multiImage = null

    static defaultProps = {
        images: [],
        title: "",
        onSave: ()=>{}
    }
    handleOpenModal = () => this.setState({modalOpen: true})
    handleCloseModal = () => this.setState({modalOpen: false})
    
    handleSave = () => {
        if (this.multiImage) {
            this.multiImage.handleSave()
            this.handleCloseModal()
        }
    }
    render(){
        const {
            images, 
            title,
            onSave
        } = this.props
        const {modalOpen} = this.state
        return (
            <div>
                <div
                    className={styles.thumbnailContainer}
                    onClick={this.handleOpenModal}
                >
                    <Thumbnail 
                        url={images.length > 0 ? images[0].url: ""}
                    />
                    {
                        images.length > 1 ?
                        <div className={styles.imageCount}>{images.length}</div>
                        :
                        null
                    }
                </div>
                {
                    modalOpen ? 
                    <Modal
                        isOpen={modalOpen}
                        closeModal={this.handleCloseModal}
                        className={styles.modal}
                    >
                        { title ? <div className={`strong ${styles.title}`}>{title}</div> : null}
                        <MultiImage 
                            ref={el => this.multiImage = el}
                            images={images}
                            onSave={onSave}
                        />
                        <div className="buttonHolder">
                            <button className="button white icon" onClick={this.handleCloseModal}><Icon icon="close"/> Cancel</button>
                            <button className="button primary icon" onClick={this.handleSave}><Icon icon="done"/> Save</button>
                        </div>
                    </Modal>
                    :
                    null
                }
            </div>
        )
    }
}


export default MultiImageModal

