import React from 'react'
import PropTypes from "prop-types"
import SellerList from "../SellerList"
import CategoryList from "../CategoryList"

import styles from "./SideBar.module.css"

import { SELLER_OPTION_TYPE, CATEGORY_OPTION_TYPE} from "../../constants/firebase"
function SideBar({sellers, categories, history}){
    return(
        <div className={styles.container}>
            <SellerList />
            <CategoryList />
        </div>
    )
}

export default SideBar;