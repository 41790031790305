import React from "react"
import Icon from "../Icon"
const SocialMetric = ({icon, text="", onClick=null}) => {
    return (
        <div className={`socialMetric ${onClick? 'interactive' : ""}`} onClick={onClick ? onClick : ()=>{}}>
            { icon ? <Icon icon={icon}/> : null}
            <span>{text}</span>
        </div>
    )
}

export default SocialMetric