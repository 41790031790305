import React from "react"
import styles from "./EarlyBirdSale2023.module.css"
import CountDown from "../../components/CountDown"
import Icon from "../../components/Icon"
import AvatarIcon from "../../components/AvatarIcon"
import BackLink from "../../components/BackLink"
import ProductTileList from "../../components/ProductTileList"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {withRouter} from "react-router-dom"

import {
    EARLY_BIRD_SALE_2023_ID,
    EVENT_STATUS_CREATED,
    EVENT_STATUS_ONGOING,
} from "../../constants/events"

import {
    PROMOTION_APPLIES_TO_PRODUCTS_TYPE_ALL,
    PROMOTION_APPLIES_TO_PRODUCTS_TYPE_SPECIFIC,
    PROMOTER_TYPE_SELLER
} from "../../constants/promotions"

import {AVATAR_SIZE_MEDIUM, AVATAR_SIZE_SMALL} from "../../constants/interface"

import {logAnalyticsEvent} from "../../config/firebase"

class EarlyBirdSale2023 extends React.Component {

    state = {
        authModalOpen: false,
        notificationsModalOpen: false
    }

    componentDidMount(){
        const {actions} =  this.props
        logAnalyticsEvent("early_bird_sale_2023_visit_page")
        actions.fetchLogDeviceSessionAction({
            action: "earlyBirdSale2023VisitPage"
        })
        this.loadSellerProducts()
    }
    
    loadSellerProducts = () => {
        const {events, actions} = this.props
        const event = events.eventsById[EARLY_BIRD_SALE_2023_ID]
        if (!event || !event.sellerIds) return null
        Object.keys(event.sellerIds).map(sellerId => {
            actions.fetchSaveSellerProducts(sellerId)
        })
    }

    render(){
        const {events, promotions, sellers, user, history, products} = this.props
        const event = events.eventsById[EARLY_BIRD_SALE_2023_ID]
        if (!event) return ""
        let sellerList = [] 
        Object.keys(event.sellerIds).forEach(sellerId => {
            const seller = sellers.sellersById[sellerId]
            if (seller){
                sellerList.push(seller)
            }
        })

        const handleClickSeller = sellerId => history.push(`/sellers/${sellerId}`)

        
        const promotedProductIdsBySellerId = {}
        //set up empty maps for each seller 
        sellerList.forEach(seller => promotedProductIdsBySellerId[seller.id] = {})
        //go thorough each promotion
        Object.values(promotions.promotionsById).forEach(promotion => {
            //if this promotion is not linked to this event, return
            if (!promotion.terms || (promotion.terms.eventId !== event.id)) return
            //verify that the promoter is a seller 
            if (promotion.promoterType === PROMOTER_TYPE_SELLER){
                //if the seller is somehow not in the map, add them
                if (!promotedProductIdsBySellerId[promotion.promoterId]) promotedProductIdsBySellerId[promotion.promoterId] = {}
                //if the promo applies to all their products
                if (promotion.appliesToProductsType === PROMOTION_APPLIES_TO_PRODUCTS_TYPE_ALL){
                    //grab all of the sellers products
                    promotedProductIdsBySellerId[promotion.promoterId] = products.productIdsBySellerId[promotion.promoterId] ?
                                                                        products.productIdsBySellerId[promotion.promoterId] : {}
                } else if (promotion.appliesToProductsType === PROMOTION_APPLIES_TO_PRODUCTS_TYPE_SPECIFIC) {
                    Object.keys(promotion.terms.appliesToProductIds).forEach(productId => {
                        if (
                            products.productIdsBySellerId[promotion.promoterId] && 
                            products.productIdsBySellerId[promotion.promoterId][productId]
                            ) promotedProductIdsBySellerId[promotion.promoterId][productId] = true
                    })
                }   
            }
        })

        sellerList = sellerList.sort((sA, sB) => {
            const sALength = promotedProductIdsBySellerId[sA.id] ? Object.keys(promotedProductIdsBySellerId[sA.id]).splice(0, 3).length : 0
            const sBLength = promotedProductIdsBySellerId[sB.id] ? Object.keys(promotedProductIdsBySellerId[sB.id]).splice(0, 3).length : 0             
            return sBLength - sALength
        })
        return (
            <div className={styles.container}>
                <div className={styles.eventDetails}>
                    <div className={styles.homeLink}><BackLink text="Go To Homepage" path="/"/></div>
                    <div className={styles.hero}>Early Bird Sale 2023</div>
                    <div className={styles.earlyBird}></div>
                    <div className={styles.countdown}>
                    {
                            event.currentStatus === EVENT_STATUS_CREATED ?
                            <div>
                                <div className={styles.startsIn}>Starts in:</div>
                                <div className={styles.timer}><CountDown endTime={event.startDate}/></div>
           
                            </div>
                            :
                            event.currentStatus === EVENT_STATUS_ONGOING ?
                            <div>
                                <div className={styles.startsIn}>Ends in:</div>
                                <div className={styles.timer}><CountDown endTime={event.endDate}/></div>
           
                            </div>
                            :
                            null
                    }
                    </div>
                    <div className={styles.off}>Up to 40% off at Participating Sellers:</div>
                    <div className={styles.timing}>From midnight on Monday 20th to midnight on Thursday 23rd</div>
                </div>
                <div>
                    
                    {
                        sellerList.map(seller => {
                            const productList = Object.keys(promotedProductIdsBySellerId[seller.id])
                                                          .map(productId => products.productsById[productId])
                                                          .filter(product => !product.isInactive)
                                                          .splice(0, 3)
                            return (
                                <div key={`${seller.id}products`} className={styles.sellerDeal}>
                                    <div className={styles.seeMore} onClick={() => handleClickSeller(seller.id)}>
                                        <div className={styles.seeSeller}>
                                        <AvatarIcon 
                                            name={seller.name} 
                                            imageUrl={seller.logoImageUrlSmall} 
                                            size={AVATAR_SIZE_SMALL}
                                        />
                                        <div className={styles.seeMoreName}>{seller.name}</div>
                                        <div>See All Discounts</div>
                                        </div>
                                        <div className={styles.seeAll}><Icon icon="keyboard-arrow-right"/></div>
                                    </div>
                                    <ProductTileList productList={productList} />
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    <div className={styles.off}>Click on any of the sellers below to see their exclusive deals!</div>
                    <div className={styles.sellerContainer}>
                        <div className={styles.sellers}>
                            
                            {
                                sellerList.map(seller => {
                                    return (
                                        <div key={seller.id} className={styles.sellerIcon} onClick={() => handleClickSeller(seller.id)}>
                                            <AvatarIcon 
                                                name={seller.name} 
                                                imageUrl={seller.logoImageUrl} 
                                                size={AVATAR_SIZE_MEDIUM}
                                            />
                                            <div className={styles.sellerName}>{seller.name}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    events: state.events,
    sellers: state.sellers,
    products: state.products,
    user: state.user,
    device: state.device,
    promotions: state.promotions
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EarlyBirdSale2023))