import React from "react"
import PaymentModal from "../../components/PaymentModal"
import ResultModal from "../../components/ResultModal"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import {v4 as uuid4} from 'uuid'
import {
    CREDIT_TRANSACTION_TYPE_CREDIT, 
    CREDIT_TRANSACTION_EFFECT_TYPE_CREDIT,
    CREDIT_STATUS_PENDING,
    CREDIT_STATUS_CANCELLED,
    CREDIT_STATUS_PROCESSED
} from "../../constants/credit"
import {RESULT_SUCCESS, RESULT_WARNING} from "../../constants/results"

class ShopdmCreditPaymentModal extends React.Component{
    
    state = {
        creditTransactionRequestListener: ()=>{},
        creditTransactionRequestId: null,
        result: null,
        resultMessage: ""
    }

    handleCreateCreditTransactionRequest = async () => {
        const {actions, walletId, amount2DecimalPlaces, orderNumber, orderId} = this.props
        this.state.creditTransactionRequestListener()
        actions.toggleLoading(true)
        const creditTransactionRequestId = uuid4()
        const success = await actions.fetchCreateCreditTransactionRequest(
            creditTransactionRequestId,
            walletId,
            Number(amount2DecimalPlaces),
            CREDIT_TRANSACTION_TYPE_CREDIT,
            CREDIT_TRANSACTION_EFFECT_TYPE_CREDIT,
            CREDIT_STATUS_PENDING,
            {orderNumber, orderId}
        )
        if (success) {
            const creditTransactionRequestListener = await actions.fetchSubscribeToCreditTransactionRequest(creditTransactionRequestId)
            if (creditTransactionRequestListener){
                this.setState({
                    creditTransactionRequestListener,
                    creditTransactionRequestId
                })
            }            
        } else {
            this.setState({
                result: RESULT_WARNING,
                resultMessage: "Could not create transaction. Try again and contact Shopdm if this continues"
            })
            actions.toggleLoading(false)
        }
        
    }

    componentDidUpdate(prevProps){
        const {creditTransactionRequestId} = this.state
        const {creditTransactionRequests, actions} = this.props
        const creditTransactionRequest = creditTransactionRequests.creditTransactionRequestsById[creditTransactionRequestId]
        if (creditTransactionRequest){
            const previousCreditTransactionRequest = prevProps.creditTransactionRequests.creditTransactionRequestsById[creditTransactionRequestId]
            if (
                previousCreditTransactionRequest &&
                creditTransactionRequest.currentStatus === CREDIT_STATUS_CANCELLED &&
                previousCreditTransactionRequest.currentStatus === CREDIT_STATUS_PENDING
            ){
                this.setState({
                    result: RESULT_WARNING,
                    resultMessage: creditTransactionRequest.errorDisplayMessage
                }, () => actions.toggleLoading(false))
                
            } else if (
                previousCreditTransactionRequest &&
                creditTransactionRequest.currentStatus === CREDIT_STATUS_PROCESSED &&
                previousCreditTransactionRequest.currentStatus === CREDIT_STATUS_PENDING
            ){
               
               this.setState({
                    result: RESULT_SUCCESS,
                    resultMessage: "payment successful"
                }, () => actions.toggleLoading(false))
            }
        }
    }

    componentWillUnmount(){
        const {actions} = this.props
        this.state.creditTransactionRequestListener()
        actions.toggleLoading(false)
    }
    
    handleCloseResultModal = () => this.setState({result: null, resultMessage: ""})

    render(){     
        const {
            orderNumber, 
            amount2DecimalPlaces,
            walletId, 
            isOpen, 
            closeModal, 
            creditWallets
        } = this.props

        const wallet = creditWallets.creditWalletsById[walletId]
        if (!wallet) return ""
        const {result, resultMessage} = this.state
        return (
            <PaymentModal 
                paymentTitle="Shopdm Credit"
                reference={orderNumber}
                amount2DecimalPlaces={amount2DecimalPlaces}
                totalText="Shopdm Credit used"
                isOpen={isOpen}
                closeModal={closeModal}
                onPressConfirm={this.handleCreateCreditTransactionRequest}
                specificsTitle=""
            >
                <div className={"info-bubble"}>
                    Current Balance: <span className="amount">EC ${Number(wallet.balance).toFixed(2)}</span>
                </div>
                <div className={"info-bubble"}>
                New Balance: <span className="amount">EC ${Number(wallet.balance - amount2DecimalPlaces).toFixed(2)}</span>
                </div>
                {
                    result ?
                    <ResultModal 
                        result={result}
                        autoClose={result === RESULT_SUCCESS}
                        message={resultMessage}
                        onClose={this.handleCloseResultModal}
                    />
                    :
                    null
                }
            </PaymentModal>
        )
    }
}

const mapStateToProps = state => ({
    creditWallets: state.creditWallets,
    creditTransactionRequests: state.creditTransactionRequests
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopdmCreditPaymentModal)