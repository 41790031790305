import React, {useState} from "react"
import styles from "./DeliveryItems.module.css"
import CharacteristicsList from "../../components/CharacteristicsList"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {TIMING_TYPE_EVENT} from "../../constants/promotions"
import {ecommerceEvents} from "../../constants/firebase"
import { logError } from "../../utils/errorHandlingUtils"
import {alphabeticalSort} from "../../utils/stringUtils"
import {logAnalyticsEvent} from "../../config/firebase"

const DeliveryItems = ({sellerId, cart, products, appliedPromotions, promotions, events, actions}) => {
    const [listOpen, setListOpen] = useState(false)
    const itemIds = cart.itemIdsBySellerId[sellerId]
    //sort the cart items alphabetically
    const items = Object.keys(itemIds).map(itemId => {
        const item = cart.itemsByProductStockId[itemId]
        return item
    }).sort((itemA, itemB)=>{
        const productA = products.productsById[itemA.productId]
        const productB = products.productsById[itemB.productId]
        if (!productA || !productB) return 0
        return alphabeticalSort(productA.titleAndBrand, productB.titleAndBrand)
    })
    return (
        <div className={styles.container}>
            <div className="greenAnchor" onClick={() => setListOpen(!listOpen) }>{listOpen ? "hide" : "see or remove"} items</div>
            {
                listOpen ?
                <div className={styles.list}>
                {
                        items.map(item => {
                            const product = products.productsById[item.productId]
                            if (!product){
                                logError(`product ${product} with product id ${item.productId} from item ${item.id} from seller ${item.sellerId} is showing as undefined/falsy. Auto removing`)
                                actions.fetchRemoveFromCart(item.id)
                                return ""
                            }
                            const imageUrl = item.imageUrl ? item.imageUrl : product.imageUrl
                            const promotionId = appliedPromotions[item.id]
                            const promotion = promotionId ? promotions.promotionsById[promotionId] : null
                            const event = promotion && promotion.timingType === TIMING_TYPE_EVENT ? events.eventsById[promotion.terms.eventId] : null
                            //google analytics
                            const handleRemove = () => {
                                if (product){
                                    logAnalyticsEvent(ecommerceEvents.REMOVE_FROM_CART, product.analyticsItem)
                                }
                                actions.fetchLogDeviceSessionAction({
                                    action: "removeFromCart",
                                    productStockId: item.id, 
                                })
                                actions.fetchRemoveFromCart(item.id)
                            }
                            return <div key={item.id}  className={styles.productContainer}>
                                        <div className={styles.product}>
                                            <div>
                                                <img src={imageUrl} />
                                                {event ? <div className="saleEventFlag">{event.name} Deal</div> : null}
                                            </div>
                                            <div className={styles.itemDetails}> 
                                                <div className={styles.productTitle}>{product.titleAndBrand} <CharacteristicsList 
                                                    id={item.id}
                                                    characteristics={item.characteristics} 
                                                /></div>
                                                
                                                <span
                                                    className={['anchorLink', styles.remove].join(" ")}
                                                    onClick={handleRemove}
                                                >
                                                    Remove
                                                </span>
                                            </div>
                                        </div>
                                    </div>     
                        })
                    }
                </div>
                :
                null
            }
        </div>
    )
}

const mapStateToProps = state => ({
    cart: state.cart,
    products: state.products,
    promotions: state.promotions,
    events: state.events
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryItems)