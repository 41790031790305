export const PROMOTION_TYPE_SALE = 'SALE'
export const PROMOTION_TYPE_DISCOUNT_CODE = 'DISCOUNT_CODE'

export const DISCOUNT_TYPE_PERCENTAGE="PERCENT"
export const DISCOUNT_TYPE_FIXED_AMOUNT="FIXED_AMOUNT"
export const DISCOUNT_TYPE_BUY_X_GET_Y="BUY_X_GET_Y"

export const PROMOTION_APPLIES_TO_PRODUCTS_TYPE_ALL = 'ALL_PRODUCTS'
export const PROMOTION_APPLIES_TO_PRODUCTS_TYPE_SPECIFIC = 'SPECIFIC_PRODUCTS'

export const PROMOTION_CUSTOMER_ELIGIBILITY_TYPE_ALL = 'ALL_CUSTOMERS'
export const PROMOTION_CUSTOMER_ELIGIBILITY_TYPE_SPECIFIC = 'SPECIFIC_CUSTOMERS'

export const PROMOTER_TYPE_SELLER = 'SELLER'
export const PROMOTER_TYPE_SHOPDM = 'SHOPDM'

export const TIMING_TYPE_EVENT = 'EVENT'
export const TIMING_TYPE_DATES = 'DATES'
export const TIMING_TYPE_PERMANENT = 'PERMANENT'

export const PROMOTION_STATUS_CREATED = 'CREATED'
export const PROMOTION_STATUS_ONGOING = 'ONGOING'
export const PROMOTION_STATUS_PAUSED = 'PAUSED'
export const PROMOTION_STATUS_ENDED = 'ENDED'