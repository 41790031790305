import {firebaseApp} from "../config/firebase"
import { getFirestore, collection, query, getDocs, doc, setDoc, updateDoc } from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"
import {SETTINGS_IDENTIFIER} from "../constants/analysis"

export const SAVE_CATEGORIES = 'SAVE_CATEGORIES'
export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const UPDATE_FEATURED_CATEGORY_IDS = 'UPDATE_FEATURED_CATEGORY_IDS'
export const SAVE_CATEGORY_PAGE = 'SAVE_CATEGORY_PAGE'

export const saveCategories = categories => {
    return {
        type: SAVE_CATEGORIES,
        payload: {
            categories
        }
    }
}

export const createCategory = category => {
    return {
        type: CREATE_CATEGORY,
        payload: {
            category
        }
    }
}

export const updateFeaturedCategoryIds = featuredCategoryIds => {
    return {
        type: UPDATE_FEATURED_CATEGORY_IDS,
        payload: {
            featuredCategoryIds
        }
    }
}

export const saveCategoryPage = (categoryId, sortBy, pageNumber, productIds, firstDoc, lastDoc) => {
    return {
        type: SAVE_CATEGORY_PAGE,
        payload: {
            categoryId,
            sortBy,
            pageNumber,
            productIds,
            firstDoc,
            lastDoc
        }
    }
}
export const fetchSaveCategories = () => {
    const firestore = getFirestore(firebaseApp);
    const categoriesRef = query(collection(firestore, "categories"))
    return async dispatch => {
        try {
            const querySnapshot = await getDocs(categoriesRef)
            //get an array of categories from the snapshot
            const categories = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(saveCategories(categories))
            return true

        } catch (e){
            const message = `action > categories > fetchSaveCategories: Failed to save categories`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}
export const fetchCreateCategory = (id, label, onSuccess=()=>{}, onError=()=>{}) => {
    const firestore = getFirestore(firebaseApp);
    const categoriesRef = doc(firestore, "categories", id)
    const category = {
        id,
        label
    }
    return async (dispatch) => {
        try{
            await setDoc(categoriesRef, category)
            dispatch(createCategory(category))
            onSuccess(category)
            return true
        } catch (e){
            const message = `action > categories > fetchCreateCategory: Failed to create category ${JSON.stringify(category)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(category)
            return false
        }
        
    }
}

export const fetchUpdateFeaturedCategoryIds = (
    featuredCategoryIds,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const categoriesRef = doc(firestore, "categories", SETTINGS_IDENTIFIER)
    return async (dispatch) => {
        try{
            await updateDoc(categoriesRef, {
                featuredCategoryIds
            })
            dispatch(updateFeaturedCategoryIds(featuredCategoryIds))
            onSuccess(featuredCategoryIds)
            return true
        } catch (e){
            const message = `action > categories > fetchUpdateFeaturedCategoryIds: Failed to update featured categories ${JSON.stringify(featuredCategoryIds)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(featuredCategoryIds)
            return false
        }
    }
}