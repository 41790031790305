import React, { PureComponent } from "react"
import ProductTile from "../../containers/ProductTile"
import styles from "./PaginatedProductTileList.module.css"
import BackNextPagination from "../BackNextPagination"

class PaginatedProductTileList extends PureComponent{

    static defaultProps = {
        productList: [],
        maxItemsPerPage: 30,
        totalProductCount: 0,
        pageNumber: 0,
        onClickBack: () => {},
        onClickNext: () => {},
        paginate: false
    }

    render(){    
        const {
            productList, 
            onClick, 
            maxItemsPerPage, 
            totalProductCount, 
            pageNumber, 
            onClickBack, 
            onClickNext, 
            onChangePage
        } = this.props
        if (productList.length < 1) return ""
        //calculate the index of the last product on the last page 
        //by multiplying the items per page by the current page number  
        const startProductIndex = pageNumber * maxItemsPerPage

        return (
            <div className={styles.container}>
                {
                    totalProductCount > maxItemsPerPage ?
                        <div className={styles.showing}>Showing {startProductIndex + 1}-{startProductIndex + productList.length} of {totalProductCount}</div>
                    :
                        null
                }
                <div className={styles.innerContainer}>
                {
                    productList.map(
                        (product) => <ProductTile 
                                    key={product.id}
                                    onClick={onClick}
                                    productId={product.id}
                                    productPrice={product.price}
                                    productTitle={product.title}
                                    productBrand={product.brand}
                                    productUnits={product.units}
                                    starRating={product.starRating}
                                    imageUrl={product.imageUrl}
                                    createdBySellerId={product.createdBySellerId}
                                    characteristics={product.characteristics}
                                    stock={product.stock}
                                />
                    )
                }
                </div>
                <div className="padding-1em">
                {
                    totalProductCount > maxItemsPerPage ?
                    <BackNextPagination 
                        onClickBack={onClickBack}
                        onClickNext={onClickNext}
                        onChangePage={onChangePage}
                        disableBack={pageNumber === 0}
                        disableNext={(maxItemsPerPage * (pageNumber + 1) ) >= totalProductCount}
                    />
                    :
                    null
                }
                </div>
            </div>
        )
    }
}


export default PaginatedProductTileList;