import React from "react"
import styles from "./Blocked.module.css"
import logo from "../../images/logo300x300.png"
import supportConfig from "../../config/support"
import {capitalize} from "../../utils/stringUtils"
import {Redirect} from "react-router-dom"

import {connect} from "react-redux"
import {Helmet} from "react-helmet";

const Blocked = ({history, user}) => {
    const handleTerms = () =>  history.push("/terms-and-conditions")
    if (!user.authenticated || user.isActive) return <Redirect to="/"/>
    return (
        <div className={[styles.container, "screen"].join(" ")}>
            <Helmet>
                <title>Blocked</title>
            </Helmet>
            <p className="productTitle">Sorry but we've had to block you from using Shopdm, since you've violated the <span className={'anchorLink'} onClick={handleTerms}>Terms of Use</span></p>
            <img src={logo} alt="Shopdm Logo"/>
            <p>This is likely to be done if {capitalize(user.firstName)} {capitalize(user.lastName)} @ {user.email} is a fake account. If you think this was done in error, email us at <a href={`mailto:${supportConfig.maintenanceEmail}`}>{supportConfig.maintenanceEmail}</a></p>
        </div>
    )
}


const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(Blocked);