export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const CHANGE_ENVIRONMENT = 'CHANGE_ENVIRONMENT'
export const CHANGE_VERSION = 'CHANGE_VERSION'
export const TOGGLE_FEEDBACK_REQUESTED = 'TOGGLE_FEEDBACK_REQUESTED'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'

export const toggleLoading = (loading, loadingText="") => {
    return {
        type: TOGGLE_LOADING,
        payload: {
            loading,
            loadingText
        }
    }
}

export const showNotification = (title="", body="", data={}) => {
    return {
        type: SHOW_NOTIFICATION,
        payload: {
            title,
            body,
            data
        }
    }
}

export const hideNotification = () => {
    return {
        type: HIDE_NOTIFICATION
    }
}

export const setEnvironment = () => {
    return {
        type: CHANGE_ENVIRONMENT
    }
} 

export const setVersion = () => {
    return {
        type: CHANGE_VERSION
    }
} 

export const toggleFeedbackRequested = feedbackRequested => {
    return {
        type: TOGGLE_FEEDBACK_REQUESTED,
        payload: {
            feedbackRequested
        }
    }
}
