import React from "react"
import styles from "./FeedbackForm.module.css"
import CloseButton from "../../components/CloseButton";

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

class FeedbackForm extends React.Component {
    state = {
        show: false
    }

    static defaultProps = {
        delay: 10000,
        prompt: "Tell Us What You Want"
    }

    componentDidMount = () => {
        const {delay, system} = this.props
        setTimeout(() => {
            if (system.feedbackRequested) return
            this.handleToggleShow(true)
        }, delay)
    }

    handleToggleShow = show => {
        const {system, actions} = this.props
        if (!system.feedbackRequested && show) {
            actions.toggleFeedbackRequested(true)
        }
        this.setState({show})
    }

    render(){
        const {show} = this.state
        const {prompt} = this.props
        const showStyle = show ? styles.show : ""
        return (
            <div className={styles.container}>
                {
                <div className={styles.prompt} onClick={() => this.handleToggleShow(true)}>
                    {prompt}
                </div>
                }
                <div className={[styles.innerContainer, showStyle].join(" ")}>
                    <div className={styles.closeButtonContainer}>
                        <CloseButton onClick={() => this.handleToggleShow(false)}/>
                    </div>
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdvlVFcMxSKIac3DGpESHx4vqbBrVlcEVf0XPaU6ITt7WPNSg/viewform?embedded=true" width="400" height="250" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    system: state.system
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm)