import React from "react"
import styles from "./DropdownMenu.module.css"

import Icon from "../Icon"
class DropdownMenu extends React.PureComponent {
    state = {

    }

    static defaultProps = {
        buttonText: "Dropdown",
        options: []
    }

    render(){
        const {buttonText, options} = this.props
        return (
            <div className={styles.container}>
                <button className={styles.button}>{buttonText}<Icon icon="arrow-drop-down"/></button>
                <div className={styles.options}>
                    {
                        options.map(o => {
                            const {onClick=()=>{}, label} = o
                            return <a key={label} onClick={onClick}>{label}</a>
                        })
                    }
               </div>
            </div>
        )
    }
}

export default DropdownMenu