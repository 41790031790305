import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, setDoc, onSnapshot, query, where, collection } from "firebase/firestore";
import {getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage"
import { logError } from "../utils/errorHandlingUtils"
import { DAY_IN_MILLISECONDS } from "../constants/datetime";
import { PAYMENT_CLAIM_STATUS_CREATED, PAYMENT_CLAIM_STATUS_PAYMENT_VERIFIED, PAYMENT_CLAIM_STATUS_CANCELLED} from "../constants/paymentClaims"
import { PAYMENT_OBJECT_TYPE_ORDER } from "../constants/payment";

export const SAVE_PAYMENT_CLAIMS = 'SAVE_PAYMENT_CLAIMS'
export const CREATE_PAYMENT_CLAIM = 'CREATE_PAYMENT_CLAIM'

export const savePaymentClaims = paymentClaims => {
    return {
        type: SAVE_PAYMENT_CLAIMS,
        payload: {
            paymentClaims
        }
    }
}

export const createPaymentClaim = paymentClaim => {
    return {
        type: CREATE_PAYMENT_CLAIM,
        payload: {
            paymentClaim
        }
    }
}

export const fetchCreatePaymentClaim = (
    id,
    paymentProviderId,
    paymentProviderName,
    totalXcd,
    paymentProcessorFeeXcd,
    objectType,
    objectId,
    reference,
    currentStatus,
    customerId,
    customerName,
    customerPhone,
    customerEmail,
    imageFile=null,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp);
    const paymentClaimsRef = doc(firestore, "paymentClaims", id)
    const paymentClaim = {
        id,
        paymentProviderId,
        paymentProviderName,
        totalXcd: Number(totalXcd),
        paymentProcessorFeeXcd: Number(paymentProcessorFeeXcd),
        objectType,
        objectId,
        reference,
        currentStatus,
        customerId,
        customerName,
        customerPhone,
        customerEmail,
        imageUrl: "",
        createdAt: Date.now()
    }
    return async (dispatch, getState) => {
        try{
            const {user, device} = getState()
            paymentClaim.createdByUserId = user.id
            paymentClaim.deviceId = device.id
            //if an image is provided, upload it and save it's image url here
            //this will allow users to provide screenshots of NBD mobanking payments
            if (imageFile){ 
                const storage = getStorage(firebaseApp)
                const imageRef = ref(storage, `payment-claims/${id}/${Date.now()}`)
                await uploadBytes(imageRef, imageFile)
                //get the image url
                paymentClaim.imageUrl = await getDownloadURL(imageRef)
            } 
            await setDoc(paymentClaimsRef, paymentClaim)
            dispatch(createPaymentClaim(paymentClaim))
            onSuccess(paymentClaim)
            return id
        } catch (e){
            const message = `action > paymentClaims > fetchCreatePaymentClaim: Failed to create payment claim ${JSON.stringify(paymentClaim)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(paymentClaim)
            return false
        }
    }
}

export const fetchUpdatePaymentClaimStatus = (
    id,
    currentStatus,
    paymentProviderReference="",
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp);
    const paymentClaimsRef = doc(firestore, "paymentClaims", id)
    const editedAtType = (currentStatus === PAYMENT_CLAIM_STATUS_PAYMENT_VERIFIED) ?
                         'paymentVerifiedAt'
                         :
                         (currentStatus === PAYMENT_CLAIM_STATUS_CANCELLED) ?
                         'cancelledAt'
                         :
                         null
    const update = {
        currentStatus,
        paymentProviderReference
    }
    if (editedAtType) update[editedAtType] = Date.now()
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            update.lastEditedByUserId = user.id
            await setDoc(paymentClaimsRef, update, {merge: true})
            onSuccess(update)
            return true
        } catch (e){
            const message = `action > paymentClaims > fetchUpdatePaymentClaimStatus: Failed to update the status of payment claim ${id} to ${currentStatus}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(update)
            return false
        }
    }
}

export const fetchSaveAdminPaymentClaims = (
    fromDate = Date.now() - (30 * DAY_IN_MILLISECONDS)//defaults to listening to all payment claims made in the last 30 days
) => {
    
    const firestore = getFirestore(firebaseApp)
    const paymentClaimsRef = query(collection(firestore, "paymentClaims"),
                                     where("createdAt", ">=",  fromDate))
    return async dispatch => {
        try {
            const paymentClaimsListener = await onSnapshot(paymentClaimsRef,
                querySnapshot => {
                    //get an array of payment claims from the snapshot
                    const paymentClaims = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePaymentClaims(paymentClaims))
            })
            return paymentClaimsListener
        } catch (e){
            const message = `action > paymentClaims > fetchSaveAdminPaymentClaims: Failed to save admin payment claims`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveRecentlyClosedPaymentClaims = (
    fromDate = Date.now() - DAY_IN_MILLISECONDS//defaults to listening to all payment claims closed in the last day
) => {
    
    const firestore = getFirestore(firebaseApp)
    const paymentClaimsRef = query(collection(firestore, "paymentClaims"),
                                     where("closedAt", ">=",  fromDate))
    return async dispatch => {
        try {
            const paymentClaimsListener = await onSnapshot(paymentClaimsRef,
                querySnapshot => {
                    //get an array of payment claims from the snapshot
                    const paymentClaims = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePaymentClaims(paymentClaims))
            })
            return paymentClaimsListener
        } catch (e){
            const message = `action > paymentClaims > fetchSaveRecentlyClosedPaymentClaims: Failed to save recently closed payment claims`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveLivePaymentClaims = () => {
    const firestore = getFirestore(firebaseApp)
    const paymentClaimsRef = query(collection(firestore, "paymentClaims"),
                                     where("currentStatus", "==", PAYMENT_CLAIM_STATUS_CREATED)
                                     )
    return async dispatch => {
        try {
            const paymentClaimsListener = await onSnapshot(paymentClaimsRef,
                querySnapshot => {
                    //get an array of payment claims from the snapshot
                    const paymentClaims = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePaymentClaims(paymentClaims))
            })
            return paymentClaimsListener
        } catch (e){
            const message = `action > paymentClaims > fetchSaveLivePaymentClaims: Failed to save live order payment claims`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}