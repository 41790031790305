/**
 *  Purpose: the modifications that are carried on the Delivery Trip objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 import { timestampToWeekDay, getNumberOfDaysBetweenWeekDays, getTimestampForStartOfDay, getTimestampForDM24HrTime } from '../utils/datetimeUtils'
import { DAY_IN_MILLISECONDS } from '../constants/datetime'

 // STATE
 // deliveryTrips: {
 //     deliveryTripsById: {},
 //     deliveryTripIdsByAreaId: {},
 //     deliveryTripIds: [],
 // }
 
 
 const deliveryTripsReducer = (state = initialState.deliveryTrips, action) => {
     const {type, payload} = action;
     let deliveryTripsById = {...state.deliveryTripsById}
     let deliveryTripIdsByAreaId = {...state.deliveryTripIdsByAreaId}
     let deliveryTripIds = {...state.deliveryTripIds}
 
     switch (type){
         case types.SAVE_DELIVERY_AGENTS: {
             if(typeof payload.deliveryAgents !== "object") {
                 return state
             }
             const today = timestampToWeekDay(Date.now(), false)
             const startOfToday = getTimestampForStartOfDay(Date.now(), false)
             payload.deliveryAgents.forEach(deliveryAgent => {
                 //loop through each agent's scheduled trips
                 Object.values(deliveryAgent.scheduledTripsById).forEach(scheduledTrip => {
                    const deliveryTrip = getDeliveryTripFromScheduledTrip(
                        scheduledTrip,
                        deliveryAgent,
                        today,
                        startOfToday,
                    )
                    //index the trips by their ids
                    deliveryTripsById[deliveryTrip.id] = deliveryTrip
                    //index the trips by the areas the agent usually delives to
                    deliveryTripIdsByAreaId = addAreaIndexesForTrip(
                        deliveryTrip.id,
                        deliveryTrip.excludedAreasById,
                        deliveryAgent.deliveryAreaIdList,
                        deliveryTripIdsByAreaId
                    )
                 })
                 
                 
             })
             return {
                 ...state,
                 deliveryTripsById,
                 deliveryTripIdsByAreaId,
                 deliveryTripIds: Object.keys(deliveryTripsById)
             }
         }

        case types.UPDATE_DELIVERY_AGENT_DELIVERY_AREAS: {
            if(typeof payload.deliveryAgentId !== "string") {
                return state
            }
            if(!Array.isArray(payload.deliveryAreaIdList)) {
                return state
            }
            if(!Array.isArray(payload.previousDeliveryAreaIdList)) {
                return state
            }
            if(typeof payload.scheduledTripsById !== "object") {
                return state
            }
            Object.values(payload.scheduledTripsById).forEach(
                deliveryTrip => {
                    deliveryTripIdsByAreaId = removeAreaIndexesForTrip(
                        deliveryTrip.id, 
                        payload.previousDeliveryAreaIdList, 
                        deliveryTripIdsByAreaId
                    )
                    //index the trips by the areas the agent usually delives to
                    deliveryTripIdsByAreaId = addAreaIndexesForTrip(
                        deliveryTrip.id,
                        deliveryTrip.excludedAreasById,
                        payload.deliveryAreaIdList,
                        deliveryTripIdsByAreaId
                    )
                }
            )
            return {
                ...state,
                deliveryTripsById,
                deliveryTripIdsByAreaId,
                deliveryTripIds: Object.keys(deliveryTripsById)
            }
        }

        case types.UPDATE_DELIVERY_AGENT_SCHEDULED_TRIPS: {
            if(typeof payload.deliveryAgentId !== "string") {
                return state
            }
            if(typeof payload.scheduledTripsById !== "object") {
                return state
            }
            if(typeof payload.previousScheduledTripsById !== "object") {
                return state
            }
            if(typeof payload.deliveryAgent !== "object") {
                return state
            }
            //delete the previous trips for this agent and remove their area to trip indexes
            Object.values(payload.previousScheduledTripsById).forEach(
                deliveryTrip => {
                    deliveryTripIdsByAreaId = removeAreaIndexesForTrip(
                        deliveryTrip.id, 
                        payload.deliveryAreaIdList, 
                        deliveryTripIdsByAreaId
                    )
                    delete deliveryTripsById[deliveryTrip.id]
                }
            )
            //add new copies of the trip object and index all new trips by area
            const today = timestampToWeekDay(Date.now(), false)
            const startOfToday = getTimestampForStartOfDay(Date.now(), false)
            Object.values(payload.scheduledTripsById).forEach(
                scheduledTrip => {
                    const deliveryTrip = getDeliveryTripFromScheduledTrip(
                        scheduledTrip,
                        payload.deliveryAgent,
                        today,
                        startOfToday,
                    )
                    deliveryTripIdsByAreaId = addAreaIndexesForTrip(
                        deliveryTrip.id,
                        deliveryTrip.excludedAreasById,
                        payload.deliveryAreaIdList,
                        deliveryTripIdsByAreaId
                    )
                    deliveryTripsById[deliveryTrip.id] = deliveryTrip
                }
            )
            return{
                ...state,
                deliveryTripsById,
                deliveryTripIdsByAreaId,
                deliveryTripIds: Object.keys(deliveryTripsById)
            }
        }

         default: return state
     }
 }
 
 const getDeliveryTripFromScheduledTrip = (
    scheduledTrip,
    deliveryAgent,
    today,
    startOfToday,
 ) => {
     //delivery trips have actual timestamps, whereas scheduled trips have week days and times of day
     const {dayOfWeek, arriveAtTime, departAtTime, pickupAtTime} = scheduledTrip
     const dayDifference = getNumberOfDaysBetweenWeekDays(today, dayOfWeek)
     const startOfDay = startOfToday + (dayDifference * DAY_IN_MILLISECONDS) // 0 if sameday
     const [arriveAtHours, arriveAtMinutes] = arriveAtTime.split(":") //in the form 18:00
     const [departAtHours, departAtMinutes] = departAtTime.split(":")
     const deliveryTrip = {
         ...scheduledTrip,
         startOfDay,
         arriveAt: getTimestampForDM24HrTime(arriveAtHours, arriveAtMinutes, startOfDay),
         departAt: getTimestampForDM24HrTime(departAtHours, departAtMinutes, startOfDay),
         deliveryProviderId: deliveryAgent.deliveryProviderId,
         deliveryEntityName: deliveryAgent.nickName,
         isActive: deliveryAgent.isActive
     }
     if (deliveryTrip.pickupAtTime){
         const [pickupAtHours, pickupAtMinutes] = pickupAtTime.split(":")
         deliveryTrip.pickupAt = getTimestampForDM24HrTime(pickupAtHours, pickupAtMinutes, startOfDay)
     }
     return deliveryTrip
 }
 const removeAreaIndexesForTrip = (
    deliveryTripId, 
    deliveryAreaIdList=[], 
    deliveryTripIdsByAreaId={}
) => {
    //remove the indexing from a delivery trip to a delivery areas
    deliveryAreaIdList.forEach(areaId => {
        if (
            deliveryTripIdsByAreaId[areaId] && //if any trips go to this area
            deliveryTripIdsByAreaId[areaId][deliveryTripId] //and this specific trip goes to this area
        ) {
            //remove this trip from this area
            deliveryTripIdsByAreaId = {
                ...deliveryTripIdsByAreaId,
                [areaId]: {
                    ...deliveryTripIdsByAreaId[areaId]
                }
            }
            delete deliveryTripIdsByAreaId[areaId][deliveryTripId]
            //if there are no more trips for this area, remove the area
            if (Object.keys(deliveryTripIdsByAreaId[areaId]).length === 0) delete deliveryTripIdsByAreaId[areaId]
        }
    })
    return deliveryTripIdsByAreaId
}

const addAreaIndexesForTrip = (
    deliveryTripId, 
    excludedAreasById={},
    deliveryAreaIdList=[], 
    deliveryTripIdsByAreaId={}
) => {
    //index a trip to its delivery areas so we know who delivers where
    deliveryAreaIdList.forEach(areaId => {
        //if the agent has explicitly excluded this area on this trip, then ignore it
        if (excludedAreasById[areaId]) return
        //otherwise, index this trip by the areas the agent usually serves
        if (!deliveryTripIdsByAreaId[areaId]) deliveryTripIdsByAreaId[areaId] = {}
        else deliveryTripIdsByAreaId[areaId] = {...deliveryTripIdsByAreaId[areaId]}
        deliveryTripIdsByAreaId[areaId][deliveryTripId] = true
    })
    return deliveryTripIdsByAreaId
}

 export default deliveryTripsReducer