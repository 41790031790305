import React from "react"
import Modal from "../../components/Modal"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import { capitalize } from "../../utils/stringUtils";
import styles from "./JadPaymentModal.module.css"
import {config} from "../../config/card"
import {baseUrl} from "../../config/firebase"

class JadPaymentModal extends React.Component{
    /**
     * Purpose: carry out the steps required to process a JAD card payment
     *  
     * 1: populate the form with the basic values necessary for JAD to collect a card payment from the buyer
     * 2: submit the form to _blank programmatically, which opens the populated JAD screen in a new tab
     */
    constructor(props){
        super(props)
        this.submit = React.createRef()
        this.submitInterval = null
    }

    componentDidMount = async () => {
        //the React refs are often not loaded at this stage,
        //the code below retries calling handleSubmit every half second, 
        //after checking whether the ref is loaded
        
        const submitIfRefLoaded = () => {if (this.submit.current) this.handleSubmit()}
        if (this.submit.current) this.handleSubmit() 
        else this.submitInterval = setInterval(submitIfRefLoaded, 500)
    }

    handleSubmit = () => {
        //load the credit ard form by submitting the loading form programmatically by pressing the submit button

        //clear the interval if it exists
        if (this.submitInterval){
            clearInterval(this.submitInterval)
            this.submitInterval = null
        }
        this.submit.current.click()
    }

    componentWillUnmount(){
        if (this.submitInterval) {
            clearInterval(this.submitInterval)
            this.submitInterval = null
        }
    }

    render (){
        const {
            amount2DecimalPlaces, 
            amountLessPaymentCharge2DecimalPlaces,
            reference, 
            objectId, 
            objectType, 
            paymentProviderId, 
            isOpen, 
            closeModal, 
            userId,
        } = this.props
        //we can only pass one field to JAD so we build a field containing
        //reference num, objectId, paymentId and amount ussing delimiters
        const custom = `${objectType}+${reference}+${objectId}+${paymentProviderId}+${amount2DecimalPlaces}+${amountLessPaymentCharge2DecimalPlaces}+${userId}+${config.paymentId}`
        this.custom = custom
        return (
                <Modal
                    isOpen={isOpen}
                    closeModal={closeModal}
                    className={styles.modal}
                    overlayClassName={styles.modalOverlay}
                >   
                    <div className={styles.innerModal}>
                        <div className={styles.payWith}>Pay with<img alt="Visa/Mastercard" src="https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2FpaymentProviderLogos%2Fvisamastercard%2Fvisa-mastercard.png?alt=media&token=1e23e638-a933-4ab7-af36-1459395113ed"/> via <img className="providerLogo" src="https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2FpaymentProviderLogos%2Fjad%2Fjad.png?alt=media&token=c63d1b75-4ad1-4a2b-866e-b35b9b25dbb2"/>JAD</div>
                        <div>Visa/Mastercard processing via JAD is going to open a new, secure payment window. Don't see it? Click here to re-launch and complete your purchase</div>
                        <div>
                            <form 
                                action={config.formAction} 
                                method='post' 
                                target="_blank"
                            >
                                <input type='hidden' id="paymentID" name='paymentID' value={config.paymentId} />
                                <input type="hidden" id="subTotalAmount" name="subTotalAmount" value={amount2DecimalPlaces} />
                                <input type="hidden" id="discountAmount" name="discountAmount" value="0" />
                                <input type="hidden" id="tipAmount" name="tipAmount" value="0" />
                                <input type="hidden" id="itemCode" name="itemCode" value="" />
                                <input type="hidden" id="itemName" name="itemName" value={`${capitalize(objectType)} No. ${reference}`} />
                                <input type="hidden" id="custom" name="custom" value={custom} />
                                <button id="pay" ref={this.submit} name='SUBMIT' type='submit' className={styles.clickToContinue} alt="Load Payment Form">Click To Continue</button>
                            </form>
                        </div>
                    </div>
                </Modal>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(JadPaymentModal);