import React from "react"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import WalletCard from "../../components/WalletCard"
import Modal from "../../components/Modal"
import IconButton from "../../components/IconButton";
import CreditTransactionList from "../../components/CreditTransactionList"
import CalculationBreakdown from "../../components/CalculationBreakdown";
import FormTextSeparator from "../../components/FormTextSeparator";
import RadioList from "../../components/RadioList";
import {ORIENTATION_HORIZONTAL} from "../../constants/interface"
import styles from "./CheckoutCredit.module.css"
import {CREDIT_TRANSACTION_ROLE_RECIPIENT, CREDIT_TRANSACTION_ROLE_SENDER, CREDIT_STATUS_PROCESSED} from "../../constants/credit"
import {logAnalyticsEvent} from "../../config/firebase"
class CheckoutCredit extends React.Component {

    state = {
        modalStyle: "",
        transactionModalOpen: false,
        recipientTransactionsListener: () => {},
        senderTransactionsListener: () => {}
    }
    
    static defaultProps = {
        shopdmCreditOwed: 0,
    }

    togglePayWithShopdmCredit = (payWithShopdmCredit) => {
        const {actions, creditWallets, user, providerFees} = this.props
        //toggle in the cart
        if (payWithShopdmCredit) {
            const wallet = creditWallets.creditWalletsById[user.defaultWalletId]
            if (!wallet || wallet.balance === 0) return
            const clearPaymentProvider = Boolean(wallet.balance >= providerFees)
            logAnalyticsEvent("select_pay_with_shopdm_credit")
            actions.fetchLogDeviceSessionAction({
                action: "selectPayWithShopdmCredit",
                walletId: user.defaultWalletId
            })
            actions.selectPayWithShopdmCredit(clearPaymentProvider)
        }
        else {
            logAnalyticsEvent("deselect_pay_with_shopdm_credit")
            actions.fetchLogDeviceSessionAction({
                action: "deselectPayWithShopdmCredit",
                walletId: user.defaultWalletId
            })
            actions.deselectPayWithShopdmCredit()
        }
    }

    handleOpenTransactionModal = async () => {
        const {user, actions} = this.props
        this.setState({
            transactionModalOpen: true, modalStyle: "menu"
        }, () => setTimeout(() => this.setState({
            modalStyle: "menu slide"
        }), 50))
        actions.toggleLoading(true)
        const recipientTransactionsListener = await actions.fetchSubscribeToWalletCreditTransactions(user.defaultWalletId, CREDIT_TRANSACTION_ROLE_RECIPIENT)
        const senderTransactionsListener = await actions.fetchSubscribeToWalletCreditTransactions(user.defaultWalletId, CREDIT_TRANSACTION_ROLE_SENDER)
        actions.toggleLoading(false)
        this.setState({
            recipientTransactionsListener,
            senderTransactionsListener
        }, () => {
            logAnalyticsEvent("open_credit_transaction_history_at_checkout")
            actions.fetchLogDeviceSessionAction({
                action: "openCreditTransactionHistoryAtCheckout",
                walletId: user.defaultWalletId
            })
        })
    }
    
    handleCloseTransactionModal = () => {
        const {
            recipientTransactionsListener,
            senderTransactionsListener
        } = this.state
        recipientTransactionsListener()
        senderTransactionsListener()
        this.setState({transactionModalOpen: false, modalStyle: ""})
    }

    componentWillUnmount(){
        this.state.recipientTransactionsListener()
        this.state.senderTransactionsListener()
    }

    render(){
        const {
            shopdmCreditOwed,
            total,
            totalIfCreditUsed,
            providerFees,
            user,
            creditWallets,
            creditTransactions,
            cart
        } = this.props
        const {transactionModalOpen, modalStyle} = this.state
        const wallet = creditWallets.creditWalletsById[user.defaultWalletId]
        if (!wallet || wallet.balance === 0) return ""
        const outstandingMessage = wallet.balance < 0 ?
                                    `We've added your outstanding Shopdm Credit balance of EC $${Number(shopdmCreditOwed).toFixed(2)} to your total`
                                    :
                                    ""
        const paymentPrompt = wallet.balance >= providerFees ? //if there is enough credit available to cover the whole purchase
                        `Pay only EC $${Number(providerFees).toFixed(2)} by using your Shopdm Credit? (no processing fee)`
                        :
                        wallet.balance > 0 ? 
                        `Remove EC $${Number(wallet.balance).toFixed(2)} from your total by using your Shopdm Credit? \n(also reduces the processing fee)`
                        :
                        ""
        let lineItems = []
        let resultLines = []
        let totalToPay = 0
        let remainingCredit = 0
        let separatorText = ""
        if (wallet.balance >= providerFees){
            //if the wallet balance is enough to cover the whole purchase
            const creditToApply = providerFees
            totalToPay = providerFees - creditToApply
            remainingCredit = wallet.balance - creditToApply
            lineItems = [
                {label: "Total Without Processing Fee", value: providerFees},
                {label: "Shopdm Credit Used", value: creditToApply, operation: "-", bold: true}
            ]
            resultLines = [
                {label: "Total Remaining To Pay", value: totalToPay, bold: true},
            ]
            separatorText = cart.payWithShopdmCredit ? "" :"or"
        } else if (wallet.balance >= 0){
            //if the wallet balance is positive
            const creditToApply = wallet.balance
            totalToPay = totalIfCreditUsed - creditToApply
            remainingCredit = wallet.balance - creditToApply
            lineItems = [
                {label: "Total With Reduced Processing Fee", value: totalIfCreditUsed},
                {label: "Shopdm Credit Used", value: creditToApply, operation: "-", bold: true}
            ]
            resultLines = [
                {label: "Total Remaining To Pay", value: totalToPay, bold: true},
            ]
            separatorText = cart.payWithShopdmCredit ? "and" :"or"
        }
        else {
            totalToPay = total
            lineItems = [
                {label: "Current Order Total", value: total - shopdmCreditOwed},
                {label: "Shopdm Credit Owed", value: shopdmCreditOwed, operation: "+", bold: true}
            ]
            resultLines = [
                {label: "Total To Pay", value: totalToPay, bold: true},
                {label: "New Credit Balance", value: remainingCredit}
            ]
            separatorText = ""
        }
        const transactions = transactionModalOpen ?
                            Object.values(creditTransactions.creditTransactionsById)
                                  .filter(t => Boolean(
                                                t.senderWalletId === user.defaultWalletId ||
                                                t.recipientWalletId === user.defaultWalletId
                                                )
                                                && t.currentStatus === CREDIT_STATUS_PROCESSED
                                 ).sort((tA, tB) => tB.createdAt - tA.createdAt)
                            : []
        return (
            <div className={styles.container}>
                <div className={styles.innerContainer}>
                    <div>
                        <WalletCard 
                            name={wallet.name}
                            balance={
                                wallet.balance >= 0 && cart.payWithShopdmCredit ?
                                remainingCredit 
                                : 
                                wallet.balance
                            }
                            balanceText={
                                wallet.balance >= 0 && cart.payWithShopdmCredit ?
                                "New Balance"
                                : 
                                "Balance"
                            }
                            selected={cart.payWithShopdmCredit}

                        >
                            {
                                cart.payWithShopdmCredit ?
                            <div className={styles.breakdownContainer}>
                                <CalculationBreakdown 
                                    lineItems={lineItems}
                                    resultLines={resultLines}
                                />
                            </div>
                            :
                            <div className={`title ${styles.title}`}>Your Shopdm Credit</div>
                            }
                        </WalletCard>
                        <div className="actionMenu horizontal">
                            <IconButton 
                                icon="history"
                                label="Transaction History"
                                onPress={this.handleOpenTransactionModal}
                                backgroundColor="var(--primary)"
                                labelColor="var(--dark-primary)"
                            />
                        </div>
                    </div>
                </div>
                {
                    transactionModalOpen ?
                    <Modal
                        isOpen={Boolean(transactionModalOpen)}
                        closeModal={this.handleCloseTransactionModal}
                        closeOnOverlayClick={true}
                        className={modalStyle}
                        overlayClassName="menu"
                    >
                        <CreditTransactionList 
                            transactions={transactions}
                            walletId={wallet.id}
                        />
                    </Modal>
                    :
                    null
                }
                <div >
                {
                    outstandingMessage ? 
                        <div className={styles.ask}>{outstandingMessage}</div>
                    :

                    wallet.balance > 0 //if there is a wallet with a balance over 0 
                    ?
                        <div className={styles.ask}>
                            <div>{paymentPrompt}</div>
                            <RadioList 
                                dataArray={[
                                    {id: "yes", name: "Yes"}, 
                                    {id: "no", name: "No"}
                                ]}
                                onChange={() => this.togglePayWithShopdmCredit(!cart.payWithShopdmCredit)}
                                selected={cart.payWithShopdmCredit ? "yes" : "no"}
                                orientation={ORIENTATION_HORIZONTAL}
                            />
                        </div>
                    :
                    null
                }
                </div>
                
                {separatorText ? <FormTextSeparator text={separatorText}/> : ""}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    creditWallets: state.creditWallets,
    creditTransactions: state.creditTransactions,
    cart: state.cart
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCredit);