import React from "react"
import {COMPLETED, SHOPDM_CANCELLED, CUSTOMER_CANCELLED} from "../../constants/order"

const OrderStatusBadge = ({currentOrderStatus}) => {
    let label = ""
    let statusStyle = ""
    if (currentOrderStatus === COMPLETED){
        label = "completed"
        statusStyle = "primary"
    } else if (currentOrderStatus === SHOPDM_CANCELLED || currentOrderStatus === CUSTOMER_CANCELLED){
        label = "cancelled"
        statusStyle = "error"
    } else {
        label = "in progress"
        statusStyle = "warning"
    }
    return <span className={`badge ${statusStyle}`}>{label}</span>
}

export default OrderStatusBadge