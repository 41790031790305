import React from "react"
import styles from "./CalculationBreakdown.module.css"
import LineItem from "../LineItem"

const CalculationBreakdown = ({
    lineItems=[],
    resultLines=[]
}) => {
    return (
        <div className={styles.container}>
            <div className="calculationSection">
                {
                    lineItems.map(lineItem => {
                        return <LineItem 
                                    key={lineItem.label}
                                    label={lineItem.label}
                                    value={lineItem.value}
                                    operation={lineItem.operation}
                                    color={lineItem.color}
                                    bold={lineItem.bold}
                                />
                    })
                }
            </div>
            {
                resultLines.map(lineItem => {
                    return <LineItem 
                                key={lineItem.label}
                                label={lineItem.label}
                                value={lineItem.value}
                                operation={lineItem.operation}
                                color={lineItem.color}
                                bold={lineItem.bold}
                            />
                })
            }
        </div>
    )
}

export default CalculationBreakdown