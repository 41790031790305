import React from "react"
import AvatarIcon from "../../components/AvatarIcon"
import UpdateImageModal from "../../components/UpdateImageModal";
import Icon from "../../components/Icon"
import IconBadge from "../../components/IconBadge"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {AVATAR_SIZE_BIG, AVATAR_SIZE_MEDIUM} from "../../constants/interface"

import {compressImageFile} from "../../utils/fileUtils"
import {logAnalyticsEvent} from "../../config/firebase"
class SellerLogo extends React.PureComponent{

    constructor(props){
        super(props)
        const {sellers, sellerId} = props
        const seller = sellers.sellersById[sellerId]
        this.state = {
            localImageUrl: seller.logoImageUrl,
            locaImageFile: null,
            imageFile: null,
            imageFileMed: null,
            imageFileSmall: null,
            modalOpen: false,
            avatarSize: this.getAvatarSize()
        }
    }

    static defaultProps = {
        editable: true
    }
    getAvatarSize = () => {
        if (this.props.avatarSize) return this.props.avatarSize
        else return window.innerWidth >= 700 ? AVATAR_SIZE_BIG : AVATAR_SIZE_MEDIUM
    } 
    setAvatarSize = () => {
        const avatarSize = this.getAvatarSize()
        if (avatarSize === this.state.avatarSize) return
        this.setState({avatarSize})
    }

    componentDidMount = () => {
        window.addEventListener("resize", this.setAvatarSize)
    }
    componentWillUnmount = () => {
        window.removeEventListener("resize", this.setAvatarSize)
    }

    componentDidUpdate(prevProps){
        const {sellerId, sellers} = this.props
        if (sellerId !== prevProps.sellerId){
            //if the seller has changed
            const seller = sellers.sellersById[sellerId]
            this.setState({
                localImageUrl: seller.logoImageUrl,
                localImageFile: null,
                imageFile: null,
                imageFileMed: null,
                imageFileSmall: null,
                modalOpen: false
            })
        }
    }

    handleOpenModal = () => this.setState({modalOpen: true})
    handleCloseModal = () => this.setState({modalOpen: false})

    handleSelectImage = (localImageUrl, localImageFile) => {
        this.setState({
            localImageUrl,
            localImageFile,
        })
    }

    handleCropImage = async (imageFile) => {
        const imageFileMed = await compressImageFile(imageFile, 80)
        const imageFileSmall = await compressImageFile(imageFile, 36)
        this.setState({
            imageFile,
            imageFileMed,
            imageFileSmall
        })
    }

    handleSaveImage = async () => {
        const {actions, sellerId} = this.props
        const {imageFile, imageFileMed, imageFileSmall} = this.state
        actions.toggleLoading(true)
        await actions.fetchSetSellerLogo(sellerId, imageFile, imageFileMed, imageFileSmall)
        actions.toggleLoading(false)
        this.handleCloseModal()
        logAnalyticsEvent("seller_update_logo", {sellerId})
        actions.fetchLogDeviceSessionAction({
            action: "sellerUpdateLogo",
            sellerId
        })
    }

    handleDeleteImage = async () => {
        const {actions, sellerId} = this.props
        if (!window.confirm("Are you sure you want to delete your logo?")) return
        actions.toggleLoading(true)
        await actions.fetchDeleteSellerLogo(sellerId)
        actions.toggleLoading(false)
        this.setState({localImageUrl: "", localImageFile: null, imageFile: null, imageFileMed: null, imageFileSmall: null})
    }

    render(){
        const {sellers, sellerId, user, editable} = this.props
        const {avatarSize} = this.state
        const seller = sellers.sellersById[sellerId]
        if (!seller) return ""
        let showOnHover
        let bottomRight
        let onClick
        if (seller.roles[user.id] && editable){
            showOnHover = () => <Icon icon="photo-camera"/>
            bottomRight=() => <IconBadge icon="photo-camera"/>
            onClick = this.handleOpenModal
        }

        const {localImageUrl, modalOpen, localImageFile} = this.state
        return (
            <React.Fragment>
                <AvatarIcon 
                    name={seller.name} 
                    imageUrl={avatarSize === AVATAR_SIZE_BIG ? seller.logoImageUrl : seller.logoImageUrlMed} 
                    size={avatarSize} 
                    onClick={onClick}
                    ShowOnHover={showOnHover}
                    BottomRight={bottomRight}
                />
                <UpdateImageModal 
                    title="update logo"
                    imageUrl={seller.logoImageUrl}
                    onDelete={this.handleDeleteImage} 
                    onSave={this.handleSaveImage}
                    onSelect={this.handleSelectImage} 
                    onCrop={this.handleCropImage}
                    onCloseModal={this.handleCloseModal} 
                    modalOpen={modalOpen}
                    localImageUrl={localImageUrl}
                    imageFile={localImageFile}
                    compressImage={false}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    sellers: state.sellers,
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SellerLogo)