import React from "react"
import TopBar from "../../components/TopBar"
import Footer from "../../containers/Footer"
import LoginForm from "../../containers/LoginForm"

import {Helmet} from "react-helmet";

function Login({history}){
    const query = new URLSearchParams(history.location.search)
    let redirectFrom = query.get("redirect-from")
    redirectFrom = redirectFrom ? redirectFrom : "/"
    return (
        <div className={"screen"}>
            <Helmet>
                <title>Shopdm Login</title>
            </Helmet>
            <TopBar title="Login"/>
            <div className={["topBarPresent", "mainContent", "authScreen", "phoneFormContainer", "showcase"].join(" ")}>
                <LoginForm redirectTo={redirectFrom} />
            </div>
            <Footer />
        </div>
    )
}

export default Login;