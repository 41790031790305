import {logError} from "./errorHandlingUtils"

export const cleanSearchTerms = (word, removeAllWhitespace = false)  => {
    /*remove:
        - punctuation 
        - connecting words (and/or)
        - multiple spaces and replace with one space
        - set everything to lowercase
    */
    if (! (typeof word === 'string')) {
        logError(new Error(`cleanSearchTerms: word ${JSON.stringify(word)} is not a string`))
        return word
    }
    const whitespaceReplacement = removeAllWhitespace ? "" : " "
    return word.toLowerCase().replace(/\'+/g, '') //remove possesion apostrophes entirely
                             .replace(/\.+|\'+|\"+|\(+|\)+|\&+|\-+|\/+|\\+/g, ' ') //remove punctuation
                             .replace(/ and +| in +| or +/g, ' ') //remove connecting words
                             .replace(/^the | ltd$| inc$| enterprises?$| plus$/g, '') //remove company identifiers from the ends of words/lines 
                             .trim()
                             .replace(/\s\s+/g, whitespaceReplacement) //collapse multiple spaces into 
}