import React from 'react'
import styles from './ProductCharOptionPicker.module.css'
import { alphabeticalSort } from "../../utils/stringUtils"
import { objectsAreEqual } from "../../utils/generalUtils";

class ProductCharOptionPicker extends React.PureComponent {

    constructor(props){
        super(props)
        //order options alphabetically
        const options = [...props.options].sort((oA, oB) => alphabeticalSort(oA.text, oB.text))
        this.state = {
            options
        }
        this.refList = options.map(o => React.createRef())
        this.list = React.createRef()
    }

    componentDidMount() {
        const {selected} = this.props
        const {options} = this.state
        const selectedIndex = options.findIndex(o => o.id === selected)
        if (
            selectedIndex !== -1 && 
            this.refList[selectedIndex] &&
            this.refList[selectedIndex].current &&
            this.list &&
            this.list.current
        ) {
            this.list.current.scrollLeft = this.refList[selectedIndex].current.offsetLeft
        }
    }

    componentDidUpdate(prevProps){
        if (!objectsAreEqual(prevProps.options, this.props.options)){
            this.setState({
                options: [...this.props.options].sort((oA, oB) => alphabeticalSort(oA.text, oB.text))
            })
        }
    }
    
    static defaultProps = {
        options: [],
        onSelect: () => {},
        attribute: "",
        selected: ""
    }
    render(){
        const { onSelect, attribute, selected} = this.props
        const {options} = this.state
        return (
        <div className={styles.container}>
            <div className={styles.optionList} ref={this.list}>
                {
                    options.map((o, i) => {
                        const selectedStyle = o.id === selected? styles.selected : ""
                        return (
                            <div
                                key={o.id}
                                ref={this.refList[i]}
                                className={`${styles.option} ${selectedStyle}`} 
                                onClick={() => onSelect(o.id, attribute)}
                            >
                                {o.text}
                            </div>
                        )
                    })
                }
            </div>
        </div>
        )
    }
}

export default ProductCharOptionPicker