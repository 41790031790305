import React from "react"
import RadioButton from "../RadioButton"
import styles from "./PaymentMethodOption.module.css"

const PaymentMethodOption = ({logo, name, onSelect, checked, isValid=true, invalidText="Not Available"}) => {
    const validityStyle = isValid ? "" : 'unavailable'
    const selectedStyle = checked ? 'selected' : ""
    return (
        <div className={['checkoutOption', selectedStyle, validityStyle, styles.container].join(" ")}>
            <RadioButton
                onChange={onSelect}
                checked={checked}
                editable={isValid}
            >
                <div>
                    {logo ? <img className={['providerLogo', styles.providerLogo].join(" ")} src={logo} alt={name ? name : ""}/> : null}
                    {name ? <div>{name}</div> : null}
                </div>
            </RadioButton>
            {isValid ? null :<span className={'errorText'}>{invalidText}</span>}
        </div>
    )

}

export default PaymentMethodOption