import React from "react";
import WalletCard from "../../components/WalletCard"
import IconButton from "../../components/IconButton"

import TopupCreditModal from "../TopupCreditModal";

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {logAnalyticsEvent} from "../../config/firebase"
class YourWalletCard extends React.Component {
    state = {
        topupModalOpen: false
    }

    handleOpenModal = key => {
        const {actions, walletId} = this.props
        if (key === "topupModalOpen"){
            logAnalyticsEvent('open_topup_modal')
            actions.fetchLogDeviceSessionAction({
                action: "openTopupModal", 
                walletId
            })
        }
        this.setState({[key]: true})
    }
    handleCloseModal = key => this.setState({[key]: false})

    render () {
        const {walletId, creditWallets} = this.props
        const {topupModalOpen} = this.state
        const wallet = creditWallets.creditWalletsById[walletId] 
        if (!wallet) return ""
        return (
            <div>
                <WalletCard 
                    name={wallet.name}
                    balance={wallet.balance}
                />
                <div className="actionMenu horizontal">
                    <IconButton 
                        icon="add"
                        label="Top up"
                        onPress={() => this.handleOpenModal("topupModalOpen")}
                        backgroundColor="var(--primary)"
                        labelColor="var(--dark-primary)"
                    />
                    {/* <IconButton 
                        icon="receipt"
                        label="Pay Invoices"
                        onPress={() => alert("bam")}
                        backgroundColor="var(--primary)"
                        labelColor="var(--dark-primary)"
                    /> */}
                </div>
                {
                    topupModalOpen ?
                    <TopupCreditModal 
                        isOpen={topupModalOpen}
                        walletId={walletId}
                        walletName={wallet.name}
                        closeModal={() => this.handleCloseModal("topupModalOpen")}
                    />
                    :
                    null
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    creditWallets: state.creditWallets
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(YourWalletCard)