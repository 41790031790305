import {baseUrl, firebaseApp} from "../config/firebase"
import {getFirestore, query, collection, where, getDoc, getDocs, onSnapshot, doc, runTransaction, updateDoc, setDoc, increment, writeBatch} from "firebase/firestore"
import { logError } from "../utils/errorHandlingUtils"
import {v4 as uuid4} from 'uuid'
import {CUSTOMER_CONFIRMED_PAYMENT, CUSTOMER_SELECTED_PAY_INSTORE, SHOPDM_CONFIRMED_PAYMENT} from '../constants/order'
import {STATISTICS_IDENTIFIER} from '../constants/analysis'
import {THIRD_PARTY_DELIVERY} from "../constants/delivery"
import { DAY_IN_MILLISECONDS } from "../constants/datetime";
import currency from "currency.js"

export const CREATE_ORDER = 'CREATE_ORDER'
export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS'
export const UPDATE_SELLER_ORDER_STATUS = 'UPDATE_SELLER_ORDER_STATUS'
export const UPDATE_SELLER_ORDER_PACKAGE_INFO = 'UPDATE_SELLER_ORDER_PACKAGE_INFO'
export const SAVE_ORDERS = 'SAVE_ORDERS'
export const RECORD_ACTUAL_ORDER_ITEM_TOTAL = 'RECORD_ACTUAL_ORDER_ITEM_TOTAL'
export const createOrder = (order) => {
    return {
        type: CREATE_ORDER,
        payload: {
            order
        }
    }
}

export const saveOrders = orders => {
    return {
        type: SAVE_ORDERS,
        payload: {
            orders
        }
    }
}

export const updateOrderStatus = (orderId, statusUpdate) => {
    return {
        type: UPDATE_ORDER_STATUS,
        payload: {
            orderId,
            statusUpdate
        }
    }
}

export const updateSellerOrderStatus = (orderId, sellerId, statusUpdate, statusHistory) => {
    return {
        type: UPDATE_SELLER_ORDER_STATUS,
        payload: {
            orderId,
            sellerId,
            statusUpdate,
            statusHistory
        }
    }
}

export const updateSellerPackageInfo = (orderId, sellerId, packageInfoUpdate) => {
    return {
        type: UPDATE_SELLER_ORDER_PACKAGE_INFO,
        payload: {
            orderId,
            sellerId,
            packageInfoUpdate
        }
    }
}

export const recordActualOrderItemTotal = (
    orderId,
    itemId,
    actualItemTotalXcd,
) => {
    return {
        type: RECORD_ACTUAL_ORDER_ITEM_TOTAL,
        payload : {
            orderId,
            itemId,
            actualItemTotalXcd,
        }
    }
}

export const fetchSaveMyOrders = userId => {
    const firestore = getFirestore(firebaseApp)
    const ordersRef = query(collection(firestore, "orders"),
                                     where("customerId", "==", userId))
    return async dispatch => {
        try {
            const querySnapshot = await getDocs(ordersRef)
            //get an array of orders from the snapshot
            const orders = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(saveOrders(orders))
            return true
        } catch (e){
            const message = `action > orders > fetchSaveMyOrders: Failed to save orders for user ${userId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSubscribeToMyOrders = userId => {
    /**
      * Purpose: retrieve the orders from the firestore database for a particular user
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const ordersRef = query(collection(firestore, "orders"),
                                where("customerId", "==", userId))
    return async dispatch => {
        try {
            const ordersListener = await onSnapshot(ordersRef,
                querySnapshot => {
                    //get an array of orders from the snapshot
                    const orders = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveOrders(orders))
            })
            return ordersListener
        } catch (e){
            const message = `action > orders > fetchSubscribeToMyOrders: Failed to save orders`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchSubscribeToMyOrder = (orderId) => {
    /**
      * Purpose: retrieve one order from the firestore database for a particular user
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const ordersRef = doc(firestore, "orders", orderId)
    return async dispatch => {
        try {
            const ordersListener = await onSnapshot(ordersRef, 
                docRef => {
                    const order = {...docRef.data()};
                    dispatch(saveOrders([order]))
            })
            return ordersListener
        } catch (e){
            const message = `action > orders > fetchSubscribeToMyOrder: Failed to save order`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchSaveLiveOrders = () => {
    /**
      * Purpose: retrieve the live orders from the firestore database
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const ordersRef = query(collection(firestore, "orders"), 
                                where("currentStatus", 
                                        "in", 
                                        [CUSTOMER_CONFIRMED_PAYMENT, SHOPDM_CONFIRMED_PAYMENT, CUSTOMER_SELECTED_PAY_INSTORE]
                                    ))
                                    
                                
    return async dispatch => {
        try {
            const ordersListener = await onSnapshot(ordersRef,
                querySnapshot => {
                    //get an array of orders from the snapshot
                    const orders = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveOrders(orders))
                } 
            )
            return ordersListener
        } catch (e){
            const message = `action > orders > fetchSaveLiveOrders: Failed to save live orders`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveRecentlyClosedOrders = (
    fromDate = Date.now() - DAY_IN_MILLISECONDS//defaults to listening to all orders closed in the last day
) => {
    /**
      * Purpose: retrieve all live orders from the firestore database
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const ordersRef = query(collection(firestore, "orders"), 
                                where("closedAt", ">=",  fromDate))
                                    
                                
    return async dispatch => {
        try {
            const ordersListener = await onSnapshot(ordersRef,
                querySnapshot => {
                    //get an array of orders from the snapshot
                    const orders = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    console.log("saving recently closed orders", orders)
                    dispatch(saveOrders(orders))
                } 
            )
            return ordersListener
        } catch (e){
            const message = `action > orders > fetchSaveRecentlyClosedOrders: Failed to save recently closed orders`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveAdminOrders = () => {
    /**
      * Purpose: retrieve the orders from the firestore database
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const ordersRef = query(collection(firestore, "orders"))
                                
    return async dispatch => {
        try {
            const ordersListener = await onSnapshot(ordersRef,
                querySnapshot => {
                    //get an array of orders from the snapshot
                    const orders = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveOrders(orders))
                } 
            )
            return ordersListener
        } catch (e){
            const message = `action > orders > fetchSaveAdminOrders: Failed to save orders`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveAdminOrdersInDateRange = (
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all orders placed in the last 30 days
    toDate=Date.now()
) => {
    /**
      * Purpose: retrieve the orders from the firestore database created between the specified date range
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const ordersRef = query(collection(firestore, "orders"), 
                            where("createdAt", ">=",  fromDate),
                            where("createdAt", "<=", toDate))                          
    return async dispatch => {
        try {
            const ordersListener = await onSnapshot(ordersRef,
                querySnapshot => {
                    //get an array of orders from the snapshot
                    const orders = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveOrders(orders))
                } 
            )
            return ordersListener
        } catch (e){
            const message = `action > orders > fetchSaveAdminOrdersInDateRange: Failed to save orders from ${fromDate} to ${toDate}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveSellerOrders = sellerId => {
    /**
      * Purpose: retrieve the orders from the firestore database for a particular seller
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp) 
    const ordersRef = query(collection(firestore, "orders"),
                                where("sellerIds", "array-contains", sellerId))
    return async dispatch => {
        try {
            const ordersListener = await onSnapshot(ordersRef,
                querySnapshot => {
                    //get an array of orders from the snapshot
                    const orders = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveOrders(orders))
            })
            return ordersListener
        } catch (e){
            const message = `action > orders > fetchSaveSellerOrders: Failed to save orders`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchSaveLiveSellerOrders = sellerId => {
    /**
      * Purpose: retrieve the live orders for one seller from the firestore database
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const ordersRef = query(collection(firestore, "orders"), 
                                where("currentStatus", 
                                        "in", 
                                        [CUSTOMER_CONFIRMED_PAYMENT, SHOPDM_CONFIRMED_PAYMENT, CUSTOMER_SELECTED_PAY_INSTORE]
                                    ),
                                where("sellerIds", "array-contains", sellerId))
                                    
                                
    return async dispatch => {
        try {
            const ordersListener = await onSnapshot(ordersRef,
                querySnapshot => {
                    //get an array of orders from the snapshot
                    const orders = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveOrders(orders))
                } 
            )
            return ordersListener
        } catch (e){
            const message = `action > orders > fetchSaveLiveSellerOrders: Failed to save live orders for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveRecentlyClosedSellerOrders = (
    sellerId,
    fromDate = Date.now() - DAY_IN_MILLISECONDS//defaults to listening to all orders closed in the last day
) => {
    /**
      * Purpose: retrieve the live orders for one seller from the firestore database
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const ordersRef = query(collection(firestore, "orders"), 
                                where("closedAt", ">=",  fromDate),
                                where("sellerIds", "array-contains", sellerId))
                                    
                                
    return async dispatch => {
        try {
            const ordersListener = await onSnapshot(ordersRef,
                querySnapshot => {
                    //get an array of orders from the snapshot
                    const orders = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveOrders(orders))
                } 
            )
            return ordersListener
        } catch (e){
            const message = `action > orders > fetchSaveRecentlyClosedSellerOrders: Failed to save recently closed orders for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveDeliveryProviderOrders = deliveryProviderId => {
    /**
      * Purpose: retrieve the orders from the firestore database for a particular delivery provider
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp) 
    const ordersRef = query(collection(firestore, "orders"),
                                where("deliveryProviderIds", "array-contains", deliveryProviderId))
    return async dispatch => {
        try {
            const ordersListener = await onSnapshot(ordersRef,
                querySnapshot => {
                    //get an array of orders from the snapshot
                    const orders = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveOrders(orders))
            })
            return ordersListener
        } catch (e){
            const message = `action > orders > fetchSaveDeliveryProviderOrders: Failed to delivery provider orders`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchCreateOrder = (
    customerId,
    customerEmail,
    customerName,
    customerProfileImageUrl,
    customerAddress,
    customerPhone,
    customerWalletId,
    customerUnsubscribed,
    sellerIds,
    sellersById,
    deliveryProviderIds,
    deliveryProvidersById,
    deliverySlotsById,
    paymentProvider,
    totalXcd,
    paymentProcessorTotalXcd,
    shopdmCreditUsedXcd,
    shopdmCreditOwedXcd,
    processingFeeXcd,
    paymentProcessorFeeXcd,
    deliveryFeeXcd,
    subTotalXcd,
    shopdmCommissionEarningsXcd,
    shopdmDeliveryEarningsXcd,
    shopdmGiftingEarningsXcd,
    shopdmTotalEarningsXcd,
    orderItemsById,
    giftParameters,
    giftFeeXcd,
    orderNotes,
    cartId,
    promotionIds,
    orderStatus,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const id = uuid4()
    const firestore = getFirestore(firebaseApp)
    const ordersRef = doc(firestore, "orders", id)
    const statsRef = doc(firestore, 'orders', STATISTICS_IDENTIFIER);
    const incrementValue = increment(1);
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    const day = now.getDate()
    
    const order = {
        id,
        customerId,
        customerEmail,
        customerName,
        customerProfileImageUrl,
        customerAddress,
        customerPhone,
        customerWalletId,
        sellerIds,
        sellersById,
        sellerOrderStatus: {},
        deliveryProviderIds,
        deliveryProvidersById,
        deliverySlotsById,
        paymentProvider,
        totalXcd,
        paymentProcessorTotalXcd,
        shopdmCreditUsedXcd,
        shopdmCreditOwedXcd,
        processingFeeXcd,
        paymentProcessorFeeXcd,
        deliveryFeeXcd,
        subTotalXcd,
        shopdmCommissionEarningsXcd,
        shopdmDeliveryEarningsXcd,
        shopdmGiftingEarningsXcd,
        shopdmTotalEarningsXcd,
        orderItemsById,
        giftParameters,
        giftFeeXcd,
        orderNotes,
        cartId,
        promotionIds,
        currentStatus: orderStatus,
        orderStatus: {[orderStatus] : Date.now()},
        createdAtDay:day,
        createdAtMonth: month,
        createdAtYear: year,
        createdAt: Date.now(),
        isPayInstore: false,
        edits:[]
    }
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            order.isCustomersFirstOrder = Boolean(!user.orderCount || user.orderCount === 0)  
            const successful = await runTransaction(firestore, async transaction =>{
                //generate order number based on the current order count for the year
                const statsDocRef = await transaction.get(statsRef)
                const previousCount = statsDocRef.data()[`count${year}`] ? Number(statsDocRef.data()[`count${year}`]) : 0
                order.orderNumber = `${previousCount + 1}${year}`
                await transaction.update(statsRef,{
                    count: incrementValue,
                    [`count${year}`]: incrementValue,
                    [`count${year}-${month}`]: incrementValue,
                    [`count${year}-${month}-days.${day}`]: incrementValue,
                })
                await transaction.set(ordersRef, order)
            })
            dispatch(createOrder(order))
            onSuccess(order)
            return order.orderNumber
        } catch (e){
            const message = `Failed to create order ${JSON.stringify(order)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`action > orders > fetchCreateOrder: ${e.message} ${message}`))
            } else {
                e.message = `action > orders > fetchCreateOrder: ${e.message} ${message}`
                logError(e)
            }
            onError(order)
            return false
        }
        
    }
}

export const fetchUpdateOrder = (
    id,
    customerId,
    customerEmail,
    customerName,
    customerProfileImageUrl,
    customerAddress,
    customerPhone,
    customerWalletId,
    customerUnsubscribed,
    sellerIds,
    sellersById,
    deliveryProviderIds,
    deliveryProvidersById,
    deliverySlotsById,
    paymentProvider,
    totalXcd,
    paymentProcessorTotalXcd,
    shopdmCreditUsedXcd,
    shopdmCreditOwedXcd,
    processingFeeXcd,
    paymentProcessorFeeXcd,
    deliveryFeeXcd,
    subTotalXcd,
    shopdmCommissionEarningsXcd,
    shopdmDeliveryEarningsXcd,
    shopdmGiftingEarningsXcd,
    shopdmTotalEarningsXcd,
    orderItemsById,
    giftParameters,
    giftFeeXcd,
    orderNotes,
    cartId,
    promotionIds,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const ordersRef = doc(firestore, "orders", id)
    const orderUpdate = {
        customerId,
        customerEmail,
        customerName,
        customerProfileImageUrl,
        customerAddress,
        customerPhone,
        customerWalletId,
        sellerIds,
        sellersById,
        sellerOrderStatus: {},
        deliveryProviderIds,
        deliveryProvidersById,
        deliverySlotsById,
        paymentProvider,
        totalXcd,
        paymentProcessorTotalXcd,
        shopdmCreditUsedXcd,
        shopdmCreditOwedXcd,
        processingFeeXcd,
        paymentProcessorFeeXcd,
        deliveryFeeXcd,
        subTotalXcd,
        shopdmCommissionEarningsXcd,
        shopdmDeliveryEarningsXcd,
        shopdmGiftingEarningsXcd,
        shopdmTotalEarningsXcd,
        orderItemsById,
        giftParameters,
        giftFeeXcd,
        orderNotes,
        cartId,
        promotionIds,
        isPayInstore: false,
        lastEditedAt: Date.now(),
        lastEditedByUserId: customerId
    }
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            orderUpdate.isCustomersFirstOrder = Boolean(!user.orderCount || user.orderCount === 0)  
            await updateDoc(ordersRef, orderUpdate)
            // dispatch((orderUpdate))
            onSuccess(orderUpdate)
            return true
        } catch (e){
            const message = `Failed to update order ${id} ${JSON.stringify(orderUpdate)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`action > orders > fetchUpdateOrder: ${e.message} ${message}`))
            } else {
                e.message = `action > orders > fetchUpdateOrder: ${e.message} ${message}`
                logError(e)
            }
            onError(orderUpdate)
            return false
        }
        
    }
}

export const fetchUpdateOrderStatus = (
    id,
    status,
    onSuccess= () => {},
    onError = ()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const ordersRef = doc(firestore, "orders", id)
    const statusUpdate = {
        currentStatus: status,
        orderStatus: {[status]: Date.now()},
        lastEditedAt: Date.now()
    }
    //flag for if the customer has decided to pay instore
    if (status === CUSTOMER_SELECTED_PAY_INSTORE){
        statusUpdate.isPayInstore = true
    }
    return async (dispatch, getState) => {
        try{
            statusUpdate.lastEditedByUserId = getState().user.id
            await setDoc(ordersRef, statusUpdate, {merge: true})
            dispatch(updateOrderStatus(id, statusUpdate))
            onSuccess(statusUpdate)
            return true
        } catch (e){
            const message = `action > orders > fetchUpdateOrderStatus: Failed to update order ${id} with values ${JSON.stringify(statusUpdate)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(statusUpdate)
            return false
        }
    }
}

export const fetchUpdateOrderClosedAt = (
    id,
    closedAt,
    onSuccess= () => {},
    onError = ()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const ordersRef = doc(firestore, "orders", id)
    const statusUpdate = {
        closedAt,
    }
    return async (dispatch, getState) => {
        try{
            await setDoc(ordersRef, statusUpdate, {merge: true})
            return true
        } catch (e){
            const message = `action > orders > fetchUpdateOrderClosedAt: Failed to update order ${id} with values ${JSON.stringify(statusUpdate)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(statusUpdate)
            return false
        }
    }
}

export const fetchUpdateSellerOrderStatus = (
    id,
    sellerId,
    status,
    muteNotifications=false,
    onSuccess= () => {},
    onError = ()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const ordersRef = doc(firestore, "orders", id)
    return async (dispatch, getState) => {
        try{
            const {orders, user} = getState()
            const order = orders.ordersById[id]
            if (!order) return false
            const statusHistory = {[status]: Date.now()}
            const statusUpdate = {
                lastEditedAt: Date.now(),
                muteNotifications,
                sellerOrderStatus: {
                    [sellerId]: statusHistory
                }
            }
            statusUpdate.lastEditedByUserId = user.id
            await setDoc(ordersRef, statusUpdate, {merge: true})
            dispatch(updateSellerOrderStatus(id, sellerId, statusUpdate, statusHistory))
            onSuccess(statusUpdate)
            return true
        } catch (e){
            const message = `action > orders > fetchUpdateSellerOrderStatus: Failed to update order ${id} with seller status ${status} for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(status)
            return false
        }
    }
}

export const fetchUpdateSellerPackageInfo = (
    id,
    sellerId,
    numOfPieces,
    numOfRefrigeratedPieces,
    numOfFrozenPieces,
    onSuccess= () => {},
    onError = ()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const ordersRef = doc(firestore, "orders", id)
    return async (dispatch, getState) => {
        try{
            const {orders, user} = getState()
            const order = orders.ordersById[id]
            if (!order) return false
            const packageInfoUpdate = {
                lastEditedAt: Date.now(),
                sellerPackageInfo: {
                    [sellerId]: {
                        numOfPieces,
                        numOfFrozenPieces,
                        numOfRefrigeratedPieces
                    }
                },
                lastEditedByUserId: user.id
            }
            await setDoc(ordersRef, packageInfoUpdate, {merge: true})
            dispatch(updateSellerPackageInfo(id, sellerId, packageInfoUpdate))
            onSuccess(packageInfoUpdate)
            return true
        } catch (e){
            const message = `action > orders > fetchUpdateSellerPackageInfo: Failed to update order ${id} with seller package info numOfPieces ${numOfPieces} numOfRefrigeratedPieces ${numOfRefrigeratedPieces} numOfFrozenPieces ${numOfFrozenPieces} for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}

export const fetchRecordActualItemTotal = (
    id,
    itemId,
    productId,
    sellerId,
    actualItemTotalXcd,
    onSuccess=()=>{},
    onError =()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const ordersRef = doc(firestore, "orders", id)
    return async (dispatch, getState) => {
        try{
            const {orders, user, sellers} = getState()
            const order = orders.ordersById[id]
            if (!order) return false
            else if (!sellers.sellerIdsByProductId[productId][sellerId]){
                throw new Error(`You may not record an actual price for item ${itemId} as seller ${sellerId} does not sell product ${productId}`)
            } else if (isNaN(actualItemTotalXcd)){
                throw new Error(`Actual item total ${actualItemTotalXcd} is not a number`)
            }
            const orderUpdate = {
                lastEditedAt: Date.now(),
                lastEditedByUserId: user.id,
                orderItemsById:{
                    [itemId]: {actualItemTotalXcd, shopdmReplaceQuantity: null}
                }
            }
            await setDoc(ordersRef, orderUpdate,{merge: true})
            dispatch(recordActualOrderItemTotal(
                id,
                itemId,
                actualItemTotalXcd,
            ))
            onSuccess(orderUpdate)
            return true
        } catch (e){
            const message = `action > orders > fetchRecordActualItemTotal: Failed to record actual item total of $${actualItemTotalXcd} for item ${itemId}, product ${productId} in order ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(actualItemTotalXcd)
            return false
        }
    }
}

export const fetchToggleShopdmReplaceItem = (
    id,
    itemId,
    productId,
    sellerId,
    shopdmReplaceQuantity=null,
    onSuccess=()=>{},
    onError =()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const ordersRef = doc(firestore, "orders", id)
    return async (dispatch, getState) => {
        try{
            const {orders, user, sellers} = getState()
            const order = orders.ordersById[id]
            if (!order) return false
            else if (!sellers.sellerIdsByProductId[productId][sellerId]){
                throw new Error(`You may not request a Shopdm replacement for item ${itemId} as seller ${sellerId} does not sell product ${productId}`)
            } else if (shopdmReplaceQuantity !== null && isNaN(shopdmReplaceQuantity)){
                throw new Error(`Shopdm replacement quantity ${shopdmReplaceQuantity} is not a number`)
            }
            const orderUpdate = {
                lastEditedAt: Date.now(),
                lastEditedByUserId: user.id,
                orderItemsById:{
                    [itemId]: {actualItemTotalXcd:null, shopdmReplaceQuantity}
                }
            }
            await setDoc(ordersRef, orderUpdate,{merge: true})
            onSuccess(orderUpdate)
            return true
        } catch (e){
            const message = `action > orders > fetchToggleReplaceItem: Failed to ask Shopdm to replace ${shopdmReplaceQuantity} of item ${itemId}, product ${productId} in order ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(shopdmReplaceQuantity)
            return false
        }
    }
}



export const fetchVerifyCustomerCode = (
    verificationCode,
    orderId,
    orderNumber,
    sellerId,
    onSuccess= () => {},
    onError = ()=>{}
) => {
    const link = `${baseUrl}v1/orders/verify-code/sellers/customer-code`
    return async (dispatch, getState) => {
        try{
            const verifiedBy = getState().user.id
            let json
            const response = await fetch(link, {
                "method": 'POST',
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                "body": JSON.stringify({
                    verificationCode,
                    orderId,
                    orderNumber,
                    verifiedBy,
                    sellerId,
                    verificationSource: 'CUSTOMER'
                })
                
            })
            if (!response.ok) {
                let errorMessage = "Internal Server Error"
                if (response.status === 404) errorMessage = `The Code ${verificationCode} Is Incorrect`
                throw new Error(errorMessage, {statusCode: response.status});
            }
            json = await response.json()
            onSuccess()
            return true
        } catch (e){
            if (e.message === "Failed to fetch") e.message = "Check Internet Connection"
            onError(e)
            return false
        }
    }
}

export const fetchVerifyDeliveryCode = (
    verificationCode,
    orderId,
    orderNumber,
    sellerId,
    deliveryProviderId,
    onSuccess= () => {},
    onError = ()=>{}
) => {
    const link = `${baseUrl}v1/orders/verify-code/sellers/delivery-provider-pickup-code`
    return async (dispatch, getState) => {
        try{
            const verifiedBy = getState().user.id
            let json
            const response = await fetch(link, {
                "method": 'POST',
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                "body": JSON.stringify({
                    verificationCode,
                    orderId,
                    orderNumber,
                    verifiedBy,
                    sellerId,
                    deliveryProviderId,
                    verificationSource: THIRD_PARTY_DELIVERY
                })
                
            })
            if (!response.ok) {
                let errorMessage = "Internal Server Error"
                if (response.status === 404) errorMessage = `The Code ${verificationCode} Is Incorrect` 
                throw new Error(errorMessage, json);
            }
            json = await response.json()
            onSuccess()
            return true
        } catch (e){
            if (e.message === "Failed to fetch") e.message = "Check Internet Connection"
            onError(e)
            return false
        }
    }
}

export const fetchVerifyDeliveryDropoffCode = (
    verificationCode,
    orderId,
    orderNumber,
    sellerId,
    deliveryProviderId,
    onSuccess= () => {},
    onError = ()=>{}
) => {
    const link = `${baseUrl}v1/orders/verify-code/delivery-providers/customer-code`
    return async (dispatch, getState) => {
        try{
            const verifiedBy = getState().user.id
            let json
            const response = await fetch(link, {
                "method": 'POST',
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                "body": JSON.stringify({
                    verificationCode,
                    orderId,
                    orderNumber,
                    verifiedBy,
                    sellerId,
                    deliveryProviderId,
                    verificationSource: 'CUSTOMER'
                })
                
            })
            if (!response.ok) {
                let errorMessage = "Internal Server Error"
                if (response.status === 404) errorMessage = "Incorrect Code Entered"
                throw new Error(errorMessage, json);
            }
            json = await response.json()
            onSuccess()
            return true
        } catch (e){
            if (e.message === "Failed to fetch") e.message = "Check Internet Connection"
            onError(e)
            return false
        }
    }
}

export const fetchVerificationCodes = (
    orderNumber,
    onSuccess= () => {},
    onError = ()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const orderVerificationRef = doc(firestore, "orderVerification", orderNumber)
    return async () => {
        try{
            
            const docRef = await getDoc(orderVerificationRef)
            let verificationCodes = null
            if (docRef.exists()){
                verificationCodes = docRef.data()
            }
            onSuccess()
            return verificationCodes
        } catch (e){
            const message = `action > orders > fetchVerificationCodes: Failed to get verification codes for order ${orderNumber}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(e)
            return false
        }
    }
}

export const fetchUpdateBulkOrdersToHavePaymentProcessorFees = () => {
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) => {
        try {
            const {orders} = getState()
            //loop through batches to avoid hitting the 500 write firebase limit
            const ordersWithoutProcessingFees = Object.values(orders.ordersById).filter(o => !o.paymentProcessorFeeXcd)
            const batchArray = [writeBatch(firestore)]
            const batchLimit = 400 //after this many operations on the batch, a new batch will be added and used
            let batchIndex = 0
            let operationCount = 0
            for(let i = 0; i < ordersWithoutProcessingFees.length; i++){
                const batch = batchArray[batchIndex]
                const o = {...ordersWithoutProcessingFees[i]}
                const shopdmFeeXcd = o.shopdmCommissionEarningsXcd ?
                                     o.shopdmCommissionEarningsXcd
                                     :
                                     o.subTotalXcd * 0.03
                const paymentProcessorFeeXcd = currency(o.processingFeeXcd - shopdmFeeXcd).toString()
                if (paymentProcessorFeeXcd < 0) {
                    throw new Error("payment processor fee is negative!", paymentProcessorFeeXcd, o)
                } else if (Number(paymentProcessorFeeXcd) === 0){
                    console.warn("payment processor fee is zero", o)
                }
                o.paymentProcessorFeeXcd = paymentProcessorFeeXcd
                if (!o.shopdmCommissionEarningsXcd) o.shopdmCommissionEarningsXcd = isNaN(o.subTotalXcd * 0.03) ? "0.00" : currency(o.subTotalXcd * 0.03).toString()
                if (isNaN(o.totalXcd)) o.totalXcd = "0.00"
                const ordersRef = doc(firestore, "orders", o.id)
                batch.update(ordersRef, {
                    paymentProcessorFeeXcd,
                    shopdmCommissionEarningsXcd: o.shopdmCommissionEarningsXcd,
                    totalXcd: o.totalXcd
                })
                operationCount++
                if (operationCount >= batchLimit) {
                    batchArray.push(writeBatch(firestore));
                    batchIndex++;
                    operationCount = 0;
                }
            }
            //save all orders in the different batches
            let savedOrderCount = 0
            for (let i in batchArray) {
                const batch = batchArray[i]
                savedOrderCount += batch._mutations ? batch._mutations.length : batchLimit
                await batch.commit()
                console.log(`Saved ${savedOrderCount} out of ${ordersWithoutProcessingFees.length} orders`)
            } 
            return true
        } catch (e){
            const message = `action > orders >  fetchUpdateBulkOrdersToHavePaymentProcessorFees: Failed to update bulk orders to have payment processor fees`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}