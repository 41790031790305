import React from "react"
import styles from "./AppLoader.module.css"
import BigLogo from "../../images/logo300x300.png"
import MedLogo from "../../images/logo200x200.png"
import SmallLogo from "../../images/logo.png"
import LoadAnimation from "../LoadAnimation"

class AppLoader extends React.Component{
    render(){
        const {innerWidth} = window
        const logo = innerWidth > 1300 ? BigLogo:   
                     innerWidth <= 700 ? SmallLogo :
                     MedLogo
        return (
            <div className={styles.container}>
                <div className={styles.center}>
                    <img src={logo} />
                    <div className={`slogan ${styles.slogan}`}>Because it should be easy</div>
                    <div className={styles.animation}>
                        <LoadAnimation />
                    </div>            
                </div>
            </div>
        )
    }
}
export default AppLoader;