import React from "react"
import Icon from "../Icon"

import styles from "./NavigationTile.module.css"

function NavigationTile({icon, title, onClick=()=>{}, selected=false}){
    const selectedStyle = selected ? styles.selected : null
    return (
        <div className={[styles.tile, styles.container, selectedStyle].join(" ")} onClick={onClick}>
            <Icon icon={icon}/>
            <div>{title}</div>
        </div>
    )
}
export default NavigationTile;