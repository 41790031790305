import React from 'react'
import Modal from "../Modal"
import DisabledButton from "../DisabledButton"

import styles from "./PaymentModal.module.css"
import Logo from "../../images/logo300x300.png"
import {
    PAYMENT_OBJECT_TYPE_ORDER,
} from "../../constants/payment"
import {copyToClipboard} from "../../utils/interactionUtils"

function PaymentModal({
    paymentTitle,
    logo=Logo, 
    reference, 
    amount2DecimalPlaces, 
    objectType=PAYMENT_OBJECT_TYPE_ORDER,
    totalText="Total to pay",
    isOpen, 
    closeModal, 
    onPressConfirm, 
    currency="EC", 
    currencySymbol="$", 
    specificsTitle="How to pay:", 
    confirmButtonText="Confirm And Place Order",
    cancelButtonText="Cancel And Go Back To Checkout",
    children,
    isDisabled=false,
    disabledText=""
}){

    const handlePressCancel = () => {
        if (window.confirm(`Are you sure you want to cancel? Your ${objectType} will not be processed until you confirm the payment`)){
            closeModal()
        }
    }
    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            showCloseButton={false}
            className="contained"
                innerClassName="contained"
        >
                <div className="modalHeader">
                    <img height={45} width={45} draggable="false" src={logo} alt="Shopdm Logo"/>
                    <div className="strong strongTitle">{paymentTitle}</div>
                </div>
                <div className="modalInner contained">
                    <div className={styles.content}>
                        <div>
                            <div>{totalText}</div>
                            <div className='amount big center spaced'>{currency} {currencySymbol}<span title="copy amount" className='actionText' onClick={() => copyToClipboard(amount2DecimalPlaces)}>{amount2DecimalPlaces}</span></div>
                            <div>{`for ${objectType} ${reference}`}</div>
                        </div>
                        {specificsTitle  ? <div className='strong'>{specificsTitle}</div> : ""}
                        <div className={styles.specifics}>
                            {children}
                        </div>
                    </div>
                </div>
                <div className="modalFooter">
                    <button className="button dark" onClick={handlePressCancel}>{cancelButtonText}</button>
                    {
                        isDisabled ?
                        <DisabledButton
                            alert={disabledText}
                        >
                            {confirmButtonText}
                        </DisabledButton>
                        :
                        <button className='button primary' onClick={() => onPressConfirm()}>
                            {confirmButtonText}
                        </button>
                    }
                    
                </div>
        </Modal>
    )
}
export default PaymentModal