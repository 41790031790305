import React from "react"

import {
    BrowserRouter as Router,
    Redirect,
} from "react-router-dom";

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import {AIRBNB_CATEGORY_ID} from "../../constants/categories"
import {logAnalyticsEvent} from "../../config/firebase"

class PartnerProfile extends React.Component{

    state = {
        loaded: false
    }
    componentDidMount = async () => {
        const {actions, match} = this.props
        const {partnerHandleId} = match.params
        actions.toggleLoading(true)
        const partner = await actions.fetchSavePartnerByHandle(partnerHandleId)
        actions.toggleLoading(false)
        if (partner){
            logAnalyticsEvent("partner_referred_customer")
            actions.fetchLogDeviceSessionAction({
                action: "partnerReferredCustomer",
                partnerId: partner.id,
                handleId: partner.handleId
            })
        }
        this.setState({loaded: true})
    }

    render(){
        const {loaded} = this.state
        const {match} = this.props
        const {partnerHandleId} = match.params
        if (loaded) return <Redirect to={`/categories/${AIRBNB_CATEGORY_ID}`}/>
        return (
        <div>
            {/* Partner profile {partnerHandleId}  */}
        </div>
        )
    }
}

const mapStateToProps = state => ({
    partners: state.partners
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerProfile)