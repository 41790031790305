import React from "react"

import Thumbnail from "../../components/Thumbnail"
import CloseButton from "../../components/CloseButton"
import CharacteristicsList from "../CharacteristicsList"

import styles from "./ProductVariantListItem.module.css"

class ProductVariantListItem extends React.PureComponent {

    static defaultProps = {
        id: "",
        imageUrl: "",
        titleAndBrand: "",
        characteristics: {},
        showCloseButton: true,
        onPressClose: () => {},
        onClick: () => {}
    }
    render(){
        const {id, imageUrl, titleAndBrand, price, characteristics, showCloseButton, onPressClose, onClick} = this.props
        return(
            <div className={styles.container} >
                <div className="display-flex flex-1" onClick={() => onClick(id)}>
                    <div><Thumbnail url={imageUrl}/></div>
                    <div className={styles.description}>
                        <div className={styles.title}>{titleAndBrand}</div>
                        <div>EC${Number(price).toFixed(2)}</div>
                        <div className={styles.characteristics}>
                        {
                            Object.keys(characteristics).length >= 1?
                            <CharacteristicsList id={id} characteristics={characteristics}/>
                            :
                            null
                        }
                        </div>
                        {this.props.children}
                    </div>
                </div>
                <div className={styles.close}>
                    {showCloseButton ? <CloseButton onClick={() => onPressClose(id)}/> : null}
                </div>
            </div>
        )
    }
}

export default ProductVariantListItem