import React from "react"
import styles from "./WalletCard.module.css"
import logo from "../../images/logo.png"

import {
    WALLET_CARD_SIZE_BIG,
    WALLET_CARD_SIZE_MEDIUM,
    WALLET_CARD_SIZE_SMALL,
} from "../../constants/interface"

const WalletCard = ({
    name="",
    balance=0,
    size=WALLET_CARD_SIZE_BIG,
    onClick=null,
    selected=false,
    children,
    balanceText="Balance"
}) => {
    const walletStyles = size === WALLET_CARD_SIZE_MEDIUM ?
                         styles.medium
                         :
                         size === WALLET_CARD_SIZE_SMALL ?
                         styles.small
                         :
                         ""
    const clickableStyles = onClick ? styles.clickable : ""
    const selectedStyles = selected ? styles.selected : ""
    onClick = onClick ? onClick : ()=>{}
    return (
        <div 
            className={`${styles.container} ${walletStyles} ${clickableStyles} ${selectedStyles}`}
            onClick={onClick}
        >
            <div>
                {
                children ?
                <div>
                    {children}
                    <div className={`${styles.balanceBadge} balance`}>{balanceText}: <span className={`${balance < 0? "negative-balance" : ""}`}>EC${balance.toFixed(2).split(".")[0]}.<em>{balance.toFixed(2).split(".")[1]}</em></span></div>
                </div>
                
                :
                <div>
                    <div className={[`balance ${styles.balance} ${balance < 0? styles.negative : ""}`].join(" ")}>
                        EC${balance.toFixed(2).split(".")[0]}.<em>{balance.toFixed(2).split(".")[1]}</em>
                    </div>
                </div>
                }
                <img className={['logo', styles.logo].join(" ")} draggable="false" src={logo} alt="Shopdm"/>
            </div>
            <div className={styles.name}>
                {name}
            </div>
        </div>
    )
}

export default WalletCard