import {firebaseApp} from "../config/firebase"
import {getFirestore, doc, setDoc, writeBatch, deleteDoc, onSnapshot, getDocs, query, collection, where} from "firebase/firestore"
import { logError } from "../utils/errorHandlingUtils"
import {
    PROMOTION_STATUS_ONGOING,
    PROMOTION_STATUS_ENDED,
    PROMOTER_TYPE_SELLER
} from "../constants/promotions"

export const SAVE_PROMOTIONS = 'SAVE_PROMOTIONS'
export const CREATE_PROMOTION = 'CREATE_PROMOTION'
export const DELETE_PROMOTION = 'DELETE_PROMOTION'
export const EDIT_PROMOTION = 'EDIT_PROMOTION'

export const savePromotions = promotions => {
    return {
        type: SAVE_PROMOTIONS,
        payload: {
            promotions
        }
    }
}

const createPromotion = promotion => {
    return {
        type: CREATE_PROMOTION,
        payload: {
            promotion
        }
    }
}

const deletePromotion = promotionId => {
    return {
        type: DELETE_PROMOTION,
        payload: {
            promotionId
        }
    }
}

const editPromotion = (id, edits) => {
    return {
        type: EDIT_PROMOTION,
        payload: {
            id,
            edits
        }
    }
}

export const fetchSaveActivePromotions = () => {
    /**
      * Purpose: retrieve all active promotions from the firestore database
      */
     const firestore = getFirestore(firebaseApp)
     const promotionsRef = query(collection(firestore, "promotions"),
                                      where("currentStatus", "==", PROMOTION_STATUS_ONGOING))
     return async (dispatch) => {
            try {
                const promotionsListener = await onSnapshot(promotionsRef,
                    querySnapshot => {
                        //get an array of orders from the snapshot
                        const promotions = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                        dispatch(savePromotions(promotions))
                })
                return promotionsListener
         } catch (e){
             const message = `action > events > fetchSaveActivePromotions: Failed to save active promotions`
             if (e.message_){
                 //deal with firebase-specific errors
                 logError(new Error(`${e.message} ${message}`))
             } else {
                 e.message = `${e.message} ${message}`
                 logError(e)
             }
             return () => {}
         }
     }
}

export const fetchSaveRecentlyClosedPromotions = (
    fromDate = Date.now() - (60 * 1000)//defaults to listening to all promotions closed in the last minute
) => {
    /**
      * Purpose: retrieve all active promotions from the firestore database
      */
     const firestore = getFirestore(firebaseApp)
     const promotionsRef = query(collection(firestore, "promotions"),
                                        where("closedAt", ">=",  fromDate))
     return async (dispatch) => {
            try {
                const promotionsListener = await onSnapshot(promotionsRef,
                    querySnapshot => {
                        //get an array of orders from the snapshot
                        const promotions = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                        dispatch(savePromotions(promotions))
                })
                return promotionsListener
         } catch (e){
             const message = `action > events > fetchSaveRecentlyClosedPromotions: Failed to save recently closed promotions`
             if (e.message_){
                 //deal with firebase-specific errors
                 logError(new Error(`${e.message} ${message}`))
             } else {
                 e.message = `${e.message} ${message}`
                 logError(e)
             }
             return () => {}
         }
     }
}

export const fetchSaveSellerPromotions = sellerId => {
    /**
      * Purpose: retrieve all of a seller's promotions from the firestore database
      */
     const firestore = getFirestore(firebaseApp)
     const eventsRef = query(collection(firestore, "promotions"),
                                      where("promoterId", "==", sellerId),
                                      where("promoterType", "==", PROMOTER_TYPE_SELLER))
     return async (dispatch) => {
         try {
            const querySnapshot = await getDocs(eventsRef)
            //get an array of events from the snapshot
            const promotions = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(savePromotions(promotions))
            return promotions
         } catch (e){
             const message = `action > events > fetchSaveSellerPromotions: Failed to save seller ${sellerId} promotions`
             if (e.message_){
                 //deal with firebase-specific errors
                 logError(new Error(`${e.message} ${message}`))
             } else {
                 e.message = `${e.message} ${message}`
                 logError(e)
             }
             return false
         }
     }
}

export const fetchCreatePromotion = (
        id,
        title,
        promoterId,
        promoterType,
        promotionType,
        discountType,
        appliesToProductsType,
        customerEligibilityType,
        timingType,
        terms,
        isActive,
        startDate,
        endDate,
        currentStatus,
        onSuccess = () =>{},
        onError = () =>{}
    ) => {
        const firestore = getFirestore(firebaseApp)
        const promotionsRef = doc(firestore, "promotions", id)
        const promotion = {
            id,
            title,
            promoterId,
            promoterType,
            promotionType,
            discountType,
            appliesToProductsType,
            customerEligibilityType,
            timingType,
            terms,
            isActive,
            startDate,
            endDate,
            createdAt: Date.now(),
            currentStatus
        }
        return async (dispatch, getState) => {
            try{
                const {user} = getState()
                promotion.createdByUserId = user.id
                await setDoc(promotionsRef, promotion)
                dispatch(createPromotion(promotion))
                onSuccess(promotion)
                return true
            } catch (e){
                const message = `action > promotions > fetchCreatePromotion: Failed to create promotion for promoter ${promoterId} or type ${promoterType} ${JSON.stringify(promotion)}`
                if (e.message_){
                    //deal with firebase-specific errors
                    logError(new Error(`${e.message} ${message}`))
                } else {
                    e.message = `${e.message} ${message}`
                    logError(e)
                }
                onError(promotion)
                return false
            }
        }
}

export const fetchDeletePromotion = (
    id,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const promotionsRef = doc(firestore, "promotions", id)
    return async (dispatch) => {
        try {
            await deleteDoc(promotionsRef)
            dispatch(deletePromotion(id))
            onSuccess()
            return true
        } catch (e) {
            const message = `action > promotions > fetchDeletePromotion: Failed to delete promotion ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(e)
            return false
        }
    }
}

export const fetchEditPromotionStatus = (
    id,
    currentStatus,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const promotionsRef = doc(firestore, "promotions", id)
    return async (dispatch, getState) => {
        try {
            const {user} = getState()
            const edits = {
                currentStatus,
                lastEditedByUserId: user.id,
                lastEditedAt: Date.now(),
                closedAt: currentStatus === PROMOTION_STATUS_ENDED ? Date.now() : null
            }
            await setDoc(promotionsRef, edits, {merge: true})
            dispatch(editPromotion(id, edits))
            onSuccess()
            return true
        } catch (e) {
            const message = `action > promotions > fetchEditPromotionStatus: Failed to edit promotion ${id} to status ${currentStatus}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(e)
            return false
        }
    }
}

export const fetchUpdateAllPromotionsStatusesForEvent = (
    eventId,
    currentStatus=PROMOTION_STATUS_ONGOING,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    
    return async (dispatch, getState) => {
        try {
            const {promotions, user} = getState()
            const batch = writeBatch(firestore)
            Object.values(promotions.promotionsById).forEach(promotion => {
                if (
                    promotion.terms.eventId && 
                    (promotion.terms.eventId === eventId)
                ){
                    const promotionsRef = doc(firestore, "promotions", promotion.id)
                    batch.set(promotionsRef, {
                        currentStatus,
                        lastEditedByUserId: user.id,
                        lastEditedAt: Date.now(),
                        closedAt: currentStatus === PROMOTION_STATUS_ENDED ? Date.now() : null
                    }, {merge: true})
                }
            }) 
            
            await batch.commit()
            onSuccess()
            return true
        } catch (e) {
            const message = `action > promotions > fetchUpdateAllPromotionsStatusesForEvent: Failed to update promotion statuses for event ${eventId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(e)
            return false
        }
    }
}