import React from "react"
import PaymentModal from "../PaymentModal"
import Icon from "../Icon"
import styles from "./WireTransferPaymentModal.module.css"

const WireTransferPaymentModal = ({
    logo, 
    reference, 
    amount2DecimalPlaces, 
    isOpen, 
    closeModal, 
    onPressConfirm,
    confirmButtonText="Confirm And Place Order",
    cancelButtonText,
    objectType,
    disclaimerText=""
}) => {
    const usdAmount = (amount2DecimalPlaces/2.67).toFixed(2)
    const handlePressConfirm = () => {
        if (window.confirm(`Only press ok if you have finished the Wire Transfer payment for US$${usdAmount}`)){
            onPressConfirm()
        }
    }
    return (
        <PaymentModal 
            paymentTitle="Wire Transfer"
            logo={logo}
            reference={reference}
            amount2DecimalPlaces={usdAmount}
            currency="US"
            isOpen={isOpen}
            closeModal={closeModal}
            onPressConfirm={handlePressConfirm}
            confirmButtonText={confirmButtonText}
            cancelButtonText={cancelButtonText}
            objectType={objectType}
        >
            <div className={styles.container}>
                <div className={styles.summary}>Send us a wire transfer for <span className="amount">US ${usdAmount}</span> dollars then press '{confirmButtonText}' when you're done</div>
                <div className="formNotes info"><Icon icon="info"/> Wire transfers can take anywhere from 2 to 7 working days to arrive in our bank account{disclaimerText ? ` ${disclaimerText}`: ""}</div>
                <div className={"info-bubble"}>
                    <div className={styles.title}>Shopdm Details</div>
                    <div><span>Account Number:</span> 100082769</div>
                    <div><span>Account Name:</span> Dom Software Ltd T/A Shopdm</div>
                    <div><span>Account Address:</span> Cnr Hanover St & Hodges Lane, Roseau, Dominica</div>
                </div>
                <div className={"info-bubble"}>
                    <div className={styles.title}>Bank Details</div>
                    <div><span>Bank Name:</span> National Bank Of Dominica</div>
                    <div><span>Swift Code:</span> NCDMDMDM</div>            
                    <div><span>Bank Address:</span> 64 Hillsborough St, Roseau, Dominica</div>
                </div>
                <div className={"info-bubble"}>
                    <div className={styles.title}>Transaction Details</div>
                    <div><span>Amount (USD):</span> US${usdAmount}</div>
                    <div><span>Reference:</span> Shopdm {reference}</div>
                </div>
                <div className={"info-bubble"}>
                    <div className={styles.title}>Intermediary Bank Details (optional, depends on your bank)</div>
                    <div><span>Intermediary Bank Name:</span> Bank Of America</div>
                    <div><span>Intermediary Bank Address:</span> New York, USA</div>
                    <div><span>Intermediary Bank Routing Code/Number:</span> 026009593</div>
                    <div><span>Intermediary Bank Swift Code:</span> BOFAUS3N</div>
                </div>
            </div>
        </PaymentModal>
    )
}

export default WireTransferPaymentModal