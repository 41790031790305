import React from "react"

import Modal from "../../components/Modal"
import OrderListItem from "../../components/OrderListItem"
import OrderFeeBreakdown from '../../components/OrderFeeBreakdown'

import AddToCartButton from '../AddToCartButton'

import ReactToPrint from 'react-to-print';

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import {capitalizeAllWords, alphabeticalSort} from "../../utils/stringUtils"
import {timestampToDateTimeString} from "../../utils/datetimeUtils"

class CustomerOrderItemsModal extends React.PureComponent {

    constructor(props){
        super(props)
        this.state = {
            modalOpen: false,
            checkedItemIds: {},
            showPrintTitle: false
        }
        this.listRef = null
        this.printResolve = null
        this.printReject = null
        this.orderProductsLoaded = false
    }

    handleUpdateShowPrintTitle = showPrintTitle => {
        /**
         * To enable printing via React-To-Print
         * We trigger a refresh here as well as set the flag 
         * to show the print title a second time, so it shows up on the printed pdf
         */
        if (showPrintTitle && this.printResolve){
            this.printResolve()
            this.printResolve = null
        }
        this.setState({showPrintTitle})
    }

    handleLoadOrderProducts = async () => {
        //do not load the products more than once
        if (this.orderProductsLoaded) return
        const {actions, orderId, orders} = this.props
        //get the list of items from the order and load them, 
        //unless they have already been loaded
        const order = orders.ordersById[orderId]
        if (!order) return
        const orderProductIdMap = {}
        Object.values(order.orderItemsById).forEach(item => {
            orderProductIdMap[item.productId] = true
        })
        actions.toggleLoading(true)
        const success = await actions.fetchSaveProductsInList(Object.keys(orderProductIdMap))
        actions.toggleLoading(false)
        if (success) this.orderProductsLoaded = true
    }

    render(){
        const {orderId, orders} = this.props
        const {modalOpen, checkedItemIds, showPrintTitle} = this.state
        const order = orders.ordersById[orderId]
        if (!order) return ""
        const sellerList = Object.values(order.sellersById).sort((sellerA, sellerB) => alphabeticalSort(sellerA.name, sellerB.name))
        const checkedCount = Object.keys(checkedItemIds).reduce((count, itemId) => {
            if (checkedItemIds[itemId]) count += 1 
            return count
        }, 0) 

        return (
        <div>
            <button className="button dark" onClick={() => this.setState({modalOpen: true}, this.handleLoadOrderProducts)}>Check ({Object.keys(order.orderItemsById).length}) Items Or Buy Again</button>
            <Modal
                isOpen={modalOpen}
                closeModal={() => this.setState({modalOpen: false})}
                innerClassName="orderItemModal"
                closeOnOverlayClick={true}
            >
                <div className="title center">Shopdm Order {order.orderNumber} Checklist</div>
                <div className="orderItemCounter">{checkedCount} / {Object.keys(order.orderItemsById).length} items</div>
                <div className='buttonHolder spaced'>
                    <ReactToPrint 
                        trigger={()=> <button className="button dark">Print Receipt</button>}
                        content={() => this.listRef}
                        onBeforeGetContent={() => {
                            return new Promise((resolve, reject) => {
                                actions.toggleLoading(true)
                                this.printResolve = resolve;
                                this.printReject = reject;
                                this.handleUpdateShowPrintTitle(true)
                            });
                        }}
                        onAfterPrint={()=> {
                            actions.toggleLoading(false);
                            this.handleUpdateShowPrintTitle(false)
                        }}
                    />
                </div>
                <OrderFeeBreakdown order={order}/>
                <div ref={ref => this.listRef = ref}>
                    {
                        showPrintTitle ? 
                        <div>
                            <div className="title center">Shopdm Order {order.orderNumber} Receipt</div>
                            <OrderFeeBreakdown order={order}/>
                            <div>Printed at {timestampToDateTimeString()}</div>
                        </div>
                        :
                        null
                    }
                    <div className="innerOrderItemModal">
                    {
                        sellerList.map(seller => {
                            const sellerItems = [...seller.itemIds].map(itemId => {
                                                        const item = {...order.orderItemsById[itemId]}
                                                        item.titleAndBrand = item.titleAndBrand ? item.titleAndBrand : item.title
                                                        return item
                                                    })
                                                    .sort((itemA, itemB)=> alphabeticalSort(itemA.titleAndBrand, itemB.titleAndBrand))
                            return (
                                <div key={seller.id}>
                                    <div className="strong">{capitalizeAllWords(seller.name)} ({sellerItems.length})</div>
                                    {
                                        sellerItems.map(item => {
                                            return <OrderListItem key={item.id} 
                                                        id={item.id}
                                                        quantity={item.quantity} 
                                                        title={item.titleAndBrand} 
                                                        serialNumber={item.serialNumber}
                                                        price={item.price}
                                                        imageUrl={item.imageUrl}
                                                        onClickCheckbox={() => this.setState({checkedItemIds: {...checkedItemIds, [item.id]: !checkedItemIds[item.id]}})}
                                                        characteristics={item.characteristics}
                                                        checked={checkedItemIds[item.id]}
                                                        replaced={item.shopdmReplaceQuantity}
                                                        actualItemTotalXcd={item.actualItemTotalXcd}
                                                        refund={item.refund}
                                                        showCheckbox={true}
                                                        showActions={false}
                                                    >
                                                        <AddToCartButton 
                                                            productId={item.productId}
                                                            productStockId={item.id}
                                                            method="from add to cart button"
                                                            addToCartText="Buy Again"
                                                        />
                                                    </OrderListItem>
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </Modal>  
        </div>
        )
    }
} 

const mapStateToProps = state => ({
    orders: state.orders
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrderItemsModal)