export const PRODUCT_CHAR_NAMES = ["size", "color", "type", "style", "flavor", "scent"]
export const PRODUCT_CHAR_PLACEHOLDERS = {
    "size" : "e.g. 16ml or regular",
    "color": "e.g. red or blue",
    "type" : "e.g. white or wholewheat",
    "flavor": "e.g. cherry or grape",
    "style": "e.g. checkered or braided",
    "scent": "e.g. rose or cinnamon"
}
export const PRODUCT_UNITS_OPTIONS = ["lb", "half lb", "hand", "bag", "bundle", "piece", "pack", "can", "metre", "bottle", "tray"]

export const NEWEST_PRODUCTS_COUNT = 6