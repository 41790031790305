import React from "react"
import Modal from "../Modal"

import CreateAccountForm from "../../containers/CreateAccountForm"
import LoginForm from "../../containers/LoginForm"

import styles from "./AccountNeededModal.module.css"

const CREATE_ACCOUNT_FORM = "CREATE_ACCOUNT_FORM"
const LOGIN_FORM = "LOGIN_FROM"
class AccountNeededModal extends React.Component {

    state = {
        displayForm: CREATE_ACCOUNT_FORM
    }

    static defaultProps = {
        title: "to continue"
    }

    handleChangeDisplayForm = displayForm => this.setState({displayForm})

    render(){
        const {modalOpen, handleCloseModal, handleSuccess, title} = this.props
        const {displayForm} = this.state
        const titleAction = displayForm === CREATE_ACCOUNT_FORM ? "Create an account " : 
                            displayForm === LOGIN_FORM ? "Login ":
                            "Sign in "

        return (
            <Modal
                isOpen={modalOpen}
                closeModal={handleCloseModal}
                className={`${styles.modal} fit`}
                overlayClassName={`center ${styles.modalOverlay}`}
                closeOnOverlayClick={true}
                title={<div className={styles.title}>{titleAction} {title}</div>}
            >    
                { 
                    displayForm === CREATE_ACCOUNT_FORM ?
                    <CreateAccountForm 
                        onSuccess={handleSuccess} 
                        formTitle=""
                        handleLogin={() => this.handleChangeDisplayForm(LOGIN_FORM)}
                    />
                    :
                    displayForm === LOGIN_FORM ?
                    <LoginForm 
                        formTitle=""
                        onSuccess={handleSuccess} 
                        redirectOnAuth={false}
                        handleCreateAccount={()=>this.handleChangeDisplayForm(CREATE_ACCOUNT_FORM)}
                    />
                    :
                    null
                }
            </Modal>
        )
    }
}

export default AccountNeededModal