import React from 'react'
import {timestampToDateTimeString} from "../../utils/datetimeUtils"
import {capitalizeAllWords} from "../../utils/stringUtils"
import DataTable from "../../components/DataTable"
import CustomerOrderItemsModal from '../CustomerOrderItemsModal'

import {connect} from "react-redux"

import OrderStatusBadge from "../../components/OrderStatusBadge"

import styles from "./CustomerOrderTable.module.css"

function CustomerOrderTable ({orderArray, selectedOrderId="", onClick=null}){
    const rowHighlightFunction = order => order.id === selectedOrderId ? 
                                            "var(--light-primary)" :
                                            ""
    return <DataTable 
        dataArray={orderArray}
        title="Orders"
        fields={[
            {id: 'orderNumber', header: "Order No."}, 
            {id: 'currentStatus', header :"Status", transformFunction: currentStatus => <OrderStatusBadge currentOrderStatus={currentStatus}/>}, 
            {id: 'id', header: "Item Total", transformFunction: (orderId, value, key, order) => <div className={styles.itemTotal}>
                                                                                <div>EC${order.totalXcd}</div>
                                                                                <CustomerOrderItemsModal orderId={orderId} />
                                                                            </div>
                                                                            , hideSort: true}, 
            {id: 'id', header: "Delivery Method(s)", transformFunction: orderId => <DeliveryProviders orderId={orderId} />, hideSort: true},   
            {id: 'placedAt', header: "Order Placed On", transformFunction: createdAt => timestampToDateTimeString(createdAt, true, true, false)},
        ]}
        defaultSortBy="placedAt"
        rowHighlightFunction={rowHighlightFunction}
        emptyText="No Orders. Go on, get yourself something nice :)"
        searchFields={['orderNumber']}
        paginate={true}
        maxRowsPerPage={25}
        onClick={onClick}
    />
}
const mapStateToProps = state => ({
    orders: state.orders
})


const DeliveryProviders = connect(mapStateToProps)( ({orderId, orders}) => {
    const order = orders.ordersById[orderId]
    if (!order) return ""
    const getSellerDeliveryProvider = seller => {
        const deliveryProvider =  order.deliveryProvidersById ? 
                                  order.deliveryProvidersById[seller.deliveryProviderId]
                                  : null
        if (!deliveryProvider) return null
        return (
        <div key={seller.id}>
            <div>{capitalizeAllWords(seller.name)}: <b>{capitalizeAllWords(deliveryProvider.name)}</b> (${Number(seller.deliveryFeeXcd).toFixed(2)})</div>

        </div>
        )
    }
    return (
    <div>
        {
            Object.values(order.sellersById).map(getSellerDeliveryProvider)
        }
    </div>)
})

export default CustomerOrderTable