import {firebaseApp} from "../config/firebase"
import { getFirestore, query, collection, onSnapshot, where, doc, writeBatch, increment, orderBy, limit} from "firebase/firestore"
import { logError } from "../utils/errorHandlingUtils"
import { STATISTICS_IDENTIFIER } from '../constants/analysis';
import { DAY_IN_MILLISECONDS } from "../constants/datetime";

export const SAVE_DISBURSEMENTS = 'SAVE_DISBURSEMENTS'
export const CREATE_DISBURSEMENTS = 'CREATE_DISBURSEMENTS'

export const saveDisbursements = disbursements => {
    return {
        type: SAVE_DISBURSEMENTS,
        payload: {
            disbursements
        }
    }
}

export const createDisbursements = disbursements => {
    return {
        type: CREATE_DISBURSEMENTS,
        payload: {
            disbursements
        }
    }
}

export const fetchSaveAdminDisbursements = () => {
    const firestore = getFirestore(firebaseApp)
    const disbursementsRef = query(collection(firestore, "disbursements"))
    return async (dispatch) => {
        try{
            const disbursementsListener = await onSnapshot(disbursementsRef,
                querySnapshot =>{
                    //get an array of products from the snapshot
                    const disbursements = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveDisbursements(disbursements))
                }
            )
            return disbursementsListener
        } catch (e){
            const message = `action > disbursements > fetchSaveAdminDisbursements: Failed to save disbursements`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSaveSellerDisbursements = sellerId => {
    const firestore = getFirestore(firebaseApp)
    const disbursementsRef = query(collection(firestore, "disbursements"),
                                      where("payeeId", "==", sellerId))
    return async (dispatch) => {
        try{
            const disbursementsListener = await onSnapshot(disbursementsRef, 
                querySnapshot =>{
                    //get an array of products from the snapshot
                    const disbursements = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveDisbursements(disbursements))
                }
            )
            return disbursementsListener
        } catch (e){
            const message = `action > disbursements > fetchSaveSellerDisbursements: Failed to save disbursements`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSaveDeliveryProviderDisbursements = deliveryProviderId => {
    const firestore = getFirestore(firebaseApp)
    const disbursementsRef = query(collection(firestore, "disbursements"), 
                                    where("payeeId", "==", deliveryProviderId))
    return async (dispatch) => {
        try{
            const disbursementsListener = await onSnapshot(disbursementsRef,
                querySnapshot =>{
                    //get an array of products from the snapshot
                    const disbursements = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveDisbursements(disbursements))
                }
            )
            return disbursementsListener
        } catch (e){
            const message = `action > disbursements > fetchSaveDeliveryProviderDisbursements: Failed to save disbursements`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchCreateDisbursements = (
    disbursements,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    const day = now.getDate()
    return async (dispatch, getState) => {
        try{
            const firestore = getFirestore(firebaseApp)
            const batch = writeBatch(firestore)
            let total = 0
            let count = 0
            disbursements.forEach(disbursement => {
                total += Number(disbursement.totalXcd)
                if (disbursement.reference > count) count = disbursement.reference
                disbursement.createdAt = Date.now()
                disbursement.createdByUserId = getState().user.id
                const disbursementRef = doc(firestore, "disbursements", disbursement.id)
                batch.set(disbursementRef, disbursement)
            })
            const statsRef = doc(firestore, 'disbursements', STATISTICS_IDENTIFIER);
            const increaseValue = increment(total);
            const increaseCount = increment(disbursements.length);
            batch.update(statsRef, {
                count,
                [`count${year}`]: increaseCount,
                [`count${year}-${month}`]: increaseCount,
                [`count${year}-${month}-days.${day}`]: increaseCount,
                [`total${year}`]: increaseValue,
                [`total${year}-${month}`]: increaseValue,
                [`total${year}-${month}-days.${day}`]: increaseValue,
            })
            await batch.commit()
            dispatch(createDisbursements(disbursements))
            onSuccess(disbursements)
            return true
        } catch (e){
            const message = `action > disbursements > fetchCreateDisbursements: Failed to create disbursements ${JSON.stringify(disbursements)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(disbursements)
            return false
        }
        
    }
}

export const fetchSubscribeToLastDisbursement = (
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const lastDisbursementRef = query(collection(firestore, "disbursements"), 
                                    orderBy("reference", "desc"),
                                    limit(1)
                                )
    return async (dispatch) => {
        try{
            const lastDisbursementListener = await onSnapshot(lastDisbursementRef,
                querySnapshot =>{
                    if (querySnapshot.docs.length === 0){
                        return false
                    }
                    //save the last disbursement
                    const lastDisbursement = querySnapshot.docs[0].data();  
                    dispatch(saveDisbursements([lastDisbursement]))
                }
            )
            return lastDisbursementListener
        } catch (e){
            const message = `action > disbursements > fetchSubscribeToLastDisbursement: Failed to save last disbursement`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
        
    }
}

export const fetchSubscribeToAdminDisbursementsInDateRange = (
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all disbursements placed in the last 30 days
    toDate=Date.now()
) => {
    /**
      * Purpose: retrieve the disbursements from the firestore database created between the specified date range
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const disbursementsRef = query(collection(firestore, "disbursements"), 
                            where("createdAt", ">=",  fromDate),
                            where("createdAt", "<=", toDate))                          
    return async dispatch => {
        try {
            const disbursementsListener = await onSnapshot(disbursementsRef,
                querySnapshot => {
                    //get an array of disbursements from the snapshot
                    const disbursements = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveDisbursements(disbursements))
                } 
            )
            return disbursementsListener
        } catch (e){
            const message = `action > disbursements > fetchSubscribeToAdminDisbursementsInDateRange: Failed to save disbursements from ${fromDate} to ${toDate}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}