import React from "react"
import styles from "./ProcessBanner.module.css"
import AddToCart from "../../images/add-to-cart.png"
import CreditCard from "../../images/credit-card.png"
import Delivery from "../../images/delivery.png"
import Sms from "../../images/sms.png"

const ProcessBanner = () => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.title}>Shop For Anything in 4 Simple Steps</div>
            </div>
            <div className={styles.elements}>
                <Element icon={AddToCart} text="Add To Cart And Checkout"/>
                <Element icon={CreditCard} text="Pay With Card Or Mobanking"/>
                <Element icon={Sms} text="Track Your Order Online"/>
                <Element icon={Delivery} text="Pick Up Or Get It Delivered"/>
            </div>
        </div>
    )
}

const Element = ({text, icon}) => {
    return (
        <div className={styles.element}>
            <div className={styles.elementCircle}>
                <img src={icon} height="60%"/>
            </div>
            <div className={styles.elementText}>{text}</div>
        </div>
    )
}
export default ProcessBanner