import React from 'react'
import FormField from "../../components/FormField"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"
import CustomerService from "../../images/customer-service.png"

const CheckoutOrderNotes = ({cart, actions}) => {
    return (
        <div>    
            <div className={'strong checkoutTitle space-bottom'}><span className="badge outline">5</span><img src={CustomerService}/>Leave A Note (optional)</div>
            <div className="checkoutField">
            <FormField  
                value={cart.orderNotes} 
                isTextArea={true}
                onChange={e => actions.updateOrderNotes(e.target.value)}
                placeholder="Leave any additional info we need to know e.g. 'I will be arriving at my hotel on the 16th' or 'I will be in town from 11am and would like to pick up then.' We'll make sure anyone who needs to know, knows"
            />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    cart: state.cart
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutOrderNotes)