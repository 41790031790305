import React from "react"
import styles from "./Unsubscribe.module.css"
import logo from "../../images/logo300x300.png"
import supportConfig from "../../config/support"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {capitalize} from "../../utils/stringUtils"
import ResultScreen from "../../components/ResultScreen"
import {Helmet} from "react-helmet";

class Unsubscribe extends React.Component{

    constructor(props){
        super(props)
        const {history} = props 
        const query = new URLSearchParams(history.location.search)
        let redirectFrom = query.get("redirect-from")
        redirectFrom = redirectFrom ? redirectFrom : "/"
        this.state = {
            resubscribed: false,
            redirectFrom
        }
    }

    handleLogin = () =>  {
        const {history} = this.props
        history.push("/login?redirect-from=/unsubscribe")
    }
    handleResubscribe = () => {
        const {history} = this.props
        const {redirectFrom} = this.state
        this.setState({resubscribed: true},
        () => setTimeout( () => history.push(redirectFrom), 3000))
    }

    handleUnsubscribe = unsubscribed => {
        const {user, actions} = this.props
        if (!user.authenticated) alert("Please log in so we can update your user preferences")
        else actions.fetchUpdateUnsubscribed(
            user.id, 
            unsubscribed, 
            !unsubscribed? this.handleResubscribe : ()=>{}
        )
    }

    render(){
        const {user, history, actions} = this.props
        const {resubscribed} = this.state

        return (
            <div className={[styles.container, "screen"].join(" ")}>
                <Helmet>
                    <title>Unsubscribe</title>
                </Helmet>
                {
                    resubscribed ?
                        <ResultScreen message={`Happy to have you back, ${capitalize(user.firstName)}! We're redirecting you to Shopdm now`}/>
                    :
                        <React.Fragment>
                            <div className="productTitle">{user.unsubscribed ? "You've unsubscribed. We hope this isn't 'goodbye, goodbye'" : "We're sad it came to this :("}</div>
                            { 
                                user.authenticated && !user.unsubscribed?
                                    <div className={styles.moreInfo}>Are you sure you want to unsubscribe, {capitalize(user.firstName)}?</div>
                                :
                                user.authenticated && user.unsubscribed?
                                    <div className={styles.moreInfo}>If you'd like to resubscribe, press the button below</div>
                                :
                                <div className={styles.moreInfo}>This is the page to unsubscribe, but we can't quite tell who you are, <span className={'anchorLink'} onClick={this.handleLogin}>so please log in here</span></div>
                            }
                            <p>Unsubcribed users can't make purchases on Shopdm, since we need to be able to email your verification code</p>
                            <img src={logo} alt="Shopdm Logo"/>
                            <button className="button dark" onClick={() => this.handleUnsubscribe(!user.unsubscribed)}>{ user.unsubscribed ? "Subscribe" : "Unsubscribe" }</button>
                            
                            <p>Want to talk? Email us at <a href={`mailto:${supportConfig.maintenanceEmail}`}>{supportConfig.maintenanceEmail}</a> or call us at <a title="call" href={`tel:${supportConfig.maintenancePhone}`}>{supportConfig.maintenancePhone}</a></p>
                        </React.Fragment>  
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);