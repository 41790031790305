import React from "react"
import styles from "./CharacteristicsList.module.css"

const CharacteristicsList = ({id, characteristics = {}}) => {
    if (Object.keys(characteristics).length === 0) return ""
    return (
    <div className={styles.container}>
        {
            Object.keys(characteristics).map(attr => {
                return <span key={`${id}${attr}`}>{attr}: {characteristics[attr]}</span>
            })
        }
    </div>
    )
}

export default CharacteristicsList