import React from "react"
import styles from "./Footer.module.css"
import Facebook from "./facebook.png"
import {Link} from "react-router-dom"
import supportConfig from "../../config/support"

function Footer ({}){
    return (
    <div className={styles.container}>
        <div className={styles.blocks}>
        <div className={styles.stack}>
            <div className='strong'>Get To Know Us</div>
            <div className={styles.spaced}><Link to="/about">About Shopdm</Link></div>
            <div className={styles.spaced}>Shopdm is a <a href="http://www.domsoftware.io">Dom Software</a> product</div>
            <div className='strong'>Dom Software</div>
            <div>{supportConfig.companyAddress.line1},</div>
            <div>{supportConfig.companyAddress.city},</div>
            <div className={styles.spaced}>{supportConfig.companyAddress.country}</div>
            <div className='strong'>Make Money With Us</div>
            <Link to="/how-selling-works">Sell your products online</Link>
            <Link to="/how-delivery-works">Deliver to your community</Link>
            <div className='strong'>Contact</div>
            <div>General inquiries ? Yes, we answer our emails 😊 - <a href={`mailto:${supportConfig.maintenanceEmail}?subject=Feedback On Shopdm`} target="_blank">{supportConfig.maintenanceEmail}</a></div>
            <div>Order updates or "how does it work" questions ? <a title="call" href={`tel:${supportConfig.operationsPhone}`}>{supportConfig.operationsPhone}</a></div>
            <div>Technical issues ? <a title="call" href={`tel:${supportConfig.maintenancePhone}`}>{supportConfig.maintenancePhone}</a></div>
            <div>Seller support ? <a title="call" href={`tel:${supportConfig.partnersPhone}`}>{supportConfig.partnersPhone}</a></div>
        </div>
        <div className={styles.stack}>
            <div className='strong'>Legal</div>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
            <Link to="/seller-terms-of-use">Partner Terms Of Use</Link>
            <Link to="/privacy">Consumer Data Privacy Policy</Link>
            <Link to="/refunds">Returns & Refund Policy</Link>
            <Link to="/delivery-policy">Delivery Policy</Link>
            <Link to="/security-policy">Security Policy</Link>
        </div>
        <div className={styles.stack}>
            <div className='strong'>Payment</div>
            <div className={styles.spaced}>We accept VISA and MasterCard</div>
            <div className={styles.visaMastercard}>
                <img className="providerLogo" src="https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2FpaymentProviderLogos%2Fvisamastercard%2Fvisa-mastercard.png?alt=media&token=1e23e638-a933-4ab7-af36-1459395113ed"/>
                <img className="providerLogo" src="https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2FpaymentProviderLogos%2Fvisamastercard%2FVerifiedByVisa80x48.jpg?alt=media&token=51a0a9ae-8eba-4760-89da-ed0de18f9f48"/>
                <img className="providerLogo" src="https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2FpaymentProviderLogos%2Fvisamastercard%2Fsc-mastercard-securecode66x48.png?alt=media&token=fb467261-077d-4b2d-a0f6-4d6fb4e69caa"/>
            </div>
        </div>
        </div>
        <div className={styles.bottom}>
            <div><a href='https://www.facebook.com/Shopdm-107717144270757'><img src={Facebook}/></a></div>
            <div>© {new Date().getFullYear()} Shopdm</div>
        </div>
    </div>
    )
}

export default Footer;