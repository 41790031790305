import {firebaseApp} from "../config/firebase"
import {getAnalytics, setUserId, setUserProperties} from "firebase/analytics"
import {getFirestore, writeBatch, doc, updateDoc, setDoc, deleteDoc, getDoc, increment, deleteField, onSnapshot} from "firebase/firestore"
import {getStorage, ref, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage"
import { getMessaging, deleteToken, isSupported} from "firebase/messaging";
import { 
    getAuth, 
    onAuthStateChanged, 
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut, 
    createUserWithEmailAndPassword, 
    sendPasswordResetEmail,
    GoogleAuthProvider,
} from "firebase/auth";
import { logError } from "../utils/errorHandlingUtils"
import { STATISTICS_IDENTIFIER } from '../constants/analysis'
import { USER_EVENTS_IDENTIFIER, USER_FOLLOWS_IDENTIFIER } from "../constants/social"

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const ADD_ADDRESS = 'ADD_ADDRESS'
export const SAVE_USER = 'SAVE_USER'
export const SET_ACTIVE_SELLER_ACCOUNT = 'SET_ACTIVE_SELLER_ACCOUNT'
export const SET_ACTIVE_DELIVERY_PROVIDER_ACCOUNT = 'SET_ACTIVE_DELIVERY_PROVIDER_ACCOUNT'
export const UPDATE_UNSUBSCRIBED = 'UPDATE_UNSUBSCRIBED'
export const SET_DEFAULT_USER_ADDRESS = 'SET_DEFAULT_USER_ADDRESS'
export const DELETE_USER_ADDRESS = 'DELETE_USER_ADDRESS'
export const EDIT_USER_ADDRESS = 'EDIT_USER_ADDRESS'
export const SUBSCRIBE = 'SUBSCRIBE'
export const SET_USER_PROFILE_IMAGE = 'SET_USER_PROFILE_IMAGE'
export const FOLLOW_SELLER = 'FOLLOW_SELLER'
export const UNFOLLOW_SELLER = 'UNFOLLOW_SELLER'
export const SAVE_USER_FOLLOWS = 'SAVE_USER_FOLLOWS'
export const SUBSCRIBE_USER_TO_EVENT = 'SUBSCRIBE_USER_TO_EVENT'
export const CREATE_REACTION = 'CREATE_REACTION'
export const DELETE_REACTION = 'DELETE_REACTION'

export const subscribe = subscriptionEmail => {
    return {
        type: SUBSCRIBE,
        payload: {
            subscriptionEmail
        }
    }
}

export const saveUser = user => {
    return {
        type: SAVE_USER,
        payload: {
            user
        }
    }
}

export const login = user => {
    return {
        type: LOGIN,
        payload: {
            user
        }
    }
}

export const logout = () => {
    return {
        type: LOGOUT
    }
}

export const addAddress = address => {
    return {
        type: ADD_ADDRESS,
        payload: {
            address
        }
    }    
}

export const setActiveSellerAccount = activeSellerAccountId => {
    return {
        type: SET_ACTIVE_SELLER_ACCOUNT,
        payload: {
            activeSellerAccountId
        }
    }    
}

export const setActiveDeliveryProviderAccount = activeDeliveryProviderAccountId => {
    return {
        type: SET_ACTIVE_DELIVERY_PROVIDER_ACCOUNT,
        payload: {
            activeDeliveryProviderAccountId
        }
    }    
}

export const updateUnsubscribed = unsubscribed => {
    return {
        type: UPDATE_UNSUBSCRIBED,
        payload: {
            unsubscribed
        }
    }
}

export const setDefaultUserAddress = addressId => {
    return {
        type: SET_DEFAULT_USER_ADDRESS,
        payload: {
            addressId
        }
    }
}

export const editUserAddress = (address) => {
    return {
        type: EDIT_USER_ADDRESS,
        payload: {
            address
        }
    }
}

export const deleteUserAddress = (addressId, defaultAddressId) => {
    return {
        type: DELETE_USER_ADDRESS,
        payload: {
            addressId,
            defaultAddressId
        }
    }
}

export const setUserProfileImage = (profileImageUrl, profileImageUrlMed, profileImageUrlSmall) => {
    return {
        type: SET_USER_PROFILE_IMAGE,
        payload: {profileImageUrl, profileImageUrlMed, profileImageUrlSmall}
    }
}

export const followSeller = (sellerId) => {
    return {
        type: FOLLOW_SELLER,
        payload: {sellerId}
    }
}

export const unfollowSeller = (sellerId) => {
    return {
        type: UNFOLLOW_SELLER,
        payload: {sellerId}
    }
}

export const saveUserFollows = (follows) => {
    return {
        type: SAVE_USER_FOLLOWS,
        payload: {follows}
    }
}

export const subscribeUserToEvent = (eventId) => {
    return {
        type: SUBSCRIBE_USER_TO_EVENT,
        payload: {eventId}
    }
}

export const createReaction = (reaction, reactionCount) => {
    return {
        type: CREATE_REACTION,
        payload: {
            reaction,
            reactionCount
        }
    }
}

export const deleteReaction = (reactionId, reactionType, userId, objectId, objectType, reactionCount) => {
    return {
        type: DELETE_REACTION,
        payload: {
            reactionId,
            reactionType,
            userId,
            objectId,
            objectType,
            reactionCount
        }
    }
}

export const fetchAddAddress = (
    id,
    line1, 
    line2, 
    city, 
    settlementId,
    countryName, 
    countryId, 
    contactNumber, 
    directions,
    usaCity,
    zipCode,
    onSuccess=()=>{},
    onError=()=> {}
) => {
    const address = {
        id,
        line1,
        line2,
        city,
        settlementId,
        countryName,
        countryId,
        contactNumber,
        directions,
        usaCity,
        zipCode
    }
    return async (dispatch, getState) => {
        try{
            const user = getState().user
            if (!user.authenticated) throw new Error("No user is logged in")
            const firestore = getFirestore(firebaseApp)
            const batch = writeBatch(firestore)
            const userRef = doc(firestore, 'users', user.id)
            batch.update(userRef, {
                defaultAddressId: id,        
                [`addressesById.${id}`]: address
            })
            const statsRef = doc(firestore, 'users', STATISTICS_IDENTIFIER);
            const incrementValue = increment(1);
            batch.update(statsRef, {
                [`addressCountBySettlementId.${settlementId}`]: incrementValue
            })
            await batch.commit()
            dispatch(addAddress(address))
            onSuccess(address)
            return true
        } catch(e){
            const message = `action > user > fetchAddAddress: Failed to add address ${line1}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(address)
            return false
        }
    }
}

export const fetchEditAddress = (
    id,
    line1, 
    line2, 
    city, 
    settlementId,
    countryName, 
    countryId, 
    contactNumber, 
    directions,
    usaCity,
    zipCode,
    onSuccess=()=>{},
    onError=()=> {}
) => {
    const address = {
        id,
        line1,
        line2,
        city,
        settlementId,
        countryName,
        countryId,
        contactNumber,
        directions,
        usaCity,
        zipCode,
    }
    return async (dispatch, getState) => {
        try{
            const user = getState().user
            if (!user.authenticated) throw new Error("No user is logged in")
            const firestore = getFirestore(firebaseApp)
            const batch = writeBatch(firestore)
            const userRef = doc(firestore, 'users', user.id)
            batch.update(userRef, {     
                [`addressesById.${id}`]: address
            })
            const previousAddress = user.addressesById[id] ? user.addressesById[id] : {}
            const statsRef = doc(firestore, 'users', STATISTICS_IDENTIFIER);
            const statsUpdate = {}
            if (previousAddress.settlementId !== settlementId){
                //if the edit includes a change to the settlement then update stats
                statsUpdate[`addressCountBySettlementId.${settlementId}`] = increment(1)
                if (previousAddress.settlementId) statsUpdate[`addressCountBySettlementId.${previousAddress.settlementId}`] = increment(-1)
            }
            batch.update(statsRef, statsUpdate)
            await batch.commit()
            dispatch(editUserAddress(address))
            onSuccess(address)
            return true
        } catch(e){
            const message = `action > user > fetchEditAddress: Failed to edit address ${line1}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(address)
            return false
        }
    }
}

export const fetchSetDefaultUserAddress = (
    id,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    return async (dispatch, getState) => {
        try{
            const user = getState().user
            const firestore = getFirestore(firebaseApp)
            const userRef = doc(firestore, 'users', user.id)
            if (!user.addressesById[id]) throw new Error(`No address matches address id ${id}`)
            await updateDoc(userRef, {defaultAddressId: id})
            dispatch(setDefaultUserAddress(id))
            onSuccess(id)
            return true
        } catch(e){
            const message = `action > user > fetchSetDefaultUserAddress: Failed to set address ${id} as default`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(id)
            return false
        }
    }
}

export const fetchSubscribe = (
    email,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    return async (dispatch, getState) => {
        try{
            const {device} = getState()
            const firestore = getFirestore(firebaseApp)
            const mailingListRef = doc(firestore, 'mailingList', email)
            await setDoc(mailingListRef, {
                email,
                deviceId: device.id,
                subscribedAt: Date.now()
            })
            dispatch(subscribe(email))
            onSuccess(email)
            return true
        } catch(e){
            const message = `action > user > fetchSubscribe: Failed to subscribe email ${email}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(email)
            return false
        }
    }
}

export const fetchDeleteUserAddress = (
    id,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    return async (dispatch, getState) => {
        try{
            const user = getState().user
            const firestore = getFirestore(firebaseApp)
            const userRef = doc(firestore, 'users', user.id)
            const addressIds = Object.keys(user.addressesById)
            if (addressIds.length === 1) throw new Error(`Cannot delete address ${id}, it is the user's only address`)
            const defaultAddressId = user.defaultAddressId === id ?
                                    addressIds.filter(addressId => addressId !== id )[0]
                                    :
                                    user.defaultAddressId
            const batch = writeBatch(firestore)
            batch.update(userRef, {
                defaultAddressId,        
                [`addressesById.${id}`]: deleteField()
            })
            const statsRef = doc(firestore, 'users', STATISTICS_IDENTIFIER);
            const decrement = increment(-1);
            const address = user.addressesById[id]
            batch.update(statsRef, {
                [`addressCountBySettlementId.${address.settlementId}`]: decrement
            })
            await batch.commit()
            dispatch(deleteUserAddress(id, defaultAddressId))
            onSuccess(id)
            return true
        } catch(e){
            const message = `action > user > fetchDeleteUserAddress: Failed to delete address ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(id)
            return false
        }
    }
}

async function fetchCreateUser(user, userId, deviceId ) {
    try {
        const firestore = getFirestore(firebaseApp);
        const batch = writeBatch(firestore);
        user.id = userId;
        //set the current device as one of the user's devices
        user.devices = {[deviceId]: true};
        const userRef = doc(firestore, "users", userId);
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth();
        const day = now.getDate();
        batch.set(userRef, {
            ...user,
            lastVisitedAt: Date.now(),
            visitCount: 1,
            visitCountsByMonth:{[`${year}-${month}`]: 1},
            lastOrderedAt: 0,
            orderCount: 0,
        });
        const statsRef = doc(firestore, "users", STATISTICS_IDENTIFIER);
        const incrementValue = increment(1);
        batch.update(statsRef, {
            [`count${year}`]: incrementValue,
            [`count${year}-${month}`]: incrementValue,
            [`count${year}-${month}-days.${day}`]: incrementValue,
        });
        const deviceRef = doc(firestore, "devices", deviceId);
        batch.update(deviceRef, {
            lastSignedInUserId: userId,
        });
        await batch.commit();
        const analytics = getAnalytics(firebaseApp);
        setUserId(analytics, userId);
        setUserProperties(analytics, {userId});
        return user;
    } catch (e) {
        const message = `action > user > fetchCreateUser: Failed to save user to database ${userId}`
        if (e.message_){
            //deal with firebase-specific errors
            logError(new Error(`${e.message} ${message}`))
        } else {
            e.message = `${e.message} ${message}`
            logError(e)
        }
        return false
    }
}

export const fetchCreateUserWithEmailAndPassword = (email, password, firstName, lastName, onSuccess=()=>{}, onError=()=>{}) => {
    /**
     * Propose: create a new user
     */
    const now = Date.now()
     const user = {
        email,
        firstName,
        lastName,
        isActive: true,
        createdAt: now,
        emailVerified: false,
        lastLogin: now,
        defaultAddressId: null,
        addressesById: {}
    }
    return async (dispatch, getState) => {
        try {
            const authenticated = await new Promise((resolve, reject) => {
                const auth = getAuth(firebaseApp);
                createUserWithEmailAndPassword(auth, email, password)
                .then((user) => resolve(user))
                .catch((err) => reject(err));
            });
            const {device} = getState();
            const createdUser =  await fetchCreateUser(
              user,
              authenticated.user.uid,
              device.id,
            );
            if (createdUser) {
                dispatch(login({...createdUser, authenticated: true}))
            } else {
                throw new Error(`Failed to create user ${authenticated.user.id}`);
            }
            onSuccess(createdUser)
            return true;
        } catch (e){
            const message = `action > user > fetchCreateUser: Failed to create user ${email}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(user, e)
            return false
        }
    }
} 

export const fetchLogout = () => {
    
    return async dispatch => {
        try {
            const supported = await isSupported()
            const messaging = supported ? getMessaging() : null;
            //delete messaging token, so next user of this device does not get this user's notifications
            if (messaging) deleteToken(messaging)
            await new Promise((resolve, reject) => {
                const auth = getAuth(firebaseApp)
                signOut(auth)
                    .then(() => resolve(true))
                    .catch((err) => reject(err));
            })
            dispatch(logout())
            return true
        } catch (e){
            const message = `action > user > fetchLogout: Failed to logout`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

async function fetchLogin(
  userId,
  deviceId,
  isGoogleLogin=false,
  lastLogin=Date.now()
) {
    try {
        const firestore = getFirestore(firebaseApp);
        const userRef = doc(firestore, "users", userId);
        const batch = writeBatch(firestore);
        const date = new Date()
        const update = {
            lastLogin,
            lastVisitedAt: Date.now(),
            visitCount: increment(1),
            visitCountsByMonth:{[`${ date.getFullYear()}-${date.getMonth()}`]: increment(1)},
            devices: {[deviceId]: true},
        }
        if (isGoogleLogin) update.lastGoogleLogin = lastLogin
        batch.set(
            userRef,
            update,
            {merge: true}
        );
        const deviceRef = doc(firestore, "devices", deviceId);
        batch.update(deviceRef, {
            lastSignedInUserId: userId,
        });
        await batch.commit();
        const docRef = await getDoc(userRef);
        const user = docRef.data();
        const analytics = getAnalytics(firebaseApp);
        setUserId(analytics, userId);
        setUserProperties(analytics, {userId: userId});
        return user;
    } catch (e) {
        const message = `action > user > fetchLogin: Failed to save user to database ${userId}`
        if (e.message_){
            //deal with firebase-specific errors
            logError(new Error(`${e.message} ${message}`))
        } else {
            e.message = `${e.message} ${message}`
            logError(e)
        }
        return false
    }
}

export const fetchLoginWithEmailAndPassword = (email, password, onSuccess=()=>{}, onError=()=>{}) => {
    /**
     * Purpose: log the user in and save his userId
     */
    return async (dispatch, getState) => {
        try {
            const authenticated = await new Promise((resolve, reject) => {
                const auth = getAuth(firebaseApp);
                signInWithEmailAndPassword(auth, email, password)
                    .then((user) => resolve(user))
                    .catch((err) => reject(err));
            });
            const {device} = getState();

            const loggedInUser = await fetchLogin(
                authenticated.user.uid,
                device.id
            );
            if (loggedInUser) {
                dispatch(login({...loggedInUser, authenticated: true}))
            } else {
                throw new Error(`Failed to log in user ${authenticated.user.id}`);
            }
            onSuccess(loggedInUser)
            return true;
        } catch (e){
            const message = `action > user > fetchLoginWithEmailAndPassword: Failed to login email ${email}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError({email, password, errorCode: e.code})
            return false
        }
    }
}

export const fetchCreateOrLoginUserWithGoogle = (
    onSuccess=()=>{},
    onError=()=>{}
) =>  {
    /**
     * Propose: create or login a user using Google
     */
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    return async (dispatch, getState) => {
        try {
            const result = await signInWithPopup(auth, provider);
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const {device} = getState();
            // Google User
            const googleUser = result.user;

            const firestore = getFirestore(firebaseApp);
            const userRef = doc(firestore, 'users', googleUser.uid);
            const docRef = await getDoc(userRef);

            if (docRef.exists()) {
                const loggedInUser = await fetchLogin(googleUser.uid, device.id, true);
                if (loggedInUser) {
                    dispatch(login({...loggedInUser, authenticated: true}))
                    onSuccess(loggedInUser)
                    return true;
                } else {
                    throw new Error(`Failed to log in user ${googleUser.uid}`);
                }
            } else {
                // ShopDM user formatted from google User
                const [firstName, lastName] = googleUser.displayName.toLowerCase().match(/^(\S+)\s(.*)/) ? //if the user has a display name
                                              googleUser.displayName.toLowerCase().match(/^(\S+)\s(.*)/).slice(1) //split it on the first space and give it to us in 2 pieces for firstname lastname
                                              : 
                                              [googleUser.email, googleUser.email] //in the rare case that they dont have a display name, give us their email twice
                const user = {
                    email: googleUser.email,
                    firstName,
                    lastName,
                    isActive: true,
                    createdAt: Number(googleUser.metadata.lastLoginAt),
                    emailVerified: true,
                    lastLogin: Number(googleUser.metadata.lastLoginAt),
                    defaultAddressId: null,
                    addressesById: {},
                    lastGoogleLogin: Number(googleUser.metadata.lastLoginAt)
                };
                const createdUser = await fetchCreateUser(
                    user,
                    googleUser.uid,
                    device.id,
                );
                if (createdUser) {
                    dispatch(login({...createdUser, authenticated: true}))
                } else {
                    throw new Error(`Failed to create user ${googleUser.uid}`);
                }
                onSuccess(createdUser)
                return true;
            }

        } catch (e) {
            const message = `action > user > fetchCreateOrLoginUserWithGoogle: Failed to create Google user`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(e)
            dispatch(logout())
            return false
        }
    }
}

export const trackUserSession = () => {
    return async (dispatch, getState) => {
        try {
            const auth = getAuth(firebaseApp)
            const sessionListener = onAuthStateChanged(auth, function(authenticated) {
                console.log("firing onAuthStateChanged, value is ", authenticated)
                console.log("auth value is ", auth)
                if (authenticated) {
                    setTimeout(async () => {
                        const {user} = getState()
                        if (
                            !user.authenticated && 
                            user.lastLogin === 0 &&
                            authenticated.uid
                            
                        ){
                            const firestore = getFirestore(firebaseApp)
                            const userRef = doc(firestore, 'users', authenticated.uid)
                            const docRef = await getDoc(userRef)
                            const user = docRef.data()
                            dispatch(login({...user, authenticated: true}))
                        } 
                    }, 1000)
                } else {
                    //if the user has been logged in for more than a second locally
                    //but they are not authenticated on the server
                    console.log("auth state is null")
                    setTimeout(() => {
                        const {user} = getState()
                        if (
                            user.authenticated && 
                            user.lastLogin !== 0 &&
                            user.lastLogin < (Date.now() - 1000)
                        ){
                            logError(`auto logout of user, because auth state is ${JSON.stringify(authenticated)}`)
                            dispatch(logout())
                        }
                    }, 1000)
                }
            });
            return sessionListener
        } catch (e) {
            const message = `action > user > trackUserSession: Failed to track user session`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSaveUser = userId => {
    /**
     * Purpose: save the specified user
     */
    return async dispatch => {
        try {   
            const firestore = getFirestore(firebaseApp)
            const userRef = doc(firestore, 'users', userId)
            const userListener = await onSnapshot(userRef,
                docRef => {
                    const user = docRef.data()
                    dispatch(saveUser(user))
                })
            return userListener
        } catch(e){
            const message = `action > user > fetchSaveUser: Failed to save user ${userId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSetActiveSellerAccount = (userId, activeSellerAccountId) => {
    /**
     * Purpose: set the active seller account on the current user
     */
    return async dispatch => {
        try {   
            const firestore = getFirestore(firebaseApp)
            const userRef = doc(firestore, 'users', userId)
            await updateDoc(userRef, {activeSellerAccountId})
            dispatch(setActiveSellerAccount(activeSellerAccountId))
            return true
        } catch(e){
            const message = `action > user > fetchSetActiveSellerAccount: Failed to set active seller account ${activeSellerAccountId} on user ${userId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSetActiveDeliveryProviderAccount = (userId, activeDeliveryProviderAccountId) => {
    /**
     * Purpose: set the active delivery provider account on the current user
     */
    return async dispatch => {
        try {   
            const firestore = getFirestore(firebaseApp)
            const userRef = doc(firestore, 'users', userId)
            await updateDoc(userRef, {activeDeliveryProviderAccountId})
            dispatch(setActiveDeliveryProviderAccount(activeDeliveryProviderAccountId))
            return true
        } catch(e){
            const message = `action > user > fetchSetActiveDeliveryProviderAccount: Failed to set active delivery provider account ${activeDeliveryProviderAccountId} on user ${userId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchResetPassword = (email, onSuccess=()=>{}, onError=()=>{}) => {
    return async dispatch => {
        try {
             await new Promise((resolve, reject) => {
                const auth = getAuth(firebaseApp)
                sendPasswordResetEmail(auth, email,{
                    url: `https://shopdm.store/login`
                })
                    .then(() => resolve())
                    .catch((err) => reject(err));
            })
            onSuccess(email)
            return true
        } catch(e) {
            const message = `action > user > fetchResetPassword: Failed to send reset password email to ${email}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(email, e.message)
            return false
        }
    }
}

export const fetchUpdateUnsubscribed = (id, unsubscribed, onSuccess) => {
    return async dispatch => {
        try {   
            const firestore = getFirestore(firebaseApp)
            const userRef = doc(firestore, 'users', id)
            await updateDoc(userRef, {unsubscribed})
            dispatch(updateUnsubscribed(unsubscribed))
            onSuccess()
            return true
        } catch(e){
            const message = `action > user > fetchUpdateUnsubscribed: Failed to set user unsubscribed to ${unsubscribed} on user ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSetProfileImage = (
    id,
    imageFile,
    imageFileMed, 
    imageFileSmall,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const userRef = doc(firestore, 'users', id)
    return async (dispatch) => {
        try{
            let profileImageUrl
            let profileImageUrlMed
            let profileImageUrlSmall
            //upload the image
            if (!imageFile.url){ //if this is not a non-upload case where a url is provided
                const storage = getStorage(firebaseApp)
                const imageRef = ref(storage, `users/${id}/profile`)
                await uploadBytes(imageRef, imageFile)
                const imageRefMed = ref(storage, `users/${id}/profile_med`)
                await uploadBytes(imageRefMed, imageFileMed)
                const imageRefSmall = ref(storage, `users/${id}/profile_small`)
                await uploadBytes(imageRefSmall, imageFileSmall)
                //get the image url
                profileImageUrl = await getDownloadURL(imageRef)
                profileImageUrlMed = await getDownloadURL(imageRefMed)
                profileImageUrlSmall = await getDownloadURL(imageRefSmall)
            } 
            //otherwise just take the actual url provided
            else {
                profileImageUrl = imageFile.url
                profileImageUrlMed = imageFile.url
                profileImageUrlSmall = imageFile.url
            }
            await updateDoc(userRef, {profileImageUrl,profileImageUrlMed, profileImageUrlSmall})
            dispatch(setUserProfileImage(profileImageUrl, profileImageUrlMed, profileImageUrlSmall))
            onSuccess(profileImageUrl, profileImageUrlMed, profileImageUrlSmall)
            return true
        } catch (e){
            const message = `action > users > fetchSetProfileImage: Failed to set user ${id} profile image`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(imageFile)
            return false
        }
        
    }
}

export const fetchDeleteProfileImage = (
    id,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const userRef = doc(firestore, 'users', id)
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            const storage = getStorage(firebaseApp)
            if (user.profileImageUrl){
                const imageRef = ref(storage, user.profileImageUrl)
                await deleteObject(imageRef)
            }
            if (user.profileImageUrlMed){
                const imageRef = ref(storage, user.profileImageUrlMed)
                await deleteObject(imageRef)
            }
            if (user.profileImageUrlSmall){
                const imageRef = ref(storage, user.profileImageUrlSmall)
                await deleteObject(imageRef)
            }
            await updateDoc(userRef, {profileImageUrl: "", profileImageUrlMed: "", profileImageUrlSmall: ""})
            dispatch(setUserProfileImage("", "", ""))
            return true
        } catch (e){
            const message = `action > users > fetchDeleteProfileImage: Failed to delete user ${id} profile image`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(id)
            return false
        }
        
    }
}

export const fetchSubscribeUserToEvent = (
    id,
    eventId,
    deviceId,
    messagingToken,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const userEventsRef = doc(firestore, `users/${id}/social`, USER_EVENTS_IDENTIFIER)
    return async (dispatch, getState) => {
        try {
            const {user} = getState()
            if (!(user.events && user.events[eventId])) {
                await setDoc(userEventsRef, {
                    events: {[eventId]: {
                        subscribedAt: Date.now(),
                        id: eventId,
                        devices: {
                            [deviceId]: messagingToken
                        }
                    }}
                }, {merge: true})
                dispatch(subscribeUserToEvent(eventId))
            }
            return true
        } catch (e) {
            const message = `action > user > fetchSubscribeUserToEvent: Failed to subscribe user ${id} to event ${eventId} alerts with device ${deviceId} using token ${messagingToken}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}

export const fetchFollowSeller = (
    id,
    sellerId,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const userFollowsRef = doc(firestore, `users/${id}/social/`, USER_FOLLOWS_IDENTIFIER)
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            if (!(user.follows && user.follows[sellerId])) {
                await setDoc(userFollowsRef, {
                    follows: {[sellerId]: Date.now()}
                }, {merge: true})
                dispatch(followSeller(sellerId))
            }
            return true
        } catch (e){
            const message = `action > users > fetchFollowSeller: Failed to follow seller ${sellerId} with user ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(id)
            return false
        }
        
    }
}

export const fetchUnfollowSeller = (
    id,
    sellerId,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const userFollowsRef = doc(firestore, `users/${id}/social/`, USER_FOLLOWS_IDENTIFIER)
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            await setDoc(userFollowsRef, {
                follows: {[sellerId]: deleteField()}
            }, {merge: true})
            dispatch(unfollowSeller(sellerId))
            return true
        } catch (e){
            const message = `action > users > fetchUnfollowSeller: Failed to unfollow seller ${sellerId} with user ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(id)
            return false
        }
        
    }
}

export const fetchSaveUserFollows = (
    id,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const userFollowsRef = doc(firestore, `users/${id}/social/`, USER_FOLLOWS_IDENTIFIER)   
    return async (dispatch) => {
        try{
            const docRef = await getDoc(userFollowsRef)
            const follows = docRef.data()
            dispatch(saveUserFollows(follows && follows.follows ? follows.follows : {}))
            return true
        } catch (e){
            if (!e.message.includes("Cannot read properties of undefined (reading 'follows')")){
                //only log error if it is not because the user has not yet followed any sellers
                const message = `action > users > fetchSaveUserFollows: Failed to save follows for user ${id}`
                if (e.message_){
                    //deal with firebase-specific errors
                    logError(new Error(`${e.message} ${message}`))
                } else {
                    e.message = `${e.message} ${message}`
                    logError(e)
                }
            } else console.warn ("log error blocked since the user has not yet followed any sellers")
            onError(id)
            return false
        }
        
    }
}

export const fetchCreateReaction = (
    id,
    reactionType,
    entityId,
    entityType,
    objectId,
    objectType,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const reaction = {
        id: `${objectId}.${id}`, //create a deterministic id so the user can never double-like
        reactionType,
        objectId,
        objectType,
        entityId,
        entityType,
        reactedAt: Date.now()
    }
    const userReactionsRef = doc(firestore, `users/${id}/reactions/${reaction.id}`)
    return async (dispatch, getState) => {
        try{
            const {user, reactions} = getState()
            const currentReactions = reactions.reactionsByObjectId[objectId] ? 
                                    {...reactions.reactionsByObjectId[objectId].reactions}
                                    :
                                    {}
            for (let userId in currentReactions){
                const userReaction = currentReactions[userId]
                if (userReaction.reactionType !== reactionType) delete currentReactions[userId]
            }
            currentReactions[user.id] = true
            reaction.userId = user.id
            reaction.userName = `${user.firstName} ${user.lastName}`
            reaction.profileImageUrl = user.profileImageUrlSmall ? user.profileImageUrlSmall : ""
            await setDoc(userReactionsRef, reaction)
            dispatch(createReaction(reaction, Object.keys(currentReactions).length))
            onSuccess(reaction)
            return true
        } catch (e){
            const message = `action > users > fetchCreateReaction: Failed to create a reaction for user ${id}, ${JSON.stringify(reaction)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(id)
            return false
        }
        
    }
}

export const fetchDeleteReaction = (
    id,
    objectId,
    objectType,
    reactionType,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const reactionId = `${objectId}.${id}`
    const userReactionsRef = doc(firestore, `users/${id}/reactions/${reactionId}`)
    return async (dispatch, getState) => {
        try{
            const {user, reactions} = getState()
            const currentReactions = reactions.reactionsByObjectId[objectId] ? 
                                    {...reactions.reactionsByObjectId[objectId].reactions}
                                    :
                                    {}
            for (let userId in currentReactions){
                const userReaction = currentReactions[userId]
                if (userReaction.reactionType !== reactionType) delete currentReactions[userId]
            }
            delete currentReactions[user.id]
            await deleteDoc(userReactionsRef)
            dispatch(deleteReaction(reactionId, reactionType, id, objectId, objectType, Object.keys(currentReactions).length))
            onSuccess()
            return true
        } catch (e){
            const message = `action > users > fetchDeleteReaction: Failed to delete reaction ${reactionType} in ${reactionId} for user ${id} object ${objectId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(id, objectId)
            return false
        }
        
    }
}

export const fetchSetLastVisitedAt = (userId) => {
    /**
     * Purpose: log the last time the user visited the app
     */
    return async (dispatch, getState) => {
        try {   
            const {user} = getState()
            if (!user || !user.id || !user.authenticated || user.id !== userId) return false
            const firestore = getFirestore(firebaseApp)
            const userRef = doc(firestore, 'users', user.id)
            const date = new Date()
            await setDoc(userRef, {
                lastVisitedAt: Date.now(),
                visitCount: increment(1),
                visitCountsByMonth:{[`${ date.getFullYear()}-${date.getMonth()}`]: increment(1)},
            }, {merge: true})
            return true
        } catch(e){
            const message = `action > user > fetchSetLastVisitedAt: Failed to last visited at on user $`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}