import React from "react"
import styles from "./DisasterBanner.module.css"

const DisasterBanner = () => {
    return(
    <div className={styles.container}>
        <div><span>11AM Day-Before Hurricane Delivery Policy</span> To guarantee delivery, please place orders by 11AM on the day before a hurricane is scheduled to arrive in Dominica.</div>
        <div>On the actual day, delivery agents may be unavailable and stores may be overloaded. Road and utility damage may also disrupt deliveries after the hurricane so check back here to see when we are back up and running!</div>
    </div>
    )
}

export default DisasterBanner