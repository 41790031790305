import React from "react"
import TopBar from "../../components/TopBar"
import Footer from "../../containers/Footer"
import ResetPasswordForm from "../../containers/ResetPasswordForm"
import {Helmet} from "react-helmet";

function ResetPassword({history}){
    return (
        <div className={"screen"}>
            <Helmet>
                <title>Reset Password</title>
            </Helmet>
            <TopBar title="Reset Password"/>
            <div className={["searchBarPresent", "mainContent", 'authScreen', 'showcase'].join(" ")}>
                <ResetPasswordForm redirectTo="/"/>
            </div>
            <Footer />
        </div>
    )
}

export default ResetPassword;