import React from 'react'
import PropTypes from "prop-types"
import SideBarOption from "../../components/SideBarOption"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {withRouter} from "react-router-dom"
import Icon from "../../components/Icon"

import {alphabeticalSort} from "../../utils/stringUtils"

import {CATEGORY_OPTION_TYPE} from "../../constants/firebase"

function CategoryList({sellers, categories, history, actions, postClick=()=>{}}){
    const handleClick = path => {
        actions.updateQueryString("")
        history.push(path)
        postClick()
    }

    const categoryList = categories.categoryIds.map(categoryId => categories.categoriesById[categoryId])
                                        .sort((categoryA, categoryB) => alphabeticalSort(categoryA.label, categoryB.label))
    return(
        <div >
            <p className="strong navigationListHeader"><Icon icon="reorder"/> Categories ({categoryList.length})</p>
            <div className="navigationList">
            {
                categoryList.map(category => {
                    return <SideBarOption 
                                key={category.id}
                                optionId={category.id}
                                label={category.label}
                                type={CATEGORY_OPTION_TYPE}
                                onClick={handleClick}
                            />
                })
            }
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    categories: state.categories
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CategoryList));