/**
 *  Purpose: the modifications that are carried on the Payee objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // payees:{
 //   payeesById:{},
 //   payeeIdByHandleId: {},
 //   feeSettingsByPayeeId: {},
 //   rolesByPayeeId: {},
 //   lastLoadedAt: 0
 // }
 
 
 const payeesReducer = (state = initialState.payees, action) => {
     const {type, payload} = action;
     let payeesById = {...state.payeesById}
     let payeeIdByHandleId = {...state.payeeIdByHandleId}
     let rolesByPayeeId = {...state.rolesByPayeeId}
     let feeSettingsByPayeeId = {...state.feeSettingsByPayeeId}
     switch (type){
         case types.SAVE_PAYEES: {
             if(typeof payload.payees !== "object") {
                 logError(`payeesReducer > SAVE_PAYEES: payees payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.payees.forEach(payee => {
                payeesById[payee.id] = payee
                payeeIdByHandleId[payee.handleId] = payee.id
             });
             return {
                 ...state,
                 payeesById,
                 payeeIdByHandleId,
                 lastLoadedAt: payload.isLoad ? Date.now() : state.lastLoadedAt
             }
        }
        
        case types.SAVE_PAYEE_FEE_SETTINGS: {
            if(typeof payload.feeSettings !== "object") {
                logError(`payeesReducer > SAVE_PAYEE_FEE_SETTINGS: feeSettings payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.payeeId !== "string") {
                logError(`payeesReducer > SAVE_PAYEE_FEE_SETTINGS: payeeId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                feeSettingsByPayeeId: {
                    ...feeSettingsByPayeeId,
                    [payload.payeeId] : payload.feeSettings
                }
            }
        }
        case types.CREATE_PAYEE: {
            if(typeof payload.payee !== "object") {
                logError(`payeesReducer > CREATE_PAYEE: payee in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }

            return {
                ...state,
                payeesById: {
                    ...payeesById,
                    [payload.payee.id]: payload.payee
                },
            }
        }

        case types.EDIT_PAYEE: {
            if(typeof payload.payeeId !== "string") {
                logError(`payeesReducer > EDIT_PAYEE: payeeId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.edits !== "object") {
                logError(`payeesReducer > EDIT_PAYEE: edits in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if (!payeesById[payload.payeeId]){
                logError(`payeesReducer > EDIT_PAYEE: payeeId ${payload.payeeId} does not exist`)
                return state
            }
            return {
                ...state,
                payeesById: {
                    ...payeesById,
                    [payload.payeeId]: {
                        ...payeesById[payload.payeeId],
                        ...payload.edits
                    }
                },
            }
        }

        case types.SAVE_PAYEE_ROLES: {
            if(typeof payload.roles !== "object") {
                logError(`payeesReducer > SAVE_PAYEE_ROLES: roles payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.payeeId !== "string") {
                logError(`payeesReducer > SAVE_PAYEE_ROLES: payeeId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                rolesByPayeeId: {
                    ...rolesByPayeeId,
                    [payload.payeeId] : payload.roles
                }
            }
        }

         default: return state
     }
 }
 
 export default payeesReducer