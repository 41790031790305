import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, writeBatch, query, collection, getDocs, onSnapshot, where, updateDoc, setDoc} from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"
import { LOCATION_TYPE_CURRENT_LOCATION } from "../constants/location";
import {PAYOUT_METHOD_BANK_DEPOSIT} from "../constants/payouts"
import {DELIVERY_VEHICLE_DRIVETRAIN_DEFAULTS, DELIVERY_VEHICLE_CAPACITY} from "../constants/delivery"
export const SAVE_DELIVERY_AGENTS = 'SAVE_DELIVERY_AGENTS'
export const UPDATE_DELIVERY_AGENT_IS_ACTIVE = 'UPDATE_DELIVERY_AGENT_IS_ACTIVE'
export const UPDATE_DELIVERY_AGENT_DELIVERY_AREAS = 'UPDATE_DELIVERY_AGENT_DELIVERY_AREAS'
export const UPDATE_DELIVERY_AGENT_SCHEDULED_TRIPS = 'UPDATE_DELIVERY_AGENT_SCHEDULED_TRIPS'
export const UPDATE_DELIVERY_AGENT_VEHICLES = 'UPDATE_DELIVERY_AGENT_VEHICLES'

export const saveDeliveryAgents = deliveryAgents => {
    return {
        type: SAVE_DELIVERY_AGENTS,
        payload: {
            deliveryAgents
        }
    }
}

export const updateDeliveryAgentIsActive = (deliveryAgentId, isActive) => {
    return {
        type: UPDATE_DELIVERY_AGENT_IS_ACTIVE,
        payload: {
            deliveryAgentId,
            isActive
        }
    }
}

export const updateDeliveryAgentDeliveryAreas = (
    deliveryAgentId, 
    deliveryAreaIdList, 
    previousDeliveryAreaIdList, 
    scheduledTripsById
) => {
    return {
        type: UPDATE_DELIVERY_AGENT_DELIVERY_AREAS,
        payload: {
            deliveryAgentId,
            deliveryAreaIdList,
            previousDeliveryAreaIdList,
            scheduledTripsById
        }
    }
}

export const updateDeliveryAgentScheduledTrips = (
    deliveryAgentId,
    scheduledTripsById,
    previousScheduledTripsById,
    deliveryAgent
) => {
    return {
        type: UPDATE_DELIVERY_AGENT_SCHEDULED_TRIPS,
        payload: {
            deliveryAgentId,
            scheduledTripsById,
            previousScheduledTripsById,
            deliveryAgent
        }
    }
}

export const updateDeliveryAgentVehicles = (
    deliveryAgentId,
    vehiclesById,
    defaultVehicleId
) => {
    return {
        type: UPDATE_DELIVERY_AGENT_VEHICLES,
        payload: {
            deliveryAgentId,
            vehiclesById,
            defaultVehicleId
        }
    }
}

export const fetchSaveDeliveryAgents = () => {
    const firestore = getFirestore(firebaseApp);
    const deliveryAgentsRef = query(collection(firestore, "deliveryAgents"))
    return async dispatch =>{
        try {
            const querySnapshot = await getDocs(deliveryAgentsRef)
            //get an array of delivery agents from the snapshot
            const deliveryAgents = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(saveDeliveryAgents(deliveryAgents))
            return true
        } catch (e){
            const message = `action > deliveryAgents > fetchSaveDeliveryAgents: Failed to save delivery agents`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSubscribeToDeliveryAgents = () => {
    /**
      * Purpose: retrieve the delivery agents from the firestore database
      * Note: the onSnapshot below watches for changes to the delivery agents on the server
      */
    const firestore = getFirestore(firebaseApp)
    const deliveryAgentsRef = query(collection(firestore, "deliveryAgents"))
    return async dispatch => {
        try {
            const deliveryAgentsListener = await onSnapshot(deliveryAgentsRef,
                querySnapshot => {
                    //get an array of products from the snapshot
                    const deliveryAgents = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveDeliveryAgents(deliveryAgents))
            })
            return deliveryAgentsListener
        } catch (e){
            const message = `action > deliveryAgents > fetchSubscribeToDeliveryAgents: Failed to save delivery agents`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSubscribeToAreaDeliveryAgents = (
    areaId
) => {
    /**
      * Purpose: retrieve the delivery agents from the firestore database
      * Note: the onSnapshot below watches for changes to the delivery agents on the server
      *       who serve a particular area
      */
    const firestore = getFirestore(firebaseApp)
    const deliveryAgentsRef = query(
                                    collection(firestore, "deliveryAgents"),
                                    where("deliveryAreaIdList", "array-contains", areaId)
                              )
    return async dispatch => {
        try {
            const deliveryAgentsListener = await onSnapshot(deliveryAgentsRef,
                querySnapshot => {
                    //get an array of products from the snapshot
                    const deliveryAgents = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveDeliveryAgents(deliveryAgents))
            })
            return deliveryAgentsListener
        } catch (e){
            const message = `action > deliveryAgents > fetchSubscribeToAreaDeliveryAgents: Failed to subscribe to delivery agents for area ${areaId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchCreateDeliveryAgent = (
    id, 
    firstName,
    lastName,
    nickName,
    contactNumbers,
    defaultContactNumber,
    agentType,
    roles,
    deliveryAreaIdList,
    scheduledTripsById,
    defaultVehicleId,
    vehiclesById,
    payoutMethod,
    bankAccount=null,
    countryId,
    deliveryProviderId,
    managedByEntityId,
    managedByEntityType,
    numberOfCompletedDeliveries,
    customerRating,
    avgDropoffLateness,
    isActive=true, 
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const deliveryAgent = {
        id, 
        firstName,
        lastName,
        nickName,
        contactNumbers,
        defaultContactNumber,
        agentType,
        roles,
        deliveryAreaIdList,
        scheduledTripsById,
        defaultVehicleId,
        vehiclesById,
        payoutMethod,
        bankAccountId: payoutMethod === PAYOUT_METHOD_BANK_DEPOSIT && 
                        bankAccount && bankAccount.id ? 
                        bankAccount.id 
                        : 
                        "",
        countryId,
        deliveryProviderId,
        managedByEntityId,
        managedByEntityType,
        numberOfCompletedDeliveries,
        customerRating,
        avgDropoffLateness,
        isActive, 
        createdAt: Date.now(),
    }
    return async (dispatch, getState) => {
        try{ 
            const {user} = getState()
            deliveryAgent.createdByUserId = user.id
            const batch = writeBatch(firestore)
            const deliveryAgentsRef = doc(firestore, "deliveryAgents", id)
            batch.set(deliveryAgentsRef, deliveryAgent)
            const locationHistoryRef = doc(firestore, `deliveryAgents/${id}/locationHistory`, LOCATION_TYPE_CURRENT_LOCATION)
            batch.set(locationHistoryRef, {
                id: LOCATION_TYPE_CURRENT_LOCATION,
                latLon: {},
                locationLastUpdatedAt: 0,
            })
            if (payoutMethod === PAYOUT_METHOD_BANK_DEPOSIT && bankAccount){
                const bankAccountRef = doc(firestore, `deliveryAgents/${id}/accounts`, bankAccount.id)
                batch.set(bankAccountRef, bankAccount)
            }
            await batch.commit()
            dispatch(saveDeliveryAgents([deliveryAgent]))
            onSuccess(deliveryAgent)
            return deliveryAgent
        } catch (e){
            const message = `action > deliveryAgents > fetchCreateDeliveryAgent: Failed to create delivery agent ${JSON.stringify(deliveryAgent)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(deliveryAgent)
            return false
        }
        
    }
}

export const fetchCreateBulkDeliveryAgents = (
    deliveryAgents = [],
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) => {
        try{ 
            const {user} = getState()
            //loop through batches to avoid hitting the 500 write firebase limit
            const batchArray = [writeBatch(firestore)]
            const batchLimit = 400 //after this many operations on the batch, a new batch will be added and used
            let batchIndex = 0
            let operationCount = 0
            for(let i = 0; i < deliveryAgents.length; i++){
                const deliveryAgent = deliveryAgents[i]
                const {id} = deliveryAgent
                deliveryAgent.createdByUserId = user.id
                deliveryAgent.createdAt = Date.now()
                delete deliveryAgent.deliveryAreasById
                const bankAccount = deliveryAgent.bankAccount ? {...deliveryAgent.bankAccount} : null
                delete deliveryAgent.bankAccount 
                delete deliveryAgent.closedDays 
                const batch = batchArray[batchIndex]
                const deliveryAgentsRef = doc(firestore, "deliveryAgents", id)
                batch.set(deliveryAgentsRef, deliveryAgent)
                const locationHistoryRef = doc(firestore, `deliveryAgents/${id}/locationHistory`, LOCATION_TYPE_CURRENT_LOCATION)
                batch.set(locationHistoryRef, {
                    id: LOCATION_TYPE_CURRENT_LOCATION,
                    latLon: {},
                    locationLastUpdatedAt: 0,
                })
                if (bankAccount){
                    const bankAccountRef = doc(firestore, `deliveryAgents/${id}/accounts`, bankAccount.id)
                    batch.set(bankAccountRef, bankAccount)
                }
                operationCount += 3
                if (operationCount >= batchLimit) {
                    batchArray.push(writeBatch(firestore));
                    batchIndex++;
                    operationCount = 0;
                }
            }
            for (let i in batchArray) {
                const batch = batchArray[i]
                await batch.commit()
                console.log(`saved batch `, i)
            } 
            dispatch(saveDeliveryAgents(deliveryAgents))
            onSuccess(deliveryAgents)
            return deliveryAgents
        } catch (e){
            const message = `action > deliveryAgents > fetchCreateBulkDeliveryAgents: Failed to create delivery agents`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(deliveryAgents)
            return false
        }
        
    }   
}

export const fetchUpdateDeliveryAgentIsActive = (
    id,
    isActive,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const deliveryAgentsRef = doc(firestore, "deliveryAgents", id)
    const update = {isActive}
    return async (dispatch, getState) =>{
        try {
            const {user, deliveryAgents} = getState()
            const deliveryAgent = {...deliveryAgents.deliveryAgentsById[id]}
            if (!deliveryAgent || !deliveryAgent.id) throw new Error(`The id ${id} does not correspond to an actual deliver provider`)
            update.lastEditedAt = Date.now()
            update.lastEditedByUserId = user.id
            await updateDoc(deliveryAgentsRef, update)
            dispatch(updateDeliveryAgentIsActive(id, isActive))
            onSuccess(update)
            return true
        } catch (e){
            const message = `action > deliveryAgents > fetchUpdateDeliveryAgentIsActive: Failed to update the delivery agent isActive for ${id} to ${isActive}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateDeliveryAgentDeliveryAreas = (
    id,
    deliveryAreaIdList,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const deliveryAgentsRef = doc(firestore, "deliveryAgents", id)
    const update = {deliveryAreaIdList}
    return async (dispatch, getState) =>{
        try {
            const {user, deliveryAgents} = getState()
            const deliveryAgent = {...deliveryAgents.deliveryAgentsById[id]}
            if (!deliveryAgent || !deliveryAgent.id) throw new Error(`The id ${id} does not correspond to an actual deliver provider`)
            update.lastEditedAt = Date.now()
            update.lastEditedByUserId = user.id
            await updateDoc(deliveryAgentsRef, update)
            dispatch(
                updateDeliveryAgentDeliveryAreas(
                    id, 
                    deliveryAreaIdList, 
                    deliveryAgent.deliveryAreaIdList, 
                    deliveryAgent.scheduledTripsById
                )
            )
            onSuccess(update)
            return true
        } catch (e){
            const message = `action > deliveryAgents > fetchUpdateDeliveryAgentDeliveryAreas: Failed to update the areas of delivery agent ${id} to ${JSON.stringify(deliveryAreaIdList)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateDeliveryAgentScheduledTrips = (
    id,
    scheduledTripsById={},
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const deliveryAgentsRef = doc(firestore, "deliveryAgents", id)
    const update = {scheduledTripsById}
    return async (dispatch, getState) =>{
        try {
            const {user, deliveryAgents} = getState()
            const deliveryAgent = {...deliveryAgents.deliveryAgentsById[id]}
            if (!deliveryAgent || !deliveryAgent.id) throw new Error(`The id ${id} does not correspond to an actual deliver provider`)
            update.lastEditedAt = Date.now()
            update.lastEditedByUserId = user.id
            const previousScheduledTripsById = {...deliveryAgent.scheduledTripsById}
            await updateDoc(deliveryAgentsRef, update)
            dispatch(
                updateDeliveryAgentScheduledTrips(
                    id, 
                    scheduledTripsById, 
                    previousScheduledTripsById,
                    deliveryAgent
                )
            )
            onSuccess(update)
            return true
        } catch (e){
            const message = `action > deliveryAgents > fetchUpdateDeliveryAgentScheduledTrips: Failed to update the scheduled trips of delivery agent ${id} to ${JSON.stringify(scheduledTripsById)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateDeliveryAgentVehicles = (
    id,
    vehiclesById={},
    defaultVehicleId="",
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const deliveryAgentsRef = doc(firestore, "deliveryAgents", id)
    const update = {vehiclesById, defaultVehicleId}
    return async (dispatch, getState) =>{
        try {
            const {user, deliveryAgents} = getState()
            const deliveryAgent = {...deliveryAgents.deliveryAgentsById[id]}
            if (!deliveryAgent || !deliveryAgent.id) throw new Error(`The id ${id} does not correspond to an actual deliver provider`)
            update.lastEditedAt = Date.now()
            update.lastEditedByUserId = user.id
            await updateDoc(deliveryAgentsRef, update)
            dispatch(
                updateDeliveryAgentVehicles(
                    id, 
                    vehiclesById,
                    defaultVehicleId
                )
            )
            onSuccess(update)
            return true
        } catch (e){
            const message = `action > deliveryAgents > fetchUpdateDeliveryAgentVehicles: Failed to update the vehicles of delivery agent ${id} to ${JSON.stringify(vehiclesById)} and the default to ${defaultVehicleId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchBulkUpdateDeliveryAgentVehiclesAndClosedDays = (
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) =>{
        try {
            const {user, deliveryAgents} = getState()
            for(let id in deliveryAgents.deliveryAgentsById){
                const deliveryAgent = {...deliveryAgents.deliveryAgentsById[id]}
                if (!deliveryAgent || !deliveryAgent.id) throw new Error(`The id ${id} does not correspond to an actual deliver provider`)
                deliveryAgent.lastEditedAt = Date.now()
                deliveryAgent.lastEditedByUserId = user.id
                delete deliveryAgent['closedDays']
                Object.values(deliveryAgent.vehiclesById).forEach(vehicle => {
                    vehicle = {
                        ...vehicle,
                        color: vehicle.color ? vehicle.color : "",
                        drivetrain: vehicle.drivetrain ? vehicle.drivetrain : DELIVERY_VEHICLE_DRIVETRAIN_DEFAULTS[vehicle.vehicleType],
                        capacity: vehicle.capacity ? vehicle.capacity : DELIVERY_VEHICLE_CAPACITY[vehicle.vehicleType].avg
                    }
                    deliveryAgent.vehiclesById[vehicle.id] = vehicle
                })
                const deliveryAgentsRef = doc(firestore, "deliveryAgents", id)
                await setDoc(deliveryAgentsRef, deliveryAgent)
            }
            return true
        } catch (e){
            const message = `action > deliveryAgents > fetchUpdateDeliveryAgentVehicles: Failed to bulk update the vehicles and closed days of the delivery agents`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}