export const AVATAR_SHAPE_ROUND="round"
export const AVATAR_SHAPE_SQUARE="square"

export const AVATAR_SIZE_SMALL="small"
export const AVATAR_SIZE_MEDIUM="medium"
export const AVATAR_SIZE_BIG="big"

export const HEADER_SIZE_SMALL="small"
export const HEADER_SIZE_MEDIUM="medium"
export const HEADER_SIZE_BIG="big"


export const COUNTDOWN_SIZE_BIG="big"
export const COUNTDOWN_SIZE_SMALL="small"

export const WALLET_CARD_SIZE_BIG="big"
export const WALLET_CARD_SIZE_MEDIUM="medium"
export const WALLET_CARD_SIZE_SMALL="small"

export const ORIENTATION_VERTICAL = "vertical"
export const ORIENTATION_HORIZONTAL = "horizontal"