import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, onSnapshot, query, collection } from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"

export const SAVE_CREDIT_WALLETS = 'SAVE_CREDIT_WALLETS'

export const saveCreditWallets = creditWallets => {
    return {
        type: SAVE_CREDIT_WALLETS,
        payload: {
            creditWallets
        }
    }
}

export const fetchSubscribeToMyCreditWallet = walletId => {
    /**
      * Purpose: retrieve a credit wallet from the firestore database for a particular user
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp);
    const walletRef = doc(firestore, "creditWallets", walletId)
                      
    return async dispatch => {
        try {
            const walletListener = await onSnapshot(walletRef,
                docRef => {
                    //get a wallet from the snapshot
                    const wallet = docRef.data();
                    dispatch(saveCreditWallets([wallet]))
            })
            return walletListener
        } catch (e){
            const message = `action > creditWallets > fetchSubscribeToMyCreditWallet: Failed to save wallet`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchSaveAdminCreditWallets = () => {
    /**
      * Purpose: listen to all credit wallets in the firestore database
      */
    const firestore = getFirestore(firebaseApp);
    const creditWalletsRef = query(collection(firestore, "creditWallets"))
    return async dispatch => {
        try {
            const creditWalletsListener = await onSnapshot(creditWalletsRef, 
                querySnapshot  => {
                    //get an array of credit wallets from the snapshot
                    const creditWallets = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveCreditWallets(creditWallets))
                }
            )
            //get an array of categories from the snapshot
            return creditWalletsListener
        } catch (e){
            const message = `action > creditWallets > fetchSaveAdminCreditWallets: Failed to listen to credit wallets`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}
