import React from "react"

import DeliveryMethodOption from "../../components/DeliveryMethodOption"
import Icon from "../../components/Icon"
import DeliveryItems from "../DeliveryItems"

import AvatarIcon from "../../components/AvatarIcon"
import DateHighlight from "../../components/DateHighlight"
import RoundTime from "../../components/RoundTime"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {AVATAR_SIZE_MEDIUM} from "../../constants/interface"
import {PICK_UP_INSTORE_ID, SELF_DELIVERY_ID} from "../../constants/delivery"

import styles from "./DeliveryOptions.module.css"
import {capitalizeAllWords, alphabeticalSort} from "../../utils/stringUtils"
import {estimateDeliveryFulfilledAt} from "../../utils/deliveryUtils"

import {logAnalyticsEvent} from "../../config/firebase"
import { DOMINICA_TIMEZONE } from "../../constants/datetime"

function DeliveryOptions({sellerId, index, cart, user, sellers, appliedPromotions, deliveryProviders, deliveryTrips, countries, actions}){
    const itemIdList = Object.keys(cart.itemIdsBySellerId[sellerId])
    //find the seller who we are choosing a delivery method for
    const seller = sellers.sellersById[sellerId]
    if (!seller) return ""
    const sellerName = capitalizeAllWords(seller.name)
    const selectedDeliveryProviderId = cart.selectedDeliveryProviderIdsBySellerId[sellerId]
    const selectedPickupAddressId = cart.selectedPickupAddressIdBySellerId[sellerId]
    const giftHandlingRequired = Boolean(cart.giftParameters && (cart.giftParameters.isSurprise || (cart.giftParameters.addOns && cart.giftParameters.addOns.giftWrapping)))
    //get the seller's supported delivery providers
    const supportedDeliveryProviders = seller.supportedDeliveryProviders ?
                                        seller.supportedDeliveryProviders.map(deliveryProviderId => deliveryProviders.deliveryProvidersById[deliveryProviderId])
                                                                        .sort((dA, dB) => {
                                                                            if (!dA || !dB) return 0
                                                                            return alphabeticalSort(dA.name, dB.name)
                                                                        })
                                        : []
    
    //when a delivery selection is made for this seller, propogate it to the other sellers                                                                    
    const handleSelectDeliveryProvider = deliveryProvider => {
        //if the delivery agent being selected is the one already select, do nothing
        if (cart.selectedDeliveryProviderIdsBySellerId[seller.id] === deliveryProvider.id) return
        //to simplify the buyer's experience 
        if (deliveryProvider.id === SELF_DELIVERY_ID){
            //if is merchant/self delivery
            logAnalyticsEvent("select_merchant_delivery", {name: deliveryProvider.name})
            actions.fetchLogDeviceSessionAction({
                action: "selectMerchantDelivery",
                name: deliveryProvider.name
            })        
            actions.selectDeliveryProviderForSeller(deliveryProvider.id, seller.id)
        } else{
            if (deliveryProvider.id === PICK_UP_INSTORE_ID){
                //if is pick up instore fulfilment
                logAnalyticsEvent("select_pick_up_instore", {name: deliveryProvider.name})
                actions.fetchLogDeviceSessionAction({
                    action: "selectPickUpInStore",
                })
            } else {
                //if is third party delivery
                logAnalyticsEvent("select_3rd_party_delivery", {name: deliveryProvider.name})
                actions.fetchLogDeviceSessionAction({
                    action: "select3rdPartyDelivery",
                    name: deliveryProvider.name
                })
            }
            Object.keys(cart.itemIdsBySellerId).forEach(sellerId =>{
                
                if (
                    (sellerId !== seller.id) && //if not the speficied seller and
                    (!cart.selectedDeliveryProviderIdsBySellerId[sellerId]) //currently does not have a selected deliver provider

                ){
                    const otherSeller = sellers.sellersById[sellerId]
                    if (otherSeller && otherSeller.supportedDeliveryProviders.includes(deliveryProvider.id)){
                        //if the delivery provider is an option on this seller
                        //automatically select this delivery provider for seller
                        actions.selectDeliveryProviderForSeller(deliveryProvider.id, sellerId)   
                    }
                    
                } 
                else if (
                    (index === 0) && //the specified seller is the first seller in the list
                    (sellerId !== seller.id) && //and this is not the speficied seller and
                    (cart.selectedDeliveryProviderIdsBySellerId[sellerId]) && //this seller already has a delivery provider chosen
                    //and the chosen delivery provider, is the same as the last delivery provider by the selected seller
                    (cart.selectedDeliveryProviderIdsBySellerId[sellerId] === cart.selectedDeliveryProviderIdsBySellerId[seller.id]) 
                ){
                    const otherSeller = sellers.sellersById[sellerId]
                    if (otherSeller && otherSeller.supportedDeliveryProviders.includes(deliveryProvider.id)){
                        //if the delivery provider is an option on this seller
                        //automatically select this delivery provider for seller
                        actions.selectDeliveryProviderForSeller(deliveryProvider.id, sellerId)   
                    }
                } else if (
                    (index !== 0) && //the specified seller is not the first seller
                    (sellerId === seller.id) && //this is the specified seller
                    // and the delivery provider is a third party delivery service  
                    (deliveryProvider.id !== PICK_UP_INSTORE_ID)
                ){
                    const otherSellers = []
                    Object.keys(cart.itemIdsBySellerId).forEach(otherSellerId => {
                        const otherSeller = sellers.sellersById[otherSellerId]
                        //skip this seller if it is the current seller
                        if (!otherSeller || otherSeller.id === sellerId) return
                        //skip this seller if it has already selected the same delivery provider
                        else if (cart.selectedDeliveryProviderIdsBySellerId[otherSellerId] === deliveryProvider.id) return
                        //skip the other seller if it does not support the selected delivery provider
                        else if (otherSeller.supportedDeliveryProviders.includes(deliveryProvider.id)) otherSellers.push(otherSeller)
                    })
                    if (otherSellers.length > 0){
                        //if there were other sellers that supported the delivery provider
                        const message = otherSellers.reduce((message, seller, i) => {
                            if (i === 0) message = `${message}${capitalizeAllWords(seller.name)}`
                            else message = `${message} and ${capitalizeAllWords(seller.name)}`

                            if((otherSellers.length - 1) === i) message = `${message}?`
                            return message
                        }, 
                        `Also update the delivery method for `)
                        if (window.confirm(message)){
                            //set this delivery method for the other sellers
                            otherSellers.forEach(seller => actions.selectDeliveryProviderForSeller(deliveryProvider.id, seller.id))
                        }
                    }
                    actions.selectDeliveryProviderForSeller(deliveryProvider.id, sellerId)
                }
                //if this is the specified seller, then always update the deliver provider 
                else if (sellerId === seller.id) actions.selectDeliveryProviderForSeller(deliveryProvider.id, sellerId)
            })
        }
    }
    //log analytics when a pickup location is selected
    const pickupAddresses = seller.addressesById ? Object.values(seller.addressesById) : []
    const handleSelectedPickupAddress = (addressId, deliveryProvider) => {
        logAnalyticsEvent("choose_pick_up_location", {name: deliveryProvider.name, addressId})
        actions.fetchLogDeviceSessionAction({
            action: "choosePickUpLocation",
            addressId 
        })
        actions.selectPickupAddressForSeller(addressId, sellerId)
    }
    const sellerReadinessEstimation = cart.sellerReadinessEstimationsBySellerId && cart.sellerReadinessEstimationsBySellerId[sellerId] ?
                                                          cart.sellerReadinessEstimationsBySellerId[sellerId]
                                                          :
                                                          {readyAt: Date.now()}
    return (
    <div className={`${styles.container} checkoutField`}>
        <div className={['strong', styles.seller].join(" ")}>
            <div className={styles.sellerIcon}>
                <span className="badge outline">3.{index + 1}</span>
                <AvatarIcon name={seller.name} imageUrl={seller.logoImageUrlMed} size={AVATAR_SIZE_MEDIUM} borderColor="var(--light-grey)"/>
            </div>
            <div>Choose A Delivery Method For <span className="infoHighlight">{sellerName}</span></div>
        </div>
        <div className={styles.deliveryContainer}>
            {
                supportedDeliveryProviders.map(deliveryProvider => {
                    //loop through each of the seller's delivery providers and retrun a DeliveryMethodOption
                    const userAddress = {...user.addressesById[user.defaultAddressId]}
                    userAddress.countryName = countries.countriesById[userAddress.countryId].name
                    //does this delivery provider seler to the area in the user's address? 
                    const providerDeliversToUserSettlement =!deliveryProvider.deliveryCountryIds[userAddress.countryId]?
                                                            false
                                                            : 
                                                            !deliveryProvider.deliverySettlementIds ?
                                                            //if there is no specification, they deliver everywhere 
                                                            true
                                                            :
                                                            deliveryProvider.deliverySettlementIds[userAddress.settlementId] !== undefined ? 
                                                            //otherwise check whether the user address is in the sellers list of addresses
                                                            //equality to undefined used to allow for 0 fee deliveries
                                                            true
                                                            :
                                                            false
                    if (
                        (deliveryProvider.id === selectedDeliveryProviderId) && 
                        (
                            !providerDeliversToUserSettlement || 
                            (giftHandlingRequired && !Boolean(deliveryProvider.giftHandling))
                        )
                        ){
                        //clear the selected deliver provider for a seller if
                        //1. they are the selected delivery provider and
                        //2(a). they do not deliver to the buyer's location or
                        //2(b). they do not handle gifts and gift handling is required
                        actions.selectDeliveryProviderForSeller("", seller.id)
                    }
                    //used to trigger consolidation
                    //this is the list of sellers that will use this delivery provider on this order
                    const sellerIdsUsingDeliveryProviderInDisplayOrder = []
                    const sellerIdsWithDeliveryProviderAvailableMap = {}
                    Object.keys(cart.itemIdsBySellerId)
                            .map(sellerId => sellers.sellersById[sellerId])
                            .sort((sA, sB) => alphabeticalSort(sA.name, sB.name))
                            .forEach((seller) => {
                                if (seller.supportedDeliveryProviders && seller.supportedDeliveryProviders.includes(deliveryProvider.id)){
                                    sellerIdsWithDeliveryProviderAvailableMap[seller.id] = true
                                }
                                //if the seller has selected this delivery provider to use on this order
                                //, add them to the list
                                if (cart.selectedDeliveryProviderIdsBySellerId[seller.id] === deliveryProvider.id){
                                    sellerIdsUsingDeliveryProviderInDisplayOrder.push(seller.id)
                                }
                            })
                    //estimate when the delivery will be fulfilled
                    //considering only this seller's items, not the others 
                    const deliveryFulfillmentEstmation = estimateDeliveryFulfilledAt(
                        sellerReadinessEstimation.readyAt, 
                        deliveryProvider, 
                        userAddress, 
                        deliveryTrips
                    )
                    return (
                        <DeliveryMethodOption 
                            key={`${seller.id}${deliveryProvider.id}`}
                            deliveryProvider={deliveryProvider}
                            isActive={ //is the option available to be selected?
                                providerDeliversToUserSettlement && 
                                (!giftHandlingRequired || Boolean(deliveryProvider.giftHandling))
                                && deliveryFulfillmentEstmation
                            }
                            selected={deliveryProvider.id === selectedDeliveryProviderId} //is the option selected?
                            selectedIndex={sellerIdsUsingDeliveryProviderInDisplayOrder.findIndex(id => id === sellerId)} //the order the seller appears on the page, if it has selected this delivery provider
                            userAddress={userAddress}
                            giftHandlingRequired={giftHandlingRequired}
                            numOfSellersSelected={sellerIdsUsingDeliveryProviderInDisplayOrder.length}
                            numOfSellersAvailable={Object.keys(sellerIdsWithDeliveryProviderAvailableMap).length}
                            onSelect={() => handleSelectDeliveryProvider(deliveryProvider)}
                            estimatedFulfilledAt={deliveryFulfillmentEstmation ? deliveryFulfillmentEstmation.fulfilledAt : 0}
                            //only used for self delivery
                            sellerName={sellerName}
                            sellerLogo={seller.logoImageUrlMed}
                            sellerDeliverySettings={seller.selfDelivery}
                            //only used for pickup instore
                            pickupAddresses={pickupAddresses}
                            selectedPickupAddressId={selectedPickupAddressId}
                            onSelectPickupAddress={addressId => handleSelectedPickupAddress(addressId, deliveryProvider)}
                        />
                    )
                })
            }
        </div>
        <div className={styles.itemInfo}>
            <div>
                <Icon icon="schedule"/> 
                <div>{itemIdList.length} Item{itemIdList.length > 1 ? "s" : ""} packaged by around <RoundTime timestamp={sellerReadinessEstimation.readyAt} timezone={DOMINICA_TIMEZONE}/>, <DateHighlight timestamp={sellerReadinessEstimation.readyAt}/></div>
            </div>
        </div>
        <DeliveryItems
            sellerId={sellerId}
            appliedPromotions={appliedPromotions}
        />
    </div>
    )
}

const mapStateToProps = state => ({
    deliveryProviders: state.deliveryProviders,
    deliveryTrips: state.deliveryTrips,
    sellers: state.sellers,
    cart: state.cart,
    user: state.user,
    countries: state.countries,
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOptions);