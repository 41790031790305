import React from "react"
import {connect} from "react-redux"

import {v4 as uuid4} from 'uuid'

class Form extends React.Component{

    identifier = "id"

    handleChange = (e, attr, type = "string", min=null, max=null, onSuccess=()=>{}) => {
        let value = e.target.value
        if(type === "string"){
            value = String(value);
        } else if (type === "number") {

            if (value === "") value = value
            else {
                const minSatisfied = typeof min !== 'number' || value >= min
                const maxSatisfied = typeof max !== 'number' || value <= max
                if(minSatisfied && maxSatisfied) value = Number(value);
                else return
            }
        }
        const values = {
            ...this.state.values, 
            [attr]: value
        }
        this.setState({values, errors: {}}, () => onSuccess(values))
    }

    handleChangeSelect = (value, key, onSuccess=()=>{}) => {
        const values = {
            ...this.state.values,
            [key]: value
        }
        this.setState({
            values,
            errors: {}
        }, () => onSuccess(values))
    }

    setErrorHighlight = key => {
        //highlight a component if something is not correct, then remove the highlight class (e.g. shake) a second later
        this.setState({
            errorHighlights: {
                ...this.state.errorHighlights,
                [key]: true
            }
        }, () => {
            setTimeout(() => {
                    const errorHighlights = {...this.state.errorHighlights}
                    delete errorHighlights[key]
                    this.setState({errorHighlights})
            }, 1000)
        })
    }

    validate = () => {
        const errors = {}
        const {values} = this.state
        Object.keys(values).forEach(key => {
            if (!Boolean(values[key])){
                errors[key] = `${key} is required`
            }
        })
        if (Object.values(errors).every(error => !error)) return true
        else {
            this.setState({errors})
            return false
        }
    };

    //implementations of executeSubmit must return a promise 
    //in order to see the loading screen
    executeSubmit = async () => {}
    
    //form subclasses need to have actions in their props
    //in order to see the loading screen
    handleSubmit = async () => {
        const {actions} = this.props
        if(await this.validate()){
            if (actions) actions.toggleLoading(true)
            await this.executeSubmit()
            if (actions) actions.toggleLoading(false)
            this.clearFields()
            this.postSubmit()
        } else this.handleInvalid()
    }

    handleKeyDown = (e) => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          this.handleSubmit();
        }
    }

    handleInvalid = () =>{
        console.log("could not submit")
    }


    clearFields = () => this.setState({values: {...this.fields}})

    generateId = () => uuid4()

    handleSubmitSuccess = (object) => {
        console.log(`${object[this.identifier]} was successfully added`)
    } 

    handleSubmitError = object => {
        console.log(`${object ? object[this.identifier] : ""} could not be added`)
    }

    postSubmit = () => {
        const {postSubmit} = this.props
        if (postSubmit) postSubmit()
    }

    scrollToTop = () => window.scrollTo(0, 0)

}

export default Form
