import React, {useState} from "react"
import StarRatingComponent from "react-star-rating-component"
import AvatarIcon from "../AvatarIcon"
import { capitalizeAllWords } from "../../utils/stringUtils"
import { timestampToDateString } from "../../utils/datetimeUtils"
import EllipsisText from "../EllipsisText"
import styles from "./ReviewContent.module.css"

const ReviewContent = ({
    reviewId,
    name,
    profileImageUrl="",
    starRating=null,
    createdAt,
    reviewText="",
    isFullText=true,
    handleShowMore,
    handleShowLess,
    defaultShowMore=false
}) => {

    return (
    <div className={`display-flex margin-bottom-05em ${styles.container}`}>
            <div className="margin-right-05em"><AvatarIcon name={name} imageUrl={profileImageUrl}/></div>
            <div>
                <div className="bold">{capitalizeAllWords(name)}</div>
                <div className="margin-bottom-05em color-grey font-size-12px">{timestampToDateString(createdAt, "EEE dd MMM yy, t")}</div>
                <StarRatingComponent name={reviewId} value={starRating}/>
                <div>
                    <EllipsisText 
                        charDisplayLength={99}
                        maxWidth="100%"
                        text={reviewText}
                        showMoreLabel="View full review"
                        labelStyles="anchorLink color-link-blue"
                        handleShowLess={handleShowLess}
                        handleShowMore={handleShowMore}
                        defaultShowMore={defaultShowMore}
                    />
                </div>
            </div>
        </div>
    )
}

export default ReviewContent