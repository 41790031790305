import React from "react"
import styles from "./Slider.module.css"
import Icon from "../Icon"

class Slider extends React.PureComponent{
    
    state = {
        atScrollEndLeft: true,
        atScrollEndRight: true,
    }

    static defaultProps = {
        showArrows: true
    }

    componentDidMount(){
        this.toggleScrollEnd()
    }
    
    handleScroll = scrollValue => {
        this.slider.scrollLeft += scrollValue
    }

    toggleScrollEnd = () => {
        const checkSliderSize = () =>this.setState({
            atScrollEndLeft: this.slider.scrollLeft === 0,
            atScrollEndRight: (this.slider.scrollLeft + this.slider.offsetWidth) >= this.slider.scrollWidth
        })
        if (this.slider) checkSliderSize()
        else setTimeout(checkSliderSize, 1000)
    }
    render(){
        const {atScrollEndLeft, atScrollEndRight} = this.state
        const {showArrows} = this.props
        const leftArrowVisibile = atScrollEndLeft ? styles.hidden : ""
        const rightArrowVisible = atScrollEndRight ? styles.hidden : ""
        return (
            <div className={styles.container}>
                {
                    showArrows ?
                    <div 
                        onClick={() => this.handleScroll(-1 * window.innerWidth)} 
                        className={`${styles.arrow} ${styles.left} ${leftArrowVisibile}`}
                    >
                        <Icon icon="keyboard-arrow-left"/>
                    </div>
                    :
                    null
                }
                <div className={styles.innerContainer} ref={el => this.slider = el} onScroll={this.toggleScrollEnd}>
                {this.props.children}
                </div>
                {
                    showArrows ?
                    <div 
                        onClick={() => this.handleScroll(window.innerWidth)} 
                        className={`${styles.arrow} ${styles.right} ${rightArrowVisible}`}
                    >
                        <Icon icon="keyboard-arrow-right"/>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

export default Slider