import React from "react"

import BigUpButton from "../../components/BigUpButton"
import AccountNeededModal from "../../components/AccountNeededModal"
import ReactionListModal from "../../components/ReactionListModal"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {REACTION_TYPE_BIGUP, REACTION_TYPE_STUPES} from "../../constants/reactions"
import {OBJECT_TYPE_VIDEO} from "../../constants/objects"

import {logAnalyticsEvent} from "../../config/firebase"
class ReactionPanel extends React.Component {
    
    state = {
        authModalOpen: false,
        userModalOpen: false,
        preAuthReaction: null
    }

    static defaultProps = {
        reactionTotals: {}
    }

    handleOpenAuthModal = preAuthReaction => this.setState({authModalOpen: true, preAuthReaction})
    handleCloseAuthModal = () => this.setState({authModalOpen: false, preAuthReaction: null})
    
    handleOpenUserModal = () => this.setState({userModalOpen: true})
    handleCloseUserModal = () => this.setState({userModalOpen: false})

    handleReact = async reactionType => {
        const {actions, user, entityId, entityType, objectId, objectType} = this.props
        if (!user.authenticated) {
            this.handleOpenAuthModal(reactionType)
            return
        }
        const currentReaction = this.getCurrentReaction()
        if (currentReaction === reactionType){
            await actions.fetchDeleteReaction(
                user.id,
                objectId,
                objectType,
                reactionType
            )
        } else {
            await actions.fetchCreateReaction(
                user.id,
                reactionType,
                entityId,
                entityType,
                objectId,
                objectType,
            )
            logAnalyticsEvent("userReaction", {userId: user.id, reactionType, entityId, entityType, objectId, objectType} )
            actions.fetchLogDeviceSessionAction({
                action: "userReaction",
                userId: user.id,
                reactionType,
                objectId
            })
        }
    }
    
    getUserReactionList = () => {
        const {reactions, objectId} = this.props
        let list = null
        if (
            reactions.reactionsByObjectId[objectId]
        ) {
            list = Object.values(reactions.reactionsByObjectId[objectId].reactions)
                         .sort((rA, rB) => rB.reactedAt - rA.reactedAt)
        }
        return list
    }
    getCurrentReaction = () => {
        const {user, reactions, objectId} = this.props
        let reactionType = null
        const hasReaction = Boolean(
            reactions.reactionsByObjectId[objectId] &&
            reactions.reactionsByObjectId[objectId].reactions[user.id]
        )
        if (hasReaction){
            reactionType = reactions.reactionsByObjectId[objectId].reactions[user.id].reactionType
        }
        return reactionType
    }

    handleAuthSuccess = () => {
        const {preAuthReaction} = this.state
        this.handleCloseAuthModal()
        this.handleReact(preAuthReaction)
    }

    render(){
        const {reactionTotals, objectType} = this.props
        const {authModalOpen, preAuthReaction, userModalOpen} = this.state
        const reactionType = this.getCurrentReaction()
        const objectName = objectType === OBJECT_TYPE_VIDEO ?
                           "video"
                           :
                           objectType
        const userReactions = this.getUserReactionList()
        return (
            <React.Fragment>
                <BigUpButton 
                    onClick={() => this.handleReact(REACTION_TYPE_BIGUP)}
                    onClickCount={this.handleOpenUserModal}
                    reactionCount={reactionTotals[REACTION_TYPE_BIGUP]}
                    selected={reactionType === REACTION_TYPE_BIGUP}
                    userReactions={userReactions}
                />
                {
                    authModalOpen && preAuthReaction? 
                    <AccountNeededModal 
                        title={`to ${preAuthReaction} this ${objectName}`}
                        modalOpen={authModalOpen}
                        handleCloseModal={this.handleCloseAuthModal}
                        handleSuccess={this.handleAuthSuccess}
                    />
                    :
                    null
                }
                {
                    userModalOpen ?
                    <ReactionListModal 
                        reactionList={userReactions}
                        modalOpen={userModalOpen}
                        handleCloseModal={this.handleCloseUserModal}
                    />
                    :
                    null
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    reactions: state.reactions
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReactionPanel);