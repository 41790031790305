import React from "react"
import styles from './FormField.module.css'
import DataSelect from "../DataSelect"
import RadioList from "../RadioList"
import PhoneInput from "../PhoneInput"
import CurrencyInput from "../CurrencyInput"
import DateRangePicker from "../DateRangePicker"
import MultiImageModal from "../MultiImageModal"
import SuggestionList from "../SuggestionList"
import Icon from "../Icon"
import {ORIENTATION_VERTICAL} from "../../constants/interface"

function FormField({
    value,
    onChange,
    type="text",
    min,
    max,
    errorText,
    label,
    isTextArea=false,
    isDropDown=false,
    displayTransformFunction= value => value,
    isRadioList=false,
    isDateRange=false,
    isSuggestionList=false,
    isMultiImage=false,
    pickerLabel="name",
    showMultiple=false,
    selectMultiple=false,
    allowBlank=true,
    modalTitle="",
    dataArray=[],
    images=[],
    editable=true,
    note="",
    placeholder="",
    left="",
    right="",
    inputIconButton="",
    selectTextOnFocus=false,
    currency,
    currencySymbol,
    currencyInputSize="wide",
    currencyTextSize="",
    inputMode=undefined,
    radioListOrientation=ORIENTATION_VERTICAL,
    onKeyDown=()=>{},
    onFocus=()=>{},
    onBlur=()=>{},
    onClickInputIconButton=()=>{},
    error=""
}){
    return (
        <div className={`${styles.container} ${errorText ? styles.error : ""}`}>
            {label ? <div className={styles.label} >{label}</div> : null}
            <div className={styles.inputContainer}>
            {left ? <span className={styles.left}>{left}</span> : null}
            {
                isTextArea ?
                <textarea
                    className="textarea"
                    value={value} 
                    onChange={editable ? onChange: ()=>{}}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    readOnly={!editable}
                    placeholder={placeholder}
                ></textarea>
                :
                isDropDown ?
                <DataSelect 
                    dataArray={dataArray}
                    label={pickerLabel}
                    onSelect={editable ? onChange: ()=>{}}
                    selected={value}
                    selectMultiple={selectMultiple}
                    size={showMultiple ? 5 : 1}
                    allowBlank={allowBlank}
                    transformFunction={displayTransformFunction}
                />
                :
                isSuggestionList ?
                <SuggestionList 
                    dataArray={dataArray}
                    label={pickerLabel}
                    className={`input ${error ? "error": ""} ${editable ? "": "readOnly"}`}
                    type={type}
                    readOnly={!editable}
                    value={value}
                    onChange={editable ? onChange: ()=>{}}
                    onKeyDown={onKeyDown}
                    min={min}
                    max={max}
                    placeholder={placeholder}
                    onBlur={onBlur}
                />
                :
                isRadioList ?
                <RadioList
                    dataArray={dataArray}
                    label={pickerLabel}
                    onChange={editable ? onChange: ()=>{}}
                    selected={value}
                    allowBlank={allowBlank}
                    orientation={radioListOrientation}
                />
                :
                isMultiImage?
                <MultiImageModal
                    title={modalTitle}
                    images={images}
                    onSave={onChange}
                />
                :
                isDateRange ?
                <DateRangePicker
                    dateRange={value}
                    onChange={editable ? onChange: ()=>{}}
                />
                :
                type === "tel"?
                <PhoneInput
                    className={`input ${editable ? "": "readOnly"}`}
                    readOnly={!editable}
                    value={value}
                    onChange={editable ? onChange: ()=>{}}
                    onKeyDown={onKeyDown}
                />
                :
                type === "currency" ? 
                <CurrencyInput 
                    className={`input ${editable ? "": "readOnly"}`}
                    readOnly={!editable}
                    value={value} 
                    onChange={editable ? onChange: ()=>{}}
                    onKeyDown={onKeyDown}
                    currency={currency}
                    currencySymbol={currencySymbol}
                    inputSize={currencyInputSize}
                    textSize={currencyTextSize}
                />
                :
                <div className={styles.input}>
                    <input
                        className={`input ${error ? "error": ""} ${editable ? "": "readOnly"}`}
                        type={type}
                        readOnly={!editable}
                        value={value}
                        onChange={editable ? onChange: ()=>{}}
                        onKeyDown={onKeyDown}
                        min={min}
                        max={max}
                        placeholder={placeholder}
                        onFocus={selectTextOnFocus ? e => e.target.select() : onFocus}
                        onBlur={onBlur}
                        inputMode={inputMode}
                    />
                    {
                        inputIconButton ? 
                        <div className={styles.iconButton} onClick={onClickInputIconButton}>
                            <Icon icon={inputIconButton}/>
                        </div>
                        :
                        null
                    }
                </div>
            }
            {right ? <span className={styles.right}>{right}</span> : null}
            </div>
            {note ? <div className='formNotes'>{note}</div> : null}
            {errorText ? <div className={styles.errorText}>{errorText}</div> : null}
        </div>
    )
}

export default FormField;