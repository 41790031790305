import React from "react"
import styles from "./ScheduleList.module.css"
import {dateStringToTimestamp, timestampToDateString, getTimestampForStartOfDay, timestampToWeekDay} from "../../utils/datetimeUtils"

import WindowedList from "../WindowedList";
import {DAY_IN_MILLISECONDS} from "../../constants/datetime"
import { objectsAreEqual } from "../../utils/generalUtils";

class ScheduleList extends React.PureComponent {

    constructor(props) {
        super(props)
        const {startDate, endDate, activeScheduleId} = props
        const scheduleArray = this.getScheduleArrayFromTimeRange(startDate, endDate)
        this.state = {
            scheduleArray,
            //determine which schedule to show first
            //the starting point is usually 0 - the first element in the list
            //but if there is an activeScheduleId provided
            //then we find its index and use it as the startAt (the first schedule visible in the WindowedList)
            startAt: activeScheduleId ?
                        scheduleArray.findIndex(s => s.id === activeScheduleId)
                        :
                        0,
            jumpTo: null
        }
        //store the last change of schedule due to the scroll event
        this.lastScheduleScrollIntoView = null

        
    }
    
    static defaultProps = {
            activeScheduleId: 0,
            startDate: Date.now(),
            minDateTime: Date.now(),
            endDate: Date.now() + (7 * DAY_IN_MILLISECONDS),
            supportedSlots: [], 
            weeklyAvailableSlotMap: {},
            excludedDayMap: {}, //any specific days that should de excluded due to special cases e.g. holidays
            timezone: "", 
            onChangeActiveSchedule: ()=>{},
            onSelectSlot: ()=>{},
            selectedSlotId: null
    }

    componentDidUpdate = (prevProps) => {
        //use the start and dates to generate the schedule array
        //make one schedule for every day within the start and end dates, inclusive
        const {startDate, endDate, timezone, supportedSlots, weeklyAvailableSlotMap, activeScheduleId} = this.props
        let scheduleArray = this.state.scheduleArray
        if (
            prevProps.startDate !== startDate ||
            prevProps.endDate !== endDate ||
            prevProps.timezone !== timezone ||
            !objectsAreEqual(
                prevProps.supportedSlots, 
                supportedSlots
            ) 
            ||
            !objectsAreEqual(
                prevProps.weeklyAvailableSlotMap,
                weeklyAvailableSlotMap
            )
        ){
            scheduleArray = this.getScheduleArrayFromTimeRange(startDate, endDate)
            this.setState({
                scheduleArray
            })
        }
        //if the active schedule has changed and
        //it was not passed in from the scroll event
        //then it was external, so jump to it 
        if (
            (prevProps.activeScheduleId !== activeScheduleId) &&
            (activeScheduleId !== this.lastScheduleScrollIntoView) &&
            (
                this.lastScheduleScrollIntoView === null ||
                prevProps.activeScheduleId === this.lastScheduleScrollIntoView
            )
        ){
            this.setState({
                jumpTo: scheduleArray.findIndex(s => s.id === activeScheduleId)
            })
        } 
    }

    getScheduleArrayFromTimeRange = (startDate, endDate) => {
        const {
            timezone, 
            supportedSlots, 
            weeklyAvailableSlotMap, //includes all available slots, indexed by day of the week
            excludedDayMap, //handle excluding special days, like holidays
        } = this.props
        const scheduleArray = []
        const startOfStartDay =  getTimestampForStartOfDay(startDate, !timezone, timezone)
        // find all days within the range provided
        const endOfLastDay = getTimestampForStartOfDay(endDate, !timezone, timezone) + DAY_IN_MILLISECONDS - 1
        // by starting at the provided start day, and add days until the end of the last day is reached
        for (let date = startOfStartDay; date < endOfLastDay; date += DAY_IN_MILLISECONDS){
            const title = timestampToDateString(date, "MMMM dd", !timezone, timezone)
            const dayOfTheWeek = timestampToWeekDay(date, !timezone, timezone)
            //calculate the slots in each schedule by processing the times of day in the supported slot in combination with the day of that schedule
            const dayString = timestampToDateString(date, "yyyy-MM-dd", !timezone, timezone)
            //gives us the (time) slots available on a given day of the week, 
            //this will be a subset of the overall supported slots
            //supported slots deals with time of day, this deals with day of week
            const availableSlotsForWeekDayMap = weeklyAvailableSlotMap[dayOfTheWeek] && !excludedDayMap[dayString]?
                                            weeklyAvailableSlotMap[dayOfTheWeek]
                                            :
                                            {} 
            const slots = supportedSlots.map(supportedSlot => {
                
                //a supported slot is available if it is included in the available map for this day of the week
                const availableSlot = availableSlotsForWeekDayMap[supportedSlot.id]
                //the start and end time are sometimes adjusted in the available slot, so use these if available
                const startTimeString =  availableSlot ? availableSlot.startTime : supportedSlot.startTime
                const endTimeString = availableSlot ? availableSlot.endTime : supportedSlot.endTime
                const startTime = dateStringToTimestamp(`${dayString} ${startTimeString}`, "yyyy-MM-dd hh:mm", !timezone, timezone)
                const endTime = dateStringToTimestamp(`${dayString} ${endTimeString}`, "yyyy-MM-dd hh:mm", !timezone, timezone)
                return {
                    id: startTime,
                    startTime,
                    endTime,
                    type: supportedSlot.type,
                    title: supportedSlot.title,
                    isAvailable: Boolean(availableSlot)
                }
            })   
            scheduleArray.push({
                id: date,
                date,
                slots,
                title //todo remove
            })
        }
        return scheduleArray
    }

    //update active id when a new schedule scrolls into view
    handleScrollIntoView = nextScheduleId => {
        const {activeScheduleId, onChangeActiveSchedule} = this.props
        this.lastScheduleScrollIntoView = nextScheduleId
        if (nextScheduleId !== activeScheduleId) {
            onChangeActiveSchedule(nextScheduleId)
        }
    }

    render (){
        const {
            activeScheduleId=0,
            timezone, 
            onSelectSlot,
            selectedSlotId,
            minDateTime
        } = this.props
        const {startAt, jumpTo, scheduleArray} = this.state 
        return (
            <WindowedList
                list={scheduleArray}
                getKeyFromItem={schedule => schedule.date}
                startAt={startAt}
                jumpTo={jumpTo}
                onItemScrolledIntoView={this.handleScrollIntoView}
                isVisibleThreshold={0.6}
                renderItem={
                    (schedule) => <Schedule
                                    id={schedule.id}
                                    slots={schedule.slots}
                                    date={schedule.date}
                                    timezone={timezone}
                                    isActive={schedule.id === activeScheduleId}
                                    onSelectSlot={onSelectSlot}
                                    selectedSlotId={selectedSlotId}
                                    minDateTime={minDateTime}
                                />
                }
            />
        )
    }
}

class Schedule extends React.PureComponent {
    render(){
        const {
            id, 
            date=Date.now(), 
            timezone="", 
            slots=[], 
            isActive=false,
            onSelectSlot=()=>{},
            selectedSlotId=null,
            minDateTime
        } = this.props
        const dateString = timestampToDateString(date, "EEE, MMMM dd", !timezone, timezone)
        return (
            <div className={styles.schedule}>
                <div className={styles.scheduleTitle}>{dateString}</div>
                <div className={styles.scheduleSlots}>
                {
                    slots.map(slot => {
                        return <Slot 
                                    key={slot.id}
                                    id={slot.id}
                                    type={slot.type}
                                    title={slot.title}
                                    startTime={slot.startTime}
                                    endTime={slot.endTime}
                                    onSelect={onSelectSlot}
                                    timezone={timezone}
                                    isSelected={selectedSlotId === slot.id}
                                    isAvailable={slot.isAvailable && (slot.endTime > minDateTime) }
                                /> 
                    })
                }
                </div>
            </div>
        )
    }
}

class Slot extends React.PureComponent{
    render(){
        const {
            id, 
            title="", 
            startTime=0, 
            endTime=1, 
            timezone="", 
            type="", 
            isAvailable=true, 
            isSelected=false, 
            onSelect=()=>{}
        } = this.props
        const startTimeString = timestampToDateString(startTime, "h:mm a", !timezone, timezone)
        const endTimeString = timestampToDateString(endTime, "h:mm a", !timezone, timezone)
        const buttonText = !isAvailable ?
                            "Unavailable"
                            :
                            isSelected ?
                            "Selected"
                            :
                            "Available"
        const handleSelect = isAvailable ? () => onSelect(startTime, endTime, timezone, type) : () => {}
        return (
            <div className={`${styles.slotContainer} ${isSelected ? styles.selected: ""} ${!isAvailable ? styles.unavailable : ""}`}>
                <div className={styles.slotDetails}>
                    <div className={styles.slotTimeRange}>{startTimeString}-{endTimeString}</div>
                    <div className={styles.slotType}>{title}</div>
                </div>
                <div className={styles.slotButton} onClick={handleSelect}>{buttonText}</div>
            </div>
        )
    }
}
export default ScheduleList