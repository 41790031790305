import React from "react"
import styles from './CreditTransactionList.module.css'
import CreditTransaction from "../CreditTransaction"
import FormField from "../FormField"
import {timestampsAreOnSameDay, timestampToDateString} from "../../utils/datetimeUtils"
import {stringBContainsStringA} from "../../utils/stringUtils"

class CreditTransactionList extends React.Component{
    state = {
        searchTerm: ""
    }

    static defaultProps = {
        transactions: [],
        walletId: "",
        title: "Transaction History",
        enableSearch: false
    }

    render(){
        const {transactions, walletId, title, enableSearch} = this.props
        const {searchTerm} = this.state
        const filteredTransactions = enableSearch && searchTerm && searchTerm.trim() ? 
                                transactions.filter(t => t.reason && typeof t.reason === "string" && stringBContainsStringA(searchTerm, t.reason))
                                : transactions 
        let currentDay = Date.now()
        const today = currentDay
        let firstTransactionToday = -1
        return (
            <div>
                <div className="transactionListTitle">{title}</div>
                {
                    enableSearch ?
                            <FormField 
                                value={searchTerm} 
                                onChange={e => this.setState({ searchTerm: String(e.target.value)})}
                                placeholder={`Search By Description`}
                            />
                            :
                            null
                }
                <div className="transactionList">
                    {
                        filteredTransactions.map(
                            (transaction, i) => {
                                const processedAt = transaction.processedAt ? transaction.processedAt : transaction.createdAt
                                let displayDate = false
            
                                if (!timestampsAreOnSameDay(currentDay, processedAt)){
                                    displayDate = true
                                    currentDay = processedAt
                                } else if (currentDay === today){
                                    if (firstTransactionToday < 0) firstTransactionToday = i 
                                }
                                return (
                                    <div key={transaction.id}>
                                        {
                                            firstTransactionToday === i ?
                                            <div className={styles.date}>Today</div>
                                            :
                                            displayDate ? 
                                            <div className={styles.date}>{timestampToDateString(currentDay, "EEEE, dd MMMM yy")}</div>
                                            :
                                            null
                                        }
                                        <CreditTransaction 
                                            walletId={walletId}
                                            recipientWalletId={transaction.recipientWalletId}
                                            recipientName={transaction.recipientName}
                                            recipientTitle={transaction.recipientTitle}
                                            endingRecipientBalance={transaction.endingRecipientBalance}
                                            senderId={transaction.senderId} 
                                            senderName={transaction.senderName}
                                            senderTitle={transaction.senderTitle}
                                            endingSenderBalance={transaction.endingSenderBalance}
                                            totalXcd={transaction.totalXcd} 
                                            createdAt={transaction.createdAt} 
                                            reason={transaction.reason}
                                            
                                        />
                                    </div>
                                )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default CreditTransactionList