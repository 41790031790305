import React from "react"
import ReviewTile from "../ReviewTile"
import Slider from "../../components/Slider"

const ReviewSlider = ({
    reviewArray=[],
    handleOpenReviewModal=()=>{}
}) => {
    reviewArray = [
        {
            name: "sonja wiencke",
            createdByEntityId: "elFDqLn6vsOZ2yVNxkIg4hQYwgA2",
            createdByEntityType: "INDIVIDUAL",
            createdByUserId: "elFDqLn6vsOZ2yVNxkIg4hQYwgA2",
            createdByUserName: "sonja wiencke",
            profileImageUrl: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/users%2FelFDqLn6vsOZ2yVNxkIg4hQYwgA2%2Fprofile_small?alt=media&token=4144ead5-8c4e-4a7e-8ee8-fe4035aa6105",
            createdAt: 1723642740000, //(limit to 100 chars)
            reviewText: `Shopdm has become essential for me. I work from home and I don't drive, so going to Roseau to buy groceries is a hassle and a cost that I'm happy to avoid. The service is very affordable and conveniently fast. And the Shopdm team's customer service is absolutely top-notch! 👌`,
            isFullText: true,
            starRating: 5,
            imageUrls: [],
            id: "6e689f2c-647e-48f8-8ad8-05230df712c2",
            threadId: "a8f1ab27-9cb8-4f28-b16e-b951723ec987",
            isThreadStart: true,
            type: "REVIEW_TYPE_SHOPDM",
            entityType: "SHOPDM", //SHOPDM / SELLER / DELIVERY PROVIDER / INDIVIDUAL: "",
            entityId: "SHOPDM",
            objectId: "SHOPDM",
            objectType: "SHOPDM",
            isPublic: true,
        },
        {
            name: "melinda ulysses",
            createdByEntityId: "vA14Zw932QWPKrqTuMQVMYhFmJL2",
            createdByEntityType: "INDIVIDUAL",
            createdByUserId: "vA14Zw932QWPKrqTuMQVMYhFmJL2",
            createdByUserName: "melinda ulysses",
            profileImageUrl: "",
            createdAt: 1709687880304, //(limit to 100 chars)
            reviewText: `Shop dm is very convenient for me as someone who can't really move around when I want to due to my schedule. The gentleman operating their contact line is always courteous and respectful. The rep at Astaphans is great! She communicates effectively and ensures that you are satisfied with your items. My only issue is the stores not updating their inventory, and that's not on shop dm. Great job guys!`,
            isFullText: true,
            starRating: 5,
            imageUrls: [],
            id: "483f4570-849f-4e44-af50-de451bc103d9",
            threadId: "e2b5ca22-e60d-4d97-bae4-c14d61a7cf29",
            isThreadStart: true,
            type: "REVIEW_TYPE_SHOPDM",
            entityType: "SHOPDM", //SHOPDM / SELLER / DELIVERY PROVIDER / INDIVIDUAL: "",
            entityId: "SHOPDM",
            objectId: "SHOPDM",
            objectType: "SHOPDM",
            isPublic: true,
        },
        {
            name: "indu sudhakar",
            createdByEntityId: "jNf4R6JStkZHp8vVPsbi5iaB21K2",
            createdByEntityType: "INDIVIDUAL",
            createdByUserId: "jNf4R6JStkZHp8vVPsbi5iaB21K2",
            createdByUserName: "indu sudhakar",
            profileImageUrl: "",
            createdAt: 1709395597304, //(limit to 100 chars)
            reviewText: `This service is a lifesaver! We live at the North of the island, far from the grocery stores in Roseau. Like many others, we dreaded having to waste a whole day in town finding what we needed from different stores. Now with ShopDM, we don’t have to!

            They have a wide variety of items on their site with multiple store pickup options so I can get delivery from multiple stores at once (major apps in the US don’t even do this!)
            
            If there is something we need that isn’t listed, their team is quick to source options from other stores and we always get it with our order the same day. Groceries, dog food, medication, even home decor… it’s all available.
            
            The team is very responsive and proactive which I’ve never experienced with another delivery service.
            
            They are also adding helpful features all the time. For example, they just added a new feature that allows customers to request items that are out of stock. I did it today for a wine brand I like and the item was replenished in mere minutes so I could order it.
            
            All of this for a very affordable delivery fee. Between the gas and time I’d spend doing it myself, the fee is next to nothing.
            
            I highly recommend ShopDM!`,
            isFullText: true,
            starRating: 5,
            imageUrls: [],
            id: "5264f419-70e8-44e6-bf40-6bd73e048861",
            threadId: "7f52edcb-0fcd-4d06-b576-aadf06b75614",
            isThreadStart: true,
            type: "REVIEW_TYPE_SHOPDM",
            entityType: "SHOPDM", //SHOPDM / SELLER / DELIVERY PROVIDER / INDIVIDUAL: "",
            entityId: "SHOPDM",
            objectId: "SHOPDM",
            objectType: "SHOPDM",
            isPublic: true,
        },
        {
            name: "Allina Christian",
            createdByEntityId: "lAXj7AAOMSPqtLqhl0MVmvcwXaL2",
            createdByEntityType: "INDIVIDUAL",
            createdByUserId: "lAXj7AAOMSPqtLqhl0MVmvcwXaL2",
            createdByUserName: "Allina Christian",
            profileImageUrl: "",
            createdAt: 	1709172000304, //(limit to 100 chars)
            reviewText: `Thank you shopdm for your assistance in getting groceries to my mom when needed. Your service has been great so far and I’m expecting even better service in time to come. You reach out to ensure that your customers understand the service you provide. You inform your customers if there are issues with the products they order. Products get to my mom in the country side within 24-48 hours of ordering. This is about my 4th or 5th time ordering and will continue to do so. I would encourage anyone looking to assist family members in Dominica with groceries to buy from shopdm.store you won’t regret it. Just shop, pay with credit card, PayPal etc and they deliver. The delivery fee is also not overpriced. It doesn’t matter where you live you can shop through shopdm and your family will get what is ordered. Thank you shopdm for your service.`,
            isFullText: true,
            starRating: 5,
            imageUrls: [],
            id: "f3013ed3-1c83-41e6-a8e8-6dc9f78b8e9b",
            threadId: "ccdb2a94-0176-4155-8798-9ab53962226e",
            isThreadStart: true,
            type: "REVIEW_TYPE_SHOPDM",
            entityType: "SHOPDM", //SHOPDM / SELLER / DELIVERY PROVIDER / INDIVIDUAL: "",
            entityId: "SHOPDM",
            objectId: "SHOPDM",
            objectType: "SHOPDM",
            isPublic: true,
        },
        {
            name: "Joel Hakala",
            createdByEntityId: "Sy7fKsC1sFPCYPJF6xiwGzoFn902",
            createdByEntityType: "INDIVIDUAL",
            createdByUserId: "Sy7fKsC1sFPCYPJF6xiwGzoFn902",
            createdByUserName: "Joel Hakala",
            profileImageUrl: "",
            createdAt: 	1705608780304, //(limit to 100 chars)
            reviewText: `We visited Roseau for groceries and coming from Finland, it wasn’t what we were expecting. Finding parking spots, searching for products, queuing... Luckily there is Shopdm to do it for us. The team is super nice and you can get almost ANYTHING delivered to your door.`,
            isFullText: true,
            starRating: 5,
            imageUrls: [],
            id: "c438221c-80d3-4bc4-8101-0a3aea1e65b8",
            threadId: "9219c517-a940-4ae9-87ec-f6ce77cb49ce",
            isThreadStart: true,
            type: "REVIEW_TYPE_SHOPDM",
            entityType: "SHOPDM", //SHOPDM / SELLER / DELIVERY PROVIDER / INDIVIDUAL: "",
            entityId: "SHOPDM",
            objectId: "SHOPDM",
            objectType: "SHOPDM",
            isPublic: true,
        },
        
    ]
    return (
        <Slider>
            {
                reviewArray.map(
                    review => {
                        const {id, name, profileImageUrl, starRating, createdAt, reviewText, isFullText} = review
                        return (
                        // <div  style={{margin: "10px"}}>
                                <ReviewTile
                                    key={id}
                                    reviewId={id}
                                    name={name}
                                    profileImageUrl={profileImageUrl}
                                    starRating={starRating}
                                    createdAt={createdAt}
                                    reviewText={reviewText}
                                    isFullText={isFullText}
                                    handleOpenReviewModal={() => handleOpenReviewModal(id)}
                            />
                            // </div>
                        )
                    }
                )
            }
        </Slider>
    )
}

export default ReviewSlider