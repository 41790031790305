import React from "react"
import Modal from "../../components/Modal"
import PhotoUpload from "../../components/PhotoUpload"
import Icon from "../../components/Icon"

import styles from "./UpdateImageModal.module.css"

class UpdateImageModal extends React.Component{

    photoUpload = null

    handleChangeImage = async () => {
        if (this.photoUpload) this.photoUpload.handleClick()
    }

    static defaultProps = {
        onDelete: ()=>{},
        onSave: ()=>{},
        onSelect: ()=>{},
        onCrop: ()=>{},
        onCloseModal: ()=>{},
        title: "update photo",
        cropWidth:150,
        cropHeight:150,
        compressImage: true
    }
    render(){
        const {
            title,
            imageUrl, //note, this must be a fixed URL, i.e for the unchanging file on the server 
            onDelete, 
            onSave, 
            onSelect, 
            onCrop,
            onCloseModal, 
            modalOpen, 
            localImageUrl, //note, this must be a fixed URL, i.e for the unchanging file on the local machine
            imageFile, //this is the changing file that can be cropped or moved
            compressImage,
            cropWidth,
            cropHeight,
            cropShape,
            maxImageDimensions,
        } = this.props
        return (
            <Modal
                isOpen={modalOpen}
                closeModal={onCloseModal}
                className={styles.modal}
            >
                <div className={`strong ${styles.title}`}>{title}</div>
                <div className={styles.photoFrame}>
                    <PhotoUpload 
                        ref={ref => this.photoUpload = ref} 
                        imageUrl={localImageUrl} 
                        imageFile={imageFile}
                        onChange={onSelect} 
                        onCrop={onCrop}
                        maxImageDimensions={maxImageDimensions}
                        cropWidth={cropWidth}
                        cropHeight={cropHeight}
                        cropShape={cropShape}
                        compressImage={compressImage}
                    />
                </div>
                <div className={styles.buttonHolder}>
                    {
                        imageUrl || localImageUrl ?
                        <button className="button white icon" onClick={this.handleChangeImage}><Icon icon="photo-camera"/>Change</button>
                        :
                        null
                    }
                    {
                        imageUrl ? 
                        <button className="button white icon" onClick={onDelete}><Icon icon="delete"/>Delete</button>
                        :
                        null
                    }
                    
                    {
                        imageFile ?
                        <button className="button primary icon" onClick={onSave}><Icon icon="done"/>Save</button>
                        :
                        null
                    }
                </div>
            </Modal>
        )
    }
}


export default UpdateImageModal

