import React from "react"
import SearchBar from "../../containers/SearchBar"
import Footer from "../../containers/Footer"
import YourWallet from "../../containers/YourWallet"

import {connect} from "react-redux"
import {Redirect} from "react-router-dom";

import styles from "./ShopdmCredit.module.css"
import BackLink from "../../components/BackLink"
import {Helmet} from "react-helmet";

class ShopdmCredit extends React.Component{
    goBack = () => {
        const {history} = this.props
        history.push('/')
    }
    
    getUserId(){
        const {match} = this.props
        const {userId} = match.params
        return userId
    }

    render(){   
        const {user} = this.props
        const userId = this.getUserId()
        if (!user || !user.authenticated) return <Redirect to={`/login?redirect-from=/shopdm-credit/${userId}`} />
        if (!(user.id === userId)) return <Redirect to="/"/>
        if (!user.defaultWalletId) return <Redirect to="/"/>
        return (
            <div className={[styles.container, "screen"].join(" ")}>
                <Helmet>
                    <title>Your Shopdm Credit</title>
                </Helmet>
                <SearchBar/>
                <div className={["mainContent", "searchBarPresent", styles.mainContent].join(" ")}>
                    <BackLink text="Continue Shopping" onClick={this.goBack}/>
                    <div className={styles.innerContainer}>
                        <YourWallet />
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(ShopdmCredit)