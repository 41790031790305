/**
 * Purpose: Sentry remotely tracks runtime errors from our app
 * View Issues Dashboard here: https://sentry.io/organizations/dom-software/issues/?project=5259660
 */
import * as Sentry from '@sentry/browser';
import {version} from "./release"
import {selectedEnvironment} from "./index"

import configureStore from "../store/configureStore"
import {DateTime} from "luxon"

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://8f5f6b7b31ff49daba5b54dbf1efeda1@o400851.ingest.sentry.io/5259660",
        release: version,
        environment: selectedEnvironment
    });
}

let user={}
let device={}
export let unsubscribeScope = () => {}

export const initScope = () => {
    const store = configureStore()
    unsubscribeScope = store.subscribe(configureScope)
}  

const configureScope  = () => {
    const store = configureStore()
    let state = store.getState()
    if (
        user.id === state.user.id &&
        device.id === state.device.id
    ){
        return false
    }
    user = {...state.user}
    device = {...state.device}
    Sentry.configureScope((scope) => {
        scope.setUser({
            id: user.authenticated ? user.id : "Not Logged In",
            username: user.authenticated ? `${user.firstName} ${user.lastName}` : "No name available",
            email : user.authenticated ? user.email : "No Email"
        });
        
        scope.setExtra("machineLocalTime", DateTime.local().toFormat("MMM dd, yy t ZZ"));
        scope.setExtra("deviceId", device.id)
    });

}