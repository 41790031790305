import React from 'react'

import Footer from "../../containers/Footer"
import SearchBar from "../../containers/SearchBar"
import AddedToBasket from '../../containers/AddedToBasket'
import MobileNavigationBar from "../../containers/MobileNavigationBar"
import CustomerOrders from "../../containers/CustomerOrders";

import {Helmet} from "react-helmet";
import BackLink from "../../components/BackLink"

import {connect} from "react-redux"
import {Redirect} from "react-router-dom";
import {ORDER_PLACED} from "../../constants/navigation"

import styles from "./CustomerProfile.module.css"

class CustomerProfile extends React.Component {

    constructor(props){
        super(props)
        const {history} = props
        const query = new URLSearchParams(history.location.search)
        const lastAction = query.get("last-action")
        this.state = {lastAction}
    }

    componentDidMount(){
        window.scrollTo(0,0)
    }
    getUserId(){
        const {match} = this.props
        const {userId} = match.params
        return userId
    }

    goBack = () => {
        const {history} = this.props
        history.push('/')
    }

    render(){
        const userId = this.getUserId()
        const {user, history} = this.props
        if (!user.authenticated) return <Redirect to={`/login?redirect-from=/customers/${userId}`} />
        if (!userId) return <Redirect to="/"/>
        if (user.id !== userId) return <Redirect to="/"/>
        const {lastAction} = this.state
        return(
            <div className="screen">
                <Helmet>
                    <title>Your Orders</title>
                </Helmet>
                <SearchBar/>
                    <div className={["mainContent", "searchBarPresent", styles.mainContent].join(" ")}>
                        <AddedToBasket zIndex={500}/>
                        <BackLink text="Continue Shopping" onClick={this.goBack}/>
                        {
                            lastAction === ORDER_PLACED ?
                            <div className={'successBar'}>
                                <div>
                                    <div>Thank you for shopping on Shopdm! We've emailed you at {user.email}</div>
                                    <div className="formNotes">Check your inbox to make sure your Shopdm emails aren't going to junk or spam. When your items are ready we'll email you a special code to receive your items.</div>
                                </div>
                            </div>
                            :
                            null
                        }
                        <CustomerOrders />
                        <MobileNavigationBar />
                    </div>
                    <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(CustomerProfile)