import {firebaseApp} from "../config/firebase"
import { getFirestore, collection, query, getDocs, where } from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"
import {
    COUNTRY_SETTLEMENT_LOADING_PERIOD
} from "../constants/loading"

export const SAVE_SETTLEMENTS = "SAVE_SETTLEMENTS"

export const saveSettlements = (settlements, loaded = null) => {
    return {
        type: SAVE_SETTLEMENTS,
        payload: {
            settlements,
            loaded
        }
    }
}

export const fetchSaveCountrySettlements = (
    countryId,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    /**
      * Purpose: retrieve all of the settlements for a country from the firestore database
      */
    const firestore = getFirestore(firebaseApp)
    const settlementsRef = query(collection(firestore, "settlements"),
                                     where("countryId", "==", countryId))
    return async (dispatch, getState) => {
        try {
            const {settlements} = getState()
            //check whether this country's settlements were loaded recently, and if so, do not load again
            if (settlements.loaded.countryIds[countryId] &&
                (Date.now() - settlements.loaded.countryIds[countryId]) <= COUNTRY_SETTLEMENT_LOADING_PERIOD
            ) return true
            const querySnapshot = await getDocs(settlementsRef)
            const countrySettlements = querySnapshot.docs.map(docRef => {
                const settlement = { ...docRef.data()}
                return settlement
            });
            dispatch(saveSettlements(countrySettlements, {countryIds:{[countryId]: Date.now()}}))
            onSuccess()
            return true
        } catch (e){
            const message = `action > settlements > fetchSaveCountrySettlements: Failed to save settlements for country ${countryId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}