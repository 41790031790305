import React from "react"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import CurrentOrderStatus from "../../components/CurrentOrderStatus"
import Icon from "../../components/Icon"
import CustomerOrderTable from "../CustomerOrderTable"

import styles from "./CustomerOrders.module.css"
import {OPENED} from "../../constants/order"
import {getOrderPlacedAt} from "../../utils/orderUtils"

class CustomerOrders extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            currentOrderId : null,
            ordersListener : () => {},
        } 
    }

    componentDidMount = async () => {
        const {actions, user} = this.props
        const ordersListener = await actions.fetchSubscribeToMyOrders(user.id)
        this.setState({ordersListener})
    }

    componentWillUnmount(){
        this.state.ordersListener()
    }

    getOrderArray = orders => {
        const {user} =  this.props
        return Object.values(orders.ordersById)
                            //find all orders made by this cusstomer with a status beyond 'opened'
                            .filter(order => order.customerId === user.id && order.currentStatus !== OPENED)
                            .map(order => {
                                    return {
                                        ...order,
                                        placedAt: getOrderPlacedAt(order)
                                    }
                                })
                            .sort((orderA, orderB) => Number(orderB.placedAt) - Number(orderA.placedAt))
    }
    
    handleSelectOrder = (currentOrderId) => {
        this.setState({currentOrderId})        
        window.scrollTo(0, 0)         
    }
                                             
    render(){
        const {currentOrderId} = this.state
        const {orders} = this.props
        const orderArray = this.getOrderArray(orders)
        const currentOrder = currentOrderId ? 
                            orders.ordersById[currentOrderId]
                            :
                            orderArray.length > 0 ?
                            orderArray[0] :
                            null
        return (
            <div className={styles.container}>
                <div className="title"><Icon icon="local-shipping"/> Track Your Orders</div>
                {
                    orderArray.length > 1 ?
                    <div className={styles.instructions}>Click on a row in the table below to track a different order</div>
                    :
                    null
                }
                <CurrentOrderStatus order={currentOrder}/>
                <CustomerOrderTable 
                    orderArray={orderArray}
                    selectedOrderId={currentOrder ? currentOrder.id: ""} 
                    onClick={orderId => this.handleSelectOrder(orderId, orderArray)}/>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    orders: state.orders,
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  });
  

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrders)
