/**
 *  Purpose: the modifications that are carried on the Users object for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {STATISTICS_IDENTIFIER} from "../constants/analysis"
// STATE
// users:{
//   usersById:{},
//   userIds: [],
//   userIdsByEmail: {},
//   stats,
//   lastLoadedAt: Date.now()
// }


const usersReducer = (state = initialState.users, action) => {
    const {type, payload} = action;
    let usersById = {...state.usersById}
    let userIds = {...state.userIds}
    let userIdsByEmail = {...state.userIdsByEmail}
    let stats = {...state.stats}

    switch (type){
        case types.SAVE_USERS: {
            if(typeof payload.users !== "object") {
                logError(`usersReducer > SAVE_USERS: users payload ${payload} is not an object`)
                return state
            }
            payload.users.forEach(user => {
                if (user.id !== STATISTICS_IDENTIFIER){
                    usersById[user.id] = user
                    userIdsByEmail[user.email] = user.id
                } else stats = user
            })
            return {
                ...state,
                usersById,
                userIds: Object.keys(usersById),
                userIdsByEmail,
                stats,
                lastLoadedAt: Date.now()
            }
        }

        case types.LOGOUT: {
            return initialState.users
        }

        default: return state
    }
}

export default usersReducer