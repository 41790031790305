import React from "react"

import ShareButton from "../../components/ShareButton" 
import Icon from "../../components/Icon"
import SellerLogo from "../SellerLogo"
import SellerBrandHeader from "../SellerBrandHeader"
import FollowButton from "../FollowButton"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import styles from "./SellerProfileBar.module.css"
import {capitalizeAllWords} from "../../utils/stringUtils"
import SellerVideoThumbnail from "../SellerVideoThumbnail"

const SellerProfileBar = ({sellerId, sellers, productIdsBySellerId, user, handleScrollToProducts=()=>{}, actions}) => {
    const seller = sellers.sellersById[sellerId]
    if (!seller) return ""
    return (
        <div className={styles.container}>
            <SellerBrandHeader sellerId={sellerId}/>
            <div className={styles.profileContainer}>
                <div className={styles.profile}>
                    <div className={styles.logo}>
                        <SellerLogo sellerId={sellerId}/>
                    </div>
                    <div className={styles.sellerInfo}>
                        <div>
                            <div className={`title ${styles.name}`}>{seller.name}</div>
                            <div className={styles.followers}>{seller.followerCount} followers</div>
                        </div>
                        <div className={styles.buttonHolder}>
                            <FollowButton sellerId={sellerId}/>
                            <ShareButton text={`Check out ${capitalizeAllWords(seller.name)} on Shopdm`}/>
                        </div>
                        {
                            seller.displayVideo && productIdsBySellerId[seller.id]? 
                                <div><a className={`anchorLink ${styles.goToProducts}`} onClick={handleScrollToProducts}><Icon icon="shopping-cart"/> Go To Products</a></div> 
                            : 
                            null
                        }
                    </div>
                </div>
                <div className={styles.about}>
                    {
                        seller.displayVideo || 
                        seller.roles[user.id]
                        ?
                        <SellerVideoThumbnail 
                            sellerId={sellerId}
                            videoId={seller.displayVideo ? seller.displayVideo.id : ""}
                        />
                        :
                        null
                    }
                    {
                        false ?
                        <div className={styles.description}>
                            <div>
                                It’s here! It’s free. It’s fun. It’s never too late to create long term change. This program is an invitation to move better, feel better, and improve brain and body function significantly. 
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    sellers: state.sellers,
    user: state.user,
    productIdsBySellerId: state.products.productIdsBySellerId
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(SellerProfileBar)