import React from "react"
import {withRouter} from "react-router-dom"
import styles from "./BackLink.module.css"
import Icon from "../Icon"

const DIRECTION_BACK = "back"
const DIRECTION_FORWARD = "forward"
function BackLink({text, path, history, direction=DIRECTION_BACK, onClick= null}){
    const handleClick = onClick ? onClick : () => history.push(path, {from: history.location.pathname})
    return <span className={styles.container} onClick={handleClick}>
                { direction === DIRECTION_BACK ? <Icon icon="keyboard-arrow-left"/> : null}
                {text}
                { direction === DIRECTION_FORWARD ? <Icon icon="keyboard-arrow-right"/> : null}
            </span>
}

export default withRouter(BackLink);