import {firebaseApp} from "../config/firebase"
import {doc, getFirestore, updateDoc, writeBatch, arrayUnion} from "firebase/firestore"
import { logError } from "../utils/errorHandlingUtils"
import {token} from "../config/ipinfo"
import {v4 as uuid4} from 'uuid'
import {isMobileOnly, isBrowser, isIOS, isAndroid} from "react-device-detect"
import { getDateString, getTimeString } from "../utils/datetimeUtils"
import {WEEK_IN_MILLISECONDS} from "../constants/datetime"
import { getMessaging, getToken, isSupported} from "firebase/messaging";
import { NOTIFICATIONS_NOT_SUPPORTED} from "../constants/firebase"

import { vapidKey } from "../config/firebase"

export const CREATE_DEVICE = 'CREATE_DEVICE'
export const UPDATE_DEVICE = 'UPDATE_DEVICE'
export const LOG_DEVICE_SESSION_ACTION = 'LOG_DEVICE_SESSION_ACTION'

export const createDevice = device => {
    return {
        type: CREATE_DEVICE,
        payload: {
            device
        }
    }
}

export const updateDevice = deviceUpdate => {
    return {
        type: UPDATE_DEVICE,
        payload: {
            deviceUpdate
        }
    }
}

export const logDeviceSessionAction = action => {
    return {
        type: LOG_DEVICE_SESSION_ACTION,
        payload: {
            action
        }
    }
}

export const fetchLogDeviceSessionAction = (action, onSuccess=()=>{}, onError=()=>{}) => {
    return async (dispatch, getState) => {
        action.at = Date.now()
        try {
            const {device} = getState()
            const firestore = getFirestore(firebaseApp)
            const sessionRef = doc(firestore, `devices/${device.id}/sessions`, device.lastSessionId)
            await updateDoc(sessionRef, {
                actions: arrayUnion(action)
            })
            dispatch(logDeviceSessionAction(action))
            onSuccess(action)
            return true
        } catch (e){
            const message = `${JSON.stringify(action)} action > devices > fetchLogDeviceSessionAction: Failed to add action to device session`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(action)
            return false
        }
    }
}

const fetchIPInformation = async () => {
    try {
        const p = await fetch(`https://ipinfo.io?token=${token}`)
        return await p.json()
    } catch (e){
        const message = `action > devices > fetchIPInformation: Failed to fetch IP Information`
        if (e.message_){
            //deal with firebase-specific errors
            logError(new Error(`${e.message} ${message}`))
        } else {
            e.message = `${e.message} ${message}`
            logError(e)
        }
        return {error: true}
    }
}

export const fetchUpdateDevice = (id, onSuccess=()=>{}, onError=()=>{}) => {
    const firestore = getFirestore(firebaseApp)
    const devicesRef = doc(firestore, "devices", id)
    let deviceUpdate = {}
    return async (dispatch, getState) => {
        try{
            const {user, device} = getState()
            const lastSessionId = `${getDateString()}-at-${getTimeString()}`
            let ipInfo = {}
            if (!device.lastUpdatedIPInfo || ((Date.now() - device.lastUpdatedIPInfo) > WEEK_IN_MILLISECONDS)){
                //if a week has passed since the last update to ip information
                const {city="", country="", ip="", loc="", org="", region="", timezone=""} = await fetchIPInformation()
                ipInfo = {
                    cityName: city ? city.toLowerCase() : "",
                    latLon: loc,
                    ip,
                    org,
                    region,
                    timezone,
                    countryCode: country ? country.toLowerCase() : "",
                    lastUpdatedIPInfo: Date.now()
                }
            }
            const userAgent = device.userAgent ? device.userAgent : window && window.navigator ? window.navigator.userAgent : ""
            deviceUpdate = {
                lastSignedInUserId: user.id ? user.id : device.lastSignedInUserId,
                lastVisitedAt: Date.now(),
                lastVisitDate: getDateString(),
                totalVisits: device.totalVisits + 1,
                lastSessionId,
                userAgent,
                ...ipInfo
            }
            const batch = writeBatch(firestore)
            batch.set(devicesRef, deviceUpdate, {merge: true})
            const sessionsRef = doc(firestore, `devices/${id}/sessions`, lastSessionId)
            const countryCode = ipInfo.countryCode ? 
                                ipInfo.countryCode : 
                                device.countryCode ? 
                                device.countryCode
                                :
                                ""
            batch.set(sessionsRef,{
                id: lastSessionId,
                actions: [{action: "startSession", countryCode}]
            })
            await batch.commit()
            dispatch(updateDevice(deviceUpdate))
            onSuccess(deviceUpdate)
            return true
        } catch (e){
            const message = `action > devices > fetchUpdateDevice: Failed to update device ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(deviceUpdate)
            return false
        } 
    }
}

export const fetchCreateDevice = (onSuccess=()=>{}, onError=()=>{}) => {
    const id = uuid4()
    const firestore = getFirestore(firebaseApp)
    const devicesRef = doc(firestore, "devices", id)
    let device = {id}
    return async (dispatch, getState) => {
        try{
            const ipInfo = await fetchIPInformation()
            if (!ipInfo.error){
                const {city="", country="", ip="", loc="", org="", region="", timezone=""} = ipInfo
                device = {
                    id,
                    cityName: city ? city.toLowerCase() : "",
                    latLon: loc,
                    ip,
                    org,
                    region,
                    timezone,
                    countryCode: country ? country.toLowerCase() : "",
                    lastUpdatedIPInfo: Date.now()
                }
            }
            const {user} = getState()
            const lastSessionId = `${getDateString()}-at-${getTimeString()}`
            const deviceType = isMobileOnly ? "mobile" : isBrowser ? "desktop" : "tablet"
            const userAgent = window && window.navigator ? window.navigator.userAgent : ""
            //separate building of the device object as there may be internet issues
            device = {
                ...device,
                deviceType,
                lastSignedInUserId: user.id ? user.id : null,
                mobileOSType: isIOS ? "ios" : isAndroid ? "android" : "not mobile",
                firstVisitedAt: Date.now(),
                firstVisitDate: getDateString(),
                lastVisitedAt: Date.now(),
                lastVisitDate: getDateString(),
                totalVisits: 1,
                lastSessionId,
                userAgent
            }
            const batch = writeBatch(firestore)
            batch.set(devicesRef, device)
            const sessionsRef = doc(firestore, `devices/${id}/sessions`, lastSessionId)
            batch.set(sessionsRef,{
                id: lastSessionId,
                actions: [{action: "startSession"}]
            })
            //if a user was signed in on this device, add it to their list of devices
            if (user.id){
                const userRef = doc(firestore, "users", user.id)
                batch.set(userRef, {
                    devices: {[id]: {id, deviceType}}
                }, {merge: true})
            }
            await batch.commit()
            dispatch(createDevice(device))
            onSuccess(device)
            return true
        } catch (e){
            const message = `action > devices > fetchCreateDevice: Failed to create device ${JSON.stringify(device)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(device)
            return false
        } 
    }
}

export const fetchUpdateDeviceMessagingToken = (id, onSuccess=()=>{}, onError=()=>{}) => {
    const result = {success: false, error: ""}
    return async (dispatch, getState) => {
        try{
            const {user, device} = getState()
            const supported = await isSupported()
            if (!supported) {
                throw new Error(NOTIFICATIONS_NOT_SUPPORTED)
            }
            if (!user.authenticated || !user.id) {
                result.error = "User not authenticated"
                return result
            }
            const messaging = getMessaging();
            const messagingToken = await getToken(messaging, {vapidKey})
            result.success = true
            result.messagingToken = messagingToken
            //if the messaging token has not changed, do not update the server
            if (messagingToken === device.messagingToken) return result
            const firestore = getFirestore(firebaseApp)
            const devicesRef = doc(firestore, "devices", id)
            const batch = writeBatch(firestore)
            const deviceUpdate = {
                messagingToken,
                lastUpdatedMessagingToken: Date.now()
            }
            batch.set(devicesRef, deviceUpdate, {merge: true})
            const userRef = doc(firestore, "users", user.id)
            const {deviceType=""} = device
            batch.set(userRef, {
                devices: {[id]: {id, messagingToken, deviceType}}
            }, {merge: true})
            await batch.commit()
            dispatch(updateDevice(deviceUpdate))
            return result
        } catch (e){
            const message = `action > devices > fetchUpdateDeviceMessagingToken: Failed to update messaging token for device ${id}`
            const errorMessage = `${e.message} ${message}`
            logError(new Error(errorMessage))
            console.log(errorMessage)
            onError()
            return ({
                success: false,
                error: errorMessage
            })
        } 
    }
}