/**
 *  Purpose: the modifications that are carried on the Topup objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 import {STATISTICS_IDENTIFIER} from "../constants/analysis"
 
 // STATE
 // topups:{
 //   currentTopupId: "",
 //   topupsById:{},
 //   topupIds: []
 //   stats: {}
 // }
 
 
 const topupsReducer = (state = initialState.topups, action) => {
     const {type, payload} = action;
     let topupsById = {...state.topupsById}
     let stats = {...state.stats}
 
     switch (type){
         case types.CREATE_TOPUP: {
             if(typeof payload.topup !== "object") {
                 logError(`topupsReducer > CREATE_TOPUP: topup payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             topupsById[payload.topup.id] = payload.topup
             return {
                ...state,
                currentTopupId: payload.topup.id,
                topupsById,
                topupIds: Object.keys(topupsById)
             }
         }

         case types.SAVE_TOPUPS: {
            if(typeof payload.topups !== "object") {
                logError(`topupsReducer > SAVE_TOPUPS: topups payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.topups.forEach(topup => {
                if (topup.id !== STATISTICS_IDENTIFIER){
                    topupsById[topup.id] = topup
                } else stats = topup
            });
            return {
                ...state,
                topupsById,
                stats,
                topupIds: Object.keys(topupsById)
            }
        }

        case types.CLEAR_CURRENT_TOPUP: {
            return {
                ...state,
                currentTopupId: ""
            }
        }
        
        case types.LOGOUT: {
            return initialState.topups
        }

        default: return state
    }
}

export default topupsReducer