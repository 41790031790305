import React from "react"
import styles from "./LineItem.module.css"

const LineItem = ({
    label, 
    value, 
    quantity, 
    operation,
    bold=false,
    color=""
}) => {
    const boldStyle = bold ? {fontWeight: 700} : {}
    const colorStyle = color ? {color} : {}
    const operationStyle = operation === "-" ? styles.minus :
                           operation === "+" ? styles.plus: 
                           ""
    return (
        <div style={{...colorStyle, ...boldStyle}} className={`lineItem ${operationStyle}`}>
            <div styles={boldStyle} className={`lineItemLabel`}>
                {
                    quantity ? 
                    <span className="lineItemQuantity">{`${quantity}x`}</span>
                    :
                    null
                }
                <span>{label}:</span>
            </div>
            <span styles={boldStyle} className={`${styles.value}`}>{operation ? operation : ""} EC ${value.toFixed(2)}</span>
        </div>
    )
}

export default LineItem