import React from "react"
import DateSlider from "../../components/DateSlider";
import ScheduleList from "../../components/ScheduleList";
import styles from "./DeliverySlotPicker.module.css"

import {DEFAULT_DELIVERY_SLOTS} from "../../constants/delivery"
import {getTimestampForStartOfDay, timestampToDateString, timestampToWeekStartTimestamp} from "../../utils/datetimeUtils"
import {DAY_IN_MILLISECONDS, DOMINICA_TIMEZONE} from "../../constants/datetime"

class DeliverySlotPicker extends React.PureComponent {

    constructor(props){
        super(props)
        let {selectedSlot, timezone} = props
        const startOfToday = getTimestampForStartOfDay(Date.now(), !timezone, timezone)
        //active date is just the currently active date in the date picker and schedule list
        const activeDate = selectedSlot ? 
                         getTimestampForStartOfDay(selectedSlot.startTime, !timezone, timezone) 
                         : 
                         startOfToday
        //do not allow deliveries to be scheduled before today
        const minDate = startOfToday
        //min date must always be greater than or equal to min date
        const minDateTime = props.minDateTime >= minDate ? props.minDateTime : Date.now()
        //allow deliveries to be scheduled up to 2 months into the future
        const maxDate= startOfToday + (60 * DAY_IN_MILLISECONDS) - 1
        //store the end of the week
        const startOfWeek = timestampToWeekStartTimestamp(activeDate, !timezone, timezone)
        const endOfWeek = startOfWeek + (7 * DAY_IN_MILLISECONDS) - 1
        this.state = {
            activeDate,
            minDate,
            minDateTime,
            maxDate,
            endOfWeek,
        }
    }

    static defaultProps = {
        timezone: DOMINICA_TIMEZONE,
        selectedSlot: null,
        onSelectDeliverySlot: () => {},
        onDeselectDeliverySlot: () => {},
        supportedSlots: Object.values(DEFAULT_DELIVERY_SLOTS.types),
        isLoaded: false,
        weeklyAvailableSlotMap: {},
        excludedDayMap: {}, //any specific days that should de excluded due to special cases e.g. holidays
        minDateTime: Date.now()
    }

    static getDerivedStateFromProps(props, state){
        let {minDateTime} = props
        //if the min date time is updated externally, update it in state
        //the min date determines which slots are shown, 
        //the min date time determines which slots are available
        if (minDateTime && minDateTime !== state.minDateTime) {
            minDateTime = minDateTime >= state.minDate ? minDateTime : Date.now()
            return {minDateTime}
        }
        return null
    }

    handleChangeActiveDate = activeDate => {
        const {timezone} = this.props
        const startOfActiveDate = getTimestampForStartOfDay(activeDate, !timezone, timezone)
        const startOfWeek = timestampToWeekStartTimestamp(activeDate, !timezone, timezone)
        const endOfWeek = startOfWeek + (7 * DAY_IN_MILLISECONDS) - 1
        this.setState({
            activeDate: startOfActiveDate,
            endOfWeek
        })
    }

    handleSelectSlot = (startTime, endTime, timezone, type) => {
        const {onSelectDeliverySlot, onDeselectDeliverySlot, selectedSlot} = this.props
        if (
            selectedSlot && 
            selectedSlot.startTime === startTime &&
            selectedSlot.endTime === endTime
        ){
            //a selected slot is re-selected, deselect it  
            onDeselectDeliverySlot(selectedSlot.id)
        }
        else onSelectDeliverySlot(startTime, endTime, timezone, type)
    }

    render (){
        const {timezone, selectedSlot, TopRight, supportedSlots, isLoaded, weeklyAvailableSlotMap, excludedDayMap} = this.props
        const {minDate, minDateTime, maxDate, activeDate} = this.state
        return (
            <div className={styles.container}>
                <div className={styles.top}>
                    <div className={styles.selection}>
                            Go to selected date: <span 
                                    className={selectedSlot ? styles.date: ""}
                                    onClick={selectedSlot ? () => this.handleChangeActiveDate(selectedSlot.startTime) : ()=>{}}
                                >
                                    { selectedSlot ? timestampToDateString(selectedSlot.startTime, "MMMM dd", !timezone, timezone) : "None"}
                                </span>
                    </div>
                    {
                        TopRight ?
                        <div className={styles.topRight}>
                            <TopRight />
                        </div>
                        :
                        null
                    }
                </div>
                <div className={styles.dateSlider}>
                    <DateSlider 
                        selectedDate={selectedSlot ? selectedSlot.startTime : null} 
                        activeDate={activeDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        timezone={timezone}
                        onChangeActiveDate={this.handleChangeActiveDate}
                    />
                </div>
                {
                    //display the schedule list only once its slots are loaded
                    isLoaded ?
                        <ScheduleList
                            activeScheduleId={activeDate} //dates are used as the id
                            startDate={minDate}
                            minDateTime={minDateTime}
                            endDate={maxDate}
                            supportedSlots={supportedSlots}
                            weeklyAvailableSlotMap={weeklyAvailableSlotMap}
                            excludedDayMap={excludedDayMap}
                            timezone={timezone}
                            onChangeActiveSchedule={this.handleChangeActiveDate}
                            onSelectSlot={this.handleSelectSlot}
                            selectedSlotId={selectedSlot ? selectedSlot.startTime : null}
                        />
                    :
                    null
                }
            </div>
        )
    }
}

export default DeliverySlotPicker