import React from "react"
import styles from "./UserAddressModal.module.css"
import Modal from "../../components/Modal"
import RadioButton from "../../components/RadioButton"
import DisabledButton from "../../components/DisabledButton"

import CreateBuyerAddressForm from "../CreateBuyerAddressForm"
import EditBuyerAddressForm from "../EditBuyerAddressForm"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {addressObjectToString, limitTextLength} from "../../utils/stringUtils"

import {logAnalyticsEvent} from "../../config/firebase"

class UserAddressModal extends React.Component {
    state = {
        isAdding: false,
        isEditing: false,
        editAddressId: ""
    }

    handleAddAddress = () => this.setState({isAdding: true, isEditing: false})
    handleEditAddress = editAddressId => this.setState({isAdding: false, isEditing: true, editAddressId})
    
    handleFinishAddAddress = () => {
        const {handleCloseModal} = this.props
        logAnalyticsEvent("add_another_address")
        this.setState({isAdding: false, isEditing: false, editAddressId: ""})
        setTimeout(handleCloseModal, 1000)
    }
    
    handleFinishEditAddress = () => {
        logAnalyticsEvent("edit_address")
        this.setState({isAdding: false, isEditing: false, editAddressId: ""})
    }

    handleSelectAddress = async addressId => {
        const {actions, user, handleCloseModal} = this.props
        if (addressId !== user.defaultAddressId){
            actions.toggleLoading(true)
            await actions.fetchSetDefaultUserAddress(addressId)
            actions.toggleLoading(false)
            setTimeout(handleCloseModal, 1000)
        }
    }

    handleDeleteAddress = async addressId => {
        const {actions} = this.props
        actions.toggleLoading(true)
        await actions.fetchDeleteUserAddress(addressId)
        actions.toggleLoading(false)
    }

    render(){
        const {user, handleCloseModal, isOpen} = this.props
        const selectedAddress = user.addressesById[user.defaultAddressId]
        const addressList = Object.keys(user.addressesById).reduce((list, addressId) => {
            if (addressId === selectedAddress.id) return list
            const address = user.addressesById[addressId]
            list.push(address)
            return list
        }, [selectedAddress])
        const {isAdding, isEditing, editAddressId} = this.state
        return (
            <Modal 
                isOpen={isOpen}
                closeModal={handleCloseModal}
                className={styles.modal}
                closeOnOverlayClick={true}
            >
            {
                isAdding ? 
                    <CreateBuyerAddressForm buttonText="Save And Change Address" postSubmit={this.handleFinishAddAddress}/>
                :

                isEditing? 
                    <EditBuyerAddressForm addressId={editAddressId} postSubmit={this.handleFinishEditAddress} />
                :
                    <React.Fragment>
                    <div className={['strong', styles.modalTitle].join(" ")}>Add Or Change Delivery Address</div>
                        <div className={styles.addButton}>
                            <button onClick={this.handleAddAddress} className="button primary">Add A New Address</button>
                        </div>
                        {
                            addressList.map(address => <AddressTile 
                                                            key={address.id} 
                                                            address={address}
                                                            selected={address.id === selectedAddress.id}
                                                            addressCount={addressList.length}
                                                            onClick={() => {this.handleSelectAddress(address.id); logAnalyticsEvent("choose_other_address")}}
                                                            onEdit={() => this.handleEditAddress(address.id)}
                                                            onDelete={()=> {this.handleDeleteAddress(address.id); logAnalyticsEvent("delete_address")}}
                                                        />)
                        }
                    </React.Fragment>   

            }
            </Modal>
        )
    }
}

function AddressTile({address={}, selected=false, addressCount, onClick=()=>{}, onEdit=()=>{}, onDelete=()=>{}}){
    const selectedStyle = selected ? styles.selected : ""
    return (
        <div className={[styles.addressContainer, selectedStyle].join(" ")}>
            <div className={styles.addressOption} onClick={onClick}>
                <RadioButton checked={selected}/>
                <div>
                    <div>{addressObjectToString(address)}</div>
                    <div className={styles.directions}>{limitTextLength(address.directions, 100)}</div>
                    <div className={styles.phone}>{address.contactNumber}</div>
                </div>
            </div>
            <div className={styles.buttons}>
                <button className="button dark" onClick={onEdit}>Edit</button>
                {
                    addressCount > 1 ?
                        <button onClick={onDelete} className="button">Delete</button>
                    :
                        <DisabledButton
                            alert="Add a second address before you delete this one"
                        >
                            Delete
                        </DisabledButton>
                }
            </div>
        </div>
    )
}

const mapStatetoProps = state => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStatetoProps, mapDispatchToProps)(UserAddressModal)