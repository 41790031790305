/**
 *  Purpose: the modifications that are carried on the Seller objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {STATISTICS_IDENTIFIER} from "../constants/analysis"
// STATE
// sellers:{
//  sellersById:{},
//  sellerIdsByProductId: {},
//  sellerIds: {},
//  inactiveSellerIds: {},
//  accountingDocsBySellerId: {},
//  activityBySellerId: {},
//  loadedProductIdsBySellerPage: {},
// }


const sellersReducer = (state = initialState.sellers, action) => {
    const {type, payload} = action;
    let sellersById = {...state.sellersById}
    let sellerIds = {...state.sellerIds}
    let sellerIdsByProductId = {...state.sellerIdsByProductId}
    let inactiveSellerIds = {...state.inactiveSellerIds}
    let roleNotificationsBySellerId = {...state.roleNotificationsBySellerId}
    let accountingDocsBySellerId = {...state.accountingDocsBySellerId}
    let activityBySellerId = {...state.activityBySellerId}
    let loadedProductIdsBySellerPage = {...state.loadedProductIdsBySellerPage}
    switch (type){
        case types.SAVE_SELLERS: {
            if(typeof payload.sellers !== "object") {
                logError(`sellersReducer > SAVE_SELLERS: sellers payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.sellers.forEach(seller => {
                if (!seller.followerCount) seller.followerCount = 0
                sellersById[seller.id] = seller
                if (!seller.isActive) inactiveSellerIds[seller.id] = true
            })
            return {
                ...state,
                sellersById,
                inactiveSellerIds,
                sellerIds: Object.keys(sellersById)
            }
        }

        case types.CREATE_SELLER: {
            if(typeof payload.seller !== "object") {
                logError(`sellersReducer > CREATE_SELLER: seller payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            sellersById[payload.seller.id] = payload.seller
            if (!payload.seller.isActive) inactiveSellerIds[payload.seller.id] = true
            return {
                ...state,
                sellersById,
                inactiveSellerIds,
                sellerIds: Object.keys(sellersById)
            }
        }
        
        case types.CREATE_PRODUCT: {
            if(typeof payload.product !== "object") {
                return state
            }
            if(typeof payload.sellerId !== "string") {
                return state
            }
    
            if (!sellerIdsByProductId[payload.product.id]) sellerIdsByProductId[payload.product.id] = {}
            sellerIdsByProductId[payload.product.id][payload.sellerId] = true
            return {
                ...state,
                sellerIdsByProductId,
            }
        }

        //we listen to the product collection so sometimes this response arrives before CREATE_PRODUCT
        case types.SAVE_PRODUCTS: {
            if(typeof payload.products !== "object") {
                return state
            }
            
            payload.products.forEach(product => {
                if (product.id === STATISTICS_IDENTIFIER) return
                if (!sellerIdsByProductId[product.id]) sellerIdsByProductId[product.id] = {}
                sellerIdsByProductId[product.id][product.createdBySellerId] = true
                const seller = {...sellersById[product.createdBySellerId]}
                sellersById[product.createdBySellerId] = seller
            })
            return {
                ...state,
                sellersById,
                sellerIdsByProductId,
                sellerIds: Object.keys(sellersById)
            }
        }

        case types.DELETE_PRODUCT: {
            if(typeof payload.productId !== "string") {
                return state
            }
            delete sellerIdsByProductId[payload.productId]
            return {
                ...state,
                sellerIdsByProductId,
            }
        }

        case types.ADD_SELLER_ROLE: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > ADD_SELLER_ROLE: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.userId !== "string") {
                logError(`sellersReducer > ADD_SELLER_ROLE: userId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.roleName !== "string") {
                logError(`sellersReducer > ADD_SELLER_ROLE: roleName in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            return{
                ...state,
                sellersById: {
                    ...sellersById,
                    [payload.sellerId] : {
                        ...sellersById[payload.sellerId],
                        roles: {
                            ...sellersById[payload.sellerId].roles,
                            [payload.userId]: payload.roleName
                        }
                    }
                }
            }
        }

        case types.DELETE_SELLER_ROLE: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > DELETE_SELLER_ROLE: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.userId !== "string") {
                logError(`sellersReducer > DELETE_SELLER_ROLE: userId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            const roles = {...sellersById[payload.sellerId].roles}
            delete roles[payload.userId]
            return{
                ...state,
                sellersById: {
                    ...sellersById,
                    [payload.sellerId] : {
                        ...sellersById[payload.sellerId],
                        roles
                    }
                }
            }
        }

        case types.UPDATE_SELLER_ISACTIVE: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > UPDATE_SELLER_ISACTIVE: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.isActive !== "boolean") {
                logError(`sellersReducer > UPDATE_SELLER_ISACTIVE: isActive in payload is not a boolean ${JSON.stringify(payload)}`)
                return state
            }
            if (payload.isActive && inactiveSellerIds[payload.sellerId]){
                delete inactiveSellerIds[payload.sellerId]
            } else if (!payload.isActive && !inactiveSellerIds[payload.sellerId]){
                inactiveSellerIds[payload.sellerId] = true
            }
            return{
                ...state,
                sellersById: {
                    ...sellersById,
                    [payload.sellerId] : {
                        ...sellersById[payload.sellerId],
                        isActive: payload.isActive
                    }
                },
                inactiveSellerIds
            }
        }

        case types.UPDATE_SELLER_IS_SAME_DAY: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > UPDATE_SELLER_IS_SAME_DAY: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.isSameDay !== "boolean") {
                logError(`sellersReducer > UPDATE_SELLER_IS_SAME_DAY: isSameDay in payload is not a boolean ${JSON.stringify(payload)}`)
                return state
            }
            return{
                ...state,
                sellersById: {
                    ...sellersById,
                    [payload.sellerId] : {
                        ...sellersById[payload.sellerId],
                        isSameDay: payload.isSameDay
                    }
                },
            }
        }

        case types.ADD_SELLER_ADDRESS: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > ADD_SELLER_ADDRESS: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.address !== "object") {
                logError(`sellersReducer > ADD_SELLER_ADDRESS: address in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            sellersById[payload.sellerId] = {
                ...sellersById[payload.sellerId],
                addressesById : {
                    ...sellersById[payload.sellerId].addressesById,
                    [payload.address.id] : payload.address
                }
            }
            return {
                ...state,
                sellersById
            }
        }   

        case types.DELETE_SELLER_ADDRESS: {
            if(typeof payload.id !== "string") {
                logError(`sellersReducer > DELETE_SELLER_ADDRESS: id in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.addressId !== "string") {
                logError(`sellersReducer > DELETE_SELLER_ADDRESS: addressId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            sellersById[payload.id] = {
                ...sellersById[payload.id],
                addressesById : {
                    ...sellersById[payload.id].addressesById,
                    [payload.addressId] : false
                }
            }
            delete sellersById[payload.id].addressesById[payload.addressId]
            return {
                ...state,
                sellersById
            }
        }  

        case types.UPDATE_SELLER_SUPPORTED_DELIVERY_PROVIDERS: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > UPDATE_SELLER_SUPPORTED_DELIVERY_PROVIDERS: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.supportedDeliveryProviders !== "object") {
                logError(`sellersReducer > UPDATE_SELLER_SUPPORTED_DELIVERY_PROVIDERS: supportedDeliveryProviders in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            sellersById[payload.sellerId] = {
                ...sellersById[payload.sellerId],
                supportedDeliveryProviders : [...payload.supportedDeliveryProviders]
            }
            return {
                ...state,
                sellersById
            }
        }

        case types.UPDATE_SELLER_SELF_DELIVERY_SETTINGS: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > UPDATE_SELLER_SELF_DELIVERY_SETTINGS: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.selfDelivery !== "object") {
                logError(`sellersReducer > UPDATE_SELLER_SELF_DELIVERY_SETTINGS: selfDelivery in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            sellersById[payload.sellerId] = {
                ...sellersById[payload.sellerId],
                selfDelivery : {...payload.selfDelivery}
            }
            return {
                ...state,
                sellersById
            }
        }

        case types.UPDATE_SELLER_ADDRESS_OPENING_HOURS: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > UPDATE_SELLER_ADDRESS_OPENING_HOURS: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.addressId !== "string") {
                logError(`sellersReducer > UPDATE_SELLER_ADDRESS_OPENING_HOURS: addressId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.openingHours !== "object") {
                logError(`sellersReducer > UPDATE_SELLER_ADDRESS_OPENING_HOURS: openingHours in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            sellersById[payload.sellerId] = {
                ...sellersById[payload.sellerId],
                addressesById: {
                    ...sellersById[payload.sellerId].addressesById,
                    [payload.addressId]: {
                        ...sellersById[payload.sellerId].addressesById[payload.addressId],
                        openingHours: payload.openingHours
                    }
                } 
            }
            return {
                ...state,
                sellersById
            }
        }

        case types.SET_SELLER_DEFAULT_ADDRESS: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > SET_SELLER_DEFAULT_ADDRESS: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.addressId !== "string") {
                logError(`sellersReducer > SET_SELLER_DEFAULT_ADDRESS: addressId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            sellersById[payload.sellerId] = {
                ...sellersById[payload.sellerId],
                defaultAddressId: payload.addressId
            }
            return {
                ...state,
                sellersById
            }
        }

        case types.UPDATE_SELLER_SUPPORTED_PAYMENT_PROVIDERS: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > UPDATE_SELLER_SUPPORTED_PAYMENT_PROVIDERS: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.supportedPaymentProviders !== "object") {
                logError(`sellersReducer > UPDATE_SELLER_SUPPORTED_PAYMENT_PROVIDERS: supportedPaymentProviders in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            sellersById[payload.sellerId] = {
                ...sellersById[payload.sellerId],
                supportedPaymentProviders : [...payload.supportedPaymentProviders]
            }
            return {
                ...state,
                sellersById
            }
        }

        case types.SET_SELLER_LOGO: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > SET_SELLER_LOGO: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.logoImageUrl !== "string") {
                logError(`sellersReducer > SET_SELLER_LOGO: logoImageUrl in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.logoImageUrlMed !== "string") {
                logError(`sellersReducer > SET_SELLER_LOGO: logoImageUrlMed in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.logoImageUrlSmall !== "string") {
                logError(`sellersReducer > SET_SELLER_LOGO: logoImageUrlSmall in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            const {logoImageUrl, logoImageUrlMed, logoImageUrlSmall} = payload
            sellersById[payload.sellerId] = {
                ...sellersById[payload.sellerId],
                logoImageUrl,
                logoImageUrlMed,
                logoImageUrlSmall
            }
            return {
                ...state,
                sellersById
            }
        }

        case types.SET_SELLER_HEADER: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > SET_SELLER_HEADER: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.headerImageUrl !== "string") {
                logError(`sellersReducer > SET_SELLER_HEADER: headerImageUrl in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }

            if(typeof payload.headerImageUrlMed !== "string") {
                logError(`sellersReducer > SET_SELLER_HEADER: headerImageUrlMed in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }

            if(typeof payload.headerImageUrlSmall !== "string") {
                logError(`sellersReducer > SET_SELLER_HEADER: headerImageUrlSmall in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            const {headerImageUrl, headerImageUrlMed, headerImageUrlSmall} = payload
            sellersById[payload.sellerId] = {
                ...sellersById[payload.sellerId],
                headerImageUrl,
                headerImageUrlMed,
                headerImageUrlSmall
            }
            return {
                ...state,
                sellersById
            }
        }

        case types.FOLLOW_SELLER: {
            if(typeof payload.sellerId !== "string") {
                return state
            }
            sellersById[payload.sellerId] = {
                ...sellersById[payload.sellerId],
                followerCount: sellersById[payload.sellerId].followerCount + 1
            }
            return {
                ...state,
                sellersById
            }
        }

        case types.UNFOLLOW_SELLER: {
            if(typeof payload.sellerId !== "string") {
                return state
            }
            sellersById[payload.sellerId] = {
                ...sellersById[payload.sellerId],
                followerCount: sellersById[payload.sellerId].followerCount - 1
            }
            return {
                ...state,
                sellersById
            }
        }

        case types.EMBED_SELLER_VIDEO: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > EMBED_SELLER_VIDEO: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.video !== "object") {
                logError(`sellersReducer > EMBED_SELLER_VIDEO: video in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            const {isDisplayVideo, video, sellerId} = payload
            //if the video which has just been embedded is also the seller's display video
            if (isDisplayVideo){
                sellersById[sellerId] = {
                    ...sellersById[sellerId],
                    displayVideo: {
                        ...sellersById[sellerId].displayVideo,
                        ...video
                    }
                }
            }
            return {
                ...state,
                sellersById
            }
        }

        case types.DELETE_SELLER_VIDEO: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > DELETE_SELLER_VIDEO: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if (typeof payload.videoId !== "string"){
                logError(`sellersReducer > DELETE_SELLER_VIDEO: videoId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            const {sellerId, videoId, isDisplayVideo, newDisplayVideo} = payload
            if (isDisplayVideo){
                sellersById[sellerId] = {
                    ...sellersById[sellerId],
                    displayVideo: newDisplayVideo ? newDisplayVideo : null
                } 
            }
            return {
                ...state,
                sellersById,
            }
        }
        
        case types.SAVE_SELLER_ROLE_NOTIFICATIONS: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > SAVE_SELLER_ROLE_NOTIFICATIONS: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.roleNotifications !== "object") {
                logError(`sellersReducer > SAVE_SELLER_ROLE_NOTIFICATIONS: roleNotifications in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                roleNotificationsBySellerId: {
                    ...roleNotificationsBySellerId,
                    [payload.sellerId]: {
                        ...roleNotificationsBySellerId[payload.sellerId],
                        ...payload.roleNotifications
                    },
                }
            }
        }

        case types.ADD_SELLER_ROLE_NOTIFICATION: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > ADD_SELLER_ROLE_NOTIFICATION: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.userId !== "string") {
                logError(`sellersReducer > ADD_SELLER_ROLE_NOTIFICATION: userId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.deviceId !== "string") {
                logError(`sellersReducer > ADD_SELLER_ROLE_NOTIFICATION: deviceId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.messagingToken !== "string") {
                logError(`sellersReducer > ADD_SELLER_ROLE_NOTIFICATION: messagingToken in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if (!roleNotificationsBySellerId[payload.sellerId]) roleNotificationsBySellerId[payload.sellerId] = {}
            if (!roleNotificationsBySellerId[payload.sellerId][payload.userId]) roleNotificationsBySellerId[payload.sellerId][payload.userId] = {}
            //update the messsaging token for this device
            roleNotificationsBySellerId[payload.sellerId][payload.userId][payload.deviceId] = payload.messagingToken
            return {
                ...state,
                roleNotificationsBySellerId
            }
        }

        case types.SAVE_SELLER_ACCOUNTING_DOC: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > SAVE_SELLER_ACCOUNTING_DOC: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.accountingDoc !== "object") {
                logError(`sellersReducer > SAVE_SELLER_ACCOUNTING_DOC: accountingDoc in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if (!accountingDocsBySellerId[payload.sellerId]) accountingDocsBySellerId[payload.sellerId] = {}
            accountingDocsBySellerId[payload.sellerId][payload.accountingDoc.id] = payload.accountingDoc
            return {
                ...state,
                accountingDocsBySellerId
            }
        }

        case types.UPDATE_SELLER_ACTIVITY: {
            if(typeof payload.sellerId !== "string") {
                logError(`sellersReducer > UPDATE_SELLER_ACTIVITY: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.activityId !== "string") {
                logError(`sellersReducer > UPDATE_SELLER_ACTIVITY: activityId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.activityUpdate !== "object") {
                logError(`sellersReducer > UPDATE_SELLER_ACTIVITY: activityUpdate in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }

            return {
                ...state,
                activityBySellerId: {
                    ...state.activityBySellerId,
                    [payload.sellerId]: {
                        ...state.activityBySellerId[payload.sellerId],
                        [payload.activityId]: payload.activityUpdate 
                    }
                }
            }
        }

        case types.SAVE_SELLER_PRODUCTS_PAGE: {
            if (typeof payload.sellerId !== "string") {
                logError(`sellersReducer > SAVE_SELLER_PRODUCTS_PAGE: sellerId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if (typeof payload.sortBy !== "string") {
                logError(`sellersReducer > SAVE_SELLER_PRODUCTS_PAGE: sortBy payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if (typeof payload.pageNumber !== "number") {
                logError(`sellersReducer > SAVE_SELLER_PRODUCTS_PAGE: pageNumber payload is not a number ${JSON.stringify(payload)}`)
                return state
            }
            if (!Array.isArray(payload.productIds)) {
                logError(`sellersReducer > SAVE_SELLER_PRODUCTS_PAGE: productIds payload is not an array ${JSON.stringify(payload)}`)
                return state
            }
            if (typeof payload.firstDoc !== "object") {
                logError(`sellersReducer > SAVE_SELLER_PRODUCTS_PAGE: firstDoc payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if (typeof payload.lastDoc !== "object") {
                logError(`sellersReducer > SAVE_SELLER_PRODUCTS_PAGE: firstDoc payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            const {sellerId, sortBy, pageNumber, productIds, firstDoc, lastDoc} = payload
            //if the seller does not exist, add it
            if (!loadedProductIdsBySellerPage[sellerId]) loadedProductIdsBySellerPage[sellerId] = {}
            //if the sortBy does not exist for this seller, add it in
            if (!loadedProductIdsBySellerPage[sellerId][sortBy]) loadedProductIdsBySellerPage[sellerId][sortBy] = {}
            //index the list of productIds by the page number
            loadedProductIdsBySellerPage[sellerId][sortBy][pageNumber] = {
                productIds,
                firstDoc,
                lastDoc
            } 
            return {
                ...state,
                loadedProductIdsBySellerPage
            }
        }
        default: return state
    }
}


export default sellersReducer