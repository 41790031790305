/**
 *  Purpose: the modifications that are carried on the Credit Wallet objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"

// STATE
// creditWallets:{
//   creditWalletsById:{},
//   creditWalletsByEntityId: {},
//   creditWalletIds: []
// }


const creditWalletsReducer = (state = initialState.creditWallets, action) => {
    const {type, payload} = action;
    let creditWalletsById = {...state.creditWalletsById}
    let creditWalletsByEntityId = {...state.creditWalletsByEntityId}
    let creditWalletIds = {...state.creditWalletIds}
    switch (type){
        case types.SAVE_CREDIT_WALLETS: {
            if(typeof payload.creditWallets !== "object") {
                logError(`creditWalletsReducer > SAVE_CREDIT_WALLETS: creditWallets payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.creditWallets.forEach(wallet => {
                if (wallet && wallet.id) {
                    creditWalletsById[wallet.id] = wallet
                    if (wallet.entityId){
                        //index wallets by entity id
                        if (!creditWalletsByEntityId[wallet.entityId]) creditWalletsByEntityId[wallet.entityId] = {}
                        creditWalletsByEntityId[wallet.entityId][wallet.id] = true
                    }
                }
            });
            return {
                ...state,
                creditWalletsById,
                creditWalletsByEntityId,
                creditWalletIds : Object.keys(creditWalletsById)
            }
        }

        case types.LOGOUT: {
            return initialState.creditWallets
        }

        default: return state
    }
}

export default creditWalletsReducer