import React from "react"
import SearchBar from "../../containers/SearchBar"
import MobileNavigationBar from "../../containers/MobileNavigationBar"

import Footer from "../../containers/Footer"
import {Redirect} from "react-router-dom";
import styles from './Event.module.css'

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {Helmet} from "react-helmet";
import {capitalizeAllWords} from "../../utils/stringUtils"

import BlackFriday2021 from "../../events/BlackFriday2021"
import BlackFriday2022 from "../../events/BlackFriday2022";
import EarlyBirdSale2023 from "../../events/EarlyBirdSale2023";
import BlackFriday2023 from "../../events/BlackFriday2023";

import {BLACK_FRIDAY_2021_ID, BLACK_FRIDAY_2022_ID, BLACK_FRIDAY_2023_ID, EARLY_BIRD_SALE_2023_ID} from "../../constants/events"


class Event extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            eventListener: () => {},
            loaded: false
        }
    }

    componentDidMount = () => {
        this.subscribeToCurrentEvent()
    }

    subscribeToCurrentEvent = async () => {
        const {actions, match} = this.props
        const {eventId} = match.params
        const event = await actions.fetchSaveEvent(eventId)
        let eventListener = this.state.eventListener
        if (event){
            eventListener = await actions.fetchSubscribeToEvent(eventId)
        }
        this.setState({eventListener, loaded: true})
    }

    componentDidUpdate(prevProps){
        const {match} = this.props
        if (match.params.eventId !== prevProps.match.params.eventId){
            //if the event has been changed
            this.state.eventListener()
            this.setState({loaded: false}, this.subscribeToCurrentEvent)
        }
    }

    componentWillUnmount(){
        this.state.eventListener()
    }

    render(){
        const {events, match, user} = this.props
        const {loaded} = this.state
        if (!loaded) return ""
        const {eventId} = match.params
        const event = events.eventsById[eventId]
        if (!event) return <Redirect to="/404"/>
        return (
        <div className={[styles.container, "screen"].join(" ")}>
            <Helmet>
                <title>{capitalizeAllWords(event.name)}</title>
            </Helmet>
            <SearchBar/>
            <div className={["mainContent", "searchBarPresent", styles.mainContent].join(" ")}>  
                <MobileNavigationBar />
                {
                    eventId === BLACK_FRIDAY_2021_ID ?
                    <BlackFriday2021 />
                    :

                    eventId === BLACK_FRIDAY_2022_ID ? 
                    <BlackFriday2022 />
                    :

                    eventId === EARLY_BIRD_SALE_2023_ID ?
                    <EarlyBirdSale2023 />
                    :
                    eventId === BLACK_FRIDAY_2023_ID ?
                    <BlackFriday2023 />
                    :
                    <div>EVENT!</div>
                }
            </div>
            <Footer />
        </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    events: state.events
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Event);