import React from "react"
import styles from "./DateHighlight.module.css"
import {getIntuitiveDateFromTimestamp} from "../../utils/datetimeUtils"
import { capitalizeAllWords } from "../../utils/stringUtils"
import {DOMINICA_TIMEZONE} from "../../constants/datetime"

const DateHighlight = ({timestamp, useLocalTimezone=false, timezone=DOMINICA_TIMEZONE}) => {
    if (typeof timestamp !== "number") return ""
    const dateString = getIntuitiveDateFromTimestamp({timestamp, useLocalTimezone, timezone})
    const dayStyle = dateString === "today" ? styles.today :
                     dateString === "tomorrow" ? styles.tomorrow :
                     ""
    return (
        <span className={`${styles.container} ${dayStyle}`}>{capitalizeAllWords(dateString)}</span>
    )
}

export default DateHighlight