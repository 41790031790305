/**
 *  Purpose: the modifications that are carried on the Message objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // messages:{
 //   messagesById:{},
 //   messageIds: []
 // }
 
 
 const messagesReducer = (state = initialState.messages, action) => {
     const {type, payload} = action;
     let messagesById = {...state.messagesById}
     switch (type){
         case types.SAVE_MESSAGES: {
             if(typeof payload.messages !== "object") {
                 logError(`messagesReducer > SAVE_MESSAGES: messages payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.messages.forEach(message => {
                messagesById[message.id] = message
             });
             return {
                ...state,
                messagesById,
                messageIds: Object.keys(messagesById)
             }
         }
 
      
        case types.CREATE_MESSAGE: {
            if(typeof payload.message !== "object") {
                logError(`messagesReducer > CREATE_MESSAGE: message payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            messagesById[payload.message.id] = payload.message
            return {
                ...state,
                messagesById,
                messageIds: Object.keys(messagesById)
            }
        }
         default: return state
     }
 }
 
 export default messagesReducer