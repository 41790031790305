import React from "react"
import UpdateImageModal from "../../components/UpdateImageModal"
import Icon from "../../components/Icon"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import AvatarIcon from "../../components/AvatarIcon"
import IconBadge from "../../components/IconBadge"

import {AVATAR_SIZE_MEDIUM, AVATAR_SIZE_BIG} from "../../constants/interface"
import {compressImageFile} from "../../utils/fileUtils"
import {logAnalyticsEvent} from "../../config/firebase"

class UpdateProfileImageModal extends React.Component{

    constructor(props){
        super(props)
        const {imageUrl} = props
        this.state = {
            localImageUrl: imageUrl,
            locaImageFile: null,
            imageFile: null,
            imageFileMed: null,
            imageFileSmall: null,
            modalOpen: false,
            avatarSize: this.getAvatarSize()
        }
        this.photoUpload = null
    }

    handleOpenModal = () => this.setState({modalOpen: true})
    handleCloseModal = () => this.setState({modalOpen: false})

    getAvatarSize = () => window.innerWidth >= 700 ? AVATAR_SIZE_BIG : AVATAR_SIZE_MEDIUM 
    setAvatarSize = () => {
        const avatarSize = this.getAvatarSize()
        if (avatarSize === this.state.avatarSize) return
        this.setState({avatarSize})
    }

    componentDidMount = () => {
        window.addEventListener("resize", this.setAvatarSize)
    }
    componentWillUnmount = () => {
        window.removeEventListener("resize", this.setAvatarSize)
    }

    handleSelectImage = async (localImageUrl, localImageFile) => {
        this.setState({
            localImageUrl,
            localImageFile,
        })
    }

    handleCropImage = async (imageFile) => {
        const imageFileMed = await compressImageFile(imageFile, 80)
        const imageFileSmall = await compressImageFile(imageFile, 36)
        this.setState({
            imageFile,
            imageFileMed,
            imageFileSmall
        })
    }

    handleSaveImage = async () => {
        const {actions, userId} = this.props
        const {imageFile, imageFileMed, imageFileSmall} = this.state
        actions.toggleLoading(true)
        await actions.fetchSetProfileImage(userId, imageFile, imageFileMed, imageFileSmall)
        actions.toggleLoading(false)
        this.handleCloseModal()
        logAnalyticsEvent("user_update_profile_photo", {userId})
        actions.fetchLogDeviceSessionAction({
            action: "userUpdateProfilePhoto",
            userId
        })
    }

    handleDeleteImage = async () => {
        const {actions, userId} = this.props
        if (!window.confirm("Are you sure you want to delete your profile photo?")) return
        actions.toggleLoading(true)
        await actions.fetchDeleteProfileImage(userId)
        actions.toggleLoading(false)
        this.setState({localImageUrl: "",  localImageFile: null, imageFile: null, imageFileMed: null, imageFileSmall: null})
    }

    render(){
        const {name, imageUrl, imageUrlMed} = this.props
        const {localImageUrl, modalOpen, localImageFile, avatarSize} = this.state
        return (
            <React.Fragment>
                <AvatarIcon 
                    name={name} 
                    imageUrl={avatarSize === AVATAR_SIZE_BIG ? imageUrl : imageUrlMed} 
                    size={avatarSize} 
                    onClick={this.handleOpenModal}
                    ShowOnHover={() => <Icon icon="photo-camera"/>}
                    BottomRight={() => <IconBadge icon="photo-camera"/>}
                />
                <UpdateImageModal 
                    title="update profile photo"
                    imageUrl={imageUrl}
                    onDelete={this.handleDeleteImage} 
                    onSave={this.handleSaveImage}
                    onSelect={this.handleSelectImage}
                    onCrop={this.handleCropImage} 
                    onCloseModal={this.handleCloseModal} 
                    modalOpen={modalOpen}
                    localImageUrl={localImageUrl}
                    imageFile={localImageFile}
                    compressImage={false}
                />
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(null, mapDispatchToProps)(UpdateProfileImageModal)

