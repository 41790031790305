/**
 *  Purpose: the modifications that are carried on the Delivery Slot objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 import { DELIVERY_SLOTS_IDENTIFIER } from "../constants/platformSettings"
 
import {
    SUPPORTED_DELIVERY_SLOT_MAP,
    deliverySlotTypeToHumanReadable,
} from "../constants/delivery"

import {dateStringToTimestamp} from "../utils/datetimeUtils"
 // STATE
 // deliverySlots:{
 //   supportedDeliverySlotsById:{},
 //   supportedDeliverySlotIdsInOrder: []
 // }
 
 
 const deliverySlotsReducer = (state = initialState.deliverySlots, action) => {
     const {type, payload} = action;
     let supportedDeliverySlotsById = {...state.supportedDeliverySlotsById}
     let supportedDeliverySlotIdsInOrder = {...state.supportedDeliverySlotIdsInOrder}
 
     switch (type){
        case types.SAVE_PLATFORM_SETTING: {
            if(typeof payload.platformSetting !== "object") {
                return state
            }
            if (payload.settingId !== DELIVERY_SLOTS_IDENTIFIER) return state

            //generate delivery slots based on supported slots in platform settings
            //this is more or less done once, on mount or after the platform settings are loaded 
            const {platformSetting} = payload
            if (!platformSetting.types) return state
            supportedDeliverySlotsById = {}

            Object.keys(SUPPORTED_DELIVERY_SLOT_MAP).forEach(slotId => {
                const slot = {...platformSetting.types[slotId]}
                slot.title = deliverySlotTypeToHumanReadable[slot.type]
                supportedDeliverySlotsById[slotId] = slot
            })   
            supportedDeliverySlotIdsInOrder = Object.values(supportedDeliverySlotsById)
                                        .sort((sA, sB) => dateStringToTimestamp(sA.startTime, "hh:mm") - dateStringToTimestamp(sB.startTime, "hh:mm")) 
                                        .map(slot => slot.id)                 
            
            return {
                ...state,
                supportedDeliverySlotsById,
                supportedDeliverySlotIdsInOrder
            }
        }

        default: return state
    }
 }
 
 export default  deliverySlotsReducer