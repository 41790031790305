/**
 *  Purpose: the modifications that are carried on the Search object for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {cleanSearchTerms} from "../utils/searchUtils"

// STATE
// search:{
//   queryString:"",
//   queryResultsByQueryString: {},
// }


const searchReducer = (state = initialState.search, action) => {
    const {type, payload} = action;
    const queryResultsByQueryString = {...state.queryResultsByQueryString}
    switch (type){
        case types.UPDATE_QUERY_STRING: {
            if(typeof payload.queryString !== "string") {
                logError(`searchReducer > UPDATE_QUERY_STRING: query string ${payload.queryString} is not a string`)
                return state
            }
            return {
                ...state,
                queryString: payload.queryString
            }
        }

        case types.SEARCH_PRODUCTS: {
            if(typeof payload.results !== "object") {
                logError(`searchReducer > SEARCH_PRODUCTS: results in payload is a not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.results.products !== "object") {
                logError(`searchReducer > SEARCH_PRODUCTS: results.products in payload is a not an object ${JSON.stringify(payload)}`)
                return state
            }
            const resultProductIds = payload.results.products.reduce((map, product) => {
                map[product.id] = true
                return map
            }, {})
            return {
                ...state,
                queryResultsByQueryString: {
                    ...queryResultsByQueryString,
                    [cleanSearchTerms(state.queryString)]: {
                        lastQueriedAt: Date.now(),
                        resultProductIds
                    }
                }
            }
        }

        case types.LOGOUT: {
            return {
                ...state,
                queryString: "",
                queryResultsByQueryString: {}
            }
        }

        default: return state
    }
}

export default searchReducer