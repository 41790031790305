import React from "react"
import PaginatedProductTileList from "../../components/PaginatedProductTileList"
import GiftInfoBanner from "../../banners/GiftInfoBanner"
import DYBTBanner from "../../banners/DYBTBanner"
import {DYBT_CATEGORY_ID} from "../../constants/categories"
import {connect} from "react-redux"

import styles from "./CategoryTileList.module.css"

function CategoryTileList ({
    categoryId, categories, inactiveSellerIds, title="", productList=[], totalProductCount=0, pageNumber=0, onClickBack=()=>{}, onClickNext=()=>{}, onChangePage=()=>{}}){
    //filter out products from inactive sellers or products that have been set to inactive
    const matchedProductList = productList.filter(product => !inactiveSellerIds[product.createdBySellerId] && !product.isInactive)
                                        .filter(product => product.categoryIds.includes(categoryId))
    const category = categories.categoriesById[categoryId]

    return (
        <div className={styles.container}>
            <p className={["title", styles.heading].join(" ")}>{category.label} ({totalProductCount})</p>
            {
                category && category.banner?
                <GiftInfoBanner />
                :
                category && category.id === DYBT_CATEGORY_ID?
                <DYBTBanner />
                :
                null
            }
            <PaginatedProductTileList 
                productList={matchedProductList} 
                totalProductCount={totalProductCount}
                pageNumber={pageNumber}
                onClickBack={onClickBack}
                onClickNext={onClickNext}
                onChangePage={onChangePage}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    categories: state.categories,
    inactiveSellerIds : state.sellers.inactiveSellerIds
})

export default connect(mapStateToProps)(CategoryTileList);