/**
 *  Purpose: the modifications that are carried on the Credit Transaction objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"

// STATE
// creditTransactions:{
//   creditTransactionsById:{},
//   creditTransactionIds: []
// }


const creditTransactionsReducer = (state = initialState.creditTransactions, action) => {
    const {type, payload} = action;
    let creditTransactionsById = {...state.creditTransactionsById}
    let creditTransactionIds = {...state.creditTransactionIds}
    switch (type){
        case types.SAVE_CREDIT_TRANSACTIONS: {
            if(typeof payload.creditTransactions !== "object") {
                logError(`creditTransactionsReducer > SAVE_CREDIT_TRANSACTIONS: creditTransactions payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.creditTransactions.forEach(transaction => {
                creditTransactionsById[transaction.id] = transaction
            });
            return {
                ...state,
                creditTransactionsById,
                creditTransactionIds : Object.keys(creditTransactionsById)
            }
        }

        default: return state
    }
}

export default creditTransactionsReducer