import { getTimestampForDM24HrTime, timestampIsOnWorkingDay, getTimestampOfNextWorkingDay, getTimestampForStartOfDay, timestampToWeekDay, getClosedDaysFromOpeningHours} from "./datetimeUtils"
import { DAY_IN_MILLISECONDS, DOMINICA_TIMEZONE } from "../constants/datetime"
import { logError } from "./errorHandlingUtils"
import { alphabeticalSort, capitalizeAllWords } from "./stringUtils"
/**
 * Get a seller's opening and closing times in string form e.g. 08:00 or 17:00
 * Also get the opening and closing timestamp for the timestampOfDay provided
 */
export const getSellerOpeningHours = (sellerAddress, timestampOfDay, sellerId) => {
    if (!sellerAddress) {
        logError(`getSellerOpeningHours: Seller has no address ${sellerId}`)
        return null
    }
    if (!sellerAddress.openingHours) {
        //if this is a previous style address, handle it
        //this is encountered for instance, when calculating metrics for 
        //pre v2.9.0 orders 
        if (sellerAddress.openingTime && sellerAddress.closingTime){
            const {openingTime, closingTime} = sellerAddress
            const [openingAtHours, openingAtMinutes] = openingTime.split(":")
            const [closingAtHours, closingAtMinutes] = closingTime.split(":")
            const openingAt = getTimestampForDM24HrTime(openingAtHours, openingAtMinutes, timestampOfDay)
            const closingAt = getTimestampForDM24HrTime(closingAtHours, closingAtMinutes, timestampOfDay)
            return {
                openingTime,
                closingTime,
                openingAt,
                closingAt
            }
        } else {
            logError(`getSellerOpeningHours: Seller ${sellerId} address ${sellerAddress.id} has no opening hours`)
            return null
        }
    }
    //find the week day associated with the time specified
    //then use this to get the opening hours on that day
    const weekday = timestampToWeekDay(timestampOfDay, false, DOMINICA_TIMEZONE)
    const openingHoursOnDay = sellerAddress.openingHours[weekday]
    if (!openingHoursOnDay) {
        console.warn(`getSellerOpeningHours: Seller ${sellerId} is not open on ${weekday} - returning null`)
        return null
    }
    const {openingTime, closingTime} = openingHoursOnDay
    if (typeof openingTime !== "string" || typeof closingTime !== "string"){
        logError(`getSellerOpeningHours: seller ${sellerId}, address ${sellerAddress.id} opening or closing time is not a string. Opening time ${openingTime}. Closing time ${closingTime}` )
        return null
    }
    const [openingAtHours, openingAtMinutes] = openingTime.split(":")
    const [closingAtHours, closingAtMinutes] = closingTime.split(":")
    const openingAt = getTimestampForDM24HrTime(openingAtHours, openingAtMinutes, timestampOfDay)
    const closingAt = getTimestampForDM24HrTime(closingAtHours, closingAtMinutes, timestampOfDay)
    return {
        openingTime,
        closingTime,
        openingAt,
        closingAt
    }
}

/**
 * Get a timestamp from which we can measure the time taken for order response
 * This time needs to account for situtions where the order came in on a holiday or 
 * a non-work day or after the business was closed
 * It must also account for whether the seller is a same day or next day
 * 
 * Algorithm:
 *      a) Determine if the start day is a working day
 *      b) If the start day is a working day, 
 *              i) If we are within the store's opening hours, the startPrepAt point is now  
 *                 (after the opening time and before the closing time). 
 *              ii) if we are before the store's opening time, then the start point is the opening time
 *              iii) if we are after the store's closing time, then the start point if the next working day's opening time
 *      c) Else if the start day is not a work day because it is a holiday, or the store is closed
 *          then the start point is the opening time on the next working day 
 */
export const getSellerStartPointToMeasureOrderReponse = (startAt, seller, selectedAddressId="", timezone=DOMINICA_TIMEZONE) => {
    //the opening hours can differ across a seller's addresses, so first we need to know which address is relevant
    //if none is specified, use the default seller address, as this is where delivery persons will pick from
    const sellerAddress = selectedAddressId && seller.addressesById[selectedAddressId]?
                          seller.addressesById[selectedAddressId]
                          :
                          seller.addressesById[seller.defaultAddressId]
    const closedDays = getClosedDaysFromOpeningHours(sellerAddress.openingHours)
    let startPrepAt
    //startAt must be tomorrow if seller is not a same day store
    const sameOrNextDayStartAt = seller.isSameDay ?
                                    startAt
                                    :
                                    //start calculations at the beginning of the next day
                                    getTimestampForStartOfDay(startAt,!timezone, timezone) + DAY_IN_MILLISECONDS

    const startDayIsWorkingDay = timestampIsOnWorkingDay(sameOrNextDayStartAt, closedDays, timezone)
    if (startDayIsWorkingDay){
        //find the opening and closing times
        const {openingAt, closingAt} = getSellerOpeningHours(sellerAddress, sameOrNextDayStartAt, seller.id)
        //if we are within the store's opening hours, the start point is the start point provided
        startPrepAt = openingAt <= sameOrNextDayStartAt && closingAt >= sameOrNextDayStartAt ?
                    sameOrNextDayStartAt
                    :
                    //if the store has not yet opened then the start time is their opening time
                    openingAt > sameOrNextDayStartAt ?
                    openingAt
                    :
                    null //otherwise (if we are beyond their closing time today) we do not set a startPrepAt
    }
    //if the start prep at time is not set, it is either because
    //the start day is not a work day or
    //the start day is a work day but we are beyond the closing time
    //in both cases we must find the next working day and work from there
    if (!startPrepAt){
        const nextWorkingDay = getTimestampOfNextWorkingDay(sameOrNextDayStartAt, closedDays, timezone)
        //find the next working day's opening time
        const {openingAt} = getSellerOpeningHours(sellerAddress, nextWorkingDay, seller.id)
        //start calculations from that point
        startPrepAt = openingAt
    }
    return startPrepAt
}

export const getSellerNamesFromSellerList = (sellerList = []) => {
    return sellerList.sort((sA, sB) => alphabeticalSort(sA.name, sB.name))
                    .reduce((text, seller, i, arr) => {
                        const sellerName = capitalizeAllWords(seller.name)
                        text = !text ? sellerName : `${text}${i === arr.length - 1 ? " and " : ", "}${sellerName}`
                        return text
        }, "")
}