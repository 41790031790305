import {logError} from './errorHandlingUtils'
import {YOUTUBE_HOST_ID} from "../constants/videos"

export const stringBContainsStringA = (stringA, stringB) => {
    if (! (typeof stringA === 'string')) {
        logError(new Error(`stringBContainsStringA: stringA ${JSON.stringify(stringA)} is not a string`))
        return 
    }
    if (! (typeof stringB === 'string')) {
        logError(new Error(`stringBContainsStringA: stringB ${JSON.stringify(stringB)} is not a string`))
        return
    }
    stringA = stringA.toLowerCase().replace(/ +?|\.+|\'+/g, '')
    stringB = stringB.toLowerCase().replace(/ +?|\.+|\'+/g, '')
    return stringB.includes(stringA)
}

export const addressObjectToString = (address, addDirections = false) => {
    if (!address) return ""
    let addressLine = ""
    if (address.line1) addressLine = `${addressLine}${address.line1}, `
    if (address.line2) addressLine = `${addressLine}${address.line2}, `
    if (address.usaCity) addressLine = `${addressLine}${address.usaCity}, `
    if (address.city) addressLine = `${addressLine}${address.city}, `
    if (address.zipCode) addressLine = `${addressLine}${address.zipCode}, `
    addressLine = capitalizeAllWords(`${addressLine} ${address.countryName}`)
    if (addDirections && Boolean(address.directions)) addressLine = `${addressLine}: ${address.directions}, `
    return addressLine
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

export const stringAEqualsStringB = (stringA, stringB) => {
    if (! (typeof stringA === 'string')) {
        logError(new Error(`stringAEqualsStringB: stringA ${JSON.stringify(stringA)} is not a string`))
        return 
    }
    if (! (typeof stringB === 'string')) {
        logError(new Error(`stringAEqualsStringB: stringB ${JSON.stringify(stringB)} is not a string`))
        return
    }
    stringA = stringA.toLowerCase().replace(/ +?|\.+/g, '')
    stringB = stringB.toLowerCase().replace(/ +?|\.+/g, '')
    return stringA === stringB
}

export const capitalizeAllWords = text => {
    if (! (typeof text === 'string')) {
        console.warn(`capitalizeAllWords: text ${JSON.stringify(text)} is not a string`)
        return ""
    }
    return text.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
}
export const alphabeticalSort = (stringA, stringB) => {
    if (! (typeof stringA === 'string')) {
        console.warn(`alphabeticalSort: stringA ${JSON.stringify(stringA)} is not a string. String B is ${JSON.stringify(stringB)}`)
        return 0
    }
    if (! (typeof stringB === 'string')) {
        console.warn(`alphabeticalSort: stringB ${JSON.stringify(stringB)} is not a string. String A is ${JSON.stringify(stringA)}`)
        return 0
    }
    stringA = stringA.toLowerCase().replace(/ +?|\.+/g, '')
    stringB = stringB.toLowerCase().replace(/ +?|\.+/g, '')
    if ( stringA < stringB ){
        return -1;
      }
      if ( stringA > stringB ){
        return 1;
      }
      return 0;
}

export const limitTextLength = (text, limit = 50) => {
    if (!text || (typeof text !== "string")) return ""
    if (typeof limit !== "number") limit = 50
    if (text.length <= limit) return text
    return `${text.slice(0, limit)}...`
} 

export const generateRandom16CharString = () => {
    const d = new Date()
    return `${Math.random().toString(36).slice(2)}${d.getDate()}${d.getFullYear().toString().slice(2)}`.slice(0,17)
}

// source: https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
const hashCode = str => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
       hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
} 

const intToRGB = i => {
    var c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
}

export const colorFromString = string => `#${intToRGB(hashCode(string))}`

export const normalize = (string, removeWhitespace=true) => {
    if (! typeof string === 'string') string = String(string)
    string = string.toLowerCase().trim()
    if (removeWhitespace) string = string.replace(/ /g, '')
    return string
}

export const getListFromDelimitedString = (string="", normalizeFunc=normalize, delimiter=",") => {
    const list = string && typeof string === "string"? string.split(delimiter): []
    return list.map(element => normalize(element)).filter(str => Boolean(str))
}

export const parseAttributeSringToObject = code => {
    const obj = {}
    if (
        !code ||
        typeof code !== "string" ||
        !code.includes("=")
    ) return obj
    const matches = code.match(/([^\r\n\t\f\v= '"]+)(?:=(["'])?((?:.(?!\2?\s+(?:\S+)=|\2))+.)\2?)?/g)
    if (!matches) return obj
    matches.forEach(pair => {
        const components = pair.split(`=`)
        let attr = components[0]
        let value = ""
        if (components.length){
            components.shift()
            value = components.join(`=`).replaceAll(`"`, "")
        }
        obj[attr] = value
    })
    return obj
}

export const isValidUrl = string => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  export const urlHasValidHost = (string, hosts = []) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
    return hosts.includes(url.host);
  }

  export const getUrlHost = (string) => {
    let url;
    try {
      url = new URL(string);
      return url.host
    } catch (_) {
      return "";  
    }
  }

  export const getHostVideoIdFromUrl = (url) => {
    const host = getUrlHost(url)
    if (host === YOUTUBE_HOST_ID){
        const id = url.match(/.+\/embed\/([\w-]+)/)[1];
        return id
    }
    return "";  

  }

  export const setUrlParams = (url, params={}) => {
    let newUrl = url  
    try {
        const urlObj = new URL(url)
        const i = url.indexOf('?')
        const q = i === -1 ? "" : url.slice(i+1)
        const queryParams = new URLSearchParams(q);
        Object.keys(params).forEach(attr => {
            queryParams.set(attr, params[attr])
        })
        newUrl = `${urlObj.origin}${urlObj.pathname}?${queryParams.toString()}`
    } catch (e){
        logError(e)
    }
    return newUrl
  }
