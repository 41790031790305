/**
 *  Purpose: the modifications that are carried on the System object for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {selectedEnvironment} from "../config"
import {version} from "../config/release"

// STATE
// system:{
//  maintenance: false,
//  limitedAccess: false,
//  loading: false,
//  loadingText: "",
//  notification: null,
//  environment: null,
//  version: null
// }


const systemReducer = (state = initialState.system, action) => {
    const {type, payload} = action;

    switch (type){

        case types.TOGGLE_LOADING: {
            return {
                ...state,
                loading: payload.loading,
                loadingText: payload.loadingText
            }
        }

        case types.SHOW_NOTIFICATION: {
            const {title, body, data={}} = payload
            let link = data.link ? data.link : ""
            let icon = data.icon ? data.icon : ""

            return {
                ...state,
                notification: {
                    title,
                    body,
                    link,
                    icon
                }
            }
        }

        case types.HIDE_NOTIFICATION: {
            return {
                ...state,
                notification: null
            }
        }

        case types.CHANGE_ENVIRONMENT: {
            return {
                ...state,
                environment: selectedEnvironment
            }
        }

        case types.CHANGE_VERSION: {
            console.log("Version ", version)
            return {
                ...state,
                version
            }
        }

        case types.TOGGLE_FEEDBACK_REQUESTED: {
            return {
                ...state,
                feedbackRequested: payload.feedbackRequested
            }
        }

        default: return state
    }
}

export default systemReducer