import React, { Children } from "react"
import ReactTooltip from "react-tooltip";
import {v4 as uuid4} from 'uuid'

class DisabledButton extends React.Component{
    constructor(props){
        super(props)
        this.button = React.createRef()
        this.id = uuid4()
    }

    static defaultProps = {
        alert: "Not active",
        displayTime: 1200,
        className:""
    }

    handleClick = () => {
        const {displayTime} = this.props
        this.showTooltip()
        this.show = true
        setTimeout(() => {
            this.show = false
            this.hideTooltip()
        }, displayTime);
    }

    showTooltip = () => {
        const node = this.button
        ReactTooltip.show(node);
    };

    hideTooltip = () => {
        if (this.show) return
        const node = this.button
        ReactTooltip.hide(node);
    }

    render(){
        const {className} = this.props
        return (
                <React.Fragment>
                <button 
                        className={`button disabled ${className}`}
                        ref={ref => this.button = ref}
                        data-tip={this.props.alert}
                        onClick={this.handleClick}
                        onMouseEnter={this.showTooltip}
                        onMouseLeave={this.hideTooltip}
                    >
                        {this.props.children}
                    </button>
                    <ReactTooltip id={this.id} event="no-event" className="tooltip"/>
                </React.Fragment>
                )
    }
}

export default DisabledButton