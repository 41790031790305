import {logError} from "./errorHandlingUtils"
import imageBlobReduce from 'image-blob-reduce'
import {baseUrl} from "../config/firebase"
import {parse} from 'papaparse'
import {    
    IMAGE_FILE_EXT_JPG,
    IMAGE_FILE_EXT_JPEG,
    IMAGE_FILE_EXT_PNG, 
    IMAGE_FILE_EXT_GIF, 
    IMAGE_FILE_EXT_WEBP,
    IMAGE_FILE_EXT_JFIF
} from "../constants/image"

export const downloadFile = (file, fileName="") => {
    var blob = new Blob([file]);
    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export const readFile = file => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onload = event =>{
            const data = event.target.result
            resolve(data)
        }
        fileReader.readAsBinaryString(file)
    })
}

export const readExcelFile = async (e, sheetCount=1, onSuccess=()=>{}, errorMesssage="", header=true, startAtRow=0) => {
    try {
        const isCsv = Boolean(e.target.files[0] && e.target.files[0].name.includes(".csv"))
        let data = await readFile(e.target.files[0])
        if (!isCsv){
            //if this is an excel file instead of a csv, first convert it to csv
            const response = await fetch(`${baseUrl}v1/utils/excel-to-csv`, { method: 'POST', body: data });
            if (!response.ok) return [] //if something goes wrong, return an empty array
            const json = await response.json()
            data = json.csv
        }
        let rows
        //csvs have rows marked by newline chars so the row can be removed before converting to json 
        if (startAtRow > 0){
            data = data.split('\n').slice(startAtRow).join('\n')
        }
        const csvData = parse(data, {header})
        rows = csvData.data
        onSuccess(rows)
        return rows
    } catch (e) {
        e.message = `${errorMesssage}, ${e.message}`
        logError(e)
    }
}

export const formatCsvValuesToSchema = (csvRows=[], schema={}, ignoreExtraFields=false)=> {
    if (!Array.isArray(csvRows)) throw new Error(`${csvRows} is not an array`)
    const formattedRows = []
    if (csvRows.length === 0) return {formattedRows, missingSchemaKeys: {}, unknownKeys: {}}
    let unknownKeys = {} //keys that are in the file, but not in the schema
    const missingSchemaKeys = {...schema} //keys that are in the schema, expected to update values, but not in the file
    csvRows.forEach(row => {
        const formatted = {}
        for (let key in row) {
            const schemaKey = typeof key === "string" ? key.toLowerCase().trim() : key
            if (schema[schemaKey]) {
                formatted[schema[schemaKey]] = typeof row[key] === "string" ? row[key].trim() : String(row[key])
                //we want to be able to flag any keys from the schema that are missing completely
                //is the key shows up once in the csv, then it has been set. 
                //in that, case delete it from the missing scehema keys 
                //Otherwise, it is possible that the file is not properly formatted 
                delete missingSchemaKeys[schemaKey]
            } 
            else if (schemaKey) unknownKeys[key] = true
        }
        formattedRows.push(formatted)
    })
    const unknownKeyErrorMsg = Object.keys(unknownKeys).reduce((msg, key) => {
        if (!msg) msg = `Could not process column(s) ${key}`
        else msg = `${msg}, or ${key}`
        return msg
    }, "") 
    if (unknownKeyErrorMsg && !ignoreExtraFields){
        const schemaKeys = Object.keys(schema).join("\n")
        alert(`Error During Upload\n\n${unknownKeyErrorMsg}. These column names are incorrect. Double-check them for typos and then re-upload.\n\n The allowed column names are:\n${schemaKeys}`)
        return false
    }
    return {formattedRows, missingSchemaKeys, unknownKeys}
}

export const compressImageFile = async (fileBlob, maxWidthOrHeight=300) => {
    //image-blob-reduce & pica docs here: https://github.com/nodeca/image-blob-reduce#readme
    const reduce = imageBlobReduce({
        pica: imageBlobReduce.pica({ features: [ 'js', 'wasm' ] }) //terser breaks imageBloblReduce after minification
    })
    const compressedFile = await reduce.toBlob(fileBlob, {max: maxWidthOrHeight})
    console.log(`Image compressed from ${fileBlob.size/1000} KBs to ${compressedFile.size/1000} KBs`)
    return compressedFile
}

export const extractSkuFromImageName = (imageName="") => {
    imageName = imageName.trim()
    const regex = /[^a-zA-Z\-\d]/
    //find the first character that is not alphanumeric or a hythen
    //break the string at that point and trim
    return imageName.slice(0, imageName.match(regex).index).trim()
}

export const firebaseImageUrlsEqual = (urlA, urlB) => (
    (urlA === urlB) ||
    (urlA.split("?")[0] === urlB.split("?")[0])
)

export const isSupportedImageExtension = (
    imageName,
    supportedImageTypes = {
        [IMAGE_FILE_EXT_JPG]: true,
        [IMAGE_FILE_EXT_JPEG]: true,
        [IMAGE_FILE_EXT_PNG]: true, 
        [IMAGE_FILE_EXT_GIF]: true, 
        [IMAGE_FILE_EXT_WEBP]: true,
        [IMAGE_FILE_EXT_JFIF]: true
    }
    ) => {
    const re = /(?:\.([^.]+))?$/;
    const extension = re.exec(imageName)[1];
    return Boolean (
        extension &&
        (typeof extension === 'string') &&
        supportedImageTypes[extension.toLowerCase()]
    )
} 

export const cleanNumber = value => {
    if (typeof value === "number") return value
    else if (typeof value === "string"){
        value = value.replace("$", "").replace(" ", "")
        value = value.replace(",", "").replace(" ", "")
        return Number(value)
    }
    return 0
}

export const getCurrencyAmountFromString = value => {
    if (typeof value === "number") return value
    else if (typeof value === "string"){
        value = value.trim()
        //if the value is simply a number string, cast to a number and return
        if (!isNaN(value)) return Number(value)
        else if (value.includes(".")){
            //else if the value includes a period, to indicate cents in the currency
            //use a regex to pull out this value
            const re = /\d+\.\d\d(?!\d)/
            const match = re.exec(value)
            if (!(match && match.length > 0)) return 0
            else return Number(match[0])
        }
        else {
            //if the string does not have a . to indicate cents
            //find the first number in the string and return it
            const re = /\d+/
            const match = re.exec(value)
            if (!(match && match.length > 0)) return 0
            else return Number(match[0])
        }
    }
    return 0
}