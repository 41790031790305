import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"

export const SAVE_DAILY_SUMMARIES = "SAVE_DAILY_SUMMARIES"

export const saveDailySummaries = dailySummaries => {
    return {
        type: SAVE_DAILY_SUMMARIES,
        payload: {
            dailySummaries
        }
    }
}

export const fetchSubscribeToDailySummary = (
    usageId
) => {
    /**
      * Purpose: retrieve a single daily summary from the firestore database
      * Note: the onSnapshot below watches for changes on the server
      */
    const firestore = getFirestore(firebaseApp);
    const dailySummaryRef = doc(firestore, "usage/--stats--/dailySummaries", usageId)
    return async dispatch => {
        try {
            const dailySummaryListener = await onSnapshot(dailySummaryRef,
                docRef => {
                    //get a transactions from the server
                    const dailySummary = {...docRef.data()}
                    dispatch(saveDailySummaries([dailySummary]))
                })
            return dailySummaryListener
        } catch (e){
            const message = `action > usage > fetchSubscribeToDailySummary: Failed to save daily summary ${usageId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}
