import {DOMINICA_TIMEZONE, MINUTE_IN_MILLISECONDS, DAYS_OF_THE_WEEK, DAY_IN_MILLISECONDS, HOLIDAYS} from "../constants/datetime"
import { 
    PICK_UP_INSTORE_ID, 
    SELF_DELIVERY_ID, 
    DELIVERY_PROVIDER_TYPE_AGENT_BASED,
    DELIVERY_PROVIDER_TYPE_PICK_UP,
    DELIVERY_PROVIDER_TYPE_MERCHANT_DELIVERY,
    DELIVERY_PROVIDER_TYPE_OPENING_HOURS,
    DELIVERY_ENTITY_TYPE_DELIVERY_PROVIDER,
} from "../constants/delivery"
import {getSellerStartPointToMeasureOrderReponse, getSellerOpeningHours} from "./sellerUtils"
import { getClosedDaysFromOpeningHours, getTimestampOfNextWorkingDay, getTimestampForDM24HrTime, timestampToWeekDay, timeStringSort, getTimestampForStartOfDay, timestampToDateString, dateStringToTimestamp} from "./datetimeUtils"

export const hasOpenDays = (closedDays={}) => {
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
    //return whether there is at least one day of the week which is not closed
    return days.some(day => !closedDays[day])
} 

export const getSellerDeliverySelectionByDeliveryProviderId = (
    deliveryProvidersById={}, //from deliveryProviders
    selectedDeliveryProviderIdsBySellerId={}, //from cart
    selectedPickupAddressIdBySellerId={}, //from cart

) => {
    //get list of delivery selections
    //generate this by looping though the delivery providers selected on the order
    //if the delivery provider is not pick up instore or self delivery, 
    
    //it is unique for those 2 special delivery methods (pick up instore or self delivery),
    // so it is entered per seller

    const deliveryProviderIdMap = {} //track whether a delivery provider has been encountered before while looping
    const sellerDeliverySelectionMap = {}
    Object.keys(selectedDeliveryProviderIdsBySellerId)
        .forEach(sellerId => {
            const deliveryProviderId = selectedDeliveryProviderIdsBySellerId[sellerId]
            const deliveryProvider = deliveryProvidersById[deliveryProviderId]
            if (!deliveryProvider) return
            const deliveryProviderName = deliveryProvider.name
            //if pick up instore, add the sellerId and pickup address id
            if (deliveryProviderId === PICK_UP_INSTORE_ID){
                const pickupAddressId = selectedPickupAddressIdBySellerId[sellerId]
                const id = `${sellerId}${deliveryProviderId}`
                sellerDeliverySelectionMap[id] = {
                    id,
                    deliveryProviderName,
                    sellerId,  
                    deliveryProviderId,              
                    pickupAddressId : pickupAddressId ? pickupAddressId :"",
                    sellerIds: {[sellerId]: true}
                }
            }
            //if it is a self delivery add the seller id in as well 
            else if (deliveryProviderId === SELF_DELIVERY_ID){
                const id = `${sellerId}${deliveryProviderId}`
                sellerDeliverySelectionMap[id] = {
                    id,
                    deliveryProviderName,
                    sellerId,
                    deliveryProviderId,
                    sellerIds: {[sellerId]: true}            
                }
            } 
            else {
                //any other delivery provider should only be added once
                const id = deliveryProviderId
                if (!deliveryProviderIdMap[deliveryProviderId]){
                    deliveryProviderIdMap[deliveryProviderId] = true  
                    sellerDeliverySelectionMap[id] = {
                        id,
                        deliveryProviderName,
                        deliveryProviderId,
                        sellerIds: {[sellerId]: true}
                    }
                } else {
                    //add other seller ids to the sellerIds map for previously selected delivery providers
                    sellerDeliverySelectionMap[id].sellerIds[sellerId] = true
                }
            }
        })
    return sellerDeliverySelectionMap
}

export const estimateSellerReadyForPickupAt = (seller, lineItemCount=0, selectedAddressId="", constantTimeInMins=30, timePerLineItemInMins=7.5, timezone=DOMINICA_TIMEZONE) => {
    /**
     * Purpose: estimate when a seller will be ready for pickup
     * 
     * Algorithm:
     *  1. Find a start point to calculations from (startPrepAt)
     *  2. Add the estimated prepation time to the start point
     *  3. Allow for situations where orders spill into the next working day
     */

    //1. Choose a point in time to start calculations from, this depends on the opening hours, which depends on the seller address used,
    //   but also the holidays, weekends, time the order is being placed, and whether or not the store is a same day store
    const sellerAddress = selectedAddressId && seller && seller.addressesById && seller.addressesById[selectedAddressId]?
                          seller.addressesById[selectedAddressId]
                          :
                          seller && seller.addressesById ?
                          seller.addressesById[seller.defaultAddressId]
                          :
                          {}
        
    let startPrepAt = getSellerStartPointToMeasureOrderReponse(Date.now(), seller, sellerAddress.id, timezone)
    /**2. calculate the prep time as: 
        - constant (avg time to see the order + avg time spent on previous orders etc)
        - (number of line items * avg time to prep a line item - this involves the time to pick, call and have supervisors check back each item)
        - Note: a linear regression was done on Astaphan's historical orders to come up with 
                a good constant and avg time per item
                training set here: https://docs.google.com/spreadsheets/d/1Wjy2D8FhwjO1FKmVyFv826zdD2TBZYfCIryOXJ8MlR0/edit#gid=1513836484
                test set here: https://docs.google.com/spreadsheets/d/1DA-p7xdqDXHjrQF0Au-YuEwAZo70gr9sccRNQGuwlK4/edit#gid=784872678
    **/
    const AVG_ORDER_PREP_CONSTANT_TIME = constantTimeInMins * 60 * 1000 //30 mins
    const AVG_TIME_PER_LINE_ITEM = timePerLineItemInMins * 60 * 1000 //7.5 mins to prepare each item
    //TODO next we will need to calculate and keep up to date a running avg for each seller 
    const estimatedTimeToPrepareOrder = AVG_ORDER_PREP_CONSTANT_TIME + (lineItemCount * AVG_TIME_PER_LINE_ITEM)
    //TODO factor in the existing orders that the store has open
    //     perhaps multiply the number of open orders by the avg? or number of items in those orders by the est prep time?
    const {closingAt} = getSellerOpeningHours(sellerAddress, startPrepAt, seller.id)
    const closedDays = getClosedDaysFromOpeningHours(sellerAddress.openingHours) 
    let startPrepAtOnDay = startPrepAt
    let closingAtOnDay = closingAt
    let timeRemainingForPreparation = estimatedTimeToPrepareOrder
    //if the time to prepare the order runs past closing time
    //3. move onto the next working day, as needed, if the order is too big to finish today
    while ((startPrepAtOnDay + timeRemainingForPreparation) > closingAtOnDay){
        //remove the time worked on the previous day(s) from the time remaining for preparation
        timeRemainingForPreparation = (startPrepAtOnDay + timeRemainingForPreparation) - closingAtOnDay
        //find the next working day, considering holidays, closed days and weekends
        const nextWorkingDay = getTimestampOfNextWorkingDay(startPrepAtOnDay, closedDays, timezone)
        //set the next start point to the be opening time of the next working day
        const {openingAt, closingAt} = getSellerOpeningHours(sellerAddress, nextWorkingDay, seller.id)
        startPrepAtOnDay = openingAt
        //and the new closing time to be that of the next working day
        closingAtOnDay = closingAt
    } 
    return {
        readyAt: startPrepAtOnDay + timeRemainingForPreparation,
        startPrepAt,
        estimatedTimeToPrepareOrder 
    }
}

export const estimateSellerReadyToDepartAt = (
    estimatedReadyForPickupAt=0,
) => {
    //add an hour to the ready for pickup time to accomodate the first mile
    //perhaps in the future we will consider the time of day, for instance
    //Qwick has said that they are not currently available for first miles between
    //12PM and 2PM for example
    return estimatedReadyForPickupAt + (MINUTE_IN_MILLISECONDS * 60)
}

export const estimateAllReadyAt = (
    sellerReadinessEstimationMap={},
    sellerIds=[],
    deliveryProviderType
) => {
    let allReadyAt = 0
    sellerIds.forEach(sellerId => {
        let {readyAt} = sellerReadinessEstimationMap[sellerId] ? sellerReadinessEstimationMap[sellerId] : 0
        //if this is an agent-based delivery provider, you need to find the 
        //ready to depart at, not the ready for pickup at
        if (deliveryProviderType === DELIVERY_PROVIDER_TYPE_AGENT_BASED){
            readyAt = estimateSellerReadyToDepartAt(readyAt)
        }
        if (allReadyAt < readyAt){
            allReadyAt = readyAt
        }
    })
    return allReadyAt
}

export const estimateDeliveryFulfilledAt = (estimatedAllReadyAt, deliveryProvider, userAddress={}, deliveryTrips) => {
    /**
     * estimate the earliest time the delivery can be  fulfilled at
     */
    const estimation = {}
    if (deliveryProvider.type === DELIVERY_PROVIDER_TYPE_PICK_UP){
        //pick up instore is earliest fulfilled at the moment it is ready for pickup
        estimation.fulfilledAt = estimatedAllReadyAt
    } else if (deliveryProvider.type === DELIVERY_PROVIDER_TYPE_MERCHANT_DELIVERY){
        //merchant delivery is earliest fulfilled at 6 hrs after it is ready for pickup
        estimation.fulfilledAt = estimatedAllReadyAt + (6 * 60 * MINUTE_IN_MILLISECONDS)
        estimation.deliveryAreaId = userAddress.settlementId
    } else if (deliveryProvider.type === DELIVERY_PROVIDER_TYPE_OPENING_HOURS){
        //qwick and other opening hours based delivery providers, we estimate 2 hrs from readiness time
        estimation.fulfilledAt = estimatedAllReadyAt + (120 * MINUTE_IN_MILLISECONDS)
        estimation.deliveryAreaId = userAddress.settlementId
        estimation.deliveryAreaName = userAddress.city
        estimation.deliveryEntityType = DELIVERY_ENTITY_TYPE_DELIVERY_PROVIDER
        estimation.deliveryEntityId = deliveryProvider.id
        estimation.deliveryEntityName = deliveryProvider.name
    } else if (deliveryProvider.type === DELIVERY_PROVIDER_TYPE_AGENT_BASED){
        const deliveryTripsToAreaMap = deliveryTrips.deliveryTripIdsByAreaId[userAddress.settlementId]
        const deliveryTripsToArea = deliveryTripsToAreaMap ? 
                                Object.keys(deliveryTripsToAreaMap)
                                      .map(deliveryTripId => deliveryTrips.deliveryTripsById[deliveryTripId])
                                      .filter(deliveryTrip => deliveryTrip.isActive &&
                                            deliveryTrip.deliveryProviderId === deliveryProvider.id)
                                      //order so the first trips to arrive are first
                                      .sort((dA, dB) => dA.arriveAt - dB.arriveAt)
                                : 
                                []
        if (deliveryTripsToArea.length === 0) return null
        else {
            //find the first trip that departs after the ready to depart time
            let nextDeliveryTrip = deliveryTripsToArea.find(deliveryTrip => deliveryTrip.departAt > estimatedAllReadyAt)

            if (!nextDeliveryTrip) return null
            //TODO deal with the case where the next trip lands on a holiday or a day the store is not open
            estimation.fulfilledAt = nextDeliveryTrip.arriveAt
            estimation.pickupAt = nextDeliveryTrip.pickupAt ? nextDeliveryTrip.pickupAt : ""
            estimation.departAt = nextDeliveryTrip.departAt
            estimation.deliveryTripId = nextDeliveryTrip.id
            estimation.deliveryEntityId = nextDeliveryTrip.deliveryEntityId
            estimation.deliveryEntityName = nextDeliveryTrip.deliveryEntityName
            estimation.deliveryEntityType = nextDeliveryTrip.deliveryEntityType
            estimation.deliveryAreaId = userAddress.settlementId
            estimation.deliveryAreaName = userAddress.city
        }
    }
    return estimation
}

export const getWeeklyAvailableSlotsWithinOpeningHours = (supportedSlotMap, openingHours={}, timezone=DOMINICA_TIMEZONE) => {
    /**
     * Purpose: loop through the supported slots and find those that fall within the opening hours of the seller's address
     *          if necessary, adjust the start and end times of the slot so that it falls exclusively within the opening hours
     */
    const weeklyAvailableSlots = {}
    DAYS_OF_THE_WEEK.forEach(dayOfWeek => {
        //if the store is open on that day, consider adding the slots on that day to the weekly avaiable slots
        if (openingHours[dayOfWeek]){
            const {openingTime, closingTime} = openingHours[dayOfWeek]
            const [openingAtHour, openingAtMins] = openingTime.split(":")
            const openingAt = getTimestampForDM24HrTime(openingAtHour, openingAtMins, Date.now(), timezone)
            const [closingAtHour, closingAtMins] = closingTime.split(":")
            const closingAt = getTimestampForDM24HrTime(closingAtHour, closingAtMins, Date.now(), timezone)
            //any slots that fit within the opening times will be added here
            const availableSlots = {}
            Object.values(supportedSlotMap).forEach(slot => {
                let {startTime, endTime} = slot
                const [startAtHour, startAtMins] = startTime.split(":")
                const startAt = getTimestampForDM24HrTime(startAtHour, startAtMins, Date.now(), timezone) 
                const [endAtHour, endAtMins] = endTime.split(":")
                const endAt = getTimestampForDM24HrTime(endAtHour, endAtMins, Date.now(), timezone) 
                if (
                    //a slot is available if more than the last 30 mins of the slot fall within the opening hours
                    ((endAt - openingAt) >= (MINUTE_IN_MILLISECONDS * 30)) && endAt < closingAt ||
                    //a slot is available if at least the first 30 mins of the slot fall within the opening hours
                    endAt >= closingAt && ((closingAt - startAt) >= (MINUTE_IN_MILLISECONDS * 30)) 
                ){
                    // adjust the start time of the available slot to opening time of the store, if the opening time is after the slots start time
                    startTime = openingAt > startAt ? openingTime : startTime
                    //adjust the end time of the available slot to the closing time of the store, if the closing time is before the slots end time
                    endTime = closingAt < endAt ? closingTime : endTime
                    availableSlots[slot.id] = {
                        ...slot,
                        startTime,
                        endTime
                    }
                }
            })
            if (Object.keys(availableSlots).length > 0) weeklyAvailableSlots[dayOfWeek] = availableSlots
        }
    })
    return weeklyAvailableSlots
}

export const getWeeklyAvailableSlotsFromDeliveryAgentBasedDeliveryProvider = (supportedSlotMap, deliveryTripList) => {
    const weeklyAvailableSlots = {}
    //organize the trips by day of the week for efficient lookup
    const deliveryTripsByDayOfWeek = deliveryTripList.reduce((map, trip) => {
        //add the day if it is not there already
        if (!map[trip.dayOfWeek]) map[trip.dayOfWeek] = {}
        map[trip.dayOfWeek][trip.id] = trip
        return map
    }, {})
    DAYS_OF_THE_WEEK.forEach(dayOfWeek => {
        //any slots that intersect with at least one scheduled trip will be added here
        const availableSlotsOnDay = {}
        //if there are any trips on this day
        if (deliveryTripsByDayOfWeek[dayOfWeek]){
            const tripsOnDay = Object.values(deliveryTripsByDayOfWeek[dayOfWeek])

            Object.values(supportedSlotMap).forEach(slot => {
                const tripsInSlot = tripsOnDay.filter(trip => {
                    return slot.startTime <= trip.arriveAtTime && slot.endTime > trip.arriveAtTime
                })
                if (tripsInSlot.length > 0) availableSlotsOnDay[slot.id] = {...slot}
            })
        }
        if (Object.keys(availableSlotsOnDay).length > 0) weeklyAvailableSlots[dayOfWeek] = availableSlotsOnDay
    })
    return weeklyAvailableSlots
}

export const getListOfAvailableDeliverySlotsFromWeeklyAvailableSlotMap = (
    weeklyAvailableSlotMap={},
    numberOfDays=30,
    startDate=Date.now(),
    excludeHolidays=true,
    timezone=DOMINICA_TIMEZONE
) => {
    //TODO factor in holidays?
    const slots = []
    const startOfStartDay =  getTimestampForStartOfDay(startDate, !timezone, timezone)
    const endOfLastDay = startOfStartDay + (numberOfDays * DAY_IN_MILLISECONDS) - 1
    // find all days within the range provided
    // by starting at the provided start day, and add days until the end of the last day is reached
    for (let date = startOfStartDay; date < endOfLastDay; date += DAY_IN_MILLISECONDS){
        const dayOfTheWeek = timestampToWeekDay(date, !timezone, timezone)
        const dayString = timestampToDateString(date, "yyyy-MM-dd", !timezone, timezone)
        const isHoliday = excludeHolidays && HOLIDAYS[dayString]
        //gives us the (time) slots available on a given day of the week, 
        //this will be a subset of the overall supported slots
        //supported slots deals with time of day, this deals with day of week
        const availableSlotsForWeekDayMap = weeklyAvailableSlotMap[dayOfTheWeek] && !isHoliday?
                                         weeklyAvailableSlotMap[dayOfTheWeek]
                                         :
                                         {} 
        Object.values(availableSlotsForWeekDayMap)
              .sort((sA,sB) => timeStringSort(sA.startTime, sB.startTime))
              .map(availableSlot => {
                
                const startTimeString =  availableSlot.startTime
                const endTimeString = availableSlot.endTime
                const startTime = dateStringToTimestamp(`${dayString} ${startTimeString}`, "yyyy-MM-dd hh:mm", !timezone, timezone)
                const endTime = dateStringToTimestamp(`${dayString} ${endTimeString}`, "yyyy-MM-dd hh:mm", !timezone, timezone)
                slots.push({
                    id: startTime,
                    startTime,
                    endTime,
                    type: availableSlot.type,
                    title: availableSlot.title,
                    isAvailable: true
                })
              })   
    }
    return slots
}