import React from 'react'
import RadioButton from "../RadioButton"
import {
    ORIENTATION_HORIZONTAL,
    ORIENTATION_VERTICAL
} from "../../constants/interface"
function RadioList ({dataArray=[], label="name", onChange=()=>{}, selected="", allowBlank=true, editable=true, isActive=true, orientation=ORIENTATION_VERTICAL}){
    const getOptions = () =>{
        return dataArray.map(data => {
            return <RadioButton 
                        key={data.id} 
                        checked={selected === data.id}
                        editable={editable}
                        isActive={isActive}
                        onChange={() => onChange(data.id)}
                    >
                        {data[label]}
                    </RadioButton>
        })
    }
    const orientationStyles = orientation === ORIENTATION_HORIZONTAL ?
                              "horizontal"
                              :
                              ""
    return (
        <div
            className={`radioList ${orientationStyles}`}
        >
            {getOptions()}
        </div>
    )
}

export default RadioList