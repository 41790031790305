import React from "react"

import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

import styles from "./EventTimer.module.css"
import CountDown from "../../components/CountDown"
import Icon from "../../components/Icon"
import CloseButton from "../../components/CloseButton"

import {COUNTDOWN_SIZE_SMALL} from "../../constants/interface"
import {CLOSE_BUTTON_THEME_LIGHT} from "../../constants/interaction"

class EventTimer extends React.PureComponent {

    state = {
        hide: false
    }
    render(){
        const {events, history, location} =  this.props
        const {hide} = this.state
        //if there are no live components, display nothing
        if (!events.live || hide) return ""
        const liveEvent = events.eventsById[events.live]
        const handleGoToEvent = () => {
            const eventPath = `/events/${liveEvent.id}`
            if (location.pathname === eventPath) return
            history.push(eventPath)
        }
        return (
            <div className={styles.container}>
                <div onClick={handleGoToEvent}>
                    <div className={styles.name}>{liveEvent.name}</div>
                    <CountDown 
                        endTime={liveEvent.endDate}
                        size={COUNTDOWN_SIZE_SMALL}
                    />
                    <div className={styles.link}>see deals <Icon icon="keyboard-arrow-right"/></div>
                </div>
                <CloseButton 
                    theme={CLOSE_BUTTON_THEME_LIGHT}
                    onClick={() => this.setState({hide: true})}
                />
            </div>
        )
    }
    
}

const mapStateToProps = state => ({
    events: state.events
})

export default connect(mapStateToProps)(withRouter(EventTimer));