import React from "react"
import styles from "./CheckBox.module.css"

function CheckBox({children, checked, editable=true, onChange=()=>{}}){
    const editableStyle = editable ? styles.editable : null
    return(
        <label className={[styles.container, editableStyle].join(" ")}>
            <input 
                type="checkbox" 
                onChange={() => {if (editable) onChange()}}
                checked={checked}
            />
            <span className={styles.checkmark}></span>
            {children}
        </label>
    )
}

export default CheckBox;