import React from "react"
import styles from "./OrderFeeBreakdown.module.css"
import currency from 'currency.js'

const OrderFeeBreakdown = ({order={}, useActualSubTotal=true}) => {
    const subTotalXcd = (useActualSubTotal) ? 
                        //if the user wants the to use the actual up to date item totals, 
                        // then add up the actual item totals 
                        Object.values(order.orderItemsById).reduce((sum, item) => {
                            sum = sum.add(
                                item.refund ? 0
                                :
                                item.actualItemTotalXcd ? 
                                item.actualItemTotalXcd
                                :
                                item.price * item.quantity
                            )
                            return sum
                        }, currency(0)).value.toFixed(2)
                        :
                        order.subTotalXcd
    return (
        <div className={styles.container}>
            <div>Subtotal: <span>${subTotalXcd}</span></div>
            <div>Delivery fee: <span>${order.deliveryFeeXcd}</span></div>
            {Number(order.giftFeeXcd) > 0 ? <div>Gifting fee: <span>${order.giftFeeXcd}</span></div> : ""}
            <div>Processing fee: <span>${order.processingFeeXcd}</span></div>
            {Number(order.shopdmCreditOwedXcd) > 0 ? <div>Shopdm Credit Owed: <span className='badge warning'>+ ${order.shopdmCreditOwedXcd}</span></div> : ""}
            {Number(order.shopdmCreditUsedXcd) > 0 ? <div>Shopdm Credit Used: <span className='badge primary'>- ${order.shopdmCreditUsedXcd}</span></div> : ""}
        </div>
    )
}

export default OrderFeeBreakdown