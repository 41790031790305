export const OPENED = 'opened'
export const CUSTOMER_CONFIRMED_PAYMENT = 'customerConfirmedPayment'
export const CUSTOMER_SELECTED_PAY_INSTORE = 'customerSelectedPayInstore'
export const SHOPDM_CONFIRMED_PAYMENT = 'shopdmConfirmedPayment'
export const SELLER_SEEN = 'sellerSeen' 
export const SELLER_CONFIRMED_READY = "sellerConfirmedReady"
export const SELLER_ITEMS_OUT_FOR_DELIVERY = "sellerItemsOutForDelivery"
export const SELLER_ORDER_COMPLETION_VERIFIED = "sellerOrderCompletionVerified"
export const THIRD_PARTY_ITEMS_OUT_FOR_DELIVERY = "thirdPartyItemsOutForDelivery"
export const THIRD_PARTY_DELIVERY_COMPLETION_VERIFIED = "thirdPartyDeliveryCompletionVerified"
export const SELLER_ITEMS_UNSATISFACTORY = "sellerItemsUnsatisfactory"
export const SELLER_CANCELLED = 'sellerCancelled'
export const COMPLETED = 'completed'
export const CUSTOMER_CANCELLED = 'customerCancelled'
export const SHOPDM_CANCELLED = 'shopdmCancelled'
export const INCORRECT_PAYMENT_RECEIVED = 'incorrectPaymentReceived'
//testing data
// 1655721659959
// 1655721659970
// 1655721659980
// actualItemTotalXcd

// userId = H5HLjsnRcOed5g8YgS45jqgzMdn2
// orderId = 1dfb9330-786f-4e35-9e79-8203abcb1ea9
// paymentProviderId = yjeSCPFoDbZT6lrSjwZw
export const orderStatusToHumanReadable = {
    [OPENED]: OPENED,
    [CUSTOMER_CONFIRMED_PAYMENT]: 'customer says they paid',
    [CUSTOMER_SELECTED_PAY_INSTORE]: 'customer will pay instore',
    [SHOPDM_CONFIRMED_PAYMENT]: 'payment verified',
    [SELLER_SEEN]: "seen",
    [SELLER_CONFIRMED_READY]: "ready for pickup",
    [SELLER_ITEMS_OUT_FOR_DELIVERY]: "out for delivery",
    [SELLER_ORDER_COMPLETION_VERIFIED]: "seller has input the verification code",
    [THIRD_PARTY_ITEMS_OUT_FOR_DELIVERY]: "out for delivery via delivery service",
    [THIRD_PARTY_DELIVERY_COMPLETION_VERIFIED]: "delivery service has input the verification code",
    [SELLER_ITEMS_UNSATISFACTORY]: 'customer has problem with items',
    [SELLER_CANCELLED]: "cancelled by seller",
    [COMPLETED]: COMPLETED,
    [CUSTOMER_CANCELLED]: 'cancelled by customer',
    [SHOPDM_CANCELLED]: 'cancelled',
    [INCORRECT_PAYMENT_RECEIVED]: 'wrong amount paid'
}

export const orderStatusSequence = {
    [OPENED]: 1,
    [CUSTOMER_CONFIRMED_PAYMENT]: 2,
    [INCORRECT_PAYMENT_RECEIVED]: 2,
    [SHOPDM_CONFIRMED_PAYMENT]: 3,
    [CUSTOMER_SELECTED_PAY_INSTORE]: 3,
    [SELLER_SEEN]: 4,
    [SELLER_CONFIRMED_READY]: 5,
    [SELLER_ITEMS_OUT_FOR_DELIVERY]: 5,
    [SELLER_CANCELLED] : 6,
    [CUSTOMER_CANCELLED]: 6,
    [SHOPDM_CANCELLED]: 6,
    [THIRD_PARTY_ITEMS_OUT_FOR_DELIVERY]: 6,
    [SELLER_ORDER_COMPLETION_VERIFIED]: 6,
    [THIRD_PARTY_DELIVERY_COMPLETION_VERIFIED]: 7,
    [SELLER_ITEMS_UNSATISFACTORY]: 8,
    [COMPLETED]: 10
}