import React, {useState} from "react"
import ReviewContent from "../../components/ReviewContent"
import Modal from "../../components/Modal"

import styles from "./ReviewTile.module.css"

const ReviewTile = ({
    reviewId,
    name,
    profileImageUrl="",
    starRating=null,
    createdAt,
    reviewText="",
    isFullText=true,
    handleOpenReviewModal=()=>{},

}) => {
    const [modalOpen, setModalOpen] = useState(false)
    const handleOpenModal = () => {
        handleOpenReviewModal()
        setModalOpen(true)
    }
    return (
        <React.Fragment>
            <div className={`card ${styles.container}`}>
                <ReviewContent 
                    reviewId={reviewId}
                    name={name}
                    profileImageUrl={profileImageUrl}
                    starRating={starRating}
                    createdAt={createdAt}
                    reviewText={reviewText}
                    isFullText={isFullText}
                    handleShowMore={handleOpenModal}
                />
            </div>
            <Modal
                isOpen={modalOpen}
                closeModal={() => setModalOpen(false)}
                closeOnOverlayClick={true}
                // className={"modalTransparent"}
                // closeButtonTheme={CLOSE_BUTTON_THEME_LIGHT}
            >
                <ReviewContent 
                    reviewId={reviewId}
                    name={name}
                    profileImageUrl={profileImageUrl}
                    starRating={starRating}
                    createdAt={createdAt}
                    reviewText={reviewText}
                    isFullText={isFullText}
                    defaultShowMore={true}
                />
            </Modal>
        </React.Fragment>
    )
}

export default ReviewTile