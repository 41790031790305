import React from 'react'
import Logo from "../../images/logo300x300.png"
import Modal from "../Modal"
import DisabledButton from "../DisabledButton"

function ContainedModal({
    isOpen, 
    logo=Logo,
    closeModal=()=>{}, 
    onPressConfirm=()=>{},
    cancelButtonText="Cancel And Go Back",
    confirmButtonText="Confirm And Continue",
    title="Title",
    children,
    isDisabled=false,
    disabledText=""
}){
    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            showCloseButton={false}
            className="contained"
            innerClassName="contained"
        >
                <div className="modalHeader">
                    <img className="imgIcon" draggable="false" src={logo} alt="Shopdm Logo"/>
                    <div className="strong strongTitle">{title}</div>
                </div>
                <div className="modalInner contained">
                    {children}
                </div>
                <div className="modalFooter">
                    <button className="button dark" onClick={closeModal}>{cancelButtonText}</button>
                    {
                        isDisabled ?
                        <DisabledButton
                            alert={disabledText}
                        >
                            {confirmButtonText}
                        </DisabledButton>
                        :
                        <button className='button primary' onClick={() => onPressConfirm()}>
                            {confirmButtonText}
                        </button>
                    }
                </div>
        </Modal>
    )
}
export default ContainedModal