import React from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions";
import { objectsAreEqual} from "../../utils/generalUtils"
import {estimateSellerReadyForPickupAt} from "../../utils/deliveryUtils"
import {timestampToDateTimeString} from "../../utils/datetimeUtils"
import {DOMINICA_TIMEZONE, MINUTE_IN_MILLISECONDS} from "../../constants/datetime"
import {PICK_UP_INSTORE_ID} from "../../constants/delivery"

class SellerReadinessEstimator extends React.PureComponent {
    /**
     * Purpose:
     * 
     * 1. Estimate when sellers' items will be ready for pickup
     * 2. Update estimations whenever inputs change
     * 3. save readiness estimations in the cart in redux
     * 
     */
    constructor(props){
        super(props)
        this.state = {
            estimationIntervalId: setInterval(
                this.updateSellerReadinessEstimations, 
                MINUTE_IN_MILLISECONDS //run every minute
            ) 
        }
    }

    componentDidMount = () => this.updateSellerReadinessEstimations()

    componentDidUpdate = (prevProps) => {
        const {cart} = this.props
        //update the trips available to deliver the items to the destination area
        if ( 
            //if the assignment of delivery providers to sellers has changed
            //specifically, if the use of pickup instore changes
            !objectsAreEqual(
                cart.selectedDeliveryProviderIdsBySellerId, 
                prevProps.cart.selectedDeliveryProviderIdsBySellerId
            ) ||
            //if the pickup addresses for any of the stores has changed
            !objectsAreEqual(
                cart.selectedPickupAddressIdBySellerId,
                prevProps.cart.selectedPickupAddressIdBySellerId
            ) ||
            //if the items in the cart have changed (since the amount of items affects the time to prep the order)
            !objectsAreEqual(
                cart.itemsByProductStockId, 
                prevProps.cart.itemsByProductStockId
            )
        ){
            this.updateSellerReadinessEstimations()
        }
    }

    updateSellerReadinessEstimations = () => {
        const {actions, cart} = this.props
        const sellerReadinessEstimationsBySellerId = Object.keys(cart.itemIdsBySellerId)
                                                           .reduce((map, sellerId) => {
                                                                map[sellerId] = this.estimateSellerReadyForPickupAt(sellerId)
                                                                return map     
                                                            }, {})
        //update seller estimations in redux
        actions.updateSellerReadinessEstimations(sellerReadinessEstimationsBySellerId)
    }

    estimateSellerReadyForPickupAt = sellerId => {
        /**
         * Purpose: find the estimatedReadyForPickupAt for each store
         */
        const {sellers, cart} = this.props

        let estimation = {readyAt: 0}
        const seller = sellers.sellersById[sellerId]
        if (!seller) return estimation
        //find the number of distinct items from this seller involved in the order
        const lineItemCount = cart.itemIdsBySellerId[sellerId] ? 
                                Object.keys(cart.itemIdsBySellerId[sellerId]).length
                                :
                                0
        //find whether the delivery provider assigned to this seller is pickup instore and 
        //whether there is a selected pickup location
        //if so then use the selected pickup location for readiness calculations 
        //otherwise, use the seller's default address for the calculations
        const selctedDeliveryProviderId = cart.selectedDeliveryProviderIdsBySellerId[sellerId]
        const pickupAddressId = (
                                    selctedDeliveryProviderId === PICK_UP_INSTORE_ID && 
                                    cart.selectedDeliveryProviderIdsBySellerId[sellerId]
                                )
                                ?
                                    cart.selectedPickupAddressIdBySellerId[sellerId]
                                :
                                    seller.defaultAddressId
        estimation = estimateSellerReadyForPickupAt(seller, lineItemCount, pickupAddressId,30, 7.5, DOMINICA_TIMEZONE)
        return estimation
    }

    componentWillUnmount = () => {
        //clear the interval that recalculates the available delivery trips
        if (this.state.estimationIntervalId) {
            clearInterval(this.state.estimationIntervalId)
        }
    }

    render(){
        const {cart, sellers} = this.props
        if (cart.sellerReadinessEstimationsBySellerId){
            // Object.keys(cart.sellerReadinessEstimationsBySellerId)
            //       .forEach(sellerId => {
            //           const seller = sellers.sellersById[sellerId]
            //           const estimation = cart.sellerReadinessEstimationsBySellerId[sellerId]
            //           const estimatedReadyAt = timestampToDateTimeString(estimation.readyAt)
            //           console.log(`${seller.name} estimated ready for pickup at ${estimatedReadyAt}, seller id ${seller.id}`)
            //       })
        }
        return ""
    }
}


const mapStateToProps = state => ({
    cart: state.cart,
    sellers: state.sellers
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SellerReadinessEstimator)