import React from "react"
import currency from "currency.js"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import CartItem from "../../components/CartItem"
import FormField from "../../components/FormField"

import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

import styles from "./CartItemList.module.css"
import {capitalizeAllWords, alphabeticalSort, stringBContainsStringA} from "../../utils/stringUtils"

import {logAnalyticsEvent} from "../../config/firebase"
import {ecommerceEvents} from "../../constants/firebase"
import ShoppingCart from "../../images/shopping-cart.png"
import {getProductStockPromotion, calculatePromotionalPrice, getPromotionOfferString} from "../../utils/promotions"
import {TIMING_TYPE_EVENT} from "../../constants/promotions"

class CartItemList extends React.Component{
    
    state = {
        searchTerm: ""
    }

    componentWillMount(){
        const {cart, actions, products} = this.props
        //google analytics
        if (cart.itemIds.length > 0 ){
            const analyticsObject = cart.itemIds.reduce((analyticsObject, itemId) => {
                const item = cart.itemsByProductStockId[itemId]
                const product = products.productsById[item.productId]
                if (product && product.analyticsItem){
                    analyticsObject.items.push(product.analyticsItem)
                    analyticsObject.value += product.analyticsItem.price
                } 
                return analyticsObject
            }, {items: [], currency: "XCD", value: 0})
            logAnalyticsEvent(ecommerceEvents.VIEW_CART, analyticsObject);
            actions.fetchLogDeviceSessionAction({
                action: "viewCart", 
            })
        } 
    }
    handleGoToCheckout = () => {
        const {history} = this.props
        history.push("/checkout")
    }
    
    render(){
        const {cart, products, sellers, user, promotions, events, actions, history} = this.props
        const {searchTerm} = this.state
        const items = cart.itemIds.map(itemId => {
            const item = {...cart.itemsByProductStockId[itemId]}
            const product = products.productsById[item.productId]
            if (!product) return {}
            item.titleAndBrand = product.titleAndBrand
            return item
        })
        .filter(item => Boolean(item.id))
        .sort((itemA, itemB)=> alphabeticalSort(itemA.titleAndBrand, itemB.titleAndBrand))

        const filteredItems = searchTerm && searchTerm.trim() ? 
                                items.filter(item => stringBContainsStringA(searchTerm, item.titleAndBrand))
                                : items

        const totals = items.reduce((totals, item) => {
            const promotion = getProductStockPromotion(
                item.id,
                item.productId, 
                item.sellerId, 
                user.activeSellerAccountId, 
                promotions
            )
            const price = promotion ? 
                            calculatePromotionalPrice(item.price, promotion)
                            : 
                            item.price
    
            totals.balance += currency(price).multiply(item.quantity).value
            totals.count += item.quantity
            return totals
        }, {balance: 0, count: 0})
        

        return (
            <div className={styles.container}>
                <div className={styles.top}>
                    <p className={['title', styles.title].join(" ")}><img src={ShoppingCart}/>Your Shopping Cart ({totals.count})</p>
                    {
                            items.length ?
                            <GoToCheckoutPanel total={totals.balance} onClick={this.handleGoToCheckout}/>
                            :
                            null
                    }
                </div>
                <div className={styles.innerContainer}>
                    {
                        items.length ?
                            <div className={styles.list}>
                            <FormField 
                                value={searchTerm} 
                                label={searchTerm && searchTerm.trim() ? `Search matched ${filteredItems.length} out of ${items.length} items`: "Search by Item Name"}
                                onChange={e => this.setState({searchTerm: String(e.target.value)})}
                                placeholder={`e.g. "${items[0].titleAndBrand}"`}
                            />
                            {
                                filteredItems.map(item => {
                                    const product = products.productsById[item.productId]
                                    if (!item || !product) return "" //deal with poor internet
                                    const imageUrl = item.imageUrl ? item.imageUrl : product.imageUrl
                                    const seller = sellers.sellersById[item.sellerId]
                                    //google analytics
                                    const handleRemove = () => {
                                        logAnalyticsEvent(ecommerceEvents.REMOVE_FROM_CART, product.analyticsItem)
                                        actions.fetchLogDeviceSessionAction({
                                            action: "removeFromCart",
                                            productStockId: item.id, 
                                        })
                                        actions.fetchRemoveFromCart(item.id)
                                    }
                                    const handleChangeQuantity = quantity => actions.fetchUpdateCartItemQuantity(item.id, quantity)
                                    {/* const handleViewSellerProfile = () => history.push(`/seller-profile/${seller.id}`) */}
                                    //TODO change after adding seller profile
                                    const handleViewSellerProfile = () => history.push(`/sellers/${seller.id}`)
                                    const handleViewProductDetails = () => history.push(`/product-details?pid=${product.id}&psid=${item.id}`, {from: history.location.pathname})
                                    const promotion = getProductStockPromotion(
                                        item.id,
                                        product.id, 
                                        product.createdBySellerId, 
                                        user.activeSellerAccountId, 
                                        promotions
                                    )
                                    let event = promotion && promotion.timingType === TIMING_TYPE_EVENT ?
                                    events.eventsById[promotion.terms.eventId]
                                    :
                                    null

                                    let productTotal = currency(item.price).multiply(item.quantity).value
                                    let previousTotal
                                    let promotionOfferString =""
                                    if (promotion) {
                                        previousTotal=productTotal
                                        productTotal=calculatePromotionalPrice(item.price, promotion) * item.quantity
                                        promotionOfferString = getPromotionOfferString(promotion)
                                    }
                                    return <CartItem 
                                                key={item.id}
                                                id={item.id}
                                                imageUrl={imageUrl} 
                                                productTitle={product.titleAndBrand}
                                                productTotal={productTotal}
                                                previousTotal={previousTotal}
                                                characteristics={item.characteristics}
                                                quantity={item.quantity}
                                                promotion={promotion}
                                                event={event}
                                                promotionOfferString={promotionOfferString}
                                                sellerName={capitalizeAllWords(seller.name)}
                                                maxQuantity={item.quantityInStock}
                                                minimumOrderQty={product.minimumOrderQty}
                                                units={product.units}
                                                onChangeQuantity={handleChangeQuantity}
                                                onRemove={handleRemove}
                                                onViewSellerProfile={handleViewSellerProfile}
                                                onViewProductDetails={handleViewProductDetails}
                                            />
                                })
                            }
                            </div>
                    :
                    <div>Your cart is empty</div>
                    }
                </div>
                <div className={styles.bottom}>
                    {
                        items.length ?
                        <GoToCheckoutPanel total={totals.balance} onClick={this.handleGoToCheckout}/>
                        :
                        null
                    }
                </div>
            </div>
        )
    }
}

const GoToCheckoutPanel = ({total, onClick=()=>{}}) => (
    <div className={styles.checkoutPanel}>
        <p>Subtotal: <span className={'strong'}>EC ${Number(total).toFixed(2)}</span></p>
        <button 
            className={["button", "primary"].join(" ")}
            onClick={onClick}
        >
            Go To Checkout
        </button>
    </div>
)
const mapStateToProps = state => ({
    cart: state.cart,
    products: state.products,
    sellers: state.sellers,
    user: state.user,
    promotions: state.promotions,
    events: state.events
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CartItemList));