import React from "react"

import {
    OPENED, 
    CUSTOMER_SELECTED_PAY_INSTORE,
    SHOPDM_CONFIRMED_PAYMENT, 
    SELLER_SEEN,
    SELLER_CONFIRMED_READY, 
    SELLER_ITEMS_OUT_FOR_DELIVERY,
    SELLER_ORDER_COMPLETION_VERIFIED,
    THIRD_PARTY_ITEMS_OUT_FOR_DELIVERY, 
    THIRD_PARTY_DELIVERY_COMPLETION_VERIFIED,
    CUSTOMER_CONFIRMED_PAYMENT
} from "../../constants/order"
import {PICK_UP_INSTORE, SELF_DELIVERY, THIRD_PARTY_DELIVERY} from "../../constants/delivery"

import styles from "./CurrentOrderStatus.module.css"
import {capitalizeAllWords} from "../../utils/stringUtils"
import { timestampToDateTimeString } from "../../utils/datetimeUtils"
import OrderStatusBadge from "../../components/OrderStatusBadge"

function CurrentOrderStatus({order}) {
    if (!order) return ""
    return (
        <div className={styles.container}>
            <div className={styles.orderHeader}>
                <div className={styles.orderTitle}>Now Tracking Order {order.orderNumber}</div>
                <div>Status: <OrderStatusBadge currentOrderStatus={order.currentStatus}/></div>
            </div>
            {
                order.sellerIds.map(sellerId => {
                    const seller = order.sellersById[sellerId]
                    const sellerOrderStatus = order.sellerOrderStatus[sellerId]
                    const deliveryProvider = order.deliveryProvidersById ? 
                                             order.deliveryProvidersById[seller.deliveryProviderId]
                                             :
                                             null
                    if (!deliveryProvider) return ""
                    return <SellerOrderStatus 
                                key={sellerId} 
                                orderId={order.id}
                                seller={seller} 
                                sellerOrderStatus={sellerOrderStatus}
                                orderStatus={order.orderStatus}
                                deliveryProvider={deliveryProvider}
                                isPayInstore={order.isPayInstore}
                            />
                })
            }
        </div>
    )
}

function SellerOrderStatus({orderId, seller, sellerOrderStatus={}, orderStatus={}, deliveryProvider={}, isPayInstore=false}){
    let stages = null
    let deliveryText = "Delivery by "
    const sellerName = capitalizeAllWords(seller.name)
    const seenOrReadyForPickup = sellerOrderStatus[SELLER_SEEN] ?
                                  sellerOrderStatus[SELLER_SEEN] :
                                  sellerOrderStatus[SELLER_CONFIRMED_READY] ?
                                  sellerOrderStatus[SELLER_CONFIRMED_READY] :
                                  sellerOrderStatus[SELLER_ITEMS_OUT_FOR_DELIVERY] ?
                                  sellerOrderStatus[SELLER_ITEMS_OUT_FOR_DELIVERY]
                                  : null

    const currentStatus = {status: "", at: 0}
    const statuses = {...orderStatus, ...sellerOrderStatus}
    Object.keys(statuses)
          .forEach(key => {
              const value = statuses[key]
                if (value > currentStatus.at){
                    currentStatus.status = key
                    currentStatus.at = value
                }
          })
    
    if (seller.deliveryMethod === THIRD_PARTY_DELIVERY){
        stages = [
            <Stage 
                key={`${orderId}${seller.id}${OPENED}`} 
                isStart={true} 
                isCurrent={currentStatus.status === OPENED || currentStatus.status === CUSTOMER_CONFIRMED_PAYMENT} 
                label="Order Placed" 
                completed={orderStatus[OPENED]}
            />,
            <Stage 
                key={`${orderId}${seller.id}${SHOPDM_CONFIRMED_PAYMENT}`} 
                isCurrent={currentStatus.status === SHOPDM_CONFIRMED_PAYMENT} 
                label="Payment Processed" 
                completed={orderStatus[SHOPDM_CONFIRMED_PAYMENT]}
            />,
            <Stage 
                key={`${orderId}${seller.id}${SELLER_SEEN}`} 
                isCurrent={currentStatus.status === SELLER_SEEN} 
                label={`Seen By ${sellerName}`} 
                completed={seenOrReadyForPickup}
            />,
            <Stage 
                key={`${orderId}${seller.id}${SELLER_CONFIRMED_READY}`} 
                isCurrent={currentStatus.status === SELLER_CONFIRMED_READY} 
                label="Items Ready" 
                completed={sellerOrderStatus[SELLER_CONFIRMED_READY]}
            />,
            <Stage 
                key={`${orderId}${seller.id}${THIRD_PARTY_ITEMS_OUT_FOR_DELIVERY}`} 
                isCurrent={currentStatus.status === THIRD_PARTY_ITEMS_OUT_FOR_DELIVERY} 
                label={`Picked Up By ${capitalizeAllWords(deliveryProvider.name)}`} 
                completed={sellerOrderStatus[THIRD_PARTY_ITEMS_OUT_FOR_DELIVERY]}
            />,
            <Stage 
                key={`${orderId}${seller.id}${THIRD_PARTY_DELIVERY_COMPLETION_VERIFIED}`} 
                label="Delivered" 
                completed={sellerOrderStatus[THIRD_PARTY_DELIVERY_COMPLETION_VERIFIED]}
            />
        ]
        deliveryText += capitalizeAllWords(deliveryProvider.name)
    } else if (seller.deliveryMethod === SELF_DELIVERY){
        stages = [
            <Stage 
                key={`${orderId}${seller.id}${OPENED}`} 
                isStart={true} 
                isCurrent={currentStatus.status === OPENED || currentStatus.status === CUSTOMER_CONFIRMED_PAYMENT} 
                label="Order Placed" 
                completed={orderStatus[OPENED]}
            />,
            <Stage 
                key={`${orderId}${seller.id}${SHOPDM_CONFIRMED_PAYMENT}`} 
                isCurrent={currentStatus.status === SHOPDM_CONFIRMED_PAYMENT} 
                label="Payment Processed" 
                completed={orderStatus[SHOPDM_CONFIRMED_PAYMENT]}
            />,
            <Stage 
                key={`${orderId}${seller.id}${SELLER_SEEN}`}
                isCurrent={currentStatus.status === SELLER_SEEN}  
                label={`Seen By ${sellerName}`} 
                completed={seenOrReadyForPickup}
            />,
            <Stage 
                key={`${orderId}${seller.id}${SELLER_ITEMS_OUT_FOR_DELIVERY}`} 
                isCurrent={currentStatus.status === SELLER_ITEMS_OUT_FOR_DELIVERY} 
                label={`${capitalizeAllWords(seller.name)} Started Your Delivery`} 
                completed={sellerOrderStatus[SELLER_ITEMS_OUT_FOR_DELIVERY]}
            />,
            <Stage 
                key={`${orderId}${seller.id}${SELLER_ORDER_COMPLETION_VERIFIED}`} 
                label="Delivered" 
                completed={sellerOrderStatus[SELLER_ORDER_COMPLETION_VERIFIED]}
            />
        ]  
        deliveryText += `${capitalizeAllWords(seller.name)} (the merchant)`
    } else if (seller.deliveryMethod === PICK_UP_INSTORE){
        if (isPayInstore){
            stages = [
                <Stage 
                    key={`${orderId}${seller.id}${OPENED}`} 
                    isCurrent={currentStatus.status === OPENED || currentStatus.status === CUSTOMER_CONFIRMED_PAYMENT} 
                    isStart={true} 
                    label="Order Placed" 
                    completed={orderStatus[OPENED]}
                />,
                <Stage 
                    key={`${orderId}${seller.id}${CUSTOMER_SELECTED_PAY_INSTORE}`} 
                    isCurrent={currentStatus.status === CUSTOMER_SELECTED_PAY_INSTORE} 
                    label="You Selected Pay In Store" 
                    completed={orderStatus[CUSTOMER_SELECTED_PAY_INSTORE]}
                />,
                <Stage 
                    key={`${orderId}${seller.id}${SELLER_SEEN}`} 
                    isCurrent={currentStatus.status === SELLER_SEEN} 
                    label={`Seen By ${sellerName}`} 
                    completed={seenOrReadyForPickup}
                />,
                <Stage 
                    key={`${orderId}${seller.id}${SELLER_CONFIRMED_READY}`} 
                    isCurrent={currentStatus.status === SELLER_CONFIRMED_READY} 
                    label="Items Ready For Pickup" 
                    completed={sellerOrderStatus[SELLER_CONFIRMED_READY]}
                />,
                <Stage 
                    key={`${orderId}${seller.id}${SELLER_ORDER_COMPLETION_VERIFIED}`} 
                    label={`You Picked Up Your Items And Paid`} 
                    completed={sellerOrderStatus[SELLER_ORDER_COMPLETION_VERIFIED]}
                />
            ]  
        } else {
            stages = [
                <Stage 
                    key={`${orderId}${seller.id}${OPENED}`} 
                    isCurrent={currentStatus.status === OPENED || currentStatus.status === CUSTOMER_CONFIRMED_PAYMENT} 
                    isStart={true} 
                    label="Order Placed" 
                    completed={orderStatus[OPENED]}
                />,
                <Stage 
                    key={`${orderId}${seller.id}${SHOPDM_CONFIRMED_PAYMENT}`} 
                    isCurrent={currentStatus.status === SHOPDM_CONFIRMED_PAYMENT} 
                    label="Payment Processed" 
                    completed={orderStatus[SHOPDM_CONFIRMED_PAYMENT]}
                />,
                <Stage 
                    key={`${orderId}${seller.id}${SELLER_SEEN}`} 
                    isCurrent={currentStatus.status === SELLER_SEEN} 
                    label={`Seen By ${sellerName}`} 
                    completed={seenOrReadyForPickup}
                />,
                <Stage 
                    key={`${orderId}${seller.id}${SELLER_CONFIRMED_READY}`} 
                    isCurrent={currentStatus.status === SELLER_CONFIRMED_READY} 
                    label="Items Ready For Pickup" 
                    completed={sellerOrderStatus[SELLER_CONFIRMED_READY]}
                />,
                <Stage 
                    key={`${orderId}${seller.id}${SELLER_ORDER_COMPLETION_VERIFIED}`} 
                    label={`You Picked Up Your Items`} 
                    completed={sellerOrderStatus[SELLER_ORDER_COMPLETION_VERIFIED]}
                />
            ]  
        }
        deliveryText = "You Must Pick Up The Items Yourself"
    } 
    
    
    return (
        <div className={styles.statusRow}>
            <div className={styles.sellerName}>
                <div>{seller.name}</div>
                <div className={styles.deliveryText}>{deliveryText}</div>
            </div>
            <div className={styles.stages}>
                {stages}
            </div>
        </div>
    )
}

function Stage({label, isStart=false, isCurrent=false, completed=false}){
    const startStyles = isStart ? styles.startStage : ""
    const currentStyles = isCurrent ? styles.current : ""
    const completedStyles = completed ? styles.completed : ""
    return (
        <div className={[styles.stage, startStyles].join(" ")}>
            
            <div className={styles.stageVisual}>
                {
                    !isStart ?
                    <div className={[styles.path, completedStyles].join(" ")}></div>
                    :
                    null
                }
                <div className={[styles.circle, currentStyles, completedStyles].join(" ")}></div>
            </div>
            <div className={styles.stageLabel}>
                <div>
                    <div className={styles.label}>{label}</div> 
                    {completed ? <div>{timestampToDateTimeString(completed, true, true, false)}</div> : null}
                </div>    
            </div>
        </div>
    )
}

export default CurrentOrderStatus