/**
 *  Purpose: the modifications that are carried on the Credit Transaction Request objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // creditTransactionRequests:{
 //   creditTransactionRequestsById:{},
 //   creditTransactionRequestIds: []
 // }
 
 
 const creditTransactionRequestsReducer = (state = initialState.creditTransactionRequests, action) => {
     const {type, payload} = action;
     let creditTransactionRequestsById = {...state.creditTransactionRequestsById}
     switch (type){
         case types.SAVE_CREDIT_TRANSACTION_REQUESTS: {
             if(typeof payload.creditTransactionRequests !== "object") {
                 logError(`creditTransactionRequestsReducer > SAVE_CREDIT_TRANSACTION_REQUESTS: creditTransactionRequests payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.creditTransactionRequests.forEach(request => {
                 creditTransactionRequestsById[request.id] = request
             });
             return {
                 ...state,
                 creditTransactionRequestsById,
                 creditTransactionRequestIds : Object.keys(creditTransactionRequestsById)
             }
         }
 
         default: return state
     }
 }
 
 export default creditTransactionRequestsReducer