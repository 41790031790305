import React from "react"

import Modal from "../../components/Modal"
import AccountNeededModal from "../../components/AccountNeededModal"
import CharacteristicsList from "../../components/CharacteristicsList"
import IncrementInput from "../../components/IncrementInput"

import styles from "./RequestRestockButton.module.css"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"
import { capitalizeAllWords } from "../../utils/stringUtils";
import {v4 as uuid4} from 'uuid'

import ResultModal from "../../components/ResultModal"
import {RESULT_SUCCESS, RESULT_WARNING} from "../../constants/results"

class RequestRestockButton extends React.PureComponent {

    constructor(props){
        super(props)
        const {minimumOrderQty} = props
        this.state = {
            authModalOpen: false,
            requestModalOpen: false,
            quantity: minimumOrderQty,
            imageLoaded: false,
            result: null,
            resultMessage: "",
            restockRequestsListener: () => {}
        }
    }

    static defaultProps = {
        sellerName: "",
        minimumOrderQty: 1,
        variantCharacteristics: {},
        skuNumber: "",
        productTitle: "",
        productImageUrl: "",
        restockRequestCount: null
    }

    handleOpenAuthModal = () => this.setState({authModalOpen: true})
    handleCloseAuthModal = () => this.setState({authModalOpen: false})
    
    handleOpenRequestModal = async () => {
        const {actions, user, productId} = this.props
        actions.toggleLoading(true)
        const restockRequestsListener = await actions.fetchSubscribeToMyRestockRequestsForProduct(productId, user.id)
        actions.toggleLoading(false)
        this.setState({requestModalOpen: true, restockRequestsListener})
    }
    
    handleCloseRequestModal = () => this.setState({requestModalOpen: false}, this.clearListeners)

    clearListeners = () => {
        const {restockRequestsListener} = this.state
        if (typeof restockRequestsListener === "function") {
            restockRequestsListener()
        }
        this.setState({restockRequestsListener: () => {}})
    }

    handleClick = async () => {
        const {user} = this.props
        if (!user.authenticated) this.handleOpenAuthModal()
        else {
            this.handleOpenRequestModal()
        }
    }

    handleAuthSuccess = () => {
        const {user, restockRequests, productStockId} = this.props
        this.handleCloseAuthModal()
        //if there is no existing restock request, then auto open the request modal 
        if (!this.getOpenRestockRequestForThisProductVariant()){
            this.handleOpenRequestModal()
        } else this.handleShowRestockAlreadyRequested()
    }

    handleIncrementQuantity = quantity => this.setState({quantity})

    handleImageLoaded = () => this.setState({imageLoaded: true})

    handleCreateRestockRequest = async () => {
        const {quantity} = this.state
        const {actions, productId, productStockId, productImageUrl, productTitle, variantCharacteristics, price, skuNumber, sellerId, sellerName, sellerAlertEmail, sellerLogoImageUrl, user, restockRequests} = this.props
        //if we have somehow gotten to this point with previous restock requests active
        //then we close the request modal and show the alert that a restock request has already been made
        if (this.getOpenRestockRequestForThisProductVariant()){
            this.handleShowRestockAlreadyRequested()
            return
        }
        if(!window.confirm(`Are you sure you want to send this restock request?`)) return
        actions.toggleLoading(true)
        await actions.fetchCreateRestockRequest(
            uuid4(),
            productId,
            productStockId,
            quantity,
            price,
            productImageUrl,
            productTitle,
            variantCharacteristics,
            skuNumber,
            sellerId,
            sellerName,
            sellerAlertEmail,
            sellerLogoImageUrl,
            this.handleRequestSuccess,
            this.handleRequestError
        )
        actions.toggleLoading(false)
    }

    handleRequestSuccess = () =>{
        this.setState({
            result: RESULT_SUCCESS,
            resultMessage: "Request received. We'll alert you when it's restocked"
        })
    }

    handleRequestError = errorMessage => {
        this.setState({
            result: RESULT_WARNING,
            resultMessage: `Something went wrong. Your request was not sent. Please try again: ${errorMessage}`
        })
    }

    handleCloseResultModal = () => {
        const {result} = this.state
        this.setState({result: null, resultMessage: ""},
            () => {if (result === RESULT_SUCCESS) this.handleCloseRequestModal()}
        )
    }

    handleShowRestockAlreadyRequested = () => {
        const sellerName = capitalizeAllWords(this.props.sellerName)
        alert(`You've already asked ${sellerName} to restock this item. We will alert you when they do.\n\nIf you know someone else who'd like to buy this item, you can also ask them to make a request.`)
        if (this.state.requestModalOpen) this.handleCloseRequestModal()
    }

    componentWillUnmount = () => {
        this.clearListeners()
    }

    getOpenRestockRequestForThisProductVariant = () => {
        /**
         * Purpose: determine whether the product variant has an open restock request from a specified user
         */
        const {restockRequests, user, productStockId} = this.props
        //determine whether there are any restock requests
        //if not, return
        if (!(
            restockRequests.productStockIdsByUserId[user.id] && 
            restockRequests.productStockIdsByUserId[user.id][productStockId]
        )) return ""
        //of the restock requests, check whether any are open
        //if so, there should only be one
        const restockRequestIdMap = restockRequests.productStockIdsByUserId[user.id][productStockId]
        const openRestockRequestId = Object.keys(restockRequestIdMap).find(restockRequestId => {
            const restockRequest = restockRequests.restockRequestsById[restockRequestId]
            return restockRequest && !restockRequest.closedAt
        }) 
        return openRestockRequestId ? openRestockRequestId : ""

    }

    render(){
        const {authModalOpen, requestModalOpen, quantity, imageLoaded, result, resultMessage} = this.state
        let {productStockId, productTitle, productImageUrl, variantCharacteristics, sellerName, minimumOrderQty, restockRequestCount, user, restockRequests} = this.props
        const userName = user ? capitalizeAllWords(`${user.firstName} ${user.lastName}`) : ""
        sellerName = capitalizeAllWords(sellerName)
        //used to display a placeholder while the image loads
        const frameStyle = (imageLoaded) ?
                            "postload"
                            :
                            "preload"
        //find out whether this stock id has been restock requested by the current user
        const existingRestockRequestId = this.getOpenRestockRequestForThisProductVariant()
        return (
            <React.Fragment>
                {
                    existingRestockRequestId ?
                    <button className={`button ${styles.button}`} onClick={this.handleShowRestockAlreadyRequested}>
                        {
                            restockRequestCount > 1 ?
                            <React.Fragment>
                                <span className="numberBadge dark">{restockRequestCount}</span> People Requested
                            </React.Fragment>
                            :
                            "You Requested This"
                        }
                    </button>
                    :
                    <button className={`button ${styles.button}`} onClick={this.handleClick}>Request Restock</button>
                }
                {
                    authModalOpen? 
                    <AccountNeededModal 
                        title={`to ask ${sellerName} to restock this item`}
                        modalOpen={authModalOpen}
                        handleCloseModal={this.handleCloseAuthModal}
                        handleSuccess={this.handleAuthSuccess}
                    />
                    :
                    null
                }
                {
                    requestModalOpen & user.authenticated?
                    
                    <Modal
                        isOpen={requestModalOpen}
                        closeModal={this.handleCloseRequestModal}
                        className={`fit ${styles.modal}`}
                        overlayClassName={`center ${styles.modalOverlay}`}
                        closeOnOverlayClick={true}
                        title={`Request Restock`}
                    >    
                        <div>Alert {sellerName} that <span className="bold">{userName}</span> wants to buy <span className="numberBadge dark">{quantity}</span> of this item?</div>
                        <div className={`strong ${styles.productDetails}`}>
                            <div>
                                <div>{productTitle}</div>
                                <div>
                                    <CharacteristicsList 
                                        id={productStockId}
                                        characteristics={variantCharacteristics}
                                    />
                                </div>
                                <div className={`${frameStyle} ${styles.imageFrame}`}>
                                    <img 
                                        className={`productImage ${styles.image}`} 
                                        src={productImageUrl} 
                                        alt={productTitle}
                                        onLoad={this.handleImageLoaded}
                                    />
                                </div>
                                <IncrementInput 
                                    value={quantity}
                                    onChange={this.handleIncrementQuantity}
                                    min={minimumOrderQty}
                                />
                            </div>                            
                        </div>
                        <button className={`button ${styles.button}`} onClick={this.handleCreateRestockRequest}>Send Request</button>
                    </Modal>
                    :
                    null
                }
                {
                    result ? 
                    <ResultModal 
                        result={result}
                        autoClose={result === RESULT_SUCCESS}
                        message={resultMessage}
                        onClose={this.handleCloseResultModal}
                    />
                     : 
                    null
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    restockRequests: state.restockRequests
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestRestockButton)