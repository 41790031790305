export const objectsAreEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true
    if (typeof obj1 !== "object" || !obj1 || typeof obj2 !== "object" || !obj2) return false 
    let props1 = Object.getOwnPropertyNames(obj1);
    let props2 = Object.getOwnPropertyNames(obj2);
    if (props1.length != props2.length) {
      return false;
    }
    for (let i = 0; i < props1.length; i++) {
      let prop = props1[i];
      let bothAreObjects = typeof(obj1[prop]) === 'object' && typeof(obj2[prop]) === 'object';
      if ((!bothAreObjects && (obj1[prop] !== obj2[prop]))
      || (bothAreObjects && !objectsAreEqual(obj1[prop], obj2[prop]))) {
        return false;
      }
    }
    return true;
  }

  export const objectsHaveSameKeys = (a, b) => {
    var aKeys = Object.keys(a).sort();
    var bKeys = Object.keys(b).sort();
    return JSON.stringify(aKeys) === JSON.stringify(bKeys);
  }

export const invertObject = map  => {
    const inverted = {};
    Object.keys(map).forEach(key => inverted[map[key]] = key)
    return inverted;
}