/**
 *  Purpose: the modifications that are carried on the Reaction objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // reactions:{
 //   reactionsByObjectId:{},
 //   reactionObjectIds: [],
 // }
 
 const reactionsReducer = (state = initialState.reactions, action) => {
     const {type, payload} = action;
     let reactionsByObjectId = {...state.reactionsByObjectId}
     switch (type){
         case types.SAVE_SELLER_REACTIONS: {
            if(typeof payload.reactions !== "object") {
            logError(`reactionsReducer > SAVE_SELLER_REACTIONS: reactions in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.sellerId !== "string") {
            logError(`reactionsReducer > SAVE_SELLER_REACTIONS: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.objectId !== "string") {
            logError(`reactionsReducer > SAVE_SELLER_REACTIONS: objectId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            reactionsByObjectId[payload.objectId] = payload.reactions
            return {
                ...state,
                reactionsByObjectId,
                reactionObjectIds: Object.keys(reactionsByObjectId)
            }
         }
        
        case types.CREATE_REACTION: {
            if(typeof payload.reaction !== "object") {
                logError(`reactionsReducer > CREATE_REACTION: reaction in payload is not a object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.reactionCount !== "number") {
                logError(`reactionsReducer > CREATE_REACTION: reactionCount in payload is not a number ${JSON.stringify(payload)}`)
                return state
            }
            const reaction = payload.reaction
            if (!reactionsByObjectId[reaction.objectId]) {
                reactionsByObjectId[reaction.objectId] = {
                    id: `${reaction.objectId}.reactions`,
                    objectId: reaction.objectId,
                    objectType: reaction.objectType,
                    entityType: reaction.entityType,
                    entityId: reaction.entityId,
                    reactions: {}
                }
            }
            reactionsByObjectId[reaction.objectId].reactions[reaction.userId] = {
                    reactionType: reaction.reactionType,
                    reactedAt: reaction.reactedAt,
                    userId: reaction.userId,
                    userName: reaction.userName,
                    profileImageUrl: reaction.profileImageUrl
            }
            return {
                ...state,
                reactionsByObjectId,
                reactionObjectIds: Object.keys(reactionsByObjectId)
            }
        }

        case types.DELETE_REACTION: {
            if(typeof payload.reactionId !== "string") {
                logError(`reactionsReducer > DELETE_REACTION: reactionId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.reactionType !== "string") {
                logError(`reactionsReducer > DELETE_REACTION: reactionType in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.userId !== "string") {
                logError(`reactionsReducer > DELETE_REACTION: userId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.objectId !== "string") {
                logError(`reactionsReducer > DELETE_REACTION: objectId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.objectType !== "string") {
                logError(`reactionsReducer > DELETE_REACTION: objectType in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.reactionCount !== "number") {
                logError(`reactionsReducer > DELETE_REACTION: reactionCount in payload is not a number ${JSON.stringify(payload)}`)
                return state
            }
            if (reactionsByObjectId[payload.objectId]){
                reactionsByObjectId[payload.objectId].reactions = {
                    ...reactionsByObjectId[payload.objectId].reactions
                }
                delete reactionsByObjectId[payload.objectId].reactions[payload.userId]
            }
            return {
                ...state,
                reactionsByObjectId,
                reactionObjectIds: Object.keys(reactionsByObjectId)
            }
        }
         default: return state
     }
 }
 
 export default reactionsReducer