import {firebaseApp} from "../config/firebase"
import {searchServiceUrl} from "../config/services"
import { getFirestore, doc, setDoc, increment} from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"
import { getDateString } from "../utils/datetimeUtils"
import {getAnalyticsItem} from "../utils/productUtils"
import {saveProducts} from "./products"

export const UPDATE_QUERY_STRING = 'UPDATE_QUERY_STRING'
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS'

export const updateQueryString = queryString => {
    return {
        type: UPDATE_QUERY_STRING,
        payload: {
            queryString
        }
    }
}

export const searchProducts = results => {
    return {
        type: SEARCH_PRODUCTS,
        payload: {
            results
        }
    }
}

export const fetchSearchProducts = (
    queryString,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const link = `${searchServiceUrl}v1/search/products`
    return async (dispatch, getState) => {
        try{
            const {categories, sellers} = getState()
            let json
            const response = await fetch(link, {
                "method": 'POST',
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                "body": JSON.stringify({
                    queryString
                }) 
            })
            if (!response.ok) {
                let errorMessage = "Internal Server Error"
                if (response.status === 404) errorMessage = "Product matches not found"
                throw new Error(errorMessage, json);
            }
            const results = await response.json()
            if (results.products ){
                results.products.forEach(product => {
                    const seller = sellers.sellersById[product.createdBySellerId]
                    product.analyticsItem = getAnalyticsItem(product, categories, seller)
                })
            }
            
            onSuccess()
            dispatch(searchProducts(results))
            if (results.products && results.products.length > 0 ){
                dispatch(saveProducts(results.products))
            }
            return true
        } catch (e){
            if (e.message === "Failed to fetch") e.message = "Check Internet Connection"
            onError(e)
            return false
        }
    }

}

export const fetchLoadSearchProducts = (
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const link = `${searchServiceUrl}v1/search/load-products`
    return async () => {
        try{
            let json
            const response = await fetch(link)
            if (!response.ok) {
                let errorMessage = "Internal Server Error"
                if (response.status === 404) errorMessage = "Error loading products"
                throw new Error(errorMessage, json);
            }
            const results = await response.json()
            onSuccess()
            return true
        } catch (e){
            if (e.message === "Failed to fetch") e.message = "Check Internet Connection"
            onError(e)
            return false
        }
    }

}

export const fetchTrackQueryString = queryString => {
    return async (dispatch, getState) => {
        const {user} = getState()
        if (
            !queryString.trim() || 
            process.env.NODE_ENV !== 'production' ||
            (user.authenticated && (user.isAdmin || user.isEditor))
        ) {
            dispatch(updateQueryString(queryString))
            return
        }
        try {
            const id = getDateString()
            const firestore = getFirestore(firebaseApp);
            const searchRef = doc(firestore, "search", id)
            const incrementValue = increment(1)
            setDoc(searchRef, {
                [queryString.trim().toLowerCase()]: incrementValue,
                id,
            }, {merge: true})
            dispatch(updateQueryString(queryString))
            return true
        } catch (e) {
            const message = `action > search > fetchTrackQueryString: Failed to track query string ${queryString}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateSearchIndex = (
    id,
    index,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
     const searchIndexRef = doc(firestore, "searchIndexes", id)
    const searchIndex = {id, index}
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            searchIndex.lastEditedBy = user.id
            searchIndex.lastEditedAt = Date.now()
            await setDoc(searchIndexRef, searchIndex)
            onSuccess(searchIndex)
            return true
        } catch (e){
            const message = `action > search >  fetchUpdateSearchIndex: Failed to update search index ${id}`
                if (e.message_){
                    //deal with firebase-specific errors
                    logError(new Error(`${e.message} ${message}`))
                } else {
                    e.message = `${e.message} ${message}`
                    logError(e)
                }
                onError(searchIndex)
                return false
        }
    }

}