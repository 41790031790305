import {firebaseApp} from "../config/firebase"
import { getFirestore, collection, query, getDocs } from "firebase/firestore";

import { logError } from "../utils/errorHandlingUtils"

export const SAVE_PAYMENT_PROVIDERS = 'SAVE_PAYMENT_PROVIDERS'

export const savePaymentProviders = paymentProviders => {
    return {
        type: SAVE_PAYMENT_PROVIDERS,
        payload: {
            paymentProviders
        }
    }
}

export const fetchSavePaymentProviders = () => {
    const firestore = getFirestore(firebaseApp);
    const paymentProvidersRef = query(collection(firestore, "paymentProviders"))
    return async dispatch =>{
        try {
            const querySnapshot = await getDocs(paymentProvidersRef)
            //get an array of payment providers from the snapshot
            const paymentProviders = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(savePaymentProviders(paymentProviders))
            return true
        } catch (e){
            const message = `action > paymentProviders > fetchSavePaymentProviders: Failed to save payment providers`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}