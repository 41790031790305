import React from "react"
import PropTypes from "prop-types"
import styles from "./QuantityDropdown.module.css"

class QuantityDropdown extends React.Component{

    static defaultProps = {
        selected: 1,
        min: 1,
        max: 10,
        onSelect: () => {},
        label: "Quantity",
        showMinOrder:true
    }

    render(){
        const {min, max, selected, units, onSelect, label, showMinOrder} = this.props
        const quantityOptions = []
        for (let i = min; i <= max; i++ ){
            quantityOptions.push(<option key={i} value={i}>{i}</option>)
        }
        return (
            <div className={styles.container}>
                <span>{label}:</span>
                <select value={selected} onChange={e => onSelect(Number(e.target.value))}>
                    {quantityOptions}
                </select>
                {min > 1 && showMinOrder? <div className={styles.min}>({min} {units ? `${units}`: ""} Min. Order)</div> : null}
            </div>
        )
    }
}

QuantityDropdown.propTypes = {
    selected: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onSelect: PropTypes.func
}

export default QuantityDropdown;