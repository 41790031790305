import React from "react"
import ProductTileList from "../../components/ProductTileList"

import {cleanSearchTerms} from "../../utils/searchUtils"
import {MINUTE_IN_MILLISECONDS} from "../../constants/datetime"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"
import PropTypes from "prop-types"

import styles from "./ResultsTileList.module.css"

class ResultsTileList extends React.PureComponent{
    
    componentDidMount(){
        this.findMatchingProducts()
    }
    
    componentDidUpdate(prevProps){
        if (prevProps.queryString !== this.props.queryString){
            this.findMatchingProducts()
        }
    }

    findMatchingProducts = async () => {
        const {queryString, search, actions} = this.props
        const searchTerm = cleanSearchTerms(queryString)
        if (
            search.queryResultsByQueryString[searchTerm] &&
            (Date.now() - search.queryResultsByQueryString[searchTerm].lastQueriedAt) <= MINUTE_IN_MILLISECONDS * 10
        ){
            console.log("skipping ssearch due to recent query")
            return
        }
        console.time("user search time")
        
        actions.toggleLoading(true, "Searching all of Dominica")
        await actions.fetchSearchProducts(queryString)
        actions.toggleLoading(false)
        console.timeEnd("user search time")
    }

    render(){
        const {queryString, search, products, inactiveSellerIds} = this.props
        const queryResults = search.queryResultsByQueryString[cleanSearchTerms(queryString)]
        const matchedProductList = queryResults ? Object.keys(queryResults.resultProductIds)
                                                        .map(productId => products.productsById[productId])
                                                        .filter(product => {
                                                            return Boolean(product) && !inactiveSellerIds[product.createdBySellerId] && !product.isInactive
                                                        })
                                                : []
        return (
            <div className={styles.container}>
                <p className={["title", styles.heading].join(" ")}>Search Results that match '{queryString}' ({matchedProductList.length})</p>
                <ProductTileList productList={matchedProductList} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    search: state.search,
    products: state.products,
    inactiveSellerIds : state.sellers.inactiveSellerIds
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

ResultsTileList.propTypes = {
    queryString: PropTypes.string.isRequired,
    search: PropTypes.shape({
        queryString: PropTypes.string.isRequired
    }).isRequired,
    products: PropTypes.shape({
        productsById: PropTypes.object.isRequired,
        productIds: PropTypes.array.isRequired
    }).isRequired,
}
export default connect(mapStateToProps, mapDispatchToProps)(ResultsTileList);