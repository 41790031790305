import currency from 'currency.js'
import {
    CUSTOMER_SELECTED_PAY_INSTORE, 
    CUSTOMER_CONFIRMED_PAYMENT, 
    SHOPDM_CONFIRMED_PAYMENT,
    SELLER_CONFIRMED_READY,
    SELLER_ITEMS_OUT_FOR_DELIVERY,
    SELLER_ORDER_COMPLETION_VERIFIED,
    THIRD_PARTY_DELIVERY_COMPLETION_VERIFIED,
    THIRD_PARTY_ITEMS_OUT_FOR_DELIVERY
} from "../constants/order"
import {SELF_DELIVERY_ID} from "../constants/delivery"
import {SHOPDM_DELIVERY_ID} from "../constants/shopdmServices"
import {getProductStockPromotion, calculatePromotionalPrice} from "./promotions"
import {calculatePaymentCharge, calculateDeliveryCharge, calculateShopdmFee} from "./paymentUtils"
import { getSellerStartPointToMeasureOrderReponse} from './sellerUtils'
import { getVariantDescription } from './productUtils'
import { capitalizeAllWords } from './stringUtils'
export const getCurrentSellerStatus = (order, sellerId) =>{
    return order.sellerOrderStatus[sellerId] ?
        Object.keys(order.sellerOrderStatus[sellerId]).reduce((currentStatus, status) => {
            if (!currentStatus) return status
            if (order.sellerOrderStatus[sellerId][currentStatus] < order.sellerOrderStatus[sellerId][status]) return status
            else return currentStatus
        }, null)
    :
    null
}

//use placed at date as the point at which the customer left the checkout screen
//this is when they said they paid (via Mobanking) or decided to pay instore
//or when Shopdm confirmed the payment if they paid with debit or credit card or paypal
export const getOrderPlacedAt = o => o.orderStatus[CUSTOMER_SELECTED_PAY_INSTORE] ?
                                    o.orderStatus[CUSTOMER_SELECTED_PAY_INSTORE] :
                                    o.orderStatus[CUSTOMER_CONFIRMED_PAYMENT] ?
                                    o.orderStatus[CUSTOMER_CONFIRMED_PAYMENT] :
                                    o.orderStatus[SHOPDM_CONFIRMED_PAYMENT] ?
                                    o.orderStatus[SHOPDM_CONFIRMED_PAYMENT] :
                                    o.createdAt

//find the time a seller moved to a particular status in an order
export const getSellerOrderStatusAt = (order, sellerId, sellerStatus=SELLER_CONFIRMED_READY) => {
    const {sellerOrderStatus} = order 
    return sellerOrderStatus && sellerOrderStatus[sellerId] &&
        sellerOrderStatus[sellerId][sellerStatus] ?
        sellerOrderStatus[sellerId][sellerStatus]
        :
        0
}

//find the time nearest to the time an order would have been 'posted' on a store's charge account
//for most orders this will be the time when the order response manager changed the order's status to 'READY FOR PICKUP'
//if this does not work, it may be because the store is doing self-delivery, so check for the time the order was set to SELLER_OUT_FOR_DELIVERY
//otherwise, choose the time the order was paid, this is the first time the store could have seen it (via email or the order item list)
//if otherwise return zero
export const getSellerOrderNearestTimeToChargeAccountPostedAt = (order, sellerId) => {
    let processedAt = getSellerOrderStatusAt(order, sellerId, SELLER_CONFIRMED_READY) //for most stores the nearest time will the when the item is set ready for pickup
    if (processedAt > 0) return processedAt
    processedAt = getSellerOrderStatusAt(order, sellerId, SELLER_ITEMS_OUT_FOR_DELIVERY) //perhaps this isa self delivery order, so there is no ready for pickup
    if (processedAt > 0) return processedAt
    processedAt = order.orderStatus[SHOPDM_CONFIRMED_PAYMENT] ? //it was first visible when it was paid
                  order.orderStatus[SHOPDM_CONFIRMED_PAYMENT]
                  :
                  order.orderStatus[CUSTOMER_SELECTED_PAY_INSTORE] ? //perhaps it was a pay-instore order
                  order.orderStatus[CUSTOMER_SELECTED_PAY_INSTORE]
                  :
                  0 //otherwise, the order could not have been processed by the store
    return processedAt
}  

export const getSellerOrderValue = (order, sellerId) => {
    if(! (order.sellerOrderStatus[sellerId] && 
        (order.sellerOrderStatus[sellerId][SELLER_ORDER_COMPLETION_VERIFIED] || 
            order.sellerOrderStatus[sellerId][THIRD_PARTY_ITEMS_OUT_FOR_DELIVERY] ||
            order.sellerOrderStatus[sellerId][THIRD_PARTY_DELIVERY_COMPLETION_VERIFIED]
        )
    )
    ) {
        return 0
    }
    const seller = order.sellersById[sellerId]
    let sum = seller.itemIds.reduce((total, itemId) => {
        const item = order.orderItemsById[itemId]
        //if refunded, short circuit
        if (item.refund) return total
        //the total is the price by the quantity received by the customer
        total = total.add(
                item.actualItemTotalXcd ? 
                 item.actualItemTotalXcd : 
                 Number(
                     item.price * 
                     (item.shopdmReplaceQuantity ? //if there is a replace quantity, remove it from the original quantity
                        item.quantity - item.shopdmReplaceQuantity 
                        : item.quantity
                     )
                )
        )
        return total
    }, currency(0))
    if (seller.isSelfDelivery) sum = sum.add(seller.selfDeliveryFee)
    return sum.value
}

export const calculateSellerOrderPreparationTime = (order, sellerId) => {
    const sellerAtOrderTime = order.sellersById[sellerId]
    if (!sellerAtOrderTime || !order.sellerOrderStatus[sellerId]) return 0
    //take the start time as the point when Shopdm reported that the item had been paid for
    let startAt = order.orderStatus[SHOPDM_CONFIRMED_PAYMENT] ? 
                      order.orderStatus[SHOPDM_CONFIRMED_PAYMENT]
                      :
                      //but if Shopdm has not done this, then take the pay instore time
                      order.orderStatus[CUSTOMER_SELECTED_PAY_INSTORE] ?
                      order.orderStatus[CUSTOMER_SELECTED_PAY_INSTORE]
                      :
                      0
    //if the start time is still unset, return zero
    if (!startAt) return 0
    //take the time that the store set it ready for pickup as the end time
    const endAt =  order.sellerOrderStatus[sellerId][SELLER_CONFIRMED_READY] ? 
                     order.sellerOrderStatus[sellerId][SELLER_CONFIRMED_READY]
                     //if not, assume self-delivery and take the time that the store started the delivery 
                     :
                     order.sellerOrderStatus[sellerId][SELLER_ITEMS_OUT_FOR_DELIVERY] ? 
                     order.sellerOrderStatus[sellerId][SELLER_ITEMS_OUT_FOR_DELIVERY]
                     :
                     0
    if (!endAt) {
        return 0
    }
    //choose a point to start the calculations from
    //consider opening & closing times, holidays and weekends
    const sellerAddress = sellerAtOrderTime.addressesById[sellerAtOrderTime.defaultAddressId]
    let startPrepAt = getSellerStartPointToMeasureOrderReponse(startAt, sellerAtOrderTime, sellerAddress.id)
    if (endAt < startPrepAt) startPrepAt = startAt
    //return the difference between the paid at time and the ready time
    return endAt - startPrepAt
}
export const calculateOrderTotals = (
    cart, 
    user, 
    sellers, 
    deliveryProviders,
    paymentProviders,
    promotions,
    wallet
) => {
    let subTotal = currency(0) 
    let discountedSubTotal = currency(0)
    let appliedPromotions = {}
    cart.itemIds.forEach(itemId => {
        const item = cart.itemsByProductStockId[itemId]
        const itemTotal = currency(item.price).multiply(item.quantity)
        subTotal = subTotal.add(itemTotal)
        const promotion = getProductStockPromotion(
            item.id,
            item.productId, 
            item.sellerId, 
            user.activeSellerAccountId, 
            promotions
        )
        if (promotion){
            //add the discounted price instead of the total price 
            discountedSubTotal = discountedSubTotal.add(
                currency(calculatePromotionalPrice(item.price, promotion)).multiply(item.quantity)
            )
            //log the promotions applied
            appliedPromotions[item.id] = promotion.id
        } else discountedSubTotal = discountedSubTotal.add(itemTotal)
        
    })
    subTotal = subTotal.value
    discountedSubTotal = discountedSubTotal.value
    const deliveryProviderMap = {}
    const shopdmDeliveryMap = {}
    Object.keys(cart.selectedDeliveryProviderIdsBySellerId).forEach(sellerId => {
        const deliveryProviderId = cart.selectedDeliveryProviderIdsBySellerId[sellerId]
        if (! deliveryProviderId) return
        const seller = sellers.sellersById[sellerId]
        const deliveryProvider = deliveryProviders.deliveryProvidersById[deliveryProviderId]
        if (! deliveryProvider) return
        deliveryProviderMap[sellerId] = deliveryProvider.id === SELF_DELIVERY_ID ?
                                                    {...deliveryProvider, totalFee: seller.selfDelivery.fixedFee}
                                                    :
                                                    deliveryProvider
        if (deliveryProviderId === SHOPDM_DELIVERY_ID) shopdmDeliveryMap[sellerId] = deliveryProvider
    })
    const userAddress = user.addressesById[user.defaultAddressId]
    const deliveryFee = calculateDeliveryCharge(deliveryProviderMap, userAddress)
    const giftFee = cart.giftParameters && cart.giftParameters.addOns && cart.giftParameters.addOns.giftWrapping? cart.giftParameters.addOns.giftWrapping : 0

    let providerFees = currency(discountedSubTotal).add(deliveryFee).add(giftFee).value

    let shopDmFee = calculateShopdmFee(discountedSubTotal) 
    
    const paymentProvider = cart.selectedPaymentProviderId ? 
                        paymentProviders.paymentProvidersById[cart.selectedPaymentProviderId]
                        :
                        null
    let paymentCharge = paymentProvider ? 
                            calculatePaymentCharge((currency(providerFees).add(shopDmFee).value), paymentProvider )
                            :
                            0 
    const discount = (subTotal - discountedSubTotal) >= 0 ? (subTotal - discountedSubTotal) : 0
    let shopdmCreditUsed = 0 //used to reduce the buyers total, based on their wallet balance
    let shopdmCreditOwed = 0 //used to collect on underpayments
    let totalIfCreditUsed = currency(providerFees).add(shopDmFee).add(paymentCharge).value
    if (wallet){
        //if the user has a wallet
        if (wallet.balance < 0){
            //if the balance is negative take the value of the amount owed
            shopdmCreditOwed = wallet.balance * -1
            shopDmFee = calculateShopdmFee(currency(discountedSubTotal).add(shopdmCreditOwed).value) 
            paymentCharge = paymentProvider ? 
                            calculatePaymentCharge(
                                currency(providerFees).add(shopDmFee).add(shopdmCreditOwed).value, 
                                paymentProvider 
                            )
                            :
                            0 
            totalIfCreditUsed = currency(providerFees).add(shopDmFee).add(paymentCharge).add(shopdmCreditOwed).value
        } else if (wallet.balance >= providerFees){
            //if the wallet has enough credit to cover the whole purchase
            totalIfCreditUsed = providerFees
            if (cart.payWithShopdmCredit){
                //set the appleid credit to the provider fee total 
                shopdmCreditUsed = providerFees
                shopDmFee = 0
                paymentCharge = 0
            }
        } else if (
            wallet.balance > 0 && 
            wallet.balance < providerFees 
        ){
            //if the wallet has a positive balance but not enough to cover the whole purchase
            let creditAdjustedDiscountedSubTotal 
            let remainingWalletBalance = wallet.balance
            if (remainingWalletBalance >= discountedSubTotal){
                creditAdjustedDiscountedSubTotal = 0
                remainingWalletBalance = currency(remainingWalletBalance).subtract(discountedSubTotal).value
            } else {
                creditAdjustedDiscountedSubTotal = currency(discountedSubTotal).subtract(remainingWalletBalance).value
                remainingWalletBalance = 0
            }
            let creditAdjustedDeliveryFee = deliveryFee
            if (remainingWalletBalance >= deliveryFee){
                creditAdjustedDeliveryFee = 0
                remainingWalletBalance = currency(remainingWalletBalance).subtract(deliveryFee).value
            } else {
                creditAdjustedDeliveryFee = currency(deliveryFee).subtract(remainingWalletBalance).value
                remainingWalletBalance = 0
            }
            //the remaining wallet balance cannot be more than the gifting fee
            //else it would have gone into the previous if block
            let creditAdjustedGiftingFee = currency(giftFee).subtract(remainingWalletBalance).value
            remainingWalletBalance = 0
            const creditAdjustedProviderFees = currency(creditAdjustedDiscountedSubTotal).add(creditAdjustedDeliveryFee).add(creditAdjustedGiftingFee).value
            const creditAdjustedShopdmFee = calculateShopdmFee(creditAdjustedDiscountedSubTotal)
            const creditAdjustedPaymentCharge = paymentProvider ? 
                            calculatePaymentCharge(
                                currency(creditAdjustedProviderFees).add(creditAdjustedShopdmFee).value, 
                                paymentProvider 
                            )
                            :
                            0
            totalIfCreditUsed = currency(providerFees).add(creditAdjustedShopdmFee).add(creditAdjustedPaymentCharge).value 
            if (cart.payWithShopdmCredit){
                shopdmCreditUsed = wallet.balance
                shopDmFee = creditAdjustedShopdmFee
                paymentCharge = creditAdjustedPaymentCharge
            }
        } 
    }
    const shopdmCommissionEarningsXcd = shopDmFee
    //calculate the shopdm delivery earnings using the deliveries done by Shopdm's delivery services
    const shopdmDeliveryEarningsXcd = calculateDeliveryCharge(shopdmDeliveryMap, userAddress)
    const shopdmGiftingEarningsXcd = giftFee
    const shopdmTotalEarningsXcd = currency(shopdmCommissionEarningsXcd).add(shopdmDeliveryEarningsXcd).add(shopdmGiftingEarningsXcd).value
    const total = currency(providerFees).add(shopDmFee).add(paymentCharge).add(shopdmCreditOwed).value
    return  {
        subTotal,
        discountedSubTotal,
        discount,
        deliveryFee,
        giftFee,
        processingFee: currency(shopDmFee).add(paymentCharge).value,
        providerFees, //discountedsubtotal + gifting fee + delivery fees
        paymentProcessorFee: paymentCharge,
        total,
        paymentProcessorTotal: currency(total).subtract(shopdmCreditUsed).value,
        totalIfCreditUsed,
        shopdmCommissionEarningsXcd,
        shopdmDeliveryEarningsXcd,
        shopdmGiftingEarningsXcd,
        shopdmTotalEarningsXcd,
        appliedPromotions,
        shopdmCreditUsed,
        shopdmCreditOwed,
    }
}

export const orderItemsToString = (orderItems=[]) => {
    return orderItems.reduce((string, item, i) => {
        const {quantity, titleAndBrand, skuNumber, price} = item 
        const itemString = `${quantity} x ${titleAndBrand}${skuNumber ? ` SKU:${skuNumber}`: ""} ($${(price * quantity).toFixed(2)}${quantity > 1 ? `/${price.toFixed(2)} each` : ""}) ${getVariantDescription(item, true, true, "|")}`
        string = i > 0 ? `${string}\n${itemString}` : itemString
        return string
    }, "")
} 

export const deliverySlotToString = (deliverySlotId, order) => {
    const {orderNumber, customerName, customerPhone, customerAddress, giftParameters, sellerPackageInfo={}} = order
    let recipientName = customerName
    let recipientPhone = customerPhone
    if (giftParameters) {
        recipientName = giftParameters.recipientName
    }
    const deliverySlot = order.deliverySlotsById[deliverySlotId]
    const pickupString = Object.keys(deliverySlot.sellerIds)
                               .reduce((string, sellerId, i) => {
                                    const seller = order.sellersById[sellerId]
                                    const sellerName = seller.id === "iS0xIq1WNzPoLQ47FLtB" ? "Market" : capitalizeAllWords(seller.name)
                                    const packageInfo = sellerPackageInfo[seller.id] ? sellerPackageInfo[seller.id] : {}
                                    const {numOfPieces=0, numOfFrozenPieces=0, numOfRefrigeratedPieces=0} = packageInfo 
                                    const packageString = sellerPackageInfo[seller.id] ? 
                                                        `${numOfPieces} piece(s) ${numOfRefrigeratedPieces > 0 ? `R: (${numOfRefrigeratedPieces} in fridge)` : ""} ${numOfFrozenPieces > 0 ? `F: (${numOfFrozenPieces} in Freezer)` : ""}`
                                                        : ""
                                    string = i === 0 ?
                                            `${sellerName}${packageString ? ` - ${packageString}` : ""}` 
                                            :
                                            `${string}\n${sellerName}${packageString ? ` - ${packageString}` : ""}`
                                    return string
                               }, "")
    let string = `${orderNumber} Delivery Request\n\nTo: ${recipientName} ${recipientPhone}\nAt: ${customerAddress}\n\nPickups:\n${pickupString}`
    return string 
} 