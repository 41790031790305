import React from "react"
import styles from "./Banner.module.css"

const Banner = ({}) => {
    return (
        <div className={styles.container}>
            <iframe className={styles.iframe} 
            src="https://www.youtube.com/embed/fEUXOd3z_dg?playlist=fEUXOd3z_dg&loop=1&controls=0&autoplay=1&mute=1">
            </iframe>
            <div className={styles.content}>
                <div className={styles.title}>WhoOosh Virtual Art Exhibition</div>
                <a 
                    className={styles.button}
                    href="https://kubuliartsexhibition.com/tours/whooosh13/index.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Visit Interactive Art Gallery On Kubuliarts.com
                </a>
            </div>
        </div>
    )
}

export default Banner