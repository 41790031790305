import React, {useState, useRef} from "react"
import {FacebookProvider, Share} from 'react-facebook'
import Icon from "../Icon" 
import Modal from "../Modal"

import {APP_ID} from "../../config/facebook"
import {copyToClipboard} from "../../utils/interactionUtils"
import styles from "./ShareButton.module.css"
import {logAnalyticsEvent} from "../../config/firebase"

class ShareButton extends React.Component{

    state = {
        shareModalOpen: false,
    }

    static defaultProps = {
        title: "Shopdm"
    }

    facebook = null
    nativeShare = async () => {
        try {
            const {text} = this.props
            await navigator.share({
                title: "Shopdm",
                text,
                url: window.location.href
            })
        } catch (err) {
            console.error("Native share failed: ", err.message)
        } 
    }

    handleClick = () => {
        if (window.innerWidth > 700){
            this.handleOpenShareModal()
        } else if (navigator.share) {
            //if it is a phone, use the native share
            this.nativeShare()    
            logAnalyticsEvent("share_mobile")
        }
    }

    handleOpenShareModal = () => this.setState({shareModalOpen: true})
    handleCloseShareModal = () => this.setState({shareModalOpen: false})

    render (){
        const {text} = this.props
        const {shareModalOpen} = this.state
        return (
            <React.Fragment>
                <button className="button white icon" onClick={this.handleClick}>
                    <Icon icon="share"/>
                    Share
                </button>
                {
                    shareModalOpen ?
                        <ShareModal 
                            isOpen={shareModalOpen}
                            closeModal={this.handleCloseShareModal}
                        />
                    :
                    null
                }
            </React.Fragment>
        )
    }
}

const ShareModal = ({
    isOpen=false,
    closeModal=()=>{}
}) => {
    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            closeOnOverlayClick={true}
            className={styles.modal}
            overlayClassName={styles.modalOverlay}
        >
            <div className="strong">Share</div>
            <div className={styles.shareOptionList}>
                <FacebookProvider appId={APP_ID}>
                    <Share href={window.location.href}>
                    {
                        ({ handleClick, loading }) => <div 
                                                        className={`${styles.shareOption} facebookIcon`} 
                                                        onClick={ loading ? () => {} : handleClick}
                                                        > 
                                                            <span onClick={()=>logAnalyticsEvent("share_web_facebook")}>
                                                                <Icon icon="facebook" />
                                                            </span> 
                                                            <span onClick={()=>logAnalyticsEvent("share_web_facebook")}>
                                                                Facebook
                                                            </span>
                                                    </div>
                    }
                    </Share>
                </FacebookProvider>
                <WhatsappButton closeModal={closeModal}/>
                <CopyButton closeModal={closeModal} />
            </div>
            
        </Modal>
    )
}

const CopyButton = ({closeModal=()=>{}}) => {
    const [copied, setCopied] = useState(false)
    const handleClick = () => {
        copyToClipboard(window.location.href)
        setCopied(true)
        logAnalyticsEvent("share_web_copy_link")
        setTimeout(closeModal, 1000)
    }
    return (
        <div className={styles.shareOption} onClick={handleClick}>
            <Icon icon={ copied ? "check" : "link" }/>
            { copied ? "Copied!" : "Copy link" }
        </div>
    )
}

const WhatsappButton = ({closeModal=()=>{}}) => {
    const handleClick = () => {
        window.open(`https://web.whatsapp.com/send?text=${window.location.href}`)
        logAnalyticsEvent("share_web_whatsapp")
        closeModal()
    }
    return (
        <div className={`${styles.shareOption} whatsappIcon`} onClick={handleClick}>
            <Icon icon="whatsapp"/>
            WhatsApp
        </div>
    )
}
export default ShareButton