import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, setDoc, query, collection, where, onSnapshot, writeBatch, updateDoc} from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"
import { baseUrl } from "../config/firebase"
import { DAY_IN_MILLISECONDS } from "../constants/datetime";
import { PAYMENT_OBJECT_TYPE_TOPUP, PAYMENT_OBJECT_TYPE_ORDER} from "../constants/payment"

export const SAVE_PAYMENTS = 'SAVE_PAYMENTS'
export const EDIT_PAYMENT = 'EDIT_PAYMENT'

export const savePayments = payments => {
    return {
        type: SAVE_PAYMENTS,
        payload: {
            payments
        }
    }
}

export const editPayment = (paymentId, edits) => {
    return {
        type: EDIT_PAYMENT,
        payload: {
            paymentId,
            edits
        }
    }
}

export const fetchTrackPayment = (
    id,
    amountInXcd,
    amountInCurrency,
    currencyCode,
    objectId,
    objectType,
    reference,
    paymentProviderId,
    paymentProviderName,
    paymentProviderReference,
    userId,
    paidAt,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp);
    const paymentsRef = doc(firestore, "payments", id)
    const payment = {
        id,
        amountInXcd,
        amountInCurrency,
        currencyCode,
        objectId,
        objectType,
        reference,
        paymentProviderId,
        paymentProviderName,
        paymentProviderReference,
        userId,
        paidAt: paidAt ? paidAt : Date.now(),
        createdAt: Date.now()
    }
    return async (dispatch) => {
        try{
            await setDoc(paymentsRef, payment)
            onSuccess(payment)
            return true
        } catch (e){
            const message = `action > payments > fetchTrackPayment: Failed to track payment ${JSON.stringify(payment)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(payment)
            return false
        }
    }
}

export const fetchTrackPaypalPayment = (
    id,
    amountInXcd,
    amountInCurrency,
    currencyCode,
    objectId,
    objectType,
    reference,
    paymentProviderId,
    paymentProviderName,
    paymentProviderReference,
    userId,
    deviceId="",
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const payment = {
        id,
        amountInXcd,
        amountInCurrency,
        currencyCode,
        objectId,
        objectType,
        reference,
        paymentProviderId,
        paymentProviderName,
        paymentProviderReference,
        userId,
        deviceId,
        createdAt: Date.now()
    }
    const link = `${baseUrl}v1/payments/paypal`
    return async (dispatch) => {
        try{
            let json
            const response = await fetch(link, {
                "method": 'POST',
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                "body": JSON.stringify(payment) 
            })
            if (!response.ok) {
                let errorMessage = "Internal Server Error"
                if (response.status === 404) errorMessage = "Payment could not be created"
                throw new Error(errorMessage, json);
            }
            json = await response.json()
            // dispatch(trackPayment(payment))
            onSuccess(payment)
            return true
        } catch (e){
            const message = `action > payments > fetchTrackPaypalPayment: Failed to track payment ${JSON.stringify(payment)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(payment)
            return false
        }
    }
}

export const fetchSaveAdminPaymentsInDateRange = (
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all payments placed in the last 30 days
    toDate=Date.now()
) => {
    /**
      * Purpose: retrieve the payments from the firestore database created between the specified date range
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const paymentsRef = query(collection(firestore, "payments"), 
                            where("createdAt", ">=",  fromDate),
                            where("createdAt", "<=", toDate))                          
    return async dispatch => {
        try {
            const paymentsListener = await onSnapshot(paymentsRef,
                querySnapshot => {
                    //get an array of payments from the snapshot
                    const payments = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayments(payments))
                } 
            )
            return paymentsListener
        } catch (e){
            const message = `action > payments > fetchSaveAdminPaymentsInDateRange: Failed to save payments from ${fromDate} to ${toDate}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateBulkPaymentsToHavePaymentProcessorFees = () => {
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) => {
        try {
            const {payments, orders, topups} = getState()
            //loop through batches to avoid hitting the 500 write firebase limit
            const paymentsWithoutProcessingFees = Object.values(payments.paymentsById).filter(p => !p.paymentProcessorFeeXcd)
            const batchArray = [writeBatch(firestore)]
            const batchLimit = 400 //after this many operations on the batch, a new batch will be added and used
            let batchIndex = 0
            let operationCount = 0
            for(let i = 0; i < paymentsWithoutProcessingFees.length; i++){
                const batch = batchArray[batchIndex]
                const p = {...paymentsWithoutProcessingFees[i]}
                if (!p.objectId){
                    if (!p.orderId) {
                        console.warn("Error with ", p)
                        throw new Error(`Payment ${p.id} has no order id and no object id`)
                    }
                    p.objectId = p.orderId
                    p.objectType = PAYMENT_OBJECT_TYPE_ORDER
                }
                if (!p.paymentProviderReference && p.orderNumber){
                    p.paymentProviderReference = p.reference
                    p.reference = p.orderNumber
                }
                const object = p.objectType ===  PAYMENT_OBJECT_TYPE_TOPUP ?
                               topups.topupsById[p.objectId]
                               :
                               p.objectType ===  PAYMENT_OBJECT_TYPE_ORDER ?
                               orders.ordersById[p.objectId]
                               :
                               null
                if (!object){ 
                    console.warn("Warning for ", p)
                    console.warn(`Object is still falsy`)
                } else {
                    p.paymentProcessorFeeXcd = object.paymentProcessorFeeXcd

                    if (p.paymentProcessorFeeXcd < 0) {
                        throw new Error("payment processor fee is negative!", p, object)
                    } else if (Number(p.paymentProcessorFeeXcd) === 0){
                        console.warn("payment processor fee is zero", p ,object)
                    }
            
                    const paymentsRef = doc(firestore, "payments", p.id)
                    batch.update(paymentsRef, {
                        paymentProcessorFeeXcd: p.paymentProcessorFeeXcd,
                        objectId: p.objectId,
                        objectType: p.objectType,
                        paymentProviderReference: p.paymentProviderReference,
                        reference: p.reference
                    })
                    operationCount++
                    if (operationCount >= batchLimit) {
                        batchArray.push(writeBatch(firestore));
                        batchIndex++;
                        operationCount = 0;
                    }
                }

              
            }
            //save all payments in the different batches
            let savedPaymentsCount = 0
            console.log("about to save payments ", paymentsWithoutProcessingFees)
            for (let i in batchArray) {
                const batch = batchArray[i]
                savedPaymentsCount += batch._mutations ? batch._mutations.length : batchLimit
                await batch.commit()
                console.log(`Saved ${savedPaymentsCount} out of ${paymentsWithoutProcessingFees.length} payments`)
            } 
            return true
        } catch (e){
            const message = `action > payments >  fetchUpdateBulkPaymentsToHavePaymentProcessorFees: Failed to update bulk payments to have payment processor fees`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateAdminPaymentIsInvalid = (
    id,
    isInvalid, 
    onSuccess=()=>{}, 
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const paymentsRef = doc(firestore, "payments", id)
    const paymentEdit = {
        isInvalid,
        lastEditedAt: Date.now()
    }
    return async (dispatch, getState) => {
        try{
            const {payments, user} = getState()
            paymentEdit.lastEditedByUserId = user.id
            const payment = payments.paymentsById[id]
            //if the payment was verified, but is now being set to invalid
            //then remove its verification
            if (isInvalid && payment.isVerified){
                paymentEdit.isVerified = false
            }
            await updateDoc(paymentsRef, paymentEdit)
            dispatch(editPayment(id, paymentEdit))
            onSuccess(paymentEdit)
            return true
        } catch (e){
            const message = `action > payments > fetchUpdateAdminPaymentIsInvalid: Failed to update payment ${id} with values ${JSON.stringify(paymentEdit)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(paymentEdit)
            return false
        }
    }
}

export const fetchUpdateAdminPaymentIsVerified = (
    id,
    isVerified, 
    onSuccess=()=>{}, 
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const paymentsRef = doc(firestore, "payments", id)
    const paymentEdit = {
        isVerified,
        lastEditedAt: Date.now()
    }
    return async (dispatch, getState) => {
        try{
            const {payments, user} = getState()
            paymentEdit.lastEditedByUserId = user.id
            const payment = payments.paymentsById[id]
            //if the payment was invalid, but is now being verified
            //then remove its invalidation
            if (isVerified && payment.isInvalid){
                paymentEdit.isInvalid = false
            }
            await updateDoc(paymentsRef, paymentEdit)
            dispatch(editPayment(id, paymentEdit))
            onSuccess(paymentEdit)
            return true
        } catch (e){
            const message = `action > payments > fetchUpdateAdminPaymentIsVerified: Failed to update payment ${id} with values ${JSON.stringify(paymentEdit)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(paymentEdit)
            return false
        }
    }
}