import { query, collection, where, getDocs, onSnapshot } from "firebase/firestore";

export const getFirestoreObjectsByIdList = async (firestore, ids=[], collectionName="") => {
    let queries = [];
    for(let i = 0; i < ids.length; i += 10) {
    queries.push(query(
        collection(firestore, collectionName),
        where("id", "in", ids.slice(i, i + 10)),
    ));
    }
    let docSnapshots = [];
    for(let i = 0; i < queries.length; i++) {
        docSnapshots.push(getDocs(queries[i]));
    }
    docSnapshots = await Promise.all(docSnapshots);
 
    const docs = [...new Set([].concat(...docSnapshots.map(
            (snapshot) => snapshot.docs.map(docRef => ({...docRef.data()})))))];
    return docs
}

export const listenToFirestoreObjectsByIdList = async (firestore, ids=[], collectionName="", onUpdate=()=>{}) => {
    let queries = [];
    for(let i = 0; i < ids.length; i += 10) {
    queries.push(query(
        collection(firestore, collectionName),
        where("id", "in", ids.slice(i, i + 10)),
    ));
    }
    let listeners = [];
    for(let i = 0; i < queries.length; i++) {
        listeners.push(onSnapshot(queries[i], onUpdate));
    }
    listeners = await Promise.all(listeners);
    return listeners
}