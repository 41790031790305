/**
 *  Purpose: the modifications that are carried on the Video objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {OBJECT_TYPE_VIDEO} from "../constants/objects"
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // videos:{
 //   videosById:{},
 //   videoIds: [],
 // }
 
 const videosReducer = (state = initialState.videos, action) => {
     const {type, payload} = action;
     let videosById = {...state.videosById}
     switch (type){
         case types.SAVE_SELLER_VIDEOS: {
             if(typeof payload.videos !== "object") {
                 return state
             }
             payload.videos.forEach(video => {   
                videosById[video.id] = {
                    ...videosById[video.id],
                    ...video
                }    
            })
            return {
                ...state,
                videosById,
                videoIds: Object.keys(videosById)
            }
         }

        case types.SAVE_SELLERS: {
            if(typeof payload.sellers !== "object") {
                return state
            }
            payload.sellers.forEach(seller => {
                if (seller.displayVideo){
                    videosById[seller.displayVideo.id] = {
                        ...videosById[seller.displayVideo.id],
                        ...seller.displayVideo
                    }
                } 
            })
            return {
                ...state,
                videosById,
                videoIds: Object.keys(videosById)
            }
        }

        case types.DELETE_SELLER_VIDEO: {
            if (typeof payload.videoId !== "string"){
                return state
            }

            delete videosById[payload.videoId]
            return {
                ...state,
                videosById,
                videoIds: Object.keys(videosById)
            }
        }
        
        case types.CREATE_REACTION: {
            if(typeof payload.reaction !== "object") {
                return state
            }
            if(typeof payload.reactionCount !== "number") {
                return state
            }
            const reaction = payload.reaction
            //if the object being un-reacted to is not a video then exit
            if (reaction.objectType !== OBJECT_TYPE_VIDEO) {
                return state
            }
            if (
                videosById[reaction.objectId] &&
                videosById[reaction.objectId].reactions
            ) {
                videosById[reaction.objectId] = {
                    ...videosById[reaction.objectId],
                    reactions: {...videosById[reaction.objectId].reactions}
                }
                videosById[reaction.objectId].reactions[reaction.reactionType] = payload.reactionCount
            }
            return {
                ...state,
                videosById,
                videoIds: Object.keys(videosById)
            }
        }

        case types.DELETE_REACTION: {
            if(typeof payload.reactionType !== "string") {
                return state
            }
            if(typeof payload.objectId !== "string") {
                return state
            }
            if(typeof payload.objectType !== "string") {
                return state
            }
            if(typeof payload.reactionCount !== "number") {
                return state
            }
            //if the object being un-reacted to is not a video then exit
            if (payload.objectType !== OBJECT_TYPE_VIDEO) {
                return state
            }
            if (
                videosById[payload.objectId] &&
                videosById[payload.objectId].reactions
            ) {
                videosById[payload.objectId] = {
                    ...videosById[payload.objectId],
                    reactions: {...videosById[payload.objectId].reactions}
                }
 
                videosById[payload.objectId].reactions[payload.reactionType] = payload.reactionCount
            }
            
    
            return {
                ...state,
                videosById,
                videoIds: Object.keys(videosById)
            }
        }
        default: return state
     }
 }
 
 export default videosReducer