import React from 'react'
import {dateStringToTimestamp, timestampToDateString} from "../../utils/datetimeUtils"
import styles from "./DateRangePicker.module.css"
import {DAY_IN_MILLISECONDS} from "../../constants/datetime"

const DateRangePicker = ({dateRange={fromDate: 0, toDate: Date.now()}, onChange=()=>{}}) => {
    const {fromDate, toDate} = dateRange 
    const handleDateChange = (e, key) => {
        const range = {fromDate, toDate}
        //if the new toDate is today, take the current time, not the beginning of the day
        let value = dateStringToTimestamp(e.target.value)
        if (key === "toDate"){
            value += (DAY_IN_MILLISECONDS - 1)
        }
        range[key] = value
        onChange(range)
    }
    return (
        <div className={styles.container}>
            <div className={styles.selectContainer}>
                <div className={styles.label}>From</div>
                <input 
                    type="date" 
                    onChange={e => handleDateChange(e, "fromDate")} 
                    value={timestampToDateString(fromDate)}
                    max={timestampToDateString(toDate)}
                />
            </div>
            <div className={styles.selectContainer}>
                <div className={styles.label}>To</div>
                <input 
                    type="date" 
                    onChange={e => handleDateChange(e, "toDate")} 
                    value={timestampToDateString(toDate)}
                    min={timestampToDateString(fromDate)}
                />
            </div>
        </div>
    )
}

export default DateRangePicker