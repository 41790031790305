/**
 *  Purpose: the modifications that are carried on the Fee objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {STATISTICS_IDENTIFIER} from "../constants/analysis"
// STATE
// fees:{
//   feesById:{},
//   feeIds: []
// }


const feesReducer = (state = initialState.fees, action) => {
    const {type, payload} = action;
    let feesById = {...state.feesById}
    let stats = {...state.stats}

    switch (type){
        case types.SAVE_FEES: {
            if(typeof payload.fees !== "object") {
                logError(`feesReducer > SAVE_FEES: fees payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.fees.forEach(fee => {
                if (fee.id !== STATISTICS_IDENTIFIER){
                    feesById[fee.id] = fee
                } else stats = fee
            });
            return {
                ...state,
                feesById,
                stats,
                feeIds: Object.keys(feesById)
            }
        }

        case types.CREATE_DISBURSEMENTS: {
            if(typeof payload.disbursements !== "object") {
                return state
            }
            payload.disbursements.forEach(disbursement => {
                disbursement.feeIds.forEach(feeId => {
                    if (!feesById[feeId]) return
                    feesById[feeId] = {
                        ...feesById[feeId],
                        paid: true,
                        disbursementId: disbursement.id,
                        paidAt: disbursement.createdAt
                    }
                })
            })
            
            return {
                ...state,
                feesById
            }
        }

        case types.LOGOUT: {
            return initialState.fees
        }

        default: return state
    }
}

export default feesReducer