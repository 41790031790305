import React from "react"
import styles from "./RadioButton.module.css"

function RadioButton({children, checked, editable=true, isActive=true, onChange=()=>{}}){
    const editableStyle = editable ? styles.editable : null
    const activeStyle = isActive ? null : styles.inactive
    return(
        <label className={[styles.container, editableStyle, activeStyle].join(" ")}>
            
            <input 
                type="radio" 
                onChange={() => {if (editable && isActive) onChange()}}
                checked={checked}
            />
            <span className={styles.checkmarkContainer}>
            <span className={styles.checkmark}></span>
            </span>
            {children}
        </label>
    )
}

export default RadioButton;