import React from "react"

import {
    Redirect
  } from "react-router-dom";
import SearchBar from "../../containers/SearchBar"
import Footer from "../../containers/Footer"
import ProductCloseup from "../../containers/ProductCloseup"
import MobileNavigationBar from "../../containers/MobileNavigationBar"

import BackLink from "../../components/BackLink"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {withRouter} from "react-router"
import {Helmet} from "react-helmet";

import styles from "./ProductDetails.module.css"
import PropTypes from "prop-types"

class ProductDetails extends React.Component{
    
    constructor(props){
        super(props)
        const {location} = this.props
        const query = new URLSearchParams(location.search)
        this.state = {
            productId: query.get("pid"),
            defaultProductStockId: query.get("psid"),
            productListener: () => {},
            loaded: false
        }
    }

    componentDidMount = async () => {
        const {actions, products} = this.props
        const {productId} = this.state
        if(productId){
            let product = products.productsById[productId] 
            if (!product){
                actions.toggleLoading(true)
                await actions.fetchSaveProduct(productId)
                actions.toggleLoading(false)
            }
            this.setState({loaded: true})
            if (!product) return
            const productListener = await actions.fetchSubscribeToProduct(productId)
            this.setState({productListener})
        }
    }

    componentWillUnmount(){
        this.state.productListener()
    }

    goBack = () => {
        const {history, products} = this.props
        const {location} = history
        //if the location state is set and
        //the last route was sellers, categories, results or home
        //then go back
        //otherwise, push
        if (
            location.state && 
            location.state.from &&
            (
                location.state.from==="/" || 
                location.state.from.includes("/sellers/") ||
                location.state.from.includes("/results") ||
                location.state.from.includes("/categories/")
            )) history.goBack()
        else {
            const {productId} = this.state
            const product = products.productsById[productId]
            history.push(`/sellers/${product.createdBySellerId}`)
        }
    }
    render(){
        const {productId, defaultProductStockId, loaded} = this.state
        if (!productId){
            return <Redirect to="/"/>
        }
        
        return (
            <div className={[styles.container, "screen"].join(" ")}>
                <Helmet>
                    <title>Product Details</title>
                </Helmet>
                <SearchBar/>
                <div className={["mainContent", "searchBarPresent", styles.mainContent, "showcase"].join(" ")}>
                    <div className={styles.linkContainer}>
                        <BackLink text="Back to Products" onClick={this.goBack}/>
                    </div>
                    <div>
                        <ProductCloseup 
                            productId={productId}
                            defaultProductStockId={defaultProductStockId}
                            handleGoBack={this.goBack}
                            loaded={loaded}
                        />
                    </div>
                    <MobileNavigationBar />
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    products: state.products
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

ProductDetails.propTypes = {
    actions: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductDetails));