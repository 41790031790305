import React from "react"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions";
import { DOMINICA_TIMEZONE } from "../../constants/datetime";

import {
    DELIVERY_PROVIDER_TYPE_AGENT_BASED,
    DELIVERY_PROVIDER_TYPE_OPENING_HOURS,
    DELIVERY_PROVIDER_TYPE_PICK_UP,
    DELIVERY_PROVIDER_TYPE_MERCHANT_DELIVERY,
} from "../../constants/delivery"

import { DELIVERY_SLOTS_IDENTIFIER } from "../../constants/platformSettings"

import {
    getSellerDeliverySelectionByDeliveryProviderId,
    getWeeklyAvailableSlotsWithinOpeningHours, 
    getWeeklyAvailableSlotsFromDeliveryAgentBasedDeliveryProvider,
    getListOfAvailableDeliverySlotsFromWeeklyAvailableSlotMap
} from "../../utils/deliveryUtils"
import { objectsAreEqual } from "../../utils/generalUtils";


class DeliverySlotAvailabilityManager extends React.Component {
    /**
     * Purpose:
     * 1. determine which delivery slots are available for a given seller delivery selection 
     * 2. deselect any slots which ends before the estimated fulfilled at
     * 3. automatically select the first available slot, if none is selected 
     */

    componentDidMount = async () => {
        const {actions} = this.props
        //load the all delivery slots on mount
        await actions.fetchSavePlatformSetting(DELIVERY_SLOTS_IDENTIFIER)
    }

    componentDidUpdate = async (prevProps) => {
        const {cart, user, deliveryTrips, deliverySlots} = this.props


        const userAddress = user.addressesById[user.defaultAddressId]
        const prevUserAddress = prevProps.user.addressesById[prevProps.user.defaultAddressId]
        //determine whether or recalculate available delivery slots
        //TODO consider rare case where opening hours on deliveryProvider or store would
        if(
            //if the supported slots change
            !objectsAreEqual(
                prevProps.deliverySlots.supportedDeliverySlotsById,
                deliverySlots.supportedDeliverySlotsById
            ) ||
            //if the area that the order items are going to changes or
            userAddress.settlementId !== prevUserAddress.settlementId
            ||
            //if the assignment of delivery providers to sellers has changed or
            !objectsAreEqual(
                cart.selectedDeliveryProviderIdsBySellerId, 
                prevProps.cart.selectedDeliveryProviderIdsBySellerId
            ) ||
            //if the delivery trips available for the specified area have changed 
            !objectsAreEqual(
                deliveryTrips.deliveryTripIdsByAreaId[userAddress.settlementId], 
                prevProps.deliveryTrips.deliveryTripIdsByAreaId[userAddress.settlementId]
            ) ||
            //if the pickup addresses for any of the stores has changed
            !objectsAreEqual(
                cart.selectedPickupAddressIdBySellerId,
                prevProps.cart.selectedPickupAddressIdBySellerId
            )

        ){
            this.calculateWeeklyAvailableSlotsForDeliverySelections()
        }
        if (
            //if the delivery fulfillment estimations have changed or
            !objectsAreEqual(
                cart.deliveryFulfillmentEstimationsByDeliverySelectionId,
                prevProps.cart.deliveryFulfillmentEstimationsByDeliverySelectionId
            ) ||
            //or the weekly available slots for delivery selections have changed
            !objectsAreEqual(
                cart.weeklyAvailableDeliverySlotsByDeliverySelectionId,
                prevProps.cart.weeklyAvailableDeliverySlotsByDeliverySelectionId
            )
        ) {
            await this.deselectEarlierDeliverySlots()
            this.selectNextDeliverySlots()
        }
    }


    calculateWeeklyAvailableSlotsForDeliverySelections = () => {
        const {user, cart, deliveryProviders, actions} = this.props
        const userAddress = user.addressesById[user.defaultAddressId]
        const deliverySelectionMap = getSellerDeliverySelectionByDeliveryProviderId(
            deliveryProviders.deliveryProvidersById,
            cart.selectedDeliveryProviderIdsBySellerId,
            cart.selectedPickupAddressIdBySellerId
        )
        const weeklyAvailableDeliverySlotsByDeliverySelectionId = Object.values(deliverySelectionMap)
                                                                .reduce((map, deliverySelection) => {
            const {id, deliveryProviderId, sellerId, pickupAddressId} = deliverySelection
            map[id] = this.getWeeklyAvailableSlotsFromDeliveryProvider(
                deliveryProviderId,
                userAddress.settlementId,
                sellerId, 
                pickupAddressId
            )
            return map
        }, {})
        actions.updateWeeklyAvailableDeliverySlots(weeklyAvailableDeliverySlotsByDeliverySelectionId)
    }

    getWeeklyAvailableSlotsFromDeliveryProvider = (deliveryProviderId, deliveryAreaId, sellerId, pickupAddressId) => {
        const {sellers, deliveryTrips, deliveryProviders, deliverySlots} = this.props
        const {supportedDeliverySlotsById} = deliverySlots
        let weeklyAvailableSlots = {}
        const deliveryProvider = deliveryProviders.deliveryProvidersById[deliveryProviderId]
        if (!deliveryProvider) return {}
        //seller obj is needed for pickup / merchant delivery
        const seller = sellers.sellersById[sellerId]
        if (deliveryProvider.type === DELIVERY_PROVIDER_TYPE_PICK_UP){
            //only applies to pickup-instore
            const sellerAddress = seller.addressesById[pickupAddressId]
            if (!sellerAddress || !sellerAddress.openingHours) return {}
            weeklyAvailableSlots = getWeeklyAvailableSlotsWithinOpeningHours(supportedDeliverySlotsById, sellerAddress.openingHours)
        } else if (deliveryProvider.type === DELIVERY_PROVIDER_TYPE_MERCHANT_DELIVERY){
            //TODO in future, maybe set delivery hours on the self delivery object in the store
            //this is because, the store may not offer delivery during its regular opening hours
            const defaultAddress = seller.addressesById[seller.defaultAddressId]
            weeklyAvailableSlots = getWeeklyAvailableSlotsWithinOpeningHours(supportedDeliverySlotsById, defaultAddress.openingHours)
        } else if (deliveryProvider.type === DELIVERY_PROVIDER_TYPE_OPENING_HOURS){
            //e.g. delivery providers like Qwick
            //TODO this needs to consider the opening hours of the store as well
            weeklyAvailableSlots = getWeeklyAvailableSlotsWithinOpeningHours(supportedDeliverySlotsById, deliveryProvider.openingHours)
        }
        else if (deliveryProvider.type === DELIVERY_PROVIDER_TYPE_AGENT_BASED){
            //only applies to Fulfilled By Shopdm, but can apply to others in the future
            const deliveryTripsInAreaMap = deliveryTrips.deliveryTripIdsByAreaId[deliveryAreaId]
            //if there are no delivery trips to this area then return the default, that none are available
            if (!deliveryTripsInAreaMap) return weeklyAvailableSlots
            const deliveryTripList = Object.keys(deliveryTripsInAreaMap)
                                           .map(tripId => deliveryTrips.deliveryTripsById[tripId])
                                           .filter(trip => trip.isActive && trip.deliveryProviderId === deliveryProviderId)
            weeklyAvailableSlots = getWeeklyAvailableSlotsFromDeliveryAgentBasedDeliveryProvider(supportedDeliverySlotsById, deliveryTripList)
        } 
        return weeklyAvailableSlots
    }

    deselectEarlierDeliverySlots = async () => {
        /**
         * cycle over all selected delivery slots and delete each slot that ends before we think fulfillment will happen
         */
        const {cart, actions} = this.props
        if (cart.deliverySlotsById && Object.values(cart.deliverySlotsById).length > 0){
            for (let deliverySlotId in cart.deliverySlotsById) {
                const deliverySlot = cart.deliverySlotsById[deliverySlotId]
                //delivery slots and delivery selections have the same ids
                const deliveryFulfillmentEstimation = cart.deliveryFulfillmentEstimationsByDeliverySelectionId[deliverySlot.id]
                //if the slot ends before we estimate fulfillment will happen,
                //then deselect it  
                if (
                    !deliveryFulfillmentEstimation ||
                    deliverySlot.endTime <= deliveryFulfillmentEstimation.fulfilledAt
                ){
                    await actions.fetchDeselectDeliverySlot(deliverySlot.id)
                }
            }
        }
    }

    selectNextDeliverySlots = () => {
        //look at delivery selections that have no selected slot and choose the first available one
        const {cart, deliveryProviders, actions} = this.props
        const deliverySelectionMap = getSellerDeliverySelectionByDeliveryProviderId(
            deliveryProviders.deliveryProvidersById,
            cart.selectedDeliveryProviderIdsBySellerId,
            cart.selectedPickupAddressIdBySellerId
        )
        Object.values(deliverySelectionMap).forEach(deliverySelection => {
            //if there is already a selected delivery slot, about
            //del selection and slot used interchangably
            if (cart.deliverySlotsById[deliverySelection.id]) return
            //else
            const weeklyAvailableSlots = cart.weeklyAvailableDeliverySlotsByDeliverySelectionId[deliverySelection.id]
            const availableSlotList = getListOfAvailableDeliverySlotsFromWeeklyAvailableSlotMap(
                weeklyAvailableSlots,
                15, //generate slots for two weeks into the future 
                Date.now(),
                true
            )
            const deliveryFulfillmentEstimation = cart.deliveryFulfillmentEstimationsByDeliverySelectionId[deliverySelection.id]
            //if there is not estimated fulfillment time, abort
            if (!deliveryFulfillmentEstimation) return
            const {fulfilledAt} = deliveryFulfillmentEstimation
            const nextDeliverySlot = availableSlotList.find(slot => slot.startTime <= fulfilledAt && slot.endTime > fulfilledAt)
            //consider rounding time here
            if (nextDeliverySlot){
                const {startTime, endTime, type} = nextDeliverySlot
                const {id, deliveryProviderId, sellerIds} = deliverySelection
                const deliverySlot = {
                    id,
                    startTime,
                    endTime,
                    timezone: DOMINICA_TIMEZONE,
                    deliveryProviderId,
                    type
                }
                actions.selectDeliverySlot(
                    deliverySlot,
                    sellerIds
                )
            }
        })
    }

    render(){
        return ""
    }
}

const mapStateToProps = state => ({
    user: state.user,
    platformSettings: state.platformSettings,
    cart: state.cart,
    sellers: state.sellers,
    deliveryTrips: state.deliveryTrips,
    deliveryProviders: state.deliveryProviders,
    deliverySlots: state.deliverySlots
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliverySlotAvailabilityManager)