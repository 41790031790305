import React from 'react'
import BuyerAddressForm from "../BuyerAddressForm"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"
import {v4 as uuid4} from 'uuid'

function CreateBuyerAddressForm({actions, buttonText="Save Address", postSubmit=()=>{}}){
    const submit = async (
        line1, 
        line2, 
        city, 
        settlementId, 
        countryName,
        countryId,
        phoneNumber,
        directions,
        usaCity,
        zipCode,
        handleSubmitSuccess, 
        handleSubmitError
    ) => {
        return await actions.fetchAddAddress(
            uuid4(),
            line1,
            line2,
            city,
            settlementId,
            countryName,
            countryId,
            phoneNumber,
            directions,
            usaCity,
            zipCode,
            handleSubmitSuccess, 
            handleSubmitError
        )
    }
    return (
        <BuyerAddressForm 
            actions={actions}
            formTitle='Provide A Delivery Address'
            buttonText={buttonText}
            postSubmit={postSubmit}
            submit={submit}
        />
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(CreateBuyerAddressForm);