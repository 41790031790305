import React from "react";
import Icon from "../Icon";
import {
    timestampToDateString, 
    getTimestampForStartOfDay, 
    timestampToWeekStartTimestamp,
    timestampsAreOnSameDay
} from "../../utils/datetimeUtils"
import {DAY_IN_MILLISECONDS} from "../../constants/datetime"
import styles from "./DateSlider.module.css"

class DateSlider extends React.PureComponent{
    constructor(props){
        super(props)
        let {activeDate, selectedDate, timezone} = props
        activeDate = activeDate ? 
                     getTimestampForStartOfDay(activeDate, !timezone, timezone) 
                     : 
                     selectedDate ?
                     getTimestampForStartOfDay(selectedDate, !timezone, timezone) 
                     :
                     getTimestampForStartOfDay(Date.now(), !timezone, timezone)
        const startOfWeek = timestampToWeekStartTimestamp(activeDate, !timezone, timezone)
        this.state = {
            activeDate,
            startOfWeek,
        }
    }

    static defaultProps = {
        activeDate: Date.now(),
        selectedDate: null,
        timezone: "",
        minDate: null,
        maxDate: null,
        onChangeActiveDate: ()=>{},
    }
    
    componentDidUpdate(prevProps){
        //if the active date in the props changes
        if (prevProps.activeDate !== this.props.activeDate){
            //trigger a state update locally
            this.handleChangeActiveDate(this.props.activeDate)
        }
    }
    handleChangeActiveDate = activeDate => {
        const {timezone, onChangeActiveDate} = this.props
        activeDate = getTimestampForStartOfDay(activeDate, !timezone, timezone)
        const startOfWeek = timestampToWeekStartTimestamp(activeDate, !timezone, timezone)
        this.setState({
            activeDate,
            startOfWeek,
        }, () => onChangeActiveDate(activeDate))
    }

    handleGoToPreviousWeek = () => {
        const {startOfWeek} = this.state
        this.handleChangeActiveDate(startOfWeek - 1)
    } 

    handleGoToNextWeek = () => {
        const {startOfWeek} = this.state
        this.handleChangeActiveDate(startOfWeek + (7 * DAY_IN_MILLISECONDS))
    } 

    getMonth = () => {
        const {timezone} = this.props
        const {startOfWeek} = this.state
        const endOfWeek = startOfWeek + (7 * DAY_IN_MILLISECONDS) - 1
        const startMonth = timestampToDateString(startOfWeek, "MMMM", !timezone, timezone)
        const endMonth = timestampToDateString(endOfWeek, "MMMM", !timezone, timezone)
        return startMonth === endMonth ? //if the week begins and ends in the same month
                startMonth  //show only that month
                :
                `${startMonth}-${endMonth}` //otherwise show both the start and end month
    }
    render(){
        const {startOfWeek, activeDate} = this.state
        const {timezone, minDate, maxDate, selectedDate} = this.props
        const weekDaysArray = new Array(7).fill(0).map((d, i) => {
            const date = startOfWeek + (i * DAY_IN_MILLISECONDS)
            const disabled = Boolean(
                (minDate && date < minDate) || //earlier than minimum date
                (maxDate && date > maxDate) //later than maxDate
            )
            const day = timestampToDateString(date, "d", !timezone, timezone)
            const dayOfWeek = timestampToDateString(date, "EEE", !timezone, timezone)
            const active = disabled ? 
                            false 
                            : 
                            timestampsAreOnSameDay(activeDate, date)
            const selected = disabled ? false :
                             selectedDate ? 
                             timestampsAreOnSameDay(selectedDate, date) 
                             : 
                             false
            const isToday = timestampsAreOnSameDay(Date.now(), date)
            return {
                date,
                day,
                dayOfWeek,
                active,
                selected,
                isToday,
                disabled
            }
        })

        const previousArrowDisabled = minDate && startOfWeek < minDate
        const nextArrowDisabled = maxDate && 
                                 ((startOfWeek + (7 * DAY_IN_MILLISECONDS) - 1) > maxDate)
        return (
            <div className={styles.container}>
                <div className={styles.month}>
                    {this.getMonth()}
                </div>
                <div className={styles.week}>
                    <div className={`${styles.arrow} ${previousArrowDisabled ? styles.disabled : ""}`} 
                        onClick={!previousArrowDisabled ? this.handleGoToPreviousWeek : () => {}}
                    >
                        {
                            !previousArrowDisabled ?
                            <Icon icon="keyboard-arrow-left"/>
                            :
                            null
                        }
                    </div>
                    {
                        weekDaysArray.map(weekDay => {
                            const {date, day, dayOfWeek, active, selected, isToday, disabled} = weekDay 
                            return <Day 
                                        key={date}
                                        day={day}
                                        dayOfWeek={dayOfWeek}
                                        active={active}
                                        selected={selected}
                                        isToday={isToday}
                                        onClick={() => this.handleChangeActiveDate(date)}
                                        disabled={disabled}
                                    />
                        })
                    }
                    <div className={`${styles.arrow} ${nextArrowDisabled ? styles.disabled : ""}`} 
                        onClick={!nextArrowDisabled ? this.handleGoToNextWeek : ()=>{}}
                    >
                        {
                            !nextArrowDisabled ?
                            <Icon icon="keyboard-arrow-right"/>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const Day = ({day, dayOfWeek, active=false, selected=false, isToday=false, disabled=false, onClick=()=>{}}) => {
    return (
        <div className={`${styles.dayContainer} ${active ? styles.active : ""} ${selected ? styles.selected : ""} ${disabled ? styles.disabled : ""}`}
            onClick={!disabled ? onClick : ()=>{}}
        >
            <div className={styles.dayOfWeek}>{isToday ? 'Today' : dayOfWeek}</div>
            <div className={styles.dayNumber}>{day}</div>
        </div>
    )
}
export default DateSlider