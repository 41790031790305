import React from "react"
import SelectedDeliverySlot from "../SelectedDeliverySlot"
import Modal from "../../components/Modal"
import AvailableDeliverySlotPicker from "../AvailableDeliverySlotPicker"
import DeliveryFulfillmentEstimator from "../DeliveryFulfillmentEstimator"
import SellerReadinessEstimator from "../SellerReadinessEstimator"
import DeliverySlotAvailabilityManager from "../DeliverySlotAvailabilityManager"
import CloseButton from "../../components/CloseButton"
import TimeCheck from "../../images/time-check.png"
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions";

import {PICK_UP_INSTORE_ID, SELF_DELIVERY_ID} from "../../constants/delivery"
import { alphabeticalSort } from "../../utils/stringUtils"
import { objectsAreEqual} from "../../utils/generalUtils"
import {DOMINICA_TIMEZONE} from "../../constants/datetime"
import {getSellerDeliverySelectionByDeliveryProviderId} from "../../utils/deliveryUtils"

class CheckoutDeliverySlot extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            activeDeliverySelectionId: null,
            deliverySelectionMap: this.getDeliverySelectionMap(),
        }
    }
    /**
     * Purpose:
     * 1. List a SelectedDeliverySlot for each delivery provider involved in the order
     *    (Update this on mount, and in response to changes to deliveryProvider-seller assignments)
     * 
     * 2. Allow the user to select a delivery slot for each delivery provider
     * 
     */
    componentDidUpdate = (prevProps) => {
        const {cart} = this.props
        if ( //if the assignment of delivery providers to sellers has changed
            !objectsAreEqual(
                cart.selectedDeliveryProviderIdsBySellerId, 
                prevProps.cart.selectedDeliveryProviderIdsBySellerId
            )
            || 
            //or the selected pick up address changes
            !objectsAreEqual(
                cart.selectedPickupAddressIdBySellerId, 
                prevProps.cart.selectedPickupAddressIdBySellerId
            )
        ){
            this.setState({
                deliverySelectionMap: this.getDeliverySelectionMap()
            })
        }
    }

    getDeliverySelectionMap = () => {
        const {cart, deliveryProviders} = this.props
        //get list of delivery selections
        return getSellerDeliverySelectionByDeliveryProviderId(
            deliveryProviders.deliveryProvidersById,
            cart.selectedDeliveryProviderIdsBySellerId,
            cart.selectedPickupAddressIdBySellerId
        )
    }

    //generate jsx for delivery selections
    getDeliverySlotSelections = () => {
        const {sellers} = this.props
        const {deliverySelectionMap} = this.state
        const getFirstSellerName = (sellerIds) =>  Object.keys(sellerIds)
                                                        .map(sellerId => sellers.sellersById[sellerId].name)
                                                        .sort((sAName, sBName) => alphabeticalSort(sAName, sBName))[0]
        const deliverySelectionList = Object.values(deliverySelectionMap)
                                            .sort((dA, dB) => {
                                                const dAFirstSellerName = getFirstSellerName(dA.sellerIds)
                                                const dBFirstSellerName = getFirstSellerName(dB.sellerIds)
                                                return alphabeticalSort(dAFirstSellerName, dBFirstSellerName)
                                            })
        const {cart} = this.props
        const deliverySelections = []
        deliverySelectionList.forEach(deliverySelection => {
            const Right = () => <button className="button primary" onClick={() => this.setState({activeDeliverySelectionId: deliverySelection.id})}>Change Slot</button>
            const {id, deliveryProviderId, sellerId, pickupAddressId, sellerIds} = deliverySelection
            //del selection and slot used interchangably
            const deliverySlot = cart.deliverySlotsById[deliverySelection.id]
            if (deliveryProviderId === SELF_DELIVERY_ID) {
                deliverySelections.push(
                    <SelectedDeliverySlot 
                        key={id}
                        deliverySlot={deliverySlot}
                        deliveryProviderId={deliveryProviderId}
                        sellerId={sellerId}
                        sellerIds={sellerIds} 
                        Right={Right}
                    />
                )
            }
            else if  (deliveryProviderId === PICK_UP_INSTORE_ID){
                deliverySelections.push(
                    <SelectedDeliverySlot 
                        key={id}
                        deliverySlot={deliverySlot}
                        deliveryProviderId={deliveryProviderId}
                        sellerId={sellerId}
                        sellerIds={sellerIds} 
                        pickupAddressId={pickupAddressId}
                        Right={Right}
                    />
                )
            }
            else deliverySelections.push(
                <SelectedDeliverySlot 
                    key={id}
                    deliverySlot={deliverySlot}
                    deliveryProviderId={deliveryProviderId}
                    sellerIds={sellerIds} 
                    Right={Right}
                /> 
            )
        })
        return deliverySelections
    }

    //set the selected delivery slot in redux using the selected times and delivery providers
    handleSelectDeliverySlot = (startTime, endTime, timezone, type) => {
        const {actions} = this.props
        const {activeDeliverySelectionId, deliverySelectionMap} = this.state
        const activeDeliverySelection = deliverySelectionMap[activeDeliverySelectionId]
        const {id, deliveryProviderId, sellerIds={}} = activeDeliverySelection //the delivery selection which is active in the modal
        //delivery slots have the same id as the active delivery selection
        const deliverySlot = {
            id,
            startTime,
            endTime,
            timezone,
            deliveryProviderId,
            type
        }
        actions.selectDeliverySlot(
            deliverySlot,
            sellerIds
        )
    }

    handleDeselectDeliverySlot = deliverySlotId => {
        const {actions} = this.props
        actions.deselectDeliverySlot(deliverySlotId)
    }

    render(){
        const {cart, user} = this.props 
        const {activeDeliverySelectionId, deliverySelectionMap} = this.state
        //use the active delivery selection to find the selected slot in redux
        //del selection and slot used interchangably
        const selectedSlot = activeDeliverySelectionId ?
                            cart.deliverySlotsById[activeDeliverySelectionId]
                            :
                            null
        //this is the delivery selection on which the modal is opened 
        const activeDeliverySelection = activeDeliverySelectionId ? 
                                        deliverySelectionMap[activeDeliverySelectionId]
                                        :
                                        null
        //get the current delivery address
        const userAddress = user.addressesById[user.defaultAddressId]
        return (
            <div>
                {/* automatically estimate when sellers on the order will have items ready for pickup */}
                <SellerReadinessEstimator />
                {/* automatically estimate when a pickup or delivery will be fulfilled to filter available slots */}
                <DeliveryFulfillmentEstimator /> 
                {/* automatically select the first available slot and deselect no-longer-valid slots.
                    Slots are available if the fall within opening hours or if they intersect with agent delivery trips
                */}
                <DeliverySlotAvailabilityManager />
                <div className={'strong checkoutTitle'}><span className="badge outline">4</span><img src={TimeCheck}/>Tell Us When You'd Like To Receive Your Items</div>
                {
                    Object.keys(deliverySelectionMap).length > 0 ?
                        <React.Fragment>
                            <div className="instructions space-bottom">* This could change depending on driver schedules and store responsiveness. But we'll do our best and keep you informed 🙂</div>
                            <div className="checkoutField">
                                {this.getDeliverySlotSelections()}
                            </div>
                        </React.Fragment>
                    :
                    "First, choose a delivery method above"
                }
                {
                    activeDeliverySelectionId ? 
                    <Modal
                        isOpen={Boolean(activeDeliverySelectionId)}
                        closeModal={() => this.setState({activeDeliverySelectionId: null})}
                        className="fit contained"
                        overlayClassName="center noPadding"
                        closeOnOverlayClick={true}
                        showCloseButton={false}
                    >
                        <AvailableDeliverySlotPicker 
                            selectedSlot={selectedSlot}
                            activeDeliverySelection={activeDeliverySelection}
                            deliveryAreaId={userAddress.settlementId}
                            onSelectDeliverySlot={this.handleSelectDeliverySlot}
                            onDeselectDeliverySlot={this.handleDeselectDeliverySlot}
                            timezone={DOMINICA_TIMEZONE}
                            TopRight={() => <CloseButton onClick={() => this.setState({activeDeliverySelectionId: null})} />}
                        />
                    </Modal>
                    :
                    null
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    cart: state.cart,
    deliveryProviders: state.deliveryProviders,
    sellers: state.sellers,
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDeliverySlot)