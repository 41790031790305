import {firebaseApp} from "../config/firebase"
import { getFirestore, collection, query, getDocs, where} from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"

export const SAVE_PARTNERS = 'SAVE_PARTNERS'

export const savePartners = partners => {
    return {
        type: SAVE_PARTNERS,
        payload: {
            partners
        }
    }
}

export const fetchSavePartners = () => {
    const firestore = getFirestore(firebaseApp);
    const partnersRef = query(collection(firestore, "partners"))
    return async dispatch => {
        try {
            const querySnapshot = await getDocs(partnersRef)
            //get an array of partners from the snapshot
            const partners = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(savePartners(partners))
            return true

        } catch (e){
            const message = `action > partners > fetchSavePartners: Failed to save partners`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSavePartnerByHandle = handleId => {
    handleId = String(handleId).toLowerCase().trim()
    const firestore = getFirestore(firebaseApp);
    const partnersRef = query(collection(firestore, "partners"), where("handleId", "==", handleId))
    return async dispatch => {
        try {
            const querySnapshot = await getDocs(partnersRef)
            //get an array of partners from the snapshot
            const partners = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(savePartners(partners))
            //handles are unique, so there should only be one partner for a given handle
            const partner = partners.length > 0 ? partners[0] : null
            return partner

        } catch (e){
            const message = `action > partners > fetchSavePartnerByHandle: Failed to save partner with handle ${handleId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return null
        }
    }
}