import React from "react"
import styles from "./AboutBanner.module.css"
import {withRouter} from "react-router-dom"

const AboutBanner = ({history}) => {
    const onLearnMore = () => history.push("/about")
    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <img 
                    src="https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2FaboutMedia%2Fteam-half-april-2021-small.jpeg?alt=media&token=4c0bb4ba-cf1f-4499-ac31-0f3847077490" 
                    alt="Shopdm Team"
                />  
            </div>
            <div className={styles.right}>
                <div className={`title ${styles.heading}`}>Because It Should Be Easy</div>
                <div className={styles.explanation}>Living in Dominica should be easier. Growing a business in Dominica should be easier. These beliefs are behind everything we do. We're not there yet, but we try to add new products and improve the platform every day.</div>
                <div>
                    <button onClick={onLearnMore} className="button white">Learn More About Us</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(AboutBanner)