import React from "react";
import {timestampToDateString} from "../../utils/datetimeUtils"
import {DAY_TIME_HOUR_LIST} from "../../constants/datetime"

const RoundTime = ({timestamp, timezone=""}) => {
    if (typeof timestamp !== "number") return ""
    const timeString = timestampToDateString(timestamp, "HH:mm", !timezone, timezone)
    const roundTime = DAY_TIME_HOUR_LIST.find(dayTime => dayTime.id >= timeString) 
    return <span>{roundTime ? roundTime.text : timeString}</span>
} 

export default RoundTime