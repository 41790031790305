import React from "react"
import styles from "./UsageStats.module.css"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import {getDominicaDatestring} from "../../utils/datetimeUtils"

import Icon from "../../components/Icon"

class UsageStatsBar extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {
            dailySummaryListener: () => {},
            usageId: getDominicaDatestring()
        }
    }

    componentDidMount = async () => {
        const {actions} = this.props
        const {usageId} = this.state
        const dailySummaryListener = await actions.fetchSubscribeToDailySummary(usageId)
        this.setState({dailySummaryListener})
    }

    componentWillUnmount(){
        this.state.dailySummaryListener()
    }

    render(){
        const {usage} = this.props
        const {usageId} = this.state
        const dailySummary = usage.dailySummariesById[usageId]
        return (
            <div className={styles.container}>
                <Stat label="site visits today" icon="computer" value={dailySummary ? dailySummary.sessionCount : 0}/>
                <Stat label="Customers today" icon="face" value={dailySummary ? dailySummary.uniqueDeviceCount : 0}/>
                <Stat label="Customer countries" icon="public" value={dailySummary && dailySummary.countryCodes ? Object.keys(dailySummary.countryCodes).length : 0}/>
                {/* <Stat label="Deliveries in progress" value={2}/> */}
                
            </div>
        )
    }
}

const Stat = ({label="", value="", icon=""}) => {
    return (
    <div className={styles.stat}>
        <div className={styles.label}><Icon icon={icon}/> {label}</div>
        <div className={styles.value}>{value}</div>
    </div>
    )
}
const mapStateToProps = state => ({
    usage: state.usage,
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UsageStatsBar)