export const CREDIT_TRANSACTION_EFFECT_TYPE_DEBIT = 'DEBIT'
export const CREDIT_TRANSACTION_EFFECT_TYPE_CREDIT = 'CREDIT'

export const CREDIT_TRANSACTION_TYPE_DEBIT = 'DEBIT'
export const CREDIT_TRANSACTION_TYPE_CREDIT = 'CREDIT'
export const CREDIT_TRANSACTION_TYPE_PAYOUT = 'PAYOUT'
export const CREDIT_TRANSACTION_TYPE_PAYMENT = 'PAYMENT'
export const CREDIT_TRANSACTION_TYPE_FEE = 'FEE'
export const CREDIT_TRANSACTION_TYPE_ERROR_CORRECTION = 'ERROR_CORRECTION'

export const CREDIT_STATUS_PENDING = 'PENDING'
export const CREDIT_STATUS_CANCELLED = 'CANCELLED'
export const CREDIT_STATUS_PROCESSED = 'PROCESSED'

export const SHOPDM_CREDIT_ENTITY_ID = '--shopdm--'
export const SHOPDM_SENDER_NAME = 'Shopdm'

export const CREDIT_TYPE_INDIVIDUAL = 'INDIVIDUAL'
export const CREDIT_TYPE_SHOPDM = 'SHOPDM' 

//store wallets
export const SHOPDM_STORES_WALLET_ID = '--shopdm-stores--'
export const SHOPDM_ACTUALS_WALLET_ID = '--shopdm-actuals--'

//delivery system wallets
export const SHOPDM_DELIVERY_PROVIDERS_WALLET_ID = '--shopdm-delivery-providers--'
export const SHOPDM_DELIVERY_AGENTS_WALLET_ID = '--shopdm-delivery-agents--'
export const SHOPDM_PICKUP_LOCATIONS_WALLET_ID = '--shopdm-pickup-locations--'

//payment processing wallets
export const SHOPDM_PAYPAL_WALLET_ID = '--shopdm-paypal--'
export const SHOPDM_JAD_WALLET_ID = '--shopdm-jad--'
export const SHOPDM_NBD_MOBANKING_WALLET_ID = '--shopdm-nbd-mobanking--'

//spending wallets
export const SHOPDM_SPENDING_WALLET_ID = '--shopdm-spending--'

export const CREDIT_TRANSACTION_ROLE_RECIPIENT='CREDIT_TRANSACTION_ROLE_RECIPIENT'
export const CREDIT_TRANSACTION_ROLE_SENDER='CREDIT_TRANSACTION_ROLE_SENDER'

export const CREDIT_WALLET_TYPE_SHOPDM_PAYMENT_PROCESSOR = "SHOPDM_PAYMENT_PROCESSOR"
export const CREDIT_WALLET_TYPE_SHOPDM_EARNINGS= "SHOPDM_EARNINGS"