import React from "react"
import MobankingReceiptUploadModal from "../MobankingReceiptUploadModal"
import PaymentModal from "../../components/PaymentModal"
import Icon from "../../components/Icon"

import styles from "./MobankingModal.module.css"
import {connect} from "react-redux"
import {CUSTOMER_CONFIRMED_PAYMENT, SHOPDM_CONFIRMED_PAYMENT} from "../../constants/order"
import {NBD_MOBANKING_ID} from "../../constants/payment"
import {copyToClipboard} from "../../utils/interactionUtils"

const MerchantAdd = 'https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2FexplanationVideos%2Fmerchant-add.mp4?alt=media&token=b9b2a3eb-eb54-444b-8a10-1c2407722de1'
const MobankingPay = 'https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/shopdm%2FexplanationVideos%2Fmobanking-pay.mp4?alt=media&token=fb986ce0-9fb4-48bb-a32c-0056f5c1c8c6'


class MobankingModal extends React.Component{

    state = {
        isSmallScreen: false,
        billingAccountNumber: "",
        showInstructions: false,
        paymentTimeElapsed: false,
        redirectCountdown: 5,
        redirectInterval: 0,
        showReceiptUploadModal: false
    }

    instructionsShown = false

    static defaultProps = {
        closeModal:()=>{},
        onCompletePayment: ()=>{}
    }

    checkBeforeLeaving = (e) => {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = '';
    }

    componentDidMount(){
        this.getBillingAccountNumber()
        window.addEventListener('beforeunload', this.checkBeforeLeaving)
        //determine whether we are on a mobile device or not
        const isSmallScreen = Boolean(window.screen.width <= 800)
        //countdown until the user can claim they have paid, this is set to 30 secs, the min time it will
        //take to pay via Mobanking
        setTimeout(() => this.setState({paymentTimeElapsed: true}), 30000)
        
        if (!isSmallScreen) {
            //if this is a desktop
            //set the interval which will countdown until Mobanking is opened
            const redirectInterval = setInterval(
                () => {
                    if (this.state.redirectCountdown < 1) {
                        this.handleLaunchMobanking()
                    } else this.setState({redirectCountdown: this.state.redirectCountdown - 1})
                }
            , 1000)
            this.setState({redirectInterval})
        } else this.setState({isSmallScreen})
    }

    getBillingAccountNumber = () => {
        const {orders, reference} = this.props
        const orderNumber = reference
        let billingOrder = null
        Object.values(orders.ordersById).forEach(o => {
            if (o.orderStatus[CUSTOMER_CONFIRMED_PAYMENT] && o.orderStatus[SHOPDM_CONFIRMED_PAYMENT]){
                //first check whether there haave been orders where the customer has used Mobanking before for a successful payment
                //next find the earliest of these orders
                if (!billingOrder ||
                    billingOrder.createdAt > o.createdAt
                    ) billingOrder= o
            }
        })
        const billingAccountNumber = (!billingOrder) ? orderNumber : billingOrder.orderNumber
        this.setState({billingAccountNumber})
    }

    handleLaunchMobanking = () =>{
        //if a redirect interval exists, clear it
        clearInterval(this.state.redirectInterval)
        const mobankingUrl = "https://secure.nbdominica.com"
        window.open(
            mobankingUrl,
            "_blank"
        )
        this.setState({
            redirectCountdown: 0
        })
    }

    componentWillUnmount(){
        if (this.state.redirectInterval) clearInterval(this.state.redirectInterval)
        window.removeEventListener('beforeunload', this.checkBeforeLeaving)
    }
    handleConfirmPayment = (imageFile = null) => {
        const {closeModal, amount2DecimalPlaces, onCompletePayment} = this.props
        if (window.confirm(`Only press ok if you have finished the Mobanking payment for EC$${amount2DecimalPlaces}`)){
            closeModal()
            onCompletePayment(imageFile)
        }
    }

    toggleShowInstructions = () => {
        this.instructionsShown = true
        this.setState({showInstructions: !this.state.showInstructions})
    }

    toggleShowReceiptUploadModal = () => this.setState({showReceiptUploadModal: !this.state.showReceiptUploadModal})
    render(){
        const {amount2DecimalPlaces, isOpen, closeModal, userName, reference, paymentProviders, confirmButtonText, cancelButtonText, objectType} = this.props
        const {isSmallScreen, billingAccountNumber, showInstructions, paymentTimeElapsed, redirectCountdown, showReceiptUploadModal} = this.state
        const mobankingPaymentProvider = paymentProviders.paymentProvidersById[NBD_MOBANKING_ID]
        return (
            <PaymentModal 
                paymentTitle="NBD Mobanking"
                logo={mobankingPaymentProvider.logo}
                reference={reference}
                amount2DecimalPlaces={amount2DecimalPlaces}
                isOpen={isOpen}
                closeModal={closeModal}
                onPressConfirm={this.toggleShowReceiptUploadModal}
                confirmButtonText={confirmButtonText}
                cancelButtonText={cancelButtonText}
                objectType={objectType}
                isDisabled={!paymentTimeElapsed}
                disabledText="Please follow the instructions to pay via Mobanking"
            >
            <div className={styles.container}>
                {
                    !isSmallScreen? 
                    <button className="button outline" onClick={this.handleLaunchMobanking}>
                        {
                            redirectCountdown > 1 ?
                            `Redirecting you to NBD Mobanking in ${redirectCountdown}`
                            :
                            "Can't see NBD Mobanking? Click here"
                        }
                    </button>
                    :
                    <div>
                        Open the Mobanking app to pay ${amount2DecimalPlaces} to Shopdm or visit secure.nbdominica.com in your Google Chrome app 
                    </div>
                }

                <a href="#how-to-pay" className={`instructions ${styles.instructionsPrompt} ${!this.instructionsShown ? styles.wiggle : ""}`} onClick={this.toggleShowInstructions}>
                    <Icon icon="info"/>
                    <span>
                    {
                        showInstructions ?
                        "Hide instructions"
                        :
                        `Choose Shopdm as the payee and pay $${amount2DecimalPlaces}. Need detailed instructions? Click here`
                    } 
                    </span>
                </a>
                {
                    showInstructions ?

                    <div>
                        <div className={styles.title}>
                            <div className="title" id="how-to-pay">How To Pay With Mobanking</div>
                        </div>
                        <div className={"info-bubble"}>
                            <div className={styles.info}>
                                <span className="badge dark">1</span>
                                <span className={styles.infoTitle}>Login</span>
                            </div>
                            <div className={styles.info}>
                                <span  className="badge dark">2</span>
                                <span className={styles.infoTitle}>{isSmallScreen ? "Press 'Payments' at the bottom right" : "Scroll down and press 'Make a Payment'"}</span>
                            </div>
                            <div className={styles.info}>
                                <span  className="badge dark">3</span>
                                <span className={styles.infoTitle}>{isSmallScreen ? "Press 'Pay Merchant' and choose Shopdm." :`At 'To Payee' choose Shopdm.`} <a href="#merchant-add">Here's How to add Shopdm as a merchant, if you have not added us yet</a></span>
                            </div>
                            <div className={styles.info}>
                                <span  className="badge dark">4</span>
                                <span className={styles.infoTitle}>At 'From Account', choose your account</span>
                            </div>
                            <div className={styles.info}>
                                <span  className="badge dark">5</span>
                                <span className={styles.infoTitle}>At 'Amount' Enter <span title="copy amount" className='actionText' onClick={() => copyToClipboard(amount2DecimalPlaces)}>{amount2DecimalPlaces}</span></span>
                            </div>
                            <div className={styles.info}>
                                <span  className="badge dark">6</span>
                                <span className={styles.infoTitle}>{isSmallScreen ? "Press 'Review' and then press 'Submit'" : `Press 'Continue' and then press 'Submit'`}</span>
                            </div>
                            {
                                isSmallScreen ?
                                <div className={styles.info}>
                                    <span  className="badge dark">7</span>
                                    <span className={styles.infoTitle}>Take a screenshot of your NBD mobanking receipt from 'Payment History' to fast-track order processing</span>
                                </div>
                                :
                                <div className={styles.info}>
                                    <span  className="badge dark">7</span>
                                    <span className={styles.infoTitle}>Take a screenshot of your NBD mobanking payment confirmation to fast-track order processing</span>
                                </div>
                            }
                            <div className={styles.info}>
                                <span  className="badge dark">8</span>
                                <span className={styles.infoTitle}>Close Mobanking and come back to Shopdm</span>
                            </div>
                            <div className={styles.info}>
                                <span  className="badge dark">9</span>
                                <span className={styles.infoTitle}>Press the green 'Confirm And Place Order' button on this screen</span>
                            </div>
                            <div className={styles.notSure}>Not sure what to do next? Click below to play the video</div>
                            <video width="100%" height="auto" controls>
                                <source src={MobankingPay} type="video/mp4"/>
                            </video>
                        </div>

                        <div id="merchant-add" className={`${styles.title} title`}>How To Add Shopdm As A Merchant</div>
                        <div className={"info-bubble"}>
                            
                            <div className={styles.only}>(Only follow these instructions if you have not yet added Shopdm as a Merchant)</div>
                            <div className={styles.info}>
                                <span className="badge dark">1</span>
                                <span className={styles.infoTitle}>Go To 'Manage Payees' in the menu to the left</span>
                            </div>
                            <div className={styles.info}>
                                <span className="badge dark">2</span>
                                <span className={styles.infoTitle}>Press the blue 'Add New Merchant' button</span>
                            </div>
                            <div className={styles.info}>
                                <span className="badge dark">3</span>
                                <span className={styles.infoTitle}>Choose 'SHOPDM' from the list and press 'Next'</span>
                            </div>
                            <div className={styles.info}>
                                <span className="badge dark">4</span>
                                <span className={styles.infoTitle}>Type '<span title="copy nickname" className='actionText' onClick={() => copyToClipboard('Shopdm')}>Shopdm</span>' into Nickname</span>
                            </div>
                            <div className={styles.info}>
                                <span className="badge dark">5</span>
                                <span className={styles.infoTitle}>Type <span title="copy billing account number" className='actionText' onClick={() => copyToClipboard(billingAccountNumber)}>{billingAccountNumber}</span> into Billing Account</span>
                            </div>
                            <div className={styles.info}>
                                <span className="badge dark">6</span>
                                <span className={styles.infoTitle}>Type <span title="copy customer name on the invoice" className='actionText' onClick={() => copyToClipboard(userName)}>{userName}</span> into Customer Name on Invoice</span>
                            </div>
                            <div className={styles.info}>
                                <span className="badge dark">7</span>
                                <span className={styles.infoTitle}>Press 'Next' and then 'Submit'</span>
                            </div>
                            <video width="100%" height="auto" controls>
                                <source src={MerchantAdd} type="video/mp4"/>
                            </video>
                        </div>
                    </div>
                    :
                    null
            }
            </div>
            {
                showReceiptUploadModal ?
                <MobankingReceiptUploadModal 
                    modalOpen={showReceiptUploadModal}
                    handleCloseModal={this.toggleShowReceiptUploadModal}
                    objectType={objectType}
                    handleConfirmPayment={this.handleConfirmPayment}
                />
                :
                null
            }
            </PaymentModal>
        )
    }
}

const mapStateToProps = state => ({
    orders: state.orders,
    paymentProviders: state.paymentProviders
})
export default connect(mapStateToProps)(MobankingModal)