import React from 'react'

import styles from './DataTable.module.css'

import DataTableCell from './DataTableCell'

export default ({data, fields, index, showRowIndices, even=false, error={}, onClick=null, selected=false, rowHighlightFunction=()=>{}}) => {
    if (!data) return null
    if(!fields) return null

    const evenStyle = even ? styles.even : ""

    const getCells = () => {
        return fields.map(field => {
            const transformFunction = field.transformFunction ? field.transformFunction : value => value
            const highlightFunction = field.highlightFunction ? field.highlightFunction :()=>null 
            return <DataTableCell 
                        key={`${data.id}${field.id}${field.header}`} 
                        value={transformFunction(data[field.id], data.id, field.id, data)}
                        highlight={highlightFunction(data[field.id], data.id, field.id)}
                        errorMessage={error[field.id] && !field.hideError? String(error[field.id]) : ""}
                    />
        })

    }
    const handleClick = onClick ? () => onClick(data.id) : () => {}
    const clickableStyle = onClick ? styles.clickable : ""
    const selectedStyle = selected ? styles.selected : ""
    const highlightStyle = rowHighlightFunction(data) ? {'backgroundColor': rowHighlightFunction(data)} : null
    return (
        <tr 
            style={highlightStyle}
            onClick={handleClick}
            className={[styles.rowContainer, evenStyle, clickableStyle, selectedStyle].join(" ")} 
            >
            {
                showRowIndices ? 
                <DataTableCell 
                    key={`${data.id}rowIndex`} 
                    value={index + 1}
                />
                : 
                null  
            }
            {getCells()}
        </tr>
    )
}

