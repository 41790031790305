import {firebaseApp} from "../config/firebase"
import {getFirestore, query, collection, where, limit, orderBy, getDoc, getDocs, doc, setDoc, onSnapshot, writeBatch, updateDoc, arrayUnion, deleteField, startAfter, endBefore, limitToLast, getCountFromServer} from "firebase/firestore"
import {getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll} from "firebase/storage"
import { toggleLoading } from "./system"
import { saveCategoryPage } from "./categories"
import { saveSellerProductsPage } from "./sellers"
import { logError } from "../utils/errorHandlingUtils"
import { firebaseImageUrlsEqual, isSupportedImageExtension } from "../utils/fileUtils"
import { getFirestoreObjectsByIdList, listenToFirestoreObjectsByIdList } from "../utils/firebase"
import {getAnalyticsItem} from "../utils/productUtils"
import {FIREBASE_IMAGE_IDENTIFIER} from "../constants/image"
import {STATISTICS_IDENTIFIER} from "../constants/analysis"
import {NEWEST_PRODUCTS_COUNT} from "../constants/product"
import { PAGINATE_NEXT, PAGINATE_BACK } from "../constants/interaction"
import {INVENTORY_UPDATE_TYPE_MANUAL} from "../constants/inventory"
import {
    SELLER_PRODUCT_LOADING_PERIOD, 
    CATEGORY_PRODUCT_LOADING_PERIOD, 
    FEATURED_PRODUCT_LOADING_PERIOD,
    FEATURED_PRODUCTS_LOADING_ID,
    NEWEST_PRODUCTS_LOADING_ID
} from "../constants/loading"

export const SAVE_PRODUCTS = 'SAVE_PRODUCTS'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const EDIT_PRODUCT = 'EDIT_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const CREATE_PRODUCT_STOCK = 'CREATE_PRODUCT_STOCK'
export const DELETE_PRODUCT_STOCK = 'DELETE_PRODUCT_STOCK'
export const EDIT_PRODUCT_STOCK = 'EDIT_PRODUCT_STOCK'

export const saveProducts = (products, loaded = null) => {
    return {
        type: SAVE_PRODUCTS,
        payload: {
            products,
            loaded
        }
    }
}

export const createProduct = (product, sellerId) => {
    return {
        type: CREATE_PRODUCT,
        payload: {
            product,
            sellerId
        }
    }
}

export const editProduct = (productId, edits, previousValues) => {
    return {
        type: EDIT_PRODUCT,
        payload: {
            productId,
            edits,
            previousValues
        }
    }
}

export const deleteProduct = productId => {
    return {
        type: DELETE_PRODUCT,
        payload: {
            productId
        }
    }
}

const createProductStock = productStock => {
    return {
        type: CREATE_PRODUCT_STOCK,
        payload: {
            productStock
        }
    }
}

const editProductStock = (productStockId, edits, previousValues, productId) => {
    return {
        type: EDIT_PRODUCT_STOCK,
        payload: {
            productStockId,
            edits,
            previousValues,
            productId
        }
    }
}

const deleteProductStock = (productStockId, productId) => {
    return {
        type: DELETE_PRODUCT_STOCK,
        payload: {
            productStockId,
            productId
        }
    }
}

export const fetchSaveProducts = () => {
    const firestore = getFirestore(firebaseApp)
    const productsRef = query(collection(firestore, "products"))
    return async (dispatch, getState) => {
        try {
            const {sellers, categories} = getState() 
            const querySnapshot = await getDocs(productsRef)
            //get an array of products from the snapshot
            const products = querySnapshot.docs.map(docRef => {
                const product = { ...docRef.data()}
                //if this is the stats object
                if (product.id === STATISTICS_IDENTIFIER) return product
                const seller = sellers.sellersById[product.createdBySellerId]
                product.analyticsItem = getAnalyticsItem(product, categories, seller)
                return product
            });
            dispatch(saveProducts(products))
            return true

        } catch (e){
            const message = `action > products > fetchSaveProducts: Failed to save products`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveSellerProducts = sellerId => {
    /**
      * Purpose: retrieve the seller's products from the firestore database for a particular seller
      */
    const firestore = getFirestore(firebaseApp)
    const productsRef = query(collection(firestore, "products"),
                                     where("createdBySellerId", "==", sellerId))
    return async (dispatch, getState) => {
        try {
            const {sellers, categories, products} = getState()
            //check whether this products were loaded recently, and if so, do not load again
            if (products.loaded.sellerIds[sellerId] &&
                (Date.now() - products.loaded.sellerIds[sellerId]) <= SELLER_PRODUCT_LOADING_PERIOD
            ) return true
            const querySnapshot = await getDocs(productsRef)
            const sellerProducts = querySnapshot.docs.map(docRef => {
                const product = { ...docRef.data()}
                const seller = sellers.sellersById[product.createdBySellerId]
                const category = categories.categoriesById[product.categoryIds[0]]
                product.analyticsItem = {
                    item_id: product.id,
                    item_name: product.brand ? `${product.brand} ${product.title}`: product.title,
                    item_category: category ? category.label : "",
                    item_brand: seller ? seller.name : "",
                    price: product.price
                }
                return product
            });
            dispatch(saveProducts(sellerProducts, {sellerIds:{[sellerId]: Date.now()}}))
            return true
        } catch (e){
            const message = `action > products > fetchSaveSellerProducts: Failed to save products for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSubscribeToSellerProducts = sellerId => {
    /**
      * Purpose: retrieve the seller's products from the firestore database for a particular seller
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const productsRef = query(collection(firestore, "products"),
                                     where("createdBySellerId", "==", sellerId))
    return async dispatch => {
        try {
            const productsListener = await onSnapshot(productsRef,
                querySnapshot => {
                    //get an array of products from the snapshot
                    const products = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveProducts(products, {sellerIds:{[sellerId]: Date.now()}}))
            })
            return productsListener
        } catch (e){
            const message = `action > products > fetchSubscribeToSellerProducts: Failed to save products for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSaveCategoryProducts = categoryId => {
    /**
      * Purpose: retrieve the category's products from the firestore database
      */
    const firestore = getFirestore(firebaseApp)
    const productsRef = query(collection(firestore, "products"),
                                     where("categoryIds", "array-contains", categoryId))
    return async (dispatch, getState) => {
        try {
            const {sellers, categories, products} = getState()
            //check whether this products were loaded recently, and if so, do not load again
            if (products.loaded.categoryIds[categoryId] &&
                (Date.now() - products.loaded.categoryIds[categoryId]) <= CATEGORY_PRODUCT_LOADING_PERIOD
            ) return true
            const querySnapshot = await getDocs(productsRef)
            const categoryProducts = querySnapshot.docs.map(docRef => {
                const product = { ...docRef.data()}
                const seller = sellers.sellersById[product.createdBySellerId]
                const category = categories.categoriesById[product.categoryIds[0]]
                product.analyticsItem = {
                    item_id: product.id,
                    item_name: product.brand ? `${product.brand} ${product.title}`: product.title,
                    item_category: category ? category.label : "",
                    item_brand: seller ? seller.name : "",
                    price: product.price
                }
                return product
            });
            dispatch(saveProducts(categoryProducts, {categoryIds:{[categoryId]: Date.now()}}))
            return true
        } catch (e){
            const message = `action > products > fetchSaveCategoryProducts: Failed to save products for category ${categoryId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSavePaginatedCategoryProducts = (
    categoryId,
    firstDoc=null,
    lastDoc=null,
    paginateDirection=PAGINATE_NEXT,
    pageNumber=0,
    sortBy="createdAt",
    sortDirection="desc",
    productCount=30
 ) => {
    /**
      * Purpose: - retrieve the category's products from the firestore database in batches of 30
      *          - create successive queries that take the next or previous 30 products
      */
    const firestore = getFirestore(firebaseApp)

    //1. form the base query which pulls active products from the specified category
    //and sorts them by most recent first 
    let batchQuery = query(collection(firestore, "products"),
                                    where("isInactive", "==", false),
                                    where("categoryIds", "array-contains", categoryId),
                                     orderBy(sortBy, sortDirection)
                                     )
    //2. add limit and document marker to the query, depending on whether
    //   we are going forwards or backwards, and whether this is the first query
    
    //offset limits are used when the side is loaded on the paginated page 
    //and the page number is MORE than 0. Our response is to use limit to load all of the documents
    //leading up to the desired page
    let offsetLimit = 0
    if (paginateDirection === PAGINATE_NEXT){
        //(a) if forwards

        //if this is not the first query, add the document marker as the last doc from the last query
        if (lastDoc) batchQuery = query(batchQuery, startAfter(lastDoc))
        //limit the query size
        //if the page number is more than zero, but this is the first query
        //e.g. if someone sends page 2 of a category to their friend
        //we will need to load more products than we want to, since firebase's startAfter and enBefore methods force
        //us to load from the beginning of the ordered collection
        if (pageNumber > 0 && !lastDoc) {
            offsetLimit = productCount * pageNumber
            batchQuery = query(batchQuery, limit(offsetLimit + productCount))
        } 
        //limit to 30 docs or otherwise
        else {
            batchQuery = query(batchQuery, limit(productCount))
        }
    } else if (paginateDirection === PAGINATE_BACK && firstDoc) {
        //(b) if backwards && we have a marker from a previous query
        
        //use the last query's first doc as a marker, to get docs before that 
        //and limit to 30 docs or otherwise
        if (firstDoc) batchQuery = query(batchQuery, endBefore(firstDoc), limitToLast(productCount))
    } 
    else throw new Error(`pagination failed ${paginateDirection} is invalid or firstDoc or lastDoc error`)

    return async (dispatch, getState) => {
        try {
            const {sellers, categories, products} = getState()
            // //check whether this products were loaded recently, and if so, do not load again
            if (
                categories.loadedProductIdsByCategoryPage[categoryId] &&
                categories.loadedProductIdsByCategoryPage[categoryId][sortBy] &&
                categories.loadedProductIdsByCategoryPage[categoryId][sortBy][pageNumber]  
            ) {
                const page = categories.loadedProductIdsByCategoryPage[categoryId][sortBy][pageNumber]
                return {
                    success: true,
                    productList: page.productIds.map(pId => products.productsById[pId]),
                    firstDoc: page.firstDoc,
                    lastDoc: page.lastDoc
                }
            }
            const querySnapshot = await getDocs(batchQuery)
            const categoryProducts = querySnapshot.docs
                                                  //TODO handle mroe elegantly. 
                                                  //This slice simply discards the extra loaded documents, causing them to the reloaded if the user goes ot their pages
                                                  //whereas, we could save them into redux if we calculate their firstDoc, lastDoc and pageNumbers and productIds 
                                                  .slice(offsetLimit) 
                                                  .map((docRef, i) => {
                //set document markers: first and last doc of the query
                if (i === 0) firstDoc = docRef
                if (i === querySnapshot.docs.length - 1) lastDoc = docRef
                //extract and format the product data
                const product = { ...docRef.data()}
                const seller = sellers.sellersById[product.createdBySellerId]
                const category = categories.categoriesById[product.categoryIds[0]]
                product.analyticsItem = {
                    item_id: product.id,
                    item_name: product.brand ? `${product.brand} ${product.title}`: product.title,
                    item_category: category ? category.label : "",
                    item_brand: seller ? seller.name : "",
                    price: product.price
                }
                return product
            });
            //only dave the category page if some products are returned
            //this avoids internet and other loading errors
            if (categoryProducts.length === 0) return {success: false}
            dispatch(
                saveProducts(categoryProducts)
            )
            dispatch (
                saveCategoryPage(
                    categoryId,
                    sortBy,
                    pageNumber,
                    categoryProducts.map(p => p.id),
                    firstDoc,
                    lastDoc
                )
            )
            return {
                success: true,
                productList: categoryProducts,
                firstDoc,
                lastDoc
            }
        } catch (e){
            const message = `action > products > fetchSavePaginatedCategoryProducts: Failed to save products for category ${categoryId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return {
                success: false
            }
        }
    }
}


export const fetchCountActiveCategoryProducts = (
    categoryId,
) => {
    const firestore = getFirestore(firebaseApp)
    const categoryProductsRef = query(collection(firestore, "products"),
                                where("isInactive", "==", false),
                                where("categoryIds", "array-contains", categoryId),
                                )
    return async (dispatch) => {
        try{
            const snapshot = await getCountFromServer(categoryProductsRef);
            return snapshot.data().count
        } catch (e){
            const message = `action > products > fetchCountActiveCategoryProducts: Failed to count active products in category ${categoryId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return 0
        }
    }
}

export const fetchSavePaginatedSellerProducts = (
    sellerId,
    firstDoc=null,
    lastDoc=null,
    paginateDirection=PAGINATE_NEXT,
    pageNumber=0,
    sortBy="createdAt",
    sortDirection="desc",
    productCount=30
 ) => {
    /**
      * Purpose: - retrieve the seller's products from the firestore database in batches of 30
      *          - create successive queries that take the next or previous 30 products
      */
    const firestore = getFirestore(firebaseApp)

    //1. form the base query which pulls active products from the specified category
    //and sorts them by most recent first 
    let batchQuery = query(collection(firestore, "products"),
                                    where("isInactive", "==", false),
                                    where("createdBySellerId", "==", sellerId),
                                     orderBy(sortBy, sortDirection)
                                     )
    //2. add limit and document marker to the query, depending on whether
    //   we are going forwards or backwards, and whether this is the first query
    
    //offset limits are used when the side is loaded on the paginated page 
    //and the page number is MORE than 0. Our response is to use limit to load all of the documents
    //leading up to the desired page
    let offsetLimit = 0
    if (paginateDirection === PAGINATE_NEXT){
        //(a) if forwards

        //if this is not the first query, add the document marker as the last doc from the last query
        if (lastDoc) batchQuery = query(batchQuery, startAfter(lastDoc))
        //limit the query size
        //if the page number is more than zero, but this is the first query
        //e.g. if someone sends page 2 of a category to their friend
        //we will need to load more products than we want to, since firebase's startAfter and enBefore methods force
        //us to load from the beginning of the ordered collection
        if (pageNumber > 0 && !lastDoc) {
            offsetLimit = productCount * pageNumber
            batchQuery = query(batchQuery, limit(offsetLimit + productCount))
        } 
        //limit to 30 docs or otherwise
        else {
            batchQuery = query(batchQuery, limit(productCount))
        }
    } else if (paginateDirection === PAGINATE_BACK && firstDoc) {
        //(b) if backwards && we have a marker from a previous query
        
        //use the last query's first doc as a marker, to get docs before that 
        //and limit to 30 docs or otherwise
        if (firstDoc) batchQuery = query(batchQuery, endBefore(firstDoc), limitToLast(productCount))
    } 
    else throw new Error(`pagination failed ${paginateDirection} is invalid or firstDoc or lastDoc error`)

    return async (dispatch, getState) => {
        try {
            const {sellers, categories, products} = getState()
            // //check whether this products were loaded recently, and if so, do not load again
            if (
                sellers.loadedProductIdsBySellerPage[sellerId] &&
                sellers.loadedProductIdsBySellerPage[sellerId][sortBy] &&
                sellers.loadedProductIdsBySellerPage[sellerId][sortBy][pageNumber]  
            ) {
                const page = sellers.loadedProductIdsBySellerPage[sellerId][sortBy][pageNumber]
                return {
                    success: true,
                    productList: page.productIds.map(pId => products.productsById[pId]),
                    firstDoc: page.firstDoc,
                    lastDoc: page.lastDoc
                }
            }
            const querySnapshot = await getDocs(batchQuery)
            const sellerProducts = querySnapshot.docs
                                                  //TODO handle mroe elegantly. 
                                                  //This slice simply discards the extra loaded documents, causing them to the reloaded if the user goes ot their pages
                                                  //whereas, we could save them into redux if we calculate their firstDoc, lastDoc and pageNumbers and productIds 
                                                  .slice(offsetLimit) 
                                                  .map((docRef, i) => {
                //set document markers: first and last doc of the query
                if (i === 0) firstDoc = docRef
                if (i === querySnapshot.docs.length - 1) lastDoc = docRef
                //extract and format the product data
                const product = { ...docRef.data()}
                const seller = sellers.sellersById[product.createdBySellerId]
                const category = categories.categoriesById[product.categoryIds[0]]
                product.analyticsItem = {
                    item_id: product.id,
                    item_name: product.brand ? `${product.brand} ${product.title}`: product.title,
                    item_category: category ? category.label : "",
                    item_brand: seller ? seller.name : "",
                    price: product.price
                }
                return product
            });
            //only dave the category page if some products are returned
            //this avoids internet and other loading errors
            if (sellerProducts.length === 0) return {success: false}
            dispatch(
                saveProducts(sellerProducts)
            )
            dispatch (
                saveSellerProductsPage(
                    sellerId,
                    sortBy,
                    pageNumber,
                    sellerProducts.map(p => p.id),
                    firstDoc,
                    lastDoc
                )
            )
            return {
                success: true,
                productList: sellerProducts,
                firstDoc,
                lastDoc
            }
        } catch (e){
            const message = `action > products > fetchSavePaginatedSellerProducts: Failed to save products for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return {
                success: false
            }
        }
    }
}

export const fetchCountActiveSellerProducts = (
    sellerId,
) => {
    const firestore = getFirestore(firebaseApp)
    const sellerProductsRef = query(collection(firestore, "products"),
                                where("isInactive", "==", false),
                                where("createdBySellerId", "==", sellerId),
                                )
    return async (dispatch) => {
        try{
            const snapshot = await getCountFromServer(sellerProductsRef);
            return snapshot.data().count
        } catch (e){
            const message = `action > products > fetchCountActiveSellerProducts: Failed to count active products created by seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return 0
        }
    }
}

export const fetchSaveFeaturedProducts = () => {
    /**
      * Purpose: retrieve products from the firestore database that are featured on the homepage
      */
    const firestore = getFirestore(firebaseApp)
    const productsRef = query(collection(firestore, "products"),
                                     where("isFeatured", "==", true))
    return async (dispatch, getState) => {
        try {
            const {sellers, categories, products} = getState()
            //check whether this products were loaded recently, and if so, do not load again
            if (products.loaded.system[FEATURED_PRODUCTS_LOADING_ID] &&
                (Date.now() - products.loaded.system[FEATURED_PRODUCTS_LOADING_ID]) <= FEATURED_PRODUCT_LOADING_PERIOD
            ) return true
            const querySnapshot = await getDocs(productsRef)
            const featuredProducts = querySnapshot.docs.map(docRef => {
                const product = { ...docRef.data()}
                const seller = sellers.sellersById[product.createdBySellerId]
                const category = categories.categoriesById[product.categoryIds[0]]
                product.analyticsItem = {
                    item_id: product.id,
                    item_name: product.brand ? `${product.brand} ${product.title}`: product.title,
                    item_category: category ? category.label : "",
                    item_brand: seller ? seller.name : "",
                    price: product.price
                }
                return product
            });
            dispatch(saveProducts(featuredProducts, {system:{[FEATURED_PRODUCTS_LOADING_ID]: Date.now()}}))
            return true
        } catch (e){
            const message = `action > products > fetchSaveFeaturedProducts: Failed to save featured products`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveProduct = (id = "") => {
    /**
      * Purpose: retrieve a single product from the firestore database
      */
    const firestore = getFirestore(firebaseApp)
    const productRef = doc(firestore, "products", id)
    return async (dispatch) => {
        try {
            const docRef = await getDoc(productRef)
            if (!docRef.exists()) return false
            const product = docRef.data()
            dispatch(saveProducts([product]))
            return product
        } catch (e){
            const message = `action > products > fetchSaveProduct: Failed to save product`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveNewestProducts = () => {
    /**
      * Purpose: retrieve products from the firestore database that are in the list of newest products
      */
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) => {
        try {
            const {sellers, categories} = getState()
            const productsRef = query(collection(firestore, "products"),
                                     orderBy("createdAt", "desc"), limit(NEWEST_PRODUCTS_COUNT))
            const querySnapshot = await getDocs(productsRef)
            const newestProducts = querySnapshot.docs.map(docRef => {
                const product = { ...docRef.data()}
                const seller = sellers.sellersById[product.createdBySellerId]
                const category = categories.categoriesById[product.categoryIds[0]]
                product.analyticsItem = {
                    item_id: product.id,
                    item_name: product.brand ? `${product.brand} ${product.title}`: product.title,
                    item_category: category ? category.label : "",
                    item_brand: seller ? seller.name : "",
                    price: product.price
                }
                return product
            });
            dispatch(saveProducts(newestProducts, {system:{[NEWEST_PRODUCTS_LOADING_ID]: Date.now()}}))
            return true
        } catch (e){
            const message = `action > products > fetchSaveNewestProducts: Failed to save newest products`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveProductsInCart = (productIdsInCart) => {
    /**
      * Purpose: retrieve products from the firestore database that are in one of the user's carts   
      */
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) => {
        try {
            const {cart, sellers, categories} = getState()

            if (!productIdsInCart){
                const productIds = {}
                //loop over the current cart and any carts waiting to be merged
                const carts = [cart, ...Object.values(cart.cartsToMergeByCartId)]
                //go over the items in each cart, adding them to the productIds map
                carts.forEach(c => {
                    Object.values(c.itemsByProductStockId).forEach(item => productIds[item.productId] = true)
                })
                productIdsInCart = Object.keys(productIds)
            }
            
            let productsInCart = await getFirestoreObjectsByIdList(
                firestore, 
                productIdsInCart, 
                "products"
            )
            productsInCart = productsInCart.map(product => {
                const seller = sellers.sellersById[product.createdBySellerId]
                const category = categories.categoriesById[product.categoryIds[0]]
                product.analyticsItem = {
                    item_id: product.id,
                    item_name: product.brand ? `${product.brand} ${product.title}`: product.title,
                    item_category: category ? category.label : "",
                    item_brand: seller ? seller.name : "",
                    price: product.price
                }
                return product
            });
            dispatch(saveProducts(productsInCart))
            return true
        } catch (e){
            const message = `action > products > fetchSaveProductsInCart: Failed to save the products in the cart`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchListenToProductsInCart = (productIdsInCart) => {
    /**
      * Purpose: listen to products from the firestore database that are in one of the user's carts   
      */
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) => {
        try {
            const {cart, sellers, categories} = getState()

            if (!productIdsInCart){
                const productIds = {}
                //loop over the current cart and any carts waiting to be merged
                const carts = [cart, ...Object.values(cart.cartsToMergeByCartId)]
                //go over the items in each cart, adding them to the productIds map
                carts.forEach(c => {
                    Object.values(c.itemsByProductStockId).forEach(item => productIds[item.productId] = true)
                })
                productIdsInCart = Object.keys(productIds)
            }
            
            let productListeners = await listenToFirestoreObjectsByIdList(
                firestore, 
                productIdsInCart, 
                "products",
                querySnapshot => {
                    //get an array of orders from the snapshot
                    let productsInCart = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    productsInCart = productsInCart.map(product => {
                        const seller = sellers.sellersById[product.createdBySellerId]
                        const category = categories.categoriesById[product.categoryIds[0]]
                        product.analyticsItem = {
                            item_id: product.id,
                            item_name: product.brand ? `${product.brand} ${product.title}`: product.title,
                            item_category: category ? category.label : "",
                            item_brand: seller ? seller.name : "",
                            price: product.price
                        }
                        return product
                    });
                    dispatch(saveProducts(productsInCart))
                }
            )
            return productListeners
        } catch (e){
            const message = `action > products > fetchListenToProductsInCart: Failed to listen to the products in the cart`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return []
        }
    }
}

export const fetchSaveProductsInList = (productIds=[], loaded=null) => {
    /**
      * Purpose: retrieve products from the firestore database that are in an arbitrary list   
      */
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) => {
        try {
            if (!productIds || productIds.length === 0) return []
            const {sellers, categories} = getState()
            let products = await getFirestoreObjectsByIdList(
                firestore, 
                productIds, 
                "products"
            )
            products = products.map(product => {
                const seller = sellers.sellersById[product.createdBySellerId]
                const category = categories.categoriesById[product.categoryIds[0]]
                product.analyticsItem = {
                    item_id: product.id,
                    item_name: product.brand ? `${product.brand} ${product.title}`: product.title,
                    item_category: category ? category.label : "",
                    item_brand: seller ? seller.name : "",
                    price: product.price
                }
                return product
            });
            dispatch(saveProducts(products, loaded))
            return products
        } catch (e){
            const message = `action > products > fetchSaveProductsInList: Failed to save the products in the list ${productIds}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSubscribeToProduct = productId => {
    /**
      * Purpose: retrieve one product from the firestore database
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const productRef = doc(firestore, "products", productId)
                                
    return async (dispatch, getState) => {
        try {
            const {sellers, categories} = getState() 
            const productListener = await onSnapshot(productRef,
                docRef => {
                    //get one product from the snapshot
                    const product = {...docRef.data()}
                    const seller = sellers.sellersById[product.createdBySellerId]
                    product.analyticsItem = getAnalyticsItem(product, categories, seller)
                    dispatch(saveProducts([product]))
                } 
            )
            return productListener
        } catch (e){
            const message = `action > products > fetchSubscribeToProduct: Failed to save product`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}


export const fetchCreateProduct = (
    id, 
    sellerId,
    title, 
    brand,
    price, 
    units,
    minimumOrderQty,
    imageFile, 
    isFeatured, 
    description,
    categoryIds,
    tags,
    characteristics,
    starRating,
    stock,
    isInactive,
    isDigital,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const productsRef = doc(firestore, "products", id)
    //stock cannot be discontinued if its quantity is greater than zero
    Object.values(stock).forEach(variant => {
        if (variant.quantityInStock >= minimumOrderQty){
            stock[variant.id].isDiscontinued = false
            stock[variant.id].discontinuedMessage = ""
        }
    })
    const product = {
        id,
        title,
        brand,
        price,
        units,
        minimumOrderQty,
        isFeatured,
        description,
        categoryIds,
        tags,
        characteristics,
        starRating,
        stock,
        createdAt: Date.now(),
        createdBySellerId: sellerId,
        isInactive,
        isDigital,
    }
    return async (dispatch, getState) => {
        try{
            const storage = getStorage(firebaseApp)
            //upload the image
            if (imageFile.file){ 
                const imageRef = ref(storage, `product-images/${sellerId}/${id}/${Date.now()}`)
                await uploadBytes(imageRef, imageFile.file)
                //get the image url
                product.imageUrl = await getDownloadURL(imageRef)
            } 
            ////if this is not a non-upload case where a url is provided,
            // just take the actual url provided
            else product.imageUrl = imageFile.url
            
            const {user, sellers, categories} = getState()
            product.createdByUserId = user.id
            //if stock is being created for the product, this includes the default stock made
            for (let variantId in stock){
                const variant = stock[variantId]
                const {imageFiles} = variant
                const imageUrls = new Array(imageFiles.length)
                await Promise.all(imageFiles.map(async (image, i) => {
                    //if this stock used the same image as the product's display image, reuse it here
                    if (image.url === imageFile.url) imageUrls[i] = product.imageUrl
                    //if the image includes a file upload it
                    else if (image.file){
                        const imageRef = ref(storage, `product-images/${sellerId}/${id}/stock/${variant.id}/${i}/${Date.now()}`)
                        await uploadBytes(imageRef, image.file)
                        const imageUrl = await getDownloadURL(imageRef)
                        imageUrls[i] = imageUrl
                    } 
                    //otherwise use the already uploaded url
                    else imageUrls[i] = image.url
                }))
                variant.imageUrl = imageUrls[0]
                variant.imageUrls = imageUrls
                delete variant.imageFiles
            }
            const batch = writeBatch(firestore)
            batch.set(productsRef, product)
            await batch.commit()
            const seller = sellers.sellersById[sellerId]
            product.analyticsItem = getAnalyticsItem(product, categories, seller)
            dispatch(createProduct(product, sellerId))
            onSuccess({...product, sellerId})
            return product
        } catch (e){
            const message = `action > products > fetchCreateProduct: Failed to create product ${JSON.stringify(product)} for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError({...product, sellerId})
            return false
        }
        
    }
}

export const fetchEditProduct = (
    id,
    sellerId,
    title,
    brand, 
    price,
    units,
    minimumOrderQty, 
    imageFile, 
    isFeatured, 
    description,
    categoryIds,
    tags,
    characteristics,
    starRating,
    newStock,
    isInactive,
    isDigital,
    onSuccess=()=>{}, 
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const productsRef = doc(firestore, "products", id)
    const productEdit = {
        title,
        brand,
        price,
        units,
        minimumOrderQty,
        isFeatured,
        description,
        categoryIds,
        tags,
        stock: newStock,
        characteristics,
        starRating,
        isInactive,
        isDigital,
        lastEditedAt: Date.now()
    }
    return async (dispatch, getState) => {
        try{
            const {products} = getState()
            const previousProduct = {...products.productsById[id]}
            const storage = getStorage(firebaseApp)
            if (imageFile.file){
                //upload the product image
                const imageRef = ref(storage, `product-images/${sellerId}/${id}/${Date.now()}`)
                await uploadBytes(imageRef, imageFile.file)
                //get the image url
                productEdit.imageUrl = await getDownloadURL(imageRef)
            } else productEdit.imageUrl = imageFile.url
            productEdit.lastEditedByUserId = getState().user.id
            //if the product was inactive and is no longer inactive
            //and all of the variants were discontinued
            //then set all previously discontinued variants to non-discontinued
            if (
                previousProduct.isInactive &&
                !productEdit.isInactive &
                Object.values(newStock).every(variant => Boolean(variant.isDiscontinued))
            ){
                Object.keys(newStock).forEach(variantId => {
                    productEdit.stock[variantId].isDiscontinued = false
                    productEdit.stock[variantId].discontinuedMessage = ""
                })                     
            }
            //at this point, the product is fully updated, what remains is to update the stock/variants
            //update all variants' imageUrls arraay, upload new images and 
            //replace any broken links that used to point to the old product image
            for (let variantId in newStock) {
                const variant = newStock[variantId]
                let {imageFiles} = variant
                const imageUrls = new Array(imageFiles.length)
                await Promise.all(imageFiles.map(async (image, i) => {
                    //if the stock has been newly uploaded and it is not the product' display image
                    if (image.file && (image.url !== imageFile.url)){
                        const imageRef = ref(storage, `product-images/${sellerId}/${id}/stock/${variant.id}/${i}/${Date.now()}`)
                        await uploadBytes(imageRef, image.file)
                        const imageUrl = await getDownloadURL(imageRef)
                        imageUrls[i] = imageUrl
                    } 
                    //if it is the product's display image, reuse the image url
                    else if (image.url === imageFile.url) imageUrls[i]= productEdit.imageUrl
                    else imageUrls[i] = image.url
                    
                }))
                //update stock in the product
                delete variant.imageFiles
                //if the stock has been updated through the product form, then update the last inventory update
                if (
                    previousProduct.stock && 
                    previousProduct.stock[variantId] &&
                    previousProduct.stock[variantId].quantityInStock !== variant.quantityInStock
                ){
                    variant.lastInventoryUpdateAt = Date.now()
                    variant.lastInventoryUpdateType = INVENTORY_UPDATE_TYPE_MANUAL
                }
                //ensure the stock is not be discontinued if its new quantity is greater than zero
                const minimumOrderQuantity = minimumOrderQty ? minimumOrderQty : 1
                if (variant.quantityInStock >= minimumOrderQuantity){
                    variant.isDiscontinued = false
                    variant.discontinuedMessage = ""
                    //if the product was previously inactive
                    //because all of the variants were discontinued
                    //then change the product to active, since we will now have at least one variant
                    //that is not discontinued 
                    if (
                        previousProduct.isInactive &&
                        Object.values(previousProduct.stock).every(variant => Boolean(variant.isDiscontinued))
                    ){
                        productEdit.isInactive = false
                    }
                }

                productEdit.stock[variant.id] = {
                    ...variant,
                    imageUrl: imageUrls[0], //this may not have changed, if the product image was not the variant's display image
                    imageUrls
                }
            }
            
            //loop through the previous versions of the stock/variants and delete any no-longer-used images
            const prevStock = previousProduct.stock ? previousProduct.stock : {}
            const stockImageMap = Object.values(newStock).reduce((map, variant) => {
                variant.imageUrls.forEach(imageUrl => {
                    map[imageUrl] = true
                })
                return map
            },{})
            const deletedImageUrls = {}
            const deletedVariantIds = {}
            for (let variantId in prevStock) {
                const variant = prevStock[variantId]
                //if this variant is in the old stock, but not the new stock, flag it as deleted
                if (!newStock[variantId]) deletedVariantIds[variantId] = true
                await Promise.all(variant.imageUrls.map(async (url) => {
                    if (
                        !firebaseImageUrlsEqual(url, productEdit.imageUrl) && //if the stock image is not reused in the product image
                        !stockImageMap[url] && //and is not used by any other variant 
                        !deletedImageUrls[url] && //and it has not yet been deleted
                        url.includes(FIREBASE_IMAGE_IDENTIFIER) //and is hosted on firebase
                    ) {
                        deletedImageUrls[url] = true
                        const imageRef = ref(storage, url)
                        try {
                            await deleteObject(imageRef)
                        } catch (e){
                            logError(`Could not delete variant image: ${e}, ${url}`)
                        }
                    }
                }))
            }
            //if the previous product display image is no longer used by 
            //any variants or the product itself, delete it
            if (
                !firebaseImageUrlsEqual(previousProduct.imageUrl, productEdit.imageUrl) &&
                !stockImageMap[previousProduct.imageUrl] &&
                !deletedImageUrls[previousProduct.imageUrl] &&
                previousProduct.imageUrl.includes(FIREBASE_IMAGE_IDENTIFIER)
            ){
                deletedImageUrls[previousProduct.imageUrl] = true
                const imageRef = ref(storage, previousProduct.imageUrl)
                try {
                    await deleteObject(imageRef)
                } catch (e){
                    logError(`Could not delete previous product image: ${e}, ${previousProduct.imageUrl}`)
                }
            }

            updateDoc(productsRef, productEdit)
            dispatch(editProduct(id, productEdit, previousProduct))
            //clean up for each deleted variant
            Object.keys(deletedVariantIds).forEach(variantId =>{
                dispatch(deleteProductStock(variantId, id))
            })
            onSuccess(productEdit)
            return productEdit
        } catch (e){
            const message = `action > products > fetchEditProduct: Failed to edit product ${id} with values ${JSON.stringify(productEdit)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(productEdit)
            return false
        }
    }
}

export const fetchDeleteProduct = (
    id,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const productsRef = doc(firestore, "products", id)
    return async (dispatch, getState) => {
        try {
            const batch = writeBatch(firestore)
            const {products} = getState()
            const product = products.productsById[id]
            if (product.imageUrl.includes(FIREBASE_IMAGE_IDENTIFIER)){
                const storage = getStorage(firebaseApp)
                const imageRef = ref(storage, product.imageUrl)
                try {
                    await deleteObject(imageRef)
                } catch (e){
                    logError(`Could not delete product image: ${e}, ${product.imageUrl}`)
                }
            }
            batch.delete(productsRef)
            batch.commit()
            dispatch(deleteProduct(id))
            onSuccess()
            return true
        } catch (e) {
            const message = `action > products > fetchDeleteProduct: Failed to delete product ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(e)
            return false
        }
    }
}

export const fetchUpdateProductIsFeatured = (
    id,
    isFeatured, 
    onSuccess=()=>{}, 
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const productsRef = doc(firestore, "products", id)
    const productEdit = {
        isFeatured,
        lastEditedAt: Date.now()
    }
    return async (dispatch, getState) => {
        try{
            productEdit.lastEditedByUserId = getState().user.id
            await updateDoc(productsRef, productEdit)
            dispatch(editProduct(id, productEdit))
            onSuccess(productEdit)
            return true
        } catch (e){
            const message = `action > products > fetchUpdateProductIsFeatured: Failed to update product ${id} with values ${JSON.stringify(productEdit)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(productEdit)
            return false
        }
    }
}

export const fetchUpdateProductIsInactive = (
    id,
    isInactive, 
    onSuccess=()=>{}, 
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const productsRef = doc(firestore, "products", id)
    const productEdit = {
        isInactive,
        lastEditedAt: Date.now()
    }
    return async (dispatch, getState) => {
        try{
            const {products} = getState()
            //if the product was inactive and is no longer inactive
            //and all of the variants were discontinued
            //then set all previously discontinued variants to non-discontinued
            const previousProduct = products.productsById[id]
            if (!previousProduct) throw new Error(`Cound not find previous product ${id}`)
            const stock = {...previousProduct.stock}
            if (
                previousProduct.isInactive &&
                !productEdit.isInactive &
                Object.values(stock).every(variant => Boolean(variant.isDiscontinued))
            ){
                Object.keys(stock).forEach(variantId => {
                    stock[variantId].isDiscontinued = false
                    stock[variantId].discontinuedMessage = ""
                })                     
            }
            //update the stock on the product
            productEdit.stock = stock
            productEdit.lastEditedByUserId = getState().user.id
            await updateDoc(productsRef, productEdit)
            dispatch(editProduct(id, productEdit))
            onSuccess(productEdit)
            return true
        } catch (e){
            const message = `action > products > fetchUpdateProductIsInactive: Failed to update product ${id} with isInactive status ${isInactive}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(productEdit)
            return false
        }
    }
}

export const fetchUpdateProductCharacteristics = (
    id,
    characteristics, 
    onSuccess=()=>{}, 
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const productsRef = doc(firestore, "products", id)
    const productEdit = {
        characteristics,
        lastEditedAt: Date.now()
    }
    return async (dispatch, getState) => {
        try{
            productEdit.lastEditedByUserId = getState().user.id
            await updateDoc(productsRef, productEdit)
            dispatch(editProduct(id, productEdit))
            onSuccess(productEdit)
            return true
        } catch (e){
            const message = `action > products > fetchUpdateProductCharacteristics: Failed to update product chracteristics for ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(productEdit)
            return false
        }
    }
}

export const fetchUpdateProductCategories = (
    id,
    categoryIds, 
    onSuccess=()=>{}, 
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const productsRef = doc(firestore, "products", id)
    const productEdit = {
        categoryIds,
        lastEditedAt: Date.now()
    }
    return async (dispatch, getState) => {
        try{
            productEdit.lastEditedByUserId = getState().user.id
            await updateDoc(productsRef, productEdit)
            dispatch(editProduct(id, productEdit))
            onSuccess(productEdit)
            return true
        } catch (e){
            const message = `action > products > fetchUpdateProductCategories: Failed to update product ${id} with values ${JSON.stringify(productEdit)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(productEdit)
            return false
        }
    }
}

export const fetchCreateProductStock = (
    id, 
    productId,
    sellerId,
    quantityInStock,
    price,
    characteristics,
    images,
    serialNumber,
    skuNumber,
    onSuccess = ()=>{},
    onError = ()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const productRef = doc(firestore, "products", productId)
    const productStock = {
        id,
        productId,
        sellerId,
        quantityInStock,
        price,
        characteristics,
        serialNumber,
        skuNumber
    }
    return async (dispatch, getState) =>{
        try {
            const imageUrls = new Array(images.length)
            await Promise.all(images.map(async (image, i) => {
                if (image.file){
                    const storage = getStorage(firebaseApp)
                    const imageRef = ref(storage, `product-images/${sellerId}/${productId}/stock/${id}/${i}/${Date.now()}`)
                    await uploadBytes(imageRef, image.file)
                    const imageUrl = await getDownloadURL(imageRef)
                    imageUrls[i] = imageUrl
                } else imageUrls[i] = image.url
            }))
            productStock.imageUrl = imageUrls[0]
            productStock.imageUrls = imageUrls
            const {products} = getState()
            const product = {...products.productsById[productId]}
            product.stock = product.stock ? product.stock : {}
            await setDoc(productRef, {
                ...product,
                stock: {
                    ...product.stock,
                    [id] : productStock
                }
            })
            dispatch(createProductStock(productStock))
            onSuccess(productStock)
            return true
        } catch (e) {
            const message = `action > products > fetchCreateProductStock: Failed to create product stock for product ${productId} ${JSON.stringify(productStock)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(productStock)
            return false
        }
    }
}

export const fetchEditProductStock = (
    id,
    productId,
    sellerId,
    quantityInStock,
    price,
    characteristics,
    images,
    serialNumber,
    skuNumber,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const productRef = doc(firestore, "products", productId)
    const productStockEdit = {
        quantityInStock,
        price,
        characteristics,
        serialNumber,
        skuNumber,
        lastEditedAt: Date.now()
    }
    return async (dispatch, getState) => {
        try{
            const {productStock, products} = getState()
            const stock = {...productStock.productStockById[id]}
            const product = {...products.productsById[productId]}
            const storage = getStorage(firebaseApp)
            const stockImageMap = Object.values(product.stock).reduce((map, variant) => {
                if (id !== variant.id){
                    variant.imageUrls.forEach(imageUrl => {
                        map[imageUrl] = true
                    })
                }
                return map
            },{})
            //delete all previous imageUrls that are not in the new images array
            await Promise.all(stock.imageUrls.map(async (url, i) => {
                //if there is not a matching image in the new images array
                //or the current and previous urls are different 
                //and the url is not the product url
                //and none of the new image use this url
                if (
                        (!images.find(image => image.url === url)) && //image url no longer in the new list of images 
                        !stockImageMap[url] && //and url is in none of the other variants
                        (!firebaseImageUrlsEqual(url, product.imageUrl)) //and it is not the product image
                    ){
                    const imageRef = ref(storage, url)
                    try {
                        await deleteObject(imageRef)
                    } catch (e){
                        logError(`Could not delete product variant image: ${e}, ${url}`)
                    }
                }
            }))

            const imageUrls = new Array(images.length)
            //upload all new image files and save and generate their urls 
            await Promise.all(images.map(async (image, i) => {
                if (image.file){
                    const imageRef = ref(storage, `product-images/${sellerId}/${productId}/stock/${id}/${i}/${Date.now()}`)
                    await uploadBytes(imageRef, image.file)
                    const imageUrl = await getDownloadURL(imageRef)
                    imageUrls[i] = imageUrl
                } else imageUrls[i] = image.url
            }))
            //ensure the (displayed) product price is updated when the stock price is changed
            //as long as there is no alternate stock which can justify the previously displayed product price
            if (
                stock.price !== price && //the price has changed
                product.price === stock.price && //the previous 2 prices were the same
                Object.values(product.stock).every(
                    variant => variant.id === id || //every stock variant is either the changed variant 
                                variant.price !== product.price// or, it has a different price than the product
                )
            ){
                //in this case, update the product price to the new stock price
                //and so avoid false advertising
                product.price = price
            }
            //if the quantity in stock has been changed
            if (stock.quantityInStock !== productStockEdit.quantityInStock){
                //on this variant, log now as the last time it was updated
                //and the type of update as manual
                productStockEdit.lastInventoryUpdateAt = Date.now()
                productStockEdit.lastInventoryUpdateType = INVENTORY_UPDATE_TYPE_MANUAL
                //if the quantity in stock is postive, 
                //then the stock cannot be discontinued
                const minimumOrderQty = product.minimumOrderQty ? product.minimumOrderQty : 1
                if (productStockEdit.quantityInStock >= minimumOrderQty) {
                    productStockEdit.isDiscontinued = false
                    productStockEdit.discontinuedMessage = ""
                    //if the product was previously inactive
                    //because all of the variants were discontinued
                    //then change the product to active, since we will now have at least one variant
                    //that is not discontinued 
                    if (
                        product.isInactive &&
                        Object.values(product.stock).every(variant => Boolean(variant.isDiscontinued))
                    ){
                        product.isInactive = false
                    }
                }
            }
            productStockEdit.imageUrls = imageUrls 
            productStockEdit.imageUrl = imageUrls[0]
            productStockEdit.lastEditedByUserId = getState().user.id
            //if this variant is the only o
            // display image is also the product's display image, update the product
            const variantList = Object.keys(product.stock)
            if (
                variantList.length === 1 ||
                (
                    variantList.length > 1 && 
                    product.imageUrl &&
                    firebaseImageUrlsEqual(stock.imageUrl, product.imageUrl) && //the previous stock and display images were the same and
                    !firebaseImageUrlsEqual(stock.imageUrl, productStockEdit.imageUrl) && //but now the display pick of the stock has been updated
                    window.confirm("Also update the product's main display image?")
                )
            ){
                product.imageUrl = imageUrls[0]
            }
            

            await updateDoc(productRef, {
                imageUrl: product.imageUrl,
                price: product.price,
                isInactive: Boolean(product.isInactive),
                [`stock.${id}`] : {
                    ...stock,
                    ...productStockEdit
                }
            })
            dispatch(editProductStock(id, productStockEdit, stock, product.id))
            onSuccess(productStockEdit)
            return true
        } catch (e){
            const message = `action > products > fetchEditProductStock: Failed to update product stock ${id} with values ${JSON.stringify(productStockEdit)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(e, productStockEdit)
            return false
        }
    }
}

export const fetchDeleteProductStock = (
    id,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) => {
        try {
            const {products, productStock} = getState()
            const stock = productStock.productStockById[id]
            const product = products.productsById[stock.productId]
            const productRef = doc(firestore, "products", stock.productId)
            const storage = getStorage(firebaseApp)
            const stockImageMap = Object.values(product.stock).reduce((map, variant) => {
                if (id !== variant.id){
                    variant.imageUrls.forEach(imageUrl => {
                        map[imageUrl] = true
                    })
                }
                return map
            },{})
            await Promise.all(stock.imageUrls.map(async (url, i) => {
                 
                if (
                    !firebaseImageUrlsEqual(url, product.imageUrl) && //if the stock image is not reused in the product image
                    !stockImageMap[url] && //and is not used by any other variant 
                    url.includes(FIREBASE_IMAGE_IDENTIFIER) //and is hosted on firebase
                ) {
                    const imageRef = ref(storage, url)
                    try {
                        await deleteObject(imageRef)
                    } catch (e){
                        logError(`Could not delete product variant image: ${e}, ${url}`)
                    }
                }
            }))
            await updateDoc(productRef, {
                [`stock.${id}`]: deleteField()
            })
            dispatch(deleteProductStock(id, stock.productId))
            onSuccess()
            return true
        } catch (e) {
            const message = `action > products > fetchDeleteProductStock: Failed to delete product stock ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(e)
            return false
        }
    }
}

export const fetchUpdateProductStockNextRestockAt = (
    id,
    productId,
    nextRestockAt,
    nextRestockMessage="",
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const productRef = doc(firestore, "products", productId)
    const productStockEdit = {
        nextRestockAt,
        nextRestockMessage,
        lastEditedAt: Date.now()
    }
    return async (dispatch, getState) => {
        try{
            const {productStock, products, user} = getState()
            const product = {...products.productsById[productId]}
            if (!product) throw new Error(`Cound not find product ${productId} to request restock`) 
            productStockEdit.lastEditedByUserId = user.id
            await setDoc(productRef, {
                stock: {
                    [id]: productStockEdit 
                }
            }, {merge: true})
            dispatch(editProductStock(id, productStockEdit, productStock.productStockById[id], product.id))
            onSuccess(productStockEdit)
            return true
        } catch (e){
            const message = `action > products > fetchUpdateProductStockNextRestockAt: Failed to update product stock ${id} with values ${JSON.stringify(productStockEdit)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(e, productStockEdit)
            return false
        }
    }
}

export const fetchUpdateProductStockIsDiscontinued = (
    id,
    productId,
    isDiscontinued,
    discontinuedMessage="",
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const productRef = doc(firestore, "products", productId)
    const productStockEdit = {
        isDiscontinued,
        discontinuedMessage,
        lastEditedAt: Date.now()
    }
    //if variant is discontinued, then by definition its quantity in stock is zero
    if (isDiscontinued) productStockEdit.quantityInStock = 0
    return async (dispatch, getState) => {
        try{
            const {productStock, products, user} = getState()
            const product = {...products.productsById[productId]}
            if (!product) throw new Error(`Cound not find product ${productId} to request restock`) 
            productStockEdit.lastEditedByUserId = user.id
            
            const productEdit = {}
            if (isDiscontinued){
                //if this variant is being discontinued and 
                //all other product variants are discontinued, 
                //then automatically hide the product
                const allVariantsDiscontinued = Object.values(product.stock)
                                                           .every(variant => Boolean(variant.isDiscontinued) || variant.id === id)    
                if (allVariantsDiscontinued) productEdit.isInactive = true
            } else {
                //if this variant is being reinstated 
                //and all of its variants were previously discontinued
                //then assume that it was hidden because all the stock was discontinued
                //and set it active, now that this is no longer the case
                const allVariantsPreviouslyDiscontinued = Object.values(product.stock)
                                                           .every(variant => Boolean(variant.isDiscontinued))    
                if (allVariantsPreviouslyDiscontinued) productEdit.isInactive = false
            }
            await setDoc(productRef, {
                ...productEdit,
                stock: {
                    [id]: productStockEdit 
                }
            }, {merge: true})
            dispatch(editProductStock(id, productStockEdit, productStock.productStockById[id], product.id))
            onSuccess(productStockEdit)
            return true
        } catch (e){
            const message = `action > products > fetchUpdateProductStockNextRestockAt: Failed to discontinue product stock ${id} with values ${JSON.stringify(productStockEdit)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(e, productStockEdit)
            return false
        }
    }
}

export const fetchUpdateProductStockAltProductStockIds = (
    id,
    productId,
    altProductStockIds={},
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const productRef = doc(firestore, "products", productId)
    const productStockEdit = {
        altProductStockIds,
        lastAltsSuggestedAt: Date.now(),
        lastEditedAt: Date.now()
    }
    return async (dispatch, getState) => {
        try{
            const {productStock, products, user} = getState()
            const product = {...products.productsById[productId]}
            if (!product) throw new Error(`Cound not find product ${productId} to suggest alternatives`) 
            const stock = productStock.productStockById[id]
            if (!stock ) throw new Error(`Cound not find product stock ${id} for product ${productId} to suggest alternatives`) 
            productStockEdit.lastEditedByUserId = user.id
            await updateDoc(productRef, {
                stock: {
                    [id]: {
                        ...stock,
                        ...productStockEdit
                    } 
                }
            })
            dispatch(editProductStock(id, productStockEdit, productStock.productStockById[id], product.id))
            onSuccess(productStockEdit)
            return true
        } catch (e){
            const message = `action > products > fetchUpdateProductStockAltProductStockIds: Failed to suggest alternatives for product stock ${id} with values ${JSON.stringify(altProductStockIds)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(e, productStockEdit)
            return false
        }
    }
}
export const fetchGetProductBulkImportImages = (sellerId, filterFunc=() => true) => {
    /**
      * Purpose: list the seller's product photos from storage to create
      */
    const storage = getStorage(firebaseApp)
    const imagesRef = ref(storage, `bulk-product-imports/${sellerId}`)
    return async dispatch => {
        try {
            const results = await listAll(imagesRef)
            const images = []
            for(let i in results.items){
                const result = results.items[i]
                const image = {}
                image.name = result.name
                image.result = result
                if (filterFunc(image)) images.push(image)
            }
            const loadedImages = []
            const windowSize = 10
            const windowCount = Math.ceil(images.length/windowSize)
            const selectionText = `Downloading ${images.length} out of ${results.items.length} images`
            dispatch(toggleLoading(true, selectionText))
            for(let i = 0; i < windowCount; i++){
                const windowStart = windowSize * i
                let windowEnd = windowStart + windowSize
                windowEnd = windowEnd < images.length ? windowEnd : images.length
                const windowImages = images.slice(windowStart, windowEnd)
                await Promise.all(windowImages.map(async (image, i) => {
                    if(!isSupportedImageExtension(image.name)){
                        //if the file is not one of the supported image type then return
                        logError(`fetchGetProductBulkImportImages: Image file ${image.name} is not a supported image`)
                        return
                    }
                    image.url = await getDownloadURL(image.result)
                    const imageResult = await fetch(image.url)
                    image.file = await imageResult.blob()
                    delete image.result
                    loadedImages.push(image)
                    dispatch(toggleLoading(true, `${selectionText}: ${Math.floor(loadedImages.length/images.length * 100)}% complete`))
                }))
            }
            return loadedImages
        } catch (e){
            const message = `action > products > fetchGetProductBulkImportImages: Failed to get product images for bulk import for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return []
        }
    }
}

export const fetchCreateBulkProducts = (
    products,
    sellerId,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    /**
      * Purpose: create many products at once
      */
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) => {
        try {
            const {user, categories, sellers} = getState()
            
            const storage = getStorage(firebaseApp)
            const bulkImageMap = {}
            //loop through batches to avoid hitting the 500 write firebase limit
            const batchArray = [writeBatch(firestore)]
            const batchLimit = 400 //after this many operations on the batch, a new batch will be added and used
            let batchIndex = 0
            let operationCount = 0
            //this tracks the created products to show progress on screen
            const createdProductIds = []
            //created products asynchronously but in synchronous windows to avoid clogging the network 
            const windowSize = 50 //this amount of products will be made at once
            const windowCount = Math.ceil(products.length/windowSize)
            for(let i = 0; i < windowCount; i++){
                const batch = batchArray[batchIndex]
                const windowStart = windowSize * i
                let windowEnd = windowStart + windowSize
                windowEnd = windowEnd < products.length ? windowEnd : products.length
                const windowProducts = products.slice(windowStart, windowEnd)
                await Promise.all(windowProducts.map(async (product) => {
                    product.createdAt = Date.now()
                    product.createdBySellerId = sellerId
                    product.createdByUserId = user.id
                    //save images
                    const imageRef = ref(storage, `product-images/${sellerId}/${product.id}/${Date.now()}`)
                    await uploadBytes(imageRef, product.imageFile.file)
                    //get the image url
                    product.imageUrl = await getDownloadURL(imageRef)
                    product.imageFile.url = product.imageUrl
                    //set the product image's file to null to avoid it being reuploaded
                    product.imageFile.file = null 
                    //save remote urls for cleanup
                    if (product.imageFile.remoteUrl) bulkImageMap[product.imageFile.remoteUrl] = true
                    //save the stock images
                    for (let stockId in product.stock){
                        const variant = product.stock[stockId]
                        const imageUrls = new Array(variant.imageFiles.length)
                        await Promise.all(variant.imageFiles.map(async (image, j) => {
                            if (image.file){
                                const storage = getStorage(firebaseApp)
                                const imageRef = ref(storage, `product-images/${sellerId}/${product.id}/stock/${variant.id}/${j}/${Date.now()}`)
                                await uploadBytes(imageRef, image.file)
                                const imageUrl = await getDownloadURL(imageRef)
                                imageUrls[j] = imageUrl
                            } else imageUrls[j] = image.url
                        }))
                        variant.imageUrl = imageUrls[0]
                        variant.imageUrls = imageUrls
                        //save remote urls for cleanup
                        variant.imageFiles.forEach(image => {
                            if (image.remoteUrl) bulkImageMap[image.remoteUrl] = true
                        })
                        delete variant.imageFiles
                    }
                    delete product.imageFile
                    const productsRef = doc(firestore, "products", product.id)
                    batch.set(productsRef, product)
                    createdProductIds.push(product.id)
                    dispatch(toggleLoading(true, `Created ${createdProductIds.length} out of ${products.length} products: ${Math.floor(createdProductIds.length/products.length * 100)}% complete`))
                }))
                operationCount += windowSize
                if (operationCount >= batchLimit) {
                    batchArray.push(writeBatch(firestore));
                    batchIndex++;
                    operationCount = 0;
                }
            }
            //save all products in the different batches
            let savedProductCount = 0
            for (let i in batchArray) {
                const batch = batchArray[i]
                savedProductCount += batch._mutations ? batch._mutations.length : batchLimit
                await batch.commit()
                dispatch(toggleLoading(true, `Saved ${savedProductCount} out of ${products.length} products: ${Math.floor(savedProductCount/products.length * 100)}% complete`))
            } 
            const seller = sellers.sellersById[sellerId]
            //TODO something between here and the deletion of the images is blocking the thread
            //ideas: it is either this synchroous loop below or
            //the fact that we are listening to the server means we accidentally do some insana amount of 
            //reads or something. Will look into on my next improvement of bulk creation, good enough for now
            products.forEach(product => {
                product.analyticsItem = getAnalyticsItem(product, categories, seller)
                dispatch(createProduct(product, sellerId))
            })
            onSuccess(products)
            return Object.keys(bulkImageMap)
        } catch (e){
            const message = `action > products > fetchCreateBulkProducts: Failed to create bulk products for ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchDeleteBulkImages = (
    imageUrls= [],
    onSuccess = () => console.log(`Deleted ${imageUrls.length} images`),
    onError=()=>{}
) => {
    /**
      * Purpose: delete all images in the list provided
      */
    const storage = getStorage(firebaseApp)
    return async (dispatch) => {
        try {
            const deletedImageUrls = []
            //delete images asynchronously but in synchronous windows to avoid clogging the network 
            const windowSize = 50 //this amount of images will be deleted at once
            const windowCount = Math.ceil(imageUrls.length/windowSize)
            for(let i = 0; i < windowCount; i++){
                const windowStart = windowSize * i
                let windowEnd = windowStart + windowSize
                windowEnd = windowEnd < imageUrls.length ? windowEnd : imageUrls.length
                const windowImageUrls = imageUrls.slice(windowStart, windowEnd)
                await Promise.all(windowImageUrls.map(async imageUrl => {
                        const delImageRef = ref(storage, imageUrl)
                        try {
                            await deleteObject(delImageRef)
                        } catch (e){
                            logError(`Could not delete bulk product creation image: ${e}, ${imageUrl}`)
                        } finally {
                            deletedImageUrls.push(imageUrl)
                            dispatch(toggleLoading(true, `Deleted ${deletedImageUrls.length} out of ${imageUrls.length} uncompressed product images: ${Math.floor(deletedImageUrls.length/imageUrls.length * 100)}% complete`))
                        }
                    })
                )
            }
            onSuccess()
            return true
        } catch (e){
            const message = `action > products > fetchDeleteBulkImages: Failed to delete bulk images`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}
export const fetchUpdateProductInventory = (
    id,
    sellerId,
    inventoryData=[],
    onSuccess=()=>{},
    onError=()=>{}
) => {
    /**
      * Purpose: save an inventory update to the backend to update products and their stock
      */
    const firestore = getFirestore(firebaseApp)
    const inventoryUpdatesRef = doc(firestore, "inventoryUpdates", id)
    const inventory = inventoryData.reduce((map, item) =>{
        if (!map[item.productId]) map[item.productId] = {} 
        map[item.productId][item.id] = item
        return map
    }, {})
    
    return async (dispatch, getState) => {
        try {
            const {user} = getState()
            const inventoryUpdate = {
                id,
                sellerId,
                createdByUserId: user.id,
                createdAt: Date.now(),
                inventory
            }
            await setDoc(inventoryUpdatesRef,
                inventoryUpdate
            )
            return true
        } catch (e){
            const message = `action > products > fetchUpdateProductInventory: Failed to save product inventory for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateProductStats = (
    onSuccess=()=>{},
    onError=()=>{}
) => {
    /**
      * Purpose: update stats for the product collection
      */
    const firestore = getFirestore(firebaseApp)
    const productsRef = doc(firestore, "products", STATISTICS_IDENTIFIER)
    return async (dispatch, getState) => {
        try {
            const stats = {id: STATISTICS_IDENTIFIER}
            const {products} = getState()
            let productCount = 0
            let productStockCount = 0
            Object.values(products.productsById)
                  .forEach(product => {
                    productCount++
                    productStockCount += product.stock ? Object.keys(product.stock).length : 0
                })
            stats.productCount = productCount
            stats.productStockCount = productStockCount
            console.log(stats)
            await setDoc(productsRef, stats)
            return true
        } catch (e){
            const message = `action > products > fetchUpdateProductStats: Failed to update the product stats`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchEditBulkProductsInactiveStatus = (
    isInactive=false,
    productIds=[],
    sellerId,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    /**
      * Purpose: hide/show many products at once
      */
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) => {
        try {
            const {user, products} = getState()
            const batchArray = [writeBatch(firestore)]
            let batchIndex = 0
            let operationCount = 0
            productIds.forEach(productId => {
                const batch = batchArray[batchIndex]
                const product = products.productsById[productId]
                if (!product || product.createdBySellerId !== sellerId) throw new Error(`Product ${productId} ${product.titleAndBrand} has a createdBySellerId of ${product.createdBySellerId} by the current seller is ${sellerId}`)
                const productEdit = {
                    lastEditedAt: Date.now(),
                    lastEditedByUserId: user.id,
                    isInactive
                }
                const productsRef = doc(firestore, "products", product.id)
                batch.set(productsRef, productEdit, {merge: true})
                operationCount++
                if (operationCount === 499) {
                    batchArray.push(writeBatch(firestore));
                    batchIndex++;
                    operationCount = 0;
                }
            })  
            //save all products with their isActive edited
            for (let i in batchArray) {
                const batch = batchArray[i]
                await batch.commit()
            }
            return true
        } catch (e){
            const message = `action > products > fetchEditBulkProductsInactiveStatus: Failed to edit bulk products active status for ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchEditBulkProductStockDiscontinuedStatus = (
    isDiscontinued=false,
    productStockIds=[],
    sellerId,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    /**
      * Purpose: discontinue/un-discontinue many product variants at once
      */
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) => {
        try {
            const {user, products, productStock} = getState()
            const batchArray = [writeBatch(firestore)]
            let batchIndex = 0
            let operationCount = 0
            const productStockIdMap = productStockIds.reduce((map, id) => {
                map[id] = true
                return map
            }, {})
            productStockIds.forEach(productStockId => {
                const batch = batchArray[batchIndex]
                const stock = {...productStock.productStockById[productStockId]}
                if (!stock) throw new Error(`Could not find product stock for product stock id ${productStockId}`)
                const product = products.productsById[stock.productId]
                if (!product) throw new Error(`Could not find product for product id ${stock.productId}`)
                if (product.createdBySellerId !== sellerId) throw new Error(`Product ${stock.productId} ${product.titleAndBrand} has a createdBySellerId of ${product.createdBySellerId} by the current seller is ${sellerId}`)
                let isInactive = Boolean(product.isInactive)          
                //if all of the products variants are discontinued, then set the product to inactive  
                if (isDiscontinued){
                    const allVariantsDiscontinued = Object.values(product.stock)
                                                               .every(variant => Boolean(variant.isDiscontinued) || productStockIdMap[variant.id])    
                    if (allVariantsDiscontinued) isInactive = true
                } else {
                    //if all of the variants were previously discontinued
                    //and now, one or more of them is being reinstated
                    //then we assume that the product was inactive because it's variants were all discontinued
                    //so we activate the product
                    const allVariantsPreviouslyDiscontinued = Object.values(product.stock).every(variant => Boolean(variant.isDiscontinued))    
                    if (allVariantsPreviouslyDiscontinued) isInactive = false
                }
                const productEdit = {
                    lastEditedAt: Date.now(),
                    lastEditedByUserId: user.id,
                    isInactive,
                    stock: {
                        [productStockId]: {
                            isDiscontinued,
                            discontinuedMessage: "",
                            quantityInStock: isDiscontinued ? 0 : stock.quantityInStock
                        }
                    }
                }
                const productsRef = doc(firestore, "products", product.id)
                batch.set(productsRef, productEdit, {merge: true})
                operationCount++
                if (operationCount === 499) {
                    batchArray.push(writeBatch(firestore));
                    batchIndex++;
                    operationCount = 0;
                }
            })  
            //save all products with their variants edited
            for (let i in batchArray) {
                const batch = batchArray[i]
                await batch.commit()
            }
            return true
        } catch (e){
            const message = `action > products > fetchEditBulkProductStockDiscontinuedStatus: Failed to edit bulk product variants discontinued status to ${isDiscontinued} for ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}