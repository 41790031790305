import React from "react"
import styles from "./CheckoutDetails.module.css"

import PaymentMethodOption from "../../components/PaymentMethodOption"
import PaypalCheckout from "../../components/PaypalCheckout"
import MobankingModal from "../MobankingModal"
import WireTransferPaymentModal from "../../components/WireTransferPaymentModal"
import PayInstoreModal from "../../components/PayInstoreModal"
import CashOnDeliveryModal from "../../components/CashOnDeliveryModal"
import ShopdmCreditPaymentModal from "../ShopdmCreditPaymentModal"
import JadPaymentModal from "../JadPaymentModal"
import FacCardPaymentModal from "../FacCardPaymentModal"

import AnnouncementBanner from "../../banners/AnnouncementBanner"
import DisabledButton from "../../components/DisabledButton"

import CheckoutUserAddress from "../CheckoutUserAddress"
import CheckoutGifting from "../CheckoutGifting"
import CheckoutCredit from "../CheckoutCredit"
import CheckoutOrderNotes from "../CheckoutOrderNotes"
import CheckoutDeliverySlot from "../CheckoutDeliverySlot"

import DeliveryOptions from "../DeliveryOptions"
import {PAYPAL_IDENTIFIER, IN_STORE_IDENTIFIER, WIRE_TRANSFER_ID, PAYPAL_ID} from "../../constants/payment"
import {PICK_UP_INSTORE, SELF_DELIVERY, THIRD_PARTY_DELIVERY, SELF_DELIVERY_ID, PICK_UP_INSTORE_ID} from "../../constants/delivery"
import {OPENED, SHOPDM_CONFIRMED_PAYMENT, CUSTOMER_SELECTED_PAY_INSTORE} from "../../constants/order"
import {
    PAYMENT_OBJECT_TYPE_ORDER
} from "../../constants/payment"
import {
    PAYMENT_CLAIM_STATUS_CREATED
} from "../../constants/paymentClaims"
import {withRouter} from "react-router-dom"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {v4 as uuid4} from 'uuid'
import currency from 'currency.js'
import {xcdToPaypalUsd} from "../../utils/currencyUtils"
import {capitalizeAllWords, addressObjectToString, capitalize, alphabeticalSort} from "../../utils/stringUtils"
import {getDeliveryProviderFee} from "../../utils/paymentUtils"
import {calculatePromotionalPrice} from "../../utils/promotions"

import {logAnalyticsEvent} from "../../config/firebase"
import { logError } from "../../utils/errorHandlingUtils"
import {ecommerceEvents} from "../../constants/firebase"
import {JAD_CARD_PAYMENTS_ID, DEBIT_OR_CREDIT_CARD_ID, NBD_MOBANKING_ID} from "../../constants/payment"

import DeliveryMan from "../../images/delivery-man.png"
import CreditCard from "../../images/credit-card.png"

const CASH_ON_DELIVERY = '--cash-on-delivery--'
class CheckoutDetails extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            isPickup: false,
            showOrderOptions: true,
            showMobankingModal: false,
            showPayInstoreModal: false,
            showCashOnDeliveryModal: false, 
            showCardModal: false,
            showJadCardModal: false,
            showWireTransferModal: false,
            showShopdmCreditModal: false
        }
    }

    componentDidMount(){
        const {actions, paymentProviders, cart, products} = this.props
        const excludedPaymentProviders = this.findPaymentProvidersExcludedBySellersInCart()
        if (!cart.payWithShopdmCredit){
            //TODO fix, way too simple to only check whether payWithShopdmCredit is selected
            let selectedPaymentProviderId = cart.selectedPaymentProviderId
            const paymentProviderIds = [
                DEBIT_OR_CREDIT_CARD_ID, //ensures debit or credit card is always the first choice
                NBD_MOBANKING_ID,
                ...paymentProviders.paymentProviderIds
            ]
            paymentProviderIds.forEach(paymentProviderId => {
                if (selectedPaymentProviderId && !excludedPaymentProviders[selectedPaymentProviderId]) return
                if (excludedPaymentProviders[paymentProviderId]) return  
                selectedPaymentProviderId = paymentProviderId
                actions.selectPaymentProvider(paymentProviderId);
            })
        }
        //google analytics
        if (cart.itemIds.length > 0 ){
            const analyticsObject = cart.itemIds.reduce((analyticsObject, itemId) => {
                const item = cart.itemsByProductStockId[itemId]
                const product = products.productsById[item.productId]
                if (!item || !product) return analyticsObject
                analyticsObject.items.push({...product.analyticsItem, quantity: item.quantity, stockId: item.id, stockPrice: item.price})
                analyticsObject.value += item.price * item.quantity
                return analyticsObject
            }, {items: [], currency: "XCD", value: 0})
            logAnalyticsEvent(ecommerceEvents.BEGIN_CHECKOUT, analyticsObject);
            actions.fetchLogDeviceSessionAction({
                action: "beginCheckout",
                cart: analyticsObject
            })
        } 
    }
    
    handleOpenPaymentModal = async () => {
        const {actions, paymentProviders, cart, providerFees, shopdmCreditUsed, user} = this.props
        const payTotalWithShopdmCredit = shopdmCreditUsed >= providerFees
        const paymentProvider = paymentProviders.paymentProvidersById[cart.selectedPaymentProviderId]
        logAnalyticsEvent("press_place_order_button")
        actions.toggleLoading(true)
        const successfullyPlaced = await this.handlePlaceOrder()
        actions.toggleLoading(false)
        if (!successfullyPlaced) {
            logError(`Failed to place order paid via ${paymentProvider.name} being made by customer ${user.firstName} ${user.lastName} ${user.email} ${user.addressesById[user.defaultAddressId].contactNumber}`)
            alert(`Something went wrong when we tried to place your order. Try refreshing this page, checking your internet connection or logging into another device to place the order.\n\nYou can then try paying again with ${paymentProvider.name} or another payment method.\n\nWe're very sorry for the inconvenience. If the problem continues, please Whatsapp the Shopdm team at +17676128202 and our technical team will be very happy to assist you :)`)
            return false
        }
        const showModal = payTotalWithShopdmCredit ?
                            'showShopdmCreditModal'
                            :
                          paymentProvider.name.includes(IN_STORE_IDENTIFIER) ?
                            'showPayInstoreModal'
                            :
                          paymentProvider.id === CASH_ON_DELIVERY ?
                          'showCashOnDeliveryModal'
                          :
                          paymentProvider.id === DEBIT_OR_CREDIT_CARD_ID ?
                            'showCardModal'
                            :
                          paymentProvider.id === JAD_CARD_PAYMENTS_ID ?
                            'showJadCardModal'
                            :
                          paymentProvider.id === WIRE_TRANSFER_ID ?
                            'showWireTransferModal'
                            :
                            'showMobankingModal'

        this.setState({[showModal]: true})
    }

    handlePlaceOrder = async () => {
        const {
            actions, paymentProviders,user, cart, total, 
            paymentProcessorTotal, shopdmCreditOwed, shopdmCreditUsed,
    
            processingFee, deliveryFee, giftFee, paymentProcessorFee, subTotal, 
            sellers, deliveryProviders, products, orders,
            shopdmCommissionEarningsXcd, shopdmDeliveryEarningsXcd, 
            shopdmGiftingEarningsXcd, shopdmTotalEarningsXcd,
            appliedPromotions, promotions
        } = this.props
        let paymentProvider = paymentProviders.paymentProvidersById[cart.selectedPaymentProviderId]
        paymentProvider = paymentProvider ? paymentProvider : null
        logAnalyticsEvent("place_order")
        const customerWalletId = (cart.payWithShopdmCredit || shopdmCreditOwed) && user.defaultWalletId?
                                 user.defaultWalletId :
                                 ""
        actions.fetchLogDeviceSessionAction({
            action: "placeOrder",
        })
        const selectedAddress = user.addressesById[user.defaultAddressId]
        
        const orderItemsById = Object.values(cart.itemsByProductStockId).reduce((orderItems, item) => {
            const product = products.productsById[item.productId]
            let price = item.price
            const originalPrice = price
            //check whether any promotions have been applied and if so, change the price
            const promotionId = appliedPromotions[item.id] ? appliedPromotions[item.id] : ""
            if (promotionId){
                const promotion = promotions.promotionsById[promotionId]
                price = calculatePromotionalPrice(price, promotion)
            }
            orderItems[item.id] = {...product, ...item, price, originalPrice, promotionId}
            return orderItems
        }, {})
        const promotionIds = Object.values(appliedPromotions).reduce((map, id) => {
            map[id] = true
            return map
        }, {})
        const sellersById = {}
        const deliveryProvidersById = {}
        Object.values(orderItemsById).forEach(item => {
            if (!sellersById[item.sellerId]) {
                const seller = {...sellers.sellersById[item.sellerId]}
                delete seller['roles']
                const deliveryProviderId =  cart.selectedDeliveryProviderIdsBySellerId[seller.id]
                const deliveryProvider = {...deliveryProviders.deliveryProvidersById[deliveryProviderId]}
                let pickupAddressId
                let deliveryMethod
                const isSelfDelivery = deliveryProvider.id === SELF_DELIVERY_ID
                if (deliveryProvider.name.includes(IN_STORE_IDENTIFIER)){
                    pickupAddressId = cart.selectedPickupAddressIdBySellerId[seller.id]
                    deliveryMethod = PICK_UP_INSTORE
                } else {
                    pickupAddressId = seller.defaultAddressId
                    deliveryMethod = isSelfDelivery ? SELF_DELIVERY : THIRD_PARTY_DELIVERY
                }
                deliveryProvider.address = seller.addressesById[pickupAddressId]
                const selfDeliveryFee = isSelfDelivery ? Number(seller.selfDelivery.fixedFee) : 0
                //if not self delivery, consolidate if needed
                //consolidation happens if the delivery provider is already included and they offer consolidation
                const consolidate = deliveryProvidersById[deliveryProviderId] && deliveryProvider.consolidation
                const deliveryFeeXcd = isSelfDelivery ? selfDeliveryFee : getDeliveryProviderFee(deliveryProvider, selectedAddress, consolidate)
                sellersById[item.sellerId] = {
                    ...seller,
                    itemIds: [],
                    pickupAddress: addressObjectToString(seller.addressesById[pickupAddressId], true),
                    deliveryProviderId,
                    deliveryMethod,
                    isSelfDelivery,
                    selfDeliveryFee,
                    deliveryFeeXcd,
                    sellerReadinessEstimation: cart.sellerReadinessEstimationsBySellerId ? 
                                                cart.sellerReadinessEstimationsBySellerId[seller.id]
                                                :
                                                {}
                }
                //add the delivery estimations and slots to the 
                deliveryProvidersById[deliveryProviderId] = {
                    ...deliveryProvider,
                    deliverySlotIds: cart.deliverySlotIdsByDeliveryProviderId ?
                                      Object.keys(cart.deliverySlotIdsByDeliveryProviderId[deliveryProviderId])
                                      :
                                      [] 
                }
            }
            sellersById[item.sellerId].itemIds.push(item.id)
        })
        const deliverySlotsById = cart.deliverySlotsById ? 
                                    Object.values(cart.deliverySlotsById)
                                          .reduce((map, deliverySlot) => {
                                              const sellerIds = Object.keys(cart.deliverySlotIdsBySellerId)
                                                                      .reduce((map, sellerId) => {
                                                  if (cart.deliverySlotIdsBySellerId[sellerId] === deliverySlot.id) map[sellerId] = true
                                                  return map
                                              }, {})
                                              map[deliverySlot.id] = {
                                                  ...deliverySlot,
                                                  deliveryFulfillmentEstimation: {...cart.deliveryFulfillmentEstimationsByDeliverySelectionId[deliverySlot.id]},
                                                  sellerIds
                                              }
                                              return map
                                          }, {})
                                    :
                                    {}

        //if an order has already been created, return the existing order id
        if (cart.currentOrderId) {
            const success = await actions.fetchUpdateOrder(
                cart.currentOrderId,
                user.id,
                user.email,
                `${capitalize(user.firstName)} ${capitalize(user.lastName)}`,
                user.profileImageUrlMed ? user.profileImageUrlMed : "",
                addressObjectToString(selectedAddress, true),
                selectedAddress.contactNumber,
                customerWalletId,
                Boolean(user.unsubscribed),
                Object.keys(sellersById),
                sellersById,
                Object.keys(deliveryProvidersById),
                deliveryProvidersById,
                deliverySlotsById,
                paymentProvider,
                total.toFixed(2),
                paymentProcessorTotal.toFixed(2),
                shopdmCreditUsed.toFixed(2),
                shopdmCreditOwed.toFixed(2),
                processingFee.toFixed(2),
                paymentProcessorFee.toFixed(2),
                deliveryFee.toFixed(2),
                subTotal.toFixed(2),
                shopdmCommissionEarningsXcd.toFixed(2),
                shopdmDeliveryEarningsXcd.toFixed(2),
                shopdmGiftingEarningsXcd.toFixed(2),
                shopdmTotalEarningsXcd.toFixed(2),
                orderItemsById,
                cart.giftParameters ? cart.giftParameters : null,
                giftFee.toFixed(2),
                cart.orderNotes ? cart.orderNotes : "",
                cart.id,
                promotionIds,
                () => console.log("order all good"),
                ()=> console.warn("order could not be created")
            )
            if (success){
                const order = orders.ordersById[cart.currentOrderId]
                return order.orderNumber
            } else return false
        } else {
            return await actions.fetchCreateOrder(
                user.id,
                user.email,
                `${capitalize(user.firstName)} ${capitalize(user.lastName)}`,
                user.profileImageUrlMed ? user.profileImageUrlMed : "",
                addressObjectToString(selectedAddress, true),
                selectedAddress.contactNumber,
                customerWalletId,
                Boolean(user.unsubscribed),
                Object.keys(sellersById),
                sellersById,
                Object.keys(deliveryProvidersById),
                deliveryProvidersById,
                deliverySlotsById,
                paymentProvider,
                total.toFixed(2),
                paymentProcessorTotal.toFixed(2),
                shopdmCreditUsed.toFixed(2),
                shopdmCreditOwed.toFixed(2),
                processingFee.toFixed(2),
                paymentProcessorFee.toFixed(2),
                deliveryFee.toFixed(2),
                subTotal.toFixed(2),
                shopdmCommissionEarningsXcd.toFixed(2),
                shopdmDeliveryEarningsXcd.toFixed(2),
                shopdmGiftingEarningsXcd.toFixed(2),
                shopdmTotalEarningsXcd.toFixed(2),
                orderItemsById,
                cart.giftParameters ? cart.giftParameters : null,
                giftFee.toFixed(2),
                cart.orderNotes ? cart.orderNotes : "",
                cart.id,
                promotionIds,
                OPENED,

                () => console.log("order all good"),
                ()=> console.warn("order could not be created")
            )
        }
    }

    handleCreatePaypalOrder = async () => {
        this.setState({showOrderOptions: false})
        const orderNumber = await this.handlePlaceOrder();
        if (!orderNumber) throw new Error("Could not create order")
        else return orderNumber
    }

    handleCancelledPaypalPayment = () => this.setState({showOrderOptions: true})

    handleFailedPaypalPayment = () => {
        const {user} = this.props
        logError(`Failed to place Paypal order being made by customer ${user.firstName} ${user.lastName} ${user.email} ${user.addressesById[user.defaultAddressId].contactNumber}`)
        this.setState({showOrderOptions: true})
    }

    handleCompletePaypalPayment = async (paypalReference,amountInCurrency, currencyCode) => {
        const {actions, cart, user, device, paymentProviders, orders} = this.props
        const paymentProvider = paymentProviders.paymentProvidersById[cart.selectedPaymentProviderId]
        const order = orders.ordersById[cart.currentOrderId]
        const paymentProcessorAmount2DecimalPlaces = !order ? 0 :
                                                    order.paymentProcessorTotalXcd ?
                                                    order.paymentProcessorTotalXcd :
                                                    order.totalXcd
        actions.toggleLoading(true)
        const successful = await actions.fetchTrackPaypalPayment(
            uuid4(),
            paymentProcessorAmount2DecimalPlaces,
            amountInCurrency,
            currencyCode,
            cart.currentOrderId,
            PAYMENT_OBJECT_TYPE_ORDER,
            order.orderNumber,
            paymentProvider.id,
            paymentProvider.name,
            paypalReference,
            user.id,
            device.id
        )
        //do not remove loading screen until transition occurs or after an error
        if (!successful){
            actions.toggleLoading(false)
        }
    }
    handleCompletePayment = async (status = CUSTOMER_SELECTED_PAY_INSTORE) => {
        const {actions, cart} = this.props
        actions.toggleLoading(true)
        const successful = await actions.fetchUpdateOrderStatus(cart.currentOrderId, status)
        actions.toggleLoading(false)
        if (!successful){
            actions.toggleLoading(false)
            alert("Something went wrong, please try again")
        }
    }

    handleCreatePaymentClaim = async (imageFile = null) => {
        const {actions, cart, user, paymentProviders, orders} = this.props
        const paymentProvider = paymentProviders.paymentProvidersById[cart.selectedPaymentProviderId]
        const order = orders.ordersById[cart.currentOrderId]
        actions.toggleLoading(true)
        const successfullyCreated = await actions.fetchCreatePaymentClaim(
            uuid4(),
            paymentProvider.id,
            paymentProvider.name,
            order.paymentProcessorTotalXcd,
            order.paymentProcessorFeeXcd,
            PAYMENT_OBJECT_TYPE_ORDER,
            order.id,
            order.orderNumber,
            PAYMENT_CLAIM_STATUS_CREATED,
            user.id,
            capitalizeAllWords(`${user.firstName} ${user.lastName}`),
            user.defaultAddressId && user.addressesById? user.addressesById[user.defaultAddressId].contactNumber : "",
            user.email,
            imageFile
        )
        if (!successfullyCreated) {
            //if something stopped the payment claim to be made   
            actions.toggleLoading(false)
            alert(`Something went wrong. Could not make payment claim`)
        } //else if the payment claim was made successfully, leave the loading screen going 
    }
    findPaymentProvidersExcludedBySellersInCart = () => {
        const {cart, sellers, paymentProviders} = this.props 
        const excludedPaymentProviders = {}
        //indetify the payment providers not supported by any seller in the cart
        Object.keys(cart.itemIdsBySellerId).forEach(sellerId => {
            const seller = sellers.sellersById[sellerId]
            paymentProviders.paymentProviderIds.forEach(paymentProviderId => {
                if (seller.supportedPaymentProviders.includes(paymentProviderId)) return
                const paymentProvider = paymentProviders.paymentProvidersById[paymentProviderId]
                excludedPaymentProviders[paymentProviderId] = `${seller.name} does not support ${paymentProvider.name} payments. Choose a different payment method or buy these products separately`
            })
        })
        return excludedPaymentProviders
    } 

    handleClosePaymentModal = () => this.setState({
        showMobankingModal: false, 
        showPayInstoreModal: false,
        showCashOnDeliveryModal: false, 
        showCardModal: false, 
        showJadCardModal: false,
        showWireTransferModal: false,
        showShopdmCreditModal: false
    })

    hasDeliveryProviderForEachSeller = () => {
        const {cart, deliveryProviders} = this.props
        return Object.keys(cart.itemIdsBySellerId).every(sellerId => {
            const deliveryProviderId = cart.selectedDeliveryProviderIdsBySellerId[sellerId]
            if (!deliveryProviderId) return false
            const deliveryProvider = deliveryProviders.deliveryProvidersById[deliveryProviderId]
            //if pickup instore is selected, but no pickup location is selected 
            if (deliveryProvider.name.includes(IN_STORE_IDENTIFIER) && !cart.selectedPickupAddressIdBySellerId[sellerId]) return false
            return true
        })
    }
    render(){
        const excludedPaymentProviders = this.findPaymentProvidersExcludedBySellersInCart()
        const {isPickup,showOrderOptions, showMobankingModal, showPayInstoreModal, showCashOnDeliveryModal, showCardModal, showJadCardModal, showWireTransferModal, showShopdmCreditModal} = this.state
        const {
            user, 
            paymentProviders, 
            deliveryProviders, 
            sellers, 
            total, 
            paymentProcessorTotal,
            cart, 
            actions, 
            orders, 
            appliedPromotions,
            shopdmCreditUsed,
            shopdmCreditOwed,
            totalIfCreditUsed,
            providerFees,
            creditWallets,
            device
        } = this.props
        const {giftParameters} = cart
        const paymentProvider = cart.selectedPaymentProviderId ? 
                                paymentProviders.paymentProvidersById[cart.selectedPaymentProviderId]
                                :
                                null
        const payTotalWithShopdmCredit = shopdmCreditUsed >= providerFees
        const order = cart.currentOrderId ? orders.ordersById[cart.currentOrderId] : null
        const instructionsList = []
        let instructions = Object.keys(cart.itemIdsBySellerId).reduce((instructions, sellerId) => {
            //ensure every seller has a delivery provider
            const deliveryProviderId = cart.selectedDeliveryProviderIdsBySellerId[sellerId]
            const deliveryProvider = deliveryProviders.deliveryProvidersById[deliveryProviderId]
            const seller = sellers.sellersById[sellerId]
            if (!seller) return instructions
            const sellerName = capitalizeAllWords(seller.name)
            //ensure every delivery provider has a slot
            const deliverySlotIdMap = cart.deliverySlotIdsByDeliveryProviderId[deliveryProviderId]
            let addedMessage = ""
            //1. make an error message if there is not delivery provider chosen
            if (!deliveryProvider){
                addedMessage = `Tell us who should deliver your items from  ${sellerName}`
            }
            //2. make an error msg if it is pickup instore but a location is not chosen
            else if (
                deliveryProvider.id === PICK_UP_INSTORE_ID &&
                !cart.selectedPickupAddressIdBySellerId[sellerId]
            ){
                addedMessage = `Tell us where you want to pick up your items from ${sellerName}`
            }
            //3. make an error message if the delivery provider has no delivery slot selected
            else if (
                !deliverySlotIdMap ||
                Object.keys(deliverySlotIdMap).length === 0
            ){
                addedMessage = `Tell us when you want${deliveryProvider.id === PICK_UP_INSTORE_ID ? " to pick up": ""} your items from ${sellerName} ${deliveryProvider.id !== PICK_UP_INSTORE_ID ? "delivered": ""}`
            }
            if (addedMessage){
                instructions = instructions ? 
                    `${instructions} and ${addedMessage.toLowerCase()}`
                    :
                    addedMessage
                    instructionsList.push(<li key={addedMessage}>{addedMessage}</li>)
            }
            return instructions
        }, "")
        if (!paymentProvider && !payTotalWithShopdmCredit) {
            const instructionMessage = `Tell us how you want to pay for your items`
            instructions = instructions ? `${instructions} and ${instructionMessage.toLowerCase()}` : instructionMessage
            instructionsList.push(<li key={instructionMessage}>{instructionMessage}</li>)
        }
        //if it is a gift, 
        let giftParametersValid = true
        if (giftParameters){
            if (!giftParameters.contactNumber || giftParameters.contactNumber.length < 7){
                giftParametersValid = false
                const instructionMessage = "Give us a valid phone number to contact you about your gift"
                instructions = instructions ? `${instructions} and ${instructionMessage.toLowerCase()}` : instructionMessage
                instructionsList.push(<li key={instructionMessage}>{instructionMessage}</li>)
            }
            if (!giftParameters.recipientName){
                giftParametersValid = false
                const instructionMessage = "Give us the name of your gift recipient"
                instructions = instructions ? `${instructions} and ${instructionMessage.toLowerCase()}` : instructionMessage
                instructionsList.push(<li key={instructionMessage}>{instructionMessage}</li>)
            }
        }
        const wallet = creditWallets.creditWalletsById[user.defaultWalletId]
        const paymentButtonText = `Place Order And ${ payTotalWithShopdmCredit? 
                                                     `Use EC $${providerFees.toFixed(2)} Of Shopdm Credit` : `Pay EC $${paymentProcessorTotal.toFixed(2)}`}`
        const paymentProcessorAmount2DecimalPlaces = !order ? 0 :
                                                    order.paymentProcessorTotalXcd ?
                                                    order.paymentProcessorTotalXcd :
                                                    order.totalXcd
        //particularly for JAD since it applies its fee on top of the amoutn we supply
        const paymentProcessorAmountLessPaymentProcessorFee2DecimalPlaces = !order ? 0 : currency(paymentProcessorAmount2DecimalPlaces - order.paymentProcessorFeeXcd).value.toFixed(2) 
        const sellerList = Object.keys(cart.itemIdsBySellerId)
                                .sort((sellerAId, sellerBId) => {
                                    const sellerA = sellers.sellersById[sellerAId]
                                    const sellerB = sellers.sellersById[sellerBId]
                                    if (!sellerA || !sellerB) return 0
                                    return alphabeticalSort(sellerA.name, sellerB.name)
                                })
        return (
            <div className={styles.container}>
                {
                    showOrderOptions ?
                    <React.Fragment>
                        <div className="formSection">
                            <div className={`instructionTitle`}>Checkout in <span className="badge outline">6</span> steps</div>
                            <CheckoutUserAddress />
                        </div>
                        <div className="formSection">
                            <CheckoutGifting />
                        </div>
                        <div className="formSection">
                            <div className={`strong checkoutTitle space-bottom`}>
                                <span className="badge outline">3</span>
                                <img src={DeliveryMan}/>
                                <div>Who Should Deliver Your Items{sellerList.length > 1 ? <span> From The <span className="infoHighlight">{sellerList.length} Stores</span></span>: ""}?</div>
                            </div>
                            <div>
                                {
                                    sellerList.map((sellerId, i) => <DeliveryOptions 
                                                                        key={sellerId} 
                                                                        sellerId={sellerId} 
                                                                        appliedPromotions={appliedPromotions} 
                                                                        index={i}
                                                                    />
                                    )
                                }
                            </div>
                        </div>
                        <div className="formSection">
                            <CheckoutDeliverySlot />
                        </div>
                        <div className="formSection">
                            <CheckoutOrderNotes />
                        </div>
                        <div className="formSection">
                            <div className={'strong checkoutTitle space-bottom'}><span className="badge outline">6</span><img src={CreditCard}/>Pay With</div>
                            <CheckoutCredit 
                                shopdmCreditOwed={shopdmCreditOwed}
                                totalIfCreditUsed={totalIfCreditUsed}
                                providerFees={providerFees}
                                total={total}
                            />
                            {
                                //if the user is not paying with Shopdm Credit or
                                //the user does not have a wallet with a balance that is enough to cover the provider fees
                                //then show the usual payment methods
                                !cart.payWithShopdmCredit || 
                                !wallet || 
                                !(wallet.balance >= providerFees) //provider fees include gifting, delivery fees and discounted product subtotal
                                 ? 
                                <div>
                                    <p>
                                        Choose a payment processor to pay {wallet && wallet.balance !== 0 ? `EC $${paymentProcessorTotal.toFixed(2)}` : ""}
                                    </p>
                                    {/* {Date.now() < 1696905103000 ?<AnnouncementBanner preMessage="Use Paypal's card option for card payments. You can also pay using your Paypal Wallet, NBD Mobanking or your Shopdm Credit"/> : null} */}
                                    <div className={["checkoutField flex"].join(" ")}>
                                        {
                                            Object.values(paymentProviders.paymentProvidersById)
                                                  .sort((pA, pB) => alphabeticalSort(pA.name, pB.name))
                                                  .map( paymentProvider => {
                                                      const paymentProviderId = paymentProvider.id
                                                let invalidText = excludedPaymentProviders[paymentProviderId] ?
                                                                    excludedPaymentProviders[paymentProviderId]
                                                                    :
                                                                    // TODO remove to activate JAD card payments
                                                                    paymentProviderId === JAD_CARD_PAYMENTS_ID ?
                                                                    "Upgrade In Progress"
                                                                    :
                                                                    ""
                                                //if this is the pay in store option
                                                if (paymentProvider.name.includes(IN_STORE_IDENTIFIER)){
                                                    if (
                                                        !Object.values(cart.selectedDeliveryProviderIdsBySellerId).every(deliveryProviderId => {
                                                            const deliveryProvider = deliveryProviders.deliveryProvidersById[deliveryProviderId]
                                                            return deliveryProvider && deliveryProvider.name.includes(IN_STORE_IDENTIFIER)
                                                        })           
                                                    ){
                                                        invalidText = "To pay via cash on pickup you must pick up all items yourself"
                                                        if (cart.selectedPaymentProviderId === paymentProviderId ){
                                                            actions.deselectPaymentProvider();
                                                        }
                                                    }
                                                }
                                                if (paymentProvider.id === CASH_ON_DELIVERY){
                                                    if (
                                                        Object.values(cart.selectedDeliveryProviderIdsBySellerId).some(deliveryProviderId => {
                                                            const deliveryProvider = deliveryProviders.deliveryProvidersById[deliveryProviderId]
                                                            return deliveryProvider && deliveryProvider.name.includes(IN_STORE_IDENTIFIER)
                                                        })           
                                                    ){
                                                        invalidText = "You cannot pay cash on delivery if you are picking up some items"
                                                        if (cart.selectedPaymentProviderId === paymentProviderId ){
                                                            actions.deselectPaymentProvider();
                                                        }
                                                    } else if (!user.cashOnDelivery){
                                                        invalidText = "You cannot pay with cash on delivery"
                                                        if (cart.selectedPaymentProviderId === paymentProviderId ){
                                                            actions.deselectPaymentProvider();
                                                        }
                                                    }
                                                }
                                                //TODO improve the text probably only show when there are multiple sellers
                                                if (invalidText) return
                                                const handleChangePaymentProvider = () => {
                                                    logAnalyticsEvent("select_payment_provider", {name: paymentProvider.name})
                                                    actions.fetchLogDeviceSessionAction({
                                                        action: "selectPaymentProvider",
                                                        name: paymentProvider.name
                                                    })
                                                    actions.selectPaymentProvider(paymentProviderId)
                                                }
                                                return <PaymentMethodOption 
                                                            key={paymentProviderId}
                                                            onSelect={handleChangePaymentProvider}
                                                            logo={paymentProvider.logo}
                                                            name={paymentProvider.displayNameWithLogo ? paymentProvider.name : ""}
                                                            checked={cart.selectedPaymentProviderId === paymentProviderId}
                                                            isValid={!Boolean(invalidText)}
                                                            invalidText={invalidText}
                                                        />
                                            })
                                        }
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </React.Fragment>
                    :
                    null
                }
                <div className={`${styles.checkoutButton} formSection`}>
                {
                    paymentProvider && 
                    paymentProvider.name.includes(PAYPAL_IDENTIFIER) && 
                    !instructions?
                    <PaypalCheckout 
                        email={user.email}
                        amount={xcdToPaypalUsd(paymentProcessorTotal)}
                        createOrder={this.handleCreatePaypalOrder}
                        onFailure={this.handleFailedPaypalPayment}
                        onCancel={this.handleCancelledPaypalPayment}
                        onSuccess={this.handleCompletePaypalPayment}
                    />
                    :
                    (paymentProvider || payTotalWithShopdmCredit) && 
                    !instructions?
                    <button 
                        className={["button", "primary"].join(" ")}
                        onClick={this.handleOpenPaymentModal}
                    >
                        {paymentButtonText}
                    </button>
                    :
                    <div className={styles.disabledButtonContainer}>
                        <DisabledButton
                            alert={instructions}
                            displayTime={2000}
                        >
                            {paymentButtonText}
                        </DisabledButton>
                        <ul className={styles.instructionsList}>
                            {instructionsList}    
                        </ul>
                    </div>
                }
                </div>
                { 
                    showMobankingModal && order? 
                    <MobankingModal 
                        amount2DecimalPlaces={paymentProcessorAmount2DecimalPlaces} 
                        userName={capitalizeAllWords(`${user.firstName} ${user.lastName}`)}
                        reference={order.orderNumber} 
                        objectType={PAYMENT_OBJECT_TYPE_ORDER}
                        isOpen={showMobankingModal} 
                        closeModal={this.handleClosePaymentModal}
                        onCompletePayment={this.handleCreatePaymentClaim}
                    /> 
                    :
                    showPayInstoreModal && order ?
                    <PayInstoreModal 
                       email={user.email}
                       isOpen={showPayInstoreModal}
                       amount2DecimalPlaces={paymentProcessorAmount2DecimalPlaces}
                       logo={paymentProvider.logo}
                       reference={order.orderNumber}
                       objectType={PAYMENT_OBJECT_TYPE_ORDER}
                       closeModal={this.handleClosePaymentModal} 
                       onPressConfirm={() => this.handleCompletePayment(CUSTOMER_SELECTED_PAY_INSTORE)}
                    />
                    :
                    showCashOnDeliveryModal && order ?
                    <CashOnDeliveryModal
                       email={user.email}
                       isOpen={showCashOnDeliveryModal}
                       amount2DecimalPlaces={paymentProcessorAmount2DecimalPlaces}
                       logo={paymentProvider.logo}
                       reference={order.orderNumber}
                       objectType={PAYMENT_OBJECT_TYPE_ORDER}
                       closeModal={this.handleClosePaymentModal} 
                       onPressConfirm={() => this.handleCompletePayment(SHOPDM_CONFIRMED_PAYMENT)}
                    />
                    :
                    showCardModal && order ?
                    <FacCardPaymentModal 
                        userId={user.id}
                        deviceId={device.id}
                        userName={`${user.firstName} ${user.lastName}`}
                        email={user.email}
                        phoneNumber={ order.giftParameters ? order.giftParameters.contactNumber : order.customerPhone}
                        objectId={order.id}
                        objectType={PAYMENT_OBJECT_TYPE_ORDER}
                        reference={order.orderNumber}
                        paymentProviderId={paymentProvider.id}
                        isOpen={showCardModal}
                        amount2DecimalPlaces={paymentProcessorAmount2DecimalPlaces}
                        closeModal={this.handleClosePaymentModal} 
                    />
                    :
                    showJadCardModal && order ?
                    <JadPaymentModal 
                        userId={user.id}
                        device={device.id}
                        objectId={order.id}
                        objectType={PAYMENT_OBJECT_TYPE_ORDER}
                        reference={order.orderNumber}
                        paymentProviderId={paymentProvider.id}
                        isOpen={showJadCardModal}
                        amount2DecimalPlaces={paymentProcessorAmount2DecimalPlaces}
                        amountLessPaymentCharge2DecimalPlaces={paymentProcessorAmountLessPaymentProcessorFee2DecimalPlaces}
                        closeModal={this.handleClosePaymentModal} 
                    />
                    :
                    showWireTransferModal && order ?
                    <WireTransferPaymentModal 
                       isOpen={showWireTransferModal}
                       amount2DecimalPlaces={paymentProcessorAmount2DecimalPlaces}
                       logo={paymentProvider.logo}
                       reference={order.orderNumber}
                       objectType={PAYMENT_OBJECT_TYPE_ORDER}
                       closeModal={this.handleClosePaymentModal} 
                       onPressConfirm={this.handleCreatePaymentClaim}
                       disclaimerText="and allow your order to be processed"
                    />
                    :
                    showShopdmCreditModal && order && shopdmCreditUsed >= providerFees? 
                    <ShopdmCreditPaymentModal 
                       isOpen={showShopdmCreditModal}
                       amount2DecimalPlaces={Number(shopdmCreditUsed).toFixed(2)}
                       orderId={order.id}
                       orderNumber={order.orderNumber}
                       walletId={order.customerWalletId}
                       closeModal={this.handleClosePaymentModal} 
                    />
                    : null 
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    device: state.device,
    products: state.products,
    paymentProviders: state.paymentProviders,
    deliveryProviders: state.deliveryProviders,
    sellers: state.sellers,
    orders:state.orders,
    cart: state.cart,
    promotions: state.promotions,
    creditWallets: state.creditWallets
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckoutDetails));