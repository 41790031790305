import React from 'react'

function DataSelect ({dataArray=[], label="name", onSelect=()=>{}, selected="", size=null, defaultText="", allowBlank=true, selectMultiple=false, transformFunction= value => value}){
    const getOptions = () =>{
        return dataArray.map(data => {
            return <option key={data.id} value={data.id}>{transformFunction(data[label])}</option>
        })
    }
    return <select 
                className="select"
                value={selected} 
                onChange={e => onSelect(e.target.value)}
                size={size? size : null}
                multiple={selectMultiple}
            >
                { allowBlank ? <option>{transformFunction(defaultText)}</option> : null}
                {getOptions()}
            </select>
}

export default DataSelect