import React, {useState, useRef, useEffect} from "react"
import { useInView } from 'react-intersection-observer';
import styles from "./WindowedList.module.css"
import { useDebouncedCallback } from "use-debounce";
const WindowedList = ({
    list=[], 
    startAt=0, 
    windowSize=10, 
    containerStyle="", 
    jumpTo=null,
    renderItem=()=><div></div>,
    getKeyFromItem=item => item && item.id ? item.id : "",
    getIdFromItem=item => item && item.id ? item.id : "",
    onItemScrolledIntoView=itemId => itemId,
    isVisibleThreshold=0.6
}) => {
    //used to track whether the position rendered is the absolute first position
    const startPositionChanged = useRef(false)
    const debouncedOnItemScrolledIntoView = useDebouncedCallback((value) => {
        onItemScrolledIntoView(value);
    }, 200);
    const handleItemScrolledIntoView = (itemId, debounce=true) => {
        //track whether the start position has ever changed
        startPositionChanged.current = true
        //avoids infinite loops due to fast updates
        if (debounce) debouncedOnItemScrolledIntoView(itemId)
        else onItemScrolledIntoView(itemId)
    } 
    const halfWindow = Math.floor(windowSize/2)
    const getStartAndEnd = (startAt=0) => {
        const windowStart = startAt === 0 ? 
                            startAt
                            :
                            Math.max(startAt - halfWindow, 0) 
        const windowEnd = startAt === 0 ? 
                            Math.min(windowSize, list.length)
                            : 
                            Math.min(startAt + halfWindow, list.length)
        return ({
            windowStart,
            windowEnd
        })
    }
    //get the initial starting window
    const initialStartAndEnd = getStartAndEnd(startAt)
    const [windowStart, setWindowStart] = useState(initialStartAndEnd.windowStart)
    const [windowEnd, setWindowEnd] = useState(initialStartAndEnd.windowEnd)
    const [jumpTarget, setJumpTarget] = useState(null)
    //get the window of items
    const slideWindowDown = prevWindowEnd => {
        const newStartAndEnd = getStartAndEnd(prevWindowEnd)
        const newWindowEnd = newStartAndEnd.windowEnd
        const newWindowStart = newStartAndEnd.windowStart
        console.log(`sliding window DOWN. Start goes from ${windowStart} to ${newWindowStart}. End goes from ${windowEnd} to ${newWindowEnd}`)
        setWindowStart(newWindowStart)
        setWindowEnd(newWindowEnd)
    }
    const slideWindowUp = prevWindowStart => {
        const newStartAndEnd = getStartAndEnd(prevWindowStart)
        const newWindowEnd = newStartAndEnd.windowEnd
        const newWindowStart = newStartAndEnd.windowStart
        console.log(`sliding window UP. Start goes from ${windowStart} to ${newWindowStart}. End goes from ${windowEnd} to ${newWindowEnd}`)
        setWindowStart(newWindowStart)
        setWindowEnd(newWindowEnd)
    }
    /**
     * allow jumps to any part of the list, 
     * including those outside the current window
     */
    const prevJumpTo = useRef(jumpTo)
    useEffect(() => {
        if(
            prevJumpTo.current !== jumpTo &&
            Number.isInteger(jumpTo) && 
            jumpTo >= 0
        ){
            console.log(`JUMPING to ${jumpTo}`)
            const newStartAndEnd = getStartAndEnd(jumpTo)
            setWindowStart(newStartAndEnd.windowStart)
            setWindowEnd(newStartAndEnd.windowEnd)    
            setJumpTarget(jumpTo) 
            //track whether the start position has ever changed
            startPositionChanged.current = true
        }   
        prevJumpTo.current = jumpTo
    }, [jumpTo])
    let windowedList = list.slice(windowStart, windowEnd)
    //passing isJumping as a function instead of a boolean
    //allows children to check whether jumping is happening without
    //forcing them all to rerender
    const isJumping = () => Number.isInteger(jumpTarget)
    const hasStartPositionChanged = () => startPositionChanged.current
    const isStartAt = index => index === startAt
    const onReachInitialPosition = () => startPositionChanged.current = true 
    return (
        <div className={`${styles.container} ${containerStyle}`}>
            {
                windowedList.map((item, i) => {
                    const trueIndex = i + windowStart
                    return <WindowedListItem
                        key={getKeyFromItem(item)}
                        id={getIdFromItem(item)}
                        isWindowStart={trueIndex === windowStart}
                        isWindowEnd={trueIndex === (windowEnd - 1)}
                        onReachWindowStart={slideWindowUp}
                        onReachWindowEnd={slideWindowDown}
                        onItemScrolledIntoView={handleItemScrolledIntoView}
                        isVisibleThreshold={isVisibleThreshold}
                        isJumpTarget={jumpTarget === trueIndex}
                        onEndJump={() => setJumpTarget(null)}
                        isJumping={isJumping}
                        index={trueIndex}
                        title={item.title}
                        isStartAt={isStartAt}
                        hasStartPositionChanged={hasStartPositionChanged}
                        onReachInitialPosition={onReachInitialPosition}
                    >
                        {renderItem(item)}
                    </WindowedListItem>
                }
                )
            }
        </div>
    )
}

const WindowedListItem = ({
    id,
    isWindowStart=false,
    isWindowEnd=false,
    onReachWindowStart=()=>{},
    onReachWindowEnd=()=>{},
    onItemScrolledIntoView=(itemId, debounce=true) =>itemId,
    isVisibleThreshold, //what percentage of the windowed list item is in the viewport before it is considered visible
    isJumpTarget,
    onEndJump=()=>{},
    isJumping=()=>false,
    index,
    title="",
    isStartAt=() => false,
    hasStartPositionChanged=()=>true,
    onReachInitialPosition=()=>{},
    children
}) => {
    const { ref, inView } = useInView({threshold: isVisibleThreshold});
    const prevInView = useRef();
    //ref for container, so we can imperatively scroll into view
    const containerRef = useRef()
    const scrollIntoView = () => {
        if (containerRef && containerRef.current) containerRef.current.scrollIntoView()
    }
    if (
        !hasStartPositionChanged() &&
        isStartAt(index)
    ){
        scrollIntoView()
    }
    /**
     * handle jumping
     **/
    useEffect(() => {
        //once the jump to target is reached, clear the current jump to target
        if (inView && isJumpTarget) {
            console.log(`clearing jump target ${index} - ${title}`)
            onEndJump()
        }
    }, [inView])

    useEffect(()=>{
        if (isJumpTarget){
            console.log(`triggering the jump to ${index} - ${title}`)
            //if we are newly the jump to, then trigger a jump
            scrollIntoView()
        }
    }, [isJumpTarget])
    //flag to detect interruped jumps 
    //it works as follows: if an item that comes into view
    //stays in view for too long during a jump
    // we assume that the user interrupted the scroll
    //and we use this flag to cancel the jump and send an alert for the item in view
    const resetInterruptedJump = useRef(false)
    /**
     * handle scroll into view alerts &
     * jump resets
     */
    useEffect(() => {
        if (
            inView &&
            !prevInView.current &&
            hasStartPositionChanged() //the start position has changed
        ){
            if (
                !isJumping() || //if jumping is not happening or
                isJumpTarget //jumping is happening and this is the jump target
            ){
                console.log(`due to scroll setting ${index} - ${title} to current`)
                onItemScrolledIntoView(id)
            } else  {
                //if isJumping and we are not the target, we should ignore it
                //but there is also the case of an interrupted jump - That is, the user force scrolls during the jump
                //This would load to any consumer of our scroll detection being out of sync with the current item in view

                //To fix this, we set a timeout and if we are still in view after a while
                //we assume the jumping has been interrupted and send a scroll update and turn off jumping, 
                //otherwise we drop the action
                //if we do not already have a reset set
                if (!resetInterruptedJump.current){
                    resetInterruptedJump.current = setTimeout(() => {
                        if (resetInterruptedJump.current){
                            console.log(`${index} - ${title} has been in view too long. cancel jump and set to current`)
                            onEndJump()
                            onItemScrolledIntoView(id, false) //do not debounce as there is already a set timeout
                            onReachWindowStart(index)
                            setTimeout(scrollIntoView, 200)
                        }
                    }, 600)
                }
            }
        } else if (
            prevInView.current && // we were in view and 
            !inView && //we are no longer in view and
            resetInterruptedJump.current //there is a jump reset scheduled
        ) {
            // clear any scheduled resets to interrupted jumps if we used to be in view
            clearTimeout(resetInterruptedJump.current)
            resetInterruptedJump.current = false
        } else if (
            !hasStartPositionChanged() &&
            inView &&
            isStartAt(index)
        ){
            onReachInitialPosition()
        }
    }, [inView])


    /**
     * 3. handle the case of an interrupted jump right after it is set, 
     *    on the original item which was already in view when the jump started
     */
    if (
        isJumping() &&
        !isJumpTarget &&
        prevInView.current &&
        inView &&
        !resetInterruptedJump.current
    ){
        resetInterruptedJump.current = setTimeout(() => {
            if (resetInterruptedJump.current){
                console.log(`${index} - ${title} (the first) has been in view too long. cancel jump and set to current`)
                onEndJump()
                onItemScrolledIntoView(id, false)  //do not debounce as there is already a set timeout
                onReachWindowStart(index)
                setTimeout(scrollIntoView, 200)
            }
        }, 600)
    }

    /**
     * handle windowing
     **/
    useEffect(() => {
        if (
            inView &&
            !prevInView.current &&
            isWindowEnd  && 
            !isJumping()
        ) {
            console.log(`reached ${index} - ${title} it was the window end`)
            onReachWindowEnd(index)
        } else if (
            inView &&
            !prevInView.current &&
            isWindowStart && 
            !isJumping() &&
            hasStartPositionChanged()
        ){
            console.log(`reached ${index} - ${title} it was the window start`)
            onReachWindowStart(index)
        }
        prevInView.current = inView
    }, [inView])

    return (
        <div ref={containerRef}>
            <div ref={ref}>
                {children}
            </div>
        </div>
    )
}

export default WindowedList