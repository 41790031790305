import React from 'react'
import styles from "./MultiImageFrame.module.css"
import Thumbnail from "../Thumbnail"

class MultiImageFrame extends React.Component {

    state = {
        selectedIndex: 0
    }

    static defaultProps = {
        images: []
    }

    handleChangeSelectedIndex = selectedIndex => this.setState({selectedIndex})

    static getDerivedStateFromProps(props, state){
        if (!props.images[state.selectedIndex]) return {selectedIndex: 0}
        return null
    }
    
    render(){
        const {images} = this.props
        const {selectedIndex} = this.state
        if (!images.length) return ""
        return (
            <div className={styles.container}>
                <div className={styles.thumbnailList}>
                    {
                        images.map( (image, i) => {
                            return <Thumbnail 
                                        key={image.url}
                                        url={image.url} 
                                        index={i}
                                        selected={i === selectedIndex}
                                        onClick={() => this.handleChangeSelectedIndex(i)}
                                    />
                        })
                    }
                </div>
                <div className={styles.imageFrame}>
                    {
                        images.length > 0 ?
                        <img className={styles.productImage} src={images[selectedIndex].url} />
                        :
                        null
                    }
                </div>
            </div>
        )
    }
}

export default MultiImageFrame