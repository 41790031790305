/**
 *  Purpose: the modifications that are carried on the usage objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"

 // STATE
 // usages:{
 //   dailySummariesById:{},
 // }
 
 
 const usageReducer = (state = initialState.usage, action) => {
     const {type, payload} = action;
     let dailySummariesById = {...state.dailySummariesById}
     let loaded = {...state.loaded}
     switch (type){
         case types.SAVE_DAILY_SUMMARIES: {
             if(!Array.isArray(payload.dailySummaries)) {
                 logError(`usageReducer > SAVE_DAILY_SUMMARIES: dailySummaries payload is not an array ${JSON.stringify(payload)}`)
                 return state
             }
             payload.dailySummaries.forEach(dailySummary => {
                dailySummariesById[dailySummary.id] = dailySummary
             });
             return {
                 ...state,
                 dailySummariesById,
             }
        }
 
        default: return state
     }
 }
 
 export default usageReducer