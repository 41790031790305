import React from "react"
import styles from "./MenuItem.module.css"
import Icon from "../Icon"

const MenuItem = ({label, icon="", onClick=()=>{}}) => {
    return (
    <div onClick={onClick} className={styles.container}>
        <div className={styles.label}>
            <Icon icon={icon}/>
            <span>{label}</span>
        </div>
        <Icon icon="keyboard-arrow-right"/>
    </div>
    )
}

export default MenuItem;