import React from 'react'
import PaymentModal from "../PaymentModal"

function CashOnDeliveryModal({logo, reference, amount2DecimalPlaces, isOpen, closeModal, onPressConfirm, email}){
    return (
        <PaymentModal
            paymentTitle='Cash On Delivery'
            logo={logo}
            reference={reference}
            amount2DecimalPlaces={amount2DecimalPlaces}
            isOpen={isOpen}
            closeModal={closeModal}
            onPressConfirm={onPressConfirm}
        >
            <div>
                <div className="info-bubble">1. <span>Put aside exactly EC${amount2DecimalPlaces} to pay for your items</span>. Sorry, we don't usually use cash so we won't have change</div>
                <div className="info-bubble">2. <span>Press 'Confirm And Place Order'</span> to let us know we should start processing your order</div>
                <div className="info-bubble">3. We will email you at <span>{email}</span> with order updates!</div>
            </div>
        </PaymentModal>
    )
}
export default CashOnDeliveryModal