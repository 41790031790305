export const PAYEE_TYPE_PAYDM_MERCHANT = "PAYDM_MERCHANT"

export const PAYEE_TRANSACTION_FEE_SETTING_6PCT_ON_RECIPIENT = "6PCT_ON_RECIPIENT"
export const PAYEE_TRANSACTION_FEE_SETTING_6PCT_ON_SENDER = "6PCT_ON_SENDER"
export const PAYEE_TRANSACTION_FEE_SETTING_3PCT_EACH = "3PCT_EACH"

export const PAYEE_TRANSACTION_FEE_SETTING_MAP = {
    [PAYEE_TRANSACTION_FEE_SETTING_6PCT_ON_RECIPIENT]: {
        recipientFee: 0.06,
        senderFee: 0
    },
    [PAYEE_TRANSACTION_FEE_SETTING_3PCT_EACH]: {
        recipientFee: 0.03,
        senderFee: 0.03
    },
    [PAYEE_TRANSACTION_FEE_SETTING_6PCT_ON_SENDER]: {
        recipientFee: 0,
        senderFee: 0.06
    },
}

export const PAYEE_AUTHORIZED_FUNCTIONS_ALL_FUNCTIONS = "ALL_FUNCTIONS"
export const PAYEE_AUTHORIZED_FUNCTIONS_ONLY_INVOICES = "ONLY_INVOICES"
export const PAYEE_AUTHORIZED_FUNCTIONS_ONLY_PAYMENTS = "ONLY_PAYMENTS"

export const PAYEE_AUTHORIZED_FUNCTIONS_SETTING_MAP = {
    [PAYEE_AUTHORIZED_FUNCTIONS_ALL_FUNCTIONS]: {
        invoices: true,
        oneTimePayments: true
    },
    [PAYEE_AUTHORIZED_FUNCTIONS_ONLY_INVOICES]: {
        invoices: true
    },
    [PAYEE_AUTHORIZED_FUNCTIONS_ONLY_PAYMENTS]: {
        oneTimePayments: true
    },
}

export const PAYEE_TRANSACTION_LIMIT_1000 = 1000
export const PAYEE_TRANSACTION_LIMIT_5000 = 5000
export const PAYEE_TRANSACTION_LIMIT_10000 = 10000
export const PAYEE_TRANSACTION_LIMIT_25000 = 25000
export const PAYEE_TRANSACTION_LIMIT_50000 = 50000

export const PAYEE_MONTHLY_LIMIT_10000 = 10000
export const PAYEE_MONTHLY_LIMIT_25000 = 25000
export const PAYEE_MONTHLY_LIMIT_50000 = 50000
export const PAYEE_MONTHLY_LIMIT_100000 = 100000
export const PAYEE_MONTHLY_LIMIT_250000 = 250000

export const PAYEE_CATEGORIES = [
    {id: "ACCOMMODATION", name: "Hotels & Guesthouses"},
    {id: "RESTAURANTS", name: "Restaurants"},
    {id: "MANUFACTURERS", name: "Manufacturers"},
    {id: "RETAIL", name: "Retail"},
    {id: "TOURISM", name: "Tourist Services"},
    {id: "TRANSPORTATION", name: "Transportation"},
    {id: "PROFESSIONAL_SERVICES", name: "Professional Services"},
    {id: "ENTERTAINMENT", name: "Entertainment"},
    {id: "PERSONAL_CARE_SERVICES", name: "Personal Care Services"},
]

export const PAYEE_PAYOUT_SETTINGS_ID = '--payout-settings--'
export const PAYEE_TRANSACTION_FEE_SETTINGS_ID = '--transaction-fee--'
export const PAYEE_LIMIT_SETTINGS_ID = '--limit-settings--'
export const PAYEE_AUTHORIZED_FUNCTIONS_ID = '--authorized-functions--'
export const PAYEE_PROFILE_ID = '--profile--'
export const PAYEE_ROLES_ID = '--roles--'

export const PAYEE_ROLE_OWNER = 'OWNER'
export const PAYEE_ROLE_MANAGER = 'MANAGER'
export const PAYEE_ROLE_CUSTOMER_SERVICE = 'CUSTOMER_SERVICE'
export const PAYEE_ROLE_ACCOUNTS = 'ACCOUNTS'
export const PAYEE_ROLE_SHOPDM_ADMIN = 'SHOPDM_ADMIN'