import React from "react"
import Modal from "../../components/Modal"

import CartProductChangedItem from "../../components/CartProductChangedItem";

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"
import {capitalizeAllWords, alphabeticalSort} from "../../utils/stringUtils"
import { objectsAreEqual } from "../../utils/generalUtils";

import styles from "./CartItemsChangedModal.module.css"
import ShoppingCart from "../../images/shopping-cart.png"

class CartItemsChangedModal extends React.PureComponent {
    
    constructor(props){
        super(props)
        const {changedItems, cartChanges} = this.getChangedItemsByComparingItemsInCartToLoadedProducts()
        this.state = {
            changedItems,
            cartChanges,
            productListeners: []
        }
    }

    componentDidMount = async () => {
        const {actions} = this.props
        const productListeners = await actions.fetchListenToProductsInCart()
        this.setState({productListeners})
    }

    cancelListeners = () => {
        const {productListeners} = this.state
        if (Array.isArray(productListeners)){
            productListeners.forEach(listener => listener())
        }
        this.setState({productListeners: []})
    } 

    componentWillUnmount() {
        this.cancelListeners()
    }
    
    componentDidUpdate(){
        const {cart} = this.props
        const prevChangedItems = this.state.changedItems
        const {changedItems, cartChanges} = this.getChangedItemsByComparingItemsInCartToLoadedProducts(cart)
        if (Object.keys(changedItems).length === 0 && Object.keys(prevChangedItems).length > 0){
            //if all changes are resolved, clear all cart changes and changed items
            //this will also close the modal if it is open
            this.setState({changedItems: {}, cartChanges: {}})
        } else if (
            !objectsAreEqual(changedItems, prevChangedItems)
        ){
            //if there are items which do not match those in the cart
            //but these are not exactly the same as before
            //then override the previous items flagged as not matching
            //as well as the changes made to them so for 
            this.setState({changedItems, cartChanges})
        }
    }

    handleRemove = itemId => {
        const {cartChanges} = this.state 
        this.setState({
            cartChanges: {
                ...cartChanges,
                [itemId] : {
                    ...cartChanges[itemId],
                    remove: true,
                    quantity: 0
                }
            }
        })
    }

    handleChangeQuantity = (itemId, quantity) => {
        const {cartChanges} = this.state 
        const itemChanges = {...cartChanges[itemId], quantity}
        delete itemChanges["remove"]
        this.setState({
            cartChanges: {
                ...cartChanges,
                [itemId] : itemChanges
            }
        })
    }

    handleToggleNotify = (itemId, notify=true) => {
        const {cartChanges} = this.state 
        const itemChanges = {...cartChanges[itemId], notify}
        this.setState({
            cartChanges: {
                ...cartChanges,
                [itemId] : itemChanges
            }
        })
    }

    handleSaveChangesToCart = async () => {
        const {cartChanges, changedItems} = this.state
        const {products, actions} = this.props
        actions.toggleLoading(true)
        for (let itemId in cartChanges){
            const change = cartChanges[itemId]
            if (change.remove){
                //if the item has been removed, remove it from the cart
                await actions.fetchRemoveFromCart(itemId)
            } else {
                //otherwise add the new item to the cart, with the new quantity
                const item = changedItems[itemId]
                const product = products.productsById[item.productId]
                await actions.fetchAddToCart(
                    {...product.stock[itemId]},
                    change.quantity
                )
            }
            
        }
        actions.toggleLoading(false)
    }
    
    getChangedItemsByComparingItemsInCartToLoadedProducts = () => {
        const {cart, products} = this.props
        const changedItems = {}
        const cartChanges = {}
        Object.values(cart.itemsByProductStockId).forEach(item => {
            const loadedProduct = products.productsById[item.productId]
            if (loadedProduct){
                const minimumOrderQty = loadedProduct.minimumOrderQty ? loadedProduct.minimumOrderQty : 1
                let loadedItem = loadedProduct.stock[item.id]
                if (loadedItem){
                    //make a modifyable copy of the item
                    loadedItem = {...loadedItem, previousPrice: item.price, previousQuantity: item.quantity}
                    if (loadedItem.price !== item.price){
                        //if the price has changed
                        loadedItem.priceChange = true
                        //for every item, with a price change store the current qty selected as a 'change'
                        //this will be updated if there is a problem with the amount of selected stock
                        //in the next if block
                        if (!cartChanges[item.id]) cartChanges[item.id] = {}
                        cartChanges[item.id].quantity = item.quantity
                        changedItems[item.id] = loadedItem
                    }
                    if (loadedItem.quantityInStock < item.quantity){
                        //if the quantity in stock available is less than the quantity that was added to cart
                        loadedItem.quantityChange = true
                        changedItems[item.id] = loadedItem

                        if (!cartChanges[item.id]) cartChanges[item.id] = {}
                        if (loadedItem.quantityInStock < minimumOrderQty){
                            //if the item is out of stock
                            cartChanges[item.id].remove = true
                            cartChanges[item.id].quantity = 0
                        } else {
                            //otherwise, set the quantity to the max available quantity
                            cartChanges[item.id].quantity = loadedItem.quantityInStock
                        }
                    }
                } else {
                    //TODO deal with the case where the item has been deleted
                }
            }
        })
        return ({
            changedItems,
            cartChanges
        })
    }
   
    render(){
        const {changedItems, cartChanges} = this.state
        const {products, sellers} = this.props
        const items = Object.values(changedItems).sort((itemA, itemB)=>{
            const productA = products.productsById[itemA.productId]
            const productB = products.productsById[itemB.productId]
            if (!productA || !productB) return 0
            return alphabeticalSort(productA.titleAndBrand, productB.titleAndBrand)
        })
        return(
            <Modal
                isOpen={Object.keys(changedItems).length > 0}
                closeModal={()=>{}}
                className="contained"
                innerClassName="contained"
                showCloseButton={false}
            >
                <div className="modalHeader">
                    <div className={styles.iconBack}><img src={ShoppingCart} className="imgIcon"/></div>
                    <div className="strong strongTitle">{items.length} item{items.length > 1? "s" : ""} in your cart {items.length > 1? "have" : "has"} changed in price or availability</div>
                </div>
                <div className={[styles.itemList, "modalInner contained"].join(" ")}>
                    {
                        items.map(item => {
                            const seller = sellers.sellersById[item.sellerId]
                            const product = products.productsById[item.productId]
                            if (!seller || !product) return ""
                            const imageUrl = item.imageUrl ? item.imageUrl : product.imageUrl
                            const minimumOrderQty = product.minimumOrderQty ? product.minimumOrderQty : 1
                            return <CartProductChangedItem 
                                        key={item.id}
                                        item={item}
                                        imageUrl={imageUrl}
                                        productTitle={product.titleAndBrand}
                                        minimumOrderQty={minimumOrderQty}
                                        sellerName={capitalizeAllWords(seller.name)}
                                        changes={cartChanges[item.id]}
                                        onRemove={this.handleRemove}
                                        onChangeQuantity={this.handleChangeQuantity}
                                        onToggleNotify={this.handleToggleNotify}
                                    />
                        })
                    }
                </div>
                <div className="modalFooter">
                    <button className="button primary" onClick={this.handleSaveChangesToCart}>Accept Changes</button>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    cart: state.cart,
    products: state.products,
    sellers: state.sellers
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItemsChangedModal)