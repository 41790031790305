import React from "react"
import {withRouter} from "react-router-dom"
import styles from "./AddedToBasket.module.css"

import {connect} from "react-redux"
import {getVariantDescription} from "../../utils/productUtils"
import { capitalizeAllWords } from "../../utils/stringUtils"

const hideDelay = 4000
class AddedToBasket extends React.PureComponent{
    constructor(props){
        super(props)
        this.state = {
            display: 0
        } 
    }

    static defaultProps = {
        zIndex: undefined
    }

    static getDerivedStateFromProps(props, state){
        const lastAddedAt = props.cart.lastAddedAt ? props.cart.lastAddedAt : 0
        if (
            (lastAddedAt !== state.display) &&
            ((Date.now() - lastAddedAt) < hideDelay)
        ){
            return {display: lastAddedAt}
        }
        return null
    }

    componentWillUnmount() {
        if (this.hideFunction) clearTimeout(this.hideFunction) 
    }

    render(){
        const {display} = this.state
        const {cart, products, history, zIndex} = this.props
        const lastAddedItem = cart.lastAddedItemId ? 
                            cart.itemsByProductStockId[cart.lastAddedItemId]
                            :
                            null
        if (!lastAddedItem) return null
        if (!display) return null

        this.hideFunction = setTimeout(()=>{
            //only hide if it has not been set again
            if (display === this.state.display){
                this.setState({display: 0})
            }
        }, hideDelay)

        const product = products.productsById[lastAddedItem.productId]
        const imageUrl = lastAddedItem.imageUrl ? lastAddedItem.imageUrl : product.imageUrl
        const style = zIndex ? {zIndex} : {}
        return (
            <div style={style} className={['successBar', styles.container].join(" ")}>
                <div className={styles.details}>
                    <img src={imageUrl}/>
                    <span className={styles.description}>{`${lastAddedItem.quantity} x ${`${capitalizeAllWords(getVariantDescription(lastAddedItem))} `}${product.titleAndBrand} has been added to your shopping cart. Enjoy!`}</span>
                </div>
                <div>
                    <button 
                        className="button dark"
                        onClick={() => history.push("/cart-details", {from: history.location.pathname})}
                    >
                        Review Shopping Cart
                    </button>
                    <button 
                        className={["button", "primary", styles.checkoutButton].join(" ")}
                        onClick={() => history.push("/checkout")}
                    >
                        Go To Checkout
                    </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    cart: state.cart,
    products: state.products
})

export default connect(mapStateToProps)(withRouter(AddedToBasket));