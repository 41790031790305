export const SELLER = 'SELLER'
export const DELIVERY_PROVIDER = 'DELIVERY_PROVIDER'

export const NBD_BANK_ID = 'nbd'
export const NCCU_BANK_ID = 'nccu'
export const REPUBLIC_BANK_ID = 'republic'

export const BANK_ACCOUNT_TYPE_SAVINGS = 'SAV'
export const BANK_ACCOUNT_TYPE_CHECKING = 'DDA'

export const SUPPORTED_BANKS = 
[
    {id: NBD_BANK_ID, name: "National Bank Of Dominica"}, 
    {id: NCCU_BANK_ID, name: "National Cooperative Credit Union"}, 
    {id: REPUBLIC_BANK_ID, name: "Republic Bank"}
]

export const SUPPORTED_BANK_ACCOUNT_TYPES = [
    {id: BANK_ACCOUNT_TYPE_SAVINGS, name: "Savings"},
    {id: BANK_ACCOUNT_TYPE_CHECKING, name: "Checking"}
]
export const PAYOUT_METHOD_CASH = 'CASH'
export const PAYOUT_METHOD_BANK_DEPOSIT = 'BANK_DEPOSIT'
export const SUPPORTED_PAYOUT_METHODS = [
    {id: PAYOUT_METHOD_BANK_DEPOSIT, name: "Automatic Deposit Into Account"},
    {id: PAYOUT_METHOD_CASH, name: "Cash"}
]