import React from "react"
import styles from "./YourWallet.module.css"

import YourWalletCard from "../YourWalletCard"
import CreditTransactionList from "../../components/CreditTransactionList"
import Transaction from "../../components/Transaction"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {CREDIT_TRANSACTION_ROLE_RECIPIENT, CREDIT_TRANSACTION_ROLE_SENDER, CREDIT_STATUS_PROCESSED} from "../../constants/credit"
import {TOPUP_STATUS_PAYMENT_CLAIMED} from "../../constants/topup"
import {logAnalyticsEvent} from "../../config/firebase"
class YourWallet extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            recipientTransactionsListener : () => {},
            senderTransactionsListener : () => {},
            recipientTopupsListener: ()=>{}
        } 
    }

    componentDidMount = async () => {
        const {actions, user} = this.props
        const recipientTransactionsListener = await actions.fetchSubscribeToEntityCreditTransactions(user.id, CREDIT_TRANSACTION_ROLE_RECIPIENT)
        const senderTransactionsListener = await actions.fetchSubscribeToEntityCreditTransactions(user.id, CREDIT_TRANSACTION_ROLE_SENDER)
        const recipientTopupsListener = await actions.fetchSubscribeToTopupsByDate(user.defaultWalletId)
        this.setState({
            recipientTransactionsListener,
            senderTransactionsListener, 
            recipientTopupsListener
        })
        logAnalyticsEvent("view_your_shopdm_credit")
        actions.fetchLogDeviceSessionAction({
            action: "viewYourShopdmCredit", 
            walletId: user.defaultWalletId ? user.defaultWalletId : "" 
        })
    }

    componentWillUnmount(){
        this.state.recipientTransactionsListener()
        this.state.senderTransactionsListener()
        this.state.recipientTopupsListener()
    }

    getTransactionArray = () => {
        const {user, creditTransactions} =  this.props
        return creditTransactions.creditTransactionIds.map(transactionId => creditTransactions.creditTransactionsById[transactionId])
                              .filter(
                                  transaction => transaction.currentStatus === CREDIT_STATUS_PROCESSED &&
                                                    (transaction.recipientId === user.id || transaction.senderId === user.id)
                                )
                              .sort((transactionA, transactionB) => Number(transactionB.createdAt) - Number(transactionA.createdAt))
    }

    getPaymentClaimedTopupArray = () => {
        const {user, topups} = this.props
        return Object.values(topups.topupsById)
                     .filter(topup => topup.recipientWalletId === user.defaultWalletId &&
                                      topup.currentStatus === TOPUP_STATUS_PAYMENT_CLAIMED
                    ).sort((tA, tB) => tB.createdAt - tA.createdAt)
    }
    render(){
        const transactions = this.getTransactionArray()
        const paymentClaimedTopups = this.getPaymentClaimedTopupArray()
        const {user} = this.props
        return (
                <div className={styles.container}>
                    <div className={styles.innerContainer}>
                        <div className="title center">Your Shopdm Credit</div>
                        <div className={styles.explanation}>
                        Pay for anything on Shopdm with Shopdm Credit 

                        </div>
                        <div className={styles.cardContainer}>
                            {
                                user.defaultWalletId ? 
                                <YourWalletCard 
                                    walletId={user.defaultWalletId}
                                />
                                    :
                                null
                            }
                        </div>
                        {
                            paymentClaimedTopups && paymentClaimedTopups.length > 0?
                            <div>
                                <div className="transactionListTitle">Pending Topups</div>
                                <div className="transactionList">
                                    {
                                        paymentClaimedTopups.map(topup => {
                                            return <Transaction 
                                                key={topup.id}
                                                totalXcd={Number(topup.topupAmountXcd)}
                                                reason={`Topup ${topup.reference}`}
                                                title="Pending Top Up"
                                                isCredit={true}
                                                showEndingBalance={false}
                                            />
                                        })
                                    }
                                </div>
                            </div>
                            :
                            null
                        }
                        <CreditTransactionList 
                            transactions={transactions}
                            walletId={user.defaultWalletId}
                        />
                    </div>
                </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    creditTransactions: state.creditTransactions,
    topups: state.topups
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(YourWallet)