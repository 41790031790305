import React from "react"
import styles from "./CartBadge.module.css"
import Icon from "../../components/Icon"

import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {getProductStockPromotion, calculatePromotionalPrice} from "../../utils/promotions"
import currency from "currency.js"

function CartBadge({cart, user, promotions, history}){
    const totals = cart.itemIds.reduce((totals,productStockId) => {
        const item = cart.itemsByProductStockId[productStockId]
        const promotion = getProductStockPromotion(
            item.id,
            item.productId, 
            item.sellerId, 
            user.activeSellerAccountId, 
            promotions
        )
        const price = promotion ? 
                        calculatePromotionalPrice(item.price, promotion)
                        : 
                        item.price

        totals.balance += currency(price).multiply(item.quantity).value
        totals.count += item.quantity
        return totals
    }, {balance: 0, count: 0})
    const onClick = () => history.push('/cart-details', {from: history.location.pathname})
    return (
    <div className={styles.container} onClick={onClick}>
        <Icon icon="shopping-cart"/>
        <div>
            <div className={styles.balance}>{`EC $${Number(totals.balance).toFixed(2)}`}</div>
            <div className={styles.items}><span>{totals.count}</span> items</div>
        </div>
    </div>
    )
}

const mapStateToProps = state => ({
    cart: state.cart,
    user: state.user,
    promotions: state.promotions,
})
export default connect(mapStateToProps)(withRouter(CartBadge));