import React from 'react';
import styles from "./DYBTBanner.module.css"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import AvatarIcon from "../../components/AvatarIcon"
import {AVATAR_SIZE_MEDIUM, AVATAR_SIZE_SMALL} from "../../constants/interface"
import {DYBT_CATEGORY_ID} from "../../constants/categories"
import { alphabeticalSort } from '../../utils/stringUtils';
import Slider from "../../components/Slider"

const DYBTBanner = ({history, sellers, products}) => {
    const handleClickSeller = sellerId => history.push(`/sellers/${sellerId}`)
    const dybtProductIds = products.productIdsByCategoryId &&
                           products.productIdsByCategoryId[DYBT_CATEGORY_ID] ?
                           products.productIdsByCategoryId[DYBT_CATEGORY_ID] :
                           {}

    const dybtProducts = Object.keys(dybtProductIds).map(productId => products.productsById[productId] ? products.productsById[productId] : {})
    const sellerIdMap = {}
    //find all the dybt stores by looking over the products tagged with the DYBT category
    let sellerList = dybtProducts.reduce((sellerList, product) => {
        const seller = sellers.sellersById[product.createdBySellerId]
        if (
            seller && 
            seller.isActive &&
            !sellerIdMap[seller.id]
        ){
            //if we find a seller, add it to the list. Also add to the map to keep unique
            sellerIdMap[seller.id] = true
            sellerList.push(seller)
        }
        return sellerList
    }, []).sort((sA, sB) => alphabeticalSort(sA.name, sB.name))
 
    return (
        <div className={styles.container}>
            <div className={styles.sellerContainer}>
                <Slider>
                    {
                        sellerList.map(seller => {
                            return (
                                <div key={seller.id} className={styles.sellerIcon} onClick={() => handleClickSeller(seller.id)}>
                                    <AvatarIcon 
                                        name={seller.name} 
                                        imageUrl={seller.logoImageUrl} 
                                        size={AVATAR_SIZE_MEDIUM}
                                    />
                                    <div className={styles.sellerName}>{seller.name}</div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    categories: state.categories,
    products: state.products,
    sellers: state.sellers,
})

export default connect(mapStateToProps)(withRouter(DYBTBanner))