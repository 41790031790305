/**
 *  Purpose: the modifications that are carried on the Product objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {STATISTICS_IDENTIFIER} from "../constants/analysis"
// STATE
// products:{
//  productsById:{},
//  productIdsByCategoryId: {},
//  productIdsBySellerId: {},
//  productIds: [],
//  loaded: {}
// }


const productsReducer = (state = initialState.products, action) => {
    const {type, payload} = action;
    let productsById = {...state.productsById}
    let productIds = {...state.productIds}
    let productIdsByCategoryId = {...state.productIdsByCategoryId}
    let productIdsBySellerId = {...state.productIdsBySellerId}
    let loaded = {...state.loaded}
    let stats = {...state.stats}

    switch (type){
        case types.SAVE_PRODUCTS: {
            if(typeof payload.products !== "object") {
                logError(`productsReducer > SAVE_PRODUCTS: products payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.products.forEach(product => {
                if (product.id === STATISTICS_IDENTIFIER) {
                    //if this is the product statistics, save it as stats
                    stats = product
                    return
                } 
                product.tags = product.tags ? product.tags : {}
                product.views = product.views? product.views : 0
                product.categoryIds = product.categoryIds ? product.categoryIds : []
                product.titleAndBrand = !product.brand ? product.title : `${product.brand} ${product.title}`
                productsById[product.id] = product
                //index products by their category ids
                product.categoryIds.forEach(categoryId => {
                    if (!productIdsByCategoryId[categoryId]) productIdsByCategoryId[categoryId] = {}
                    productIdsByCategoryId[categoryId][product.id] = true
                })
                //index products by their seller id 
                if (!productIdsBySellerId[product.createdBySellerId]) productIdsBySellerId[product.createdBySellerId] = {}
                productIdsBySellerId[product.createdBySellerId][product.id] = true
            })
            if (payload.loaded){
                //record seller loads
                if (payload.loaded.sellerIds){
                    loaded.sellerIds = {
                        ...loaded.sellerIds,
                        ...payload.loaded.sellerIds
                    }
                }
                //record category loads
                if (payload.loaded.categoryIds){
                    loaded.categoryIds = {
                        ...loaded.categoryIds,
                        ...payload.loaded.categoryIds
                    }
                }
                //record order loads
                if (payload.loaded.orderIds){
                    loaded.orderIds = {
                        ...loaded.orderIds,
                        ...payload.loaded.orderIds
                    }
                }
                //record system loads
                if (payload.loaded.system){
                    loaded.system = {
                        ...loaded.system,
                        ...payload.loaded.system
                    }
                }
            }
            return {
                ...state,
                productsById,
                productIdsByCategoryId,
                productIdsBySellerId,
                productIds: Object.keys(productsById),
                loaded,
                stats
            }
        }

        case types.CREATE_PRODUCT: {
            if(typeof payload.product !== "object") {
                logError(`productsReducer > CREATE_PRODUCT: product payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.sellerId !== "string") {
                logError(`productsReducer > CREATE_PRODUCT: seller id is not a string ${JSON.stringify(payload)}`)
                return state
            }
            productsById[payload.product.id] = {
                ...payload.product,
                titleAndBrand: payload.product.brand ? `${payload.product.brand} ${payload.product.title}` : payload.product.title
            }
            return {
                ...state,
                productsById,
                productIds: Object.keys(productsById)
            }
        }

        case types.EDIT_PRODUCT: {
            if(typeof payload.productId !== "string") {
                logError(`productsReducer > EDIT_PRODUCT: productId is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.edits !== "object") {
                logError(`productsReducer > EDIT_PRODUCT: product edits payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            productsById[payload.productId] = {...productsById[payload.productId], ...payload.edits}
            productsById[payload.productId].titleAndBrand = productsById[payload.productId].brand ? 
                                                            `${productsById[payload.productId].brand} ${productsById[payload.productId].title}`
                                                            :
                                                            productsById[payload.productId].title
            return {
                ...state,
                productsById
            }
        }

        case types.DELETE_PRODUCT: {
            if(typeof payload.productId !== "string") {
                logError(`productsReducer > DELETE_PRODUCT: productId is not a string ${JSON.stringify(payload)}`)
                return state
            }
            delete productsById[payload.productId]
            return {
                ...state,
                productsById,
                productIds: Object.keys(productsById)
            }
        }

        case types.CREATE_PRODUCT_STOCK: {
            if(typeof payload.productStock !== "object") {
                return state
            }
            productsById[payload.productStock.productId] = {
                ...productsById[payload.productStock.productId],
                stock: {
                    ...productsById[payload.productStock.productId].stock,
                    [payload.productStock.id] : {...payload.productStock}
                } 
            }
            return {
                ...state,
                productsById
            }
        }

        case types.EDIT_PRODUCT_STOCK: {
            if(typeof payload.productStockId !== "string" || typeof payload.productId !== "string"){
                  return state
            }
            if(typeof payload.edits !== "object") {
                return state
            }
            productsById[payload.productId] = {
                ...productsById[payload.productId],
                stock: {
                    ...productsById[payload.productId].stock,
                    [payload.productStockId]: {
                        ...productsById[payload.productId].stock[payload.productStockId],
                        ...payload.edits
                    }
                } 
            }
            return {
                ...state,
                productsById
            }
        }

        case types.DELETE_PRODUCT_STOCK: {
            if(typeof payload.productStockId !== "string" || typeof payload.productId !== "string"){
                return state
            }
            const stock = {...productsById[payload.productId].stock}
            delete stock[payload.productStockId]
            productsById[payload.productId] = {
                ...productsById[payload.productId],
                stock
            }
            return {
                ...state,
                productsById
            }
        }
        default: return state
    }
}

export default productsReducer