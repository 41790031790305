import React from "react"
import DataTable from "../../components/DataTable"
import Thumbnail from "../../components/Thumbnail"
import Icon from "../../components/Icon"
import EllipsisText from "../../components/EllipsisText"
import { capitalizeAllWords } from "../../utils/stringUtils"

import {timestampToDateTimeString, timestampToDateString} from "../../utils/datetimeUtils"
import {copyToClipboard} from "../../utils/interactionUtils"
import AvatarIcon from "../../components/AvatarIcon"

import AddToCartButton from '../AddToCartButton'
import ProductVariantTableList from '../ProductVariantTableList'
import CharacteristicsList from "../../components/CharacteristicsList"
import { AVATAR_SIZE_SMALL} from "../../constants/interface"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {Link} from "react-router-dom"
import { objectsAreEqual } from "../../utils/generalUtils"

class CustomerRestockRequestTable extends React.Component {
    
    state = {
        //tracks the product stock ids that were loaded and the restock request they were loaded for
        loadedRestockRequestStatusesByProductStockId: {}
    }

    static defaultProps = {
        restockRequestArray: [],
        title: "Restock Requests",
        maxRowsPerPage: 25
    }

    componentDidMount(){
        const statusesByProductStockId = this.getStatusesByProductStockId(this.props.restockRequestArray)
        this.loadProductsInMap(statusesByProductStockId)
    }

    componentDidUpdate(){
        const statusesByProductStockId = this.getStatusesByProductStockId(this.props.restockRequestArray)
        this.loadProductsInMap(statusesByProductStockId)
    }

    getStatusesByProductStockId = restockRequestArray => {
        /**
         * get restock request status maps indexed by product stock id, so we can detect changes
         * 
         * This allows us to load products in two situations
         *      1. if the restock array changes because a different time range of restock requests is chosen
         *      2. if the status of one of the restock requests changes 
         */
        return restockRequestArray.reduce((map, r) => {
            map[r.productStockId] = {
                productStockId: r.productStockId,
                productId: r.productId,
                currentStatus: r.currentStatus,
                lastAltsSuggestedAt: r.lastAltsSuggestedAt,
                nextRestockAt: r.nextRestockAt
            }
            return map
        }, {})
    }

    loadProductsInMap = async statusesByProductStockId => {
        //determine which products to load or re-load based on the difference between the current status map and the saved one
        const {actions} = this.props
        const {loadedRestockRequestStatusesByProductStockId} = this.state
        const productIdsToLoad = {}
        Object.values(statusesByProductStockId).forEach(status => {
            if (
                //load product if the new this product stock id has not been seen before
                //for e.g., if the time range filtering the restock requests has been extended
                !loadedRestockRequestStatusesByProductStockId[status.productStockId] ||
                //OR load product if the status of the restock request has changed
                //this allows us to get things like discontinued messages, next restock messages or alternative suggestions
                !objectsAreEqual(
                    status,
                    loadedRestockRequestStatusesByProductStockId[status.productStockId] 
                )
            ){
                productIdsToLoad[status.productId] = true
            }
        })
        //if there are any products that meet the criteria above, load them
        const productIdsToLoadList = Object.keys(productIdsToLoad)
        //do not double load the products, before they have been loaded
        if (this.isLoading) return
        if (productIdsToLoadList.length > 0){
            this.isLoading = true
            actions.toggleLoading(true)
            const success = await actions.fetchSaveProductsInList(productIdsToLoadList)
            actions.toggleLoading(false)
            this.isLoading = false
            //if the loading was successful, then update the status map
            if (success) this.setState({
                loadedRestockRequestStatusesByProductStockId: statusesByProductStockId
            })
        }

    }

    render(){
        const {restockRequestArray, title, maxRowsPerPage, productStock} = this.props
        const rowHighlightFunction = restockRequest =>  restockRequest.quantityRestocked ?
                                                        "var(--light-primary)"
                                                        :
                                                        ""
        const displayArray = restockRequestArray.map(r => {
            const variant = productStock.productStockById[r.productStockId]
            if (!variant) return r
            return {
                ...r,
                lastMessage: variant.discontinuedMessage ?
                             variant.discontinuedMessage :
                             variant.nextRestockMessage ?
                             variant.nextRestockMessage :
                             "",
                altProductStockIds: r.altProductStockIds ? 
                                    r.altProductStockIds 
                                    :
                                    variant.altProductStockIds ? 
                                    variant.altProductStockIds : null               
            }
        })
        return  (
            <DataTable 
                    title={title}
                    dataArray={displayArray}
                    fields={[
                        {id: 'imageUrl', header: "Image", transformFunction: imageUrl => <Thumbnail url={imageUrl} expand={true}/>, hideSort: true},
                        {id: 'productName', header: 'Product Name', transformFunction: (productName, value, key, restockRequest) => <RequestResponse 
                                                                                                                                productName={productName} 
                                                                                                                                nextRestockAt={restockRequest.nextRestockAt}
                                                                                                                                inStock={Boolean(restockRequest.quantityRestocked)}
                                                                                                                                restockedQuantity={restockRequest.quantityRestocked}
                                                                                                                                isDiscontinued={restockRequest.isDiscontinued} 
                                                                                                                                characteristics={restockRequest.characteristics} 
                                                                                                                                productStockId={restockRequest.productStockId} 
                                                                                                                                productId={restockRequest.productId}
                                                                                                                                productStock={productStock}
                                                                                                                              
                                                                                                                            />},
                        {id: 'altProductStockIds', header: 'Suggested Alternatives', hideSort: true, transformFunction: altProductStockIds => altProductStockIds && Object.keys(altProductStockIds).length > 0 ?
                                                                                                                            <ProductVariantTableList 
                                                                                                                              productVariantList={Object.values(altProductStockIds)}
                                                                                                                              emptyText="No suggested alternatives"
                                                                                                                              showCloseButton={false}
                                                                                                                              getListItemChild={(productStockId, productId) => <AddToCartButton 
                                                                                                                                                                                  productId={productId}
                                                                                                                                                                                  productStockId={productStockId}
                                                                                                                                                                                  method="from customer restock request table product variant list"
                                                                                                                                                                              />
                                                                                                                                              }
                                                                                                                            /> : null},
                        {id: 'lastMessage', header: 'Store Message', transformFunction: lastMessage => <EllipsisText text={lastMessage} />},

                        {id: 'sellerName', header: 'From Store', transformFunction: (sellerName, value, key, restockRequest) => <div className="display-flex align-items-center text-align-left">
                                                                                                                                <AvatarIcon 
                                                                                                                                    name={sellerName} 
                                                                                                                                    imageUrl={restockRequest.sellerLogoImageUrl} 
                                                                                                                                    size={AVATAR_SIZE_SMALL}
                                                                                                                                />
                                                                                                                                <div className="space-left-05em actionText" onClick={() => copyToClipboard(capitalizeAllWords(sellerName))}>{capitalizeAllWords(sellerName)}</div>
                                                                                                                        </div>},
                        {id: 'quantity', header: 'Quantity Requested'},
                        {id: 'createdAt', header: "Requested At", transformFunction: createdAt => timestampToDateTimeString(createdAt, true)},
                    ]}
                    defaultSortBy="createdAt"
                    emptyText="No Restock Requests"
                    searchFields={['productName', 'sellerName']}
                    maxRowsPerPage={maxRowsPerPage}
                    rowHighlightFunction={rowHighlightFunction}
                    paginate={true}
                />
        )
    }
}

const RequestResponse = ({productName="", characteristics={}, productId="", productStockId="", nextRestockAt=0, inStock=false, restockedQuantity=0, isDiscontinued=false, productStock={}}) => {
    const stock = productStock.productStockById[productStockId]
    const quantityLeft = stock ? stock.quantityInStock : restockedQuantity
    return(
    <div className="display-flex flex-direction-column align-items-center">
        <div>
            <div>{isDiscontinued ? productName : <Link target="_blank" to={`/product-details?pid=${productId}&psid=${productStockId}`}>{productName}</Link>}</div>
            <div>
                <CharacteristicsList 
                    id={productStockId} 
                    characteristics={characteristics} 
                />
            </div>
        </div>
        {
            inStock ?
            <div>
                <div className="spaced-05em">
                    <AddToCartButton 
                        productId={productId}
                        productStockId={productStockId}
                        method="from customer restock request table"
                    />
                </div>
                <div className="badge primary">{quantityLeft ? `Restocked - ${quantityLeft} left` : "Was restocked...but now sold out again"}</div>
            </div>
            :
            isDiscontinued ?
            <div className="badge error">Discontinued</div>
            :
            nextRestockAt ?
            <div className="badge warning">Restock Scheduled</div>
            :
            null
        }
        {
            nextRestockAt ? 
            <div className="statusTime"> <Icon icon="schedule"/> { timestampToDateString(nextRestockAt, "'Restock by' EEE, dd MMM yy")}</div>
            :
            null
        }
    </div>
    )
}

const mapStateToProps = state => ({
    productStock: state.productStock,
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRestockRequestTable)
