import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, setDoc, getDocs, onSnapshot, query, where, collection } from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"
import { RESTOCK_REQUEST_STATUS_PENDING } from "../constants/restockRequest"
import { DAY_IN_MILLISECONDS } from "../constants/datetime";
import { getTimestampForStartOfDay } from "../utils/datetimeUtils"

export const SAVE_RESTOCK_REQUESTS = 'SAVE_RESTOCK_REQUESTS'
export const CREATE_RESTOCK_REQUEST = 'CREATE_RESTOCK_REQUEST'

export const saveRestockRequests = restockRequests => {
    return {
        type: SAVE_RESTOCK_REQUESTS,
        payload: {
            restockRequests
        }
    }
}

export const createRestockRequest = restockRequest => {
    return {
        type: CREATE_RESTOCK_REQUEST,
        payload: {
            restockRequest
        }
    }
}

export const fetchCreateRestockRequest = (
    id,
    productId,
    productStockId,
    quantity,
    price,
    imageUrl="",
    productName,
    characteristics={},
    skuNumber="",
    sellerId,
    sellerName,
    sellerAlertEmail,
    sellerLogoImageUrl,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp);
    const restockRequestsRef = doc(firestore, "restockRequests", id)
    const restockRequest = {
        id,
        productId,
        productStockId,
        quantity,
        price,
        imageUrl,
        productName,
        characteristics,
        skuNumber,
        sellerId,
        sellerName,
        sellerAlertEmail,
        sellerLogoImageUrl,
        currentStatus: RESTOCK_REQUEST_STATUS_PENDING,
        createdAt: Date.now(),
        closedAt: null
    }
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            restockRequest.createdByUserId = user.id
            restockRequest.userEmail = user.email
            restockRequest.userName = `${user.firstName} ${user.lastName}`
            restockRequest.userProfileImageUrl = user.profileImageUrlMed ? user.profileImageUrlMed : "" 
            restockRequest.userPhone = user.defaultAddressId && user.addressesById ? 
                                user.addressesById[user.defaultAddressId].contactNumber
                                : ""
            await setDoc(restockRequestsRef, restockRequest)
            dispatch(createRestockRequest(restockRequest))
            onSuccess(restockRequest)
            return id
        } catch (e){
            const message = `action > restockRequests > fetchCreateRestockRequest: Failed to create restock request ${JSON.stringify(restockRequest)}`
            const originalMessage = e.message
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(originalMessage)
            return false
        }
    }
}

export const fetchSubscribeToMyRestockRequestsForProduct = (
    productId,
    userId
) => {
    const firestore = getFirestore(firebaseApp)
    return async dispatch => {
        try {
            const restockRequestsRef = query(collection(firestore, "restockRequests"),
                                     where("createdByUserId", "==", userId),
                                     where("productId", "==", productId),
                                     where("closedAt", "==", null)
                                     )
            const restockRequestsListener = await onSnapshot(restockRequestsRef,
                querySnapshot => {
                    //get an array of restock requests from the snapshot
                    const restockRequests = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveRestockRequests(restockRequests))
            })
            return restockRequestsListener
        } catch (e){
            const message = `action > restockRequests > fetchSubscribeToMyRestockRequestsForProduct: Failed to save open restock requests for user ${userId} for product ${productId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSubscribeToMyRestockRequestsInDateRange = (
    userId,
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all restock requests placed in the last 30 days
    toDate=getTimestampForStartOfDay() + DAY_IN_MILLISECONDS
 ) => {
     /**
       * Purpose: retrieve the restock requests from the firestore database created between the specified date range for a specific user
       * Note: the onSnapshot below watches for changes to the objects on the server
       */
     const firestore = getFirestore(firebaseApp)
     const restockRequestsRef = query(collection(firestore, "restockRequests"), 
                             where("createdByUserId", "==", userId),
                             where("createdAt", ">=",  fromDate),
                             where("createdAt", "<=", toDate))      
    return async dispatch => {
        try {
            const restockRequestsListener = await onSnapshot(restockRequestsRef,
                querySnapshot => {
                    //get an array of restock requests from the snapshot
                    const restockRequests = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveRestockRequests(restockRequests))
            })
            return restockRequestsListener
        } catch (e){
            const message = `action > restockRequests > fetchSubscribeToMyRestockRequestsInDateRange: Failed to save restock requests for user ${userId} between ${fromDate} and ${toDate}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchSaveMyLiveRestockRequests = userId => {
    const firestore = getFirestore(firebaseApp)
    const restockRequestsRef = query(collection(firestore, "restockRequests"),
                                     where("createdByUserId", "==", userId),
                                     where("closedAt", "==", null)
                                     )
    return async dispatch => {
        try {
            const querySnapshot = await getDocs(restockRequestsRef)
            //get an array of restockRequests from the snapshot
            const restockRequests = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(saveRestockRequests(restockRequests))
            return true
        } catch (e){
            const message = `action > restockRequests > fetchSaveMyLiveRestockRequests: Failed to save live restockRequests for user ${userId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSubscribeToLiveSellerRestockRequests = sellerId => {
    /**
      * Purpose: retrieve the seller's live restock requests from the firestore database
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const restockRequestsRef = query(collection(firestore, "restockRequests"), 
                                where("closedAt", "==", null),
                                where("sellerId", "==", sellerId))
                                    
    return async dispatch => {
        try {
            const restockRequestsListener = await onSnapshot(restockRequestsRef,
                querySnapshot => {
                    //get an array of restockRequests from the snapshot
                    const restockRequests = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveRestockRequests(restockRequests))
                } 
            )
            return restockRequestsListener
        } catch (e){
            const message = `action > restockRequests > fetchSubscribeToLiveSellerRestockRequests: Failed to save live restockRequests for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSubscribeToRecentlyClosedSellerRestockRequests = (
    sellerId,
    fromDate = Date.now() - DAY_IN_MILLISECONDS//defaults to listening to all restock requests closed in the last day
) => {
    /**
      * Purpose: retrieve the recently closed restock requests for one seller from the firestore database
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const restockRequestsRef = query(collection(firestore, "restockRequests"), 
                                where("closedAt", ">=",  fromDate),
                                where("sellerId", "==", sellerId))
                                    
                                
    return async dispatch => {
        try {
            const restockRequestsListener = await onSnapshot(restockRequestsRef,
                querySnapshot => {
                    //get an array of restock requests from the snapshot
                    const restockRequests = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveRestockRequests(restockRequests))
                } 
            )
            return restockRequestsListener
        } catch (e){
            const message = `action > restockRequests > fetchSubscribeToRecentlyClosedSellerRestockRequests: Failed to save recently closed restock requests for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSubscribeToSellerRestockRequestsInDateRange = (
    sellerId,
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all restock requests placed in the last 30 days
    toDate=getTimestampForStartOfDay() + DAY_IN_MILLISECONDS
) => {
    /**
      * Purpose: retrieve the restock requests from the firestore database created between the specified date range for a specific seller
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const restockRequestsRef = query(collection(firestore, "restockRequests"), 
                            where("sellerId", "==", sellerId),
                            where("createdAt", ">=",  fromDate),
                            where("createdAt", "<=", toDate))                          
    return async dispatch => {
        try {
            const restockRequestsListener = await onSnapshot(restockRequestsRef,
                querySnapshot => {
                    //get an array of restock requests from the snapshot
                    const restockRequests = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveRestockRequests(restockRequests))
                } 
            )
            return restockRequestsListener
        } catch (e){
            const message = `action > restockRequests > fetchSubscribeToSellerRestockRequestsInDateRange: Failed to save restock requests from ${fromDate} to ${toDate} for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSubscribeToAdminRestockRequestsInDateRange = (
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all restock requests placed in the last 30 days
    toDate=getTimestampForStartOfDay() + DAY_IN_MILLISECONDS
) => {
    /**
      * Purpose: retrieve the restock requests from the firestore database created between the specified date range
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const restockRequestsRef = query(collection(firestore, "restockRequests"), 
                            where("createdAt", ">=",  fromDate),
                            where("createdAt", "<=", toDate))                          
    return async dispatch => {
        try {
            const restockRequestsListener = await onSnapshot(restockRequestsRef,
                querySnapshot => {
                    //get an array of restock requests from the snapshot
                    const restockRequests = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveRestockRequests(restockRequests))
                } 
            )
            return restockRequestsListener
        } catch (e){
            const message = `action > restockRequests > fetchSubscribeToAdminRestockRequestsInDateRange: Failed to save restock requests from ${fromDate} to ${toDate}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSubscribeToAdminOpenRestockRequests = () => {
    /**
      * Purpose: retrieve all open restock requests from the firestore database
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const restockRequestsRef = query(collection(firestore, "restockRequests"), 
                            where("closedAt", "==",  null))                          
    return async dispatch => {
        try {
            const restockRequestsListener = await onSnapshot(restockRequestsRef,
                querySnapshot => {
                    //get an array of restock requests from the snapshot
                    const restockRequests = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveRestockRequests(restockRequests))
                } 
            )
            return restockRequestsListener
        } catch (e){
            const message = `action > restockRequests > fetchSubscribeToAdminOpenRestockRequests: Failed to save open restock requests`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSubscribeToAdminRecentlyClosedRestockRequests = (
    fromDate = Date.now() - DAY_IN_MILLISECONDS//defaults to listening to all restock requests closed in the last day
) => {
    /**
      * Purpose: retrieve all restock requests closed in the last day from the firestore database
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const restockRequestsRef = query(collection(firestore, "restockRequests"), 
                                    where("closedAt", ">=",  fromDate))                          
    return async dispatch => {
        try {
            const restockRequestsListener = await onSnapshot(restockRequestsRef,
                querySnapshot => {
                    //get an array of restock requests from the snapshot
                    const restockRequests = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveRestockRequests(restockRequests))
                } 
            )
            return restockRequestsListener
        } catch (e){
            const message = `action > restockRequests > fetchSubscribeToAdminRecentlyClosedRestockRequests: Failed to save recently closed restock requests`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}