import configureStore from "../store/configureStore"
import {fieldValueIsANonNegativeNumber} from "./formValidationUtils"
import { alphabeticalSort, capitalizeAllWords} from "./stringUtils"

export const getVariantDescription = (variant,  showKey=false, capitalize=false, separator="/") => {
    if (! variant || !variant.characteristics) return ""
    return Object.keys(variant.characteristics)
        .reduce((desc, key) => {
            const keyString = capitalize ? capitalizeAllWords(key) : key
            const valueString = capitalize ? capitalizeAllWords(variant.characteristics[key]) : variant.characteristics[key]
            if (!desc) desc = `${showKey ? `${keyString}: `: ""}${valueString}`
            else desc = `${desc} ${separator} ${showKey ? `${keyString}: `: ""}${valueString}`
            return desc
        }, "")
}

export const validateSku = (skuNumber, sellerId, productStockId="") => {
    const error = {isValid: false}
    if (typeof skuNumber !== 'string'){
        error.message = "SKU number is not a string"
        return error
    }
    skuNumber = skuNumber.trim()
    if (!skuNumber) {
        error.message = "No SKU number supplied"
        return error
    }
    const store = configureStore()
    const {productStock, products} = store.getState()
    const sellerSkus = productStock.productStockIdsBySellerSKU[sellerId] ?
                        productStock.productStockIdsBySellerSKU[sellerId]
                        : {}
    //if the sku number is not found in sellerSkus, 
    //or the sellerSkus did not actully exist, because the seller has no stock for any products
    //then the sku is valid
    if (!sellerSkus[skuNumber]) return true
    //if there is an original stock id (because this stock is not being made, but modified)
    //and the original stock id is the stock id indexed for this sku and this seller
    //then the sku has not been changed from what it was and is still valid
    if (productStockId && (sellerSkus[skuNumber] === productStockId)) return true
    
    //from here onward, the sku is known to be invalid, so we construct the error message
    //1. find the original stock indexed for thiu SKU number
    const originalStockWithSku = productStock.productStockById[sellerSkus[skuNumber]]
    //2. find the original product for the original stock
    const originalProduct = products.productsById[originalStockWithSku.productId]
    //3. get the variant description for the original stock
    const originalVariantId = getVariantDescription(originalStockWithSku)
    //return the error message    
    error.message = `Sku number ${skuNumber} is already used in ${originalProduct.titleAndBrand}'s variant ${originalVariantId}`
    error.originalProductStockId = sellerSkus[skuNumber]
    error.originalProductId = originalStockWithSku.productId
    return error
}

export const validatePrice = (price, errors={}) => {
    if (typeof price !== "number" || price <= 0) errors.price = `Price must be greater than zero`
    return errors
}

export const validateQuantityInStock = (qty, errors={}) => {
    if (!fieldValueIsANonNegativeNumber(qty)) errors.quantityInStock = `Quantity in stock must be a non-negative number`
    else if (!Number.isInteger(qty)) errors.quantityInStock = `Quantity must be a whole number like 1, 2, or 50. Not a fraction or decimal like 0.5`
    return errors
}

export const getDisplayVariantId = (product, productStock) => {
    /**
     * Purpose: select the variant which will be displayed by default 
     *          when users view a product in a list or on the product page
     */
    const minimumOrderQty = product.minimumOrderQty ? product.minimumOrderQty : 1
    let stockList = productStock.productStockIdsByProductId[product.id] ? 
                        Object.keys(productStock.productStockIdsByProductId[product.id])
                            .map(productStockId => productStock.productStockById[productStockId])
                            .filter(productStock => !productStock.isDiscontinued)
                        : []
    if (stockList.length === 0) return "" //if the product has no stock (BUG) then return an empty string
    else {
        //sort the stock list in terms of cheapest first
        stockList = stockList.sort((sA, sB) => sA.price - sB.price)
        let displayVariant = stockList[0] //default to the first (and cheapest) stock
        if (stockList.length > 1){
            //if there are multiple variants
            //1. find those that are both in stock and match the product price
            let matchList = [] 
            matchList = stockList.filter(s => s.price === product.price && s.quantityInStock >= minimumOrderQty)
            //2. if there is no match for the product price that is in stock
            //   then find the first one in stock
            if (matchList.length === 0){
                matchList = stockList.filter(s => s.quantityInStock >= minimumOrderQty)
            }
            //3. if there are none in stock, find the first that matches the productPrice
            if (!matchList.length === 0){
                matchList = stockList.filter(s => s.price === product.price)
            }
            if (matchList.length > 0){
                //if there is only one match, update the display variant to this match
                if (matchList.length === 1) displayVariant = matchList[0]
                else {
                    //sort by cheapest first
                    matchList = matchList.sort((mA, mB) => mA.price - mB.price)
                    //filter to only those with the cheapest price
                    matchList = matchList.filter(m => m.price === matchList[0].price)
                    //if there are still more than one matches, then we 
                    //enforce determinism by ordering alphabetically by their id
                    matchList = matchList.sort((mA, mB) => alphabeticalSort(mA.id, mB.id)) 
                    displayVariant = matchList[0]
                }
                
            }
        }
        return displayVariant.id
    }
} 

export const getVariantIdFromCharacteristics = (chars, variantsById) => {
    if (!chars) return "" 
    const variant = Object.values(variantsById).find(v => {
        if (!v.characteristics) return false
        return Object.keys(v.characteristics)
                     .every(attr => v.characteristics[attr] === chars[attr])
    })
    return variant ? variant.id : ""
}

export const getAnalyticsItem = (product, categories, seller) => {
    const category = categories.categoriesById[product.categoryIds[0]]
    const analyticsItem = {
        item_id: product.id,
        item_name: product.brand ? `${product.brand} ${product.title}`: product.title,
        item_category: category ? category.label : "",
        item_brand: seller ? seller.name : "",
        price: product.price
    }
    return analyticsItem
}