import {firebaseApp} from "../config/firebase"
import { getFirestore, collection, query, getDocs } from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"

export const SAVE_TAGS = "SAVE_TAGS"

export const saveTags = tags => {
    return {
        type: SAVE_TAGS,
        payload: {
            tags
        }
    }
}

export const fetchSaveTags = () => {
    const firestore = getFirestore(firebaseApp);
    const tagsRef = query(collection(firestore, "tags"))
    return async dispatch => {
        try {
            const querySnapshot = await getDocs(tagsRef)
            //get an array of tags from the snapshot
            const tags = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(saveTags(tags))
            return true

        } catch (e){
            const message = `action > tags > fetchSaveTags: Failed to save tags`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}