import React from 'react'

import Footer from "../../containers/Footer"
import SearchBar from "../../containers/SearchBar"
import AddedToBasket from '../../containers/AddedToBasket'
import MobileNavigationBar from "../../containers/MobileNavigationBar"

import {Helmet} from "react-helmet";
import BackLink from "../../components/BackLink"

import {connect} from "react-redux"
import {Redirect} from "react-router-dom";

import CustomerRestockRequests from "../../containers/CustomerRestockRequests"
class CustomerRestockRequestsDashboard extends React.Component {

    getUserId(){
        const {match} = this.props
        const {userId} = match.params
        return userId
    }

    goBack = () => {
        const {history} = this.props
        history.push('/')
    }
    
    render() {
        const userId = this.getUserId()
        const {user, history} = this.props
        if (!user.authenticated) return <Redirect to={`/login?redirect-from=/customers/${userId}`} />
        if (!userId) return <Redirect to="/"/>
        if (user.id !== userId) return <Redirect to="/"/>
        return (
            <div className="screen">
                <Helmet>
                    <title>Your Restock Requests</title>
                </Helmet>
                <SearchBar/>
                <div className={["mainContent", "searchBarPresent", "flex-direction-column"].join(" ")}>
                    <AddedToBasket zIndex={500}/>
                    <BackLink text="Continue Shopping" onClick={this.goBack}/>
                    <CustomerRestockRequests />
                    <MobileNavigationBar />
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(CustomerRestockRequestsDashboard)