import React from 'react'
import BuyerAddressForm from "../BuyerAddressForm"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

function EditBuyerAddressForm({addressId, user, actions,postSubmit=()=>{}}){
    const address = user.addressesById[addressId]
    const submit = async (
        line1, 
        line2, 
        city, 
        settlementId, 
        countryName,
        countryId,
        phoneNumber,
        directions,
        usaCity,
        zipCode,
        handleSubmitSuccess, 
        handleSubmitError
    ) => {
        return await actions.fetchEditAddress(
            addressId,
            line1,
            line2,
            city,
            settlementId,
            countryName,
            countryId,
            phoneNumber,
            directions,
            usaCity,
            zipCode,
            handleSubmitSuccess, 
            handleSubmitError
        )
    }
    return (
        <BuyerAddressForm 
            actions={actions}
            address={address} 
            formTitle='Edit Address'
            buttonText='Save Changes'
            postSubmit={postSubmit}
            submit={submit}
        />
    )
}

const mapStateToProps = state => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBuyerAddressForm);