import React from "react"
import styles from "./Loading.module.css"
import LoadAnimation from "../LoadAnimation"

function Loading({isContained=false, text=""}){
    const containedStyle = isContained ? styles.contained : "" 
    return (
        <div className={`${styles.container} ${containedStyle}`}>
            <div className={styles.loader}>
                {text ? <div className="slogan primary">{text}</div>  : null}
                <div className={styles.animation}>
                    <LoadAnimation />
                </div>
            </div>
        </div>
    )
}
export default Loading;