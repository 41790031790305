export const copyToClipboard = str => {
    /**
     * Source: https://www.30secondsofcode.org/blog/s/copy-text-to-clipboard-with-javascript
     */
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};