import React from "react"
import styles from "./FormTextSeparator.module.css"

const FormTextSeparator = ({text="or"}) => {
    return (
        <div className={styles.container}>
            <div className={styles.text}>{text}</div>
        </div>
    )
}

export default FormTextSeparator
