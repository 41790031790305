import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, onSnapshot, runTransaction, increment, updateDoc, where, collection, query } from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"
import { DAY_IN_MILLISECONDS } from "../constants/datetime";
import {STATISTICS_IDENTIFIER} from '../constants/analysis'

export const SAVE_TOPUPS = 'SAVE_TOPUPS'
export const CREATE_TOPUP = 'CREATE_TOPUP'
export const CLEAR_CURRENT_TOPUP = 'CLEAR_CURRENT_TOPUP'

export const saveTopups = topups => {
    return {
        type: SAVE_TOPUPS,
        payload: {
            topups
        }
    }
}

export const createTopup = topup => {
    return {
        type: CREATE_TOPUP,
        payload: {
            topup
        }
    }
}

export const clearCurrentTopup = () => {
    return {
        type: CLEAR_CURRENT_TOPUP
    }
}

export const fetchCreateTopup = (
    id,
    recipientWalletId,
    recipientWalletName,
    paymentProviderId,
    paymentProviderName,
    totalXcd,
    topupAmountXcd,
    processingFeeXcd,
    shopdmCommissionEarningsXcd,
    paymentProcessorFeeXcd,
    senderId,
    senderEmail,
    senderPhone,
    senderName,
    currentStatus,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp);
    const topupsRef = doc(firestore, "topups", id)
    const statsRef = doc(firestore, 'topups', STATISTICS_IDENTIFIER);
    const incrementValue = increment(1);
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    const day = now.getDate()
    const topup = {
        id,
        recipientWalletId,
        recipientWalletName,
        paymentProviderId,
        paymentProviderName,
        totalXcd,
        topupAmountXcd,
        processingFeeXcd,
        shopdmCommissionEarningsXcd,
        paymentProcessorFeeXcd,
        senderId,
        senderEmail,
        senderPhone,
        senderName,
        currentStatus,
        topupStatus: {[currentStatus]: Date.now()},
        createdAt: Date.now()
    }
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            topup.createdByUserId = user.id
            const successful = await runTransaction(firestore, async transaction =>{
                //generate topup number based on the current topup count for the year
                const statsDocRef = await transaction.get(statsRef)
                const previousCount = statsDocRef.data()[`count${year}`] ? Number(statsDocRef.data()[`count${year}`]) : 0
                topup.reference = `${previousCount + 1}${year}TOPUP`
                await transaction.update(statsRef,{
                    count: incrementValue,
                    [`count${year}`]: incrementValue,
                    [`count${year}-${month}`]: incrementValue,
                    [`count${year}-${month}-days.${day}`]: incrementValue,
                })
                await transaction.set(topupsRef, topup)
            })
            dispatch(createTopup(topup))
            onSuccess(topup)
            return id
        } catch (e){
            const message = `action > topups > fetchCreateTopup: Failed to create topup ${JSON.stringify(topup)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(topup)
            return false
        }
    }
}

export const fetchUpdateTopup = (
    id,
    recipientWalletId,
    recipientWalletName,
    paymentProviderId,
    paymentProviderName,
    totalXcd,
    topupAmountXcd,
    processingFeeXcd,
    shopdmCommissionEarningsXcd,
    paymentProcessorFeeXcd,
    senderId,
    senderEmail,
    senderPhone,
    senderName,
    currentStatus,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp);
    const topupsRef = doc(firestore, "topups", id)
    const topup = {
        recipientWalletId,
        recipientWalletName,
        paymentProviderId,
        paymentProviderName,
        totalXcd,
        topupAmountXcd,
        processingFeeXcd,
        shopdmCommissionEarningsXcd,
        paymentProcessorFeeXcd,
        senderId,
        senderEmail,
        senderPhone,
        senderName,
        lastUpdatedAt: Date.now()
    }
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            topup.lastUpdatedByUserId = user.id
            updateDoc(topupsRef, topup)
            // dispatch(createTopup(topup))
            onSuccess(topup)
            return id
        } catch (e){
            const message = `action > topups > fetchUpdateTopup: Failed to update topup ${JSON.stringify(topup)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(topup)
            return false
        }
    }
}

export const fetchSubscribeToTopup = (topupId) => {
    /**
      * Purpose: retrieve one topup from the firestore database
      * Note: the onSnapshot below watches for changes on the server
      */
    const firestore = getFirestore(firebaseApp)
    const topupsRef = doc(firestore, "topups", topupId)
    return async dispatch => {
        try {
            const topupsListener = await onSnapshot(topupsRef, 
                docRef => {
                    const topup = {...docRef.data()};
                    dispatch(saveTopups([topup]))
            })
            return topupsListener
        } catch (e){
            const message = `action > topups > fetchSubscribeToTopup: Failed to save topup`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchSubscribeToTopupsByDate = (
    walletId, 
    fromDate = Date.now() - (30 * DAY_IN_MILLISECONDS)
) => {
    /**
      * Purpose: retrieve all topups to a particular wallet since a given date from the firestore database
      * Note: the onSnapshot below watches for changes on the server
      */
    const firestore = getFirestore(firebaseApp)
    const topupsRef = query(collection(firestore, "topups"),
                            where("recipientWalletId", "==", walletId),
                            where("createdAt", ">=", fromDate))

    return async dispatch => {
        try {
            const topupsListener = await onSnapshot(topupsRef, 
                querySnapshot =>{
                    //get an array of products from the snapshot
                    const topups = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveTopups(topups))
            })
            return topupsListener
        } catch (e){
            const message = `action > topups > fetchSubscribeToTopupsByDate: Failed to save topups for wallet ${walletId} since date ${fromDate}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchSaveAdminTopupsInDateRange = (
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all topups placed in the last 30 days
    toDate=Date.now()
) => {
    /**
      * Purpose: retrieve the topups from the firestore database created between the specified date range
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const topupsRef = query(collection(firestore, "topups"), 
                            where("createdAt", ">=",  fromDate),
                            where("createdAt", "<=", toDate))                          
    return async dispatch => {
        try {
            const topupsListener = await onSnapshot(topupsRef,
                querySnapshot => {
                    //get an array of topups from the snapshot
                    const topups = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveTopups(topups))
                } 
            )
            return topupsListener
        } catch (e){
            const message = `action > topups > fetchSaveAdminTopupsInDateRange: Failed to save topups from ${fromDate} to ${toDate}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}