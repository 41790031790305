import {firebaseApp} from "../config/firebase"
import {doc, getFirestore, setDoc, updateDoc, deleteField, onSnapshot} from "firebase/firestore"
import {fetchSaveProductsInCart} from "./products"
import { logError } from "../utils/errorHandlingUtils"
import {v4 as uuid4} from 'uuid'

export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const UPDATE_CART_ITEM_QUANTITY = 'UPDATE_CART_ITEM_QUANTITY'
export const SELECT_DELIVERY_PROVIDER_FOR_SELLER = 'SELECT_DELIVERY_PROVIDER_FOR_SELLER'
export const SELECT_DELIVERY_SLOT = 'SELECT_DELIVERY_SLOT'
export const DESELECT_DELIVERY_SLOT = 'DESELECT_DELIVERY_SLOT'
export const ADD_SELLER_TO_DELIVERY_SLOT = 'ADD_SELLER_TO_DELIVERY_SLOT'
export const SELECT_PICKUP_ADDRESS_FOR_SELLER = 'SELECT_PICKUP_ADDRESS_FOR_SELLER'
export const SELECT_PAYMENT_PROVIDER = 'SELECT_PAYMENT_PROVIDER'
export const DESELECT_PAYMENT_PROVIDER = 'DESELECT_PAYMENT_PROVIDER'
export const SELECT_PAY_WITH_SHOPDM_CREDIT = 'SELECT_PAY_WITH_SHOPDM_CREDIT'
export const DESELECT_PAY_WITH_SHOPDM_CREDIT = 'DESELECT_PAY_WITH_SHOPDM_CREDIT'
export const UPDATE_GIFT_PARAMETERS = 'UPDATE_GIFT_PARAMETERS'
export const UPDATE_ORDER_NOTES = 'UPDATE_ORDER_NOTES'
export const SAVE_CART = 'SAVE_CART'
export const CREATE_CART = 'CREATE_CART'
export const MERGE_CART = 'MERGE_CART'
export const UPDATE_SELLER_READINESS_ESTIMATIONS = 'UPDATE_SELLER_READINESS_ESTIMATIONS'
export const UPDATE_DELIVERY_FULFILLMENT_ESTIMATIONS = 'UPDATE_DELIVERY_FULFILLMENT_ESTIMATIONS'
export const UPDATE_WEEKLY_AVAILABLE_DELIVERY_SLOTS = 'UPDATE_WEEKLY_AVAILABLE_DELIVERY_SLOTS'

export const addToCart = (productStock, quantity, cartId, deliveryProviderId) => {
    return {
        type: ADD_TO_CART,
        payload: {
            item: {
                ...productStock,
                quantity
            },
            cartId,
            deliveryProviderId
        }
    }
}

export const removeFromCart = productStockId => {
    return {
        type: REMOVE_FROM_CART,
        payload: {
            itemId: productStockId
        }
    }
}

export const clearCart = cartId => {
    return {
        type: CLEAR_CART,
        payload: {cartId}
    }
}

export const createCart = id => {
    return {
        type: CREATE_CART,
        payload: {id}
    }
}

export const mergeCart = cart => {
    return {
        type: MERGE_CART,
        payload: {cart}
    }
}

export const saveCart = cart => {
    return {
        type: SAVE_CART,
        payload: {
            cart
        }
    }
} 

export const updateCartItemQuantity = (itemId, quantity) => {
    return {
        type: UPDATE_CART_ITEM_QUANTITY,
        payload: {
            itemId,
            quantity
        }
    }
}

export const selectDeliveryProviderForSeller = (deliveryProviderId, sellerId) => {
    return {
        type: SELECT_DELIVERY_PROVIDER_FOR_SELLER,
        payload: {deliveryProviderId, sellerId}
    }
}

export const selectDeliverySlot = (deliverySlot, sellerIds={}) => {
    return {
        type: SELECT_DELIVERY_SLOT,
        payload: {
            deliverySlot,
            sellerIds
        }
    }
}

export const deselectDeliverySlot = deliverySlotId => {
    return {
        type: DESELECT_DELIVERY_SLOT,
        payload: {deliverySlotId}
    }
}

export const addSellerToDeliverySlot = (deliverySlotId, sellerId) => {
    return {
        type: ADD_SELLER_TO_DELIVERY_SLOT,
        payload: {
            deliverySlotId,
            sellerId
        }
    }
}

export const selectPaymentProvider = paymentProviderId => {
    return {
        type: SELECT_PAYMENT_PROVIDER,
        payload: {paymentProviderId}
    }
}

export const deselectPaymentProvider = () => {
    return {
        type: DESELECT_PAYMENT_PROVIDER,
    }
}

export const selectPayWithShopdmCredit = (clearPaymentProvider) => {
    return {
        type: SELECT_PAY_WITH_SHOPDM_CREDIT,
        payload: {
            clearPaymentProvider
        }
    }
}

export const deselectPayWithShopdmCredit = () => {
    return {
        type: DESELECT_PAY_WITH_SHOPDM_CREDIT,
    }
}

export const selectPickupAddressForSeller = (addressId, sellerId) => {
    return async (dispatch, getState) => {
        const sellers = getState().sellers
        const seller = sellers.sellersById[sellerId]
        if (!seller || !seller.addressesById[addressId]) addressId = ""
        dispatch({
            type: SELECT_PICKUP_ADDRESS_FOR_SELLER,
            payload: {addressId, sellerId}
        })
    }
}

export const updateGiftParameters = giftParameters => {
    return {
        type: UPDATE_GIFT_PARAMETERS,
        payload: {
            giftParameters
        }
    }
}

export const updateOrderNotes = orderNotes => {
    return {
        type: UPDATE_ORDER_NOTES,
        payload: {
            orderNotes
        }
    }
}

export const updateSellerReadinessEstimations = sellerReadinessEstimationsBySellerId => {
    return {
        type: UPDATE_SELLER_READINESS_ESTIMATIONS,
        payload: {
            sellerReadinessEstimationsBySellerId
        }
    }
}

export const updateDeliveryFulfillmentEstimations = deliveryFulfillmentEstimationsByDeliverySelectionId => {
    return {
        type: UPDATE_DELIVERY_FULFILLMENT_ESTIMATIONS,
        payload: {
            deliveryFulfillmentEstimationsByDeliverySelectionId
        }
    }
}

export const updateWeeklyAvailableDeliverySlots = weeklyAvailableDeliverySlotsByDeliverySelectionId => {
    return {
        type: UPDATE_WEEKLY_AVAILABLE_DELIVERY_SLOTS,
        payload: {
            weeklyAvailableDeliverySlotsByDeliverySelectionId
        }
    }
}

export const fetchCreateCart = (
    onSuccess = () =>{},
    onError = () =>{}
) =>{
    return async (dispatch, getState) => {
        try{
            const {cart, user} = getState()
            let id = cart.id
            if (user.authenticated){
                if (!id) id = uuid4()
                const firestore = getFirestore(firebaseApp)
                const cartsRef = doc(firestore, "carts", id)
                const creationData = {
                    id,
                    createdAt: Date.now(),
                    createdByUserId: user.id,
                    //set the current user as the owner
                    roles: {[user.id]: 'owner'}
                }
                setDoc(cartsRef, {
                    itemsByProductStockId: {
                        ...cart.itemsByProductStockId,
                    },
                    lastModifiedAt: Date.now(),
                    lastModifiedByUserId: user.id,
                    ...creationData
                }, {merge: true})
            }
            dispatch(createCart(id))
            onSuccess(id)
            return true
        } catch (e){
            const message = `action > products > fetchCreateCart: Failed to create new cart on server`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}

export const fetchAddToCart = (
    productStock, 
    quantity, 
    deliveryProviderId,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    return async (dispatch, getState) => {
        try{
            //get the current cart
            const {cart, user} = getState()
            let id = cart.id
            const cartData = {}
            let creationData = {}
            if (user.authenticated){
                //if a user is logged in sync to server
                if (!id){
                    //if this cart does not have an id, then it is being created, we only create carts for logged in users 
                    id = uuid4()
                    creationData = {
                        id,
                        createdAt: Date.now(),
                        createdByUserId: user.id,
                        //if the cart is being created, set the current user as the owner
                        roles: {[user.id]: 'owner'}
                    }
                }
                const firestore = getFirestore(firebaseApp)
                const cartsRef = doc(firestore, "carts", id)

                setDoc(cartsRef, {
                    itemsByProductStockId: {
                        ...cart.itemsByProductStockId,
                        [productStock.id]: {
                            ...productStock,
                            quantity
                        }
                    },
                    lastModifiedAt: Date.now(),
                    lastModifiedByUserId: user.id,
                    ...creationData
                }, {merge: true})
            }
            dispatch(addToCart(productStock,quantity, id, deliveryProviderId))
            onSuccess(cartData)
            return true
        } catch (e){
            const message = `action > products > fetchAddToCart: Failed to save cart to server while adding ${quantity} of ${JSON.stringify(productStock)} with delivery provider ${deliveryProviderId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(productStock)
            return false
        }
        
    }
}


export const fetchRemoveFromCart = (
    productStockId,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    return async (dispatch, getState) => {
        try{
            const {cart, user} = getState()
            //if the cart exists on the server, user is logged in, this is the only case where remove affects the server
            if (cart.id && user.authenticated){
                //if the cart is synced to the server
                const itemsByProductStockId = {...cart.itemsByProductStockId}
                delete itemsByProductStockId[productStockId]
                const firestore = getFirestore(firebaseApp)
                const cartsRef = doc(firestore, "carts", cart.id)
                const deletionData = {}
                if (Object.keys(itemsByProductStockId).length === 0){
                    //if there are no more items after this one is removed, delete the object by ssetting the deletionData
                    deletionData.deletedByUserId = user.id
                    deletionData.deletedAt = Date.now()
                }
                updateDoc(cartsRef, {
                    [`itemsByProductStockId.${productStockId}`]: deleteField(),
                    lastModifiedAt: Date.now(),
                    lastModifiedByUserId: user.id,
                    ...deletionData
                }) 
            } //otherwise the user is not logged in and all operations are on-device
            dispatch(removeFromCart(productStockId))
            return true
        } catch (e){
            const message = `action > products > fetchRemoveFromCart: Failed to save cart to server while removing ${productStockId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(productStockId)
            return false
        }
        
    }
}

export const fetchSubscribeToCart = (
    cartId,
    onSuccess = ()=>{},
    onError = ()=>{}
) => {
    return async (dispatch, getState) => {
        try{
            const {user, products} = getState()
            const firestore = getFirestore(firebaseApp)
            const cartRef = doc(firestore, 'carts', cartId)
            const cartListener = await onSnapshot(cartRef,
                async docRef => {
                    const cart = docRef.data()
                    if (!cart || !cart.id) {
                        dispatch(clearCart(cartId))
                        return
                    }
                    const cartProductIds = Object.values(cart.itemsByProductStockId).map(item => item.productId)
                    //check whether any of the products are falsy
                    const productIdsNotLoaded = []
                    cartProductIds.forEach(productId => {
                        if (!products.productsById[productId]) productIdsNotLoaded.push(productId)
                    })
                    if (productIdsNotLoaded.length > 0) await dispatch(fetchSaveProductsInCart(productIdsNotLoaded))
                    dispatch(saveCart(cart))
                    if (cart.deletedAt && user.carts[cartId]){
                        //if the cart has been deleted, remove the cartId from the carts map on user
                        fetchUnsubscribeFromCart(user.id, cartId)
                    }
                }
            )
            return cartListener
        } catch (e){
            const message = `action > products > fetchSubscribeToCart: Failed to save cart with id ${cartId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(cartId)
            return false
        }
    }
}

export const fetchUnsubscribeFromCart = async (userId, cartId) => {
    /**
     * This is where the cart object on the server user object is maintained.
     * It is maintained here because otherwise, we would need to trigger a cloud function everytime
     * the cart is changed, i.e. any time something is added to cart or a quantity is changed.
     * 
     * Instead, this function is triggered whenever the a frontend client realises a cart has been deleted
     */
    const firestore = getFirestore(firebaseApp)
    const userRef = doc(firestore, 'users', userId)
    updateDoc(userRef, {
        [`carts.${cartId}`]: deleteField()
    })
    return () => true
}

export const fetchDeleteCart = async (userId, cartId) => {
    const firestore = getFirestore(firebaseApp)
    console.log(userId, cartId)
    //do not try to delete a cart if its user or careId does not exist
    if (cartId && userId) { 
        const cartRef = doc(firestore, 'carts', cartId)
        updateDoc(cartRef, {
            deletedByUserId: userId,
            deletedAt: Date.now()
        })
    }
    return () => true
}

export const fetchMergeCart = (
    cartId,
    onSuccess = ()=>{},
    onError = ()=>{}
) => {
    return async (dispatch, getState) => {
        try{
            const {cart, user} = getState()
            const cartToMerge = cart.cartsToMergeByCartId[cartId]
            //if the cart is no longer in cart ids to merge
            if (!cartToMerge) return true
            const firestore = getFirestore(firebaseApp)
            const cartRef = doc(firestore, 'carts', cart.id)
            setDoc(cartRef, {
                itemsByProductStockId: {
                    ...cart.itemsByProductStockId,
                    ...cartToMerge.itemsByProductStockId,
                },
                lastModifiedAt: Date.now(),
                lastModifiedByUserId: user.id,
            }, {merge: true})
            dispatch(mergeCart(cartToMerge))
            //delete the merged cart 
            fetchDeleteCart(user.id, cartId)
            return true
        } catch (e){
            const message = `action > products > fetchMergeCart: Failed to merge cart with id ${cartId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(cartId)
            return false
        }
    }
}

export const fetchUpdateCartItemQuantity = (
    itemId, 
    quantity,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    return async (dispatch, getState) => {
        try{
            //get the current cart
            const {cart, user} = getState()
            if (!cart.itemsByProductStockId[itemId]) return true
            if (user.authenticated && cart.id){
                //if a user is logged in sync to server
                const firestore = getFirestore(firebaseApp)
                const cartsRef = doc(firestore, "carts", cart.id)
                setDoc(cartsRef, {
                    itemsByProductStockId: {
                        ...cart.itemsByProductStockId,
                        [itemId]: {
                            ...cart.itemsByProductStockId[itemId],
                            quantity
                        }
                    },
                    lastModifiedAt: Date.now(),
                    lastModifiedByUserId: user.id,
                }, {merge: true})
            }
            dispatch(updateCartItemQuantity(itemId, quantity))
            onSuccess(itemId)
            return true
        } catch (e){
            const message = `action > products > fetchUpdateCartItemQuantity: Failed to update the quantity of item ${itemId} to ${quantity}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(itemId)
            return false
        }
        
    }
}

export const fetchDeselectDeliverySlot = (
    deliverySlotId,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    return async (dispatch) => {
        try {
            dispatch(deselectDeliverySlot(deliverySlotId))
            onSuccess(deliverySlotId)
            return true
        } catch (e){
            const message = `action > products > fetchDeselectDeliverySlot: Failed to deselect the delivery slot ${deliverySlotId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(deliverySlotId)
            return false
        }
    }
}