export const isValidUrl = url => url.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g)

export const isValidEmail = email => email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/g)
export const isValidPhoneNumber = (phoneNumber, areaCode="+1767", validDigitCount=7) => {
    const string = "^\\areaCode\\d{validDigitCount}$".replace("validDigitCount", validDigitCount).replace("areaCode", areaCode)
    const re = new RegExp(string, "g")
    return re.test(phoneNumber)
}

export const isValidPassword = password => {
    if (!typeof password === "string") return false
    //passwords must be at least 8 characters
    if (password.length < 8) return false
    //passwords must have at least one number and one uppercase character
    return password.match(/^(?=.*[A-Z])(?=.*\d).*$/g)
}

export const isValidUSAZipCode = zipCode => zipCode.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/g)

export const passwordFieldValueMatchesConfirmPassword = (passwordInput, confirmPasswordInput) => passwordInput === confirmPasswordInput
export const fieldValueIsANonNegativeNumber = (value) => !isNaN(value) && value >= 0 
                                                        && ((typeof value === "number") ||
                                                        ((typeof value === "string") && (
                                                            value.match(/^(0|([1-9][0-9]*))(\.[0-9]*)?$/g)
                                                        )))

export const isValidVerificationCode = verificationCode => verificationCode.match(/^\d{4}$/g)

export const handleChangeCurrencyValue = (e, min=null, max=null, ) => {
    let value = e.target.value
    if (value === "") value = value
    else {
        const minSatisfied = typeof min !== 'number' || value >= min
        const maxSatisfied = typeof max !== 'number' || value <= max
        if(
            minSatisfied && 
            maxSatisfied &&
            value.match(/^(0+|([1-9][0-9]*))?(\.|\.[0-9]?[0-9])?$/g)
        ) return value
        else return undefined
    }
    
    return value
}

export const isAlphaNumeric = value => {
    if (!value || typeof value !== "string") return false
    return value.match(/^[a-zA-Z0-9]*$/);
}