import React from "react"
import Icon from "../../components/Icon"
import AccountNeededModal from "../../components/AccountNeededModal"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"
import {capitalizeAllWords} from "../../utils/stringUtils"

import {logAnalyticsEvent} from "../../config/firebase"

class FollowButton extends React.Component {
    
    state = {
        modalOpen: false
    }

    handleOpenModal = () => this.setState({modalOpen: true})
    handleCloseModal = () => this.setState({modalOpen: false})

    handleClickFollow = () => {
        const {user, actions, sellerId} = this.props
        if (user.authenticated) {
            actions.fetchFollowSeller(user.id, sellerId)
            logAnalyticsEvent("follow_seller", {userId: user.id, sellerId})
            actions.fetchLogDeviceSessionAction({
                action: "followSeller",
                userId: user.id,
                sellerId
            })
        } else this.handleOpenModal()
        
    }
    handleClickUnfollow = () => {
        const {user, actions, sellers, sellerId} = this.props
        const seller = sellers.sellersById[sellerId]
        if (user.authenticated){
            if (!window.confirm(`You've already followed ${capitalizeAllWords(seller.name)}, would you like to unfollow them?`)) return
            actions.fetchUnfollowSeller(user.id, sellerId)
            logAnalyticsEvent("unfollow_seller", {userId: user.id, sellerId})
            actions.fetchLogDeviceSessionAction({
                action: "unfollowSeller",
                userId: user.id,
                sellerId
            })
        }
    }

    handleAuthSuccess = () => {
        this.handleCloseModal()
        this.handleClickFollow()
    }
    render (){
        const {user, sellerId, sellers} = this.props
        const {modalOpen} = this.state
        const seller = sellers.sellersById[sellerId]
        if (!seller) return ""
        return (
            <React.Fragment>
                {
                    user.follows && user.follows[sellerId] ?
                    <button className="button primary icon" onClick={this.handleClickUnfollow}><Icon icon="done"/>Following</button> 
                    :
                    <button className="button primary icon" onClick={this.handleClickFollow}><Icon icon="person-add"/>Follow</button>
                }
                {
                    modalOpen ? 
                    <AccountNeededModal 
                        title={`to follow ${seller.name}`}
                        modalOpen={modalOpen}
                        handleCloseModal={this.handleCloseModal}
                        handleSuccess={this.handleAuthSuccess}
                    />
                    :
                    null
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    sellers: state.sellers,
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(FollowButton)