import React from "react"
import styles from "./BlackFriday2021.module.css"
import CountDown from "../../components/CountDown"
import Icon from "../../components/Icon"
import AvatarIcon from "../../components/AvatarIcon"
import BackLink from "../../components/BackLink"
import ProductTileList from "../../components/ProductTileList"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {withRouter} from "react-router-dom"

import {
    BLACK_FRIDAY_2021_ID,
    EVENT_STATUS_CREATED,
    EVENT_STATUS_ONGOING,
    EVENT_STATUS_ENDED
} from "../../constants/events"

import {
    PROMOTION_APPLIES_TO_PRODUCTS_TYPE_ALL,
    PROMOTION_APPLIES_TO_PRODUCTS_TYPE_SPECIFIC,
    PROMOTER_TYPE_SELLER
} from "../../constants/promotions"

import {AVATAR_SIZE_MEDIUM, AVATAR_SIZE_SMALL} from "../../constants/interface"
import AccountNeededModal from "../../components/AccountNeededModal"
import PopupAlert from "../../components/PopupAlert"

import {logAnalyticsEvent} from "../../config/firebase"

class BlackFriday2021 extends React.Component {

    state = {
        authModalOpen: false,
        notificationsModalOpen: false
    }

    componentDidMount(){
        const {actions} =  this.props
        logAnalyticsEvent("black_friday_visit_page")
        actions.fetchLogDeviceSessionAction({
            action: "blackFridayVisitPage"
        })
    }

    notificationSupported = () =>
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window
    
    handleOpenAuthModal = () => {
        const {actions} = this.props
        logAnalyticsEvent("black_friday_open_auth")
        actions.fetchLogDeviceSessionAction({
            action: "blackFridayOpenAuth"
        })
        this.setState({authModalOpen: true})
    }

    handleCloseAuthModal = () => {
        const {user, actions} = this.props
        //if the user is now authenticated, ask them to 
        //accept notifications
        this.setState({authModalOpen: false}, 
            () => {
                if (user.authenticated){
                    logAnalyticsEvent("black_friday_login_or_create_account", user.id)
                    actions.fetchLogDeviceSessionAction({
                        action: "blackFridayLoginOrCreateAccount",
                        user: user.id,
                    })
                    this.handleClickNotify()
                }
            })
    }

    handleOpenNotificationsModal = () => this.setState({notificationsModalOpen: true})
    handleCloseNotificationsModal = (response = false) => {
        this.setState({notificationsModalOpen: false}, 
            () => {
                if (response) this.subscribeUserToEventNotifications()
            })
    }
    
    subscribeUserToEventNotifications = async () => {
        const {user, device, actions} =  this.props
        if (window.innerWidth < 500){
            alert("We don't support phone notifications yet, so we will email you when the event starts. To get desktop notifications, please visit this page from a computer.")
            actions.fetchSubscribe(user.email)
            actions.subscribeUserToEvent(BLACK_FRIDAY_2021_ID)
            logAnalyticsEvent("black_friday_phone_mailing_list", user.id)
            actions.fetchLogDeviceSessionAction({
                action: "blackFridayPhoneMailingList",
                user: user.id,
                email:  user.email
            })
            return 
        }
        const {messagingToken} = await actions.fetchUpdateDeviceMessagingToken(device.id)
        if (messagingToken && device.id){
            actions.fetchSubscribeUserToEvent(
                user.id,
                BLACK_FRIDAY_2021_ID,
                device.id,
                messagingToken
            )
            logAnalyticsEvent("black_friday_web_notifications_granted", user.id)
            actions.fetchLogDeviceSessionAction({
                action: "blackFridayWebNotificationsGranted",
                user: user.id,
                device:  device.id
            })
        }
    }
    handleClickNotify = async () => {
        const {user} =  this.props
        if (!user.authenticated){
            //if the user is not authenticated, prompt them to make an account
            this.handleOpenAuthModal()
        } else {
            //if we dont not have permission to use notifications, get them from the user
            if (this.notificationSupported() && Notification && Notification.permission !== "granted") {
                this.handleOpenNotificationsModal()
            } else this.subscribeUserToEventNotifications()
        }
    }

    render(){
        const {events, promotions, sellers, user, history, products} = this.props
        const {authModalOpen, notificationsModalOpen} = this.state
        const event = events.eventsById[BLACK_FRIDAY_2021_ID]
        if (!event) return ""
        const sellerList = Object.keys(event.sellerIds).map(sellerId => {
            const seller = sellers.sellersById[sellerId]
            return seller
        })

        const handleClickSeller = sellerId => history.push(`/sellers/${sellerId}`)

        
        const promotedProductIdsBySellerId = {}
        //set up empty maps for each seller 
        sellerList.forEach(seller => promotedProductIdsBySellerId[seller.id] = {})
        //go thorough each promotion
        Object.values(promotions.promotionsById).forEach(promotion => {
            //verify that the promoter is a seller 
            if (promotion.promoterType === PROMOTER_TYPE_SELLER){
                //if the seller is somehow not in the map, add them
                if (!promotedProductIdsBySellerId[promotion.promoterId]) promotedProductIdsBySellerId[promotion.promoterId] = {}
                //if the promo applies to all their products
                if (promotion.appliesToProductsType === PROMOTION_APPLIES_TO_PRODUCTS_TYPE_ALL){
                    //grab all of the sellers products
                    promotedProductIdsBySellerId[promotion.promoterId] = products.productIdsBySellerId[promotion.promoterId] ?
                                                                        products.productIdsBySellerId[promotion.promoterId] : {}
                } else if (promotion.appliesToProductsType === PROMOTION_APPLIES_TO_PRODUCTS_TYPE_SPECIFIC) {
                    Object.keys(promotion.terms.appliesToProductIds).forEach(productId => {
                        if (
                            products.productIdsBySellerId[promotion.promoterId] && 
                            products.productIdsBySellerId[promotion.promoterId][productId]
                            ) promotedProductIdsBySellerId[promotion.promoterId][productId] = true
                    })
                }   
            }
        })

        sellerList.sort((sA, sB) => {
            const sALength = promotedProductIdsBySellerId[sA.id] ? Object.keys(promotedProductIdsBySellerId[sA.id]).splice(0, 3).length : 0
            const sBLength = promotedProductIdsBySellerId[sB.id] ? Object.keys(promotedProductIdsBySellerId[sB.id]).splice(0, 3).length : 0             
            return sBLength - sALength
        })
        return (
            <div className={styles.container}>
                <div>
                    <div className={styles.homeLink}><BackLink text="Go To Homepage" path="/"/></div>
                    <div className={styles.hero}>Black Friday <span>Sale</span></div>
                    {
                            event.currentStatus === EVENT_STATUS_CREATED ?
                            <React.Fragment>
                                <div className={styles.startsIn}>Starts in:</div>
                                <div className={styles.timer}><CountDown endTime={event.startDate}/></div>
                                {
                                    user.events && user.events[event.id] & event.currentStatus === EVENT_STATUS_CREATED?
                                    <div className={styles.subscribed}><Icon icon="done"/> All Done! We'll Alert You When The Sale Starts</div>
                                    :
                                    (user.events || user.events[event.id]) && event.currentStatus === EVENT_STATUS_CREATED?
                                    <button 
                                        className={`button big ${styles.notifyButton}`}
                                        onClick={this.handleClickNotify}
                                    >
                                        <Icon icon="notifications"/> {user.authenticated ? 'Notify Me When It Starts' : 'Sign Up To Get Notified When It Starts'}
                                    </button>
                                    :
                                    null
                                }
                            </React.Fragment>
                            :
                            null
                    }
                    <div className={styles.off}>Up to 40% off at Participating Sellers:</div>
                    <div className={styles.timing}>From midnight on Thursday 25th to midnight Monday 29th</div>
                </div>
                <div>
                    
                    {
                        sellerList.map(seller => {
                            const productList = Object.keys(promotedProductIdsBySellerId[seller.id])
                                                          .splice(0, 3)
                                                          .map(productId => products.productsById[productId])
                            return (
                                <div key={`${seller.id}products`} className={styles.sellerDeal} onClick={() => handleClickSeller(seller.id)}>
                                    <div className={styles.seeMore}>
                                        <div className={styles.seeSeller}>
                                        <AvatarIcon 
                                            name={seller.name} 
                                            imageUrl={seller.logoImageUrlSmall} 
                                            size={AVATAR_SIZE_SMALL}
                                        />
                                        <div className={styles.seeMoreName}>{seller.name}</div>
                                        <div>See All Discounts</div>
                                        </div>
                                        <div className={styles.seeAll}><Icon icon="keyboard-arrow-right"/></div>
                                    </div>
                                    <ProductTileList productList={productList} />
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    <div className={styles.off}>Click on any of the sellers below to see their exclusive deals!</div>
                    <div className={styles.sellerContainer}>
                        <div className={styles.sellers}>
                            
                            {
                                sellerList.map(seller => {
                                    return (
                                        <div key={seller.id} className={styles.sellerIcon} onClick={() => handleClickSeller(seller.id)}>
                                            <AvatarIcon 
                                                name={seller.name} 
                                                imageUrl={seller.logoImageUrl} 
                                                size={AVATAR_SIZE_MEDIUM}
                                            />
                                            <div className={styles.sellerName}>{seller.name}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                {
                    authModalOpen ?

                    <AccountNeededModal 
                        title={`Sign up for Black Friday alerts`}
                        modalOpen={authModalOpen}
                        handleCloseModal={this.handleCloseAuthModal}
                    />
                    :
                    null
                }
                {
                    notificationsModalOpen ?

                    <PopupAlert
                        title={`Get Alerted When The Black Friday Sale Starts?`}
                        modalOpen={notificationsModalOpen}
                        handleCloseModal={this.handleCloseNotificationsModal}
                    />
                    :
                    null
                }
            </div>
        )
    }
}


const mapStateToProps = state => ({
    events: state.events,
    sellers: state.sellers,
    products: state.products,
    user: state.user,
    device: state.device,
    promotions: state.promotions
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BlackFriday2021))