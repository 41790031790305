import React, {useState, useEffect, useRef} from "react"
import IconButton from "../IconButton"
import styles from "./ActionMenu.module.css"

const ActionMenu = ({actions=[], iconHasBorder=false, iconHasShadow=true}) => {
    const menuRef = useRef(null)
    const [menuOpen, setMenuOpen] = useState(false)
    useEffect(() => {
        if (menuOpen && menuRef.current) {
            menuRef.current.focus()
        }
    })
    return (
        <div className={styles.container}>
            <IconButton 
                icon="more-vert" onPress={() => setMenuOpen(!menuOpen)}
                hasShadow={iconHasShadow}
            />
            {
                menuOpen && actions.length > 0?
                <div 
                    tabIndex="0"
                    className={styles.menu} onBlur={() => setMenuOpen(false)} ref={menuRef}>
                    {
                        actions.map((action, i) => {
                            return <MenuItem 
                                        key={`${action.name}${i}`}
                                        name={action.name} 
                                        onClick={action.onClick}
                                        onClose={() => setMenuOpen(false)}
                                    />
                        })
                    }
                </div>
                :
                null
            }
        </div>
    )
}

const MenuItem = ({name="", onClick=()=>{}, onClose=()=>{}}) => {
    const handleClick = () => {
        onClick();
        onClose();
    }
    return <div className={styles.menuItem} onClick={handleClick}>{name}</div>
}

export default ActionMenu