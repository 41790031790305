export const VIDEO_TYPE_SELLER = 'VIDEO_TYPE_SELLER'
export const VIDEO_ORIENTATION_LANDSCAPE = 'VIDEO_ORIENTATION_LANDSCAPE'
export const VIDEO_ORIENTATION_PORTRAIT = 'VIDEO_ORIENTATION_PORTRAIT'

export const DEFAULT_VIDEO_HEIGHT = 300
export const DEFAULT_VIDEO_WIDTH = 400
export const DEFAULT_VIDEO_THUMBNAIL_WIDTH = 300
export const DEFAULT_VIDEO_THUMBNAIL_HEIGHT = 200
export const DEFAULT_VIDEO_FULLSCREEN_HEIGHT = 500
export const FACEBOOK_HOST_ID = "www.facebook.com"
export const YOUTUBE_HOST_ID = "www.youtube.com"
export const APPROVED_VIDEO_HOST_LIST = [YOUTUBE_HOST_ID, FACEBOOK_HOST_ID]