import React from "react"
import MobileNavigationBar from "../../containers/MobileNavigationBar"
import SearchBar from "../../containers/SearchBar"
import HomeTileList from "../../containers/HomeTileList"
import SideBar from "../../containers/SideBar"
import AddedToBasket from "../../containers/AddedToBasket"
import DisasterBanner from "../../banners/DisasterBanner"
import FeedbackForm from "../../containers/FeedbackForm"
import UsageStatsBar from "../../containers/UsageStatsBar"
import Footer from "../../containers/Footer"

import styles from "./Home.module.css"
import HeroBanner from "../../banners/HeroBanner"
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {Helmet} from "react-helmet";

class Home extends React.Component{

    mobileNavRef = null

    componentDidMount(){
        const {actions} = this.props
        actions.updateQueryString("")
        actions.fetchSaveFeaturedProducts()
    }

    handleOpenCategoryModal = () => {
        if (this.mobileNavRef){
            this.mobileNavRef. handleOpenCategoryModal()
        }
    }
    render(){
        return (
        <div className={[styles.container, "screen"].join(" ")}>
            <Helmet>
                <title>Shopdm: Shop Online at Any Store in Dominica</title>
            </Helmet>
            <SearchBar/>
            <HeroBanner />
            {Date.now() < 1689508800000 ?<DisasterBanner /> : null}
            <UsageStatsBar />
            <div className={["mainContent", styles.mainContent].join(" ")}>
                <AddedToBasket />
                <SideBar />
                <MobileNavigationBar innerRef={ref => this.mobileNavRef = ref}/>       
                <HomeTileList 
                    handleOpenCategoryModal={this.handleOpenCategoryModal}
                />
                <FeedbackForm />
            </div>
            <Footer/>
        </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(Home);