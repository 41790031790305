import React from "react"

import Form from "../../components/Form"
import FormField from "../../components/FormField"
import BackLink from "../../components/BackLink"

import {isValidEmail} from "../../utils/formValidationUtils"
import styles from "./ResetPasswordForm.module.css"
import {Redirect} from "react-router-dom"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

class ResetPasswordForm extends Form{
    
    constructor(props){
        super(props)
        this.state = {
            values: {...this.fields},
            errors: {},
            resetPasswordEmailSent: false,
            authenticated: props.user.authenticated
        }
    }

    static defaultProps ={
        redirectTo: '/',
    }

    fields = {
        email: "",
    }

    identifier = 'email'

    executeSubmit = async () => {
        const {actions} = this.props
        const {email}= this.state.values
        return await actions.fetchResetPassword(email, this.handleSubmitSuccess, this.handleSubmitError)
    }

    validate = () => {
        const errors = {}
        const {values} = this.state
        const {email, password} = values
        Object.keys(values).forEach(key => {
            if (!Boolean(values[key])){
                errors[key] = `${key} is required`
            }
        })
        if (!errors["email"] && !isValidEmail(email)) errors["email"] = "must be a valid email"
        if (Object.values(errors).every(error => !error)) return true
        else {
            this.setState({errors})
            return false
        }
    }

    handleSubmitSuccess = (email) => {
        this.setState({resetPasswordEmailSent: email})
    } 

    handleSubmitError = (email, message) => {
        const errors = {}
        errors["reset"] = `Failed to send reset email for ${email}: ${message}`
        this.setState({errors})
    }

    render(){
        const {values, errors, resetPasswordEmailSent, authenticated} = this.state
        const {email} = values
        const {redirectTo} = this.props
        if (authenticated) return <Redirect to={redirectTo} />
        return (
            <div className={['form', styles.container].join(" ")}>
                <div className={"formTitle"}>Reset Password</div>
                {
                    !resetPasswordEmailSent ?
                    <React.Fragment>
                        {

                            errors.reset ?
                            <span className={'errorText'}>
                                {errors.reset}
                            </span>
                            :
                            null
                        }
                        <FormField 
                            label="email" 
                            type="email"
                            value={email} 
                            onChange={e => this.handleChange(e, 'email')}
                            errorText={errors.email}
                            onKeyDown={this.handleKeyDown}
                        />
                        <button className="button dark" onClick={this.handleSubmit}>Send Password Reset Email</button>
                    </React.Fragment>
                    :
                    <div>A password reset link has been sent to {resetPasswordEmailSent}</div>
                }
                <BackLink text="Back to Login" path="/login"/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);