import React from 'react'
import PropTypes from "prop-types"
import Icon from "../Icon"
import AvatarIcon from "../AvatarIcon"
import styles from "./SideBarOption.module.css"

import {logAnalyticsEvent} from "../../config/firebase"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {SELLER_OPTION_TYPE, CATEGORY_OPTION_TYPE} from "../../constants/firebase"
import {AVATAR_SHAPE_ROUND, AVATAR_SHAPE_SQUARE} from "../../constants/interface"

function SideBarOption({label, imageUrl, optionId, type, actions, sellers, categories, onClick=()=>{}}){
    const path = type === SELLER_OPTION_TYPE ? `/sellers/${optionId}` :
                 type === CATEGORY_OPTION_TYPE ? `/categories/${optionId}` :
                 '/results' 
    const seller = sellers.sellersById[optionId]
    const handleClick = () => {
        let eventName
        let actionName
        let attributeName 
        let objectName
        if (type === SELLER_OPTION_TYPE){
            eventName = "sidebar_click_seller"
            actionName = "sidebarClickSeller"
            attributeName = "sellerId"
            objectName = seller.name
        } else if (type === CATEGORY_OPTION_TYPE){
            eventName = "sidebar_click_category"
            actionName = "sidebarClickCategory"
            attributeName = "categoryId"
            const category = categories.categoriesById[optionId]
            objectName = category.label
        }
        //google analyics
        if (eventName) logAnalyticsEvent(eventName, {name: label})
        //usage tracking
        actions.fetchLogDeviceSessionAction({
            action: actionName, 
            [attributeName]: optionId,
            name: objectName
        })
        onClick(path)
    }
    return(
        <div className={styles.container} onClick={handleClick}>
            <div className={styles.profile}>
                <AvatarIcon name={label} shape={type === CATEGORY_OPTION_TYPE ? AVATAR_SHAPE_SQUARE: AVATAR_SHAPE_ROUND} imageUrl={imageUrl}/>
                <span>{label}</span>
            </div>
            {
                type === SELLER_OPTION_TYPE &&
                seller &&
                seller.displayVideo ?
                <Icon icon="videocam"/>
                :
                null
            }
        </div>
    )
}

SideBarOption.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func
}

const mapStateToProps = state => ({
    categories: state.categories,
    sellers: state.sellers
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBarOption)