/**
 *  Purpose: the modifications that are carried on the Delivery Provider objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
// STATE
// deliveryProviders: {
//     deliveryProvidersById: {},
//     deliveryProviderIds: [],
// }


const deliveryProvidersReducer = (state = initialState.deliveryProviders, action) => {
    const {type, payload} = action;
    let deliveryProvidersById = {...state.deliveryProvidersById}
    let deliveryProviderIds = {...state.deliveryProviderIds}

    switch (type){
        case types.SAVE_DELIVERY_PROVIDERS: {
            if(typeof payload.deliveryProviders !== "object") {
                logError(`deliveryProvidersReducer > SAVE_DELIVERY_PROVIDERS: deliveryProviders payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.deliveryProviders.forEach(deliveryProvider => {
                deliveryProvidersById[deliveryProvider.id] = deliveryProvider
            })
            return {
                ...state,
                deliveryProvidersById,
                deliveryProviderIds: Object.keys(deliveryProvidersById)
            }
        }

        case types.UPDATE_DELIVERY_PROVIDER_SETTLEMENTS: {
            if(typeof payload.deliveryProviderId !== "string") {
                logError(`deliveryProvidersReducer > UPDATE_DELIVERY_PROVIDER_SETTLEMENTS: deliveryProviderId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.deliverySettlementIds !== "object") {
                logError(`deliveryProvidersReducer > UPDATE_DELIVERY_PROVIDER_SETTLEMENTS: deliverySettlementIds payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            const deliveryProvider = {...deliveryProvidersById[payload.deliveryProviderId]}
            deliveryProvider.deliverySettlementIds = payload.deliverySettlementIds
            return {
                ...state,
                deliveryProvidersById: {
                    ...deliveryProvidersById,
                    [payload.deliveryProviderId]: deliveryProvider
                }
            }
        }
        default: return state
    }
}

export default deliveryProvidersReducer