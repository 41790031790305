export const EMAIL_ALREADY_IN_USE_ERROR = "auth/email-already-in-use"
export const USER_NOT_FOUND = "auth/user-not-found" 
export const WRONG_PASSWORD = "auth/wrong-password"
export const NETWORK_REQUEST_FAILED = "auth/network-request-failed"

export const NOTIFICATIONS_PERMISSION_BLOCKED = "messaging/permission-blocked"
export const NOTIFICATIONS_NOT_SUPPORTED = "NOTIFICATIONS_NOT_SUPPORTED"
export const SELLER_OPTION_TYPE = 'SELLER_OPTION_TYPE'
export const CATEGORY_OPTION_TYPE = 'CATEGORY_OPTION_TYPE'
export const ecommerceEvents = {
    VIEW_CART: "view_cart",
    REMOVE_FROM_CART: "remove_from_cart",
    BEGIN_CHECKOUT: "begin_checkout",
    SELECT_ITEM: "select_item",
    ADD_TO_CART: "add_to_cart"
}