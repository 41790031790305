import React from "react"
import { PayPalButton } from 'react-paypal-button-v2'
import config from "../../config/paypal"
import { logError } from "../../utils/errorHandlingUtils"
import PropTypes from "prop-types"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

function PayPalCheckout({
  amount, 
  email="",
  createOrder=()=>{},
  onSuccess=()=>{},
  onFailure=()=>{},
  onCancel=()=>{},
  actions
}) {
 
  const buttonStyles = {
    layout: 'vertical',
    shape: 'rect',
  }

  const handleCreateOrder = async (data, paypalActions) => {
    const orderNumber = await createOrder()
    return paypalActions.order.create({
      payer:{
        email_address: email,
      },
      purchase_units: [
        {
          description: `Shopdm Order ${orderNumber}`,
          invoice_id: orderNumber,
          amount: {
            currency_code: "USD",
            value: amount.toFixed(2)
          }
        }
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING"
      }
    });
  };
  
  const handleSuccess = (details, data)=> {
    const {amount} = details.purchase_units[0]
    onSuccess( data.orderID, amount.value, amount.currency_code)
  }

  const handleCancel = () => {
    alert('Please try again or choose another payment method. Your order will not be processed until you have paid')
    actions.toggleLoading(false)
    onCancel()
  }
  

  return (
    <PayPalButton
      options={config}
      createOrder={handleCreateOrder}
      onSuccess={handleSuccess}
      onError={err => {
        logError(`Failed to complete paypal payment ${err}`)
        actions.toggleLoading(false)
        onFailure()
        alert(`Something went wrong when we tried to place your order. Try refreshing this page, checking your internet connection or logging into another device to place the order.\n\nYou can then try paying again with Paypal or another payment method.\n\nWe're very sorry for the inconvenience. If the problem continues, please Whatsapp the Shopdm team at +17676128202 and our technical team will be very happy to assist you :)`)
      }}
      onCancel={handleCancel}
      buttonStyles={buttonStyles}
    />
  )
}

PayPalCheckout.propTypes = {
    amount: PropTypes.number.isRequired
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(PayPalCheckout)