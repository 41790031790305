import React from "react"
import SearchBar from "../../containers/SearchBar"
import MobileNavigationBar from "../../containers/MobileNavigationBar"
import CategoryTileList from "../../containers/CategoryTileList"

import SideBar from "../../containers/SideBar"
import Footer from "../../containers/Footer"
import {
    BrowserRouter as Router,
    Redirect,
    withRouter
} from "react-router-dom";
import AddedToBasket from "../../containers/AddedToBasket"
import styles from './CategoryProfile.module.css'

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {Helmet} from "react-helmet";
import queryString from "query-string"
import {capitalizeAllWords} from "../../utils/stringUtils"

import {PAGINATE_NEXT, PAGINATE_BACK} from "../../constants/interaction"
class CategoryProfile extends React.Component{

    constructor(props){
        super(props)
        const pageNumber = this.getPageNumber()
        this.state = {
            productCount: 0, //number of active products in this category 
            productList: [],
            firstDoc: null, //used for pagination
            lastDoc: null, //used for pagination
            pageNumber: pageNumber === null ? 0 : pageNumber
        }
        //used to detect page changes, including changes via the browser's back/forward buttons
        this.lastHash = window.location.hash
    }
    componentDidMount = () => {
        this.getProductCount()
        this.loadPaginatedCategoryProductBatch()
        //this sets the hash of any link that doesnt have one
        //or applies the hash when the page number e.g. 3 
        //when the user opens the page via a link that goes directly to 
        //a page that is not the first

        //we set replace to true, so we can go back without 2 presses
        this.updateUrlHash(this.state.pageNumber, true)
    }

    getPageNumber = () => {
        //get the page number from the url hash and cast it to a number or null if it doesnt exist
        let currentPageIndex = queryString.parse(window.location.hash).page
        const pageNumber = currentPageIndex && 
                            !isNaN(Number(currentPageIndex))
                             ? Number(currentPageIndex) : null
        return pageNumber
    }
    componentDidUpdate(prevProps){
        const {match} = this.props
        if (match.params.categoryId !== prevProps.match.params.categoryId){
            //if the category has been changed
            this.lastHash = window.location.hash
            let pageNumber = this.getPageNumber()
            pageNumber = pageNumber ? pageNumber : 0
            this.setState({
                productCount: 0,
                productList: [],
                firstDoc: null,
                lastDoc: null,
                pageNumber,
            }, () => {
                    this.getProductCount()
                    this.updateUrlHash(pageNumber, true)
                    this.loadPaginatedCategoryProductBatch()
                }
            )
                
        }
        //NOTE: withRouter passes the history object into this components and 
        //      triggers an update every time the url changes
       
        //if the url has changed 
        //but the pageNumber in state does not match the page number in the url
        //then the back or forward buttons must have been pressed
        if (
            this.lastHash !== this.props.history.location.hash &&
            this.getPageNumber() !== this.state.pageNumber
        ) {
            const newPageNumber = this.getPageNumber()
            const paginateDirection = newPageNumber > this.state.pageNumber ?
                                        PAGINATE_NEXT : PAGINATE_BACK
            this.loadPaginatedCategoryProductBatch(paginateDirection, this.handleGoToProducts)
        }
        //track the last hash 
        this.lastHash = this.props.history.location.hash
    }

    updateUrlHash = (pageNumber=0, replace=false) => {
        if (this.getPageNumber() === pageNumber) return
        const {history} = this.props
        if (replace){
            //in this case the current url is replaced, allowing us to
            //avoid having to press back twice when we go to a category page and then
            //append page=0
            history.replace(`${window.location.pathname}#page=${pageNumber}`)
            console.log("replacing to ", pageNumber)
        } else {
            history.push(`${window.location.pathname}#page=${pageNumber}`)
        }
    }

    handleGoToProducts = () => {
        setTimeout(() =>
        { 
            if(this.containerTop) {
                window.scrollTo(0,this.containerTop.offsetTop)
            }
        }, 50)
    }

    getProductCount = async () => {
        const {actions, categories, match} = this.props
        const {categoryId} = match.params
        const category = categories.categoriesById[categoryId]
        if (category) {
            const productCount = await actions.fetchCountActiveCategoryProducts(categoryId)
            this.setState({productCount})
        }
    }

    loadPaginatedCategoryProductBatch = async (paginateDirection=PAGINATE_NEXT, onChangePage=()=>{}) => {
        const {actions, categories, match} = this.props
        const {categoryId} = match.params
        const category = categories.categoriesById[categoryId]
        let pageNumber = this.state.pageNumber
        //skip on first load
        if (this.state.firstDoc){
            pageNumber = paginateDirection === PAGINATE_NEXT ? this.state.pageNumber + 1 : 
                        paginateDirection === PAGINATE_BACK ? Math.max(0, this.state.pageNumber - 1) : 
                        this.state.pageNumber
        }
        if(category){
            actions.toggleLoading(true)
            const {
                success,
                productList,
                firstDoc,
                lastDoc

            } = await actions.fetchSavePaginatedCategoryProducts(
                categoryId,
                this.state.firstDoc, //use last doc for pagination
                this.state.lastDoc, //use last doc for pagination
                paginateDirection,
                pageNumber
            )
            actions.toggleLoading(false)
            //only replace the last query and current products if the last batch included results
            if (success && productList.length > 0){

                this.setState({
                    productList,
                    firstDoc,
                    lastDoc,
                    pageNumber
                }, () => {
                    this.updateUrlHash(pageNumber)
                    onChangePage()
                })
            }
        }
    }

    render(){
        const {categories, match} = this.props
        const {categoryId} = match.params
        const category = categories.categoriesById[categoryId]
        if (!category) return <Redirect to="/"/>
        const {productList, productCount, pageNumber} = this.state
        return (
        <div className={[styles.container, "screen"].join(" ")}>
            <Helmet>
                <title>{capitalizeAllWords(category.label)} Category</title>
            </Helmet>
            <SearchBar/>
            <div className={["mainContent", "searchBarPresent", styles.mainContent].join(" ")}>  
                <SideBar />
                <MobileNavigationBar />
                <AddedToBasket />
                <div className={styles.categoryContent}  ref={el => this.containerTop = el}>
                    <CategoryTileList 
                        totalProductCount={productCount}
                        pageNumber={pageNumber}
                        categoryId={categoryId}
                        productList={productList}
                        onClickBack={() => this.loadPaginatedCategoryProductBatch(PAGINATE_BACK, this.handleGoToProducts)}
                        onClickNext={() => this.loadPaginatedCategoryProductBatch(PAGINATE_NEXT, this.handleGoToProducts)}
                    />
                </div>
            </div>
            <Footer />
        </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    categories: state.categories
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CategoryProfile));