/**
 *  Purpose: the modifications that are carried on the Payout objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {STATISTICS_IDENTIFIER} from "../constants/analysis"
// STATE
// payouts:{
//  payoutsById:{},
//  payoutIds: [],
//  stats: {}
// }


const payoutsReducer = (state = initialState.payouts, action) => {
    const {type, payload} = action;
    let payoutsById = {...state.payoutsById}
    let payoutIds = {...state.payoutIds}
    let stats = {...state.stats}

    switch (type){
        case types.SAVE_PAYOUTS: {
            if(typeof payload.payouts !== "object") {
                logError(`payoutsReducer > SAVE_PAYOUTS: payouts payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.payouts.forEach(payout => {
                if (payout.id !== STATISTICS_IDENTIFIER){
                    payoutsById[payout.id] = payout
                } else stats = payout
            })
            
            return {
                ...state,
                payoutsById,
                stats,
                payoutIds: Object.keys(payoutsById)
            }
        }

        case types.CREATE_DISBURSEMENTS: {
            if(typeof payload.disbursements !== "object") {
                return state
            }
            payload.disbursements.forEach(disbursement => {
                disbursement.payoutIds.forEach(payoutId => {
                    if (!payoutsById[payoutId]) return
                    payoutsById[payoutId] = {
                        ...payoutsById[payoutId],
                        paid: true,
                        disbursementId: disbursement.id,
                        paidAt: disbursement.createdAt
                    }
                })
            })
            
            return {
                ...state,
                payoutsById
            }
        }

        case types.LOGOUT: {
            return initialState.payouts
        }

        default: return state
    }
}

export default payoutsReducer