/**
 *  Purpose: the modifications that are carried on the Refund objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // refunds:{
 //   refundsById:{},
 //   refundIds: []
 // }
 
 
 const refundsReducer = (state = initialState.refunds, action) => {
     const {type, payload} = action;
     let refundsById = {...state.refundsById}
     switch (type){
         case types.SAVE_REFUNDS: {
             if(typeof payload.refunds !== "object") {
                 logError(`refundsReducer > SAVE_REFUNDS: refunds payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.refunds.forEach(refund => {
                refundsById[refund.id] = refund
             });
             return {
                ...state,
                refundsById,
                refundIds: Object.keys(refundsById)
             }
         }
 
        case types.CREATE_REFUND: {
            if(typeof payload.refund !== "object") {
                logError(`refundsReducer > CREATE_REFUND: refund payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            refundsById[payload.refund.id] = payload.refund
            return {
                ...state,
                refundsById,
                refundIds: Object.keys(refundsById)
            }
        }
         default: return state
     }
 }
 
 export default refundsReducer