import React from "react"
import VideoEmbed from "../VideoEmbed"
import Modal from "../Modal"
import MediaPostModal from "../MediaPostModal"
import SocialMetric from "../SocialMetric"
import IconButton from "../IconButton"
import Icon from "../Icon"

import ReactionPanel from "../../containers/ReactionPanel"

import styles from "./VideoThumbnail.module.css"
import  {DEFAULT_VIDEO_THUMBNAIL_WIDTH, DEFAULT_VIDEO_THUMBNAIL_HEIGHT, VIDEO_ORIENTATION_LANDSCAPE, YOUTUBE_HOST_ID} from "../../constants/videos"
import {OBJECT_TYPE_VIDEO} from "../../constants/objects"
import {setUrlParams, getHostVideoIdFromUrl} from "../../utils/stringUtils"

class VideoThumbnail extends React.Component{

    state = {
        editModalOpen: false,
        viewModalOpen: false
    }

    static defaultProps = {
        onDelete: () => {},
        onViewVideo: () => {}
    }

    handleOpenEditModal = () => this.setState({editModalOpen: true})
    handleCloseEditModal = () => this.setState({editModalOpen: false})

    handleOpenViewModal = () => {
        const {onViewVideo} = this.props
        this.setState({viewModalOpen: true}, onViewVideo)
    }
    
    handleCloseViewModal = () => this.setState({viewModalOpen: false})

    handleDeleteVideo = () => {
        const {onDelete} = this.props
        onDelete()
    }

    render(){
        const {video, children, showActions, entityType, entityId} = this.props
        const {editModalOpen, viewModalOpen} = this.state
        const urlParams = {mute: "1"}
        if (video){
            if (video.url.includes(YOUTUBE_HOST_ID)){
                urlParams.autoplay = "1"
                urlParams.loop = "1"
                urlParams.playlist = getHostVideoIdFromUrl(video.url)
            }
        }
        return (
            <div 
                className={styles.container}
            > 
            {
                video && !editModalOpen ?
                <div>
                    <div className={styles.thumbnail}>
                        {video.title ? <div className={`strong ${styles.thumbTitle}`}>{video.title}</div> : null}
                        <div className={styles.thumbnailInner}>
                            <div className={styles.thumbFrame}>
                                <div className={styles.thumbImage} onClick={this.handleOpenViewModal}>
                                    <iframe 
                                        className={styles.iframe} 
                                        src={setUrlParams(video.url, urlParams)}
                                        width={video.orientation === VIDEO_ORIENTATION_LANDSCAPE ? DEFAULT_VIDEO_THUMBNAIL_WIDTH: DEFAULT_VIDEO_THUMBNAIL_HEIGHT * video.aspectRatio}
                                        height={video.orientation === VIDEO_ORIENTATION_LANDSCAPE ? DEFAULT_VIDEO_THUMBNAIL_WIDTH/video.aspectRatio : DEFAULT_VIDEO_THUMBNAIL_HEIGHT}
                                    />
                                    <div className={styles.overlay}>
                                        <Icon icon="play-arrow"/>
                                    </div>
                                    <div className={styles.clickForFullscreen}>Click For Fullscreen <Icon icon="fullscreen"/></div>
                                </div>
                                <div className={styles.socialBar}>
                                    <div className={styles.socialStats}>
                                        <ReactionPanel 
                                            objectId={video.id}
                                            objectType={OBJECT_TYPE_VIDEO}
                                            entityType={entityType}
                                            entityId={entityId}
                                            reactionTotals={video.reactions}
                                        />
                                    </div>
                                    <div>
                                    { video.views? <SocialMetric text={`${video.views} view${video.views !== 1 ? 's' : ""}`} icon="visibility"/> : ""}
                                    </div>
                                    
                                </div>
                            </div>
                            {
                                showActions ?             
                                <div className="actionMenu">
                                    <IconButton 
                                        icon="edit"
                                        label="Edit"
                                        onPress={this.handleOpenEditModal}
                                    />
                                    <IconButton 
                                        icon="delete"
                                        label="Delete"
                                        onPress={this.handleDeleteVideo}
                                    />
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                    {
                        viewModalOpen ? 
                        <MediaPostModal
                            video={video}
                            modalOpen={viewModalOpen}
                            handleCloseModal={this.handleCloseViewModal}
                        />
                        :
                        null
                    }
                </div>
                :
                <div>
                    <VideoEmbed onClick={this.handleOpenEditModal}/>
                    {
                        editModalOpen ?
                        <Modal
                            isOpen={editModalOpen}
                            closeModal={this.handleCloseEditModal}
                            className={styles.modal}
                            overlayClassName={styles.modalOverlay}
                            showCloseButton={true}
                            closeOnOverlayClick={true}
                        >
                            {children}
                        </Modal>
                        :
                        null
                    }
                </div>
            }
            </div>
        )
    }
}

export default VideoThumbnail