import React from "react"

import PhotoUpload from "../PhotoUpload"
import IconButton from "../IconButton"
import Thumbnail from "../Thumbnail"
import styles from "./MultiImage.module.css"

class MultiImage extends React.Component{

    constructor(props){
        super(props)
        const {images} = props
        this.state = {
            currentIndex: images.length > 0 ? 0 : null,
            images
        }
        this.targetIndex = 0
        this.photoUpload = null
    }

    static defaultProps = {
        onSave: ()=>{},
        onChange: ()=> {}
    }
    
    componentDidUpdate(prevProps){
        const objectArraysEqual = (arr1, arr2) => {
            if (arr1.length !== arr2.length) return false
            else return arr1.every((o, i) => o === arr2[i])
        }
        if (
            !objectArraysEqual(prevProps.images, this.props.images) &&
            !objectArraysEqual(this.props.images, this.state.images)
        ){
            this.setState({
                images: this.props.images,
                currentIndex: this.props.images.length > 0 ? 0 : null
            })
        }
    }

    handleAddImage = image => {
        const {onChange} = this.props
        const images = [...this.state.images]
        images[this.targetIndex] = image
        this.setState({
            currentIndex: this.targetIndex,
            images
        }, () => onChange(images))
        
    }

    handleDeleteImage = () => {
        const {onChange} = this.props
        let images = [...this.state.images]
        if (images.length === 0) return
        let currentIndex = this.state.currentIndex
        delete images[currentIndex]
        images = images.filter(image => image !== undefined)
        //if we deleted the only image
        if (images.length === 0){
            currentIndex = null
            this.targetIndex = 0
        }
        //if we deleted the rightmost image, but it was not the last image
        else if ((currentIndex + 1) >= images.length) {
            currentIndex = images.length - 1
            this.targetIndex = currentIndex
        }
        this.setState({
            images,
            currentIndex
        })
        onChange(images)
    }

    handleReorderImages = (currentIndex, nextIndex)  => {
        const {onChange} = this.props
        let images = [...this.state.images]
        //if the next index is not in the acceptable bounds, abort
        if (currentIndex === null || !(nextIndex >= 0 && nextIndex < images.length)) return
        //get a pointer to the image currently in the next position
        const imageInNext = images[nextIndex]
        //point the next position to the current image
        images[nextIndex] = images[currentIndex]
        //point the previous position to the image that used to be in this position
        images[currentIndex] = imageInNext
        this.targetIndex = nextIndex
        this.setState({
            images,
            currentIndex:nextIndex
        })
        onChange(images)
    }

    handlePressUpload = targetIndex => {
        this.targetIndex = targetIndex
        if (this.photoUpload) this.photoUpload.handleClick()
    }

    handleChangeCurrentIndex = currentIndex => this.setState({currentIndex})

    handleSave = () => {
        const {onSave} = this.props
        onSave(this.state.images)
    }

    render(){
        const {images, currentIndex} = this.state
        let image = {}
        if (currentIndex !== null){
            image = images[currentIndex]
        }
        return (
            <div className={styles.container}>
                <div className={styles.currentImage}>
                    <div className={styles.frame}>
                        <PhotoUpload 
                            ref={ref => this.photoUpload = ref} 
                            imageUrl={image.url} 
                            imageFile={image.file}
                            onChange={(imageUrl, imageFile) => this.handleAddImage({file:imageFile, url:imageUrl})} 
                        />
                    </div>
                    <div className="actionMenu">
                        <div>
                            <IconButton 
                                icon="photo-camera"
                                label="Replace"
                                onPress={() => this.handlePressUpload(currentIndex === null ? 0 : currentIndex)}
                            />
                        </div>
                        <div>
                            <IconButton 
                                icon="delete"
                                label="Delete"
                                onPress={this.handleDeleteImage}
                            />
                        </div>
                        <div>
                            <IconButton 
                                icon="arrow-back"
                                label="Move Forward"
                                onPress={() => this.handleReorderImages(currentIndex, currentIndex - 1)}
                            />
                        </div>
                        <div>
                            <IconButton 
                                icon="arrow-forward"
                                label="Move Back"
                                onPress={() => this.handleReorderImages(currentIndex, currentIndex + 1)}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.imageList}>
                    {
                        images.map((image, i) => {
                            return <div key={image.url} onClick={() => this.handleChangeCurrentIndex(i)}>
                                        <Thumbnail 
                                            url={image.url}
                                            selected={currentIndex === i}
                                        />
                                    </div>
                        })
                    }
                    <div className={styles.new}>
                        <IconButton 
                            icon="add"
                            onPress={() => this.handlePressUpload(images.length)}
                        />
                    </div>
                </div>
            </div>
        )
    }
}


export default MultiImage

