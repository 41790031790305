/**
 *  Purpose: the modifications that are carried on the Session object for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"

// STATE
// session: {
//    id:"",
//    actions: [],
// }


const sessionReducer = (state = initialState.session, action) => {
    const {type, payload} = action;
    const {lastSessionId} = state
    const actions = [...state.actions]
    switch (type){

        case types.CREATE_DEVICE: {
            if(typeof payload.device !== "object") {
                return state
            }
            return {
                ...state,
                id: payload.device.lastSessionId,
                actions: [...actions, {action: "startSession"}]
            }
        }

        case types.UPDATE_DEVICE: {
            if(typeof payload.deviceUpdate !== "object") {
                return state
            }
            const updates = {}
            if (payload.deviceUpdate.lastSessionId) {
                updates.id = payload.deviceUpdate.lastSessionId
                updates.actions = [...actions, {action: "startSession"}]
            }
            return {
                ...state,
                ...updates
            }
        }

        case types.LOG_DEVICE_SESSION_ACTION: {
            if(typeof payload.action !== "object") {
                return state
            }
            return {
                ...state,
                actions: [...actions, payload.action]
            }
        }
        default: return state
    }
}

export default sessionReducer