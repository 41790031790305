
import React from "react"
import styles from "./OrderListItem.module.css"

import CheckBox from "../CheckBox";
import Thumbnail from "../Thumbnail"
import ActionMenu from "../ActionMenu"
import {copyToClipboard} from "../../utils/interactionUtils"

import CharacteristicsList from "../CharacteristicsList"

class OrderListItem extends React.Component{    
    static defaultProps = {
        onClickCheckbox: ()=>{},
        onClickActions: ()=>{},
        checked: false,
        showCheckbox: true,
        showActions: true
    }

    render(){
        const {
            id,
            quantity, 
            title, 
            price, 
            actualItemTotalXcd,
            imageUrl, 
            characteristics, 
            onClickCheckbox,
            checked, 
            replaced, 
            refund,
            showCheckbox,
            showActions,
            skuNumber, 
            serialNumber,
            menuActions=[]
        } = this.props
        const checkedStyle = checked ? styles.checked : ""
        const replacedStyle = replaced ? styles.replaced : ""
        const refundedStyle = refund ? styles.refund : ""
        const actualStyle = actualItemTotalXcd ? styles.actual : ""
        const quantityStyle = quantity === 1 ? "numberBadge" : `numberBadge dark`
        return (
            <div className={[styles.container, checkedStyle, replacedStyle, actualStyle, refundedStyle].join(" ")}>
                <div className={styles.itemCheck}>
                    <div className={styles.itemCheckbox}>
                        {
                            showCheckbox ?
                            <CheckBox 
                                onChange={onClickCheckbox}
                                checked={checked}
                            />
                            :
                            null
                        }
                        <div className={styles.listItemText}>
                            <Thumbnail url={imageUrl} expand={true} lazyLoad={false}/>
                            <div className={styles.text}>
                                <span className={quantityStyle}>{quantity}</span> 
                                <span>x {title}{skuNumber ? <b> SKU:<a title="copy sku" className="actionText" onClick={() => copyToClipboard(skuNumber)}>{skuNumber}</a></b>: ""} (${(price * quantity).toFixed(2)}{quantity > 1 ? `/${price.toFixed(2)} each` : ""})</span>
                                <CharacteristicsList 
                                    id={id}
                                    characteristics={characteristics} 
                                />
                            </div>
                            {replaced ? <div className={styles.itemEffect}><span>Replaced</span></div> : null}
                            {refund ? <div className={styles.itemEffect}><span>Refund</span></div> : null}
                            {actualItemTotalXcd ? <div className={styles.itemEffect}><span>Different Total: ${actualItemTotalXcd}</span></div> : null}
                        </div>
                    </div>
                    {this.props.children}
                    {
                        showActions ?
                        <ActionMenu 
                            actions={menuActions}
                        />
                        :
                        null
                    }
                </div>
            </div>
        )
    } 
}

export default OrderListItem