import React from "react"
import Modal from "../Modal"
import ResultModal from "../ResultModal"
import Icon from "../Icon"
import styles from "./PopupAlert.module.css"
import {RESULT_SUCCESS} from "../../constants/results"
class PopupAlert extends React.Component {

    static defaultProps = {
        title: "Alert",
        icon:"notifications",
        handleCloseModal: () =>{},
        buttons: [
            {
                text:"OK",
                className:"primary",
                isDefault: true,
                icon: ""
            }
        ],
        
    }

    render(){
        const {
            title,
            icon, 
            buttons,
            handleCloseModal,
            result,
            resultMessage
        } = this.props
        return (
            <React.Fragment>
                {
                    result ? 
                
                    <ResultModal 
                        result={result}
                        autoClose={result === RESULT_SUCCESS}
                        message={resultMessage}
                        onClose={handleCloseModal}
                    />
                    :
                    <Modal
                        isOpen={true}
                        closeModal={handleCloseModal}
                        className={styles.modal}
                        overlayClassName={styles.modalOverlay}
                        closeOnOverlayClick={true}
                        showCloseButton={false}
                    >
                        <div className={styles.content}>
                            <div className={styles.title}>
                                { icon ? <Icon icon={icon}/> : null}
                                {title}
                            </div>
                            <div className="buttonHolder">
                                {
                                    buttons.map(({text,className,onClick, isDefault, icon}) => {
                                        return <button key={text} className={`button icon ${className}`} onClick={isDefault ? handleCloseModal: onClick}>
                                                {icon ? <Icon icon={icon}/> : null}
                                                {text}
                                            </button>
                                    })
                                }
                            </div>
                        </div>
                    </Modal>
                }
            </React.Fragment>
        )
    }
}

export default PopupAlert