import {firebaseApp} from "../config/firebase"
import { getFirestore, collection, query, getDocs, doc, onSnapshot, updateDoc, runTransaction} from "firebase/firestore";
import {getStorage, ref, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage"

import { logError } from "../utils/errorHandlingUtils"
import {DELIVERY_FEE_TYPE_LOCATION_BASED, DELIVERY_PROVIDER_TYPE_OPENING_HOURS} from "../constants/delivery" 

export const SAVE_DELIVERY_PROVIDERS = 'SAVE_DELIVERY_PROVIDERS'
export const CREATE_DELIVERY_PROVIDER = 'CREATE_DELIVERY_PROVIDER' 
export const UPDATE_DELIVERY_PROVIDER_SETTLEMENTS = 'UPDATE_DELIVERY_PROVIDER_SETTLEMENTS'

export const saveDeliveryProviders = deliveryProviders => {
    return {
        type: SAVE_DELIVERY_PROVIDERS,
        payload: {
            deliveryProviders
        }
    }
}

export const createDeliveryProvider = deliveryProvider => {
    return {
        type: CREATE_DELIVERY_PROVIDER,
        payload: {
            deliveryProvider
        }
    }
}

export const updateDeliveryProviderSettlements = (deliveryProviderId, deliverySettlementIds) => {
    return {
        type: UPDATE_DELIVERY_PROVIDER_SETTLEMENTS,
        payload: {
            deliveryProviderId,
            deliverySettlementIds
        }
    }
}

export const fetchSaveDeliveryProviders = () => {
    const firestore = getFirestore(firebaseApp);
    const deliveryProvidersRef = query(collection(firestore, "deliveryProviders"))
    return async dispatch =>{
        try {
            const querySnapshot = await getDocs(deliveryProvidersRef)
            //get an array of delivery providers from the snapshot
            const deliveryProviders = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(saveDeliveryProviders(deliveryProviders))
            return true
        } catch (e){
            const message = `action > deliveryProviders > fetchSaveDeliveryProviders: Failed to save delivery providers`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSubscribeToDeliveryProviders = () => {
    /**
      * Purpose: retrieve the delivery providers from the firestore database
      * Note: the onSnapshot below watches for changes to the delivery providers on the server
      */
    const firestore = getFirestore(firebaseApp)
    const deliveryProvidersRef = query(collection(firestore, "deliveryProviders"))
    return async dispatch => {
        try {
            const deliveryProvidersListener = await onSnapshot(deliveryProvidersRef,
                querySnapshot => {
                    //get an array of products from the snapshot
                    const deliveryProviders = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveDeliveryProviders(deliveryProviders))
            })
            return deliveryProvidersListener
        } catch (e){
            const message = `action > deliveryProviders > fetchSubscribeToDeliveryProviders: Failed to save delivery providers`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchCreateDeliveryProvider = (
    id,
    {
        logoImageFile,
        logoImageFileMed,
        logoImageFileSmall,
    },
    name,
    alertEmail,
    contactNumber,
    isActive, 
    type,
    openingHours,
    feeType,
    deliveryCountryIds,
    deliverySettlementIds,
    consolidation,
    multiplePickupsAvailable,
    giftHandling,
    roles,
    bankAccount,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const deliveryProviderRef = doc(firestore, 'deliveryProviders', id)
    let deliveryProvider = {
        id,
        name,
        alertEmail,
        contactNumber,
        isActive, 
        type,
        openingHours,
        feeType,
        deliveryCountryIds,
        deliverySettlementIds,
        consolidation,
        multiplePickupsAvailable,
        giftHandling,
        roles,
        bankAccountId: bankAccount.id,
        createdAt: Date.now()
    }
    let logoImageUrl = ""
    let logoImageUrlMed = ""
    let logoImageUrlSmall = ""
    const storage = getStorage(firebaseApp)
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            await runTransaction(firestore, async (transaction) => {
   
                //upload the image
                if (!logoImageFile.url){ //if this is not a non-upload case where a url is provided
                    const imageRef = ref(storage, `deliveryProviders/${id}/logo`)
                    await uploadBytes(imageRef, logoImageFile)
                    const imageRefMed = ref(storage, `deliveryProviders/${id}/logo_med`)
                    await uploadBytes(imageRefMed, logoImageFileMed)
                    const imageRefSmall = ref(storage, `deliveryProviders/${id}/logo_small`)
                    await uploadBytes(imageRefSmall, logoImageFileSmall)
                    //get the image url
                    logoImageUrl = await getDownloadURL(imageRef)
                    logoImageUrlMed = await getDownloadURL(imageRefMed)
                    logoImageUrlSmall = await getDownloadURL(imageRefSmall)
                } 
                //otherwise just take the actual url provided
                else {
                    logoImageUrl = logoImageFile.url
                    logoImageUrlMed = logoImageFile.url
                    logoImageUrlSmall = logoImageFile.url
                }
                deliveryProvider = {
                    ...deliveryProvider,
                    logo: logoImageUrl,
                    logoImageUrl,
                    logoImageUrlMed,
                    logoImageUrlSmall,
                    createdByUserId: user.id,
                }
                //create main, public deliveryProvider object
                transaction.set(deliveryProviderRef, deliveryProvider)
              
                //create deliveryProvider bank account object
                const accountsRef = doc(firestore, `deliveryProviders/${id}/accounts`, bankAccount.id)
                transaction.set(accountsRef, bankAccount)
            })
            dispatch(createDeliveryProvider(deliveryProvider))
            onSuccess(true)
            return true
        } catch (e){
            const message = `action > deliveryProvider > fetchCreateDeliveryProvider: Failed to create deliveryProvider with  ${id} logo`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            //clean up any uploaded images for unsuccessfully created deliveryProviders
            if (logoImageUrl){
                const imageRef = ref(storage,  logoImageUrl)
                await deleteObject(imageRef)
            }
            if (logoImageUrlMed){
                const imageRefMed = ref(storage,  logoImageUrlMed)
                await deleteObject(imageRefMed)
            }
            if (logoImageUrlSmall){
                const imageRefSmall = ref(storage,  logoImageUrlSmall)
                await deleteObject(imageRefSmall)
            }
            onError(id)
            return false
        }
    }
}
export const fetchUpdateDeliveryProviderSettlements = (
    id,
    deliverySettlementIds,
    countryId,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const deliveryProvidersRef = doc(firestore, "deliveryProviders", id)
    const update = {
        deliverySettlementIds
    }
    return async (dispatch, getState) =>{
        try {
            const {user, deliveryProviders} = getState()
            const deliveryProvider = deliveryProviders.deliveryProvidersById[id]
            if (!deliveryProvider) throw new Error(`The id ${id} does not correspond to an actual deliver provider`)
            else if (deliveryProvider.feeType !== DELIVERY_FEE_TYPE_LOCATION_BASED) throw new Error(`Cannot set delivery settlements for ${deliveryProvider.name} as their fee type is ${deliveryProvider.feeType}, not ${DELIVERY_FEE_TYPE_LOCATION_BASED}`)
            else if (!deliveryProvider.deliveryCountryIds[countryId]) throw new Error(`Delivery provider ${deliveryProvider.name} does not delivery to country ${countryId}`)
            update.lastEditedAt = Date.now()
            update.lastEditedByUserId = user.id
            await updateDoc(deliveryProvidersRef, update)
            dispatch(updateDeliveryProviderSettlements(id, deliverySettlementIds))
            onSuccess(update)
            return true
        } catch (e){
            const message = `action > deliveryProviders > fetchUpdateDeliveryProviderSettlements: Failed to update the delivery settlements for ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateDeliveryProviderType = (
    id,
    type,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const deliveryProvidersRef = doc(firestore, "deliveryProviders", id)
    const update = {type}
    return async (dispatch, getState) =>{
        try {
            const {user, deliveryProviders} = getState()
            const deliveryProvider = {...deliveryProviders.deliveryProvidersById[id]}
            if (!deliveryProvider) throw new Error(`The id ${id} does not correspond to an actual deliver provider`)
            update.lastEditedAt = Date.now()
            update.lastEditedByUserId = user.id
            await updateDoc(deliveryProvidersRef, update)
            onSuccess(update)
            return true
        } catch (e){
            const message = `action > deliveryProviders > fetchUpdateDeliveryProviderType: Failed to update the delivery provider type for ${id} to ${type}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateDeliveryProviderOpeningHours = (
    id,
    openingHours={},
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const deliveryProvidersRef = doc(firestore, "deliveryProviders", id)
    const update = {openingHours}
    return async (dispatch, getState) =>{
        try {
            const {user, deliveryProviders} = getState()
            const deliveryProvider = {...deliveryProviders.deliveryProvidersById[id]}
            if (!deliveryProvider) throw new Error(`The id ${id} does not correspond to an actual delivery provider`)
            if (deliveryProvider.type !== DELIVERY_PROVIDER_TYPE_OPENING_HOURS) throw new Error(`Delivery provider ${id} is not of type ${DELIVERY_PROVIDER_TYPE_OPENING_HOURS}, but rather ${deliveryProvider.type}`)
            update.lastEditedAt = Date.now()
            update.lastEditedByUserId = user.id
            await updateDoc(deliveryProvidersRef, update)
            onSuccess(update)
            return true
        } catch (e){
            const message = `action > deliveryProviders > fetchUpdateDeliveryProviderOpeningHours: Failed to update the opening hours for delivery provider ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateDeliveryProviderIsActive = (
    id,
    isActive,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const deliveryProvidersRef = doc(firestore, "deliveryProviders", id)
    const isActiveUpdate = {
        [`isActive`] : isActive
    }
    return async (dispatch, getState) =>{
        try {
            const {user} = getState()
            isActiveUpdate.lastEditedByUserId = user.id
            isActiveUpdate.lastEditedAt = Date.now()
            await updateDoc(deliveryProvidersRef, isActiveUpdate)
            onSuccess(isActive)
            return true
        } catch (e){
            const message = `action > deliveryProviders > fetchUpdateDeliveryProviderIsActive: Failed to update deliveryProvider isActive to ${isActive}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateDeliveryProviderGiftHandling = (
    id,
    giftHandling,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const deliveryProvidersRef = doc(firestore, "deliveryProviders", id)
    const update = {
        [`giftHandling`] : giftHandling
    }
    return async (dispatch, getState) =>{
        try {
            const {user} = getState()
            update.lastEditedByUserId = user.id
            update.lastEditedAt = Date.now()
            await updateDoc(deliveryProvidersRef, update)
            onSuccess(giftHandling)
            return true
        } catch (e){
            const message = `action > deliveryProviders > fetchUpdateDeliveryProviderGiftHandling: Failed to update deliveryProvider giftHandling to ${giftHandling}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}
