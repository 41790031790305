import {
    PROMOTION_STATUS_ONGOING, 
    PROMOTION_STATUS_CREATED, 
    DISCOUNT_TYPE_FIXED_AMOUNT, 
    DISCOUNT_TYPE_PERCENTAGE, 
    PROMOTER_TYPE_SELLER,
    PROMOTION_APPLIES_TO_PRODUCTS_TYPE_ALL,
    PROMOTION_APPLIES_TO_PRODUCTS_TYPE_SPECIFIC
} from "../constants/promotions"

export const getProductPromotion = (productId, createdBySellerId, activeSellerAccountId, promotions={}) => {
    let promotionId = ""
    if (promotions.promotionIdsByProductId &&
        promotions.promotionIdsByProductId[productId]
    ){
        //if there are promotions for this product, cycle through them
        promotionId = Object.keys(promotions.promotionIdsByProductId[productId]).find(
            promotionId => {
                const promotion = promotions.promotionsById[promotionId]
                //return the first promotion which is ongoing and active
                if (
                    promotion.isActive &&
                    (
                        //the promotion is ongoing or 
                        promotion.currentStatus === PROMOTION_STATUS_ONGOING ||
                        //it haas not yet started but it is being viewed by a rep from the seller that owns it
                        (
                            (promotion.currentStatus === PROMOTION_STATUS_CREATED) && 
                            (promotion.promoterType === PROMOTER_TYPE_SELLER) &&
                            (promotion.promoterId === activeSellerAccountId) 
                        )
                    )
                ) return true
            }
        )

    }
    //if there has not yet been any match for the product to promotions
    if (!promotionId){
        if (promotions.appliesToAllPromotionIdsBySellerId &&
            promotions.appliesToAllPromotionIdsBySellerId[createdBySellerId]
        ){
            //if there are promotions for this seller that apply to all their products, cycle through them
            promotionId = Object.keys(promotions.appliesToAllPromotionIdsBySellerId[createdBySellerId]).find(
                promotionId => {
                    const promotion = promotions.promotionsById[promotionId]
                    //return the first promotion which is ongoing and active
                    if (
                        promotion.isActive && 
                        (
                            promotion.currentStatus === PROMOTION_STATUS_ONGOING ||
                            (
                                (promotion.currentStatus === PROMOTION_STATUS_CREATED) && 
                                (promotion.promoterType === PROMOTER_TYPE_SELLER) &&
                                (promotion.promoterId === activeSellerAccountId) 
                            )
                        )
                    ) return true
                }
            )
        }
    }

    if (promotionId){
        const promotion = promotions.promotionsById[promotionId]
        return promotion
    } else return false
}

export const calculatePromotionalPrice = (productPrice, promotion={}) => {
    let promoPrice = productPrice
    if (promotion.discountType === DISCOUNT_TYPE_PERCENTAGE) {
        promoPrice = promoPrice * ((100 - promotion.terms.percentage)/100)
    } else if (promotion.discountType === DISCOUNT_TYPE_FIXED_AMOUNT) {
        promoPrice = promoPrice - promotion.terms.fixedAmount
        promoPrice = promoPrice > 0? promoPrice : 0
    }
    return Number(promoPrice.toFixed(2))
}
export const getPromotionOfferString = (promotion = {})=> {
    let offerString = ""
    if (promotion.discountType === DISCOUNT_TYPE_PERCENTAGE) {
        offerString = `${promotion.terms.percentage}%`
    } else if (promotion.discountType === DISCOUNT_TYPE_FIXED_AMOUNT) {
        offerString = `$${promotion.terms.fixedAmount}`
    }
    return offerString
}

export const getProductStockPromotion = (productStockId, productId, createdBySellerId, activeSellerAccountId, promotions={}) => {
    const promotion = getProductPromotion(productId, createdBySellerId, activeSellerAccountId, promotions)
    //if there is no product match, there is no stock match
    if (!promotion) return false
    //if the promo applies to all products, it applies to all stock
    if (promotion.appliesToProductsType === PROMOTION_APPLIES_TO_PRODUCTS_TYPE_ALL) return promotion
    //if the promo applies to specific products, check whether this stock is included
    else if (promotion.appliesToProductsType === PROMOTION_APPLIES_TO_PRODUCTS_TYPE_SPECIFIC){
        //if the promo applies to this stock item return the promo, otherwise return falsse
        if (promotion.terms.appliesToProductIds[productId][productStockId]) return promotion
        else return false
    }
    else return false
}