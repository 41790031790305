import React from "react"
import styles from "./CartProductChangedItem.module.css"
import Icon from "../Icon"
import QuantityDropdown from "../QuantityDropdown"
import CheckBox from "../CheckBox"
import CharacteristicsList from "../CharacteristicsList"

const CartProductChangedItem = ({
    item={},
    imageUrl="", 
    productTitle="",
    minimumOrderQty=1,
    sellerName="",
    changes={},
    onRemove=()=>{},
    onChangeQuantity=()=>{},
    onToggleNotify=()=>{}
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.item}>
                <div className={styles.imageFrame}>
                    <img src={imageUrl}/>
                </div>
                <div>
                    <div className={styles.itemDetails}>
                        <span className="strong">{productTitle}</span>
                        <CharacteristicsList id={item.id} characteristics={item.characteristics}/>
                        from <span>{sellerName}</span>
                    </div>
                    <div className={styles.changes}>
                        {item.priceChange ? <PriceChange price={item.price} previousPrice={item.previousPrice}/> : null}
                        {item.quantityChange ? <QuantityChange quantityInStock={item.quantityInStock} previousQuantity={item.previousQuantity} minimumOrderQty={minimumOrderQty}/> : null}
                    </div>
                </div>
            </div>
            <div className={styles.actions}>
                {item.quantityInStock >= minimumOrderQty && !changes.remove? <div className={`${styles.action} ${styles.active}`} onClick={() => onRemove(item.id)}><Icon icon="delete"/> Remove</div> : null}
                {item.quantityInStock >= minimumOrderQty && changes.remove? <div className={`${styles.action} ${styles.active} ${styles.primary}`} onClick={() => onChangeQuantity(item.id, item.previousQuantity)}><Icon icon="shopping-cart"/> Add back to cart</div> : null}
                {item.quantityInStock >= minimumOrderQty ? 
                    <div className={styles.action}>
                        <QuantityDropdown 
                            label="Change quantity"
                            onSelect={quantity => onChangeQuantity(item.id, quantity)}
                            selected={changes.quantity}
                            min={changes.remove ? 0 : minimumOrderQty}
                            max={item.quantityInStock}
                            showMinOrder={false}
                        /> 
                    </div> 
                    : 
                    null
                }
                {/* TODO implement notify me when back in stock. Ask for perms here if not yet granted */}
                {item.quantityInStock < minimumOrderQty && false? 
                    <div className={styles.action}>
                        <CheckBox 
                            checked={Boolean(changes.notify)} 
                            onChange={() => onToggleNotify(item.id, !changes.notify)}
                        /> Notify me when it is back in stock
                    </div> 
                    : 
                    null
                }
            </div>
        </div>
    )
}

const PriceChange = ({price=0, previousPrice=0}) => {
    
    if (price === previousPrice) return ""
    const isIncrease = Boolean(price > previousPrice) 
    return (
        <div className={styles.changeContainer}>
            <div className={`badge ${isIncrease ? "warning" : "primary"} ${styles.changeBadge}`}>Price {isIncrease? "Increase" : "Decrease"}</div>
            <div> - has <span className={`${styles.changeText} ${isIncrease ? styles.warning : styles.primary}`}>{isIncrease ? "increased" : "decreased"}</span> from <span className="strong">EC ${previousPrice.toFixed(2)}</span> to <span className="strong">EC ${price.toFixed(2)}</span></div>
        </div>
    )
}

const QuantityChange = ({quantityInStock=0, previousQuantity=0, minimumOrderQty=1}) => {
    if (quantityInStock >= previousQuantity) return ""
    const outOfStock = Boolean(quantityInStock < minimumOrderQty)
    return (
        <div className={styles.changeContainer}>
            <div className={`badge ${outOfStock ? "" : "info"} ${styles.changeBadge}`}>{outOfStock ? "Sold Out" : "Less Available"}</div>
            <div>{!outOfStock ? <span> - you can no longer get <span className="strong">{previousQuantity}</span>, there are only <span className="strong">{quantityInStock}</span> remaining</span> : null}</div>            
        </div>
    )
}

export default CartProductChangedItem