import React from "react"
import styles from "./TestimonialBanner.module.css"
import {withRouter} from "react-router-dom"
import support from "../../config/support"
const TestimonialBanner = ({history}) => {
    return (
        <div className={styles.container}>
            <div className={styles.left}>
            <iframe 
                loading="lazy"
                width="699" height="393" 
                src="https://www.youtube.com/embed/HV-2ugdELcE" 
                title="YouTube video player" frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>    
            </iframe>

            </div>
            <div className={styles.right}>
                <div className={`title ${styles.heading}`}>Customer Testimonials</div>
                <div className={styles.explanation}>Even though we're a work in progress customer satisfaction is our top priority. But don't take our word for it, hear it directly from our customers!</div>
            </div>
        </div>
    )
}

export default withRouter(TestimonialBanner)