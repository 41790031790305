import React from "react"
import ProductVariantListItem from "../../components/ProductVariantListItem"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

class ProductVariantTableList extends React.PureComponent {

    static defaultProps = {
        productVariantList: [], //a list of maps of {productStockId, productId}
        emptyText: "No product variants",
        showCloseButton: true,
        getListItemChild: (productStockId, productId) => ""
    }

    componentDidMount(){
        this.loadRequiredProducts()
    }

    componentDidUpdate(){
        this.loadRequiredProducts()
    }

    loadRequiredProducts = async () => {
        const {actions, productVariantList, products} = this.props
        const productIdsToLoad = {}
        //get the product ids from all of the variants provided
        productVariantList.forEach(({productId}) => {
            if (!products.productsById[productId]) {
                productIdsToLoad[productId] = true
            }
        })

        const productIdList = Object.keys(productIdsToLoad)
        if (productIdList.length > 0 && !this.loading) {
            this.loading = true //flag that loading is ongoing so we do not unnecessarily reload
            await actions.fetchSaveProductsInList(productIdList)
            this.loading = false
        }
    }

    render(){
        const {productVariantList, emptyText, productStock, products, showCloseButton, getListItemChild} = this.props
        const productVariantIds = productVariantList.reduce((map, variant) => {
            map[variant.productStockId] = true
            return map
        }, {})
        return (
            <div className='tableList'>
                {
                    Object.keys(productVariantIds).length > 0 ?

                    Object.keys(productVariantIds)
                            .map((productStockId) => {
                                const variant = productStock.productStockById[productStockId]
                                if (!variant) return ""
                                const product = products.productsById[variant.productId]
                                if (!product) return ""
                                const titleAndBrand = product.titleAndBrand 
                                const {imageUrl="", price=0, characteristics={}} = variant
                                return <div key={variant.id} className='space-bottom-05em'>
                                            <ProductVariantListItem 
                                                id={variant.id}
                                                imageUrl={imageUrl}
                                                price={price}
                                                titleAndBrand={titleAndBrand}
                                                characteristics={characteristics}
                                                showCloseButton={showCloseButton}
                                            >
                                                {getListItemChild(productStockId, variant.productId)}
                                            </ProductVariantListItem>
                                        </div>
                            })
                    :
                    emptyText
                }
            </div>
)
    } 
}

const mapStateToProps = state => ({
    products: state.products,
    productStock: state.productStock
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductVariantTableList)