/**
 *  Purpose: the modifications that are carried on the Restock Request objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // restockRequests:{
 //   restockRequestsById:{},
 //   productStockIdsByUserId: {},
 //   restockRequestIds: [],
 // }
 
 
 const restockRequestsReducer = (state = initialState.restockRequests, action) => {
     const {type, payload} = action;
     let restockRequestsById = {...state.restockRequestsById}
     let productStockIdsByUserId = {...state.productStockIdsByUserId}
     switch (type){
         case types.SAVE_RESTOCK_REQUESTS: {
             if(typeof payload.restockRequests !== "object") {
                 logError(`restockRequestsReducer > SAVE_RESTOCK_REQUESTS: restockRequests payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.restockRequests.forEach(restockRequest => {
                 const {createdByUserId, productStockId} = restockRequest
                //index restock request by id
                restockRequestsById[restockRequest.id] = restockRequest
                //index restock request by user and product stock id
                //this is used to show/hide the buttons while shopping

                //if this user has not been indexed before
                if (!productStockIdsByUserId[createdByUserId]) productStockIdsByUserId[createdByUserId] = {}
                //if this product stock has not been indexed for this user before
                if (!productStockIdsByUserId[createdByUserId][productStockId]) productStockIdsByUserId[createdByUserId][productStockId] = {}
                productStockIdsByUserId[createdByUserId][productStockId][restockRequest.id] = true
             });
             return {
                ...state,
                restockRequestsById,
                productStockIdsByUserId,
                restockRequestIds: Object.keys(restockRequestsById)
             }
         }
 
        case types.CREATE_RESTOCK_REQUEST: {
            if(typeof payload.restockRequest !== "object") {
                logError(`restockRequestsReducer > CREATE_RESTOCK_REQUEST: restockRequest payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            restockRequestsById[payload.restockRequest.id] = payload.restockRequest
            const {createdByUserId, productStockId} = payload.restockRequest
            //index restock request by user and product stock id
            //this is used to show/hide the buttons while shopping

            //if this user has not been indexed before
            if (!productStockIdsByUserId[createdByUserId]) productStockIdsByUserId[createdByUserId] = {}
            //if this product stock has not been indexed for this user before
            if (!productStockIdsByUserId[createdByUserId][productStockId]) productStockIdsByUserId[createdByUserId][productStockId] = {}
            productStockIdsByUserId[createdByUserId][productStockId][payload.restockRequest.id] = true
            return {
                ...state,
                restockRequestsById,
                productStockIdsByUserId,
                restockRequestIds: Object.keys(restockRequestsById)
            }
        }

        case types.LOGOUT: {
            return initialState.restockRequests
        }

         default: return state
     }
 }
 
 export default restockRequestsReducer