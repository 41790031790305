import React from "react"
import styles from "./The404Page.module.css"
import logo from "../../images/logo300x300.png"
import BackLink from "../../components/BackLink"

import {Helmet} from "react-helmet";

const The404Page = () => {
    return (
        <div className={[styles.container, "screen"].join(" ")}>
            <Helmet>
                <title>404 Not Found</title>
            </Helmet>
            <p className="productTitle">Sorry we couldn't find that page.</p>
            <img src={logo} alt="Shopdm Logo"/>
            <BackLink 
                text="We believe Dominicans should be able to find whatever they need. Easily. Click here to go get it."
                path="/"
            />
        </div>
    )
}

export default The404Page;