import React from "react"
import styles from "./NotAuthorized.module.css"
import logo from "../../images/logo300x300.png"
import BackLink from "../../components/BackLink"
import {Helmet} from "react-helmet";

const NotAuthorized = () => {
    return (
        <div className={[styles.container, "screen"].join(" ")}>
            <Helmet>
                <title>Not Authorized</title>
            </Helmet>
            <p className="productTitle">Sorry you are not authorized to access that page.</p>
            <img src={logo} alt="Shopdm Logo"/>
            <BackLink 
                text="Now go buy local."
                path="/"
            />
        </div>
    )
}

export default NotAuthorized;