/**
 *  Purpose: the modifications that are carried on the Event objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"

 import {EVENT_STATUS_ONGOING, EVENT_STATUS_ENDED} from "../constants/events"
 // STATE
 // events:{
 //   eventsById:{},
 //   eventIds: [],
 //   live: null
 // }
 
 
 const eventsReducer = (state = initialState.events, action) => {
     const {type, payload} = action;
     let eventsById = {...state.eventsById}
     let live = state.live
 
     switch (type){
         case types.SAVE_EVENTS: {
             if(typeof payload.events !== "object") {
                 logError(`eventsReducer > SAVE_EVENTS: events payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.events.forEach(event => {
                     eventsById[event.id] = event
                     if (event.currentStatus === EVENT_STATUS_ENDED && live === event.id) live = null
                     else if (event.currentStatus === EVENT_STATUS_ONGOING && !live) live = event.id 
             });
             return {
                 ...state,
                 eventsById,
                 eventIds: Object.keys(eventsById),
                 live
             }
         }
 
         default: return state
     }
 }
 
 export default eventsReducer