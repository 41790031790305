import React from "react"
import Icon from "../Icon"
import styles from "./BigUpButton.module.css"
import ReactTooltip from "react-tooltip";
import {capitalizeAllWords} from "../../utils/stringUtils"
import {DEVICE_TYPE_DESKTOP} from "../../constants/device"

import {connect} from "react-redux"

class BigUpButton extends React.PureComponent {

    getTooltip = () => {
        const { userReactions, device} = this.props
        if (!userReactions || device.deviceType !== DEVICE_TYPE_DESKTOP) return ""
        const bigUps = userReactions.filter(r => r.reactionType === 'bigUp')
        let nameCount = 0
        let maxNames = 8
        return bigUps.reduce((tip, reaction) => {
            if (nameCount === maxNames) tip = `${tip}<br />and ${bigUps.length - maxNames} others...`
            else if (nameCount < maxNames){
                tip = tip ?
                  `${tip}<br />${capitalizeAllWords(reaction.userName)}`
                  :
                  `${capitalizeAllWords(reaction.userName)}`
            }
            nameCount++
            return tip
        }, "")
    }
    render(){
        const {reactionCount, selected, onClick, onClickCount} = this.props
        const selectedStyle = selected ? "" : styles.unselected
        const tooltip = this.getTooltip()
        return (
            <div className={styles.container}>
                <div className={`${styles.socialButtonIcon} ${selectedStyle}`} onClick={onClick}>
                    <Icon icon={selected ? "thumb-up" : "thumb-up-outlined"}/>
                    <div className={styles.socialButtonLabel}>Big Up</div>
                </div>
                <div onClick={onClickCount} className={ reactionCount ? styles.socialButtonCount : ""} data-tip={tooltip}>
                    {reactionCount ? reactionCount : ""}
                </div>
                <ReactTooltip multiline={true} place="bottom"/>
            </div>
        ) 
    }

}

const mapStateToProps = state => ({
    device: state.device
})

export default connect(mapStateToProps)(BigUpButton)