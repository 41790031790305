import React from "react"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import Icon from "../../components/Icon"
import CustomerRestockRequestTable from "../CustomerRestockRequestTable"
import DateRangePicker from "../../components/DateRangePicker"

import {getTimestampForStartOfDay} from "../../utils/datetimeUtils"
import {DAY_IN_MILLISECONDS} from "../../constants/datetime"

class CustomerRestockRequests extends React.Component{

    constructor(props){
        super(props)
        const startOfToday = getTimestampForStartOfDay()
        const endOfDay = startOfToday + DAY_IN_MILLISECONDS - 1 
        const lastFourMonths = endOfDay - (DAY_IN_MILLISECONDS * 30 * 4)
        this.state = {
            fromDate: lastFourMonths,
            toDate: endOfDay,
            restockRequestsListener : () => {},
        }
    }

    componentDidMount = async () => {
        this.loadRestockRequestsInDateRange()  
    }

    loadRestockRequestsInDateRange = async () => {
        const {actions, user} = this.props
        const {fromDate, toDate} = this.state
        //first, clear any existing restock request listeners
        this.state.restockRequestsListener()
        //next load the restock requests in the newly specified date range
        const restockRequestsListener = await actions.fetchSubscribeToMyRestockRequestsInDateRange(user.id, fromDate, toDate)
        this.setState({restockRequestsListener})
    }

    componentWillUnmount(){
        this.state.restockRequestsListener()
    }
    
    handleChangeDateRange = dateRange => {
        const {fromDate, toDate} = dateRange
        this.setState({fromDate, toDate}, this.loadRestockRequestsInDateRange)
    }

    render(){
        const {restockRequests, user} = this.props
        const {fromDate, toDate} = this.state
        const restockRequestArray = Object.values(restockRequests.restockRequestsById)
                                 //find all restock requests made by this cusstomer 
                                 .filter(r => r.createdByUserId === user.id &&
                                              r.createdAt >= fromDate && 
                                              r.createdAt <= toDate)
        return (
            <div className="padding-1em">
                <div className="title"><Icon icon="contact-support"/> Your Restock Requests</div>
                <DateRangePicker 
                    dateRange={{fromDate, toDate}}
                    onChange={this.handleChangeDateRange}  
                />
                <CustomerRestockRequestTable 
                    restockRequestArray={restockRequestArray}
                />
            </div>
        )
    }
}


const mapStateToProps = state => ({
    restockRequests: state.restockRequests,
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  });
  

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRestockRequests)
