import {firebaseApp} from "../config/firebase"
import {getFirestore, query, collection, getDoc, getDocs, writeBatch, doc, onSnapshot, updateDoc, setDoc, deleteField} from "firebase/firestore"
import { logError } from "../utils/errorHandlingUtils"
import {v4 as uuid4} from 'uuid'
import {PAYPAL_ID, NBD_MOBANKING_ID, JAD_CARD_PAYMENTS_ID, DEBIT_OR_CREDIT_CARD_ID} from "../constants/payment"
import {VIDEO_TYPE_SELLER} from "../constants/videos"
import {SHOPDM_DELIVERY_ID, PICK_UP_INSTORE_ID} from "../constants/delivery"
import {SELLER_NOTIFICATIONS_ROLES_ID} from "../constants/sellers"
import {getStorage, ref, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage"

export const SAVE_SELLERS = 'SAVE_SELLERS'
export const CREATE_SELLER = 'CREATE_SELLER'
export const ADD_SELLER_ROLE = 'ADD_SELLER_ROLE'
export const DELETE_SELLER_ROLE = 'DELETE_SELLER_ROLE'
export const UPDATE_SELLER_ISACTIVE = 'UPDATE_SELLER_ISACTIVE'
export const ADD_SELLER_ADDRESS = 'ADD_SELLER_ADDRESS'
export const DELETE_SELLER_ADDRESS = 'DELETE_SELLER_ADDRESS'
export const UPDATE_SELLER_SUPPORTED_DELIVERY_PROVIDERS = 'UPDATE_SELLER_SUPPORTED_DELIVERY_PROVIDERS'
export const UPDATE_SELLER_SELF_DELIVERY_SETTINGS = 'UPDATE_SELLER_SELF_DELIVERY_SETTINGS'
export const UPDATE_SELLER_ADDRESS_OPENING_HOURS = 'UPDATE_SELLER_ADDRESS_OPENING_HOURS'
export const SET_SELLER_DEFAULT_ADDRESS = 'SET_SELLER_DEFAULT_ADDRESS'
export const UPDATE_SELLER_SUPPORTED_PAYMENT_PROVIDERS = 'UPDATE_SELLER_SUPPORTED_PAYMENT_PROVIDERS'
export const SET_SELLER_LOGO = 'SET_SELLER_LOGO'
export const SET_SELLER_HEADER = 'SET_SELLER_HEADER'
export const EMBED_SELLER_VIDEO = 'EMBED_SELLER_VIDEO'
export const DELETE_SELLER_VIDEO = 'DELETE_SELLER_VIDEO'
export const SAVE_SELLER_VIDEOS = 'SAVE_SELLER_VIDEOS'
export const SAVE_SELLER_ROLE_NOTIFICATIONS = 'SAVE_SELLER_ROLE_NOTIFICATIONS'
export const ADD_SELLER_ROLE_NOTIFICATION = 'ADD_SELLER_ROLE_NOTIFICATION'
export const SAVE_SELLER_REACTIONS = 'SAVE_SELLER_REACTIONS'
export const SAVE_SELLER_ACCOUNTING_DOC = 'SAVE_SELLER_ACCOUNTING_DOC'
export const UPDATE_SELLER_IS_SAME_DAY = 'UPDATE_SELLER_IS_SAME_DAY'
export const UPDATE_SELLER_ACTIVITY = 'UPDATE_SELLER_ACTIVITY'
export const SAVE_SELLER_PRODUCTS_PAGE = 'SAVE_SELLER_PRODUCTS_PAGE'

const saveSellers = sellers => {
    return {
        type: SAVE_SELLERS,
        payload: {
            sellers
        }
    }
}

const createSeller = seller => {
    return {
        type: CREATE_SELLER,
        payload: {
            seller
        }
    }
}

const addSellerRole = (sellerId, userId, roleName) => {
    return {
        type: ADD_SELLER_ROLE,
        payload: {
            sellerId,
            userId,
            roleName
        }
    }
}

const deleteSellerRole = (sellerId, userId) => {
    return {
        type: DELETE_SELLER_ROLE,
        payload: {
            sellerId,
            userId
        }
    }
}

const updateSellerIsActive = (sellerId, isActive) => {
    return {
        type: UPDATE_SELLER_ISACTIVE,
        payload: {
            sellerId,
            isActive
        }
    }
}

const updateSellerIsSameDay = (sellerId, isSameDay) => {
    return {
        type: UPDATE_SELLER_IS_SAME_DAY,
        payload: {
            sellerId,
            isSameDay
        }
    }
}

const addSellerAddress = (sellerId, address) => {
    return{
        type: ADD_SELLER_ADDRESS,
        payload: {
            sellerId,
            address
        }
    }
}

export const deleteSellerAddress = (id, addressId) => {
    return{
        type: DELETE_SELLER_ADDRESS,
        payload: {
            id,
            addressId
        }
    }
}

const updateSellerAddressOpeningHours = (sellerId, addressId, openingHours) => {
    return {
        type: UPDATE_SELLER_ADDRESS_OPENING_HOURS,
        payload: {
            sellerId,
            addressId,
            openingHours
        }
    }
}

const setSellerDefaultAddress = (sellerId, addressId) => {
    return {
        type: SET_SELLER_DEFAULT_ADDRESS,
        payload: {
            sellerId,
            addressId
        }
    }
}


const updateSellerSupportedDeliveryProviders = (sellerId, supportedDeliveryProviders) => {
    return {
        type: UPDATE_SELLER_SUPPORTED_DELIVERY_PROVIDERS,
        payload: {
            sellerId,
            supportedDeliveryProviders
        }
    }
} 

const updateSellerSelfDeliverySettings = (sellerId, selfDelivery) => {
    return {
        type: UPDATE_SELLER_SELF_DELIVERY_SETTINGS,
        payload: {
            sellerId,
            selfDelivery
        }
    }
}

const updateSellerSupportedPaymentProviders = (sellerId, supportedPaymentProviders) => {
    return {
        type: UPDATE_SELLER_SUPPORTED_PAYMENT_PROVIDERS,
        payload: {
            sellerId,
            supportedPaymentProviders
        }
    }
} 

const setSellerLogo = (sellerId, logoImageUrl, logoImageUrlMed, logoImageUrlSmall) => {
    return {
        type: SET_SELLER_LOGO,
        payload: {sellerId, logoImageUrl, logoImageUrlMed, logoImageUrlSmall}
    }
}

const setSellerHeader = (sellerId, headerImageUrl, headerImageUrlMed, headerImageUrlSmall) => {
    return {
        type: SET_SELLER_HEADER,
        payload: {sellerId, headerImageUrl, headerImageUrlMed, headerImageUrlSmall}
    }
}

const embedSellerVideo = (sellerId, video, isDisplayVideo) => {
    return {
        type: EMBED_SELLER_VIDEO,
        payload: {
            sellerId,
            video,
            isDisplayVideo
        }
    }
}

const deleteSellerVideo = (sellerId, videoId, isDisplayVideo, newDisplayVideo) => {
    return {
        type: DELETE_SELLER_VIDEO,
        payload: {
            sellerId, 
            videoId,
            isDisplayVideo,
            newDisplayVideo
        }
    }
}

const saveSellerVideos = (videos=[], sellerId) => {
    return {
        type: SAVE_SELLER_VIDEOS,
        payload: {
            sellerId, 
            videos,
        }
    }
}

const saveSellerRoleNotifications = (sellerId, roleNotifications) => {
    return {
        type: SAVE_SELLER_ROLE_NOTIFICATIONS,
        payload: {sellerId, roleNotifications}
    }
}

const addSellerRoleNotification = (sellerId, userId, deviceId, messagingToken) => {
    return {
        type: ADD_SELLER_ROLE_NOTIFICATION,
        payload: {sellerId, userId, deviceId, messagingToken}
    }
}

const saveSellerReactions = (reactions={}, sellerId, objectId) => {
    return {
        type: SAVE_SELLER_REACTIONS,
        payload: {
            sellerId, 
            objectId,
            reactions,
        }
    }
}

const saveSellerAccountingDoc = (accountingDoc={}, sellerId) => {
    return {
        type: SAVE_SELLER_ACCOUNTING_DOC,
        payload: {
            sellerId,
            accountingDoc
        }
    }
}

const updateSellerActivity = (sellerId, activityId, activityUpdate) => {
    return {
        type: UPDATE_SELLER_ACTIVITY,
        payload: {
            sellerId,
            activityId,
            activityUpdate
        }
    }
}

export const saveSellerProductsPage = (sellerId, sortBy, pageNumber, productIds, firstDoc, lastDoc) => {
    return {
        type: SAVE_SELLER_PRODUCTS_PAGE,
        payload: {
            sellerId,
            sortBy,
            pageNumber,
            productIds,
            firstDoc,
            lastDoc
        }
    }
}
export const fetchSaveSellers = () => {
    const firestore = getFirestore(firebaseApp)
    const sellersRef = query(collection(firestore, "sellers"))
    return async dispatch =>{
        try {
            const querySnapshot = await getDocs(sellersRef)
            //get an array of sellers from the snapshot
            const sellers = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(saveSellers(sellers))
            return true
        } catch (e){
            const message = `action > sellers > fetchSaveSellers: Failed to save sellers`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchCreateSeller = (
    id, 
    name,
    sellerType,
    alertEmail,
    contactName, 
    contactNumber, 
    line1,
    line2,
    city, 
    settlementId,
    countryName,
    countryId, 
    directions,
    bankAccountId,
    accountNumber,
    accountFirstName,
    accountLastName,
    accountType,
    bankId,
    isSameDay,
    openingHours,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellersRef = doc(firestore, "sellers", id)
    const defaultAddressId = uuid4()
    const seller = {
        id,
        name,
        sellerType,
        alertEmail,
        bankAccountId,
        addressesById:{
            [defaultAddressId]: {
                id: defaultAddressId,
                line1,
                line2,
                city,
                settlementId,
                directions,
                countryName,
                countryId,
                contactName,
                contactNumber,
                openingHours
            },
        },
        defaultAddressId,
        roles: {},
        supportedDeliveryProviders: [],
        supportedPaymentProviders: [],
        avgPreparationTimeInMilliseconds: 11 * 60 * 60 * 1000,
        preparationTimeDivisor: 1,
        isActive: true,
        isSameDay,
        createdAt: Date.now()
    }
    const accountsRef = doc(firestore, `sellers/${id}/accounts`, bankAccountId)
    const account = {
        id: bankAccountId,
        firstName: accountFirstName,
        lastName: accountLastName,
        accountNumber,
        accountType,
        bankId
    }
    return async (dispatch, getState) => {
        try{
            const {paymentProviders, deliveryProviders} = getState()
            //add default delivery providers
            if (deliveryProviders.deliveryProvidersById[SHOPDM_DELIVERY_ID]) seller.supportedDeliveryProviders.push(SHOPDM_DELIVERY_ID)
            if (deliveryProviders.deliveryProvidersById[PICK_UP_INSTORE_ID]) seller.supportedDeliveryProviders.push(PICK_UP_INSTORE_ID)
            //add default payment providers
            if (paymentProviders.paymentProvidersById[NBD_MOBANKING_ID]) seller.supportedPaymentProviders.push(NBD_MOBANKING_ID)
            if (paymentProviders.paymentProvidersById[PAYPAL_ID]) seller.supportedPaymentProviders.push(PAYPAL_ID)
            if (paymentProviders.paymentProvidersById[JAD_CARD_PAYMENTS_ID]) seller.supportedPaymentProviders.push(JAD_CARD_PAYMENTS_ID)
            if (paymentProviders.paymentProvidersById[DEBIT_OR_CREDIT_CARD_ID]) seller.supportedPaymentProviders.push(DEBIT_OR_CREDIT_CARD_ID)
            seller.createdByUserId = getState().user.id
            const batch = writeBatch(firestore)
            batch.set(sellersRef, seller)
            batch.set(accountsRef, account)
            await batch.commit()
            dispatch(createSeller(seller))
            onSuccess(seller)
            return true
        } catch (e){
            const message = `action > sellers > fetchCreateSeller: Failed to create seller ${JSON.stringify(seller)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(e)
            return false
        }
        
    }
}

export const fetchSubscribeToSeller = sellerId => {
    /**
      * Purpose: retrieve one seller from the firestore database
      * Note: the onSnapshot below watches for changes to the seller on the server
      */
    const firestore = getFirestore(firebaseApp)
    const sellerRef = doc(firestore, "sellers", sellerId)
                                
    return async (dispatch) => {
        try {
            const sellerListener = await onSnapshot(sellerRef,
                docRef => {
                    if (!docRef.exists()) return
                    //get one seller from the snapshot
                    const seller = {...docRef.data()}
                    dispatch(saveSellers([seller]))
                } 
            )
            return sellerListener
        } catch (e){
            const message = `action > sellers > fetchSubscribeToSeller: Failed to save seller`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchAddSellerRole = (
    id,
    userId,
    roleName,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellersRef = doc(firestore, "sellers", id)
    const roleUpdate = {
        [`roles.${userId}`] : roleName
    }
    return async dispatch =>{
        try {
            await updateDoc(sellersRef, roleUpdate)
            dispatch(addSellerRole(id, userId, roleName))
            onSuccess(roleUpdate)
            return true
        } catch (e){
            const message = `action > sellers > fetchAddSellerRole: Failed to add seller role of ${roleName} for user ${userId} on seller ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchDeleteSellerRole = (
    id,
    userId,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellersRef = doc(firestore, "sellers", id)
    const roleUpdate = {
        [`roles.${userId}`] : deleteField()
    }
    return async dispatch =>{
        try {
            await updateDoc(sellersRef, roleUpdate)
            dispatch(deleteSellerRole(id, userId))
            onSuccess(roleUpdate)
            return true
        } catch (e){
            const message = `action > sellers > fetchDeleteSellerRole: Failed to delete seller role for user ${userId} on seller ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateSellerIsActive = (
    id,
    isActive,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellersRef = doc(firestore, "sellers", id)
    const isActiveUpdate = {
        [`isActive`] : isActive
    }
    return async dispatch =>{
        try {
            await updateDoc(sellersRef, isActiveUpdate)
            dispatch(updateSellerIsActive(id, isActive))
            onSuccess(isActive)
            return true
        } catch (e){
            const message = `action > sellers > fetchUpdateSellerIsActive: Failed to update seller isActive to ${isActive}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateSellerIsSameDay = (
    id,
    isSameDay,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellersRef = doc(firestore, "sellers", id)
    const isSameDayUpdate = {
        [`isSameDay`] : isSameDay
    }
    return async dispatch =>{
        try {
            await updateDoc(sellersRef, isSameDayUpdate)
            dispatch(updateSellerIsSameDay(id, isSameDay))
            onSuccess(isSameDay)
            return true
        } catch (e){
            const message = `action > sellers > fetchUpdateSellerIsSameDay: Failed to update seller isSameDay to ${isSameDay}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateSellerSupportedDeliveryProviders = (
    id,
    deliveryProviderId,
    isSelected,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellersRef = doc(firestore, "sellers", id)
    let supportedDeliveryProviders
    return async (dispatch, getState) =>{
        try {
            const sellers = getState().sellers
            const seller = sellers.sellersById[id]
            supportedDeliveryProviders = seller.supportedDeliveryProviders
            if (isSelected && !supportedDeliveryProviders.includes(deliveryProviderId)){
                supportedDeliveryProviders = [...seller.supportedDeliveryProviders, deliveryProviderId]
            } else if (!isSelected && supportedDeliveryProviders.includes(deliveryProviderId)){
                supportedDeliveryProviders = seller.supportedDeliveryProviders.filter(id => id !== deliveryProviderId)
            }
            await updateDoc(sellersRef, {supportedDeliveryProviders})
            dispatch(updateSellerSupportedDeliveryProviders(id, supportedDeliveryProviders))
            onSuccess(supportedDeliveryProviders)
            return true
        } catch (e){
            const message = `action > sellers > fetchUpdateSellerSupportedDeliveryProviders: Failed to update seller supportedDeliveryProviders to ${JSON.stringify(supportedDeliveryProviders)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateSellerSelfDeliverySettings = (
    id,
    fixedFee,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellersRef = doc(firestore, "sellers", id)
    const selfDelivery = {
        fixedFee,
    }
    return async dispatch =>{
        try {
            await updateDoc(sellersRef, {selfDelivery})
            dispatch(updateSellerSelfDeliverySettings(id, selfDelivery))
            onSuccess(selfDelivery)
            return true
        } catch (e){
            const message = `action > sellers > fetchUpdateSellerSelfDeliverySettings: Failed to add self delivery settings ${JSON.stringify(selfDelivery)} to seller id ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateSellerAddressOpeningHours = (
    id,
    addressId,
    openingHours={},
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellersRef = doc(firestore, "sellers", id)
    return async (dispatch, getState) =>{
        try {
            const seller = getState().sellers.sellersById[id]
            if (!seller) throw new Error("Seller does not exist")
            const address = seller.addressesById[addressId]
            if (!address) throw new Error("Address does not exist")
            address.openingHours = openingHours
            //remove previous approach for storing opening hrs
            delete address["openingTime"]
            delete address["closingTime"]
            await updateDoc(sellersRef, {
                addressesById: {
                    ...seller.addressesById,
                    [addressId]: address
                }
            })
            dispatch(updateSellerAddressOpeningHours(id, addressId, openingHours))
            return true
        } catch (e){
            const message = `action > sellers > fetchUpdateSellerAddressOpeningHours: Failed to update opening hours for seller ${id} with address ${addressId} to ${JSON.stringify(openingHours)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSetSellerDefaultAddress = (
    id,
    addressId,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellersRef = doc(firestore, "sellers", id)
    return async (dispatch, getState) =>{
        try {
            const seller = getState().sellers.sellersById[id]
            if (!seller) throw new Error("Seller does not exist")
            const address = seller.addressesById[addressId]
            if (!address) throw new Error("Address does not exist")
            await updateDoc(sellersRef, {
                defaultAddressId: addressId
            })
            dispatch(setSellerDefaultAddress(id, addressId))
            return true
        } catch (e){
            const message = `action > sellers > fetchSetSellerDefaultAddress: Failed to set default address for seller ${id} to address ${addressId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchAddSellerAddress = (
    id,
    sellerId,
    line1,
    line2,
    city,
    settlementId,
    countryName,
    countryId,
    directions,
    contactNumber,
    contactName,
    openingHours,
    onSuccess=() => {},
    onError=() => {}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellersRef = doc(firestore, "sellers", sellerId)
    const address = {
        id,
        line1,
        line2,
        city,
        settlementId,
        countryName,
        countryId,
        directions,
        contactNumber,
        contactName,
        openingHours
    }
    return async dispatch =>{
        try {
            await updateDoc(sellersRef, {
                [`addressesById.${id}`] : address,
                defaultAddressId: id
            })
            dispatch(addSellerAddress(sellerId, address))
            onSuccess(address)
            return true
        } catch (e){
            const message = `action > sellers > fetchAddSellerAddress: Failed to add address ${JSON.stringify(address)} to seller id ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchDeleteSellerAddress = (
    id,
    addressId,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellersRef = doc(firestore, "sellers", id)
    return async (dispatch, getState) =>{
        try {
            const {sellers} = getState()
            const seller = sellers.sellersById[id]
            if (!seller) throw new Error(`Id ${id} does not correspond to a seller`)
            const sellerAddressIds = Object.keys(seller.addressesById)
            if (sellerAddressIds.length < 2){
                alert("You cannot delete your only address. Add another address before deleting this one.")
                throw new Error(`Cannot delete seller id ${id} only address ${addressId}`)
            }
            //find an alternate default address id if
            //the current default address is the one that has been deleted
            const newDefaultAddressId = seller.defaultAddressId !== addressId ?
                                        seller.defaultAddressId 
                                        :
                                        sellerAddressIds.find(id => id !== addressId)

            await updateDoc(sellersRef, {
                [`addressesById.${addressId}`] : deleteField(),
                defaultAddressId: newDefaultAddressId
            })
            dispatch(deleteSellerAddress(id, addressId))
            return true
        } catch (e){
            const message = `action > sellers > fetchDeleteSellerAddress: Failed to delete address ${addressId} to seller id ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateSellerSupportedPaymentProviders = (
    id,
    paymentProviderId,
    isSelected,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellersRef = doc(firestore, "sellers", id)
    let supportedPaymentProviders
    return async (dispatch, getState) =>{
        try {
            const sellers = getState().sellers
            const seller = sellers.sellersById[id]
            supportedPaymentProviders = seller.supportedPaymentProviders
            if (isSelected && !supportedPaymentProviders.includes(paymentProviderId)){
                supportedPaymentProviders = [...seller.supportedPaymentProviders, paymentProviderId]
            } else if (!isSelected && supportedPaymentProviders.includes(paymentProviderId)){
                supportedPaymentProviders = seller.supportedPaymentProviders.filter(id => id !== paymentProviderId)
            }
            await updateDoc(sellersRef, {supportedPaymentProviders})
            dispatch(updateSellerSupportedPaymentProviders(id, supportedPaymentProviders))
            onSuccess(supportedPaymentProviders)
            return true
        } catch (e){
            const message = `action > sellers > fetchUpdateSellerSupportedPaymentProviders: Failed to update seller supportedPaymentProviders to ${JSON.stringify(supportedPaymentProviders)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateBulkSellersSupportedPaymentProviders = (
    sellerIds=[],
    paymentProviderId,
    isSelected,
    onSuccess= ()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    

    return async (dispatch, getState) =>{
        try {
            const {sellers, user} = getState()
            const batch = writeBatch(firestore)
            const updatedSellerMap = {}
            sellerIds.forEach(sellerId => {
                
                const seller = sellers.sellersById[sellerId]
                let supportedPaymentProviders = [...seller.supportedPaymentProviders]
                if (isSelected && !supportedPaymentProviders.includes(paymentProviderId)){
                    supportedPaymentProviders = [...seller.supportedPaymentProviders, paymentProviderId]
                } else if (!isSelected && supportedPaymentProviders.includes(paymentProviderId)){
                    supportedPaymentProviders = seller.supportedPaymentProviders.filter(id => id !== paymentProviderId)
                }
                const edit = {
                    lastEditedAt: Date.now(),
                    lastEditedByUserId: user.id,
                    supportedPaymentProviders
                }
                const sellersRef = doc(firestore, "sellers", sellerId)
                batch.set(sellersRef, edit, {merge: true})
                updatedSellerMap[sellerId] = supportedPaymentProviders
            })
            await batch.commit()
            Object.keys(updatedSellerMap).forEach(sellerId => {
                dispatch(updateSellerSupportedPaymentProviders(sellerId, updatedSellerMap[sellerId]))
            })
            return true
        } catch (e){
            const message = `action > sellers > fetchUpdateBulkSellersSupportedPaymentProviders: Failed to update seller supportedPaymentProviders of the specified sellers`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSetSellerLogo = (
    id,
    imageFile,
    imageFileMed, 
    imageFileSmall,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellerRef = doc(firestore, 'sellers', id)
    return async (dispatch) => {
        try{
            let logoImageUrl
            let logoImageUrlMed
            let logoImageUrlSmall
            //upload the image
            if (!imageFile.url){ //if this is not a non-upload case where a url is provided
                const storage = getStorage(firebaseApp)
                const imageRef = ref(storage, `sellers/${id}/logo`)
                await uploadBytes(imageRef, imageFile)
                const imageRefMed = ref(storage, `sellers/${id}/logo_med`)
                await uploadBytes(imageRefMed, imageFileMed)
                const imageRefSmall = ref(storage, `sellers/${id}/logo_small`)
                await uploadBytes(imageRefSmall, imageFileSmall)
                //get the image url
                logoImageUrl = await getDownloadURL(imageRef)
                logoImageUrlMed = await getDownloadURL(imageRefMed)
                logoImageUrlSmall = await getDownloadURL(imageRefSmall)
            } 
            //otherwise just take the actual url provided
            else {
                logoImageUrl = imageFile.url
                logoImageUrlMed = imageFile.url
                logoImageUrlSmall = imageFile.url
            }
            await updateDoc(sellerRef, {logoImageUrl,logoImageUrlMed, logoImageUrlSmall})
            dispatch(setSellerLogo(id, logoImageUrl, logoImageUrlMed, logoImageUrlSmall))
            onSuccess(id, logoImageUrl, logoImageUrlMed, logoImageUrlSmall)
            return true
        } catch (e){
            const message = `action > sellers > fetchSetSellerLogo: Failed to set seller ${id} logo`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(imageFile)
            return false
        }
        
    }
}

export const fetchDeleteSellerLogo = (
    id,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellerRef = doc(firestore, 'sellers', id)
    return async (dispatch, getState) => {
        try{
            const {sellers} = getState()
            const seller = sellers.sellersById[id]
            const storage = getStorage(firebaseApp)
            if (seller.logoImageUrl){
                const imageRef = ref(storage, seller.logoImageUrl)
                await deleteObject(imageRef)
            }
            if (seller.logoImageUrlMed){
                const imageRef = ref(storage, seller.logoImageUrlMed)
                await deleteObject(imageRef)
            }
            if (seller.logoImageUrlSmall){
                const imageRef = ref(storage, seller.logoImageUrlSmall)
                await deleteObject(imageRef)
            }
            await updateDoc(sellerRef, {logoImageUrl: "", logoImageUrlMed: "", logoImageUrlSmall: ""})
            dispatch(setSellerLogo(id, "", "", ""))
            return true
        } catch (e){
            const message = `action > sellers > fetchDeleteSellerLogo: Failed to delete seller ${id} logo`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(id)
            return false
        }
        
    }
}

export const fetchSetSellerHeader = (
    id,
    imageFile,
    imageFileMed,
    imageFileSmall,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellerRef = doc(firestore, 'sellers', id)
    return async (dispatch) => {
        try{
            let headerImageUrl
            let headerImageUrlMed
            let headerImageUrlSmall
            //upload the image
            if (!imageFile.url){ //if this is not a non-upload case where a url is provided
                const storage = getStorage(firebaseApp)
                const imageRef = ref(storage, `sellers/${id}/header`)
                await uploadBytes(imageRef, imageFile)
                const imageRefMed = ref(storage, `sellers/${id}/header_med`)
                await uploadBytes(imageRefMed, imageFileMed)
                const imageRefSmall = ref(storage, `sellers/${id}/header_small`)
                await uploadBytes(imageRefSmall, imageFileSmall)
                //get the image url
                headerImageUrl = await getDownloadURL(imageRef)
                headerImageUrlMed = await getDownloadURL(imageRefMed)
                headerImageUrlSmall = await getDownloadURL(imageRefSmall)
            } 
            //otherwise just take the actual url provided
            else {
                headerImageUrl = imageFile.url
                headerImageUrlMed = imageFile.url
                headerImageUrlSmall = imageFile.url
            }
            await updateDoc(sellerRef, {headerImageUrl, headerImageUrlMed, headerImageUrlSmall})
            dispatch(setSellerHeader(id, headerImageUrl, headerImageUrlMed, headerImageUrlSmall))
            onSuccess(id, headerImageUrl, headerImageUrlMed, headerImageUrlSmall)
            return true
        } catch (e){
            const message = `action > sellers > fetchSetSellerHeader: Failed to set seller ${id} header`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(imageFile)
            return false
        }
        
    }
}

export const fetchDeleteSellerHeader = (
    id,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellerRef = doc(firestore, 'sellers', id)
    return async (dispatch, getState) => {
        try{
            const {sellers} = getState()
            const seller = sellers.sellersById[id]
            const storage = getStorage(firebaseApp)
            if (seller.headerImageUrl){
                const imageRef = ref(storage, seller.headerImageUrl)
                await deleteObject(imageRef)
            }
            if (seller.headerImageUrlMed){
                const imageRef = ref(storage, seller.headerImageUrlMed)
                await deleteObject(imageRef)
            }
            if (seller.headerImageUrlSmall){
                const imageRef = ref(storage, seller.headerImageUrlSmall)
                await deleteObject(imageRef)
            }
            await updateDoc(sellerRef, {headerImageUrl: "", headerImageUrlMed: "", headerImageUrlSmall: ""})
            dispatch(setSellerHeader(id, "", "", ""))
            return true
        } catch (e){
            const message = `action > sellers > fetchDeleteSellerHeader: Failed to delete seller ${id} header`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(id)
            return false
        }
        
    }
}

export const subscribeUserToSellerNotifications = (
    id,
    userId,
    deviceId,
    messagingToken,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellerNotificationsRef = doc(firestore, `sellers/${id}/notifications`, SELLER_NOTIFICATIONS_ROLES_ID)
    return async (dispatch) => {
        try {
            await setDoc(sellerNotificationsRef, {
                id: SELLER_NOTIFICATIONS_ROLES_ID,
                users: {
                    [userId] : {
                        [deviceId]: messagingToken
                    }
                }
            }, {merge: true})
            dispatch(addSellerRoleNotification(id, userId, deviceId, messagingToken))
            return true
        } catch (e) {
            const message = `action > sellers > subscribeUserToSellerNotifications: Failed to subscribe user ${userId} to seller ${id} alerts with device ${deviceId} using token ${messagingToken}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}

export const unsubscribeUserFromSellerNotifications = (
    id,
    userId,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellerNotificationsRef = doc(firestore, `sellers/${id}/notifications`, SELLER_NOTIFICATIONS_ROLES_ID)
    return async () => {
        try {
            await setDoc(sellerNotificationsRef, {
                id: SELLER_NOTIFICATIONS_ROLES_ID,
                users: {
                    [userId] : deleteField()
                }
            }, {merge: true})
            return true
        } catch (e) {
            const message = `action > sellers > unsubscribeUserFromSellerNotifications: Failed to unsubscribe user ${userId} from seller notifications on ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}

export const fetchEmbedSellerVideo = (
    id,
    sellerId,
    title,
    url,
    height,
    width,
    aspectRatio,
    orientation,
    isDisplayVideo,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellerVideosRef = doc(firestore, `sellers/${sellerId}/videos/${id}`)
    const video = {
        id,
        url,
        title,
        type: VIDEO_TYPE_SELLER,
        height,
        width,
        aspectRatio,
        orientation,
        views: 0,
        commentCount: 0,
        reactions:{},
        createdAt: Date.now(),
        createdBySellerId: sellerId,
    }
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            video.createdByUserId = user.id
            const batch = writeBatch(firestore)
            batch.set(sellerVideosRef, video)
            if (isDisplayVideo){
                const sellerRef = doc(firestore, 'sellers', sellerId)
                const displayVideo = {...video}
                delete displayVideo.views
                delete displayVideo.commentCount
                delete displayVideo.reactions 
                batch.set(sellerRef, {
                    displayVideo
                }, {merge: true})
            }
            await batch.commit()
            dispatch(embedSellerVideo(sellerId, video, isDisplayVideo))
            onSuccess(video)
            return true
        } catch (e){
            const message = `action > sellers > fetchEmbedSellerVideo: Failed to embed video ${id} for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(video)
            return false
        }
        
    }
}

export const fetchEditEmbedSellerVideo = (
    id,
    sellerId,
    title,
    isDisplayVideo,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellerVideosRef = doc(firestore, `sellers/${sellerId}/videos/${id}`)
    
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            const videoEdit = {
                title,
                isDisplayVideo,
                lastEditedAt: Date.now(),
                lastEditedByUserId: user.id,
            }
            const batch = writeBatch(firestore)
            batch.set(sellerVideosRef, videoEdit, {merge: true})
            if (isDisplayVideo){
                const sellerRef = doc(firestore, 'sellers', sellerId)
                const displayVideo = {...videoEdit}
                batch.set(sellerRef, {
                    displayVideo
                }, {merge: true})
            }
            await batch.commit()
            dispatch(embedSellerVideo(sellerId, videoEdit, isDisplayVideo))
            onSuccess(videoEdit)
            return true
        } catch (e){
            const message = `action > sellers > fetchEmbedSellerVideo: Failed to embed video ${id} for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
        
    }
}

export const fetchDeleteSellerVideo = (
    id,
    sellerId,
    newDisplayVideoId,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellerVideosRef = doc(firestore, `sellers/${sellerId}/videos/${id}`)
    return async (dispatch, getState) => {
        try{
            const {videos, sellers} = getState()
            const seller = sellers.sellersById[sellerId]
            const isDisplayVideo = seller.displayVideo && seller.displayVideo.id === id
            const batch = writeBatch(firestore)
            batch.delete(sellerVideosRef)
            let newDisplayVideo = newDisplayVideoId ? 
                                  {...videos.videosById[newDisplayVideoId]}
                                  : null
            if (newDisplayVideo){
                //remove tracking fields
                delete newDisplayVideo.views
                delete newDisplayVideo.commentCount
                delete newDisplayVideo.reactions 
            }
            if (isDisplayVideo){
                const sellerRef = doc(firestore, 'sellers', sellerId)
                batch.set(sellerRef, {
                    displayVideo: newDisplayVideo
                }, {merge: true})
            }
            await batch.commit()
            dispatch(deleteSellerVideo(sellerId, id, isDisplayVideo, newDisplayVideo))
            onSuccess(id)
            return true
        } catch (e){
            const message = `action > sellers > fetchDeleteSellerVideo: Failed to delete video ${id} for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}

export const fetchSubscribeToSellerVideo = (
    videoId,
    sellerId
 ) => {
    /**
      * Purpose: retrieve one seller video from the firestore database
      * Note: the onSnapshot below watches for changes to the seller video on the server
      */
    const firestore = getFirestore(firebaseApp)
    const sellerVideoRef = doc(firestore, "sellers", `${sellerId}/videos/${videoId}`)
                                
    return async (dispatch) => {
        try {
            const sellerVideoListener = await onSnapshot(sellerVideoRef,
                docRef => {
                    if (!docRef.exists()) return
                    //get one seller video from the snapshot
                    const video = {...docRef.data()}
                    dispatch(saveSellerVideos([video], sellerId))
                } 
            )
            return sellerVideoListener
        } catch (e){
            const message = `action > sellers > fetchSubscribeToSellerVideo: Failed to save seller video`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveSellerRoleNotifications = (
    id,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const sellerNotificationsRef = doc(firestore, `sellers/${id}/notifications`, SELLER_NOTIFICATIONS_ROLES_ID)
    return async (dispatch) => {
        try {
            let roleNotifications = {}
            const docRef = await getDoc(sellerNotificationsRef)
            if (docRef.exists()){
                roleNotifications = docRef.data().users
                dispatch(saveSellerRoleNotifications(id, roleNotifications))
            }
            onSuccess()
            return roleNotifications
        } catch (e) {
            const message = `action > sellers > fetchSaveSellerRoleNotifications: Failed to save role notifications for seller ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(id)
            return false
        }
    }
}

export const fetchSubscribeToSellerObjectReactions = (
    objectId,
    sellerId
 ) => {
    /**
      * Purpose: retrieve the reactions for one seller object from the firestore database
      * Note: the onSnapshot below watches for changes to the seller object reaction on the server
      */
    const firestore = getFirestore(firebaseApp)
    const sellerReactionsRef = doc(firestore, "sellers", `${sellerId}/reactions/${objectId}.reactions`)
                                
    return async (dispatch) => {
        try {
            const sellerReactionsListener = await onSnapshot(sellerReactionsRef,
                docRef => {
                    if (!docRef.exists()) return
                    //get one reactions for one seller object from the snapshot
                    const reactions = {...docRef.data()}
                    dispatch(saveSellerReactions(reactions, sellerId, objectId))
                } 
            )
            return sellerReactionsListener
        } catch (e){
            const message = `action > sellers > fetchSubscribeToSellerObjectReactions: Failed to save reactions for object ${objectId} from seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSubscribeToSellerAccountingDoc = (
    sellerId,
    accountingId
 ) => {
    /**
      * Purpose: retrieve the account document for one seller object from the firestore database
      * Note: the onSnapshot below watches for changes to the seller account doc on the server
      */
    const firestore = getFirestore(firebaseApp)
    const sellerAccountingRef = doc(firestore, "sellers", `${sellerId}/accounting/${accountingId}`)
                                
    return async (dispatch) => {
        try {
            const sellerAccountingListener = await onSnapshot(sellerAccountingRef,
                docRef => {
                    if (!docRef.exists()) return
                    //get one reactions for one seller object from the snapshot
                    const accountingDoc = {...docRef.data()}
                    dispatch(saveSellerAccountingDoc(accountingDoc, sellerId))
                } 
            )
            return sellerAccountingListener
        } catch (e){
            const message = `action > sellers > fetchSubscribeToSellerAccountingDoc: Failed to save accounting doc for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchRemoveSellerProductIds = (
    onSuccess=()=>{},
    onError=()=>{}
) => {
    /**
      * Purpose: remove the product ids for each seller
      */
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) => {
        try {
            const {sellers} = getState()
            const batch = writeBatch(firestore)
            Object.keys(sellers.sellersById).forEach(sellerId => {
                const sellersRef = doc(firestore, "sellers", sellerId)
                batch.set(sellersRef, {
                    productIds: deleteField()
                }, {merge: true})
            })
            await batch.commit()
            return true
        } catch (e){
            const message = `action > sellers > fetchRemoveSellerProductIds: Failed to remove the productIds from the sellers`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchResetSellersAvgPreparationTimes = (
    defaultPreparationTimes={},
    onSuccess=()=>{},
    onError=()=>{}
) => {
    /**
      * Purpose: set a default avg preparation time for each seller
      */
    const firestore = getFirestore(firebaseApp)
    return async (dispatch, getState) => {
        try {
            const {sellers} = getState()
            const batch = writeBatch(firestore)
            Object.keys(sellers.sellersById).forEach(sellerId => {
                const sellersRef = doc(firestore, "sellers", sellerId)
                let avgPreparationTimeInMilliseconds = 11 * 60 * 60 * 1000
                avgPreparationTimeInMilliseconds = defaultPreparationTimes[sellerId] ?
                                     defaultPreparationTimes[sellerId]
                                     :
                                     avgPreparationTimeInMilliseconds
                batch.set(sellersRef, {
                    avgPreparationTimeInMilliseconds,
                    preparationTimeDivisor: 1
                }, {merge: true})
            })
            await batch.commit()
            return true
        } catch (e){
            const message = `action > sellers > fetchResetSellersAvgPreparationTimes: Failed to set the avgPreparationTime for each seller`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchUpdateSellerActivity = (
    id, 
    activityId,
    activityUpdate={},
    onSuccess=()=>{},
    onError=()=>{}
) => {
    /**
      * Purpose: update the activity timestamps for a given seller and a given activity, e.g. last seen
      */
    const firestore = getFirestore(firebaseApp)
    const sellerActivityRef = doc(firestore, `sellers/${id}/activity`, activityId)
    return async (dispatch, getState) => {
        try {
            const {sellers} = getState()
            //if this activity was never recorded before
            //or it was last updated over a minute ago
            if (
                !sellers.activityBySellerId[id] ||
                !sellers.activityBySellerId[id][activityId] ||
                ((activityUpdate.updatedAt - sellers.activityBySellerId[id][activityId].updatedAt) >= (1 * 60 * 1000))
            ){
                //update activity in the db server
                await setDoc(sellerActivityRef, activityUpdate)
                dispatch(updateSellerActivity(id, activityId, activityUpdate))
            }
            onSuccess()
            return true
        } catch (e){
            const message = `action > sellers > fetchUpdateSellerActivity: Failed to update the activity ${activityId} for seller ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}

export const fetchSubscribeToSellerActivity = (
    activityId,
    sellerId
 ) => {
    /**
      * Purpose: retrieve one seller activity from the firestore database
      * Note: the onSnapshot below watches for changes to the seller activity on the server
      */
    const firestore = getFirestore(firebaseApp)
    const sellerActivityRef = doc(firestore, "sellers", `${sellerId}/activity/${activityId}`)
                                
    return async (dispatch) => {
        try {
            const sellerActivityListener = await onSnapshot(sellerActivityRef,
                docRef => {
                    if (!docRef.exists()) return
                    //get one seller activity from the snapshot
                    const activityUpdate = {...docRef.data()}
                    dispatch(updateSellerActivity(sellerId, activityId, activityUpdate))
                } 
            )
            return sellerActivityListener
        } catch (e){
            const message = `action > sellers > fetchSubscribeToSellerActivity: Failed to save activity ${activityId} for seller ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}