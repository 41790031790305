import React from "react"
import styles from "./CheckoutUserAddress.module.css"
import UserAddressModal from "../UserAddressModal"
import Icon
 from "../../components/Icon"
import {connect} from "react-redux"

import {addressObjectToString, capitalizeAllWords} from "../../utils/stringUtils"

import {logAnalyticsEvent} from "../../config/firebase"
import AddressPin from "../../images/pin.png"

class CheckoutUserAddress extends React.Component {
    state = {
        modalOpen: false,
        showDirections: false
    }

    handleOpenModal = () => {
        logAnalyticsEvent("press_change_address_button")
        this.setState({modalOpen: true})
    }
    handleCloseModal = () => this.setState({modalOpen: false})

    handleToggleShowDirections = showDirections => this.setState({showDirections: !this.state.showDirections})

    render(){
        const {user} = this.props
        const selectedAddress = user.addressesById[user.defaultAddressId]
        const {modalOpen, showDirections} = this.state
        return (
        <div className={styles.container}>
            <div className={styles.header}> 
                <div className={styles.deliverTo}>
                    <div className={`strong checkoutTitle`}><span className="badge outline">1</span><img src={AddressPin}/>Deliver To </div>
                    <div className={`${styles.deliverToCity} strong infoHighlight`}>{addressObjectToString(selectedAddress)}, {selectedAddress.contactNumber}</div>
                </div>
                                     
            </div>
            <div>
                <div>
                {
                    !selectedAddress.directions ? 
                    ""
                    :
                    showDirections?
                    <div>
                        <a className={`${styles.toggleDirections} greenAnchor`} onClick={() => this.handleToggleShowDirections()}>hide directions</a>
                        <div className={`${styles.directions} checkoutField`}>
                            {selectedAddress.directions},
                        </div>
                    </div>
                    :
                    <a className={`${styles.toggleDirections} greenAnchor`} onClick={() => this.handleToggleShowDirections()}>see directions</a>
                }
                </div>
                <button className="button dark icon" onClick={this.handleOpenModal}><Icon icon="edit"/>Change Address Or Phone</button>  
                
                
            </div>
            {
                modalOpen ?
                <UserAddressModal isOpen={modalOpen} handleCloseModal={this.handleCloseModal}/>
                :
                null                
            }
        </div>
        )
    }
}

const mapStatetoProps = state => ({
    user: state.user
})

export default connect(mapStatetoProps)(CheckoutUserAddress)