import React from "react"
import TopBar from "../../components/TopBar"
import Footer from "../../containers/Footer"
import CreateAccountForm from "../../containers/CreateAccountForm"
import CreateBuyerAddressForm from "../../containers/CreateBuyerAddressForm"

import {connect} from "react-redux"
import {Redirect, withRouter} from "react-router-dom"
import {Helmet} from "react-helmet";

class CreateAccount extends React.Component{
    constructor(props){
        super(props)
        const {history} = this.props
        const query = new URLSearchParams(history.location.search)
        let redirectFrom = query.get("redirect-from")
        redirectFrom = redirectFrom ? redirectFrom : "/"
        this.state = {
            redirect: props.user.authenticated,
            redirectFrom
        }
    }

    handleCreateAccount = () => this.setState({redirect: true})
    render(){
        const{user} = this.props
        const {redirect, redirectFrom} = this.state
        return (
            <div className={"screen"}>
                <Helmet>
                    <title>Create Your Shopdm Account</title>
                </Helmet>
                <TopBar title="Create Your Account"/>
                <div className={["topBarPresent", "mainContent", "authScreen", "phoneFormContainer","showcase"].join(" ")}>
                    {
                        !redirect
                        ?
                            <CreateAccountForm onSuccess={this.handleCreateAccount}/>
                            :
                        redirect && (Object.keys(user.addressesById).length === 0 )
                        ?
                            <CreateBuyerAddressForm buttonText="Save Address And Continue"/>
                        :
                        redirect ?
                            <Redirect to={redirectFrom}/>
                        :
                            null
                    }
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(withRouter(CreateAccount));