/**
 *  Purpose: the modifications that are carried on the Category objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {SETTINGS_IDENTIFIER} from "../constants/analysis"

// STATE
// categories:{
//   categoriesById:{},
//   categoryIds: [],
//   settings: {},
//   loadedProductIdsByCategoryPage: {}
// }


const categoriesReducer = (state = initialState.categories, action) => {
    const {type, payload} = action;
    let categoriesById = {...state.categoriesById}
    let settings = {...state.settings}
    let loadedProductIdsByCategoryPage = {...state.loadedProductIdsByCategoryPage}
    switch (type){
        case types.SAVE_CATEGORIES: {
            if(typeof payload.categories !== "object") {
                logError(`categoriesReducer > SAVE_CATEGORIES: categories payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.categories.forEach(category => {
                if (category.id !== SETTINGS_IDENTIFIER){
                    categoriesById[category.id] = category
                } else settings = category
            });
            return {
                ...state,
                categoriesById,
                settings,
                loadedProductIdsByCategoryPage,
                categoryIds: Object.keys(categoriesById)
            }
        }

        case types.SAVE_CATEGORY_PAGE: {
            if (typeof payload.categoryId !== "string") {
                logError(`categoriesReducer > SAVE_CATEGORY_PAGE: categoryId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if (typeof payload.sortBy !== "string") {
                logError(`categoriesReducer > SAVE_CATEGORY_PAGE: sortBy payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if (typeof payload.pageNumber !== "number") {
                logError(`categoriesReducer > SAVE_CATEGORY_PAGE: pageNumber payload is not a number ${JSON.stringify(payload)}`)
                return state
            }
            if (!Array.isArray(payload.productIds)) {
                logError(`categoriesReducer > SAVE_CATEGORY_PAGE: productIds payload is not an array ${JSON.stringify(payload)}`)
                return state
            }
            if (typeof payload.firstDoc !== "object") {
                logError(`categoriesReducer > SAVE_CATEGORY_PAGE: firstDoc payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if (typeof payload.lastDoc !== "object") {
                logError(`categoriesReducer > SAVE_CATEGORY_PAGE: firstDoc payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            const {categoryId, sortBy, pageNumber, productIds, firstDoc, lastDoc} = payload
            //if the category does not exist, add it
            if (!loadedProductIdsByCategoryPage[categoryId]) loadedProductIdsByCategoryPage[categoryId] = {}
            //if the sortBy does not exist for this category, add it in
            if (!loadedProductIdsByCategoryPage[categoryId][sortBy]) loadedProductIdsByCategoryPage[categoryId][sortBy] = {}
            //index the list of productIds by the page number
            loadedProductIdsByCategoryPage[categoryId][sortBy][pageNumber] = {
                productIds,
                firstDoc,
                lastDoc
            } 
            return {
                ...state,
                loadedProductIdsByCategoryPage
            }
        }

        case types.UPDATE_FEATURED_CATEGORY_IDS: {
            if(typeof payload.featuredCategoryIds !== "object") {
                logError(`categoriesReducer > UPDATE_FEATURED_CATEGORY_IDS: featuredCategoryIds payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                settings: {
                    ...settings,
                    featuredCategoryIds: payload.featuredCategoryIds
                }
            }
        }

        case types.CREATE_CATEGORY: {
            if(typeof payload.category !== "object") {
                logError(`categoriesReducer > CREATE_CATEGORY: category payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            categoriesById[payload.category.id] = payload.category
            return {
                ...state,
                categoriesById,
                categoryIds: Object.keys(categoriesById)
            }
        }
        default: return state
    }
}

export default categoriesReducer