import React from "react"
import CheckBox from "../../components/CheckBox"
import FormField from "../../components/FormField"
import RadioList from "../../components/RadioList"
import UserAddressModal from "../UserAddressModal"
import Icon from "../../components/Icon"
import {ORIENTATION_HORIZONTAL} from "../../constants/interface"
import styles from "./CheckoutGifting.module.css"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {logAnalyticsEvent} from "../../config/firebase"
import {addressObjectToString, capitalizeAllWords} from "../../utils/stringUtils"
import GiftBox from "../../images/gift-box.png"
import GiveGift from "../../images/give-gift.png"
import GetGift from "../../images/get-gift.png"
import GiveLove from "../../images/give-love.png"

class CheckoutGifting extends React.Component{

    state = {
        modalOpen: false
    }

    fields = {
        recipientName: "",
        contactNumber: "",
        additionalInfo: "",
        isSurprise: false,
        addOns: {}
    }

    giftWrappingFee = 10
    
    handleOpenModal = () => this.setState({modalOpen: true})
    handleCloseModal = () => this.setState({modalOpen: false})

    handleChangeIsGift = () => {
        const {cart, actions} = this.props
        if (!cart.giftParameters){
            logAnalyticsEvent("this_is_a_gift")
            actions.fetchLogDeviceSessionAction({
                action: "thisIsAGift",
            })
        } else {
            logAnalyticsEvent("decided_it_was_not_a_gift")
            actions.fetchLogDeviceSessionAction({
                action: "decidedItWasNotAGift",
            })
        }
        if (cart.giftParameters) actions.updateGiftParameters(null)
        else actions.updateGiftParameters(this.fields)
    }

    handleChange = (e, attribute) => {
        const {cart, actions} = this.props
        const giftParameters = {...cart.giftParameters}
        giftParameters[attribute] = e.target.value
        actions.updateGiftParameters(giftParameters)
    }

    handleChangeContactNumber = (contactNumber, contactNumberValid) => {
        this.handleChange({target:{value: contactNumber}}, "contactNumber")
    }

    handleChangeIsSurprise = () => {
        const {cart, actions} = this.props
        const giftParameters = {...cart.giftParameters}
        if (!giftParameters['isSurprise']){
            logAnalyticsEvent("this_gift_is_a_surprise")
            actions.fetchLogDeviceSessionAction({
                action: "thisGiftIsASurprise",
            })
        } else {
            logAnalyticsEvent("decided_gift_was_not_a_surprise")
            actions.fetchLogDeviceSessionAction({
                action: "decidedGiftWasNotASurprise",
            })
        }
        giftParameters['isSurprise'] = !giftParameters['isSurprise'] 
        actions.updateGiftParameters(giftParameters)
    }

    handleChangeAddOns = () => {
        const {cart, actions} = this.props
        const giftParameters = {...cart.giftParameters}
        //temporary tracker for gift wrapping only, can expand for new addons
        giftParameters['addOns'] = giftParameters['addOns'] && giftParameters['addOns'].giftWrapping ? null : {giftWrapping: this.giftWrappingFee}
        if (!giftParameters['addOns']){
            logAnalyticsEvent("decided_gift_should_not_be_wrapped")
            actions.fetchLogDeviceSessionAction({
                action: "decidedGiftShouldNotBeWrapped",
            })
        } else {
            logAnalyticsEvent("this_gift_should_be_wrapped")
            actions.fetchLogDeviceSessionAction({
                action: "thisGiftShouldBeWrapped",
            })
        }
        actions.updateGiftParameters(giftParameters)    
    }

    render(){
        const {cart, user} = this.props
        const {modalOpen} = this.state
        const selectedAddress = user.addressesById[user.defaultAddressId]
        const {giftParameters} = cart
        const recipientName = giftParameters ? giftParameters.recipientName : ""
        const userName = capitalizeAllWords(`${user.firstName} ${user.lastName}`)
        const contactNumber = giftParameters ? giftParameters.contactNumber : ""
        const additionalInfo = giftParameters ? giftParameters.additionalInfo : ""
        const isSurprise = giftParameters ? giftParameters.isSurprise : false
        const addOns = giftParameters && giftParameters.addOns ? giftParameters.addOns : {}
        const displayName = recipientName ? recipientName : "Your Recipient"
        return (
            <div className={styles.container}>
                <div>
                    <div className={['strong', styles.prompt, 'checkoutTitle'].join(" ")}>
                        <span className="badge outline">2</span><img src={GiftBox}/><span>Will <span className="infoHighlight">{userName}</span> Receive These Items? Or Someone Else?</span>
                    </div>
                    <div className={styles.prompt}>
                        <RadioList 
                            dataArray={[
                                {id: "yes", name: `Yes, ${capitalizeAllWords(user.firstName)}`}, 
                                {id: "no", name: "No, Someone Else"}
                            ]}
                            onChange={this.handleChangeIsGift}
                            selected={Boolean(giftParameters) ? "no" : "yes"}
                            orientation={ORIENTATION_HORIZONTAL}
                        />
                       
                    </div>
                    <div className={styles.explanation}>If so, we'll need their name & phone to deliver the presents or groceries</div>
                </div>
                {
                    giftParameters ? 
                    <React.Fragment>
                        <div className="checkoutField">
                            <div className="strong checkoutTitle med"><img src={GetGift}/>To:</div>
                            <div className={styles.section}>
                                <FormField 
                                    label="Name Of Recipient:" 
                                    placeholder="e.g. Jane Doe"
                                    value={recipientName} 
                                    onChange={e => this.handleChange(e, 'recipientName')}
                                />
                                <div >
                                    <div>{displayName}'s Address: <span className={`${styles.addressValue} infoHighlight`}>{addressObjectToString(selectedAddress)}</span></div> 
                                    <div>{displayName}'s Phone: <span className={`${styles.addressValue} infoHighlight`}>{selectedAddress.contactNumber}</span></div> 
                                    <button className={`button dark ${styles.changeAddress} icon`} onClick={this.handleOpenModal}><Icon icon="edit"/> Change {displayName}'s Address Or Phone</button>
                                </div>
                            </div>
                            <div className="strong checkoutTitle med"><img src={GiveGift}/>From:</div>
                            <div className={styles.section}>
                                <div className={styles.userName}>{userName}</div>
                                <FormField 
                                    label={`${userName}'s Phone Number:`} 
                                    type="tel"
                                    placeholder="e.g. 17676121100"
                                    value={contactNumber} 
                                    onChange={this.handleChangeContactNumber}
                                />
                            </div>
                            <div className="strong checkoutTitle med"><img src={GiveLove}/>Add Extra Love:</div>
                            <div className={styles.section}>
                                <div className={styles.prompt}>
                                    <CheckBox checked={Boolean(addOns.giftWrapping)} onChange={this.handleChangeAddOns}/>Add Gift Wrapping & Card (EC${this.giftWrappingFee.toFixed(2)})?
                                </div>
                                <div className={styles.prompt}>
                                    <CheckBox checked={isSurprise} onChange={this.handleChangeIsSurprise}/>Make It A Surprise? (We won't contact {displayName} until delivery day)
                                </div>
                                <FormField 
                                    label="Add A Special Note For Their Card Or Additional Info" 
                                    type="additionalInfo"
                                    isTextArea={true}
                                    placeholder="e.g. 'I love you David, from Annie xoxoxo' OR Please deliver on the 16th, it's their birthday OR their office closes at 4pm"
                                    value={additionalInfo} 
                                    onChange={e => this.handleChange(e, 'additionalInfo')}
                                />
                            </div>
                        </div>
                        {
                            modalOpen ?
                            <UserAddressModal isOpen={modalOpen} handleCloseModal={this.handleCloseModal}/>
                            :
                            null
                        }
                    </React.Fragment>
                    :
                    null
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    cart: state.cart,
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutGifting)