import {firebaseApp} from "../config/firebase"
import { getFirestore, collection, query, where, setDoc, doc, onSnapshot} from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"

export const CREATE_MESSAGE = 'CREATE_MESSAGE'
export const SAVE_MESSAGES = 'SAVE_MESSAGES'

export const createMessage = (message) => {
    return {
        type: CREATE_MESSAGE,
        payload: {
            message
        }
    }
}

export const saveMessages = messages => {
    return {
        type: SAVE_MESSAGES,
        payload: {
            messages
        }
    }
}

export const fetchSubscribeToMessagesFromDate = fromDate => {
    /**
      * Purpose: retrieve the messages from the firestore database from a particular date
      * Note: the onSnapshot below watches for changes on the server
      */
    const firestore = getFirestore(firebaseApp)
    const messagesRef = query(collection(firestore, "messages"),
                                where("createdAt", ">=", fromDate))
    return async dispatch => {
        try {
            const messagesListener = await onSnapshot(messagesRef,
                querySnapshot => {
                    //get an array of messages from the snapshot
                    const messages = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveMessages(messages))
            })
            return messagesListener
        } catch (e){
            const message = `action > messages > fetchSubscribeToMessagesFromDate: Failed to save messages`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchCreateMessage = (
    id,
    messageType,
    templateType,
    to,
    status,
    params,
    description,
    onSuccess=()=>{}, 
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp);
    const messagesRef = doc(firestore, "messages", id)
    const message = {
        id,
        messageType,
        templateType,
        to,
        status,
        params,
        description,
        createdAt: Date.now()
    }
    return async (dispatch, getState) => {
        try {
            const {user} = getState()
            message.createdByUserId = user.id
            await setDoc(messagesRef, message)
            dispatch(createMessage(message))
            onSuccess(message)
            return true
        } catch (e){
            const msg = `action > messages > fetchCreateMessage: Failed to create message ${message}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${msg}`))
            } else {
                e.message = `${e.message} ${msg}`
                logError(e)
            }
            return false
        }
    }
}
