import React from "react"
import styles from "./ResultScreen.module.css"
import Icon from "../Icon"
import {RESULT_SUCCESS, RESULT_ERROR, RESULT_WARNING} from "../../constants/results"

function ResultScreen({message="", result=RESULT_SUCCESS, displayButton=false, buttonText="OK", onClick=()=>{}}){
    const icon = result === RESULT_SUCCESS ?
                 "done" :
                 result === RESULT_ERROR ?
                 "close" :
                 result === RESULT_WARNING ?
                 "priority-high"
                 :
                 ""
    const resultStyle = result === RESULT_SUCCESS ?
                        styles.success :
                        result === RESULT_ERROR ?
                        styles.error :
                        result === RESULT_WARNING ?
                        styles.warning
                        :
                        ""
    return (
        <div className={`${styles.container} ${resultStyle}`}>
            <div className={styles.innerContainer}>
                { message ? <div className={styles.message}>{message}</div> : null }
                <div className={styles.iconContainer}>
                    <div className={styles.icon}>
                    <Icon icon={icon}/>
                    </div>
                </div>
                {displayButton ?
                    <button className="button white" onClick={onClick}>{buttonText}</button>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default ResultScreen