import React from "react"
import styles from "./Pagination.module.css"
import Icon from "../Icon"

import {logAnalyticsEvent} from "../../config/firebase"

function Pagination({itemCount=0, maxItemsPerPage=10, currentPageIndex=0, onSelectIndex=()=>{}}){
    if (maxItemsPerPage < 1) {
        console.error("maxItemsPerPage must be greater than 0, current value is ", maxItemsPerPage)
        return null
    }
    const pageCount = Math.ceil(itemCount/maxItemsPerPage)
    const tiles = new Array(pageCount).fill(0)
    currentPageIndex = currentPageIndex < pageCount ? currentPageIndex : 0 
    const decreaseIndex = () => {
        const index = currentPageIndex < 1 ? 0 : (currentPageIndex - 1)
        if (index === currentPageIndex) return
        onSelectIndex(index)
        logAnalyticsEvent("pagination_click")
    }
    const increaseIndex = () => {
        let maxIndex = (Math.ceil(itemCount/maxItemsPerPage) - 1)
        maxIndex = maxIndex >= 0 ? maxIndex : 0
        const index = currentPageIndex < maxIndex ? (currentPageIndex + 1) : maxIndex
        if (index === currentPageIndex) return
        onSelectIndex(index)
        logAnalyticsEvent("pagination_click")
    }
    return (
    <div className={styles.container}>
        <div className={styles.navButton} onClick={decreaseIndex}><Icon icon="keyboard-arrow-left"/> Previous</div>
        <div className={styles.innerContainer}>
            
            {
                tiles.map((x, i) => {
                    
                    return <Tile key={`tile${i}`} label={i + 1} selected={i === currentPageIndex} onSelect={() => onSelectIndex(i)}/>
                })
            }
        </div>
        <div className={styles.navButton} onClick={increaseIndex}>Next <Icon icon="keyboard-arrow-right"/></div>
    </div>
    )
}

const Tile = ({label, selected=false, onSelect=()=>{}}) => {
    const selectedStyle = selected ? styles.selected : ""
    return <div 
                className={[styles.tile, selectedStyle].join(" ")} 
                onClick={() => {logAnalyticsEvent("pagination_click"); onSelect()}}
            >{label}</div>
}


export default Pagination