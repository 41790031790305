import React from "react"
import VideoThumbnail from "../../components/VideoThumbnail"
import SellerVideoEmbedForm from "../SellerVideoEmbedForm"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"
import { ENTITY_TYPE_SELLER } from "../../constants/entities"

import {logAnalyticsEvent} from "../../config/firebase"

class SellerVideoThumbnail extends React.Component {

    constructor(props){
        super(props)
        this.videoThumbnailRef = React.createRef()
        this.state = {
            sellerVideoListener: () =>{},
            sellerReactionsListener: () =>{}
        }
    }

    componentDidMount = () => {
        this.subscribeToSeller()
    }

    subscribeToSeller = async () => {
        const {sellerId, videoId, actions} = this.props
        if(sellerId && videoId){
            const sellerVideoListener = await actions.fetchSubscribeToSellerVideo(videoId, sellerId)
            const sellerReactionsListener = await actions.fetchSubscribeToSellerObjectReactions(videoId, sellerId)
            this.setState({sellerVideoListener, sellerReactionsListener}) 
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.videoId !== prevProps.videoId){
            //if the video has been changed
            this.state.sellerVideoListener()
            this.state.sellerReactionsListener()
            this.subscribeToSeller()
        }
    }

    componentWillUnmount(){
        this.state.sellerVideoListener()
        this.state.sellerReactionsListener()
    }

    handleCloseEditModal = () => {
        if (this.videoThumbnailRef) this.videoThumbnailRef.handleCloseEditModal()
    }

    handleDeleteVideo = async () => {
        const {actions, sellerId, videoId} = this.props
        if (!window.confirm(`Are you sure you want to delete this video as well as it's views, reactions and comments? This action cannot be reversed`)) return false
        actions.toggleLoading(true)
        this.state.sellerVideoListener()
        await actions.fetchDeleteSellerVideo(
            videoId,
            sellerId
        )
        actions.toggleLoading(false)
    }
    
    handleViewVideo = () => {
        const {sellerId, videoId, videos, actions} = this.props
        const video = videos.videosById[videoId]
        actions.fetchLogDeviceSessionAction({
            action: "viewSellerVideo",
            videoId,
            sellerId,
            videoTitle: video ? video.title : ""
        })
        logAnalyticsEvent("view_seller_video")
    }

    render(){
        const {sellerId, videoId, videos, user, sellers} = this.props
        const video = videos.videosById[videoId]
        const seller = sellers.sellersById[sellerId]
        return (
            <VideoThumbnail
                ref={ref => this.videoThumbnailRef = ref}
                video={video}
                onDelete={this.handleDeleteVideo}
                onViewVideo={this.handleViewVideo}
                showActions={Boolean(seller.roles[user.id])}
                entityType={ENTITY_TYPE_SELLER}
                entityId={sellerId}
            >
                <SellerVideoEmbedForm 
                    video={video}
                    sellerId={sellerId}
                    onCancel={this.handleCloseEditModal} 
                    postSubmit={this.handleCloseEditModal}
                />
            </VideoThumbnail>
        )
    }
}

const mapStateToProps = state => ({
    videos: state.videos,
    user: state.user,
    sellers: state.sellers
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SellerVideoThumbnail)