import React from "react"
import styles from "./Transaction.module.css"
import Icon from "../Icon"

const Transaction = ({
    totalXcd=0, 
    reason, 
    title, 
    endingBalance=0,
    showEndingBalance=true,
    isCredit=true
}) => { 
        let balanceStyles = (endingBalance < 0) ? styles.negative : ""
        const creditStyle = isCredit ? styles.credit : ""
        const icon = isCredit ? "arrow-downward" : "arrow-upward"
        return (
        <div className={styles.container}>
            <div className={styles.icon}><Icon icon={icon}/></div>
            <div className={styles.transactionDetails}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.reason}>{reason}</div>
                    {
                        showEndingBalance ?
                        <div className={styles.endingBalance}>Ending Balance: <span className={balanceStyles}>${endingBalance.toFixed(2)}</span></div>
                        :
                        null
                    }
            </div>
            <div className={["balance", styles.balance, creditStyle].join(" ")}>
                {isCredit ? "+" : "-"}
                ${totalXcd.toFixed(2).split(".")[0]}.<em>{totalXcd.toFixed(2).split(".")[1]}</em>
            </div>
            
        </div>
        )
    }

export default Transaction