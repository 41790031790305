import React from "react"

import Form from "../../components/Form"
import FormField from "../../components/FormField"
import PasswordFormField from "../../components/PasswordFormField"
import FormTextSeparator from "../../components/FormTextSeparator"
import ResultModal from "../../components/ResultModal"

import {isValidEmail} from "../../utils/formValidationUtils"
import styles from "./LoginForm.module.css"
import {withRouter, Redirect} from "react-router-dom"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {logAnalyticsEvent} from "../../config/firebase"
import {USER_NOT_FOUND, WRONG_PASSWORD, NETWORK_REQUEST_FAILED} from "../../constants/firebase"
import AuthProviderButton from "../AuthProviderButton";

import {RESULT_SUCCESS} from "../../constants/results"
import { capitalizeAllWords } from "../../utils/stringUtils"

class LoginForm extends Form{

    constructor(props){
        super(props)
        this.state = {
            values: {...this.fields},
            errors: {},
            result: false,
            resultMessage: "",
            authenticated: props.user.authenticated
        }
    }

    static defaultProps ={
        redirectTo: '/',
        hideCreateAccount: false,
        formTitle: "Login",
        redirectOnAuth: true,
        onSuccess: ()=> {}
    }
    fields = {
        email: "",
        password: ""
    }

    identifier = 'email'

    componentDidMount = () => {
        const {actions} = this.props
        logAnalyticsEvent("visit_login_page")
        actions.fetchLogDeviceSessionAction({
            action: "visitLoginPage",
        })
    }
    
    handleCreateAccount = () => {
        const {history, redirectTo} = this.props
        history.push(`/create-account?redirect-from=${redirectTo}`)
    }

    handleResetPassword = () => {
        const {history} = this.props
        history.push("/reset-password")
    }

    executeSubmit = async () => {
        const {actions} = this.props
        const {email, password}= this.state.values
        return await actions.fetchLoginWithEmailAndPassword(email, password, this.handleSubmitSuccess, this.handleSubmitError)
    }

    validate = () => {
        const errors = {}
        const {values} = this.state
        const {email, password} = values
        Object.keys(values).forEach(key => {
            if (!Boolean(values[key])){
                errors[key] = `${key} is required`
            }
        })
        if (!errors["email"] && !isValidEmail(email)) errors["email"] = "must be a valid email"
        if (Object.values(errors).every(error => !error)) return true
        else {
            this.setState({errors}, this.scrollToTop)
            return false
        }
    }

    handleSubmitSuccess = (user) => {
        const {actions} = this.props
        logAnalyticsEvent("login", {user_id: user.id})
        actions.fetchLogDeviceSessionAction({
            action: "login",
            userId: user.id
        })
        actions.fetchSaveUserFollows(user.id)
        this.setState({
            result: RESULT_SUCCESS,
            resultMessage: `Login Successful. Welcome back, ${capitalizeAllWords(user.firstName)}`
        })
    } 

    handleSubmitError = object => {
        const errors = {}
        errors["login"] = object['errorCode'] === USER_NOT_FOUND ?
                        `Failed login: Please double check your email. Or if you're trying to make an account, press 'Create An Account' below` :
                        object['errorCode'] === NETWORK_REQUEST_FAILED ?
                        `Failed login: Poor internet connection` :
                        object['errorCode'] === WRONG_PASSWORD ?
                        `Failed login: Incorrect password`
                        :
                        `Failed login`
        this.errorEmail = object['email']
        this.setState({
            errors,
        }, this.scrollToTop)
    }

    postSubmit = () => {
        if (this.errorEmail){
            const email = this.errorEmail
            this.errorEmail = ""
            this.setState({
                values: {
                    email
                }
            })
        }
    }

    render(){
        const {values, errors, authenticated, result, resultMessage} = this.state
        const {email, password} = values
        const {redirectTo, hideCreateAccount, handleCreateAccount, formTitle, redirectOnAuth, onSuccess} = this.props
        let handleClickCreateAccount = handleCreateAccount ? handleCreateAccount : this.handleCreateAccount
        if (authenticated && redirectOnAuth) return <Redirect to={redirectTo} />
        return (
            <div className={['form', styles.container].join(" ")}>
                <div className={"formTitle"}>{formTitle}</div>
                {
                    result ? 
                    <ResultModal 
                        result={result}
                        autoClose={result === RESULT_SUCCESS}
                        message={resultMessage}
                        onClose={() => {
                            this.setState({authenticated: true})
                            onSuccess()
                        }}
                        autoCloseTimeout={1500}
                    />
                     : 
                    null
                }
                
                {

                    errors.login ?
                    <span className={'badge wrap error'}>
                        {errors.login}
                    </span>
                    :
                    null
                }
                <AuthProviderButton  providerName={'Google'} onSuccess={this.handleSubmitSuccess} onError={this.handleSubmitError}/>
                <FormField
                    label="email"
                    type="email"
                    value={email}
                    onChange={e => this.handleChange(e, 'email')}
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.email}
                />
                <PasswordFormField
                    label="password"
                    value={password}
                    onChange={e => this.handleChange(e, 'password')}
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.password}
                />
                <button className="button primary" onClick={this.handleSubmit}>Login</button>
                {
                    (!hideCreateAccount) ?
                    <div>
                        <FormTextSeparator />
                        <span className={'formHelperText'}>
                            Don't have an account yet? <button className={`button dark ${styles.createAccountButton}`} onClick={handleClickCreateAccount}>Click Here To Create An Account</button>
                        </span>
                    </div>
                    :
                    null
                }
                <span className={['formHelperText', styles.resetPassword].join(" ")}>
                    Forgot your password? <span className={['anchorLink', styles.anchorLink].join(" ")} onClick={this.handleResetPassword}>Reset it here</span>
                </span>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginForm));