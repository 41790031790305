import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, onSnapshot, getDocs, query, collection, where, updateDoc } from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"
import { DAY_IN_MILLISECONDS } from "../constants/datetime";

export const SAVE_CARD_AUTHORIZATION_REQUESTS = 'SAVE_CARD_AUTHORIZATION_REQUESTS'

export const saveCardAuthorizationRequests = authRequests => {
    return {
        type: SAVE_CARD_AUTHORIZATION_REQUESTS,
        payload: {
            authRequests
        }
    }
}

export const fetchSubscribeToCardAuthorizationRequest = cardAuthorizationRequestId => {
    /**
      * Purpose: listen to a card authorization request from the firestore database
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp);
    const cardAuthorizationRequestsRef = doc(firestore, "cardAuthorizationRequests", cardAuthorizationRequestId)
                      
    return async dispatch => {
        try {
            const cardAuthorizationRequestListener = await onSnapshot(cardAuthorizationRequestsRef,
                docRef => {
                    //get a card authorization request from the snapshot
                    const cardAuthorizationRequest = docRef.data();
                    dispatch(saveCardAuthorizationRequests([cardAuthorizationRequest]))
            })
            return cardAuthorizationRequestListener
        } catch (e){
            const message = `action > cardAuthorizationRequests > fetchSubscribeToCardAuthorizationRequest: Failed to save cardAuthorizationRequest with id ${cardAuthorizationRequestId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchListenToAdminCardAuthorizationRequestsAfterDate = (
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 7),//defaults to listening to all card authorization requests made in the last 7 days
) => {
    /**
      * Purpose: listen to a card authorization requests from the firestore database, once they are made after the specified date
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp);
    const cardAuthorizationRequestsRef =  query(collection(firestore, "cardAuthorizationRequests"),
                                                where("createdAt", ">=",  fromDate),
                                          )
  
    return async dispatch => {
        try {
            const cardAuthorizationRequestsListener = await onSnapshot(cardAuthorizationRequestsRef,
                querySnapshot => {
                    //get an array of card authorization requests from the snapshot
                    const cardAuthorizationRequests = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveCardAuthorizationRequests(cardAuthorizationRequests))
            })
            return cardAuthorizationRequestsListener
        } catch (e){
            const message = `action > cardAuthorizationRequests > fetchListenToAdminCardAuthorizationRequestsAfterDate: Failed to save cardAuthorizationRequests`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchRequestRefundForCardAuthorizationRequest = (
    cardAuthorizationRequestId,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp);
    const cardAuthorizationRequestsRef = doc(firestore, "cardAuthorizationRequests", cardAuthorizationRequestId)
    return async (dispatch, getState) => {
        try {
            const {user, cardAuthorizationRequests} = getState()
            const cardAuthRequest = cardAuthorizationRequests.authRequestsById[cardAuthorizationRequestId]
            if(!cardAuthRequest) throw Error(`No card authorization request exists for ${cardAuthorizationRequestId}`)
            
            await updateDoc(cardAuthorizationRequestsRef, {
                ...cardAuthRequest,
                lastRefundRequestAt: Date.now(),
                lastEditedAt: Date.now(),
                lastEditedByUserId: user.id
            })
            onSuccess(true)
            return true
        } catch (e){
            const message = `action > cardAuthorizationRequests > fetchRequestRefundForCardAuthorizationRequest: Failed to refund cardAuthorizationRequest ${cardAuthorizationRequestId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}