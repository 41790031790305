import React from "react"
import Modal from "../../components/Modal"
import CartItem from "../../components/CartItem"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"
import {capitalizeAllWords, alphabeticalSort} from "../../utils/stringUtils"

import styles from "./CartMergeModal.module.css"

class CartMergeModal extends React.PureComponent {
    state = {
        showModal: true
    }

    handleCloseModal = () => this.setState({showModal: false})

    handleMergeCart = cartId => {
        const {actions} = this.props
        actions.fetchMergeCart(cartId)
    }

    handleDeleteCart = cartId => {
        const {user} = this.props
        //use unwrapped function
        actions.fetchDeleteCart(user.id, cartId)
    }

    render(){
        const {showModal} = this.state
        const {cart, products, sellers} = this.props
        const cartsToMerge = Object.values(cart.cartsToMergeByCartId)
        if (cartsToMerge.length === 0) return ""
        const cartToMerge = {...cartsToMerge[0]}
        const items = Object.values(cartToMerge.itemsByProductStockId).sort((itemA, itemB)=>{
            const productA = products.productsById[itemA.productId]
            const productB = products.productsById[itemB.productId]
            if (!productA || !productB) return 0
            return alphabeticalSort(productA.titleAndBrand, productB.titleAndBrand)
        })
        return(
            <Modal
                isOpen={showModal}
                closeModal={this.handleCloseModal}
                className={styles.modal}
                showCloseButton={false}
            >
                <div className={["strong", styles.title].join(" ")}>We found {items.length} items in your other cart, would you still like to buy them?</div>
                <div className={styles.cartItemList}>
                    {
                        items.map(item => {
                            const seller = sellers.sellersById[item.sellerId]
                            const product = products.productsById[item.productId]
                            if (!seller || !product) return ""
                            const imageUrl = item.imageUrl ? item.imageUrl : product.imageUrl
                            
                            return <CartItem 
                                        key={item.id}
                                        id={item.id}
                                        imageUrl={imageUrl} 
                                        productTitle={product.titleAndBrand}
                                        productTotal={item.quantity * item.price}
                                        characteristics={item.characteristics}
                                        quantity={item.quantity}
                                        sellerName={capitalizeAllWords(seller.name)}
                                        maxQuantity={item.quantityInStock}
                                        minimumOrderQty={product.minimumOrderQty}
                                        units={product.units}
                                    />
                        })
                    }
                </div>
                <div className={styles.buttonHolder}>
                    <button className="button dark" onClick={() => this.handleDeleteCart(cartToMerge.id)}>No, please remove them</button>
                    <button className="button primary" onClick={() => this.handleMergeCart(cartToMerge.id)}>Yes, add them to my cart</button>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    cart: state.cart,
    products: state.products,
    sellers: state.sellers
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CartMergeModal)