import React from "react"
import styles from "./SuccessIcon.module.css"
import Icon from "../Icon"

function SuccessIcon(){
    return (
            <div className={["responseIcon", styles.container].join(" ")}>
                <Icon icon="done"/>
            </div>
    )
}

export default SuccessIcon