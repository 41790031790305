import React from "react"
import styles from "./Maintenance.module.css"
import logo from "../../images/logo300x300.png"
import supportConfig from "../../config/support"
import {Helmet} from "react-helmet";

const Maintenance = () => {
    return (
        <div className={[styles.container, "screen"].join(" ")}>
            <Helmet>
                <title>Shopdm Maintenance</title>
            </Helmet>
            <p className="productTitle">We're cooking up something good for you, but its not ready yet</p>
            <img src={logo} alt="Shopdm Logo"/>
            <p className="title">Please check back soon!</p>
            <p>Is it absolutely urgent? Email us <a href={`mailto:${supportConfig.maintenanceEmail}`}>here</a></p>
        </div>
    )
}

export default Maintenance;