import React from "react"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import { objectsAreEqual} from "../../utils/generalUtils"
import { 
    getSellerDeliverySelectionByDeliveryProviderId,
    estimateAllReadyAt,
    estimateDeliveryFulfilledAt
} from "../../utils/deliveryUtils"
import * as actions from "../../actions";
import {timestampToDateTimeString} from "../../utils/datetimeUtils"

class DeliveryFulfillmentEstimator extends React.PureComponent {
    /**
     * Purpose:
     * 1. estimate when an order will be fulfilled based on sellerReadinessEstimations and the seller-delivery selections
     */  
    componentDidMount = () => {
        //update the delivery fulfillment estimations right after mounting
        this.updateDeliveryFulfillmentEstimations()
    }

    componentDidUpdate = (prevProps) => {
        const {cart, deliveryTrips, user} = this.props
        const userAddress = user.addressesById[user.defaultAddressId]
        const prevUserAddress = prevProps.user.addressesById[prevProps.user.defaultAddressId]
        //update the trips available to deliver the items to the destination area
        //TODO consider changes in opening hours for bouth stores and delivery providers?
        if ( 
            //if the area that the order items are going to changes or
            userAddress.settlementId !== prevUserAddress.settlementId
            ||
            //if the assignment of delivery providers to sellers has changed or
            !objectsAreEqual(
                cart.selectedDeliveryProviderIdsBySellerId, 
                prevProps.cart.selectedDeliveryProviderIdsBySellerId
            ) ||
            //if the delivery trips available for the specified area have changed 
            !objectsAreEqual(
                deliveryTrips.deliveryTripIdsByAreaId[userAddress.settlementId], 
                prevProps.deliveryTrips.deliveryTripIdsByAreaId[userAddress.settlementId]
            ) ||
            //if the pickup addresses for any of the stores has changed
            !objectsAreEqual(
                cart.selectedPickupAddressIdBySellerId,
                prevProps.cart.selectedPickupAddressIdBySellerId
            ) ||
            //if the seller readiness estimations change
            !objectsAreEqual(
                cart.sellerReadinessEstimationsBySellerId,
                prevProps.cart.sellerReadinessEstimationsBySellerId
            )
        ){
            this.updateDeliveryFulfillmentEstimations()
        }
    }
    
    updateDeliveryFulfillmentEstimations = () => {
        const {deliveryProviders, cart, actions} = this.props
        const sellerDeliverySelections = getSellerDeliverySelectionByDeliveryProviderId(
            deliveryProviders.deliveryProvidersById,
            cart.selectedDeliveryProviderIdsBySellerId,
            cart.selectedPickupAddressIdBySellerId
        )
        const deliveryFulfillmentEstimationsByDeliverySelectionId = Object.values(sellerDeliverySelections).reduce((map, selection) => {
            const {id, deliveryProviderId, sellerIds} = selection
            const deliveryProvider = deliveryProviders.deliveryProvidersById[deliveryProviderId]
            const estimatedAllReadyAt = estimateAllReadyAt(cart.sellerReadinessEstimationsBySellerId, Object.keys(sellerIds), deliveryProvider.type)
            // console.log(`${deliveryProvider.name} items all ready at ${timestampToDateTimeString(estimatedAllReadyAt)} ${Object.keys(sellerIds).length === 1 ? `seller id: ${Object.keys(sellerIds)[0]}`: ""}`)
            map[id] = this.estimateDeliveryFulfilledAt(estimatedAllReadyAt, deliveryProvider)
            return map     
        }, {})
        actions.updateDeliveryFulfillmentEstimations(deliveryFulfillmentEstimationsByDeliverySelectionId)
    }

    estimateDeliveryFulfilledAt = (estimatedAllReadyAt, deliveryProvider) => {
        /**
         * estimate the earliest time the delivery can be  fulfilled at
         */
        const {deliveryTrips, user} = this.props  
        const userAddress = user.addressesById[user.defaultAddressId]
        const estimation = estimateDeliveryFulfilledAt(
            estimatedAllReadyAt, 
            deliveryProvider, 
            userAddress, 
            deliveryTrips
        )
        // if (estimation) console.log(`${deliveryProvider.name} delivery estimated for ${timestampToDateTimeString(estimation.fulfilledAt)} `)
        return estimation
    }

    

    render(){
        return ""
    }
}

const mapStateToProps = state => ({
    user: state.user,
    cart: state.cart,
    deliveryTrips: state.deliveryTrips,
    deliveryProviders: state.deliveryProviders
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryFulfillmentEstimator)