import React from 'react'
import Modal from '../../components/Modal'
import Icon from '../../components/Icon'
import PhotoUpload from '../../components/PhotoUpload'
import styles from "./MobankingReceiptUploadModal.module.css"
import {getTimestampForDM24HrTime, timestampToDateString, getTimeObjectFromMilliseconds, timestampsAreOnSameDay, getTimestampForStartOfDay} from "../../utils/datetimeUtils"
import {DAY_IN_MILLISECONDS} from '../../constants/datetime'

class MobankingReceiptUploadModal extends React.Component {
    constructor(props) {
        super(props)
        let paymentVerificationTimestamps = this.getPaymentVerificationTimestamps()
        //check that there are still payment verifications today, and if so use tomorrows
        const next = this.getNextPaymentVerification(
            paymentVerificationTimestamps, 
            false
        )
        paymentVerificationTimestamps = next.paymentVerificationTimestamps
        const nextPaymentVerification = next.nextPaymentVerification
        const interval = setInterval(() => {
            this.debounce = true
            this.getNextPaymentVerification()
        }, 1000)
        const now = Date.now()
        this.state = {
            imageUrl: "",
            imageFile: null,
            paymentVerificationTimestamps,
            nextPaymentVerification,
            now,
            interval
        }
    }

    static defaultProps = {
        isOpen: false,
        handleCloseModal: () => {},
        handleConfirmPayment: () => {}
    }
    
    handlePressConfirm = () => {
        const {handleConfirmPayment} = this.props
        const {imageFile} = this.state
        handleConfirmPayment(imageFile)
    }

    getPaymentVerificationTimestamps = (timestampOfDay=Date.now()) => {
        const paymentVerificationTimestamps = {}
        paymentVerificationTimestamps.morning = getTimestampForDM24HrTime(7, 30, timestampOfDay)
        paymentVerificationTimestamps.afternoon = getTimestampForDM24HrTime(15, 30)
        paymentVerificationTimestamps.evening = getTimestampForDM24HrTime(23, 30)
        return paymentVerificationTimestamps
    }

    getNextPaymentVerification = (paymentVerificationTimestamps, saveToState=true) => {
        //if paymentVerificationTimestamps is supplied use it, otherwise source it from state
        paymentVerificationTimestamps = paymentVerificationTimestamps ? paymentVerificationTimestamps : {...this.state.paymentVerificationTimestamps}
        const now = Date.now()
        let nextPaymentVerification = 0
        let lastPaymentVerification = 0
        const compareNowToPaymentVerificationTimes = (paymentVerificationTimestamps) => {
            /**
             * find the next payment verification time by finding the first verification time after the current time (now)
             */
            let nextPaymentVerification = 0
            let lastPaymentVerification = 0
            //loop through timestamps to find the first one which is greater than the current time
            Object.keys(paymentVerificationTimestamps).forEach(key => {
                const timestamp = paymentVerificationTimestamps[key]
                if (timestamp > now && !nextPaymentVerification){
                    //take the first timestamp which is later than now
                    nextPaymentVerification = timestamp
                }
                //find the latest timestamp
                if (timestamp > lastPaymentVerification) lastPaymentVerification = timestamp

            })
            return ({nextPaymentVerification, lastPaymentVerification})
        }
        ({nextPaymentVerification, lastPaymentVerification} = compareNowToPaymentVerificationTimes(paymentVerificationTimestamps))
        if (nextPaymentVerification < now){ //if no timestamp was found, 
            if (timestampsAreOnSameDay(lastPaymentVerification, now)){
                //if the last payment verification time has passed, but we are on the same day
                //then get the payment verification timestamps for tomorrow
                paymentVerificationTimestamps =  this.getPaymentVerificationTimestamps(
                    getTimestampForStartOfDay() + (DAY_IN_MILLISECONDS + 1)
                )
            } else {
            //2. or if it is the next day at 12:00AM, and we still have the verification timestamps from yesterday
            //then just set the payment verifications for today
                paymentVerificationTimestamps = this.getPaymentVerificationTimestamps()
            }
            //use tomorrows paymentVerificationTimestamps to find the nextPaymentVerification time 
            nextPaymentVerification = compareNowToPaymentVerificationTimes(paymentVerificationTimestamps).nextPaymentVerification
        }
        if (saveToState){
            //flag on whether we should trigger re-render
            this.setState({
                nextPaymentVerification, 
                paymentVerificationTimestamps,
                now
            })
        } else return {
            nextPaymentVerification, 
            paymentVerificationTimestamps,
        }
        
    }
    handleSelectImage = (imageUrl, imageFile) => this.setState({imageUrl, imageFile})

    componentWillUnmount() {
        // clear interval
        clearInterval(this.state.interval)
    }
    render() {
        const {modalOpen, handleCloseModal, objectType} = this.props
        const {imageUrl, imageFile, paymentVerificationTimestamps, nextPaymentVerification, now} = this.state
        const {hours, minutes} = getTimeObjectFromMilliseconds(nextPaymentVerification - now)
        return (
            <Modal
                isOpen={modalOpen}
                closeModal={handleCloseModal}
                className={`contained ${styles.modal}`}
                innerClassName="contained"
                overlayClassName="center"
                closeOnOverlayClick={false}
                showCloseButton={false}
            >
                <div className="modalHeader">
                    <div className="strong strongTitle">Confirm Your Payment</div>
                </div>
                <div className={`modalInner contained ${styles.modalInner}`}>
                    <div className={styles.instructions}>
                        <div>It's {hours} hrs and {minutes} mins until NBD's next payment verification.</div>
                        <div><b>We will process your {objectType} after {timestampToDateString(nextPaymentVerification, "t ZZZZ")}.</b></div>
                        <div className='instructions'>
                        <Icon icon="info"/>
                            (Optional) Upload your NBD Mobanking receipt so we can begin processing your {objectType} immediately
                        </div>
                    </div>
                    <div className={styles.upload}>
                    <PhotoUpload 
                        imageUrl={imageUrl} 
                        onChange={this.handleSelectImage}
                        buttonText="Upload Receipt"
                    />
                    </div>
                </div>
                <div className="modalFooter">
                    <button className="button dark" onClick={handleCloseModal}>
                        Go Back To Payment
                    </button>
                    <button className='button primary' onClick={(this.debounce) ? this.handlePressConfirm : () => {}}>
                        Yes, I Have Paid
                    </button>
                    
                </div>
            </Modal>
        )
    }
}

export default MobankingReceiptUploadModal