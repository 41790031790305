import React from "react"

import FormField from "../FormField"

import styles from "./MultiSearchField.module.css"

class MultiSearchField extends React.Component {
    static defaultProps = {
        searchTerm: "", 
        searchFields: [],
        searchBy: "",
        onChangeSearchTerm: () => {},
        onChangeSearchBy: () => {}
    }

    render(){
        const {searchTerm, searchFields, searchBy, onChangeSearchTerm, onChangeSearchBy} = this.props
        const searchByKeyToLabelMap = searchFields.reduce((map, field) => {
                                        const {key="", label=""} = field
                                        map[key] = label
                                        return map
                                      }, {})
        return (
            <div className={styles.container}>
                <FormField
                    value={searchTerm} 
                    onChange={e => onChangeSearchTerm(String(e.target.value))}
                    placeholder={`Search By ${searchByKeyToLabelMap[searchBy]}`}
                />
                {
                    searchFields && searchFields.length > 1 ?
                    <select
                        value={searchBy}
                        onChange={e => onChangeSearchBy(e.target.value)}
                    >
                    {
                        searchFields.map(
                            ({key="", label=""}) => <option key={key} value={key}>{label}</option>
                        )
                    }
                    </select>
                    :
                    null
                }
            </div>
        )
    }
}

export default MultiSearchField