/**
 *  Purpose: the modifications that are carried on the orderEdit objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"

// STATE
// orderEdits:{
//   orderEditsById:{},
// }


const orderEditsReducer = (state = initialState.orderEdits, action) => {
    const {type, payload} = action;
    let orderEditsById = {...state.orderEditsById}
    let deletedItemIdsToOrderIds = {...state.deletedItemIdsToOrderIds}
    switch (type){

        case types.CREATE_ORDER_EDIT: {
            if(typeof payload.orderEdit !== "object") {
                logError(`orderEditsReducer > CREATE_ORDER_EDIT: orderEdit payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            orderEditsById[payload.orderEdit.id] = payload.orderEdit
            const itemDeletions = payload.orderEdit.itemDeletions ? payload.orderEdit.itemDeletions : []
            itemDeletions.forEach(item => deletedItemIdsToOrderIds[item.id] = payload.orderEdit.orderId)
            return {
                ...state,
                orderEditsById,
                deletedItemIdsToOrderIds
            }
        }

        case types.LOGOUT: {
            return initialState.orderEdits
        }

        default: return state
    }
}

export default orderEditsReducer