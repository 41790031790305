import React from "react"
import styles from "./TopBar.module.css"
import logo from "../../images/logo.png"

import {withRouter} from "react-router-dom"

function TopBar({title, history, children}){
    return (
        <div className={['topBar', styles.container].join(" ")}>
            <img className={'logo'} draggable="false" src={logo} alt="Shopdm Logo" onClick={()=> {history.push('/')}}/>
            <div className={styles.topBarContent}>
                {children ? children : null}
                {title}
            </div>
        </div>
    )
}

export default withRouter(TopBar);