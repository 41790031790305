import React from "react"
import {v4 as uuid4} from 'uuid'

class SuggestionList extends React.Component{
    constructor(props){
        super(props)
        const {value, dataArray} = props
        const {valueIdMap, idValueMap} = this.getMapsFromDataArray(dataArray)
        const isMobile = Boolean(window.screen.width <= 800)
        const suggestionValue = value ? idValueMap[value] : ""
        const listId = uuid4()
        this.state  = {
            listId,
            valueIdMap,
            idValueMap,
            suggestionValue,
            isMobile
        }
    }

    getMapsFromDataArray = dataArray => {
        const {label} = this.props
        const valueIdMap = {}
        const idValueMap = {}
        dataArray.forEach(d => {
            if (valueIdMap[d[label]]){
                alert(`This list has duplicates, so it will not work properly. The first match will always be chosen`)
                console.log(`duplicate: ${d[label]} ${valueIdMap[d[label]]}`)
            }
            valueIdMap[d[label]] = d.id
            idValueMap[d.id] = d[label]
        })
        return {valueIdMap, idValueMap}
    }

    componentDidUpdate(prevProps){
        //if the data array has been loaded after mounting
        if (this.props.dataArray.length !== prevProps.dataArray.length){
            const {valueIdMap, idValueMap} = this.getMapsFromDataArray(this.props.dataArray)
            this.setState({
                valueIdMap,
                idValueMap
            })
        }
        //allow clearing by parent sending cleared value
        const {valueIdMap, idValueMap, suggestionValue} = this.state
        if (
            !this.props.value && //the new value is blank
            prevProps.value === valueIdMap[suggestionValue] //looking up the suggestion value yeilds a valid value and this is what the previous value was 
        ){
            //in this case, the parent has cleared the value so clear the local suggestion value
            this.setState({suggestionValue: ""})
        } else if ( 
            prevProps.value !== this.props.value && 
            this.props.value
        ){
            //if there is an external change to the value after mounting, update the suggestion value to the one that corresponds to the new value
            this.setState({suggestionValue: idValueMap[this.props.value] ? idValueMap[this.props.value] : ""})
        }
    }
    static defaultProps = {
        dataArray: [],
        value: "",
        label: "name",
        className: "input",
        type: "",
        readOnly: false,
        onChange: ()=>{},
        onKeyDown:()=>{},
        min: null,
        max: null,
        placeholder: "",
        onBlur: ()=>{}
    }

    handleChange = e => {
        const {onChange, value} = this.props
        const {valueIdMap} = this.state
        let suggestionValue = e.target.value
        //if the value is found in the data array, update the parent
        if (valueIdMap[suggestionValue]) {
            const id = valueIdMap[suggestionValue]
            onChange(id)
            e.target.blur()
        } else {
            //if there is a previous value from a valid suggestionValue, remove it
            if (value) onChange("")
        }
        //otherwise, update the suggestions
        this.setState({suggestionValue})
    } 

    handleFocus = e => {
        const {value} = this.props
        if (value) {
            e.target.select()
        }
    }

    render(){
       const {
            dataArray, type, readOnly, onKeyDown,
            min, max, placeholder, onBlur, className, label, value
        } = this.props
        const {listId, suggestionValue, isMobile} = this.state
        
        return (
            <React.Fragment>
                <input
                    className={className}
                    type={type}
                    readOnly={readOnly}
                    value={suggestionValue}
                    onChange={this.handleChange}
                    onKeyDown={onKeyDown}
                    min={min}
                    max={max}
                    list={listId}
                    placeholder={placeholder}
                    onFocus={this.handleFocus}
                    onBlur={onBlur}
                />
                {
                    (!isMobile || suggestionValue) && 
                    dataArray.length > 0 ?
                    <datalist id={listId}>
                        {
                            dataArray.map(
                                d => <option value={d[label]} />
                            )
                        }
                    </datalist>
                    :
                    null
                }
            </React.Fragment>

        )
    }
}

export default SuggestionList