import React from 'react'
import styles from "./Thumbnail.module.css"
import LazyLoad from 'react-lazy-load';

const Thumbnail = ({url, selected=false, expand=false, lazyLoad=true, onClick=()=>{}}) => {
    const selectedStyle = selected ? styles.selected : ""
    const expandStyle = expand ? styles.expand : ""
    return (
        <span className={[styles.container, selectedStyle, expandStyle].join(" ")} onClick={onClick}>
            {
                lazyLoad ?

                <LazyLoad offset={700}>
                <img src={url} className={styles.thumbnailImage} draggable="false" height="45px" width="auto" onContextMenu={(e)=> e.preventDefault()}/>
                </LazyLoad>
                :

                <img src={url} className={styles.thumbnailImage} draggable="false" height="45px" width="auto" onContextMenu={(e)=> e.preventDefault()}/>
            }
        </span>
    )
}

export default Thumbnail