import {firebaseApp} from "../config/firebase"
import { getFirestore, collection, query, where, onSnapshot, getDocs, doc, runTransaction, increment } from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"
import { STATISTICS_IDENTIFIER } from '../constants/analysis';
import { SELLER, DELIVERY_PROVIDER } from "../constants/payouts"
import { SHOPDM_REPLACE_DELIVERY_FEE, SHOPDM_REPLACE_ITEMS_FEE, PAY_INSTORE_FEE} from "../constants/fees" 
import { DAY_IN_MILLISECONDS } from "../constants/datetime";

export const SAVE_FEES = 'SAVE_FEES'

export const saveFees = fees => {
    return {
        type: SAVE_FEES,
        payload: {
            fees
        }
    }
}

export const fetchSaveSellerFees = sellerId => {
    const firestore = getFirestore(firebaseApp);
    const feesRef = query(collection(firestore, "fees") , where("payeeId", "==", sellerId))
                                
    return async (dispatch) => {
        try{
            const feesListener = await onSnapshot(feesRef,
                querySnapshot =>{
                    //get an array of products from the snapshot
                    const fees = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveFees(fees))
                }
            )
            return feesListener
        } catch (e){
            const message = `action > fees > fetchSaveSellerFees: Failed to save fees for ${sellerId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveDeliveryProviderFees = deliveryProviderId => {
    const firestore = getFirestore(firebaseApp);
    const feesRef = query(collection(firestore, "fees") , where("payeeId", "==", deliveryProviderId))
                                
    return async (dispatch) => {
        try{
            const feesListener = await onSnapshot(feesRef,
                querySnapshot =>{
                    //get an array of products from the snapshot
                    const fees = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveFees(fees))
                }
            )
            return feesListener
        } catch (e){
            const message = `action > fees > fetchSaveDeliveryProviderFees: Failed to save fees for ${deliveryProviderId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}
export const fetchSubscribeToUnpaidAdminFees = () => {
    const firestore = getFirestore(firebaseApp);
    const feesRef = query(collection(firestore, "fees"), where("paid", "==", false))
    return async dispatch => {
        try {
            const feesListener = await onSnapshot(feesRef, 
                querySnapshot  => {
                    //get an array of fees from the snapshot
                    const fees = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveFees(fees))
                }
            )
            //get an array of categories from the snapshot
            return feesListener
        } catch (e){
            const message = `action > fees > fetchSubscribeToUnpaidAdminFees: Failed to save fees`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchCreateFee = (
    id,
    feeType,
    itemIds,
    sellerIds,
    orderId,
    orderNumber,
    payeeId,
    payeeName,
    payeeType,
    reason,
    totalXcd,
    onSuccess = () =>{},
    onError = () =>{}
) => {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    const day = now.getDate()
    const fee = {
        id,
        paid: false,
        feeType,
        orderNumber,
        orderId,
        payeeId,
        payeeName,
        payeeType,
        totalXcd,
        createdAt: Date.now(),
        paidAt: null,
        disbursementId: null,
        reason,
    }
    if (fee.feeType === SHOPDM_REPLACE_ITEMS_FEE || fee.feeType === PAY_INSTORE_FEE){
        fee.itemIds = itemIds
    } else if (fee.feeType === SHOPDM_REPLACE_DELIVERY_FEE){
        fee.sellerIds = sellerIds
    }

    return async (dispatch, getState) => {
        try{
            const {user, sellers, deliveryProviders} = getState()
            fee.createdByUserId = user.id
            const firestore = getFirestore(firebaseApp)
            const successful = await runTransaction(firestore, async transaction =>{
                let accountDocRef = null
                if (payeeType === SELLER) {
                    const seller = sellers.sellersById[payeeId]
                    accountDocRef = await transaction.get(doc(firestore, `sellers/${payeeId}/accounts`, seller.bankAccountId))
                } else if (payeeType === DELIVERY_PROVIDER) {
                    const deliveryProvider = deliveryProviders.deliveryProvidersById[payeeId]
                    accountDocRef = await transaction.get(doc(firestore, `deliveryProviders/${payeeId}/accounts`, deliveryProvider.bankAccountId))
                }
                if (!accountDocRef) throw new Error(`There is no account set for this payee ${payeeId} ${payeeName}`)
                const account = accountDocRef.data()
                fee.accountFirstName = account.firstName
                fee.accountLastName = account.lastName
                fee.accountNumber = account.accountNumber
                fee.accountType = account.accountType
                const feeRef = doc(firestore, "fees", fee.id)
                await transaction.set(feeRef, fee)
                const statsRef = doc(firestore, 'fees', STATISTICS_IDENTIFIER);
                const increaseValue = increment(totalXcd);
                const increaseCount = increment(1);
                await transaction.update(statsRef, {
                    [`count${year}`]: increaseCount,
                    [`count${year}-${month}`]: increaseCount,
                    [`count${year}-${month}-days.${day}`]: increaseCount,
                    [`total${year}`]: increaseValue,
                    [`total${year}-${month}`]: increaseValue,
                    [`total${year}-${month}-days.${day}`]: increaseValue,
                })
            })
            // dispatch(createFee(fee))
            onSuccess(fee)
            return true
        } catch (e){
            const message = `action > fees > fetchCreateFee: Failed to create fee ${JSON.stringify(fee)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(fee)
            return false
        }
        
    }
}

export const fetchSaveAdminFeesInDateRange = (
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all fees placed in the last 30 days
    toDate=Date.now()
) => {
    /**
      * Purpose: retrieve the fees from the firestore database created between the specified date range
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const feesRef = query(collection(firestore, "fees"), 
                            where("createdAt", ">=",  fromDate),
                            where("createdAt", "<=", toDate))                          
    return async dispatch => {
        try {
            const feesListener = await onSnapshot(feesRef,
                querySnapshot => {
                    //get an array of fees from the snapshot
                    const fees = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveFees(fees))
                } 
            )
            return feesListener
        } catch (e){
            const message = `action > fees > fetchSaveAdminFeesInDateRange: Failed to save fees from ${fromDate} to ${toDate}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}