import React from "react"
import Transaction from "../Transaction"

const CreditTransaction = ({
    totalXcd=0, 
    walletId,
    recipientWalletId,
    endingRecipientBalance,
    senderName,
    senderTitle,
    recipientName,
    recipientTitle,
    endingSenderBalance,
    createdAt, 
    reason, 
}) => {        
        const isCredit = recipientWalletId === walletId
        let title = ""
        if (isCredit){
            //if you received money
            title = senderTitle ? senderTitle : senderName
        } else {
            //if you sent money
            //use the recipient title if one is provided, otherwise, the recipient name
            title = recipientTitle ? recipientTitle: recipientName
        }
        return (
        <Transaction 
            totalXcd={totalXcd}
            reason={reason} 
            title={title}
            isCredit={isCredit}
            endingBalance={isCredit ? endingRecipientBalance : endingSenderBalance}
        />
        )
    }

export default CreditTransaction