import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, setDoc, onSnapshot, query, where, collection } from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"
import { REFUND_STATUS_PENDING } from "../constants/refunds"

export const SAVE_REFUNDS = 'SAVE_REFUND'
export const CREATE_REFUND = 'CREATE_REFUND'

export const saveRefunds = refunds => {
    return {
        type: SAVE_REFUNDS,
        payload: {
            refunds
        }
    }
}

export const createRefund = refund => {
    return {
        type: CREATE_REFUND,
        payload: {
            refund
        }
    }
}

export const fetchCreateRefund = (
    id,
    orderId,
    sellerId,
    itemId,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp);
    const refundsRef = doc(firestore, "refunds", id)
    const refund = {
        id,
        orderId,
        sellerId,
        itemId,
        currentStatus: REFUND_STATUS_PENDING,
        createdAt: Date.now()
    }
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            refund.createdByUserId = user.id
            await setDoc(refundsRef, refund)
            dispatch(createRefund(refund))
            onSuccess(refund)
            return id
        } catch (e){
            const message = `action > refunds > fetchCreateRefund: Failed to create refund ${JSON.stringify(refund)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(refund)
            return false
        }
    }
}