import React, {useState} from 'react'
import VideoEmbedForm from "../VideoEmbedForm"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {v4 as uuid4} from 'uuid'
import {logAnalyticsEvent} from "../../config/firebase"

function SellerVideoEmbedForm({sellerId, video, postSubmit=()=>{}, onCancel=()=>{}, actions}){
    const[videoId] = useState(video ? video.id : uuid4())
    const submit = video ?
            (
                title,
                url,
                height,
                width,
                aspectRatio,
                orientation,
                isDisplayVideo,
                handleSubmitSuccess, 
                handleSubmitError
            ) => actions.fetchEditEmbedSellerVideo(
                videoId,
                sellerId,
                title,
                isDisplayVideo,
                handleSubmitSuccess, 
                handleSubmitError
            )
            :
            (
                title,
                url,
                height,
                width,
                aspectRatio,
                orientation,
                isDisplayVideo,
                handleSubmitSuccess, 
                handleSubmitError
            ) => {
                
                actions.fetchEmbedSellerVideo(
                    videoId,
                    sellerId,
                    title,
                    url,
                    height,
                    width,
                    aspectRatio,
                    orientation,
                    isDisplayVideo,
                    handleSubmitSuccess, 
                    handleSubmitError
                )
                logAnalyticsEvent("embed_seller_video", {videoId, title, sellerId})
                actions.fetchLogDeviceSessionAction({
                    action: "embedSellerVideo",
                    videoId,
                    title,
                    sellerId
                })
            }
    return (
        <VideoEmbedForm
            sellerId={sellerId}
            videoId={videoId}
            video={video}
            postSubmit={postSubmit}
            submit={submit}
            onCancel={onCancel}
        />
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(SellerVideoEmbedForm);