import React from "react"
import PaginatedProductTileList from "../../components/PaginatedProductTileList"
import Slider from "../../components/Slider"
import ProductTile from "../ProductTile"

import Icon from "../../components/Icon"
import {stringBContainsStringA, capitalizeAllWords} from "../../utils/stringUtils"
import { PROMOTION_STATUS_ONGOING } from "../../constants/promotions"
import {connect} from "react-redux"
import PropTypes from "prop-types"

import styles from "./SellerTileList.module.css"
import Banner from "../../banners/Banner"

class SellerTileList extends React.PureComponent{

    render (){
        const {
            products, sellers, sellerId, promotions, inactiveSellerIds, title="",
            productList=[], totalProductCount=0, pageNumber=0, onClickBack=()=>{}, onClickNext=()=>{}, onChangePage=()=>{}
        } = this.props
        const seller = sellers.sellersById[sellerId]
        //ensure the seller is valid and not inactive
        if (!seller || inactiveSellerIds[sellerId]) return ""
        const sellerName = capitalizeAllWords(seller.name)
        const sellerProductIds = products.productIdsBySellerId[sellerId] ? Object.keys(products.productIdsBySellerId[sellerId]) : []

        const promotedProducts = []
        //build a mapping from category to products
        sellerProductIds.forEach(productId => {
            const product = products.productsById[productId]
            //filter out products from inactive sellers or products that have been set to inactive
            if (product && !inactiveSellerIds[product.createdBySellerId] && !product.isInactive){
                //find products that are part of at least one promotion
                const promotionIdList = promotions.promotionIdsByProductId[product.id] ? 
                                        Object.keys(promotions.promotionIdsByProductId[product.id]) : 
                                        []
                //if there is at least one ongoing promotion, add this product to the promoted product list
                if (promotionIdList.find(promotionId => promotions.promotionsById[promotionId].currentStatus === PROMOTION_STATUS_ONGOING)){
                    promotedProducts.push(product)
                }
            }
        },{})
       
       
        return (
            <div className={styles.container}>
                {seller.banner? <Banner/> : null }
                {
                    promotedProducts.length > 0 ? 
                    <div className={styles.hotDeals}>
                        <div className="strong display-flex align-items-center"><Icon icon="whatshot"/> <span className="space-left-05em">Today's Hot Deals ({promotedProducts.length})</span></div>
                        <Slider>
                        {
                            promotedProducts.map(
                                (product) => <ProductTile 
                                            key={product.id}
                                            productId={product.id}
                                            productPrice={product.price}
                                            productTitle={product.title}
                                            productBrand={product.brand}
                                            productUnits={product.units}
                                            starRating={product.starRating}
                                            imageUrl={product.imageUrl}
                                            createdBySellerId={product.createdBySellerId}
                                            mobileOrientation="portrait"
                                            characteristics={product.characteristics}
                                            stock={product.stock}
                                        />
                            )
                        }
                        </Slider>
                    </div>
                    : null
                }
                <p className={"strong"}>{sellerName}'s Products ({totalProductCount})</p>
                <PaginatedProductTileList 
                    ref={el => this.productList = el}
                    productList={productList} 
                    totalProductCount={totalProductCount}
                    pageNumber={pageNumber}
                    onClickBack={onClickBack}
                    onClickNext={onClickNext}
                    onChangePage={onChangePage} 
                />

            </div>
        )
    }
}

const mapStateToProps = state => ({
    sellers: state.sellers,
    products: state.products,
    inactiveSellerIds : state.sellers.inactiveSellerIds,
    promotions: state.promotions
})

SellerTileList.propTypes = {
    products: PropTypes.shape({
        productsById: PropTypes.object.isRequired,
        productIds: PropTypes.array.isRequired
    }).isRequired,
    productFilter: PropTypes.func,
    title: PropTypes.string
}
export default connect(mapStateToProps)(SellerTileList);