import React from "react"
import Icon from "../Icon"

import styles from './BackNextPagination.module.css'

const BackNextPagination = ({
    onClickBack=()=>{},
    onClickNext=()=>{},
    onChangePage=()=>{},
    disableNext=false,
    disableBack=false
}) => {
    const handleClickBack = () => {
        onChangePage()
        onClickBack()
    }
    const handleClickNext = () => {
        onChangePage()
        onClickNext()
    }
    return (
        <div className={`display-flex justify-content-center ${styles.container}`}>
            {
                disableBack ? 
                <button className="button disabled icon" ><Icon icon="arrow-back"/> Back</button>
                :
                <button className="button gradient icon" onClick={handleClickBack}><Icon icon="arrow-back"/> Back</button>
            }
            {
                disableNext ?
                <button className="button disabled icon icon-right" >Next <Icon icon="arrow-forward"/></button>
                :
                <button className="button gradient icon icon-right" onClick={handleClickNext}>Next <Icon icon="arrow-forward"/></button>
            }
        </div>
    )
}

export default BackNextPagination