import currency from 'currency.js'
import {SHOP_DM_PERCENTAGE} from "../constants/payment"

export const calculatePaymentCharge = (totalBeforeCharge, paymentProvider) => {
    if (!paymentProvider) return 0
    //note: I have decided to keep the math in regular Javascript for readability, as I find it complex/error-prone
    //rather than using currency.js. We determine the payment charge and there will be no issue
    //once the value is returned to 2 decimal places
    let charge = 0
    switch (paymentProvider.feeType){
        case "fixed": charge += paymentProvider.fixedFee; break;
        //basic percentage is used when the payment processor adds their percentage on top of the value sent
        case "basicPercentage": charge += (totalBeforeCharge * paymentProvider.percentageFee); break;
        //percentage is used when the payment processor takes their percentage from the amount sent
        case "percentage" : charge += ((totalBeforeCharge/(1 - paymentProvider.percentageFee)) - totalBeforeCharge); break;
        case "composite" : charge += ((totalBeforeCharge/(1 - paymentProvider.percentageFee)) - totalBeforeCharge) + paymentProvider.fixedFee; break;
        default: charge = 0
    }
    return currency(charge).value
}

export const calculateShopdmFee = (total) => currency(total).multiply(SHOP_DM_PERCENTAGE).value
export const calculateDeliveryCharge = (deliveryProviderMap, userAddress) => {
    if (!deliveryProviderMap) return 0
    let charge = currency(0)
    const deliveryProvidersInCharge = {}
    Object.values(deliveryProviderMap).forEach(deliveryProvider => {
        //if the delivery provider offers consolidation and has already contributed to the charge
        const consolidate = deliveryProvider.consolidation && deliveryProvidersInCharge[deliveryProvider.id]
        deliveryProvidersInCharge[deliveryProvider.id] = true
        charge = charge.add(getDeliveryProviderFee(deliveryProvider, userAddress, consolidate))
    })
    return charge.value
}

export const getDeliveryProviderFee = (deliveryProvider, userAddress, consolidate=false) => {
    if (!deliveryProvider) return 0
    let charge = 0
    if (consolidate) {
        //if we are consolidating on this delivery, then return the consolidation fee 
        charge = deliveryProvider.consolidation.fixedFee
    } else {
        //otherwise, calculate the additional base fee
        switch (deliveryProvider.feeType){
            case "fixed": charge = deliveryProvider.fixedFee; break;
            case "locationBased": charge = deliveryProvider.deliverySettlementIds[userAddress.settlementId]; break;
            case "dynamic": charge = deliveryProvider.totalFee; break;
            default: charge = 0
        }
    }
    return charge
}