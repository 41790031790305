import React from "react"
import Modal from "../Modal"
import UserRow from "../UserRow"
import styles from "./ReactionListModal.module.css"

import {logAnalyticsEvent} from "../../config/firebase"

class ReactionListModal extends React.Component {

    constructor(props){
        super(props)
        logAnalyticsEvent("viewed_who_reacted")
    }
    static defaultProps = {
        reactionList: []
    }
    render(){
        const {modalOpen, handleCloseModal, reactionList} = this.props
        return (
            <Modal
                isOpen={modalOpen}
                closeModal={handleCloseModal}
                closeOnOverlayClick={true}
                title={`Big Ups (${reactionList.length})`}
            >
                {
                    reactionList.map(
                        reaction => <div key={reaction.userId} className={styles.row}>
                                        <UserRow 
                                            id={reaction.userId}
                                            name={reaction.userName}
                                            imageUrl={reaction.profileImageUrl}
                                        />
                                    </div>
                    )
                }
            </Modal>
        )
    }
}

export default ReactionListModal