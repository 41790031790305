import React from "react"

import Form from "../../components/Form"
import FormField from "../../components/FormField"
import PasswordFormField from "../../components/PasswordFormField"
import FormTextSeparator from "../../components/FormTextSeparator"
import ResultModal from "../../components/ResultModal"

import {EMAIL_ALREADY_IN_USE_ERROR} from "../../constants/firebase"
import {isValidEmail, isValidPassword, passwordFieldValueMatchesConfirmPassword} from "../../utils/formValidationUtils"
import styles from "./CreateAccountForm.module.css"
import {withRouter, Link} from "react-router-dom"

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {logAnalyticsEvent} from "../../config/firebase"
import AuthProviderButton from "../AuthProviderButton";

import {RESULT_SUCCESS} from "../../constants/results"
import { capitalizeAllWords } from "../../utils/stringUtils"
class CreateAccountForm extends Form{

    constructor(props){
        super(props)
        this.state = {
            values: {...this.fields},
            errors: {},
            result: false,
            resultMessage: "",
        }
    }

    componentDidMount = () => {
        const {actions} = this.props
        logAnalyticsEvent("visit_create_account_page")
        actions.fetchLogDeviceSessionAction({
            action: "visitCreateAccountPage",
        })
    }

    fields = {
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
    }

    identifier = 'email'

    static defaultProps = {
        formTitle: 'Create Account',
        hideLowerFields: true
    }
    handleLogin = () => {
        const {history} = this.props
        const query = new URLSearchParams(history.location.search)
        let redirectFrom = query.get("redirect-from")
        history.push(`/login${redirectFrom ? `?redirect-from=${redirectFrom}`: ""}`)
    }

    validate = () => {
        const errors = {}
        const {values} = this.state
        const {email, password, confirmPassword, firstName, lastName} = values
        Object.keys(values).forEach(key => {
            if (!Boolean(values[key])){
                const label = key === "lastName" ? "last name" : key === "firstName" ? "first name" : key
                errors[key] = `${label} is required`
            }
        })
        if (!errors["email"] && !isValidEmail(email)) errors["email"] = "must be a valid email"
        if (!passwordFieldValueMatchesConfirmPassword(password, confirmPassword)){
            errors['password'] = "passwords do not match"
            errors['confirmPassword'] = "passwords do not match"
        }
        if (!errors["password"] && !isValidPassword(password)) errors["password"] = "minimum password: 8 characters, 1 uppercase letter, 1 number"
        if (Object.values(errors).every(error => !error)) return true
        else {
            this.setState({errors}, this.scrollToTop)
            return false
        }
    }

    executeSubmit = async () => {
        const {actions} = this.props
        const {email, password, firstName, lastName}= this.state.values
        return await actions.fetchCreateUserWithEmailAndPassword(
            email.toLowerCase(), 
            password, 
            firstName.toLowerCase(), 
            lastName.toLowerCase(), 
            this.handleSubmitSuccess, 
            this.handleSubmitError
        )
    }

    handleSubmitSuccess = (user) => {
        const name = capitalizeAllWords(user.firstName)
        const {actions}= this.props
        let resultMessage = `Welcome to Shopdm ${name}. You're almost done!`
        if (user.lastLogin === user.createdAt){
            //if the user has indeed just been created
            logAnalyticsEvent("create_account", {user_id: user.id})
            actions.fetchLogDeviceSessionAction({
                action: "createAccount",
                userId: user.id
            })
        } else {
            //otherwise the user has signed in from the Create Account page using a federated login provider such as google
            resultMessage = `Login Successful. Welcome back, ${name}`
            logAnalyticsEvent("login", {user_id: user.id})
            actions.fetchLogDeviceSessionAction({
                action: "login",
                userId: user.id
            })
            actions.fetchSaveUserFollows(user.id)
        }
        this.setState({
            result: RESULT_SUCCESS,
            resultMessage
        }) 
    } 

    handleSubmitError = (object, e) => {
        let errorDetails = ""
        switch (e.code){
            case EMAIL_ALREADY_IN_USE_ERROR: errorDetails = `: an account already exists with the email ${object[this.identifier]}`; break;
            default: errorDetails = ""
        } 
        const errors = {}
        errors["createAccount"] = `Failed to create account${errorDetails}`
        this.setState({errors}, this.scrollToTop)
    }

    render(){
        const {values, errors, result, resultMessage} = this.state
        const {formTitle, handleLogin, onSuccess, hideLowerFields} = this.props
        const {email, password, confirmPassword, firstName, lastName} = values
        const handleClickLogin = handleLogin ? handleLogin : this.handleLogin
        return (
            <div className={['form', styles.container].join(" ")}>
                <div className={"formTitle"}>{formTitle}</div>
                {
                    result ? 
                    <ResultModal 
                        result={result}
                        autoClose={result === RESULT_SUCCESS}
                        message={resultMessage}
                        onClose={onSuccess}
                        autoCloseTimeout={1500}
                    />
                     : 
                    null
                }
                {
                    errors.createAccount ?
                    <span className={'badge wrap error'}>
                        {errors.createAccount}
                    </span>
                    :
                    null
                }
                <AuthProviderButton  providerName={'Google'} onSuccess={this.handleSubmitSuccess}/>
                <FormField 
                    label="email" 
                    type="email"
                    value={email} 
                    onChange={e => this.handleChange(e, 'email')}
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.email}
                />
                <PasswordFormField 
                    label="password"
                    type="password" 
                    value={password} 
                    onChange={e => this.handleChange(e, 'password')}
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.password}
                    note="Minimum Password: 8 Characters, 1 Uppercase Letter, 1 Number "
                />
                {
                    !hideLowerFields || (email && password)? 
                    <React.Fragment>
                        <PasswordFormField 
                            label="confirm password"
                            type="password" 
                            value={confirmPassword} 
                            onChange={e => this.handleChange(e, 'confirmPassword')}
                            onKeyDown={this.handleKeyDown}
                            errorText={errors.confirmPassword}
                        />
                        <FormField 
                            label="first name" 
                            value={firstName} 
                            onChange={e => this.handleChange(e, 'firstName')}
                            onKeyDown={this.handleKeyDown}
                            errorText={errors.firstName}
                        />
                        <FormField 
                            label="last name" 
                            value={lastName} 
                            onChange={e => this.handleChange(e, 'lastName')}
                            onKeyDown={this.handleKeyDown}
                            errorText={errors.lastName}
                        />
                    </React.Fragment>
                    :
                    null
                }
                <button className="button primary" onClick={this.handleSubmit}>Create Account</button>
                <FormTextSeparator />
                <span className={'formHelperText'}>
                    Already have an account? <button className={`button dark ${styles.loginButton}`} onClick={handleClickLogin}>Click Here To Log In</button>
                </span>
                <div className={['formHelperText', styles.terms].join(" ")}>
                By creating an account, you agree to our <Link to="/terms-and-conditions">Terms and Conditions</Link>. Please also see our <Link to="/privacy">Privacy Policy</Link>.
                </div>
            </div>
        )
    }
    
}

const mapStateToProps = state => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateAccountForm));