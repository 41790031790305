/**
 *  Purpose: the modifications that are carried on the Order objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {STATISTICS_IDENTIFIER} from "../constants/analysis"
import {SELLER_CANCELLED, SELLER_ORDER_COMPLETION_VERIFIED, THIRD_PARTY_DELIVERY_COMPLETION_VERIFIED, COMPLETED, SHOPDM_CANCELLED} from "../constants/order"
// STATE
// orders:{
//   ordersById:{},
//   orderIdsBySellerId: {},
//   orderIdsByDeliveryProviderId: {},
//   orderIdsByOrderNumber: {},
//   orderIds: []
//   stats: {}
// }


const ordersReducer = (state = initialState.orders, action) => {
    const {type, payload} = action;
    let ordersById = {...state.ordersById}
    let orderIdsBySellerId = {...state.orderIdsBySellerId}
    let orderIdsByDeliveryProviderId = {...state.orderIdsByDeliveryProviderId}
    let orderIdsByOrderNumber = {...state.orderIdsByOrderNumber}
    let stats = {...state.stats}

    switch (type){
        case types.CREATE_ORDER: {
            if(typeof payload.order !== "object") {
                logError(`ordersReducer > CREATE_ORDER: order payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            ordersById[payload.order.id] = payload.order
            orderIdsByOrderNumber[payload.order.orderNumber] = payload.order.id
            payload.order.sellerIds.forEach(sellerId => {
                if (!orderIdsBySellerId[sellerId]) orderIdsBySellerId[sellerId] = {}
                orderIdsBySellerId[sellerId][payload.order.id] = true
            })
            payload.order.deliveryProviderIds.forEach(deliveryProviderId => {
                if (!orderIdsByDeliveryProviderId[deliveryProviderId]) orderIdsByDeliveryProviderId[deliveryProviderId] = {}
                orderIdsByDeliveryProviderId[deliveryProviderId][payload.order.id] = true
            })
            return {
                ...state,
                ordersById,
                orderIdsBySellerId,
                orderIdsByDeliveryProviderId,
                orderIdsByOrderNumber,
                orderIds: Object.keys(ordersById)
            }
        }
        case types.SAVE_ORDERS: {
            if(typeof payload.orders !== "object") {
                logError(`ordersReducer > SAVE_ORDERS: orders payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.orders.forEach(order => {
                if (!order.id) {
                    console.log("empty order??", order)
                }
                else if (order.id !== STATISTICS_IDENTIFIER){
                    ordersById[order.id] = order
                    orderIdsByOrderNumber[order.orderNumber] = order.id
                    order.sellerIds.forEach(sellerId => {
                        if (!orderIdsBySellerId[sellerId]) orderIdsBySellerId[sellerId] = {}
                        orderIdsBySellerId[sellerId][order.id] = true
                    }) 
                    order.deliveryProviderIds.forEach(deliveryProviderId => {
                        if (!orderIdsByDeliveryProviderId[deliveryProviderId]) orderIdsByDeliveryProviderId[deliveryProviderId] = {}
                        orderIdsByDeliveryProviderId[deliveryProviderId][order.id] = true
                    })
                } else stats = order
            });
            return {
                ...state,
                ordersById,
                orderIdsBySellerId,
                orderIdsByDeliveryProviderId,
                orderIdsByOrderNumber,
                stats,
                orderIds: Object.keys(ordersById)
            }
        }

        case types.UPDATE_ORDER_STATUS: {
            if(typeof payload.statusUpdate !== "object"){
                logError(`ordersReducer > UPDATE_ORDER_STATUS: statusUpdate payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.orderId !== "string"){
                logError(`ordersReducer > UPDATE_ORDER_STATUS: orderId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            const {currentStatus, lastEditedAt, lastEditedByUserId} = payload.statusUpdate
            ordersById[payload.orderId] = {
                ...ordersById[payload.orderId],
                currentStatus,
                lastEditedAt,
                lastEditedByUserId,
                orderStatus: {
                    ...ordersById[payload.orderId].orderStatus,
                    [currentStatus]: payload.statusUpdate[`orderStatus.${currentStatus}`]
                }
            }
            return {
                ...state,
                ordersById,
            }
        }

        case types.UPDATE_SELLER_ORDER_STATUS: {
            if(typeof payload.statusUpdate !== "object"){
                logError(`ordersReducer > UPDATE_SELLER_ORDER_STATUS: statusUpdate payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.statusHistory !== "object"){
                logError(`ordersReducer > UPDATE_SELLER_ORDER_STATUS: statusHistory payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.orderId !== "string"){
                logError(`ordersReducer > UPDATE_SELLER_ORDER_STATUS: orderId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.sellerId !== "string"){
                logError(`ordersReducer > UPDATE_SELLER_ORDER_STATUS: sellerId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            const {lastEditedAt, lastEditedByUserId} = payload.statusUpdate
            const order = {
                ...ordersById[payload.orderId],
                lastEditedAt,
                lastEditedByUserId,
                sellerOrderStatus: {
                    ...ordersById[payload.orderId].sellerOrderStatus, 
                    [payload.sellerId]: payload.statusHistory
                }
            }
            //if the seller order status has changed so that the whole order should be cancelled
            if (
                order.currentStatus !== SHOPDM_CANCELLED &&
                order.currentStatus !== COMPLETED &&
                order.sellerIds.every(sellerId => order.sellerOrderStatus[sellerId] && order.sellerOrderStatus[sellerId][SELLER_CANCELLED])
            ){
                order.orderStatus[SHOPDM_CANCELLED] = Date.now() 
                order.currentStatus = SHOPDM_CANCELLED
            }
            //or so that the whole order is now complete, then update the current status of the order
            else if (order.sellerIds.every(sellerId => order.sellerOrderStatus[sellerId] && 
                        (
                            order.sellerOrderStatus[sellerId][SELLER_CANCELLED] ||
                            order.sellerOrderStatus[sellerId][SELLER_ORDER_COMPLETION_VERIFIED] ||
                            order.sellerOrderStatus[sellerId][THIRD_PARTY_DELIVERY_COMPLETION_VERIFIED]
                        )
                    )
            ){
                order.orderStatus[COMPLETED] = Date.now() 
                order.currentStatus = COMPLETED
            }
            return {
                ...state,
                ordersById: {
                    ...ordersById,
                    [payload.orderId]: order
                },
            }
        }

        case types.UPDATE_SELLER_ORDER_PACKAGE_INFO: {
            if(typeof payload.packageInfoUpdate !== "object"){
                logError(`ordersReducer > UPDATE_SELLER_ORDER_PACKAGE_INFO: packageInfoUpdate payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.orderId !== "string"){
                logError(`ordersReducer > UPDATE_SELLER_ORDER_PACKAGE_INFO: orderId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.sellerId !== "string"){
                logError(`ordersReducer > UPDATE_SELLER_ORDER_PACKAGE_INFO: sellerId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            const {
                lastEditedAt, 
                lastEditedByUserId, 
                sellerPackageInfo
            } = payload.packageInfoUpdate
            const {
                numOfPieces, 
                numOfRefrigeratedPieces, 
                numOfFrozenPieces
            } = sellerPackageInfo[payload.sellerId]
            const order = {
                ...ordersById[payload.orderId],
                lastEditedAt,
                lastEditedByUserId,
                sellerPackageInfo: {
                    ...ordersById[payload.orderId].sellerPackageInfo, 
                    [payload.sellerId]: {numOfPieces, numOfFrozenPieces, numOfRefrigeratedPieces}
                }
            }
            return {
                ...state,
                ordersById: {
                    ...ordersById,
                    [payload.orderId]: order
                },
            }
        }
        
        case types.RECORD_ACTUAL_ORDER_ITEM_TOTAL: {
            if(typeof payload.orderId !== "string"){
                logError(`ordersReducer > RECORD_ACTUAL_ORDER_ITEM_TOTAL: orderId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.itemId !== "string"){
                logError(`ordersReducer > RECORD_ACTUAL_ORDER_ITEM_TOTAL: itemId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.actualItemTotalXcd !== "number"){
                logError(`ordersReducer > RECORD_ACTUAL_ORDER_ITEM_TOTAL: actualItemTotalXcd payload is not a number ${JSON.stringify(payload)}`)
                return state
            }
            const order = ordersById[payload.orderId]
            return {
                ...state,
                ordersById: {
                    ...ordersById,
                    [payload.orderId] : {
                        ...order,
                        orderItemsById: {
                            ...order.orderItemsById,
                            [payload.itemId]: {
                                ...order.orderItemsById[payload.itemId],
                                actualItemTotalXcd: payload.actualItemTotalXcd
                            }
                        }
                    }
                }
            }
        }

        case types.LOGOUT: {
            return initialState.orders
        }

        default: return state
    }
}

export default ordersReducer