import React from "react"
import MobileNavigationBar from "../../containers/MobileNavigationBar"
import SearchBar from "../../containers/SearchBar"
import ResultsTileList from "../../containers/ResultsTileList"
import SideBar from "../../containers/SideBar"
import Footer from "../../containers/Footer"
import AddedToBasket from "../../containers/AddedToBasket"

import {useLocation, Redirect} from "react-router-dom"
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import styles from "./Results.module.css"
import {Helmet} from "react-helmet";

import {logAnalyticsEvent} from "../../config/firebase"

function Results({search, actions}){
    //get url query
    const query = new URLSearchParams(useLocation().search)
    const sellerId = query.get("sid")
    const categoryId = query.get("cid")
    let queryString = query.get("q")
    //query strings should be in the url, if there is none but the user is on the results page, then set it to the
    //query string in search in redux
    if (queryString && queryString.trim() && queryString !== search.queryString){
        actions.fetchTrackQueryString(queryString)
        logAnalyticsEvent("text_search", {name: queryString})
        actions.fetchLogDeviceSessionAction({
            action: "textSearch", 
            name: queryString
        })
    } else if (!queryString && search.queryString) {
        queryString = search.queryString
    } else if (!queryString) queryString = ""
    
    if (sellerId) return <Redirect to={`/sellers/${sellerId}`}/>
    else if (categoryId) return <Redirect to={`/categories/${categoryId}`}/>
    return (
    <div className={[styles.container, "screen"].join(" ")}>
        <Helmet>
            <title>Search Results</title>
        </Helmet>
        <SearchBar/>
        <div className={["mainContent", "searchBarPresent", styles.mainContent].join(" ")}>
            <SideBar />
            <MobileNavigationBar />
            <AddedToBasket />
            <ResultsTileList queryString={queryString}/>
        </div>
        <Footer />
    </div>
    )
}

const mapStateToProps = state => ({
    search: state.search
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);