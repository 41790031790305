import React from "react"

class EllipsisText extends React.PureComponent {
    
    constructor(props){
        super(props)
        this.state = {
            showMore: props.defaultShowMore
        }
    }

    static defaultProps = {
        defaultShowMore: false,
        text: "",
        maxWidth: 150,
        charDisplayLength: 100,
        showMoreLabel: "more",
        showLessLabel: "hide",
        handleShowMore: null,
        labelStyles:""
    }

    handleShowMore = () => {
        const {handleShowMore} = this.props
        if (typeof handleShowMore === "function") handleShowMore()
        else this.setState({showMore: true})
    }
    
    handleHide = () => {
        this.setState({showMore: false})
    }

    render(){
        const {showMore} = this.state
        const {text, maxWidth, charDisplayLength, showMoreLabel, showLessLabel, labelStyles} = this.props
        if (!text || typeof text !== "string") return ""
        const textStyle = {maxWidth}
        const displayText = text.length <= charDisplayLength ?
                            text
                            :
                            showMore ?
                            <span>{text} <a className={`font-size-14px white-space-nowrap cursor-pointer ${labelStyles}`} onClick={this.handleHide} > - {showLessLabel}</a></span>
                            :
                            <span>{text.substring(0, charDisplayLength)}...<a className={`font-size-14px white-space-nowrap cursor-pointer ${labelStyles}`} onClick={this.handleShowMore}>{showMoreLabel}</a></span>
        return (
                <span 
                    style={textStyle} 
                    className="display-inline-block"
                    
                >
                    {displayText}
                </span>  
        )
    }
}

export default EllipsisText