import React, {useState} from 'react'

import SideBarOption from "../../components/SideBarOption"
import FormField from "../../components/FormField"


import {connect} from "react-redux"
import {withRouter} from "react-router-dom"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import Icon from "../../components/Icon"

import {alphabeticalSort, stringBContainsStringA} from "../../utils/stringUtils"

import {SELLER_OPTION_TYPE} from "../../constants/firebase"

function SellerList({sellers, history, actions, postClick=()=>{}}){
    const [searchTerm, setSearchTerm] = useState("")
    const handleClick = path => {
        actions.updateQueryString("")
        history.push(path)
        setSearchTerm("")
        postClick()
    }

    const sellerList = sellers.sellerIds.map(sellerId => sellers.sellersById[sellerId])
                                        .sort((sellerA, sellerB) => alphabeticalSort(sellerA.name, sellerB.name))
    
    const filteredSellerList = searchTerm && searchTerm.trim() ? 
                                sellerList.filter(seller => stringBContainsStringA(searchTerm, seller.name))
                                : sellerList 
    return(
        <div>
            <p className='strong navigationListHeader'><Icon icon="store"/> Stores ({sellerList.length})</p>
            <FormField 
                value={searchTerm} 
                onChange={e => setSearchTerm(String(e.target.value))}
                placeholder={`Search By Store Name`}
            />
            <div className="navigationList">
            {
                filteredSellerList.map(seller => {
                    // eliminate the seller if they are inactive
                    if (!seller.isActive) return
                    return <SideBarOption 
                                key={seller.id}
                                optionId={seller.id} 
                                label={seller.name}
                                imageUrl={seller.logoImageUrlSmall}
                                type={SELLER_OPTION_TYPE}
                                onClick={handleClick}
                            />
                })
            }
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    sellers: state.sellers,
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SellerList));