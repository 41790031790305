import React from "react"
import styles from "./ProductTile.module.css"
import StarRatingComponent from 'react-star-rating-component';
import DisabledButton from "../../components/DisabledButton"
import LazyLoad from 'react-lazy-load';
import ProductVariantPicker from "../../components/ProductVariantPicker";
import RequestRestockButton from "../RequestRestockButton"
import AddToCartButton from "../AddToCartButton";

import PropTypes from "prop-types"
import {withRouter} from "react-router-dom"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import {capitalizeAllWords} from "../../utils/stringUtils"
import {timestampToDateTimeString} from "../../utils/datetimeUtils"
import {getProductPromotion, calculatePromotionalPrice, getPromotionOfferString} from "../../utils/promotions"
import { getDisplayVariantId } from "../../utils/productUtils";

import {PROMOTION_STATUS_CREATED, PROMOTER_TYPE_SELLER, TIMING_TYPE_EVENT} from "../../constants/promotions"

class ProductTile extends React.PureComponent{
    constructor(props){
        super(props)
        const {productStock, productId, products} = props
        const product = products.productsById[productId]
        const minimumOrderQty = product.minimumOrderQty ? product.minimumOrderQty : 1
        const displayVariantId = getDisplayVariantId(product, productStock)
        this.image = React.createRef() 
        this.state = {
            imageLoaded: false,
            minimumOrderQty,
            displayVariantId
        }
    }

    static defaultProps = {
        characteristics: [],
        stock: {}
    }

    handleImageLoaded = () => this.setState({imageLoaded: true})

    handleClick = () => {
        const {productId, history} = this.props
        const {displayVariantId} = this.state
        history.push(`/product-details?pid=${productId}${displayVariantId ? `&psid=${displayVariantId}`: ""}`, {from: history.location.pathname})
    }
    
    handleChangeDisplayVariant = displayVariantId => this.setState({displayVariantId})

    render(){
        const {
            productId, 
            productTitle, 
            productBrand, 
            productPrice, 
            productUnits, 
            starRating, 
            onClick, 
            sellers, 
            createdBySellerId, 
            mobileOrientation, 
            promotions, 
            events,
            user, //TODO refactor to make this linkto redux unnecessary by passing in activeSellerAccountId
            stock,
            characteristics,
            productStock
        } = this.props
        const {imageLoaded, displayVariantId, minimumOrderQty} = this.state
        const displayVariant = productStock.productStockById[displayVariantId]

        const quantityInStock = displayVariant ? displayVariant.quantityInStock : 0
        const imageUrl = displayVariant && displayVariant.imageUrl? displayVariant.imageUrl : this.props.imageUrl 
        let basePrice = displayVariant ? displayVariant.price : productPrice
        let currentPrice = basePrice
        const promotion = getProductPromotion(productId, createdBySellerId, user.activeSellerAccountId, promotions)

        if (promotion){
            currentPrice = calculatePromotionalPrice(basePrice, promotion)
        }
        const frameStyle = (imageLoaded) ?
                            "postload"
                            :
                            "preload"
        const seller = createdBySellerId ? 
                            sellers.sellersById[createdBySellerId]
                            :
                            null
        const sellerName = seller ? capitalizeAllWords(seller.name) : ""
        const handleClick = onClick ? () => onClick(productId) : this.handleClick
        const mobileOrientationStyle = mobileOrientation === "portrait" ? 
                                        styles.mobilePortrait
                                        :
                                        mobileOrientation === "landscape"?
                                        ""
                                        :
                                        ""
        let event = promotion && promotion.timingType === TIMING_TYPE_EVENT ?
                    events.eventsById[promotion.terms.eventId]
                    :
                    null
        const saleStyle = promotion ? styles.sale : ""
        const titleAndBrand = `${productBrand ? `${productBrand} ` : ""}${productTitle}`
        return (
            <div 
                className={[styles.container, mobileOrientationStyle, saleStyle].join(" ")}
            >
                <div className={frameStyle} onClick={handleClick}>
                    <LazyLoad offset={700}>
                    <img 
                        ref={this.image}
                        className={'productImage'} 
                        src={imageUrl} 
                        alt={productTitle}
                        onLoad={this.handleImageLoaded}
                    />
                    </LazyLoad>
                </div>
                <div className={styles.productInfo}>
                    <div onClick={handleClick}>
                        <div className={styles.productTitle}>
                            {productBrand} {productTitle}{productUnits ? ` EC$${currentPrice}/${productUnits}`: ""}{minimumOrderQty > 1 ? ` (${minimumOrderQty}${productUnits} Min. Order)`: ""}
                        </div>
                        {
                            seller ?
                            <div className={styles.sellerName}>
                                from {sellerName}
                            </div>
                            :
                            null
                        }
                        {
                            starRating ?
                            <StarRatingComponent 
                                name="product-star-rating"
                                value={starRating}
                                editing={false}
                            />
                            :
                            null
                        }
                        {/* if the promotion is from an event, get the event text */}
                        {
                            event
                            ? 
                            <div className="saleEventFlag">{event.name} Deal</div> 
                            : 
                            null
                        }
                        <div className={styles.productPrice}>
                            <span>EC ${currentPrice.toFixed(2)}</span>
                            {promotion ? <span className="salePreviousPrice">${basePrice.toFixed(2)}</span> : null}
                        </div>
                    </div>
                    {
                        characteristics && characteristics.length > 0 ?
                        <div className={styles.variantPickerContainer}>
                            <ProductVariantPicker 
                                variants={stock} 
                                characteristics={characteristics} 
                                selected={displayVariantId}
                                onChange={this.handleChangeDisplayVariant}
                                filterCharOptionsWithNoMatch={true}
                            />
                        </div>
                        :
                        null
                    }
                    
                    {
                        quantityInStock >= minimumOrderQty?
                
                        <AddToCartButton 
                            productId={productId}
                            productStockId={displayVariantId}
                            method="from results"
                        />

                        :
                        <React.Fragment>
                            <DisabledButton
                                alert="Sorry, this item is out of stock"
                            >
                                Out Of Stock
                            </DisabledButton>
                            {
                                productId && displayVariantId && displayVariant && createdBySellerId ?
                                <RequestRestockButton 
                                    productId={productId} 
                                    productStockId={displayVariantId}
                                    productTitle={titleAndBrand} 
                                    productImageUrl={imageUrl}
                                    minimumOrderQty={minimumOrderQty}
                                    price={displayVariant.price}
                                    skuNumber={displayVariant.skuNumber}
                                    variantCharacteristics={displayVariant.characteristics}
                                    restockRequestCount={displayVariant.restockRequestCount}
                                    sellerId={createdBySellerId}
                                    sellerName={sellerName}
                                    sellerAlertEmail={seller ? seller.alertEmail : ""}
                                    sellerLogoImageUrl={seller && seller.logoImageUrlMed ? seller.logoImageUrlMed : ""}
                                />
                                :
                                null
                            }
                        </React.Fragment>
                    }
                </div>
               {
                   promotion ?
                    <div className={styles.saleTag}><div className={styles.saleValue}>{getPromotionOfferString(promotion)}</div> <div className={styles.saleOff}>off</div></div>
                    :
                    null
               }

               {/* if the seller user is looking at their products before the promotion starts */}
               {
                   promotion && 
                   promotion.currentStatus === PROMOTION_STATUS_CREATED &&
                   promotion.promoterType === PROMOTER_TYPE_SELLER &&
                   promotion.promoterId === user.activeSellerAccountId ?
                   <div className={styles.onlyVisible}>Only visible to you.<br /> Promo starts { timestampToDateTimeString(promotion.startDate)}</div>
                   :
                   null
               }
            </div>
        )
    }
}

ProductTile.propTypes = {
    productId: PropTypes.string.isRequired,
    productTitle: PropTypes.string.isRequired,
    productPrice: PropTypes.number.isRequired,
    starRating: PropTypes.number,
    imageUrl: PropTypes.string
}

const mapStateToProps = state => ({
    user: state.user,
    sellers: state.sellers,
    products: state.products,
    productStock: state.productStock,
    promotions: state.promotions,
    events: state.events
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductTile));