export const SELLER_NOTIFICATIONS_ROLES_ID = '--roles--'

export const SELLER_CHARGE_ACCOUNT_ACCOUNTING_DOC_ID = "--charge-account--"

export const DEFAULT_STORE_DAILY_OPENING_HOURS = {openingTime: "08:00", closingTime: "16:00"}
export const DEFAULT_STORE_OPENING_HOURS = {
  monday: DEFAULT_STORE_DAILY_OPENING_HOURS,
  tuesday: DEFAULT_STORE_DAILY_OPENING_HOURS,
  wednesday: DEFAULT_STORE_DAILY_OPENING_HOURS,
  thursday: DEFAULT_STORE_DAILY_OPENING_HOURS,
  friday: DEFAULT_STORE_DAILY_OPENING_HOURS,
  saturday: DEFAULT_STORE_DAILY_OPENING_HOURS,
}