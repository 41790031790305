import {firebaseApp} from "../config/firebase"
import {getFirestore, doc, getDoc, onSnapshot, getDocs, query, collection, where} from "firebase/firestore"
import { logError } from "../utils/errorHandlingUtils"

export const SAVE_EVENTS = 'SAVE_EVENTS'

export const saveEvents = events => {
    return {
        type: SAVE_EVENTS,
        payload: {
            events
        }
    }
}

export const fetchSaveEvent = eventId => {
    /**
      * Purpose: retrieve one event from the firestore database
      */
     const firestore = getFirestore(firebaseApp)
     const eventRef = doc(firestore, "events", eventId)
                                 
     return async (dispatch) => {
         try {
             const docRef =  await getDoc(eventRef)
             const event = docRef.exists() ? {...docRef.data()} : null
             if (event) dispatch(saveEvents([event]))
             return event
         } catch (e){
             const message = `action > events > fetchSaveEvent: Failed to save event`
             if (e.message_){
                 //deal with firebase-specific errors
                 logError(new Error(`${e.message} ${message}`))
             } else {
                 e.message = `${e.message} ${message}`
                 logError(e)
             }
             return false
         }
     }
}

export const fetchSubscribeToEvent = eventId => {
    /**
      * Purpose: retrieve one event from the firestore database
      * Note: the onSnapshot below watches for changes to the event on the server
      */
    const firestore = getFirestore(firebaseApp)
    const eventRef = doc(firestore, "events", eventId)
                                
    return async (dispatch) => {
        try {
            const eventListener = await onSnapshot(eventRef,
                docRef => {
                    if (docRef.exists()){
                        //get one event from the snapshot
                        const event = {...docRef.data()}       
                        dispatch(saveEvents([event]))
                    } 
                }
            )
            return eventListener
        } catch (e){
            const message = `action > events > fetchSubscribeToEvent: Failed to save event`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveActiveEvents = () => {
    /**
      * Purpose: retrieve all active events from the firestore database
      */
     const firestore = getFirestore(firebaseApp)
     const eventsRef = query(collection(firestore, "events"),
                                      where("isActive", "==", true))
     return async (dispatch) => {
         try {
            const eventsListener = await onSnapshot(eventsRef,
                querySnapshot => {
                    //get an array of orders from the snapshot
                    const events = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveEvents(events))
            })
            return eventsListener
         } catch (e){
             const message = `action > events > fetchSaveActiveEvents: Failed to save active events`
             if (e.message_){
                 //deal with firebase-specific errors
                 logError(new Error(`${e.message} ${message}`))
             } else {
                 e.message = `${e.message} ${message}`
                 logError(e)
             }
             return false
         }
     }
}