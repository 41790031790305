import {applyMiddleware, createStore} from 'redux'
import rootReducer from '../reducers'
import {createLogger} from 'redux-logger'
import thunk from 'redux-thunk'
import {persistStore, persistReducer, createMigrate} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const empty = () => {
    return next => action => {
      return next(action)
    }
  }

const logger = process.env.NODE_ENV === 'production'?  empty : createLogger()
const middleware = applyMiddleware(thunk, logger)

const migrations = {
  0: (state) => {
    return {
      ...state,
      cart: {
        ...state.cart,
        selectedPickupAddressIdBySellerId: {}
      }
    }
  },
  1: (state) => {
    return {
      ...state,
      cart: {
        ...state.cart,
        deliverySlotsById: {},
        deliverySlotIdsBySellerId: {},
        deliverySlotIdsByDeliveryProviderId:{},
        sellerReadinessEstimationsBySellerId: {},
        weeklyAvailableDeliverySlotsByDeliverySelectionId: {}
      }
    }
  }
}

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  timeout: null,
  whitelist: ['cart', 'user', 'system', 'device'],
  migrate: createMigrate(migrations, {debug: false})
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  middleware
)

export const persistor = persistStore(store)

export default function configureStore() {
  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (module.hot && (!(process.env.NODE_ENV === 'production'))) {
    module.hot.accept('../reducers', () => store.replaceReducer(require('../reducers')));
  }
  return store;
}