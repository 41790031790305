import React from "react"
import styles from "./WarningIcon.module.css"
import Icon from "../Icon"

function WarningIcon(){
    return (
            <div className={["responseIcon", styles.container].join(" ")}>
                <Icon icon="priority-high"/>
            </div>
    )
}

export default WarningIcon