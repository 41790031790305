import React from "react"
import DeliverySlotPicker from "../DeliverySlotPicker"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions";

import { DOMINICA_TIMEZONE, HOLIDAYS} from "../../constants/datetime"
import { DELIVERY_SLOTS_IDENTIFIER } from "../../constants/platformSettings"

class AvailableDeliverySlotPicker extends React.Component {

    static defaultProps = {
        selectedSlot: null,
        activeDeliverySelection: null,
        deliveryAreaId: "",
        onSelectDeliverySlot: () => {},
        onDeselectDeliverySlot: () => {},
        timezone: DOMINICA_TIMEZONE,
        TopRight: null
    }

    render(){
        const {
            selectedSlot, onSelectDeliverySlot, onDeselectDeliverySlot, timezone, 
            TopRight, platformSettings, cart, activeDeliverySelection, deliverySlots
        } = this.props
        if (!activeDeliverySelection) return null
        const weeklyAvailableSlotMap = cart.weeklyAvailableDeliverySlotsByDeliverySelectionId ? 
                                        cart.weeklyAvailableDeliverySlotsByDeliverySelectionId[activeDeliverySelection.id] 
                                        : 
                                        {}
        //get the delivery estimation for this delivery-selection (assignment of delivery provider to sellers)
        const deliveryFulfillmentEstimation = cart.deliveryFulfillmentEstimationsByDeliverySelectionId[activeDeliverySelection.id]
        const supportedSlotList = deliverySlots.supportedDeliverySlotIdsInOrder.map(slotId => deliverySlots.supportedDeliverySlotsById[slotId])
        return (
            <DeliverySlotPicker 
                selectedSlot={selectedSlot}
                onSelectDeliverySlot={onSelectDeliverySlot}
                onDeselectDeliverySlot={onDeselectDeliverySlot}
                timezone={timezone}
                TopRight={TopRight}
                isLoaded={platformSettings.loaded[DELIVERY_SLOTS_IDENTIFIER] }
                supportedSlots={supportedSlotList}
                //if there is no estimated delivery fulfillment time, then no slots are available 
                weeklyAvailableSlotMap={deliveryFulfillmentEstimation ? weeklyAvailableSlotMap : {} }
                //any special case days which should be excluded
                excludedDayMap={HOLIDAYS}
                //in some cases the estimation will be falsy, i.e. when there are no delivery trips available
                minDateTime={deliveryFulfillmentEstimation ? deliveryFulfillmentEstimation.fulfilledAt : undefined}
            />
        )
    }   
}

const mapStateToProps = state => ({
    cart: state.cart,
    platformSettings: state.platformSettings,
    deliverySlots: state.deliverySlots
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AvailableDeliverySlotPicker)