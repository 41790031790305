export const THREE_MB_IN_BYTES = 3145728
export const TWO_HUNDRED_KB_IN_BYTES = 200000
export const ONE_HUNDRED_KB_IN_BYTES = 100000
export const IMAGE_TYPE_JPG = 'image/jpeg'
export const IMAGE_TYPE_PNG = 'image/png'
export const IMAGE_FILE_EXT_JPG = 'jpg'
export const IMAGE_FILE_EXT_JPEG = 'jpeg'
export const IMAGE_FILE_EXT_PNG = 'png'
export const IMAGE_FILE_EXT_GIF = 'gif'
export const IMAGE_FILE_EXT_WEBP = 'webp'
export const IMAGE_FILE_EXT_JFIF = 'jfif'
export const FIREBASE_IMAGE_IDENTIFIER = 'firebasestorage.googleapis.com'