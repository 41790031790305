import {firebaseApp} from "../config/firebase"
import { getFirestore, collection, query, where, onSnapshot, doc, setDoc } from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"
import {CREDIT_TRANSACTION_ROLE_RECIPIENT, CREDIT_TRANSACTION_ROLE_SENDER, CREDIT_TYPE_INDIVIDUAL} from "../constants/credit"
import { DAY_IN_MILLISECONDS } from "../constants/datetime";

export const SAVE_CREDIT_TRANSACTIONS = 'SAVE_CREDIT_TRANSACTIONS'

export const saveCreditTransactions = creditTransactions => {
    return {
        type: SAVE_CREDIT_TRANSACTIONS,
        payload: {
            creditTransactions
        }
    }
}

export const fetchSubscribeToEntityCreditTransactions = (
    entityId,
    role=CREDIT_TRANSACTION_ROLE_RECIPIENT
 ) => {
    /**
      * Purpose: retrieve the transactions from the firestore database for a particular user
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp);
    const roleId = role === CREDIT_TRANSACTION_ROLE_SENDER ?
                    "senderId"
                    :
                    "recipientId"
    const transactionsRef = query(collection(firestore, "creditTransactions") , where(roleId, "==", entityId))
    return async dispatch => {
        try {
            const transactionsListener = await onSnapshot(transactionsRef,
                querySnapshot => {
                    //get an array of transactions from the snapshot
                    const transactions = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveCreditTransactions(transactions))
            })
            return transactionsListener
        } catch (e){
            const message = `action > creditTransactions > fetchSubscribeToEntityCreditTransactions: Failed to save transactions`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchSubscribeToWalletCreditTransactions = (
    walletId,
    role=CREDIT_TRANSACTION_ROLE_RECIPIENT
) => {
    /**
      * Purpose: retrieve the transactions from the firestore database for a particular user
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp);
    const roleId = role === CREDIT_TRANSACTION_ROLE_SENDER ?
                    "senderWalletId"
                    :
                    "recipientWalletId"
    const transactionsRef = query(collection(firestore, "creditTransactions"), 
                                    where(roleId, "==", walletId))
    return async dispatch => {
        try {
            const transactionsListener = await onSnapshot(transactionsRef,
                querySnapshot => {
                    //get an array of transactions from the snapshot
                    const transactions = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveCreditTransactions(transactions))
            })
            return transactionsListener
        } catch (e){
            const message = `action > creditTransactions > fetchSubscribeToWalletCreditTransactions: Failed to save transactions`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchSubscribeToWalletCreditTransactionsInDateRange = (
    walletId,
    role=CREDIT_TRANSACTION_ROLE_RECIPIENT,
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all payments placed in the last 30 days
    toDate=Date.now()
) => {
    /**
      * Purpose: retrieve the transactions from the firestore database for a particular wallet for a particular date range
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp);
    const roleId = role === CREDIT_TRANSACTION_ROLE_SENDER ?
                    "senderWalletId"
                    :
                    "recipientWalletId"
    const transactionsRef = query(collection(firestore, "creditTransactions"), 
                                    where(roleId, "==", walletId),
                                    where("createdAt", ">=",  fromDate),
                                    where("createdAt", "<=", toDate)  
                                )
    return async dispatch => {
        try {
            const transactionsListener = await onSnapshot(transactionsRef,
                querySnapshot => {
                    //get an array of transactions from the snapshot
                    const transactions = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveCreditTransactions(transactions))
            })
            return transactionsListener
        } catch (e){
            const message = `action > creditTransactions > fetchSubscribeToWalletCreditTransactions: Failed to save transactions`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchSubscribeToCreditTransaction = (
    id
) => {
    /**
      * Purpose: retrieve a single transactions from the firestore database
      * Note: the onSnapshot below watches for changes on the server
      */
    const firestore = getFirestore(firebaseApp);
    const transactionsRef = doc(firestore, "creditTransactions", id)
    return async dispatch => {
        try {
            const transactionListener = await onSnapshot(transactionsRef,
                docRef => {
                    //get a transactions from the server
                    const transaction = {...docRef.data()}
                    dispatch(saveCreditTransactions([transaction]))
                })
            return transactionListener
        } catch (e){
            const message = `action > creditTransactions > fetchSubscribeToCreditTransaction: Failed to save transaction`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchCreateAdminCreditTransaction = (
    creditTransaction, //makes sense to pass as an object since there is so much variation in what goes into each transaction
    onSuccess=() => {},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp);
    const creditTransactionsRef = doc(firestore, "creditTransactions", creditTransaction.id)
    creditTransaction.createdAt = Date.now()
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            creditTransaction.postedByEntityId = user.id
            creditTransaction.postedByEntityType = CREDIT_TYPE_INDIVIDUAL
            await setDoc(creditTransactionsRef, creditTransaction)
            onSuccess(creditTransaction)
            return true
        } catch (e){
            const originalError = e.message
            const message = `action > creditTransactions > fetchCreateAdminCreditTransaction: Failed to create credit transaction ${JSON.stringify(creditTransaction)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(originalError)
            return false
        }
        
    }
}