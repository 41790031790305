import {firebaseApp} from "../config/firebase"
import { getFirestore, collection, query, getDoc, getDocs, doc, writeBatch } from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"

export const CREATE_SETTLEMENTS = 'CREATE_SETTLEMENTS'
export const SAVE_COUNTRIES = 'SAVE_COUNTRIES'
export const SAVE_COUNTRY_PHONE_FORMATS = 'SAVE_COUNTRY_PHONE_FORMATS'

export const createSettlements = (countryId, settlements) => {
    return {
        type: CREATE_SETTLEMENTS,
        payload: {
            countryId,
            settlements
        }
    }
}

export const saveCountries = countries => {
    return {
        type: SAVE_COUNTRIES,
        payload: {
            countries
        }
    }
}

export const saveCountryPhoneFormats = phoneFormats => {
    return {
        type: SAVE_COUNTRY_PHONE_FORMATS,
        payload: {phoneFormats}
    }
}

export const fetchSaveCountries = () => {
    const firestore = getFirestore(firebaseApp);
    const countriesRef = query(collection(firestore, "countries"))
    return async dispatch => {
        try {
            const querySnapshot = await getDocs(countriesRef)
            //get an array of categories from the snapshot
            const countries = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(saveCountries(countries))
            return true
        } catch (e){
            const message = `action > countries > fetchSaveCountries: Failed to save countries`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchCreateSettlements = (countryId, settlements, onSuccess=()=>{}, onError=()=>{}) => {
    const firestore = getFirestore(firebaseApp);
    const countriesRef = doc(firestore, "countries", countryId)
    return async (dispatch, getState) => {
        try {
            const batch = writeBatch(firestore)
            const countrySettlements = {}
            settlements.forEach(settlement => {
                countrySettlements[settlement.id] = settlement.name
                settlement.createdAt = Date.now()
                settlement.createdByUserId = getState().user.id
                const settlementRef = doc(firestore, "settlements", settlement.id)
                batch.set(settlementRef, settlement)
            })
            batch.update(countriesRef, {
               settlements: countrySettlements
            })
            await batch.commit()
            dispatch(createSettlements(countryId, settlements))
            onSuccess(settlements)
            return true
        } catch (e){
            const message = `action > countries > fetchCreateSettlements: Failed to save settlements`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveCountryPhoneFormats = () => {
    const firestore = getFirestore(firebaseApp);
    const countryPhoneFormatsRef = doc(firestore, "countryPhoneFormats", "formats")
    return async (dispatch, getState) => {
        try {
            const {countries} = getState()
            if (countries.lastPhoneFormatsLoaded && countries.lastPhoneFormatsLoaded > 0){
                //if the phone formats have already been loaded
                return true
            }
            const docRef = await getDoc(countryPhoneFormatsRef)
            const phoneFormats = {...docRef.data()}
            dispatch(saveCountryPhoneFormats(phoneFormats))
            return true
        } catch (e){
            const message = `action > countries > fetchSaveCountryPhoneFormats: Failed to save country phone formats`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchCreateCountryPhoneFormats = (formats, onSuccess=()=>{}, onError=()=>{}) => {
    const firestore = getFirestore(firebaseApp);
    const countryPhoneFormatRef = doc(firestore, "countryPhoneFormats", "formats")
    return async (dispatch, getState) => {
        try {
            const batch = writeBatch(firestore)
            batch.set(countryPhoneFormatRef, {
               ...formats
            })
            await batch.commit()
            return true
        } catch (e){
            const message = `action > countries > fetchSavePhoneFormats: Failed to save formats`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}