/**
 *  Purpose: the modifications that are carried on the Cart object for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {OPENED} from "../constants/order"
import {PICK_UP_INSTORE_ID, SELF_DELIVERY_ID} from "../constants/delivery"
// STATE
// cart:{
//  id: null,
//  currentOrderId: null,
//  itemsByProductStockId:{},
//  itemIdsBySellerId: {},
//  selectedDeliveryProviderIdsBySellerId: {},
//  selectedPickupAddressIdBySellerId: {},
//  selectedPaymentProviderId: null,
//  payWithShopdmCredit: false,
//  itemIds: [],
//  lastAddedItemId: null,
//  lastAddedAt: 0,
//  giftParameters: null,
//  orderNotes: "",
//  cartsToMergeByCartId: {},
//  deliverySlotsById: {},
//  deliverySlotIdsBySellerId: {},
//  deliverySlotIdsByDeliveryProviderId: {},
//  sellerReadinessEstimationsBySellerId: {}
// }


const cartReducer = (state = initialState.cart, action) => {
    const {type, payload} = action;
    let id = state.id
    let itemsByProductStockId = {...state.itemsByProductStockId}
    let itemIdsBySellerId = {...state.itemIdsBySellerId}
    let itemIds = {...state.itemIds}
    let selectedDeliveryProviderIdsBySellerId = {...state.selectedDeliveryProviderIdsBySellerId}
    let selectedPickupAddressIdBySellerId = {...state.selectedPickupAddressIdBySellerId}
    let currentOrderId = state.currentOrderId
    let giftParameters = {...state.giftParameters}
    let cartsToMergeByCartId = {...state.cartsToMergeByCartId}
    let deliverySlotsById = {...state.deliverySlotsById}
    let deliverySlotIdsBySellerId = {...state.deliverySlotIdsBySellerId}
    let deliverySlotIdsByDeliveryProviderId = {...state.deliverySlotIdsByDeliveryProviderId}
    let sellerReadinessEstimationsBySellerId = {...state.sellerReadinessEstimationsBySellerId}
    switch (type){
        case types.ADD_TO_CART: {
            if(typeof payload.item !== "object") {
                logError(`cartReducer > ADD_TO_CART: item payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            itemsByProductStockId = {
                ...itemsByProductStockId, 
                [payload.item.id]: {...payload.item}
            }
            if  (!itemIdsBySellerId[payload.item.sellerId]) itemIdsBySellerId[payload.item.sellerId] = {}
            itemIdsBySellerId[payload.item.sellerId][payload.item.id] = true
            if( payload.deliveryProviderId){
                selectedDeliveryProviderIdsBySellerId[payload.item.sellerId] = payload.deliveryProviderId
            }
            id = id ? id : payload.cartId 
            return {
                ...state,
                itemsByProductStockId,
                itemIdsBySellerId,
                selectedDeliveryProviderIdsBySellerId,
                id,
                itemIds: Object.keys(itemsByProductStockId),
                lastAddedItemId: payload.item.id,
                lastAddedAt: Date.now()
            }
        }

        case types.REMOVE_FROM_CART: {
            if(typeof payload.itemId !== "string") {
                logError(`cartReducer > REMOVE_FROM_CART: itemId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            const item = {...itemsByProductStockId[payload.itemId]}
            delete itemsByProductStockId[payload.itemId]
            delete itemIdsBySellerId[item.sellerId][item.id]
            //if all of the seller's items are removed, so they are no longer in the cart
            if (Object.keys(itemIdsBySellerId[item.sellerId]).length === 0){
                delete itemIdsBySellerId[item.sellerId]
                delete selectedDeliveryProviderIdsBySellerId[item.sellerId]
                delete selectedPickupAddressIdBySellerId[item.sellerId]
                //remove index from this seller to any delivery slots
                delete deliverySlotIdsBySellerId[item.sellerId]
                //remove estimates of seller readiness
                delete sellerReadinessEstimationsBySellerId[item.sellerId]
            }
            itemIds = Object.keys(itemsByProductStockId)
            //if the cart is emptied, then clear it
            if (itemIds.length === 0) return initialState.cart
            return {
                ...state,
                itemsByProductStockId,
                itemIdsBySellerId,
                selectedDeliveryProviderIdsBySellerId,
                selectedPickupAddressIdBySellerId,
                deliverySlotIdsBySellerId,
                sellerReadinessEstimationsBySellerId,
                itemIds,
                currentOrderId,
                lastAddedItemId: null,
                lastAddedAt: 0
            }
        }

        case types.SAVE_CART: {
            if(typeof payload.cart !== "object") {
                logError(`cartReducer > SAVE_CART: cart in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            //if the main cart is deleted, and there are no other carts on device, clear cart
            let rebuiltCart
            if (
                payload.cart.deletedAt && 
                (payload.cart.id === id) && 
                (Object.keys(cartsToMergeByCartId).length === 0)) return initialState.cart
            //if the cart is deleted but it is not the main cart, remove this cart 
            else if (payload.cart.deletedAt && cartsToMergeByCartId[payload.cart.id]){
                delete cartsToMergeByCartId[payload.cart.id]
                return {
                    ...state,
                    cartsToMergeByCartId
                }
            } 
            //if the cart is not being deleted, this is a new cart, but there is already a main cart, store to merge
            else if (id && (id !== payload.cart.id) && !payload.cart.deletedAt){
                //if there is a cart on the device and this is a new, different cart
                cartsToMergeByCartId[payload.cart.id] = payload.cart
            } 
            //otherwise, if this an update of the main cart, a new main cart, 
            //or a deletion of the main cart, while there are other carts to merge, then rebuild
            else if (
                (!id  && !payload.cart.deletedAt) || //there is no main cart
                ((id === payload.cart.id) && !payload.cart.deletedAt) || //update to main cart
                ((payload.cart.id === id) && payload.cart.deletedAt && (Object.keys(cartsToMergeByCartId).length > 0)) //delete main cart, switch to other cart
            ){
                const cart = payload.cart.deletedAt ? //replacement case
                            {
                                ...Object.values(cartsToMergeByCartId)[0], 
                                selectedDeliveryProviderIdsBySellerId,
                                selectedPickupAddressIdBySellerId
                            }
                            :
                            {
                                ...payload.cart, 
                                selectedDeliveryProviderIdsBySellerId,
                                selectedPickupAddressIdBySellerId
                            } //otherwise this is the cart from the server

                //otherwise rebuild the cart using the data from the server
                rebuiltCart = rebuildCart(cart)
                //if deletion case, remove this cart from carts to merge
                if (payload.cart.deletedAt) delete cartsToMergeByCartId[cart.id]
            }

            return {
                ...state,
                ...rebuiltCart,
                cartsToMergeByCartId
            }
        }

        case types.CREATE_CART: {
            if(typeof payload.id !== "string") {
                logError(`cartReducer > CREATE_CART: id in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                id: payload.id
            }
        }

        case types.MERGE_CART: {
            if(typeof payload.cart !== "object") {
                logError(`cartReducer > MERGE_CART: cart in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            itemsByProductStockId = {
                ...itemsByProductStockId,
                ...payload.cart.itemsByProductStockId, 
            }
            Object.values(payload.cart.itemsByProductStockId).forEach(item => {
                if  (!itemIdsBySellerId[item.sellerId]) itemIdsBySellerId[item.sellerId] = {}
                itemIdsBySellerId[item.sellerId][item.id] = true
            })
            //remove from carts to merge
            delete cartsToMergeByCartId[payload.cart.id]
            return {
                ...state,
                itemsByProductStockId,
                itemIdsBySellerId,
                itemIds: Object.keys(itemsByProductStockId),
                cartsToMergeByCartId
            }
        }
        case types.CLEAR_CART: {
            //if no cart id is specified, reset state completely
            if (!payload.cartId) return initialState.cart
            else {
                //delete the cart from cartsToMerge if it is there
                delete cartsToMergeByCartId[payload.cartId]
                const nextCart = Object.values(cartsToMergeByCartId).length > 0?
                                 {...Object.values(cartsToMergeByCartId)[0]}
                                 :
                                 null
                if (id === payload.cartId){
                    //if the main cart is being deleted, select a new cart (if one is available), and either clear the cart or rebuild with new values
                    if (nextCart) delete cartsToMergeByCartId[nextCart.id]
                    return {
                        ...initialState.cart,
                        ...rebuildCart(nextCart),
                        cartsToMergeByCartId
                    }
                } else return {
                    //otherwise just remove the merge cart
                    ...state,
                    cartsToMergeByCartId
                }
            }
        }

        case types.SAVE_ORDERS: {
            if(typeof payload.orders !== "object") {
                return state
            }
            //TODO - CARTBUG perhaps this is cart the issue..
            //maybe cart.currentOrderId is cleared before save orders is triggered?
            //then it is undefined so the cart is not cleared?
            //perhaps we need to check that cart.id === order.cartId instead?
            //but, if there were different carts with different ids 
            //before the order was closed this would not work 
             const clearCart = payload.orders.find(order => {
                return ((order.id === currentOrderId) && (order.currentStatus !== OPENED))
            });
            if (clearCart !== undefined) return initialState.cart
            return state
        }

        case types.CHANGE_ENVIRONMENT: {
            return initialState.cart
        }

        case types.UPDATE_CART_ITEM_QUANTITY: {
            if(typeof payload.itemId !== "string") {
                logError(`cartReducer > UPDATE_CART_ITEM_QUANTITY: itemId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.quantity !== "number") {
                logError(`cartReducer > UPDATE_CART_ITEM_QUANTITY: quantity in payload is not a number ${JSON.stringify(payload)}`)
                return state
            }
            const item = {...itemsByProductStockId[payload.itemId]}
            if(typeof payload.quantity <= item.quantityInStock) {
                logError(`cartReducer > UPDATE_CART_ITEM_QUANTITY: the quantity added cannot be greater than the amount in stock ${JSON.stringify(payload)} ${JSON.stringify(item)}`)
                return state
            }
            return {
                ...state,
                itemsByProductStockId: {
                    ...itemsByProductStockId,
                    [item.id]: {...item, quantity: payload.quantity}
                }
            }

        }

        case types.SELECT_DELIVERY_PROVIDER_FOR_SELLER: {
            if(typeof payload.deliveryProviderId !== "string") {
                logError(`cartReducer > SELECT_DELIVERY_PROVIDER_FOR_SELLER: deliverProviderId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.sellerId !== "string") {
                logError(`cartReducer > SELECT_DELIVERY_PROVIDER_FOR_SELLER: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            //store what the previous delivery provider id was
            const prevDeliveryProviderId = selectedDeliveryProviderIdsBySellerId[payload.sellerId]
            //if we are selecting the same delivery provider as is currently selected, do nothing
            if (prevDeliveryProviderId === payload.deliveryProviderId) return state
            //update the assigned delivery provider for this seller
            selectedDeliveryProviderIdsBySellerId[payload.sellerId] = payload.deliveryProviderId ?
                                                                    payload.deliveryProviderId
                                                                    :
                                                                    null
            //if there was previously a delivery provider, clean up any delivery slots associated with them
            if (prevDeliveryProviderId){
                //clear any seller-to-delivery indexes for this seller (for the previous delivery provider)
                if (deliverySlotIdsBySellerId[payload.sellerId]){
                    delete deliverySlotIdsBySellerId[payload.sellerId]
                }
                //in the special case of pick up instore or self delivery
                if (prevDeliveryProviderId === SELF_DELIVERY_ID || prevDeliveryProviderId === PICK_UP_INSTORE_ID){
                    const prevDeliverySlotId = `${payload.sellerId}${prevDeliveryProviderId}` //the id is deterministic 
                    if (deliverySlotsById[prevDeliverySlotId]){
                        //if there is a delivery slot for pickup/self delivery for this seller
                        delete deliverySlotsById[prevDeliverySlotId]
                        delete deliverySlotIdsByDeliveryProviderId[prevDeliveryProviderId][prevDeliverySlotId]
                        if (Object.keys(deliverySlotIdsByDeliveryProviderId[prevDeliveryProviderId]).length === 0){
                            //if no other stores are using pick up instore/self delivery
                            delete deliverySlotIdsByDeliveryProviderId[prevDeliveryProviderId]
                        }
                    }
                }
                // for all other delivery providers, 
                // if the delivery provider is not delivering for any other sellers, 
                // then clear any slots associated with them
                else if (!Object.values(selectedDeliveryProviderIdsBySellerId).includes(prevDeliveryProviderId)){
                    //if the delivery provider had assigned delivery slots, delete them
                    if (deliverySlotIdsByDeliveryProviderId[prevDeliveryProviderId]){
                        Object.keys(deliverySlotIdsByDeliveryProviderId[prevDeliveryProviderId]).forEach(deliverySlotId => {
                            delete deliverySlotsById[deliverySlotId]
                        })
                        delete deliverySlotIdsByDeliveryProviderId[prevDeliveryProviderId]
                    }
                }
            }
            //add the index from the seller to the delivery slot associated with the delivery provider
            //the slot may not even exist yet, but when a slot is selected it will take this seller's prep times
            //into consideration, so the seller really is part of the slot. This will not work when more than one
            //slot can be scheduled per delivery provider. IT only works because the slot ids are deterministic
            //TODO - make this indexing work when slots have uuids and multiple slots can belong to a delivery provider                                                        
            const deliverySlotId = (
                payload.deliveryProviderId === SELF_DELIVERY_ID || 
                payload.deliveryProviderId === PICK_UP_INSTORE_ID
            ) ? 
            `${payload.sellerId}${payload.deliveryProviderId}`//the id is deterministic 
            :
            payload.deliveryProviderId
            deliverySlotIdsBySellerId[payload.sellerId] = deliverySlotId
            return {
                ...state,
                selectedDeliveryProviderIdsBySellerId,
                deliverySlotIdsBySellerId,
                deliverySlotIdsByDeliveryProviderId,
                deliverySlotsById
            }
        }

        case types.ADD_SELLER_TO_DELIVERY_SLOT: {
            if(typeof payload.deliverySlotId !== "string") {
                logError(`cartReducer > ADD_SELLER_TO_DELIVERY_SLOT: deliverSlotId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.sellerId !== "string") {
                logError(`cartReducer > ADD_SELLER_TO_DELIVERY_SLOT: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            deliverySlotIdsBySellerId[payload.sellerId] = payload.deliverySlotId
            return {
                ...state,
                deliverySlotIdsBySellerId
            }
        }

        case types.SELECT_DELIVERY_SLOT: {
            if(typeof payload.deliverySlot !== "object") {
                logError(`cartReducer > SELECT_DELIVERY_SLOT: deliverySlot in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.sellerIds !== "object") {
                logError(`cartReducer > SELECT_DELIVERY_SLOT: sellerIds in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            deliverySlotsById[payload.deliverySlot.id] = {...payload.deliverySlot}
            //index the slot id by the delivery provider id (one del provider to many slots)
            if (!deliverySlotIdsByDeliveryProviderId[payload.deliverySlot.deliveryProviderId]) deliverySlotIdsByDeliveryProviderId[payload.deliverySlot.deliveryProviderId] = {}
            deliverySlotIdsByDeliveryProviderId[payload.deliverySlot.deliveryProviderId][payload.deliverySlot.id] = true
            //index the slot id by the seller id one-to-one, (there can only be one delivery slot for one seller)
            Object.keys(payload.sellerIds).forEach(sellerId => {
                deliverySlotIdsBySellerId[sellerId] = payload.deliverySlot.id
            })
            return {
                ...state,
                deliverySlotsById,
                deliverySlotIdsByDeliveryProviderId,
                deliverySlotIdsBySellerId
            }
        }

        case types.DESELECT_DELIVERY_SLOT: {
            if(typeof payload.deliverySlotId !== "string") {
                logError(`cartReducer > DESELECT_DELIVERY_SLOT: deliverySlotId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
           
            const deliveryProviderId = deliverySlotsById[payload.deliverySlotId].deliveryProviderId
            //delete the delivery slot itself
            delete deliverySlotsById[payload.deliverySlotId]
            //delete index of delivery slot with delivery provider id
            delete deliverySlotIdsByDeliveryProviderId[deliveryProviderId][payload.deliverySlotId]
            if (Object.keys(deliverySlotIdsByDeliveryProviderId[deliveryProviderId]).length === 0){
                //if there is no other delivery slot for this delivery agent, delete the delivery agent
                delete deliverySlotIdsByDeliveryProviderId[deliveryProviderId]
            }
            //delete index of delivery slot with seller id
            Object.keys(deliverySlotIdsBySellerId).forEach(sellerId => {
                if (deliverySlotIdsBySellerId[sellerId] === payload.deliverySlotId){
                    //delete the index if it is matched to this delivery slot
                    delete deliverySlotIdsBySellerId[sellerId]
                }
            })
            return {
                ...state,
                deliverySlotsById,
                deliverySlotIdsByDeliveryProviderId,
                deliverySlotIdsBySellerId
            }
        }

        case types.UPDATE_SELLER_READINESS_ESTIMATIONS: {
            if(typeof payload.sellerReadinessEstimationsBySellerId !== "object") {
                logError(`cartReducer > UPDATE_SELLER_READINESS_ESTIMATIONS: sellerReadinessEstimationsBySellerId in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                sellerReadinessEstimationsBySellerId: {...payload.sellerReadinessEstimationsBySellerId},
            }
        }

        case types.UPDATE_DELIVERY_FULFILLMENT_ESTIMATIONS: {
            if (typeof payload.deliveryFulfillmentEstimationsByDeliverySelectionId !== "object"){
                logError(`cartReducer > UPDATE_DELIVERY_FULFILLMENT_ESTIMATIONS: deliveryFulfillmentEstimationsByDeliverySelectionId in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                deliveryFulfillmentEstimationsByDeliverySelectionId: {...payload.deliveryFulfillmentEstimationsByDeliverySelectionId}
            }
        }

        case types.UPDATE_WEEKLY_AVAILABLE_DELIVERY_SLOTS: {
            if (typeof payload.weeklyAvailableDeliverySlotsByDeliverySelectionId !== "object"){
                logError(`cartReducer > UPDATE_WEEKLY_AVAILABLE_DELIVERY_SLOTS: weeklyAvailableDeliverySlotsByDeliverySelectionId in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                weeklyAvailableDeliverySlotsByDeliverySelectionId: {...payload.weeklyAvailableDeliverySlotsByDeliverySelectionId}
            }
        }

        case types.SELECT_PAYMENT_PROVIDER:{
            if(typeof payload.paymentProviderId !== "string") {
                logError(`cartReducer > SELECT_PAYMENT_PROVIDER: paymentProviderId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                selectedPaymentProviderId: payload.paymentProviderId,
            }
        }

        case types.DESELECT_PAYMENT_PROVIDER:{
            return {
                ...state,
                selectedPaymentProviderId:  null
            }
        }

        case types.SELECT_PAY_WITH_SHOPDM_CREDIT:{
            const {clearPaymentProvider} = payload
            return {
                ...state,
                payWithShopdmCredit: true,
                selectedPaymentProviderId: clearPaymentProvider ? "" : state.selectedPaymentProviderId 
            }
        }

        case types.DESELECT_PAY_WITH_SHOPDM_CREDIT:{
            return {
                ...state,
                payWithShopdmCredit: false,
            }
        }

        case types.CREATE_ORDER: {
            if(typeof payload.order !== "object") {
                return state
            }
            return {
                ...state,
                currentOrderId: payload.order.id
            }
        }

        case types.SELECT_PICKUP_ADDRESS_FOR_SELLER: {
            if(typeof payload.addressId !== "string") {
                logError(`cartReducer > SELECT_PICKUP_ADDRESS_FOR_SELLER: addressId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.sellerId !== "string") {
                logError(`cartReducer > SELECT_PICKUP_ADDRESS_FOR_SELLER: sellerId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(payload.addressId) selectedPickupAddressIdBySellerId[payload.sellerId] = payload.addressId
            else delete selectedPickupAddressIdBySellerId[payload.sellerId]
            return {
                ...state,
                selectedPickupAddressIdBySellerId
            } 
        }

        case types.UPDATE_GIFT_PARAMETERS: {
            if(typeof payload.giftParameters !== "object") {
                return state
            }
            return {
                ...state,
                giftParameters: payload.giftParameters
            }
        }

        case types.UPDATE_ORDER_NOTES: {
            if(typeof payload.orderNotes !== "string") {
                return state
            }
            return {
                ...state,
                orderNotes: payload.orderNotes
            }
        }

        case types.LOGOUT: {
            return initialState.cart
        }
        default: return state
    }
}

const rebuildCart = cart => {
    let itemsByProductStockId = {}
    const itemIdsBySellerId = {}
    let selectedDeliveryProviderIdsBySellerId = {}
    let selectedPickupAddressIdBySellerId = {}
    let id = null
    if (cart){
        itemsByProductStockId = {
            ...cart.itemsByProductStockId, 
        }
        Object.values(cart.itemsByProductStockId).forEach(item => {
            if  (!itemIdsBySellerId[item.sellerId]) itemIdsBySellerId[item.sellerId] = {}
            itemIdsBySellerId[item.sellerId][item.id] = true
        })
        id = cart.id
        selectedDeliveryProviderIdsBySellerId = {...cart.selectedDeliveryProviderIdsBySellerId}
        //remove delivery providers related to sellers removed by saving the new cart
        //NB: those sellers with no assigned deliveryProvider do not cause an issue 
        Object.keys(selectedDeliveryProviderIdsBySellerId).forEach(sellerId => {
            //remove this key if the related seller does not appear in the rebuilt index of items by sellers  
            if (!itemIdsBySellerId[sellerId]) delete selectedDeliveryProviderIdsBySellerId[sellerId]
        })

        selectedPickupAddressIdBySellerId = {...cart.selectedPickupAddressIdBySellerId}
        //remove pick up addresse providers related to sellers removed by saving the new cart 
        Object.keys(selectedPickupAddressIdBySellerId).forEach(sellerId => {
            //remove this key if the related seller does not appear in the rebuilt index of items by sellers  
            if (!itemIdsBySellerId[sellerId]) delete selectedPickupAddressIdBySellerId[sellerId]
        })
    }
    return ({
        id,
        itemsByProductStockId,
        itemIdsBySellerId,
        selectedDeliveryProviderIdsBySellerId,
        selectedPickupAddressIdBySellerId,
        itemIds: Object.keys(itemsByProductStockId)
    })
}

export default cartReducer