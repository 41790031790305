import React, {useState, useEffect, useRef} from 'react'
import ProductCharOptionPicker from "../ProductCharOptionPicker"

import { alphabeticalSort } from "../../utils/stringUtils"
import {getVariantIdFromCharacteristics} from "../../utils/productUtils"

const ProductVariantPicker = ({variants={}, characteristics=[], selected="", onChange=()=>{}, filterCharOptionsWithNoMatch=false}) => {
    /**
     * Purpose: 
     * - allow the user to select a variant or 
     * - visualize the selected variant by
     * - matching the variant to the selected char options
     * - visualize the variant by highlighting its options in the stack of "char option pickers"
     */

    //if the flag is set to filter characteristics to only options that have variants
    //then do so, otherwise, set it to the default characteristics
    const filteredCharacteristics = filterCharOptionsWithNoMatch ? [] : characteristics
    if (filterCharOptionsWithNoMatch){
        //here we will filter the options in the product characteristics to only those that have variants that are not discontinued

        //1. build a map that indexes variant ids by their char options, for efficient lookup
        const variantIdsByCharOptionMap = Object.values(variants).reduce((map, variant) => {
            //a map called characteristics is stored in the variant
            const {characteristics} = variant
            if (characteristics){
                Object.keys(characteristics).forEach(char => {
                    if (!map[char]) map[char] = {}
                    //if the variant is not discontinued then add its option to the map
                    if (!variant.isDiscontinued){
                        const option = characteristics[char]
                        if (!map[char][option]) map[char][option] = {}
                        map[char][option][variant.id] = true
                    }
                })
            }
            return map
        }, {})
        //2. loop over the default characteristics list (stored on the product), and for each option in the options list
        //   remove it if it has no matches in the map build in step one
        characteristics.forEach(char => {
            const filteredChar = {...char, options: []}
            const variantIdsByOptionMap = variantIdsByCharOptionMap[char.name]
            if (char.options){
                char.options.forEach(option => {
                    if (variantIdsByOptionMap[option.id]){
                        filteredChar.options.push(option)
                    }
                })
            }
            filteredCharacteristics.push(filteredChar)
        })
    }
    //get the characteristics of the selected variant
    const getCharsFromSelectedVariant = () => {
        if (!selected) return {}
        else {
            const selectedVariant = variants[selected]
            if (!selectedVariant) return {}
            return selectedVariant.characteristics ?
                    {...selectedVariant.characteristics}
                    :
                    {}
        }
    }
    //on mount, initialize the selected characteristics 
    //based on the selected variant id passed in
    const [selectedChars, setSelectedChars] = useState(
        getCharsFromSelectedVariant()
    )
    //whenever a characteristics option is selected,
    //combine the value with the previously selected characteristics
    //and use this as the new "selectedChars"
    const onSelectCharOption = (value, attr) => {
        const newChars = {
            ...selectedChars,
            [attr]: value
        }
        setSelectedChars(newChars)
    }
    //track mounting
    const isMounted = useRef(false)
    //track changes to the selected variant id
    //this is only changed internally when user input changes the selectedChars
    //that way, if the parent changes the variant id, we can know it was an external change
    const lastSelected = useRef(selected)
    //whenever the selected chars changes
    //use the new selectedChars to find the 
    //new variant match (it may match none and that is fine)
    //if the result is different from the currently selected variant id
    //then update our parent
    useEffect(() => {
        if (isMounted.current){
            const variantId = getVariantIdFromCharacteristics(selectedChars, variants)
            //if a variant matched the new characteristics and it is different from the selected variant
            if (
                variantId !== selected
            ){
                //key thing to note is that lastSelected is updated BEFORE updating the parent, 
                // therefore, on re-render, when the parent sends in the new variantId, selected !== lastSelected.current the check in the next useEffect will be false 
                lastSelected.current = variantId 
                onChange(variantId)
            }
        } else isMounted.current = true
    }, [selectedChars])
    //whevever selected changes,
    //check to see if it was internally changed or externally changed
    //selected !== lastSelected.current will only be true if it was externally changed
    //in this case, update the selectedChars to match the new selected variant Id
    useEffect(() => {
        if (selected !== lastSelected.current){
            lastSelected.current = selected
            setSelectedChars(getCharsFromSelectedVariant())
        }
    }, [selected])
    //if there are no characteristics, return nothing
    if (!filteredCharacteristics || filteredCharacteristics.length === 0) return ""
    const charList = filteredCharacteristics.sort((cA, cB) => alphabeticalSort(cA.name, cB.name))
    return (
        <div>
            {
                charList.map(c => {
                    //loop over the characteristics stored on the product object
                    //then display the list of options on each char, using the ProductCharOptionPicker 
                    const selectedOptionId = selectedChars && selectedChars[c.name] ? selectedChars[c.name] : ""
                    return <ProductCharOptionPicker 
                                key={c.id}
                                options={c.options} 
                                attribute={c.name}
                                selected={selectedOptionId}
                                onSelect={onSelectCharOption}
                            />
                })
            }
        </div>
    )
}


export default ProductVariantPicker