import React from "react"
import styles from "./HeroBanner.module.css"
import Typewriter from 'typewriter-effect';
import {withRouter} from "react-router-dom"
import { selectedEnvironment } from "../../config";
import { PRODUCTION } from "../../constants/environment";
const HeroBanner = ({history}) => {
    const groceryCategoryId = selectedEnvironment === PRODUCTION ? 
                                "5e364432-7654-43aa-88fe-63c9551f0e1e"
                                : "6b66d424-6639-441b-9f68-9a346a09cb84"
    const goToGroceries = () => history.push(`categories/${groceryCategoryId}`)
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.title}>
                    Buy anything you want in Dominica
                    <Typewriter
                        onInit={(typewriter) => {
                            typewriter
                                .pasteString('without ')
                                .typeString('driving to Roseau')
                                .pauseFor(500)
                                .deleteChars(17)
                                .typeString('carrying load on the bus')
                                .pauseFor()
                                .deleteChars(24)
                                .typeString('searching three stores')
                                .pauseFor(500)
                                .deleteAll()
                                .typeString('Reliably. Cheaply. Stress-free.')
                                .start();
                        }}
                    />
                    <button 
                        className={`button white ${styles.cta}`}
                        onClick={goToGroceries}
                    >
                        Get Groceries Delivered Now
                    </button>
                </div>
                
            </div>
        </div>
    )
}

export default withRouter(HeroBanner)