/**
 *  Purpose: the modifications that are carried on the Payment objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 import {SETTINGS_IDENTIFIER} from "../constants/analysis"
 
 // STATE
 // payments:{
 //   paymentsById:{},
 //   paymentIds: []
 // }
 
 
 const paymentsReducer = (state = initialState.payments, action) => {
     const {type, payload} = action;
     let paymentsById = {...state.paymentsById}
     let settings = {...state.settings}
     switch (type){
         case types.SAVE_PAYMENTS: {
             if(typeof payload.payments !== "object") {
                 logError(`paymentsReducer > SAVE_PAYMENTS: payments payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.payments.forEach(payment => {
                 if (payment.id !== SETTINGS_IDENTIFIER){
                     paymentsById[payment.id] = payment
                 } else settings = payment
             });
             return {
                 ...state,
                 paymentsById,
                 settings,
                 paymentIds: Object.keys(paymentsById)
             }
         }

         case types.EDIT_PAYMENT: {
            if(typeof payload.paymentId !== "string") {
                logError(`paymentsReducer > EDIT_PAYMENT: paymentId is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.edits !== "object") {
                logError(`paymentsReducer > EDIT_PAYMENT: payment edits payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            paymentsById[payload.paymentId] = {...paymentsById[payload.paymentId], ...payload.edits}
            return {
                ...state,
                paymentsById
            }
        }
 
         default: return state
     }
 }
 
 export default paymentsReducer