import React from 'react'
import PropTypes from 'prop-types'
import {logError} from "../../utils/errorHandlingUtils"

import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet'
import AccountCirle from '@material-ui/icons/AccountCircle'
import AcUnit from '@material-ui/icons/AcUnit'
import Add from '@material-ui/icons/Add'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowForward from '@material-ui/icons/ArrowForward'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn'
import AttachMoney from '@material-ui/icons/AttachMoney'
import BarChart from '@material-ui/icons/BarChart'
import Brightness5 from '@material-ui/icons/Brightness5'
import Chat from '@material-ui/icons/Chat'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import CloudDownload from '@material-ui/icons/CloudDownload'
import CloudUpload from '@material-ui/icons/CloudUpload'
import Computer from '@material-ui/icons/Computer'
import ContactSupport from '@material-ui/icons/ContactSupport'
import Delete from '@material-ui/icons/Delete'
import Done from '@material-ui/icons/Done'
import DonutSmall from '@material-ui/icons/DonutSmall'
import Edit from '@material-ui/icons/Edit'
import Email from '@material-ui/icons/Email'
import Event from '@material-ui/icons/Event'
import Face from '@material-ui/icons/Face'
import Facebook from '@material-ui/icons/Facebook'
import Favorite from '@material-ui/icons/Favorite'
import Fullscreen from '@material-ui/icons/Fullscreen'
import Flare from '@material-ui/icons/Flare'
import FlashOn from '@material-ui/icons/FlashOn'
import GpsFixed from '@material-ui/icons/GpsFixed'
import GridOn from '@material-ui/icons/GridOn'
import Group from '@material-ui/icons/Group'
import History from '@material-ui/icons/History'
import Home from '@material-ui/icons/Home'
import Info from '@material-ui/icons/Info' 
import Instagram from '@material-ui/icons/Instagram'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Kitchen from '@material-ui/icons/Kitchen'
import Link from '@material-ui/icons/Link'
import LocalAtm from '@material-ui/icons/LocalAtm'
import LocalShipping from '@material-ui/icons/LocalShipping'
import Lock from '@material-ui/icons/Lock'
import Menu from '@material-ui/icons/Menu'
import MonetizationOn from '@material-ui/icons/MonetizationOn'
import MoreVert from '@material-ui/icons/MoreVert'
import Notifications from '@material-ui/icons/Notifications'
import Payment from '@material-ui/icons/Payment'
import Person from '@material-ui/icons/Person'
import PersonAdd from '@material-ui/icons/PersonAdd'
import Phone from '@material-ui/icons/Phone'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import Place from '@material-ui/icons/Place'
import PlayArrow from '@material-ui/icons/PlayArrow'
import Print from '@material-ui/icons/Print'
import PriorityHigh from '@material-ui/icons/PriorityHigh'
import Public from '@material-ui/icons/Public'
import Receipt from '@material-ui/icons/Receipt'
import Remove from '@material-ui/icons/Remove'
import Reorder from '@material-ui/icons/Reorder'
import Schedule from '@material-ui/icons/Schedule'
import Search from '@material-ui/icons/Search'
import Settings from '@material-ui/icons/Settings'
import Share from '@material-ui/icons/Share'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import Star from '@material-ui/icons/Star'
import Store from '@material-ui/icons/Store'
import ThumbUp from '@material-ui/icons/ThumbUp'
import ThumbUpOutlined from '@material-ui/icons/ThumbUpOutlined'
import ThumbDown from '@material-ui/icons/ThumbDown'
import ThumbDownOutlined from '@material-ui/icons/ThumbDownOutlined'
import TimeToLeave from '@material-ui/icons/TimeToLeave'
import VideoCall from '@material-ui/icons/VideoCall'
import Videocam from '@material-ui/icons/Videocam'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import WhatsApp from '@material-ui/icons/WhatsApp'
import Whatshot from '@material-ui/icons/Whatshot'
import YouTube from '@material-ui/icons/YouTube'

/**
 * Documentation here: https://www.npmjs.com/package/@material-ui/icons
 * 
 * Note: This component uses a switch statement to determine which icon to display
 * 
 * Important:
 *     :  If the icon you want is not imported above^, import it using the 
 *     :  currently used import style `import IconName from '@material-ui/icons/DonutSmall'`
 *     :  in the style `import {IconName} from '@material-ui/icons'` will load all available icons
 *     :  into the software and bloat the program 
 * 
 */

function Icon ({icon="donut-small", customStyles={}}){
    if (typeof icon !== 'string'){
        logError(`Icon: icon supplied is not a string ${icon}`)
        return null
    }
    icon = icon.toLowerCase()
    let SelectedIcon = DonutSmall
    switch (icon) {
        case 'account-balance-wallet': SelectedIcon = AccountBalanceWallet; break;
        case 'account-circle': SelectedIcon = AccountCirle; break;
        case 'ac-unit': SelectedIcon = AcUnit; break;
        case 'add': SelectedIcon = Add; break;
        case 'arrow-back': SelectedIcon = ArrowBack; break;
        case 'arrow-downward' : SelectedIcon = ArrowDownward; break;
        case 'arrow-drop-down' : SelectedIcon = ArrowDropDown; break;
        case 'arrow-drop-up' : SelectedIcon = ArrowDropUp; break;
        case 'arrow-forward': SelectedIcon = ArrowForward; break;
        case 'arrow-upward' : SelectedIcon = ArrowUpward; break;
        case 'assignment-turned-in' : SelectedIcon = AssignmentTurnedIn; break;
        case 'attach-money' : SelectedIcon = AttachMoney; break;
        case 'bar-chart' : SelectedIcon = BarChart; break;
        case 'brightness-5' : SelectedIcon = Brightness5; break;
        case 'chat' : SelectedIcon = Chat; break;
        case 'check' : SelectedIcon = Check; break;
        case 'close' : SelectedIcon = Close; break;
        case 'cloud-download' : SelectedIcon = CloudDownload; break;
        case 'cloud-upload' : SelectedIcon = CloudUpload; break;
        case 'computer' : SelectedIcon = Computer; break;
        case 'contact-support' : SelectedIcon = ContactSupport; break;
        case 'delete' : SelectedIcon = Delete; break;
        case 'done' : SelectedIcon = Done; break;
        case 'donut-small' : SelectedIcon = DonutSmall; break;
        case 'edit' : SelectedIcon = Edit; break;
        case 'email' : SelectedIcon = Email; break;
        case 'event' : SelectedIcon = Event; break;
        case 'face' : SelectedIcon = Face; break;
        case 'facebook' : SelectedIcon = Facebook; break;
        case 'favorite' : SelectedIcon = Favorite; break;
        case 'flare' : SelectedIcon = Flare; break;
        case 'flash-on' : SelectedIcon = FlashOn; break;
        case 'fullscreen' : SelectedIcon = Fullscreen; break;
        case 'gps-fixed' : SelectedIcon = GpsFixed; break;
        case 'grid-on' : SelectedIcon = GridOn; break;
        case 'group' : SelectedIcon = Group; break;
        case 'history' : SelectedIcon = History; break;
        case 'home' : SelectedIcon = Home; break;
        case 'info' : SelectedIcon = Info; break;
        case 'instagram' : SelectedIcon = Instagram; break;
        case 'keyboard-arrow-down' : SelectedIcon = KeyboardArrowDown; break;
        case 'keyboard-arrow-left' : SelectedIcon = KeyboardArrowLeft; break;
        case 'keyboard-arrow-right' : SelectedIcon = KeyboardArrowRight; break;
        case 'kitchen' : SelectedIcon = Kitchen; break;
        case 'link' : SelectedIcon = Link; break;
        case 'local-atm' : SelectedIcon = LocalAtm; break;
        case 'local-shipping' : SelectedIcon = LocalShipping; break;
        case 'lock' : SelectedIcon = Lock; break;
        case 'menu' : SelectedIcon = Menu; break;
        case 'monetization-on' : SelectedIcon = MonetizationOn; break;
        case 'more-vert' : SelectedIcon = MoreVert; break;
        case 'notifications' : SelectedIcon = Notifications; break;
        case 'payment' : SelectedIcon = Payment; break;
        case 'person' : SelectedIcon = Person; break;
        case 'person-add' : SelectedIcon = PersonAdd; break;
        case 'phone' : SelectedIcon = Phone; break;
        case 'photo-camera' : SelectedIcon = PhotoCamera; break;
        case 'place' : SelectedIcon = Place; break;
        case 'play-arrow' : SelectedIcon = PlayArrow; break;
        case 'print' : SelectedIcon = Print; break;
        case 'priority-high' : SelectedIcon = PriorityHigh; break;
        case 'public' : SelectedIcon = Public; break;
        case 'receipt' : SelectedIcon = Receipt; break;
        case 'remove' : SelectedIcon = Remove; break;
        case 'reorder' : SelectedIcon = Reorder; break;
        case 'schedule' : SelectedIcon = Schedule; break;
        case 'search' : SelectedIcon = Search; break;
        case 'settings' : SelectedIcon = Settings; break;
        case 'share' : SelectedIcon = Share; break;
        case 'shopping-cart' : SelectedIcon = ShoppingCart; break;
        case 'star' : SelectedIcon = Star; break;
        case 'store' : SelectedIcon = Store; break;
        case 'thumb-up' : SelectedIcon = ThumbUp; break;
        case 'thumb-up-outlined': SelectedIcon = ThumbUpOutlined; break;
        case 'thumb-down' : SelectedIcon = ThumbDown; break;
        case 'thumb-down-outlined': SelectedIcon = ThumbDownOutlined; break;
        case 'time-to-leave' : SelectedIcon = TimeToLeave; break;
        case 'video-call' : SelectedIcon = VideoCall; break;
        case 'videocam' : SelectedIcon = Videocam; break;
        case 'visibility' : SelectedIcon = Visibility; break;
        case 'visibility-off' : SelectedIcon = VisibilityOff; break;
        case 'whatsapp' : SelectedIcon = WhatsApp; break;
        case 'whatshot' : SelectedIcon = Whatshot; break;
        case 'youtube' : SelectedIcon = YouTube; break;
        default: SelectedIcon = DonutSmall
    }
    return (
        <SelectedIcon style={customStyles} className={'icon'}/>
    )
}

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    customStyles: PropTypes.object
}
export default Icon;