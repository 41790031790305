import React from "react"

export const getStatusMessageFromIsoResponseCode = cardAutRequest => {
    if (!cardAutRequest || typeof cardAutRequest !== "object" ) return ""
    const isoResponseCode = cardAutRequest.lastResponse &&
                            cardAutRequest.responseHistory &&
                            cardAutRequest.responseHistory[cardAutRequest.lastResponse] 
                            ? 
                            cardAutRequest.responseHistory[cardAutRequest.lastResponse].IsoResponseCode 
                            : 
                            ""
    if (!isoResponseCode) return ""
    const statusMessages = {
        "05": "Your bank has declined the transaction. Please use another card or contact your bank before retrying",
        "07": "Your bank has declined the transaction. Please contact your bank before retrying",
        "12": "We don't know why your bank declined the transaction. Try again. If it happens again, please contact your bank",
        "14": "You might have made a mistake while inputting your card details. Try again. If it happens again, please contact your bank",
        "15": "You might have made a mistake while inputting your card details. Try again. If it happens again, please contact your bank",
        "31": "We don't know why your bank declined the transaction. Try again. If it happens again, please contact your bank",
        "33": "Your card might be expired. Please use another card or contact your bank",
        "36": "Your bank has declined the transaction. Please contact your bank before retrying",
        "41": "Your bank has declined the transaction. Please contact your bank before retrying",
        "43": "Your bank has declined the transaction. Please contact your bank before retrying",
        "51": "It doesn't look like you have sufficient funds on that account. Please double check your account and try again",
        "52": "You might have made a mistake while inputting your card details. Try again. If it happens again, please contact your bank",
        "54": "Your card might be expired. Please use another card or contact your bank",
        "57": "We don't know why your bank declined the transaction. Try again. If it happens again, please contact your bank",
        "61": "Your bank has declined the transaction. Sometimes this is due to account limits. Please use another card or contact your bank before retrying",
        "62": "Your bank has declined the transaction. Please use another card or contact your bank before retrying",
        "65": "Your bank has declined the transaction. Sometimes this is due to account limits. Please use another card or contact your bank before retrying",
        "67": "Your bank has declined the transaction. Please use another card or contact your bank before retrying",
        "91": "For some reason, your bank was temporarily unavailable. Wait for 10 minutes and try again or contact your bank",
        "93": "Your bank has declined the transaction. Please use another card or contact your bank before retrying",
        "97": "You might have made a mistake while inputting your card details. Try again. If it happens again, please contact your bank",
        "3D0": "We don't know why your bank declined the transaction. Try again. If it happens again, please contact your bank",
        "N7": "We don't know why your bank declined the transaction. Try again. If it happens again, please contact your bank",
        "FC3": "Your bank has declined the transaction. Please contact your bank before retryingy",
    }
    const statusMessage = statusMessages[isoResponseCode]
    if (!statusMessage) return ""
    else return <div>Your account was not debited.<div className="margin-top-1em">{statusMessage}</div></div>
}