import React from "react"
import styles from "./AvatarIconStack.module.css"
import AvatarIcon from "../../components/AvatarIcon"
import { AVATAR_SIZE_MEDIUM, AVATAR_SIZE_SMALL} from "../../constants/interface"


const AvatarIconStack = ({
    avatarList=[],
    avatarSize=AVATAR_SIZE_SMALL,
    borderColor="var(--light-grey)",
    coverPercentage=0.6, //the percentage of each avatar icon that will be covered
    stackLimit=null //after this number it will say +remainingNumber instead of displaying each logo
}) => {
    /**
     * avatarList items have the following schema
     *  {
     *      name: string,
     *      imageUrl: string (optional)    
     *  }
     */
    const avatarWidth = avatarSize === AVATAR_SIZE_SMALL ? 
                        36
                        :
                        avatarSize === AVATAR_SIZE_MEDIUM ? 
                        80
                        :
                        150
    const getIconStack = () => avatarList.reduce((stack, avatar, i) => {
        const {name, imageUrl} = avatar
        if (!stackLimit || i < stackLimit){
            //if there is no limit or we are under the limit, add avatar icons to the display stack
            stack.push(
                <div 
                    style={
                        {
                            "zIndex": avatarList.length - i, //put the first avatar at the top of the pile, and the last at the bottom
                            "marginLeft": i !== 0 ? avatarWidth * coverPercentage * -1 : 0 //use margin left to stack the avatars under each other, unless it is the first avatar
                        }
                    }
                    key={`${name}${imageUrl}`}
                >
                    <AvatarIcon 
                        name={name} 
                        imageUrl={imageUrl} 
                        size={avatarSize}
                        borderColor={borderColor}
                    />
                </div>
            )
        } else if (stackLimit && stackLimit === i){
            //otherwise, if we have hit the limit of avatars to display, we display a number that shows the amount not displayed
            const remainingNumber = avatarList.length - stackLimit
            stack.push(<div key={`remaining`} className={styles.remainingNumber}>+{remainingNumber}</div>) 
        }
        return stack
    }, [])
    return (
        <div className={styles.container}>
            {getIconStack()}
        </div>
    )
}

export default AvatarIconStack