import React from "react"
import styles from "./LimitedAccess.module.css"

import LoginForm from "../../containers/LoginForm"

import logo from "../../images/logo300x300.png"
import supportConfig from "../../config/support"
import {Helmet} from "react-helmet";

const LimitedAccess = () => {
    return (
        <div className={[styles.container, "screen"].join(" ")}>
            <Helmet>
                <title>Limited Access</title>
            </Helmet>
            <div className={styles.teaser}>
                <img src={logo} alt="Shopdm Logo"/>
                <p className="productTitle">We're cooking up something good for you, but its not ready yet</p>
            </div>
            <LoginForm hideCreateAccount={true}/>
            <p className="title">Login if you've been given early access. 
                Otherwise <a href="https://docs.google.com/forms/d/e/1FAIpQLSfy4TUEDv6lFWrnGqq3YHxP_cFtPdb_4wD52fAjNfcfr14liA/viewform?usp=sf_link">join the waiting list here!</a></p>
            <p>Really want to get in touch? Email us <a href={`mailto:${supportConfig.maintenanceEmail}`}>here</a></p>
        </div>
    )
}

export default LimitedAccess;