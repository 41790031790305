/**
 *  Purpose: the modifications that are carried on the partners objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"

 // STATE
 // partners:{
 //   partnersById:{},
 //   partnerIdsByHandleId: {},
 //   partnerIds: [],
 //   lastLoadedAt: 0
 // }
 
 
 const partnersReducer = (state = initialState.partners, action) => {
     const {type, payload} = action;
     let partnersById = {...state.partnersById}
     let partnerIdsByHandleId = {...state.partnerIdsByHandleId}
 
     switch (type){
         case types.SAVE_PARTNERS: {
             if(typeof payload.partners !== "object") {
                 logError(`partnersReducer > SAVE_PARTNERS: partners payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.partners.forEach(partner => {
                partnersById[partner.id] = partner
                partnerIdsByHandleId[partner.handleId] = partner.id
             });
             return {
                 ...state,
                 partnersById,
                 partnerIdsByHandleId,
                 partnerIds: Object.keys(partnersById),
                 lastLoadedAt: Date.now()
             }
         }
 
         default: return state
     }
 }
 
 export default partnersReducer