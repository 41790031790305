/**
 *  Purpose: the modifications that are carried on the Promotion objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 import {
    PROMOTION_APPLIES_TO_PRODUCTS_TYPE_SPECIFIC, 
    PROMOTION_APPLIES_TO_PRODUCTS_TYPE_ALL,
    PROMOTER_TYPE_SELLER
} from "../constants/promotions"
 // STATE
 // promotions:{
 //   promotionsById:{},
 //   promotionIds: [],
//    promotionIdsByProductId: {},
//    appliesToAllPromotionIdsBySellerId: {}
 // }
 
 
 const promotionsReducer = (state = initialState.promotions, action) => {
     const {type, payload} = action;
     let promotionsById = {...state.promotionsById}
     let promotionIdsByProductId = {...state.promotionIdsByProductId}
     let appliesToAllPromotionIdsBySellerId = {...state.appliesToAllPromotionIdsBySellerId}
 
     switch (type){
         case types.SAVE_PROMOTIONS: {
             if(typeof payload.promotions !== "object") {
                 logError(`promotionsReducer > SAVE_PROMOTIONS: promotions payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.promotions.forEach(promotion => {
                    promotionsById[promotion.id] = promotion
                    const indexed = indexPromotion(
                        promotion, 
                        promotionIdsByProductId, 
                        appliesToAllPromotionIdsBySellerId
                    )
                    promotionIdsByProductId = indexed.promotionIdsByProductId
                    appliesToAllPromotionIdsBySellerId = indexed.appliesToAllPromotionIdsBySellerId
             });
             return {
                 ...state,
                 promotionsById,
                 promotionIds: Object.keys(promotionsById),
                 promotionIdsByProductId,
                 appliesToAllPromotionIdsBySellerId
             }
         }

         case types.CREATE_PROMOTION : {
            if(typeof payload.promotion !== "object") {
                logError(`promotionsReducer > CREATE_PROMOTION: promotion payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            const {promotion} = payload
            promotionsById[promotion.id] = promotion
            const indexed = indexPromotion(
                promotion, 
                promotionIdsByProductId, 
                appliesToAllPromotionIdsBySellerId
            )
            promotionIdsByProductId = indexed.promotionIdsByProductId
            appliesToAllPromotionIdsBySellerId = indexed.appliesToAllPromotionIdsBySellerId
            return {
                ...state,
                promotionsById,
                promotionIds: Object.keys(promotionsById),
                promotionIdsByProductId,
                appliesToAllPromotionIdsBySellerId
            }
         }

         case types.DELETE_PROMOTION: {
            if(typeof payload.promotionId !== "string") {
                logError(`promotionsReducer > DELETE_PROMOTIONS: promotionId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }

            const promotion = promotionsById[payload.promotionId]
            const {terms} = promotion
            if (promotion.appliesToProductsType === PROMOTION_APPLIES_TO_PRODUCTS_TYPE_SPECIFIC){
                //cycle through each of the products the promotion applies to and
                //unindex them
                Object.keys(terms.appliesToProductIds).forEach( productId => {
                    if (promotionIdsByProductId[productId]){
                        delete promotionIdsByProductId[productId][promotion.id]
                        //if the product is not part of any other promotions, unindex it
                        if (Object.keys(promotionIdsByProductId[productId]).length === 0){
                            delete promotionIdsByProductId[productId]
                        }
                    }
                
                })
            } else if (promotion.appliesToProductsType === PROMOTION_APPLIES_TO_PRODUCTS_TYPE_ALL){
                //if the promotion applied to all products of the promoter
                //and that promoter is a seller, unindex the seller
                if (promotion.promoterType === PROMOTER_TYPE_SELLER){
                    if (appliesToAllPromotionIdsBySellerId[promotion.promoterId]){
                        delete appliesToAllPromotionIdsBySellerId[promotion.promoterId][promotion.id]
                        //if the seller is not part of any other applies-to-all promotions, unindex it
                        if (Object.keys(appliesToAllPromotionIdsBySellerId[promotion.promoterId]).length === 0){
                            delete appliesToAllPromotionIdsBySellerId[promotion.promoterId]
                        }
                    }
                }
            }
            
            delete promotionsById[payload.promotionId]

            return {
                ...state,
                promotionsById,
                promotionIds: Object.keys(promotionsById),
                promotionIdsByProductId,
                appliesToAllPromotionIdsBySellerId
            }
        }

        case types.EDIT_PROMOTION: {
            if(typeof payload.id !== "string") {
                logError(`promotionsReducer > EDIT_PROMOTION: promotion id in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }

            if(typeof payload.edits !== "object") {
                logError(`promotionsReducer > EDIT_PROMOTION: edits in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                promotionsById:{
                    ...promotionsById,
                    [payload.id] : {
                        ...promotionsById[payload.id],
                        ...payload.edits
                    }
                },
            }
        }
 
         default: return state
     }

     
 }
 
 const indexPromotion = (
    promotion,
    promotionIdsByProductId,
    appliesToAllPromotionIdsBySellerId
    ) => {
       const {terms} = promotion
       //index promotions by product id, if they apply to individual products
       if (promotion.appliesToProductsType === PROMOTION_APPLIES_TO_PRODUCTS_TYPE_SPECIFIC){
           const {appliesToProductIds} = terms
           Object.keys(appliesToProductIds).forEach(
               productId => {
                   if (!promotionIdsByProductId[productId]) promotionIdsByProductId[productId] = {}
                   promotionIdsByProductId[productId][promotion.id] = true
               }
           )                    
       } 
       //otherwise, if the promo applies to all products, index the promo by promoter id
       else if (promotion.appliesToProductsType === PROMOTION_APPLIES_TO_PRODUCTS_TYPE_ALL){
           //right now only sellers can make promotions
           if (promotion.promoterType === PROMOTER_TYPE_SELLER){
               if (!appliesToAllPromotionIdsBySellerId[promotion.promoterId]) appliesToAllPromotionIdsBySellerId[promotion.promoterId] = {}
               appliesToAllPromotionIdsBySellerId[promotion.promoterId][promotion.id] = true
           }
       }
       return ({
           promotionIdsByProductId,
           appliesToAllPromotionIdsBySellerId
       })
}

 export default promotionsReducer