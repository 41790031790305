import React from "react"
import ContainedModal from "../../components/ContainedModal"
import PaymentMethodOption from "../../components/PaymentMethodOption"
import AnnouncementBanner from "../../banners/AnnouncementBanner"
import FormField from "../../components/FormField"
import Icon from "../../components/Icon"
import styles from "./TopupCreditModal.module.css"
import ResultModal from "../../components/ResultModal"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {CREDIT_SETTINGS_IDENTIFIER} from "../../constants/platformSettings"
import { alphabeticalSort, capitalizeAllWords } from "../../utils/stringUtils"
import {calculatePaymentCharge, calculateShopdmFee} from "../../utils/paymentUtils"
import {v4 as uuid4} from 'uuid'
import currency from "currency.js"
import {
    NBD_MOBANKING_ID, DEBIT_OR_CREDIT_CARD_ID, JAD_CARD_PAYMENTS_ID, WIRE_TRANSFER_ID, PAYMENT_OBJECT_TYPE_TOPUP
} from "../../constants/payment"
import {
    PAYMENT_CLAIM_STATUS_CREATED
} from "../../constants/paymentClaims"
import {
    TOPUP_STATUS_PENDING,
    TOPUP_STATUS_PAYMENT_CLAIMED,
    TOPUP_STATUS_PROCESSED,
    TOPUP_STATUS_CANCELLED
} from "../../constants/topup"
import {RESULT_SUCCESS, RESULT_WARNING} from "../../constants/results"

import MobankingModal from "../MobankingModal"
import WireTransferPaymentModal from "../../components/WireTransferPaymentModal"
import JadPaymentModal from "../JadPaymentModal"
import FacCardPaymentModal from "../FacCardPaymentModal"

import {logAnalyticsEvent} from "../../config/firebase"
class TopupCreditModal extends React.Component {

    constructor(props){
        super(props)
        const {topups} = props
        let amount = "0.00"
        let selectedPaymentProviderId = ""
        //if there is an existiing current topup then 
        //use its amount and payment processor to fill the two selection fields
        if (topups.currentTopupId) {
            const topup = topups.topupsById[topups.currentTopupId]
            if (topup){
                amount = topup.topupAmountXcd
                selectedPaymentProviderId = topup.paymentProviderId
            }
        }
        this.state = {
            amount,
            supportedPaymentProviders: {},
            defaultPaymentProviderId: "",
            selectedPaymentProviderId,
            paymentProviderIdModalOpen: "",
            topupListener: null,
            result: null,
            resultMessage: ""
        }
    }

    static defaultProps = {
        closeModal: ()=> {},
        onPressConfirm: () => {},
    }

    componentDidMount = async () => {
        this.listenToCurrentTopup()
        const {actions, platformSettings} = this.props
        let supportedPaymentProviders
        let defaultPaymentProviderId = ""
        let {selectedPaymentProviderId} = this.state
        //if the credit platform settings have not been downloaded, download them
        //and use the settings to restrict the payment methods that can be used to buy credit and
        //set the default payment processor
        if (Object.keys(platformSettings[CREDIT_SETTINGS_IDENTIFIER]).length === 0 ){
            const creditPlatformSettings = await actions.fetchSavePlatformSetting(CREDIT_SETTINGS_IDENTIFIER)
            if (creditPlatformSettings) {
                supportedPaymentProviders = creditPlatformSettings.supportedPaymentProviders
                defaultPaymentProviderId = creditPlatformSettings.defaultPaymentProviderId
                selectedPaymentProviderId = selectedPaymentProviderId ? selectedPaymentProviderId : creditPlatformSettings.defaultPaymentProviderId
            }
        } else {
            //if already loaded do the same with the already downloaded settings
            supportedPaymentProviders = platformSettings[CREDIT_SETTINGS_IDENTIFIER].supportedPaymentProviders
            defaultPaymentProviderId = platformSettings[CREDIT_SETTINGS_IDENTIFIER].defaultPaymentProviderId
            selectedPaymentProviderId = selectedPaymentProviderId ? selectedPaymentProviderId : platformSettings[CREDIT_SETTINGS_IDENTIFIER].defaultPaymentProviderId
        }

        if (supportedPaymentProviders){
            this.setState({
                supportedPaymentProviders,
                selectedPaymentProviderId,
                defaultPaymentProviderId
            })
        }
        
    }

    componentDidUpdate = (prevProps) => {
        this.listenToCurrentTopup()
        const {topups} = this.props
        const topup = topups.currentTopupId ? topups.topupsById[topups.currentTopupId] : null
        const prevTopup = prevProps.topups.currentTopupId ? prevProps.topups.topupsById[prevProps.topups.currentTopupId] : null
        //if the topup's status has changes from PENDING to any of the others
        //e.g PROCESSED, CANCELLED or PAYMENT_CLAIMED then
        //display the result of the topup
        //this is the main point of listenting to the topup, to display status changes
        if (
            topup && 
            prevTopup && 
            topup.id === prevTopup.id &&
            prevTopup.currentStatus === TOPUP_STATUS_PENDING &&
            topup.currentStatus !== TOPUP_STATUS_PENDING
        ) this.handleDisplayTopupResult()
    }

    listenToCurrentTopup =  async () => {
        //if there is a current topup then listen for changes
        //otherwise do nothing
        const {actions, topups} = this.props
        if (topups.currentTopupId && !this.state.topupListener && !this.awaitingResponse){
            this.awaitingResponse = true
            const topupListener = await actions.fetchSubscribeToTopup(topups.currentTopupId)
            this.setState({topupListener}, () => this.awaitingResponse = false)
        }
    }

    componentWillUnmount(){
        if (this.state.topupListener) this.state.topupListener()
    }


    getFeesFromAmount = (amount, selectedPaymentProviderId) => {
        const {paymentProviders} = this.props 
        let amountValue = 0
        let processingFee = 0
        let paymentCharge = 0
        let total = 0
        let totalLessPaymentCharge = 0
        let shopdmCommissionEarnings = 0
        const validAmount = !isNaN(amount) && amount > 0
        if (validAmount && selectedPaymentProviderId){
            amountValue = Number(amount)
            shopdmCommissionEarnings = calculateShopdmFee(amountValue)
            const paymentProvider = paymentProviders.paymentProvidersById[selectedPaymentProviderId]
            paymentCharge = paymentProvider ?
                                  calculatePaymentCharge(
                                      (amountValue + shopdmCommissionEarnings), //total before charge
                                      paymentProvider
                                  )
                                  :
                                  0
            shopdmCommissionEarnings = shopdmCommissionEarnings.toFixed(2)
            paymentCharge = paymentCharge.toFixed(2)
            processingFee = currency(shopdmCommissionEarnings).add(paymentCharge).value.toFixed(2)
            total = currency(processingFee).add(amount).value.toFixed(2)
            totalLessPaymentCharge = currency(total).subtract(paymentCharge).value.toFixed(2)
        }
        return ({
            processingFee,
            shopdmCommissionEarnings,
            paymentCharge,
            total,
            totalLessPaymentCharge
        })
    }

    getSupportedPaymentProviders = () => {
        const {paymentProviders} = this.props
        const {supportedPaymentProviders, defaultPaymentProviderId} = this.state

        let paymentProviderList = paymentProviders.paymentProviderIds.filter(id => supportedPaymentProviders[id])
                                            .map(id => paymentProviders.paymentProvidersById[id])
                                            .sort((pA, pB) => alphabeticalSort(pA.name, pB.name))
        //put the default payment provider at the front of the list
        if (defaultPaymentProviderId && paymentProviders.paymentProvidersById[defaultPaymentProviderId]){
            paymentProviderList = [
                paymentProviders.paymentProvidersById[defaultPaymentProviderId],
                ...paymentProviderList.filter(p => p.id !== defaultPaymentProviderId)
            ]
        }                            
        return paymentProviderList
    }

    handleChangePaymentProvider = selectedPaymentProviderId => {
        const {paymentProviders, actions} = this.props
        const paymentProvider = paymentProviders.paymentProvidersById[selectedPaymentProviderId]
        logAnalyticsEvent("select_topup_payment_provider", {name: paymentProvider.name})
        actions.fetchLogDeviceSessionAction({
            action: "selectTopupPaymentProvider",
            name: paymentProvider.name
        })
        this.setState({selectedPaymentProviderId})
    }

    handleRequestTopup = async (
        processingFee, 
        shopdmCommissionEarnings, 
        paymentCharge,
        total
    ) => {
        const {actions, topups, walletId, walletName, paymentProviders, user} = this.props
        const {amount, selectedPaymentProviderId} = this.state
        const paymentProvider = paymentProviders.paymentProvidersById[selectedPaymentProviderId]
        let successful = false
        const topupId = topups.currentTopupId ? topups.currentTopupId : uuid4()
        //track analytics
        logAnalyticsEvent("request_topup")
        actions.fetchLogDeviceSessionAction({
            action: "requestTopup",
            walletId,
            topupId
        })
        if (topups.currentTopupId) {
            successful = await actions.fetchUpdateTopup(
                topups.currentTopupId,
                walletId,
                walletName,
                paymentProvider.id,
                paymentProvider.name,
                total,
                amount,
                processingFee,
                shopdmCommissionEarnings,
                paymentCharge,
                user.id,
                user.email,
                user.defaultAddressId && user.addressesById? user.addressesById[user.defaultAddressId].contactNumber : "",
                capitalizeAllWords(`${user.firstName} ${user.lastName}`),
                TOPUP_STATUS_PENDING,
            )
        } else {
            successful = await actions.fetchCreateTopup(
                topupId,
                walletId,
                walletName,
                paymentProvider.id,
                paymentProvider.name,
                total,
                amount,
                processingFee,
                shopdmCommissionEarnings,
                paymentCharge,
                user.id,
                user.email,
                user.defaultAddressId && user.addressesById? user.addressesById[user.defaultAddressId].contactNumber : "",
                capitalizeAllWords(`${user.firstName} ${user.lastName}`),
                TOPUP_STATUS_PENDING,   
            )
        }
        return successful
    }

    handleOpenPaymentModal = async (
        processingFee, 
        shopdmCommissionEarnings, 
        paymentCharge,
        total
    ) => {
        const {actions} = this.props
        const {selectedPaymentProviderId} = this.state 
        actions.toggleLoading(true)
        const success = await this.handleRequestTopup(
            processingFee, 
            shopdmCommissionEarnings, 
            paymentCharge,
            total
        )
        actions.toggleLoading(false)
        if (success) {
            this.setState({paymentProviderIdModalOpen: selectedPaymentProviderId})
        }
    }

    handleClosePaymentModal = () => this.setState({paymentProviderIdModalOpen: ""})

    handleCreatePaymentClaim = async (
        paymentProviderId,
        paymentProviderName,
        totalXcd,
        paymentProcessorFeeXcd,
        objectType,
        objectId,
        reference,
        imageFile
    ) => {
        const {actions, user} = this.props
        actions.toggleLoading(true)
        const successfullyCreated = await actions.fetchCreatePaymentClaim(
            uuid4(),
            paymentProviderId,
            paymentProviderName,
            totalXcd,
            paymentProcessorFeeXcd,
            objectType,
            objectId,
            reference,
            PAYMENT_CLAIM_STATUS_CREATED,
            user.id,
            capitalizeAllWords(`${user.firstName} ${user.lastName}`),
            user.defaultAddressId && user.addressesById? user.addressesById[user.defaultAddressId].contactNumber : "",
            user.email,
            imageFile
        )
        if (!successfullyCreated) {
            //if something stopped the payment claim to be made   
            actions.toggleLoading(false)
            alert(`Something went wrong. Could not make payment claim`)
        } //else if the payment claim was made successfully, leave the loading screen going 
    }

    handleDisplayTopupResult = () => {
        const {topups, actions, paymentProviders} = this.props
        const {selectedPaymentProviderId} = this.state 
        const topup = topups.topupsById[topups.currentTopupId]
        //put down the loading screen
        actions.toggleLoading(false)
        //display the appropriate result screen
        if (topup.currentStatus === TOPUP_STATUS_PAYMENT_CLAIMED){
            const paymentProvider = paymentProviders.paymentProvidersById[selectedPaymentProviderId]
            //if the customer claimed they paid
            this.setState({
                result: RESULT_SUCCESS,
                resultMessage: `Topup successful. Your credit will be applied once your ${capitalizeAllWords(paymentProvider.name)} payment is processed`
            })
        } else if (topup.currentStatus === TOPUP_STATUS_PROCESSED){
            this.setState({
                result: RESULT_SUCCESS,
                resultMessage: "Topup successful"
            })
        } else if (topup.currentStatus === TOPUP_STATUS_CANCELLED){
            this.setState({
                result: RESULT_WARNING,
                resultMessage: "Something went wrong. Topup cancelled. Please try again"
            })
        }
    }

    handleCloseResultModal = () => {
        const {actions, closeModal} = this.props
        this.setState({result: null, resultMessage: ""})
        actions.clearCurrentTopup()
        closeModal()
    }

    render(){
        const {isOpen, closeModal, onPressConfirm, user, device, paymentProviders, topups} = this.props
        let {amount, selectedPaymentProviderId, paymentProviderIdModalOpen, result, resultMessage} = this.state
        let processingFee = "0.00"
        let shopdmCommissionEarnings = "0.00"
        let paymentCharge = "0.00"
        let total = "0.00"
        let totalLessPaymentCharge = "0.00"
        const validAmount = !isNaN(amount) && amount > 0
        if (validAmount) {
            ({processingFee, shopdmCommissionEarnings, paymentCharge, total, totalLessPaymentCharge} = this.getFeesFromAmount(amount, selectedPaymentProviderId))
        }
        const disabledText = !validAmount ? "Let us know how much you want to top up" :
                             !selectedPaymentProviderId ? "Choose a payment method" : 
                             ""
        const confirmButtonText = validAmount ? `Confirm And Pay EC $${total}` : "Confirm And Pay"
        const paymentProvider = paymentProviderIdModalOpen ? 
                                paymentProviders.paymentProvidersById[paymentProviderIdModalOpen]
                                :
                                null
        const topup = topups.currentTopupId ? topups.topupsById[topups.currentTopupId] : null
        return (
            <div>
            <ContainedModal 
                isOpen={isOpen} 
                closeModal={closeModal}
                onPressConfirm={onPressConfirm}
                title="Top Up Your Shopdm Credit"
                isDisabled={!validAmount || !selectedPaymentProviderId}
                disabledText={disabledText}
                confirmButtonText={confirmButtonText}
                onPressConfirm={
                    () => this.handleOpenPaymentModal(
                        processingFee, 
                        shopdmCommissionEarnings, 
                        paymentCharge,
                        total
                    )
                }
            >

                <div className={styles.container}>
                    {/* {Date.now() < 1696905103000 ?<AnnouncementBanner preMessage="Please use NBD Mobanking or Wire Transfer to pay for your topups" /> : null} */}
                    <div className="formSection">
                        <div className={styles.prompt}>How much would you like to top up?</div>
                        <div className={styles.amount}>
                            <FormField
                                type="currency"
                                value={amount}
                                onChange={amount => this.setState({amount})}
                                currencyInputSize="fit"
                                currencyTextSize="big"
                            />
                        </div>
                        <div>Processing fee: <span>EC ${processingFee}</span>
                            <div className={`formNotes ${styles.info}`}><Icon icon="info"/> This processing fee saves you money as it is is cheaper than paying the checkout fee repeatedly</div>
                        </div>
                    </div>
                    {
                        validAmount ?
                        <div className="formSection">
                            <div className={["strong", styles.title].join(" ")}>Pay with</div>
                            <div className="checkoutField flex">
                                {
                                    this.getSupportedPaymentProviders().map(paymentProvider => {
                                        return <PaymentMethodOption 
                                                    key={paymentProvider.id}
                                                    onSelect={ () => this.handleChangePaymentProvider(paymentProvider.id)}
                                                    logo={paymentProvider.logo}
                                                    name={paymentProvider.displayNameWithLogo ? paymentProvider.name : ""}
                                                    checked={selectedPaymentProviderId === paymentProvider.id}
                                                />
                                    })
                                }
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </ContainedModal>
            {
                paymentProviderIdModalOpen === DEBIT_OR_CREDIT_CARD_ID && topup?
                <FacCardPaymentModal 
                    isOpen={Boolean(paymentProviderIdModalOpen)}
                    closeModal={this.handleClosePaymentModal} 
                    amount2DecimalPlaces={total}
                    userId={user.id}
                    userName={`${user.firstName} ${user.lastName}`}
                    email={user.email}
                    phoneNumber={ 
                        user.defaultAddressId && 
                        user.addressesById && 
                        user.addressesById[user.defaultAddressId] ?
                        user.addressesById[user.defaultAddressId].contactNumber
                        :
                        ""
                    }
                    deviceId={device.id}
                    objectId={topup.id}
                    objectType={PAYMENT_OBJECT_TYPE_TOPUP}
                    reference={topup.reference}
                    paymentProviderId={paymentProviderIdModalOpen}
                />
                :
                paymentProviderIdModalOpen === JAD_CARD_PAYMENTS_ID && topup?
                <JadPaymentModal 
                    isOpen={Boolean(paymentProviderIdModalOpen)}
                    closeModal={this.handleClosePaymentModal} 
                    amount2DecimalPlaces={total}
                    amountLessPaymentCharge2DecimalPlaces={totalLessPaymentCharge}
                    userId={user.id}
                    deviceId={device.id}
                    objectId={topup.id}
                    objectType={PAYMENT_OBJECT_TYPE_TOPUP}
                    reference={topup.reference}
                    paymentProviderId={paymentProviderIdModalOpen}
                />
                :
                paymentProviderIdModalOpen === NBD_MOBANKING_ID && topup?
                <MobankingModal 
                    isOpen={Boolean(paymentProviderIdModalOpen)}
                    closeModal={this.handleClosePaymentModal} 
                    amount2DecimalPlaces={total}
                    userName={capitalizeAllWords(`${user.firstName} ${user.lastName}`)}
                    reference={topup.reference} 
                    objectType={PAYMENT_OBJECT_TYPE_TOPUP}
                    onCompletePayment={imageFile => this.handleCreatePaymentClaim(
                        paymentProvider.id,
                        paymentProvider.name,
                        total,
                        paymentCharge,
                        PAYMENT_OBJECT_TYPE_TOPUP,
                        topup.id,
                        topup.reference,
                        imageFile
                    )}
                /> 
                :
                paymentProviderIdModalOpen === WIRE_TRANSFER_ID && topup?
                <WireTransferPaymentModal 
                    isOpen={Boolean(paymentProviderIdModalOpen)}
                    closeModal={this.handleClosePaymentModal} 
                    amount2DecimalPlaces={total}
                    logo={paymentProvider.logo}
                    reference={topup.reference}
                    objectType={PAYMENT_OBJECT_TYPE_TOPUP}
                    onPressConfirm={() => this.handleCreatePaymentClaim(
                        paymentProvider.id,
                        paymentProvider.name,
                        total,
                        paymentCharge,
                        PAYMENT_OBJECT_TYPE_TOPUP,
                        topup.id,
                        topup.reference
                    )}
                    cancelButtonText="Cancel And Go Back"
                    confirmButtonText="Confirm And Topup"
                    disclaimerText="and become available for you to spend as Shopdm Credit"
                />
                :
                null
            }
            {
                result ?
                <ResultModal 
                    result={result}
                    autoClose={topup && topup.currentStatus === TOPUP_STATUS_PROCESSED}
                    message={resultMessage}
                    onClose={this.handleCloseResultModal}
                />
                :
                null
            }
            </div>
        )
    }
}

const mapStatetoProps = state => ({
    paymentProviders: state.paymentProviders,
    platformSettings: state.platformSettings,
    topups: state.topups,
    user: state.user,
    device: state.device
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStatetoProps, mapDispatchToProps)(TopupCreditModal)