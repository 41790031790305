import React from "react"
import styles from "./AvatarIcon.module.css"

import Icon from "../Icon"
import {colorFromString} from "../../utils/stringUtils"
import {AVATAR_SHAPE_SQUARE, AVATAR_SHAPE_ROUND, AVATAR_SIZE_SMALL, AVATAR_SIZE_MEDIUM, AVATAR_SIZE_BIG} from "../../constants/interface"

const AvatarIcon = ({name="", backgroundColor=null, borderColor=null, shape=null, imageUrl="", showDefaultIcon=false, size=AVATAR_SIZE_SMALL, onClick=null, ShowOnHover=null, BottomRight=null}) => {
    let initial = "S"
    backgroundColor = imageUrl ? "var(--white)" 
                :
                !backgroundColor && !name ? 
                "var(--primary)"  //if no name or color is provided, use the Shopdm color
                :
                !backgroundColor && name ?
                colorFromString(name)
                :
                backgroundColor
                
    if (name && name.length > 0){
        initial = name.trim()[0]
    }
    const styleSettings= {backgroundColor, borderColor}
    if (shape === AVATAR_SHAPE_SQUARE) styleSettings.borderRadius = "25%"
    const sizeStyles = size === AVATAR_SIZE_MEDIUM ? styles.medium : 
                        size === AVATAR_SIZE_BIG ? styles.big : ""
    const clickableStyles = onClick ? styles.clickable : ""
    onClick = onClick ? onClick : ()=>{}
    return (
        <div className={styles.container} onClick={onClick}>
            <div style={styleSettings} className={`${styles.innerContainer} ${sizeStyles} ${clickableStyles}`}>
                {
                    !imageUrl && showDefaultIcon ? <Icon icon="photo-camera"/> :
                    imageUrl ? 
                    <img src={imageUrl}/>
                    :
                    initial
                }
                {
                    ShowOnHover ?
                    <div className={styles.showOnHover}>
                        <ShowOnHover />
                    </div>
                    :
                    null
                }
                
            </div>
            {
                BottomRight ?
                <div className={styles.bottomRight}>
                    <BottomRight />
                </div>
                :
                null
            }
        </div>
    )
}

export default AvatarIcon