import React from "react"
import FormField from "../../components/FormField"
import Icon from "../../components/Icon"
import Form from "../../components/Form"
import {parseAttributeSringToObject, isValidUrl, urlHasValidHost} from "../../utils/stringUtils"
import {DEFAULT_VIDEO_HEIGHT, DEFAULT_VIDEO_WIDTH, APPROVED_VIDEO_HOST_LIST, FACEBOOK_HOST_ID, YOUTUBE_HOST_ID, VIDEO_ORIENTATION_LANDSCAPE, VIDEO_ORIENTATION_PORTRAIT} from "../../constants/videos"
import styles from "./VideoEmbedForm.module.css"
import Video from "../../images/video.png"

class VideoEmbedForm extends Form {

    constructor(props){
        super(props)
        const {video={}} = props
        const {
            url="", 
            title="", 
            height=DEFAULT_VIDEO_HEIGHT, 
            width=DEFAULT_VIDEO_WIDTH, 
            aspectRatio=DEFAULT_VIDEO_WIDTH/DEFAULT_VIDEO_HEIGHT,
            isDisplayVideo=true,
            orientation=""
        } = video
        this.state = {
            values: {
                ...this.fields,
                url,
                title,
                height,
                width,
                aspectRatio,
                orientation
            },
            errors: {},
            hostInstructions: FACEBOOK_HOST_ID
        }
    }

    fields = {
        code: "",
        url: "",
        title:"",
        height: DEFAULT_VIDEO_HEIGHT,
        width: DEFAULT_VIDEO_WIDTH,
        aspectRatio: DEFAULT_VIDEO_WIDTH/DEFAULT_VIDEO_HEIGHT,
        isDisplayVideo: true,
        orientation: ""
    }

    validate = () => {
        const errors = {}
        const {values} = this.state
        const {url, title, code, video} = values
        Object.keys(values).forEach(key => {
            if (!Boolean(values[key]) &&
                (key !== "code")
            ){
                const label = key === 'url' ? "Video embed code" : key
                errors[key] = `${label} is required`
            }
        })
        if (!errors.title && !title.trim()) errors.title = `Title is required`
        if (!errors.url && !isValidUrl(url)) errors.url = `${url} is not a valid url`
        if (!errors.url && !urlHasValidHost(url, APPROVED_VIDEO_HOST_LIST)) errors.url = `Your video must be hosted on facebook or youtube`
        if (Object.values(errors).every(error => !error)) return true
        else {
            this.setState({errors})
            return false
        }
    }

    handleChangeCode = code => {
        code = String(code)
        const parsedValues = parseAttributeSringToObject(code.trim())
        let {src="", height, width} = parsedValues
        if (!height || isNaN(height) || height <= 0) height = DEFAULT_VIDEO_HEIGHT
        if (!width || isNaN(width) || width <= 0) width = DEFAULT_VIDEO_WIDTH
        height = Number(height)
        width = Number(width)
        const aspectRatio = width/height
        const orientation = height > width ? VIDEO_ORIENTATION_PORTRAIT: VIDEO_ORIENTATION_LANDSCAPE
        this.setState({
            values: {
                ...this.state.values,
                code,
                width,
                height,
                url: src,
                aspectRatio,
                orientation
            },
            errors: {}
        })
    }

    executeSubmit = async () => {
        const {url, height, width, aspectRatio, isDisplayVideo, title, orientation} = this.state.values
        const {submit} = this.props
        return await submit(
            title.trim(),
            url.trim(),
            height,
            width,
            aspectRatio,
            orientation,
            isDisplayVideo
        )
    }
    
    toggleHostInstructions = hostInstructions => this.setState({hostInstructions})

    render() {
        const {values, errors, hostInstructions} = this.state
        const {code, url, height, aspectRatio, title, orientation} = values
        const {onCancel, video} = this.props 
        
        // const displayWidth = DEFAULT_VIDEO_WIDTH
        // const displayHeight = DEFAULT_VIDEO_WIDTH/aspectRatio

        const displayWidth = orientation === VIDEO_ORIENTATION_LANDSCAPE ? DEFAULT_VIDEO_WIDTH : height * aspectRatio
        const displayHeight = orientation === VIDEO_ORIENTATION_LANDSCAPE ? DEFAULT_VIDEO_WIDTH/aspectRatio :  height
        return (
            <div>
                <div>
                    <div className={styles.iconContainer}>
                        <img src={Video} />
                        <div className="strong icon"> Embed A Video</div>
                    </div>
                    {
                    url ?
                    <div className={styles.videoFrame}>
                        <iframe 
                            src={url}
                            width={displayWidth}
                            height={displayHeight}
                        />
                    </div>
                    :
                    <div>
                        Instructions for:
                        <div className={styles.instructionsToggle}>
                            <div 
                                className={hostInstructions === FACEBOOK_HOST_ID ? styles.selected : ""}
                                onClick={() => this.toggleHostInstructions(FACEBOOK_HOST_ID)}
                            >
                                <span className="facebookIcon">
                                    <Icon icon="facebook" /> 
                                </span>
                                <span>Facebook</span>
                            </div>
                            <div 
                                className={hostInstructions === YOUTUBE_HOST_ID ? styles.selected : ""}
                                onClick={() => this.toggleHostInstructions(YOUTUBE_HOST_ID)}
                            >
                                <span className="youtubeIcon">
                                    <Icon icon="youtube" /> 
                                </span>
                                <span>Youtube</span>
                            </div>
                        </div>
                        <div className="info-bubble">
                            {
                                hostInstructions === FACEBOOK_HOST_ID ?
                                <div>
                                    <ol>
                                        <li>Open Facebook on your computer.</li>
                                        <li>Find or upload the video you want to embed and open it in full screen.</li>
                                        <li>Click on the three dots on the far right above the comments.</li>
                                        <li>Click "Embed."</li>
                                        <li>Click the blue button labelled "Copy Code."</li>
                                        <li>Paste it into the field below</li>
                                    </ol>
                                    <div className={styles.note}>Note: The video must be set to "Public" for this to work. Also, remember that you will not see the Embed option if the video is not in full screen.</div>
                                </div>
                                :

                                hostInstructions === YOUTUBE_HOST_ID ?
                                <ol>
                                    <li>Open Youtube on your computer.</li>
                                    <li>Find or upload the video you want to embed and open it</li>
                                    <li>Right-click on your video to see the menu</li>
                                    <li>Click "Copy embed code"</li>
                                    <li>Paste it into the field below</li>
                                </ol>
                                :
                                null
                            }
                        </div>
                    </div>
                    }
                </div>
                { 
                    !video ? 
                    <FormField 
                        label="Paste 'Embed Code' Here"
                        value={code} 
                        onChange={e => this.handleChangeCode(e.target.value)}
                        onKeyDown={this.handleKeyDown}
                        errorText={errors.url}
                    />
                    :
                    ""
                }
                <FormField 
                    label="Add A Title"
                    value={title} 
                    onChange={e => this.handleChange(e, "title")}
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.title}
                />
                <div className="buttonHolder">
                    <button className="button white icon" onClick={onCancel}><Icon icon="close"/> Cancel</button>
                    <button className="button primary icon" onClick={this.handleSubmit}><Icon icon="done"/> Save</button>
                </div>
            </div>
        )
    }
}

export default VideoEmbedForm