/**
 *  Purpose: the modifications that are carried on the Delivery Agent objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 // STATE
 // deliveryAgents: {
 //     deliveryAgentsById: {},
 //     deliveryAgentIdsByAreaId: {},
 //     deliveryAgentIds: [],
 // }
 
 
 const deliveryAgentsReducer = (state = initialState.deliveryAgents, action) => {
     const {type, payload} = action;
     let deliveryAgentsById = {...state.deliveryAgentsById}
     let deliveryAgentIdsByAreaId = {...state.deliveryAgentIdsByAreaId}
     let deliveryAgentIds = {...state.deliveryAgentIds}

    switch (type){
         case types.SAVE_DELIVERY_AGENTS: {
             if(typeof payload.deliveryAgents !== "object") {
                 logError(`deliveryAgentsReducer > SAVE_DELIVERY_AGENTS: deliveryAgents payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.deliveryAgents.forEach(deliveryAgent => {
                 //find the previous delivery agent, if it exists 
                 const previousDeliveryAgent = deliveryAgentsById[deliveryAgent.id]
                 if (previousDeliveryAgent){
                    //remove any previous indexes for this agent
                    deliveryAgentIdsByAreaId = removeAreaIndexesForAgent(
                        deliveryAgent.id, 
                        previousDeliveryAgent.deliveryAreaIdList, 
                        deliveryAgentIdsByAreaId
                    )
                }
                //index the agents by their ids
                deliveryAgentsById[deliveryAgent.id] = deliveryAgent
                 //index the agents by the areas they deliver to
                 deliveryAgentIdsByAreaId = addAreaIndexesForAgent(
                    deliveryAgent.id, 
                    deliveryAgent.deliveryAreaIdList, 
                    deliveryAgentIdsByAreaId
                )
             })
             return {
                 ...state,
                 deliveryAgentsById,
                 deliveryAgentIdsByAreaId,
                 deliveryAgentIds: Object.keys(deliveryAgentsById)
             }
         }

        case types.UPDATE_DELIVERY_AGENT_IS_ACTIVE: {
            if(typeof payload.deliveryAgentId !== "string") {
                logError(`deliveryAgentsReducer > UPDATE_DELIVERY_AGENT_IS_ACTIVE: deliveryAgentId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.isActive !== "boolean") {
                logError(`deliveryAgentsReducer > UPDATE_DELIVERY_AGENT_IS_ACTIVE: isActive in payload is not a boolean ${JSON.stringify(payload)}`)
                return state
            }
            return{
                ...state,
                deliveryAgentsById: {
                    ...deliveryAgentsById,
                    [payload.deliveryAgentId] : {
                        ...deliveryAgentsById[payload.deliveryAgentId],
                        isActive: payload.isActive
                    }
                },
            }
        }

        case types.UPDATE_DELIVERY_AGENT_DELIVERY_AREAS: {
            if(typeof payload.deliveryAgentId !== "string") {
                logError(`deliveryAgentsReducer > UPDATE_DELIVERY_AGENT_DELIVERY_AREAS: deliveryAgentId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(!Array.isArray(payload.deliveryAreaIdList)) {
                logError(`deliveryAgentsReducer > UPDATE_DELIVERY_AGENT_DELIVERY_AREAS: deliveryAreaIdList in payload is not an array ${JSON.stringify(payload)}`)
                return state
            }
            if(!Array.isArray(payload.previousDeliveryAreaIdList)) {
                logError(`deliveryAgentsReducer > UPDATE_DELIVERY_AGENT_DELIVERY_AREAS: previousDeliveryAreaIdList in payload is not an array ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.scheduledTripsById !== "object") {
                logError(`deliveryAgentsReducer > UPDATE_DELIVERY_AGENT_DELIVERY_AREAS: scheduledTripsById in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            deliveryAgentIdsByAreaId = removeAreaIndexesForAgent(
                payload.deliveryAgentId, 
                payload.previousDeliveryAreaIdList, 
                deliveryAgentIdsByAreaId
            )
            deliveryAgentIdsByAreaId = addAreaIndexesForAgent(
                payload.deliveryAgentId, 
                payload.deliveryAreaIdList, 
                deliveryAgentIdsByAreaId
            )
            return{
                ...state,
                deliveryAgentsById: {
                    ...deliveryAgentsById,
                    [payload.deliveryAgentId] : {
                        ...deliveryAgentsById[payload.deliveryAgentId],
                        deliveryAreaIdList: payload.deliveryAreaIdList
                    }
                },
                deliveryAgentIdsByAreaId
            }
        }

        case types.UPDATE_DELIVERY_AGENT_SCHEDULED_TRIPS: {
            if(typeof payload.deliveryAgentId !== "string") {
                logError(`deliveryAgentsReducer > UPDATE_DELIVERY_AGENT_SCHEDULED_TRIPS: deliveryAgentId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.scheduledTripsById !== "object") {
                logError(`deliveryAgentsReducer > UPDATE_DELIVERY_AGENT_SCHEDULED_TRIPS: scheduledTripsById in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.previousScheduledTripsById !== "object") {
                logError(`deliveryAgentsReducer > UPDATE_DELIVERY_AGENT_SCHEDULED_TRIPS: previousScheduledTripsById in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.deliveryAgent !== "object") {
                logError(`deliveryAgentsReducer > UPDATE_DELIVERY_AGENT_SCHEDULED_TRIPS: deliveryAgent in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            return{
                ...state,
                deliveryAgentsById: {
                    ...deliveryAgentsById,
                    [payload.deliveryAgentId] : {
                        ...deliveryAgentsById[payload.deliveryAgentId],
                        scheduledTripsById: payload.scheduledTripsById
                    }
                },
            }
        }

        case types.UPDATE_DELIVERY_AGENT_VEHICLES: {
            if(typeof payload.deliveryAgentId !== "string") {
                logError(`deliveryAgentsReducer > UPDATE_DELIVERY_AGENT_VEHICLES: deliveryAgentId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.vehiclesById !== "object") {
                logError(`deliveryAgentsReducer > UPDATE_DELIVERY_AGENT_VEHICLES: vehiclesById in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.defaultVehicleId !== "string") {
                logError(`deliveryAgentsReducer > UPDATE_DELIVERY_AGENT_VEHICLES: defaultVehicleId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            return{
                ...state,
                deliveryAgentsById: {
                    ...deliveryAgentsById,
                    [payload.deliveryAgentId] : {
                        ...deliveryAgentsById[payload.deliveryAgentId],
                        vehiclesById: payload.vehiclesById,
                        defaultVehicleId: payload.defaultVehicleId
                    }
                },
            }
        }
 

         default: return state
     }
 }
 
const removeAreaIndexesForAgent = (
    deliveryAgentId, 
    deliveryAreaIdList=[], 
    deliveryAgentIdsByAreaId={}
) => {
    //remove the indexing from an agent to delivery areas
    deliveryAreaIdList.forEach(areaId => {
        if (
            deliveryAgentIdsByAreaId[areaId] && //if any agents deliver to this areas
            deliveryAgentIdsByAreaId[areaId][deliveryAgentId] //and this specific agent delivers to this area
        ) {
            //remove this agent from this area
            deliveryAgentIdsByAreaId = {
                ...deliveryAgentIdsByAreaId,
                [areaId]: {
                    ...deliveryAgentIdsByAreaId[areaId]
                }
            }
            delete deliveryAgentIdsByAreaId[areaId][deliveryAgentId]
            //if there are no more agents for this area, remove the area
            if (Object.keys(deliveryAgentIdsByAreaId[areaId]).length === 0) delete deliveryAgentIdsByAreaId[areaId]
        }
    })
    return deliveryAgentIdsByAreaId
}

const addAreaIndexesForAgent = (
    deliveryAgentId, 
    deliveryAreaIdList=[], 
    deliveryAgentIdsByAreaId={}
) => {
    //index an agent to its delivery areas so we know who delivers where
    deliveryAreaIdList.forEach(areaId => {
        if (!deliveryAgentIdsByAreaId[areaId]) deliveryAgentIdsByAreaId[areaId] = {}
        else deliveryAgentIdsByAreaId[areaId] = {...deliveryAgentIdsByAreaId[areaId]}
        deliveryAgentIdsByAreaId[areaId][deliveryAgentId] = true
    })
    return deliveryAgentIdsByAreaId
}

 export default deliveryAgentsReducer