import React from "react"
import ResultScreen from "../ResultScreen"
import Modal from "../Modal"
import styles from "./ResultModal.module.css"

class ResultModal extends React.Component{
    
    state = {
        modalOpen: true
    }

    static defaultProps = {
        onClose: ()=>{},
        autoCloseTimeout: 3000,
        autoClose: true,
        message: "",
        buttonText: "Got it",
        displayButton: false
    }

    handleClose = () => {
        const {onClose} = this.props
        this.setState({modalOpen: false}, onClose)
    }

    componentDidMount = () => {
        const {autoCloseTimeout, autoClose} = this.props
        if (autoClose) setTimeout(this.handleClose, autoCloseTimeout)
    }

    render(){
        const {modalOpen} = this.state
        const {message, result, buttonText, autoClose} = this.props
        return (
            <Modal
                isOpen={modalOpen}
                className={styles.modal}
                overlayClassName={styles.overlay}
                closeModal={this.handleClose}
                showCloseButton={false}
            >
                <ResultScreen 
                    message={message}
                    result={result}
                    buttonText={buttonText}
                    onClick={this.handleClose}
                    displayButton={!autoClose}
                />
            </Modal>
        )
    }
}

export default ResultModal