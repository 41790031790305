import React from "react"
import NavigationTile from "../../components/NavigationTile"
import Modal from "../../components/Modal"
import SellerList from "../SellerList"
import CategoryList from "../CategoryList"

import styles from "./MobileNavigationBar.module.css"

const SHOP_TAB = 'SHOP_TAB'
const SELLERS_TAB = 'SELLERS_TAB'
const CATEGORIES_TAB = 'CATEGORIES_TAB'

class MobileNavigationBar extends React.Component{
    constructor(props){
        super(props)
        const {innerRef} = this.props
        innerRef(this)
        this.state = {
            sellerModalOpen: false,
            categoryModalOpen: false,
            slideUp: false,
            activeTab: SHOP_TAB
        }
    }

    static defaultProps = {
        innerRef: ()=> {}
    }

    slide = () => {
       setTimeout( () => this.setState({slideUp: true}), 50)
    }
    handleOpenSellerModal = () => this.setState({
        categoryModalOpen: false,
        sellerModalOpen: true,
        slideUp: false,
        activeTab: SELLERS_TAB
    }, this.slide)

    
    handleOpenCategoryModal = () => this.setState({
        categoryModalOpen: true,
        sellerModalOpen: false,
        slideUp: false,
        activeTab: CATEGORIES_TAB
    }, this.slide)

    handleCloseModal = () => this.setState({
        sellerModalOpen: false, 
        categoryModalOpen: false,
        slideUp: false,
        activeTab: SHOP_TAB
    })

    render(){
        const {sellerModalOpen, categoryModalOpen, slideUp, activeTab} = this.state
        const modalStyle = slideUp ? `${styles.modal} ${styles.slide}` : styles.modal
        return (
        <div className={["phoneOnly", "flex", "mobileNavigationBar", styles.container].join(" ")}>
            <NavigationTile 
                icon="shopping-cart"
                title="Shop"
                onClick={this.handleCloseModal}
                selected={activeTab === SHOP_TAB}
            />
            <NavigationTile 
                icon="store"
                title="Stores"
                onClick={this.handleOpenSellerModal}
                selected={activeTab === SELLERS_TAB}
            />
            <NavigationTile 
                icon="reorder"
                title="Categories"
                onClick={this.handleOpenCategoryModal}
                selected={activeTab === CATEGORIES_TAB}
            />
            {
                sellerModalOpen ? 
                <Modal 
                    isOpen={sellerModalOpen}
                    closeModal={this.handleCloseModal}
                    className={modalStyle}
                    overlayClassName={styles.modalOverlay}
                    closeOnOverlayClick={true}
                >
                    <SellerList postClick={this.handleCloseModal} />
                </Modal>
                :
                null
            }
            {
                categoryModalOpen ? 
                <Modal 
                    isOpen={categoryModalOpen}
                    closeModal={this.handleCloseModal}
                    className={modalStyle}
                    overlayClassName={styles.modalOverlay}
                    closeOnOverlayClick={true}
                >
                    <CategoryList postClick={this.handleCloseModal} />
                </Modal>
                :
                null
            }
        </div>
        )
    }
}

export default MobileNavigationBar