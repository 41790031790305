import {firebaseApp, baseUrl} from "../config/firebase"
import { getFirestore, doc, query, collection, onSnapshot, getDocs, getDoc, setDoc, runTransaction } from "firebase/firestore";
import {getStorage, ref, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage"
import { logError } from "../utils/errorHandlingUtils"
import {MINUTE_IN_MILLISECONDS} from "../constants/datetime"
import {
    PAYEE_PAYOUT_SETTINGS_ID,
    PAYEE_PROFILE_ID,
    PAYEE_TRANSACTION_FEE_SETTINGS_ID,
    PAYEE_TRANSACTION_FEE_SETTING_MAP,
    PAYEE_LIMIT_SETTINGS_ID,
    PAYEE_AUTHORIZED_FUNCTIONS_ID,
    PAYEE_AUTHORIZED_FUNCTIONS_SETTING_MAP,
    PAYEE_ROLES_ID
} from "../constants/payees"
export const SAVE_PAYEES = 'SAVE_PAYEES'
export const CREATE_PAYEE = 'CREATE_PAYEE'
export const EDIT_PAYEE = 'EDIT_PAYEE'
export const SAVE_PAYEE_ROLES = 'SAVE_PAYEE_ROLES'
export const SAVE_PAYEE_FEE_SETTINGS = 'SAVE_PAYEE_FEE_SETTINGS'

export const savePayees = (payees, isLoad=false) => {
    return {
        type: SAVE_PAYEES,
        payload: {
            payees,
            isLoad
        }
    }
}

const createPayee = payee => {
    return {
        type: CREATE_PAYEE,
        payload: {
            payee
        }
    }
}

export const editPayee = (payeeId, edits) => {
    return {
        type: EDIT_PAYEE,
        payload: {
            payeeId,
            edits
        }
    }
}

export const savePayeeRoles = (payeeId, roles) => {
    return {
        type: SAVE_PAYEE_ROLES,
        payload: {
            payeeId,
            roles
        }
    }
}

export const savePayeeFeeSettings = (
    feeSettings={},
    payeeId=""
) => {
    return {
        type: SAVE_PAYEE_FEE_SETTINGS,
        payload: {
            feeSettings,
            payeeId
        }
    }
}

export const fetchSavePayees = () => {
    const firestore = getFirestore(firebaseApp)
    const payeesRef = query(collection(firestore, "payees"))
    return async (dispatch, getState) =>{
        try {
            const {payees} = getState()
            //only load all payees every 30 mins, payees are not serialized so refresh for latest

            if ((Date.now() - payees.lastLoadedAt) <= MINUTE_IN_MILLISECONDS * 30) return true
            const querySnapshot = await getDocs(payeesRef)
            //get an array of payees from the snapshot
            const payeeArray = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(savePayees(payeeArray, true))
            return true
        } catch (e){
            const message = `action > payees > fetchSavePayees: Failed to save payees`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSubscribeToPayee = (payeeId, onLoad=()=>{}) => {
    /**
      * Purpose: retrieve one payee from the firestore database
      * Note: the onSnapshot below watches for changes to the payee on the server
      */
    const firestore = getFirestore(firebaseApp)
    const payeeRef = doc(firestore, "payees", payeeId)
                                
    return async (dispatch) => {
        try {
            const payeeListener = await onSnapshot(payeeRef,
                docRef => {
                    if (!docRef.exists()) {
                        onLoad()
                        return
                    }
                    //get one payee from the snapshot
                    const payee = {...docRef.data()}
                    dispatch(savePayees([payee]))
                    onLoad()
                } 
            )
            return payeeListener
        } catch (e){
            const message = `action > payees > fetchSubscribeToPayee: Failed to subscribe to payee`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchEditPayee = (
    payeeId,
    edits={},
    onSuccess=()=>{},
    onError=()=>{}
) => {
    /**
      * Purpose: general purpuse editing of the central attributes of payees in the firestore database
      */
    const firestore = getFirestore(firebaseApp)
    const payeeRef = doc(firestore, "payees", payeeId)
                                
    return async (dispatch) => {
        try {
            await setDoc(payeeRef, edits, {merge: true})
            dispatch(editPayee(payeeId, edits))
            onSuccess()
            return true
        } catch (e){
            const message = `action > payees > fetchEditPayee: Failed to edit to payee ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}

export const fetchConfirmPayeeHandleIdUnique = (
    handleId,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    return async () => {
        try {
            const link = `${baseUrl}v1/payees/handle-unique`
            const response = await fetch(link, {
                "method": 'POST',
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                "body": JSON.stringify({
                    handleId,
                })
            })
            if (!response.ok) {
                throw new Error("something went wrong")
            }
            const result = await response.json()
            return result.success
        } catch (e) {
            e.message = `${e.message} action > payees > fetchConfirmPayeeHandleIdUnique: Failed to confirm handle ${handleId} is unique`
            logError(e)
            onError(e)
            return false
        }
    }
}

export const fetchCreatePayee = (
    id,
    {
        logoImageFile,
        logoImageFileMed,
        logoImageFileSmall,
    },
    name,
    handleId,
    payeeType,
    contactEmail,
    contactNumbers,
    defaultContactNumber,
    isActive,
    defaultCategoryId,
    categoryIds,
    countryId,
    defaultLocationId,
    locationsById,
    payoutEmail,
    bankAccount,
    businessDescription,
    expectedMonthlyVolumeInXcd,
    transactionFeeSettingType,
    transactionLimitXcd,
    monthlyLimitXcd,
    authorizedFuncSettingType,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const payeeRef = doc(firestore, 'payees', id)
    let payee = {
        id,
        name,
        handleId,
        payeeType,
        contactEmail,
        contactNumbers,
        defaultContactNumber,
        isActive,
        defaultCategoryId,
        categoryIds,
        countryId,
        defaultLocationId,
        locationsById,
        createdAt: Date.now(),
        //write a publicly accessible copy of the sender fee to the payee
        senderFee: PAYEE_TRANSACTION_FEE_SETTING_MAP[transactionFeeSettingType].senderFee
    }
    let logoImageUrl = ""
    let logoImageUrlMed = ""
    let logoImageUrlSmall = ""
    const storage = getStorage(firebaseApp)
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            await runTransaction(firestore, async (transaction) => {
                const payeeHandleRef = doc(firestore, 'payeeHandles', handleId)
                const docRef = await transaction.get(payeeHandleRef)
                if (docRef.exists()){
                    throw new Error(`Payee handle id ${handleId} has already been used`)
                }
                //upload the image
                if (!logoImageFile.url){ //if this is not a non-upload case where a url is provided
                    const imageRef = ref(storage, `payees/${id}/logo`)
                    await uploadBytes(imageRef, logoImageFile)
                    const imageRefMed = ref(storage, `payees/${id}/logo_med`)
                    await uploadBytes(imageRefMed, logoImageFileMed)
                    const imageRefSmall = ref(storage, `payees/${id}/logo_small`)
                    await uploadBytes(imageRefSmall, logoImageFileSmall)
                    //get the image url
                    logoImageUrl = await getDownloadURL(imageRef)
                    logoImageUrlMed = await getDownloadURL(imageRefMed)
                    logoImageUrlSmall = await getDownloadURL(imageRefSmall)
                } 
                //otherwise just take the actual url provided
                else {
                    logoImageUrl = logoImageFile.url
                    logoImageUrlMed = logoImageFile.url
                    logoImageUrlSmall = logoImageFile.url
                }
                payee = {
                    ...payee,
                    logoImageUrl,
                    logoImageUrlMed,
                    logoImageUrlSmall,
                    createdByUserId: user.id,
                    transactionLimitXcd: Number(transactionLimitXcd) //publicly readable to allow settings of max on payment creation
                }
                //create main, public payee object
                transaction.set(payeeRef, payee)
                //store the payee's handle, so it cannot be reused
                transaction.set(payeeHandleRef, {id: handleId, payeeId: id})
                //create payee bank account object
                const accountsRef = doc(firestore, `payees/${id}/accounts`, bankAccount.id)
                transaction.set(accountsRef, bankAccount)
                //create payee payout settings
                const payoutSettingsRef = doc(firestore, `payees/${id}/payoutSettings`, PAYEE_PAYOUT_SETTINGS_ID)
                const payoutSettings = {
                    id: PAYEE_PAYOUT_SETTINGS_ID,
                    payoutEmails: [payoutEmail],
                    bankAccountId: bankAccount.id
                }
                transaction.set(payoutSettingsRef, payoutSettings)
                //save the business profile
                const profileRef = doc(firestore, `payees/${id}/profile`, PAYEE_PROFILE_ID)
                const profile = {
                    id: PAYEE_PROFILE_ID,
                    businessDescription,
                    expectedMonthlyVolumeInXcd: Number(expectedMonthlyVolumeInXcd)
                }
                transaction.set(profileRef, profile)
                //save the payee's transaction fee settings
                const feeSettingsRef = doc(firestore, `payees/${id}/feeSettings`, PAYEE_TRANSACTION_FEE_SETTINGS_ID)
                const transactionFees = {
                    id: PAYEE_TRANSACTION_FEE_SETTINGS_ID,
                    fees: PAYEE_TRANSACTION_FEE_SETTING_MAP[transactionFeeSettingType],
                    type: transactionFeeSettingType
                }
                transaction.set(feeSettingsRef, transactionFees)
                //save the payee's limit settings
                const limitSettingsRef = doc(firestore, `payees/${id}/limitSettings`, PAYEE_LIMIT_SETTINGS_ID)
                const limitSettings = {
                    id: PAYEE_LIMIT_SETTINGS_ID,
                    transactionLimitXcd: Number(transactionLimitXcd),
                    monthlyLimitXcd: Number(monthlyLimitXcd)
                }
                transaction.set(limitSettingsRef, limitSettings)
                //save the payee's authorized functions
                const authorizedFunctionsRef = doc(firestore, `payees/${id}/authorizedFunctions`, PAYEE_AUTHORIZED_FUNCTIONS_ID)
                const authorizedFunctions = {
                    id: PAYEE_AUTHORIZED_FUNCTIONS_ID,
                    type: authorizedFuncSettingType,
                    functions: PAYEE_AUTHORIZED_FUNCTIONS_SETTING_MAP[authorizedFuncSettingType]
                }
                transaction.set(authorizedFunctionsRef, authorizedFunctions)
                //save a blank roles documents
                const rolesRef = doc(firestore, `payees/${id}/roles`, PAYEE_ROLES_ID)
                const roles = {
                    id: PAYEE_ROLES_ID,
                    roles: {}
                }
                transaction.set(rolesRef, roles)
            })
            dispatch(createPayee(payee))
            onSuccess(true)
            return true
        } catch (e){
            const message = `action > payee > fetchCreatePayee: Failed to create payee with  ${id} logo`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            //clean up any uploaded images for unsuccessfully created payees
            if (logoImageUrl){
                const imageRef = ref(storage,  logoImageUrl)
                await deleteObject(imageRef)
            }
            if (logoImageUrlMed){
                const imageRefMed = ref(storage,  logoImageUrlMed)
                await deleteObject(imageRefMed)
            }
            if (logoImageUrlSmall){
                const imageRefSmall = ref(storage,  logoImageUrlSmall)
                await deleteObject(imageRefSmall)
            }
            onError(id)
            return false
        }
    }
}

export const fetchSubscribeToPayeeRoles = (payeeId, onLoad=()=>{}) => {
    /**
      * Purpose: retrieve one payee's roles from the firestore database
      * Note: the onSnapshot below watches for changes to the payee on the server
      */
    const firestore = getFirestore(firebaseApp)
    const payeeRolesRef = doc(firestore, `payees/${payeeId}/roles`, PAYEE_ROLES_ID)
                                
    return async (dispatch) => {
        try {
            const payeeRolesListener = await onSnapshot(payeeRolesRef,
                docRef => {
                    if (!docRef.exists()) {
                        onLoad()
                        return
                    }
                    //get one payee from the snapshot
                    const roles = {...docRef.data()}
                    dispatch(savePayeeRoles(payeeId, roles))
                    onLoad()
                } 
            )
            return payeeRolesListener
        } catch (e){
            const message = `action > payees > fetchSubscribeToPayeeRoles: Failed to subscribe to roles for payee ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchUpdatePayeeRoles = (
    payeeId, 
    roles={},
    onSuccess=()=>{},
    onError=()=>{}
) => {
    /**
      * Purpose: update a payee's roles in the firestore database
      */
    const firestore = getFirestore(firebaseApp)
    const payeeRolesRef = doc(firestore, `payees/${payeeId}/roles`, PAYEE_ROLES_ID)
                                
    return async (dispatch) => {
        try {
            await setDoc(payeeRolesRef, roles)
            onSuccess()
            return true
        } catch (e){
            const message = `action > payees > fetchUpdatePayeeRoles: Failed to update to roles for payee ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}

export const fetchSaveMerchantPayeeFeeSettings = (
    payeeId,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    /**
      * Purpose: retrieve a merchant payee's fee settings from the firestore database
      */
    const firestore = getFirestore(firebaseApp)
    const feeSettingsRef = doc(firestore, `payees/${payeeId}/feeSettings`, PAYEE_TRANSACTION_FEE_SETTINGS_ID)
    
    return async dispatch => {
        try {
            const feeSettingsDocRef = await getDoc(feeSettingsRef)
            let feeSettings
            if (feeSettingsDocRef.exists()) {
                feeSettings = {...feeSettingsDocRef.data()};
                dispatch(savePayeeFeeSettings(feeSettings, payeeId))
            } else throw new Error(`Fee settings for payee ${payeeId} does not exist`)
            onSuccess(payeeId)
            return feeSettings
        } catch (e){
            const message = `action > payees > fetchSaveMerchantPayeeFeeSettings: Failed to save fee settings for payee ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}