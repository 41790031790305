/**
 *  Purpose: the modifications that are carried out on the Platform Settings object for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // platformSettings: {
 //    [INVENTORY_SETTINGS_IDENTIFIER]:{},
 //    [CREDIT_SETTINGS_IDENTIFIER]: {},
 //    [DELIVERY_SLOTS_IDENTIFIER]: {}
 // }
 
 
 const platformSettingsReducer = (state = initialState.platformSettings, action) => {
     const {type, payload} = action;
     switch (type){
 
         case types.SAVE_PLATFORM_SETTING: {
             if(typeof payload.platformSetting !== "object") {
                 return state
             }
             if (typeof payload.settingId !== "string") return state
             return {
                 ...state,
                 [payload.settingId]: payload.platformSetting,
                 loaded: {
                     ...state.loaded,
                     [payload.settingId]: Date.now()
                 }
             }
         }
 
         default: return state
     }
 }
 
 export default platformSettingsReducer