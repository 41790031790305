import {selectedEnvironment} from "./index"
import {DEVELOPMENT, PRODUCTION} from "../constants/environment"

export const SERVICE_SOURCE_REMOTE = 'SERVICE_SOURCE_REMOTE'
export const SERVICE_SOURCE_LOCAL = 'SERVICE_SOURCE_LOCAL'

export const serviceSource = SERVICE_SOURCE_REMOTE

export let searchServiceUrl = ""

if (serviceSource === SERVICE_SOURCE_REMOTE || process.env.NODE_ENV === 'production'){
    searchServiceUrl = selectedEnvironment === DEVELOPMENT ?
                        "https://shop-dm-dev.ue.r.appspot.com/"
                        : selectedEnvironment === PRODUCTION ?
                        "https://shop-dm.appspot.com/"
                        : ""
                        
} else if (serviceSource === SERVICE_SOURCE_LOCAL){
    searchServiceUrl = "http://192.168.100.2:8081/" //home
    // searchServiceUrl = "http://192.168.1.138:8081/" //office
}