import React from "react"
import Icon from "../Icon"
import styles from "./IconButton.module.css"

class IconButton extends React.PureComponent{

    static defaultProps = {
        label: "",
        hasBorder: false,
        hasShadow: true,
        backgroundColor: "",
        labelColor: ""
    }
    render(){
        const {icon, onPress, label, hasBorder, hasShadow, backgroundColor, labelColor} = this.props
        const borderStyle = hasBorder ? styles.border : ""
        const shadowStyle = hasShadow ? styles.shadow : ""
        const backgroundColorStyle = backgroundColor ? styles.backgroundColor : ""
        const labelStyle = labelColor ? {color: labelColor} : {}
        return (
        <div className={styles.container} onClick={onPress}>
            <span
                style={{backgroundColor}} 
                className={[styles.iconContainer, borderStyle, shadowStyle, backgroundColorStyle].join(" ")} 
            >
                <Icon  icon={icon}/>
            </span>
            {label ? <div style={labelStyle} className={styles.label}>{label}</div> : null}
        </div>
        )
    }
}

export default IconButton