import {firebaseApp} from "../config/firebase"
import {getFirestore, query, collection, where, getDocs, onSnapshot} from "firebase/firestore"
import { logError } from "../utils/errorHandlingUtils"
import { DAY_IN_MILLISECONDS } from "../constants/datetime";

export const SAVE_PAYOUTS = 'SAVE_PAYOUTS'

export const savePayouts = payouts => {
    return {
        type: SAVE_PAYOUTS,
        payload: {
            payouts
        }
    }
}

export const fetchSaveUnpaidAdminPayouts = () => {
    const firestore = getFirestore(firebaseApp)
    const payoutsRef = query(collection(firestore, "payouts"), where("paid", "==", false))
    return async (dispatch) => {
        try{
            const querySnapshot = await getDocs(payoutsRef)
            //get an array of products from the snapshot
            const payouts = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(savePayouts(payouts))
            return true
        } catch (e){
            const message = `action > payouts > fetchSaveUnpaidAdminPayouts: Failed to save payouts`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveSellerPayouts = sellerId => {
    const firestore = getFirestore(firebaseApp)
    const payoutsRef = query(collection(firestore, "payouts"), where("payeeId", "==", sellerId))
    return async (dispatch) => {
        try{
            const payoutsListener = await onSnapshot(payoutsRef,
                querySnapshot =>{
                    //get an array of products from the snapshot
                    const payouts = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayouts(payouts))
                }
            )
            return payoutsListener
        } catch (e){
            const message = `action > payouts > fetchSaveSellerPayouts: Failed to save payouts`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveDeliveryProviderPayouts = deliveryProviderId => {
    const firestore = getFirestore(firebaseApp)
    const payoutsRef = query(collection(firestore, "payouts"), where("payeeId", "==", deliveryProviderId))
    return async (dispatch) => {
        try{
            const payoutsListener = await onSnapshot(payoutsRef,
                querySnapshot =>{
                    //get an array of products from the snapshot
                    const payouts = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayouts(payouts))
                }
            )
            return payoutsListener
        } catch (e){
            const message = `action > payouts > fetchSaveDeliveryProviderPayouts: Failed to save payouts`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveAdminPayoutsInDateRange = (
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all payouts placed in the last 30 days
    toDate=Date.now()
) => {
    /**
      * Purpose: retrieve the payouts from the firestore database created between the specified date range
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp)
    const payoutsRef = query(collection(firestore, "payouts"), 
                            where("createdAt", ">=",  fromDate),
                            where("createdAt", "<=", toDate))                          
    return async dispatch => {
        try {
            const payoutsListener = await onSnapshot(payoutsRef,
                querySnapshot => {
                    //get an array of payouts from the snapshot
                    const payouts = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayouts(payouts))
                } 
            )
            return payoutsListener
        } catch (e){
            const message = `action > payouts > fetchSaveAdminPayoutsInDateRange: Failed to save payouts from ${fromDate} to ${toDate}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}