import React from "react"
import styles from "./SearchBar.module.css"
import SearchField from "../../components/SearchField"
import WalletCard from "../../components/WalletCard"
import DropdownMenu from "../../components/DropdownMenu"

import {WALLET_CARD_SIZE_SMALL} from "../../constants/interface"

import UserMenu from "../UserMenu"
import CartBadge from "../CartBadge"

import logo from "../../images/logo.png"
import {withRouter} from 'react-router-dom'

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {logAnalyticsEvent} from "../../config/firebase"


class SearchBar extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {
            hidden: false
        }
        this.prevScrollPos = window.pageYOffset
    }

    handleScroll = () => {
        const {device} = this.props
        if (device.mobileOSType === "ios") {
            if (this.state.hidden) this.setState({hidden: false})
            return
        }
        const currentScrollPos = window.pageYOffset;
        const hidden = this.prevScrollPos < currentScrollPos
        this.prevScrollPos = currentScrollPos
        if (hidden === this.state.hidden) return
        this.setState({
          hidden
        });
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }
      
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleSearch= queryString => {
        const {actions, history, search} = this.props
        if (search.queryString === queryString) return
        actions.fetchTrackQueryString(queryString)
        if (!queryString.trim()) history.push("/")
        else history.push(`/results?q=${queryString}`)
        logAnalyticsEvent("text_search", {name: queryString})
        actions.fetchLogDeviceSessionAction({
            action: "textSearch", 
            name: queryString
        })
    }

    handleGoToYourShopdmCredit = () => {
        const {history, actions, user} = this.props
        logAnalyticsEvent('go_to_shopdm_credit_by_wallet_card_press')
        actions.fetchLogDeviceSessionAction({
            action: "goToShopdmCreditByWalletCardPress", 
            walletId: user.defaultWalletId
        })
        history.push(`/shopdm-credit/${user.id}`)
    }
    
    handleGoToHowSellingWorks = () => {
        const {history} = this.props
        history.push(`/how-selling-works`)
    }

    handleGoToHowDeliveryWorks = () => {
        const {history} = this.props
        history.push(`/how-delivery-works`)
    }

    render(){
        const {history,search, user, sellers, deliveryProviders, products, creditWallets} = this.props
        const handleClickLogo = () => history.push("/")
        const authorizedSellerAccountId = sellers.sellerIds.find(sellerId => {
            const seller = sellers.sellersById[sellerId]
            return seller && seller.roles ? seller.roles[user.id] : false
        })
        const authorizedDeliveryProviderAccountId = deliveryProviders.deliveryProviderIds.find(deliveryProviderId => {
            const deliveryProvider = deliveryProviders.deliveryProvidersById[deliveryProviderId]
            return deliveryProvider && deliveryProvider.roles ? deliveryProvider.roles && deliveryProvider.roles[user.id] : false
        })
        const handleGoToDashboard = () => history.push(`/sellers/${authorizedSellerAccountId}/dashboard`)
        const handleManageDeliveries = () => history.push(`/delivery-providers/${authorizedDeliveryProviderAccountId}/dashboard`)
        const hiddenStyle = this.state.hidden ? styles.hidden : ""
        const wallet = user.defaultWalletId ? creditWallets.creditWalletsById[user.defaultWalletId] : null
        return (
            <div className={[styles.container, "topBar", hiddenStyle].join(" ")}>
                <div className={styles.smallTop}>
                    <img className={'logo'} src={logo} onClick={handleClickLogo} draggable="false" alt="Shopdm Logo"/>
                    <div className={styles.greetingContainer}>
                        <UserMenu />
                    </div>
                </div>
                <div className={styles.headerContent}>
                    <div  className={[styles.headerContainer, styles.left].join(" ")}>
                        <SearchField 
                            placeholder={`Search ${products.stats && products.stats.productStockCount ? products.stats.productStockCount : 0} products`}
                            onSearch={this.handleSearch}
                            onChangeText={this.handleChangeText}
                            text={search.queryString}
                        />
                    </div>
                    <div className={[styles.headerContainer, styles.right].join(" ")}>
                       
                        {
                            authorizedSellerAccountId ?
                            <button 
                                className={['button', 'primary'].join(" ")}
                                onClick={handleGoToDashboard}
                            >
                                Go To Dashboard
                            </button>
                            :
                            authorizedDeliveryProviderAccountId ?
                            <button 
                                className={['button', 'action'].join(" ")}
                                onClick={handleManageDeliveries}
                            >
                                Manage Deliveries
                            </button>
                            :
                            <DropdownMenu 
                                buttonText="Make Money. Sell or Deliver"
                                options={[
                                    {label:"Sell your products online", onClick: this.handleGoToHowSellingWorks},
                                    {label: "Deliver to your community", onClick: this.handleGoToHowDeliveryWorks}
                                ]}
                            />
                        }
                        <div className={styles.phoneOnly}>
                            {
                                wallet ? 
                                <div className={styles.wallet}>
                                    <WalletCard 
                                        name={wallet.name}
                                        balance={wallet.balance}
                                        size={WALLET_CARD_SIZE_SMALL}
                                        onClick={this.handleGoToYourShopdmCredit}
                                    /> 
                                </div>
                                : null
                            }
                            <div>
                            <CartBadge />
                            </div>
                        </div>
                        <div className={[styles.webOnly].join(" ")}>
                            <UserMenu />
                        </div>
                    </div>
                    <div className={[styles.cart, styles.headerContainer, styles.webOnly].join(" ")}>
                    {
                                wallet ? 
                                <div className={styles.wallet}>
                                    <WalletCard 
                                        name={wallet.name}
                                        balance={wallet.balance}
                                        size={WALLET_CARD_SIZE_SMALL}
                                        onClick={this.handleGoToYourShopdmCredit}
                                    /> 
                                </div>
                                : null
                            }
                        <CartBadge />
                    </div>
                </div>
                
            </div>
        )
    }
}

const mapStateToProps = state => ({
    products: state.products,
    search: state.search,
    user: state.user,
    sellers: state.sellers,
    deliveryProviders: state.deliveryProviders,
    device: state.device,
    creditWallets: state.creditWallets
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchBar));