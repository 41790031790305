import React from "react"
import Slider from "../../components/Slider"
import AvatarIcon from "../../components/AvatarIcon"
import {AVATAR_SHAPE_SQUARE, AVATAR_SHAPE_ROUND, AVATAR_SIZE_SMALL, AVATAR_SIZE_MEDIUM, AVATAR_SIZE_BIG} from "../../constants/interface"
import { capitalizeAllWords } from "../../utils/stringUtils"
import styles from "./CategorySlider.module.css"

import { selectedEnvironment } from "../../config"
import {DEVELOPMENT, PRODUCTION} from "../../constants/environment"

const CategorySlider = ({
    categoryArray=[],
    onClick=()=>{},
    onSeeAllCategories=()=>{}
}) => {
    if (selectedEnvironment === DEVELOPMENT){
        categoryArray = [
            {
                id: "6b66d424-6639-441b-9f68-9a346a09cb84", 
                label: "groceries",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Fgrocery-cat300x300.jpg?alt=media&token=cbf096b5-7124-4d0a-9a8d-c0ac18bac00a"
            },
            {
                id: "e738b47b-b4d8-4cbd-bb0a-6b1d3da3b27d", 
                label: "personal care",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Fpersonal-care-cat300x300.jpg?alt=media&token=8cb5eb5f-93be-4f63-84f7-c5aa9bf84656"
            },
            {
                id: "ca1cff88-e784-4cf2-8778-2f2f90d28bde", 
                label: "farm to table",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Ffarm-table-cat300x300.jpg?alt=media&token=c62072fd-beaf-4f12-be87-d119678d0632"
            },
            {
                id: "a8b9b46e-03ff-43f3-b6d9-02d00cdc6a3b", 
                label: "pharmaceuticals",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Fpharmacy-cat300x300.jpg?alt=media&token=27106334-7477-4683-9064-f56f95e6310c"
            },
            {
                id: "8c094e2d-020d-4f01-9e33-7762c708805e", 
                label: "strictly local",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Fstrictly-local-cat-300x300.jpg?alt=media&token=ef84b862-adae-43b7-991a-35e54585f059"
            },
            {
                id: "139ae754-a1dd-469b-9ec2-7dfdac8e7ffc", 
                label: "baby products",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Fbaby-products-cat300x300.jpg?alt=media&token=ec542018-ed9d-4f20-9a15-7acbc1bc8792"
            },
            {
                id: "a17f824c-e75e-4014-b1d9-879fda26dbb9", 
                label: "home decor",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Fhome-decor-300x300.jpg?alt=media&token=38b692b0-5b68-4426-b99c-4cb35842b0d5"
            },
            {
                id: "c1174fcf-6299-4874-a88d-d576eec8e0e7", 
                label: "haircare",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Fhair-care-cat300x300.jpg?alt=media&token=808b7115-3918-4911-95ce-d06114e0b5ab"
            },
            {
                id: "1",
                label: "All Categories",
                onClick: onSeeAllCategories
            },
        ]
    } else if (selectedEnvironment === PRODUCTION){
        categoryArray = [
            {
                id: "5e364432-7654-43aa-88fe-63c9551f0e1e", 
                label: "groceries",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Fgrocery-cat300x300.jpg?alt=media&token=cbf096b5-7124-4d0a-9a8d-c0ac18bac00a"
            },
            {
                id: "2c9c4e60-6f2a-4721-b727-7fca6de60641", 
                label: "personal care",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Fpersonal-care-cat300x300.jpg?alt=media&token=8cb5eb5f-93be-4f63-84f7-c5aa9bf84656"
            },
            {
                id: "478d8633-a1f8-4117-93c9-23db23b7fb9c", 
                label: "farm to table",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Ffarm-table-cat300x300.jpg?alt=media&token=c62072fd-beaf-4f12-be87-d119678d0632"
            },
            {
                id: "e19b7cc3-2b01-4a52-b805-2f9d87ca0188", 
                label: "pharmaceuticals",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Fpharmacy-cat300x300.jpg?alt=media&token=27106334-7477-4683-9064-f56f95e6310c"
            },
            {
                id: "bde159f9-a75e-4c23-9f5d-ffdfc3e82273", 
                label: "strictly local",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Fstrictly-local-cat-300x300.jpg?alt=media&token=ef84b862-adae-43b7-991a-35e54585f059"
            },
            {
                id: "0de3734f-7325-4a3d-a605-8a3b8145e005", 
                label: "baby products",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Fbaby-products-cat300x300.jpg?alt=media&token=ec542018-ed9d-4f20-9a15-7acbc1bc8792"
            },
            {
                id: "e96c7118-fe7f-4f91-89a3-3f81772146af", 
                label: "home decor",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Fhome-decor-300x300.jpg?alt=media&token=38b692b0-5b68-4426-b99c-4cb35842b0d5"
            },
            {
                id: "ef947eba-c296-465b-9244-9ae69907a0e0", 
                label: "haircare",
                imageUrlSmall: "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/categories%2Fhair-care-cat300x300.jpg?alt=media&token=808b7115-3918-4911-95ce-d06114e0b5ab"
            },
            {
                id: "1",
                label: "All Categories",
                onClick: onSeeAllCategories
            },
        ]
    }
    return (
        <Slider>
            {
                categoryArray.map(c => {
                    return (
                        <CategoryTile 
                            id={c.id}
                            label={c.label}
                            onClick={c.onClick ? c.onClick : onClick}
                            imageUrl={c.imageUrlSmall}
                        />
                    )
                })
            }
        </Slider>      
    )
}

const CategoryTile = ({
    id,
    label,
    onClick=()=>{},
    imageUrl=""
}) => {
    return (
        <div className={styles.categoryTile} onClick={() => onClick(id)}>
            <AvatarIcon
                name={label} 
                shape={AVATAR_SHAPE_SQUARE}
                size={AVATAR_SIZE_MEDIUM}
                imageUrl={imageUrl}
            />
            <div className={styles.categoryTileLabel}>{capitalizeAllWords(label)}</div>
        </div>
    )
}

export default CategorySlider